import React from 'react';
import dayjs from 'dayjs';
import { ClockIcon } from '@kvdbil/components';
import { useTranslation } from '~/Locale';
import { StatusTagWrapper } from './StatusTagWrapper';
import { ProcessTimeStatuses } from '~/helpers/auction';

interface Props {
  processTimeStatuses: ProcessTimeStatuses;
  preliminaryCloseAt?: string;
  isComingSoon: boolean;
}

const AuctionCardRemainingTime = ({
  processTimeStatuses,
  preliminaryCloseAt,
  isComingSoon
}: Props) => {
  const { t } = useTranslation();

  const { isClosed, isToday, isTonight, isTomorrow, isSoon, isFar } =
    processTimeStatuses;

  if (isComingSoon) {
    return (
      <StatusTagWrapper>
        <ClockIcon />
        {t('Soon on KVD')}
      </StatusTagWrapper>
    );
  }

  if (isClosed) {
    return <StatusTagWrapper>{t('Closed')}</StatusTagWrapper>;
  }

  if (!preliminaryCloseAt) {
    return null;
  }

  const closeAt = dayjs(preliminaryCloseAt);

  return (
    <StatusTagWrapper>
      {isTonight && (
        <>
          <ClockIcon />
          {t('Tonight %1$s', closeAt.format('HH:mm'))}
        </>
      )}
      {isToday && !isTonight && (
        <>
          <ClockIcon />
          {t('Today %1$s', closeAt.format('HH:mm'))}
        </>
      )}
      {isTomorrow && (
        <>
          <ClockIcon />
          {t('Tomorrow')}
        </>
      )}
      {isSoon && (
        <>
          <ClockIcon />
          {closeAt.format('dddd')}
        </>
      )}
      {isFar && (
        <>
          <ClockIcon />
          {closeAt.format('MMM D')}
        </>
      )}
    </StatusTagWrapper>
  );
};

export default AuctionCardRemainingTime;
