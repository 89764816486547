import styled from 'styled-components';
import {
  CertifiedIcon,
  EcoIcon,
  FavouriteIcon,
  FavouriteFilledIcon,
  ReleasingIcon,
  mq
} from '@kvdbil/components';
import React, { MouseEvent, useState } from 'react';
import { PopupIcon } from '~/App/shared/components/ProductCard/components/PopupIcon';
import { useTranslation } from '~/Locale';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  position: relative;

  & > span {
    margin-right: 0.5rem;
  }
  & > :last-child {
    margin-right: 0;
  }

  svg {
    font-size: 2rem;
  }

  ${mq('tablet')} {
    margin-bottom: 0.5rem;
    svg {
      font-size: 1.5rem;
    }
  }
`;

const FavouriteIconWrapper = styled.div<{ isFavourite: boolean }>`
  margin-left: auto;
  display: flex;

  ${({ theme, isFavourite }) =>
    isFavourite && `color: ${theme.colors.error.main}`};

  ${({ theme, isFavourite }) =>
    !isFavourite && `:hover { color: ${theme.colors.error.main}`};
`;

const Leaf = styled(EcoIcon)`
  path:nth-child(2) {
    fill: ${({ theme }) => theme.colors.gray.light4};
  }
`;

export interface Props {
  isCertified: boolean;
  isFavourite: boolean;
  isEcoFriendly: boolean;
  isReleasable: boolean;
  toggleFavourite(): void;
}

const Icons = ({
  isCertified,
  isEcoFriendly,
  isFavourite,
  isReleasable,
  toggleFavourite
}: Props) => {
  const [isHovering, setIsHovering] = useState(false);
  const { t } = useTranslation();

  const handleToggleFavourite = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    toggleFavourite();
  };

  return (
    <Container onMouseEnter={() => setIsHovering(true)}>
      {isCertified && (
        <PopupIcon
          icon={CertifiedIcon}
          dataTestId={'certified-icon'}
          cmsKey={'certified'}
          isVehicleCardHovered={isHovering}
        />
      )}
      {isEcoFriendly && (
        <PopupIcon
          icon={Leaf}
          dataTestId="leaf-icon"
          cmsKey={'ecoFriendly'}
          isVehicleCardHovered={isHovering}
        />
      )}
      {isReleasable && (
        <PopupIcon
          icon={ReleasingIcon}
          dataTestId="releasing-icon"
          cmsKey={'releasing'}
          isVehicleCardHovered={isHovering}
        />
      )}
      <FavouriteIconWrapper
        data-testid="favourite-icon-wrapper"
        isFavourite={isFavourite}
        onClick={handleToggleFavourite}
      >
        {isFavourite ? (
          <FavouriteFilledIcon
            data-testid="favourite-icon-solid"
            title={t('FILLED_FAVOURITE_ICON_TOOLTIP')}
          />
        ) : (
          <FavouriteIcon
            data-testid="favourite-icon"
            title={t('FAVOURITE_ICON_TOOLTIP')}
          />
        )}
      </FavouriteIconWrapper>
    </Container>
  );
};

export default Icons;
