import { combineReducers } from 'redux';
import { createUuid } from '~/helpers/id';
import { Session, SessionErrors } from '../interfaces/store/Session';
import { SessionActions } from '../actions/session';
import { VerifyPhoneNumberActions } from '../actions/verifyPhoneNumber';
import { ImpersonateAction } from '../actions/impersonate';
import { ReduxStore } from '../interfaces/store';

export type Action =
  | SessionActions
  | VerifyPhoneNumberActions
  | ImpersonateAction;

const initialState = { idToken: '' };
const auth = (state = initialState, action: SessionActions) => {
  switch (action.type) {
    case 'SESSION_HYDRATE':
      return action?.session?.auth ?? state;

    case 'SESSION_UPDATE':
    case 'SESSION_START_SUCCESS': {
      return { ...state, ...action.auth };
    }

    case 'SESSION_START_FAILURE':
    case 'SESSION_DESTROY_SUCCESS':
    case 'SESSION_DESTROY_FAILURE':
      return initialState;

    default:
      return state;
  }
};

type Member = Session['member'];

const member = (state: Member = {} as Member, action: Action) => {
  switch (action.type) {
    case 'SESSION_HYDRATE':
      return action?.session?.member ?? {};

    case 'VERIFY_MEMBER_EMAIL': {
      return {
        ...state,
        person: { ...state.person, emailVerified: true }
      };
    }

    case 'VERIFY_PHONE_NUMBER_SUCCESS':
    case 'UPDATE_PHONE_NUMBER_SUCCESS': {
      return {
        ...state,
        ...(action?.payload?.member ?? {}),
        person: {
          ...state.person,
          ...action?.payload?.member?.person
        }
      };
    }

    case 'CONFIRM_MEMBER_SETTINGS':
      return { ...state, consentAcquired: true };

    case 'SESSION_UPDATE':
    case 'SESSION_START_SUCCESS': {
      return {
        ...state,
        ...action?.member,
        person: {
          ...state.person,
          ...action?.member?.person
        }
      };
    }

    case 'SESSION_START_FAILURE':
    case 'SESSION_DESTROY_SUCCESS':
    case 'SESSION_DESTROY_FAILURE':
      return {} as Member;

    default:
      return state;
  }
};

const errors = (state: SessionErrors = null, action: Action) => {
  switch (action.type) {
    case 'SESSION_HYDRATE':
      return action?.session?.errors ?? null;

    case 'SESSION_START_FAILURE':
      return action?.errors ?? null;

    case 'SESSION_START_REQUEST':
    case 'SESSION_START_SUCCESS':
    case 'SESSION_DESTROY_REQUEST':
    case 'SESSION_DESTROY_SUCCESS':
    case 'SESSION_DESTROY_FAILURE':
      return null;

    default:
      return state;
  }
};

const isLoading = (state = false, action: Action) => {
  switch (action.type) {
    case 'SESSION_START_REQUEST':
    case 'SESSION_DESTROY_REQUEST':
      return true;

    case 'SESSION_START_SUCCESS':
    case 'SESSION_START_FAILURE':
    case 'SESSION_DESTROY_SUCCESS':
    case 'SESSION_DESTROY_FAILURE':
      return false;

    default:
      return state;
  }
};

const isAuthenticated = (state = false, action: Action) => {
  switch (action.type) {
    case 'SESSION_HYDRATE':
      return action?.session?.isAuthenticated ?? false;

    case 'SESSION_START_SUCCESS':
      return true;

    case 'SESSION_START_REQUEST':
    case 'SESSION_START_FAILURE':
    case 'SESSION_DESTROY_SUCCESS':
    case 'SESSION_DESTROY_FAILURE':
      return false;

    default:
      return state;
  }
};

const clientVersion = (state = '', action: Action) => {
  switch (action.type) {
    case 'SESSION_HYDRATE':
      // We don't want to update the version when hydrating, as the saved version will always be older
      return state;
    case 'SET_CLIENT_VERSION':
      return action.payload ?? state;

    default:
      return state;
  }
};

const isHydrated = (state = false, action: Action) => {
  switch (action.type) {
    case 'SESSION_HYDRATE':
      return true;
    default:
      return state;
  }
};

export const isImpersonating = (state = false, action: Action) => {
  switch (action.type) {
    case 'IS_IMPERSONATING':
      return true;
    case 'SESSION_DESTROY_REQUEST':
      return false;
    default:
      return state;
  }
};

const sessionId = (state = '', action: Action) => {
  switch (action.type) {
    case 'SESSION_HYDRATE': {
      return action?.session?.sessionId ?? createUuid();
    }
    default:
      return state;
  }
};

const session = combineReducers<ReduxStore['session']>({
  errors,
  isLoading,
  sessionId,
  isAuthenticated,
  auth,
  member,
  clientVersion,
  isHydrated,
  isImpersonating
});

export default session;
