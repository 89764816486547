import { GET_MY_AUCTIONS_SUCCESS } from '../actions/auctions';
import Auction from '../interfaces/Auction';
import { ReduxStore } from '../interfaces/store';

type MyAuctionAction = {
  type: typeof GET_MY_AUCTIONS_SUCCESS;
  payload?: Auction[];
};

const myAuctions = (
  state = [],
  action: MyAuctionAction
): ReduxStore['myAuctions'] => {
  switch (action.type) {
    case 'GET_MY_AUCTIONS_SUCCESS':
      return action.payload ?? state;
    default:
      return state;
  }
};

export default myAuctions;
