import { BodyText, ErrorIcon, Flex, Stack, Title } from '@kvdbil/components';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '~/Locale';
import AuthCloseButton from '../../components/AuthCloseButton';
import BankIdAuthButton from '../../components/BankIdAuthButton';

const Status = styled.div`
  color: ${({ theme }) => theme.colors.error.main};

  svg {
    font-size: 6rem;
  }
`;

type Props = {
  onRetry(): void;
  error: string;
  onClose(): void;
  title?: string;
  subtitle?: string;
};

export const VerifyBankIDRetryView = ({
  onRetry,
  error,
  onClose,
  title,
  subtitle
}: Props) => {
  const { t } = useTranslation();

  return (
    <Stack>
      <AuthCloseButton onClose={onClose} />

      {title && <Title style={{ textAlign: 'center' }}>{title}</Title>}

      {subtitle && (
        <BodyText style={{ textAlign: 'center' }}>{subtitle}</BodyText>
      )}

      <Flex justify={'center'}>
        <Status>
          <ErrorIcon />
        </Status>
      </Flex>

      {error && <BodyText style={{ textAlign: 'center' }}>{error}</BodyText>}
      <BankIdAuthButton color="info" size="regular" onClick={onRetry}>
        {t('Verify with BankID')}
      </BankIdAuthButton>
    </Stack>
  );
};

export default VerifyBankIDRetryView;
