import {
  ConnectionStateFetchAction,
  WebsocketEventAction
} from '../actions/connection-events';
import { ReduxStore } from '../interfaces/store';

const initialState: ReduxStore['connectionState'] = {
  connected: false,
  connecting: true,
  disconnectedAt: null,
  failedToFetch: false,
  failedToFetchAt: null,
  lastFetch: null,
  shouldFetchAfterReconnect: false
};

const connectionState = (
  state = initialState,
  action: WebsocketEventAction | ConnectionStateFetchAction
): ReduxStore['connectionState'] => {
  switch (action.type) {
    case 'WEBSOCKET_CONNECTED':
      return {
        ...state,
        connected: true,
        connecting: false,
        disconnectedAt: null
      };

    case 'WEBSOCKET_DISCONNECTED': {
      const newState = {
        ...state,
        connected: false,
        connecting: false,
        shouldFetchAfterReconnect: false
      };

      if (state.connected === true) {
        newState.disconnectedAt = new Date();
      }

      return newState;
    }

    case 'WEBSOCKET_RECONNECTED':
      return {
        ...state,
        connected: true,
        connecting: false,
        disconnectedAt: null,
        shouldFetchAfterReconnect: true
      };

    case 'FETCH_FAILED':
      return { ...state, failedToFetch: true, failedToFetchAt: new Date() };

    case 'REGISTER_LAST_FETCH':
      return { ...state, lastFetch: new Date() };

    case 'FETCH_SUCCESS':
      return { ...state, shouldFetchAfterReconnect: false };

    default:
      return state;
  }
};

export default connectionState;
