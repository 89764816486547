import React from 'react';
import { CheckBox as BaseCheckBox } from '@kvdbil/components';
import { CheckBoxProps as BaseCheckBoxProps } from '@kvdbil/components/types/components/CheckBox';
import {
  Control,
  Controller,
  Path,
  PathValue,
  RegisterOptions,
  UnpackNestedValue
} from 'react-hook-form';

type ControllerBaseValue = string | number | readonly string[] | undefined;

export type CheckBoxProps<FormState> = Omit<BaseCheckBoxProps, 'checked'> & {
  control: Control<FormState>;
  name: Path<FormState>;
  rules?: RegisterOptions;
  defaultValue?: UnpackNestedValue<
    PathValue<FormState, (string | undefined) & Path<FormState>>
  >;
};

const CheckBoxWithRef = React.forwardRef((props: BaseCheckBoxProps, ref) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore next-line
  <BaseCheckBox {...props} forwardRef={ref} />
));

const CheckBoxWithControl = <FormState,>(props: CheckBoxProps<FormState>) => {
  return (
    <Controller
      {...props}
      render={({ field, fieldState }) => (
        <CheckBoxWithRef
          {...props}
          {...field}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.onChange?.(e);
            field.onChange(e.target.checked);
          }}
          value={field.value as ControllerBaseValue}
          checked={Boolean(field.value)}
          hasError={Boolean(fieldState.error)}
        />
      )}
    />
  );
};

export default CheckBoxWithControl;
