import React, { ReactNode } from 'react';
import { CHUNK_LOAD_ERROR_NAME } from '../../config/constants';
import ChunkLoadErrorView from '../shared/components/ChunkLoadErrorView';
import Fatal from '../shared/components/Fatal';
import { log } from '~/helpers/bugsnagHelper';

interface Props {
  children: ReactNode;
}
interface State {
  hasError: boolean;
  error: Error | null;
}

class ChunkLoadErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(err: Error) {
    return { hasError: true, error: err };
  }

  componentDidCatch(error: Error): void {
    log(error, {
      unhandled: true,
      severity: 'error',
      message: `[ErrorBoundary] ${error.name}`
    });
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    if (this.state.error?.name === CHUNK_LOAD_ERROR_NAME) {
      return <ChunkLoadErrorView />;
    }

    return (
      <Fatal
        errorOutput={this.state.error?.message ?? 'Something went wrong'}
      />
    );
  }
}

export default ChunkLoadErrorBoundary;
