import { createSelector } from 'reselect';
import { MEMBER_TYPES, MEMBER_LEVELS } from '~/config/constants';
import { Session } from '../interfaces/store/Session';
import { ReduxStore } from '../interfaces/store';
import { getIsAccountComplete, hasForeignConnections } from '~/helpers/member';
import { Person } from '../interfaces/Person';

export const session = createSelector<ReduxStore, Session, Session>(
  state => state?.session,
  value => value
);

export const authSelector = createSelector(session, state => state?.auth);

export const idToken = createSelector(authSelector, state => state?.idToken);

export const refreshTokenSelector = createSelector(
  authSelector,
  state => state?.refreshToken || null
);

export const isAuthenticated = createSelector(
  session,
  state => state?.isAuthenticated
);

export const isHydratedSelector = createSelector(
  session,
  state => state?.isHydrated
);

export const isLoading = createSelector(session, state => state?.isLoading);

export const isLoggedInSelector = createSelector(
  isLoading,
  isHydratedSelector,
  isAuthenticated,
  (isLoading, isHydrated, isAuthenticated) =>
    Boolean(!isLoading && isHydrated && isAuthenticated)
);

export const member = createSelector(session, state => state?.member);

export const memberIdSelector = createSelector(member, state => state?.id);

export const personSelector = createSelector<
  ReduxStore,
  Session['member'],
  Person | undefined
>(member, state => state?.person);

export const phoneNumber = createSelector(
  personSelector,
  state => state?.phoneNumber
);

export const email = createSelector(personSelector, person => person?.email);

export const memberFirstName = createSelector(
  personSelector,
  state => state?.firstName
);

export const memberLastName = createSelector(
  personSelector,
  state => state?.lastName
);

export const isEmailVerified = createSelector(
  personSelector,
  state => state?.emailVerified
);

export const nationalSecurityNumber = createSelector(
  personSelector,
  person => person?.nationalIdentificationNumber
);

export const isPhoneNumberVerifiedSelector = createSelector(
  personSelector,
  state => state?.phoneNumberVerified
);

export const isBankIdVerifiedSelector = createSelector(
  personSelector,
  state => state?.bankidVerified ?? false
);

export const memberLevel = createSelector(member, state => state?.level);

export const memberType = createSelector(
  member,
  ({ memberType }) => memberType
);

export const isPrivateCustomer = createSelector(
  member,
  memberState => memberState && memberState.memberType === 'PERSON'
);

export const memberCanBidSelector = createSelector(
  memberLevel,
  memberLevel =>
    MEMBER_LEVELS.SELLER === memberLevel || MEMBER_LEVELS.BIDDER === memberLevel
);

export const isMemberOrganisationPersonSelector = createSelector(
  session,
  session => {
    const memberType = session?.member?.memberType || MEMBER_TYPES.PERSON;
    return memberType === MEMBER_TYPES.ORGANISATION_PERSON;
  }
);

export const memberOrganisationOrgNumber = createSelector(
  member,
  ({ person }) => person?.organisation?.organisationNumber
);

export const memberOrganisationVatNumber = createSelector(
  member,
  ({ person }) => person?.organisation?.vatNumber
);

export const organisationNameSelector = createSelector(
  member,
  ({ person }) => person?.organisation?.name
);

export const isMemberCompleteSelector = createSelector(
  session,
  getIsAccountComplete
);

export const hasForeignConnectionsSelector = createSelector(
  session,
  hasForeignConnections
);
