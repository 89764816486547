import React from 'react';
import { BodyTextSkeleton, skeletonAnimation } from './BlogSkeletons';
import styled from 'styled-components';

const ImageSkeletonLoader = styled.div`
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  margin-bottom: 1rem;
  ${skeletonAnimation}
`;

const DateSkeleton = styled(BodyTextSkeleton)`
  width: 60%;
  margin-top: 0.5rem;
`;

function ArticleCardSkeleton() {
  return (
    <div data-testid="skeleton">
      <ImageSkeletonLoader />
      <BodyTextSkeleton />
      <DateSkeleton />
    </div>
  );
}

export default ArticleCardSkeleton;
