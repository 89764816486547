import debounce from 'lodash.debounce';
import { useRef } from 'react';

type anyArgsFunction = (...args: unknown[]) => void;
export const useDebounce = (
  callback: anyArgsFunction,
  delay: number,
  settings?: object
) => {
  const throttledFunc = useRef(debounce(callback, delay, settings));
  return throttledFunc.current;
};
