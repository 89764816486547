// Calculation formula is from same calculator that is used on DNB site. Removed only some irrelevant variables.
// https://carporten.nu/kalkyl-avbetalning-privat-iframe/
const getRate = (
  loanPeriod: number,
  monthlyPayment: number,
  present: number
) => {
  let r: number,
    l: number,
    o: number,
    u = 0,
    h = 0,
    d = 0,
    v = 0.005;
  for (
    Math.abs(v) < 1e-10 || (h = Math.exp(loanPeriod * Math.log(1 + v))),
      r = present + monthlyPayment * loanPeriod,
      l = present * h + monthlyPayment * (1 / v) * (h - 1),
      d = o = 0,
      u = v;
    1e-10 < Math.abs(r - l) && d < 20;

  )
    (v = (l * o - r * u) / (l - r)),
      (o = u),
      (u = v),
      (r = l),
      (l =
        Math.abs(v) < 1e-10
          ? present * (1 + loanPeriod * v) + monthlyPayment * loanPeriod
          : present * (h = Math.exp(loanPeriod * Math.log(1 + v))) +
            monthlyPayment * (1 / v) * (h - 1)),
      ++d;
  return v;
};

export const getEffectiveInterestRate = (
  applicationFee: number,
  interestRate: number,
  loanPeriod: number,
  totalAmount: number,
  downPayment: number,
  monthlyFee: number,
  fractionDigits = 2
) => {
  const interestRateMonth = interestRate / 100 / 12;
  const loanAmount = totalAmount - downPayment;
  const mp =
    (interestRateMonth *
      (loanAmount * Math.pow(1 + interestRateMonth, loanPeriod))) /
    (Math.pow(1 + interestRateMonth, loanPeriod) - 1);
  const payment = mp + monthlyFee;
  const present = loanAmount - applicationFee;
  const rate = getRate(loanPeriod, payment, -present);
  const eir = ((Math.pow(1 + rate, 12) - 1) / 12) * 100 * 12;

  return eir.toFixed(fractionDigits);
};
