import { RefObject, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import {
  setFilterPageActiveAction,
  setFilterPageScrollData
} from '~/App/shared/actions/storeObjectActions';
import { usePrevious } from '~/App/shared/hooks/usePrevious';
import { translateRoute } from '~/App/shared/localization';
import {
  isRememberScrollActiveSelector,
  scrollFilterURLSelector,
  scrollPositionAuctionIdSelector
} from '~/App/shared/selectors/storeObjectsSelectors';
import { TranslateFunction, useTranslation } from '~/Locale';

/**
 * Updates a boolean value in the storeObjects state to indicate whether the filter page is active.
 * True when the component mounts, false when the component unmounts.
 */
export const useFilterPageActive = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFilterPageActiveAction(true));

    return () => {
      dispatch(setFilterPageActiveAction(false));
    };
  }, [dispatch]);
};

const isLocationObjectPage = (
  t: TranslateFunction,
  pathname: string | undefined
) => {
  return [
    translateRoute(t, 'ROUTES.FIXED_PRICE'),
    translateRoute(t, 'ROUTES.AUCTIONS')
  ].some(routes => pathname?.includes(routes));
};

/**
 * Resets the scroll position in the storeObjects state when the user navigates away from the filter page or object page.
 */
export const useResetFilterPageScrollPosition = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const previousLocation = usePrevious(location.pathname);
  const isFilterPageActive = useSelector(isRememberScrollActiveSelector);

  useEffect(() => {
    const isPreviousLocationObjectPage = isLocationObjectPage(
      t,
      previousLocation
    );
    const isObjectPage = isLocationObjectPage(t, location.pathname);

    if (!isPreviousLocationObjectPage && !isFilterPageActive && !isObjectPage) {
      dispatch(setFilterPageScrollData({}));
    }
  }, [dispatch, location.pathname, previousLocation, isFilterPageActive, t]);
};

interface ScrollToProductCard {
  auctionId: string | null;
  ref: RefObject<HTMLAnchorElement> | null;
}

/**
 * Scrolls to the last clicked product card when the user navigates to the same filter page where it was clicked.
 */
export const useScrollToProductCard = ({
  auctionId,
  ref
}: ScrollToProductCard) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isRememberScrollActive = useSelector(isRememberScrollActiveSelector);
  const scrollAuctionId = useSelector(scrollPositionAuctionIdSelector);
  const filterPageURL = useSelector(scrollFilterURLSelector);

  useEffect(() => {
    if (
      isRememberScrollActive &&
      ref?.current &&
      auctionId === scrollAuctionId &&
      location.pathname === filterPageURL
    ) {
      ref.current.scrollIntoView({ block: 'center' });
      dispatch(setFilterPageScrollData({})); // Reset so we don't scroll again
    }
  }, [
    ref,
    dispatch,
    filterPageURL,
    location.pathname,
    isRememberScrollActive,
    auctionId,
    scrollAuctionId
  ]);
};
