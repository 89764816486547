export type VehicleType =
  | 'CAR'
  | 'LIGHT_TRUCK'
  | 'RECREATIONAL_VEHICLE'
  | 'BOAT'
  | 'CARAVAN'
  | 'TRAILER'
  | 'CAMPER'
  | 'OTHER'
  | 'MC_MOPED';

export const vehicleTypes = [
  'car',
  'light_transport',
  'heavy_transport',
  'machines',
  'recreational',

  // TODO: [KVDBIL-1213] remove on split of vehicleTypes
  'transport_vehicle',
  // TODO: [KVDBIL-1213] remove on renaming of vehicleTypes
  'recreational_vehicle'
] as const;

export type VehicleTypes = (typeof vehicleTypes)[number];
