import { AxiosError } from 'axios';
import {
  CheckoutOrder,
  CheckoutPrice
} from '~/App/shared/interfaces/store/Checkout';
import { WarrantyMonths } from '~/App/views/Checkout/constants';
import { TranslateFunction } from '~/Locale';
import { isAxiosError } from './axiosUtils';
import { calculateFinance } from './calculator';

export const getMonthPeriods = (
  t: TranslateFunction,
  withResidualValue?: boolean
) =>
  withResidualValue
    ? [
        {
          label: t('24 months'),
          value: '24'
        },
        {
          label: t('36 months'),
          value: '36'
        }
      ]
    : [
        {
          label: t('24 months'),
          value: '24'
        },
        {
          label: t('36 months'),
          value: '36'
        },
        {
          label: t('48 months'),
          value: '48'
        },
        {
          label: t('60 months'),
          value: '60'
        },
        {
          label: t('72 months'),
          value: '72'
        }
      ];

/**
 * @description Inserts "-" at the fourth place from last.
 *
 * @param {*} identificationNumber
 * @returns formatted identification number.
 */
export const formatNationalIdentificationNumber = (
  identificationNumber: string
) => {
  if (typeof identificationNumber !== 'string') {
    return identificationNumber;
  }

  const end = identificationNumber.slice(-4);
  const start = identificationNumber.substring(
    0,
    identificationNumber.length - 4
  );

  if (!start || !end) {
    return '';
  }

  return `${start} - ${end}`;
};

export const formatOrgNumber = (orgNumber: string | undefined) => {
  if (typeof orgNumber !== 'string') {
    return '';
  }

  return orgNumber.substring(0, 6) + ' - ' + orgNumber.substring(6);
};

export interface WarrantyOption {
  [key: number]: {
    paymentMethod: string;
    price: number;
    originalPrice: number;
    period: WarrantyMonths;
  };
}

export interface CalculatedPrice extends CheckoutPrice {
  main: number;
  available?: boolean;
  originalPrice?: number;
  loanPrice: number;
  loanOriginalPrice?: number;
  summaryMonthlyCost?: number;
  summaryMonthlyCostOriginal?: number;
}

export interface GetCheckoutPricesInput {
  price: number;
  available?: boolean;
  originalPrice?: number;
  months: number;
  isMainMonthly: boolean;
  interestRate: number;
  downPayment?: number;
  residualPercentage?: number;
}

export const calculateCheckoutPrices = ({
  price,
  originalPrice,
  months,
  available,
  downPayment = 0,
  interestRate,
  isMainMonthly,
  residualPercentage = 0
}: GetCheckoutPricesInput) => {
  const loanPrice = calculateFinance({
    months,
    price: price,
    downPayment,
    interestRate,
    residualPercentage
  });

  const loanOriginalPrice = originalPrice ? originalPrice / months : undefined;

  return {
    price,
    available,
    loanPrice,
    originalPrice,
    downPayment,
    loanOriginalPrice,
    main: isMainMonthly ? loanPrice : price
  };
};

export /**
 * Filters out falsy values from array with Type-Safety
 *
 * @template TValue
 * @param {(TValue | null | undefined)} value
 * @returns {value is TValue}
 */
const notEmpty = <TValue>(
  value: TValue | null | undefined
): value is TValue => {
  return Boolean(value);
};

interface CalculateMonthlyCost {
  price: number;
  loanPeriod: number;
  downPayment: number;
  interestRate: number;
  residualPercentage: number;
}
export const calculateMonthlyCost = ({
  price,
  loanPeriod,
  downPayment,
  interestRate,
  residualPercentage
}: CalculateMonthlyCost) => {
  return calculateFinance({
    price,
    downPayment,
    months: loanPeriod,
    interestRate,
    residualPercentage
  });
};

export const getCheckoutStepState = (
  step: 'BUYER' | 'FINANCING' | 'PICKUP' | 'TRANSPORT' | 'INTRODUCTION',
  order?: CheckoutOrder
) => {
  let currentStep = step;
  if (order?.checkoutFlow === 'SWEDISH_CAR_DEALERS' && step === 'FINANCING') {
    currentStep = 'INTRODUCTION';
  }

  return order?.checkoutProgress?.steps[currentStep]?.state ?? 'NOT_COMPLETED';
};

export const getResponseMessage = (error: AxiosError | Error): string => {
  const errorMessage =
    isAxiosError(error) &&
    typeof error?.response?.data.message === 'string' &&
    error?.response?.data.message;

  return errorMessage || '';
};
