import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from '~/Locale';
import { MenuMainView } from './components/MenuMainView';
import { MenuViewContainer } from './components/MenuViewContainer';
import { useDispatch, useSelector } from 'react-redux';
import { selectDrawerMenuView } from '~/App/shared/selectors/drawerMenuSelector';
import {
  closeMenuDrawer,
  openMenuDrawer
} from '~/App/shared/actions/drawerMenu';
import { Spacer } from '@kvdbil/components';
import { SignIn } from '~/App/shared/components/Auth/SignIn/components/SignIn';
import { useHistory } from 'react-router';
import { translateRoute } from '~/App/shared/localization';
import VerifyPhoneNumber from '~/App/shared/components/VerifyPhoneNumber/VerifyPhoneNumber';
import { VerifyBankID } from '~/App/shared/components/Auth/VerifyBankID';
import Registration from '~/App/shared/components/Auth/Registration';

const Container = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background.light};
  box-shadow: ${({ theme }) => theme.elevations.elevation8};

  a {
    color: ${({ theme }) => theme.colors.text.dark};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

export const DrawerMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const view = useSelector(selectDrawerMenuView);

  const [isBankIdOpen, setIsBankIdOpen] = useState(false);

  const handleOnClose = useCallback(() => {
    dispatch(closeMenuDrawer());
  }, [dispatch]);

  const handleOnSignIn = useCallback(() => {
    dispatch(closeMenuDrawer());
  }, [dispatch]);

  const handleBankIdAuthComplete = useCallback(() => {
    setIsBankIdOpen(true);
  }, [setIsBankIdOpen]);

  const handleOnBack = useCallback(() => {
    dispatch(openMenuDrawer('MAIN'));
  }, [dispatch]);

  const handleToggleCreateAccount = useCallback(() => {
    dispatch(openMenuDrawer('REGISTER'));
  }, [dispatch]);

  const handlePressForgotPassword = useCallback(
    (email?: string) => {
      const query = email ? new URLSearchParams({ email }).toString() : '';
      const url =
        translateRoute(t, '/ROUTES.MEMBER/ROUTES.MEMBER_FORGOT_PASSWORD') +
        (query ? '?' + query : '');

      history.push(url);
    },
    [history, t]
  );

  const handleOnBankIdClose = useCallback(() => {
    setIsBankIdOpen(false);
  }, []);

  return (
    <Container>
      <Content>
        {view === 'MAIN' && <MenuMainView />}

        {view === 'LOGIN' && (
          <MenuViewContainer
            onBack={isBankIdOpen ? undefined : handleOnBack}
            onClose={isBankIdOpen ? undefined : handleOnClose}
          >
            <SignIn
              onSignIn={handleOnSignIn}
              onPressCreateAccount={handleToggleCreateAccount}
              onPressForgotPassword={handlePressForgotPassword}
              onBankIdAuthComplete={handleBankIdAuthComplete}
              onBankIdClose={handleOnBankIdClose}
            />
          </MenuViewContainer>
        )}

        {view === 'REGISTER' && (
          <MenuViewContainer>
            <Registration
              showDescription
              onBack={handleOnBack}
              onClose={handleOnClose}
            />

            <Spacer height={3.5} />
          </MenuViewContainer>
        )}

        {view === 'VERIFY_PHONE_NUMBER' && (
          <MenuViewContainer onBack={handleOnBack} onClose={handleOnClose}>
            <VerifyPhoneNumber isBiddingView handleClose={handleOnClose} />
          </MenuViewContainer>
        )}

        {view === 'VERIFY_BANKID' && (
          <MenuViewContainer>
            <VerifyBankID onClose={handleOnClose} />
          </MenuViewContainer>
        )}
      </Content>
    </Container>
  );
};

export default DrawerMenu;
