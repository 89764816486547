import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import { getOpeningHours } from '~/helpers/orchestration/customerService';
import { MailIcon, PhoneIcon, TinyTitleBold } from '@kvdbil/components';
import { requestErrorHandler } from '~/helpers/notifyError';
import { useTranslation } from '~/Locale';
import { OpeningHour } from '../interfaces/OpeningHours';
import styled from 'styled-components';
import {
  formatPhoneNumber,
  formatPhoneNumberForLink
} from '~/helpers/phoneNumber';
import { BodyTextSkeleton } from '~/App/views/Blog/components/BlogSkeletons';

dayjs.extend(calendar);

const ListItem = styled.li`
  font-weight: ${({ theme }) => theme.typography.fontBaseBoldWeight};
  display: flex;
  justify-content: space-between;
`;

const Cell = styled.div<{ textAlign?: 'left' | 'right'; isAlert?: boolean }>`
  color: ${({ isAlert, theme }) =>
    isAlert ? theme.colors.error.main : theme.colors.gray.light1};
  width: 100%;
  padding: 0.625em 0;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray.light3}`};
  text-align: ${({ textAlign }) => textAlign || 'left'};
`;

const IconLink = styled.a`
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.5rem;
    color: ${({ theme }) => theme.colors.gray.light1};
  }
`;

interface Props {
  days?: number;
  phone?: string;
  email?: string;
  onlyDays?: boolean;
}

const WeeklyCustomerService = ({
  days: amountDays,
  phone,
  email,
  onlyDays
}: Props) => {
  const { t } = useTranslation();
  const [days, setDays] = useState<OpeningHour[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let mounted = true;

    const currentDate = dayjs().format('YYYY-MM-DD');

    getOpeningHours(currentDate, amountDays)
      .then(data => {
        if (mounted && data?.length >= 0) {
          setDays(data);
        }
      })
      .catch(requestErrorHandler)
      .finally(() => setIsLoading(false));

    return () => {
      mounted = false;
    };
  }, [amountDays]);

  const calendarFormats = {
    sameDay: `[${t('Today')}]`,
    nextDay: `[${t('Tomorrow')}]`,
    nextWeek: 'dddd',
    sameElse: 'YYYY-MM-DD'
  };

  return (
    <div>
      {!onlyDays && <TinyTitleBold>{t('Customer Service')}</TinyTitleBold>}
      <div>
        <ul>
          {!onlyDays && (
            <>
              <ListItem>
                <Cell>
                  <IconLink
                    href={`tel:${formatPhoneNumberForLink(
                      phone ?? t('CUSTOMER_CONTACT_PHONE')
                    )}`}
                  >
                    <PhoneIcon />
                    <span>
                      {formatPhoneNumber(phone ?? t('CUSTOMER_CONTACT_PHONE'))}
                    </span>
                  </IconLink>
                </Cell>
              </ListItem>

              <ListItem>
                <Cell>
                  <IconLink
                    href={`mailto:${email ?? t('CUSTOMER_CONTACT_MAIL')}`}
                  >
                    <MailIcon />
                    <span>{email ?? t('CUSTOMER_CONTACT_MAIL')}</span>
                  </IconLink>
                </Cell>
              </ListItem>
            </>
          )}

          {isLoading &&
            new Array(amountDays ?? 8).fill('').map((_, index) => {
              return (
                <ListItem key={`skeleton-${index}`}>
                  <Cell>
                    <BodyTextSkeleton height={1.5} />
                  </Cell>
                </ListItem>
              );
            })}

          {!isLoading &&
            days &&
            days?.length >= 0 &&
            days.map(day => (
              <ListItem key={day.date.toISOString()}>
                <Cell>
                  {dayjs.isDayjs(day.date)
                    ? `${day.date.calendar(
                        null,
                        calendarFormats
                      )}, ${day.date.format('D/M')}`
                    : null}
                </Cell>
                {day.type === 'CLOSED' ? (
                  <Cell textAlign="right" isAlert>
                    {t('Customer Service Closed')}
                  </Cell>
                ) : (
                  <Cell textAlign="right">
                    {dayjs.isDayjs(day.open) && dayjs.isDayjs(day.close)
                      ? `${day.open.format('HH:mm')} - ${day.close.format(
                          'HH:mm'
                        )}`
                      : null}
                  </Cell>
                )}
              </ListItem>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default WeeklyCustomerService;
