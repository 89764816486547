import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { eatSnack } from '../shared/actions/snackbarActions';
import { Snack } from '../shared/interfaces/store/Snackbar';
import { snackbar } from '../shared/selectors/snackbarSelector';

import { Snackbar } from '@kvdbil/components';
import styled from 'styled-components';
import { useTranslation } from '~/Locale';

const StyledSnackbar = styled(Snackbar)`
  z-index: 500001;
`;

const Snackbars = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const snacks = useSelector(snackbar);

  const [activeSnack, setActiveSnack] = useState<Snack | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const [snackInStore] = snacks;

    if (!activeSnack && snackInStore) {
      setActiveSnack(snackInStore);
      dispatch(eatSnack(snackInStore.key));
      setIsOpen(true);
      return;
    }

    if (
      snackInStore &&
      activeSnack &&
      isOpen &&
      activeSnack.message === snackInStore.message
    ) {
      dispatch(eatSnack(snackInStore.key));
      return;
    }
  }, [isOpen, activeSnack, snacks, dispatch]);

  if (!activeSnack) {
    return null;
  }

  return (
    <StyledSnackbar
      data-testid={`snack-${activeSnack.key}`}
      {...activeSnack}
      message={t(activeSnack.message)}
      isOpen={isOpen}
      onClose={handleClose}
      onExited={() => setActiveSnack(null)}
    />
  );
};

export default Snackbars;
