import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { mq, resetButtonStyle, Spinner } from '@kvdbil/components';
import { translateRoute } from '~/App/shared/localization';
import { idToken as idTokenSelector } from '~/App/shared/selectors/sessionSelector';
import { requestErrorHandler } from '~/helpers/notifyError';
import { useHistory } from 'react-router';
import { useTranslation } from '~/Locale';
import { confirmUserSettings as confirmUserSettingsAction } from '~/App/shared/actions/session';
import { confirmUserSettings } from '~/helpers/orchestration/myPages';

const Container = styled.div`
  padding: 0.5rem 1rem;
  color: ${({ theme }) => theme.colors.text.light};
  background: ${({ theme }) => theme.colors.error.main};

  ${mq('tablet')} {
    padding: 1rem 2rem;
  }
`;
const Content = styled.div`
  max-width: 1024px;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TextButton = styled.button`
  ${resetButtonStyle};
  padding-left: 1rem;
  color: white;
  text-decoration: underline;
`;

export interface ConfirmUserSettingsMessageProps {
  message: string;
  confirmSettings?: typeof confirmUserSettings;
}
export const ConfirmUserSettingsMessage = ({
  message,
  confirmSettings = confirmUserSettings
}: ConfirmUserSettingsMessageProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const idToken = useSelector(idTokenSelector);
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);

    try {
      await confirmSettings({ idToken });
      dispatch(confirmUserSettingsAction());
    } catch (error: unknown) {
      requestErrorHandler(error);
    }

    setIsLoading(false);
  };

  const handleGotoSettings = async () => {
    await handleConfirm();

    history.push(
      translateRoute(t, '/ROUTES.MY_ACCOUNT/ROUTES.MY_ACCOUNT_SETTINGS')
    );
  };

  const handleConfirmClick = async () => {
    await handleConfirm();
  };

  return (
    <Container>
      <Content>
        <span dangerouslySetInnerHTML={{ __html: message }} />
        {isLoading ? (
          <div>
            <Spinner color="neutral" speed="fast" size="smaller" />
          </div>
        ) : (
          <div>
            <TextButton onClick={handleGotoSettings}>
              {t('Settings')}
            </TextButton>
            <TextButton onClick={handleConfirmClick}>{t('Confirm')}</TextButton>
          </div>
        )}
      </Content>
    </Container>
  );
};

export default ConfirmUserSettingsMessage;
