import { BodyText, Button, Message } from '@kvdbil/components';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { sessionStartSuccess } from '~/App/shared/actions/session';
import { useParamFromQuery } from '~/App/shared/hooks/useQueryParams';
import { trackGA4AccountCreated } from '~/helpers/client/ga4TrackEvent';
import { createMember } from '~/helpers/orchestration/member';
import { runWithRecaptcha } from '~/helpers/recaptcha';
import { regexEmail } from '~/helpers/regex';
import { formErrors } from '~/helpers/validation';
import { useTranslation } from '~/Locale';
import {
  CountryField,
  EmailField,
  FirstNameField,
  LastNameField,
  OrganisationNameField,
  OrganisationNumberField,
  PasswordField,
  VatNumberField
} from '../../../Fields/shared';
import { useRecaptcha } from '../../hooks/authHooks';
import CheckBoxWithControl from '../../../Fields/CheckBoxWithControl';
import { TermsCheckbox } from './TermsCheckbox';
import useLocalization from '~/App/shared/hooks/useLocalization';
import { RegistrationView } from '../types';
import { StyledCheckboxContainer } from './StyledCheckboxContainer';
import { NewsletterCheckboxes } from './NewsletterCheckboxes';
import { createEmailSubscriptionNames } from '~/App/shared/helpers/general';

const FormComponent = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: left;
`;

type Props = {
  changeView: (view: RegistrationView) => void;
};

export const CompanyRegistration = ({ changeView }: Props) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string | string[] | null>(null);
  const [hasEmail, setHasEmail] = useState(false);
  const { isSwedishLocale } = useLocalization();
  const dispatch = useDispatch();

  const emailFromParams = useParamFromQuery('email') ?? '';

  useRecaptcha();

  useEffect(() => {
    if (emailFromParams && regexEmail.test(emailFromParams)) {
      setHasEmail(true);
    } else {
      setHasEmail(false);
    }
  }, [emailFromParams]);

  const {
    handleSubmit,
    control,
    getValues,
    formState: { isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      acceptsMinimumAgeRequirement: false,
      isCarDealer: false,
      country: isSwedishLocale
        ? { value: 'se', label: t('SWEDEN') }
        : { value: '', label: '' },
      organisationName: '',
      vatNumber: '',
      organisationNumber: '',
      email: emailFromParams,
      password: '',
      personalCarSubscription: true,
      heavyVehiclesSubscription: true,
      corporateSubscription: true
    }
  });

  const onSubmit = useCallback(
    (googleResponse: string) => {
      const values = getValues();

      const country = values?.country?.value;
      const isSwedish = country === 'se';

      const organisationNumberObject = !isSwedish
        ? {
            vatNumber: values.vatNumber,
            organisationNumber: values.organisationNumber
          }
        : {
            organisationNumber: values.organisationNumber
          };

      const emailSubscriptionNames = createEmailSubscriptionNames(
        values.personalCarSubscription,
        values.heavyVehiclesSubscription,
        values.corporateSubscription
      );

      const memberData = {
        person: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          acceptsMinimumAgeRequirement: values.acceptsMinimumAgeRequirement,
          isCarDealer: values.isCarDealer,
          password: values.password
        },
        organisation: {
          name: values.organisationName,
          ...organisationNumberObject
        },
        termsAccepted: true,
        addresses: [{ country }],
        emailSubscriptionNames
      };

      createMember({
        member: memberData,
        gRecaptchaResponse: googleResponse
      })
        .then(data => {
          trackGA4AccountCreated({
            user_id: data.member.id,
            user_type: 'company',
            is_car_dealer: data.member.person.isCarDealer
          });

          setLoading(false);

          dispatch(sessionStartSuccess(data));

          if (data?.member?.person?.organisation?.isSoleProprietor) {
            changeView('BANKID_VERIFY');
          } else {
            changeView('COMPLETED');
          }
        })
        .catch((error: Record<string, string>[]) => {
          const errorMessage = formErrors(
            { errors: error },
            'response.data.message',
            true
          );
          setError(errorMessage[0]);
          setLoading(false);
        });
    },
    [getValues, dispatch, changeView]
  );

  const submitWithRecaptcha = useCallback(() => {
    setLoading(true);
    setError(null);

    runWithRecaptcha(googleResponse => onSubmit(googleResponse));
  }, [onSubmit, setLoading, setError]);

  const country = useWatch({ control, name: 'country' });

  return (
    <div>
      <FormComponent onSubmit={handleSubmit(submitWithRecaptcha)} noValidate>
        <FirstNameField control={control} t={t} />

        <LastNameField control={control} t={t} />

        <OrganisationNameField control={control} t={t} />

        <CountryField control={control} t={t} />

        <OrganisationNumberField
          control={control}
          t={t}
          country={country?.value}
        />

        {country?.value !== 'se' && <VatNumberField control={control} t={t} />}

        <EmailField isDisabled={hasEmail} control={control} t={t} />

        <PasswordField control={control} t={t} />

        <StyledCheckboxContainer direction="column" gap={2}>
          <CheckBoxWithControl
            data-testid="register-cardealer"
            control={control}
            name="isCarDealer"
            label={<BodyText as="span">{t('I am a car dealer')}</BodyText>}
            color="info"
          />

          <TermsCheckbox control={control} />
        </StyledCheckboxContainer>

        <NewsletterCheckboxes control={control} isCompany />

        {error && (
          <Message fullWidth withIcon type="error">
            {error}
          </Message>
        )}

        <Button
          size="regular"
          fullWidth
          isLoading={isLoading}
          data-test="register-submit"
          isDisabled={!isValid}
        >
          {t('Register')}
        </Button>
      </FormComponent>
    </div>
  );
};
