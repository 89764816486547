import dayjs from 'dayjs';
import { TimeSlot } from '~/App/shared/types/TimeSlot';
import { orchestration } from '~/config/public';
import axios from './axiosWithAuthHandling';

interface GetAvailablePickupTimeslotsInput {
  orderId: number;
  idToken: string;
  authToken?: string;
}
export interface GetAvailablePickupTimeslotsResponse {
  availableTimeslots: Record<string, TimeSlot[]>;
}

export const getAvailablePickupTimeslots = ({
  orderId,
  idToken,
  authToken
}: GetAvailablePickupTimeslotsInput) => {
  const startDate = dayjs().format('YYYY-MM-DD');
  const endDate = dayjs().add(14, 'day').format('YYYY-MM-DD');

  return axios.get<GetAvailablePickupTimeslotsResponse>(
    `${orchestration.url}/delivery/orders/${orderId}/available-pickup-timeslots?endDate=${endDate}&startDate=${startDate}`,
    {
      withCredentials: !authToken,
      headers: {
        Authorization: !authToken && `Bearer ${idToken}`
      },
      params: { authToken }
    }
  );
};

export interface BookPickupTimeslotInput {
  orderId: number;
  authToken?: string;
  timeslot: TimeSlot;
}

export const bookPickupTimeslotWithAuthToken = ({
  orderId,
  authToken,
  timeslot
}: BookPickupTimeslotInput) => {
  return axios.post<{ timeslot: TimeSlot }>(
    `${orchestration.url}/delivery/orders/${orderId}/book-pickup-timeslot`,
    {
      authToken,
      timeslot
    },
    {
      responseType: 'json',
      withCredentials: true
    }
  );
};
