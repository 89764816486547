/* TODO: [1MP]  This file should only be used for helpers/utiles.
                Untill the merge of One marketplace has been done.
                All this functions should later be removed.
*/
import { AuctionType } from '~/App/shared/interfaces/Auction';

enum StateKeys {
  BIDDING = 'bidding',
  FIXED_PRICE = 'fixedPrice',
  BUY_CAR = 'buyCar'
}

interface GetStateKeysFromBooleansArgs {
  isBiddingSearch: boolean;
  isFixedPriceSearch: boolean;
}

export const getStateKeysFromBooleans = ({
  isBiddingSearch,
  isFixedPriceSearch
}: GetStateKeysFromBooleansArgs): StateKeys => {
  if (isFixedPriceSearch) {
    return StateKeys.FIXED_PRICE;
  }

  if (isBiddingSearch) {
    return StateKeys.BIDDING;
  }

  return StateKeys.BUY_CAR;
};

enum UniqueIdentifier {
  BIDDING = 'Bidding',
  FIXED_PRICE = 'FixedPrice',
  BUY_CAR = 'BuyCar'
}

interface GetUniqueIdentifierFromBooleansArgs {
  isFixedPrice: boolean;
  isBidding: boolean;
}

export const getUniqueIdentifierFromBooleans = ({
  isFixedPrice,
  isBidding
}: GetUniqueIdentifierFromBooleansArgs): UniqueIdentifier => {
  if (isFixedPrice) {
    return UniqueIdentifier.FIXED_PRICE;
  }

  if (isBidding) {
    return UniqueIdentifier.BIDDING;
  }

  return UniqueIdentifier.BUY_CAR;
};

export const auctionTypeIsBidding = (
  auctionType?: AuctionType | null
): boolean => auctionType === AuctionType.BIDDING;

export const auctionTypeIsFixedPrice = (
  auctionType?: AuctionType | null
): boolean => auctionType === AuctionType.BUY_NOW;
