import { always, append, evolve, F, propEq, reject, T } from 'ramda';
import {
  RemoveFavouriteAuctionAction,
  StoreFavouriteAuctionAction
} from '~/App/shared/actions/favouriteAuctions';
import { GetMyFavouriteAuctionsAction } from '../actions/auctions';

import Auction from '../interfaces/Auction';

const initialState = {
  auctions: [] as Auction[],
  loading: false as boolean
};

export const mySavedAuctions = (
  state = initialState,
  action:
    | GetMyFavouriteAuctionsAction
    | StoreFavouriteAuctionAction
    | RemoveFavouriteAuctionAction
) => {
  switch (action.type) {
    case 'REMOVE_FAVOURITE_AUCTION_SUCCESS':
      return evolve(
        {
          loading: F,
          auctions: reject(propEq('id', action.payload))
        },
        state
      );
    case 'STORE_FAVOURITE_AUCTION_SUCCESS':
      return evolve(
        {
          auctions: append(action.payload.auction)
        },
        state
      );
    case 'GET_MY_FAVOURITE_AUCTIONS_SUCCESS':
      return evolve(
        {
          loading: F,
          auctions: always(action.payload)
        },
        state
      );
    case 'GET_MY_FAVOURITE_AUCTIONS_REQUEST':
      return evolve(
        {
          loading: T
        },
        state
      );
    default:
      return state;
  }
};

export default mySavedAuctions;
