import { ReactNode, ReactPortal, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

const usePortal = (id: string) => {
  const rootElemRef = useRef<HTMLElement | null>(null);

  const createRootElement = (id: string): HTMLElement => {
    const rootContainer = document.createElement('div');
    rootContainer.setAttribute('id', id);
    return rootContainer;
  };

  const addRootElement = (rootElem: HTMLElement) => {
    if (document.body.lastElementChild) {
      document.body.insertBefore(
        rootElem,
        document.body.lastElementChild.nextElementSibling
      );
    }
  };

  const getRootElem = () => {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div');
    }
    return rootElemRef.current;
  };

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const existingParent: HTMLElement | null = document.querySelector(`#${id}`);
    const parentElem = existingParent || createRootElement(id);

    if (!existingParent) {
      addRootElement(parentElem);
    }

    parentElem.appendChild(rootElemRef.current as HTMLElement);

    return () => {
      if (rootElemRef.current) {
        rootElemRef.current.remove();
        if (!parentElem.childElementCount) {
          parentElem.remove();
        }
      }
    };
  }, [id]);

  return getRootElem();
};

export const Portal = ({ children }: { children: ReactNode }): ReactPortal => {
  const target = usePortal('portal-root');
  return createPortal(children, target);
};

export const TooltipPortal = ({
  children
}: {
  children: ReactNode;
}): ReactPortal => {
  const target = usePortal('tooltip-portal-root');
  return createPortal(children, target);
};
