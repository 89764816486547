import pino from 'pino';
import { isProduction, isServer } from '~/config/public/environment';

const config = {
  transport: {
    target: 'pino-pretty',
    options: {
      colorize: true
    }
  }
};

export default (() => {
  // if is server side
  if (isServer) {
    return !isProduction ? pino(config) : pino();
  }

  // if is client side
  return console;
})();
