import React, { useCallback, useState } from 'react';
import {
  Button,
  Message,
  Stack,
  TinyTitleBold,
  Title
} from '@kvdbil/components';
import { SignInForm } from './SignInForm';
import { useTranslation } from '~/Locale';
import styled, { css } from 'styled-components';
import { SignInOption } from '../types';
import { SignInOptionButtons } from './SignInOptionButtons';
import {
  BankIdAuthResponse,
  postBankIdLogin
} from '~/helpers/orchestration/auth';
import { AxiosError } from 'axios';
import { useBankIdAuth } from '../../hooks/bankIdAuthHooks';
import { BankIdAuth } from '../../components/BankIdAuth';
import AuthCloseButton from '../../components/AuthCloseButton';
import useLocalization from '~/App/shared/hooks/useLocalization';
import BankIdAuthButton from '../../components/BankIdAuthButton';
import { startSession } from '../../helpers';
import { Session } from '~/App/shared/interfaces/store/Session';
import { useDispatch } from 'react-redux';

const StyledButton = styled(Button)`
  gap: 1rem;
`;

const CreateAccountButton = styled(StyledButton)`
  padding: 1rem 1.5rem;
`;

const centerText = css`
  text-align: center;
`;

const StyledTinyTitleBold = styled(TinyTitleBold)`
  ${centerText}
`;

const StyledTitle = styled(Title)`
  ${centerText}
`;

type Props = {
  onSignIn?(): void;
  onPressSignInBankid?(): void;
  onBankIdAuthComplete?(data: BankIdAuthResponse): void;
  onBankIdClose?(): void;
  onPressCreateAccount?(): void;
  onPressForgotPassword(email?: string): void;
  onFail?(error: string): void;
  onClose?(): void;
  withNoTitle?: boolean;
  errorMessage?: string | null;
  warningMessage?: string | null;
  forceDisableBankId?: boolean;
  initialEmail?: string;
};

export const SignIn = ({
  onPressSignInBankid,
  onBankIdAuthComplete,
  onBankIdClose,
  onPressCreateAccount,
  onPressForgotPassword,
  onSignIn,
  onClose,
  withNoTitle = false,
  errorMessage = null,
  warningMessage = null,
  forceDisableBankId = false,
  initialEmail
}: Props) => {
  const { t } = useTranslation();
  const { isSwedishLocale } = useLocalization();
  const [error, setError] = useState<string | null>(errorMessage);
  const [warning, setWarning] = useState<string | null>(warningMessage);
  const dispatch = useDispatch();

  const {
    qrCode,
    autoStartToken,
    orderRef,
    setData: setBankIdData,
    bankIdAuthStart,
    isLoading: isBankIdAuthStartLoading
  } = useBankIdAuth();

  const [selectedSignInOption, setSelectedSignInOption] =
    useState<SignInOption>(
      isSwedishLocale && !forceDisableBankId ? 'BANKID' : 'EMAIL'
    );

  const clearData = useCallback(() => {
    setError(null);
    setWarning(null);
    setBankIdData(null);
  }, [setBankIdData]);

  const handleOnPressEmailOption = useCallback(() => {
    setSelectedSignInOption('EMAIL');
    clearData();
  }, [clearData]);

  const handleOnPressBankIdOption = useCallback(() => {
    setSelectedSignInOption('BANKID');
    clearData();
  }, [clearData]);

  const handleOnPressFacebookOption = useCallback(() => {
    setSelectedSignInOption('FACEBOOK');
    clearData();
  }, [clearData]);

  const handleOnBankIdClose = useCallback(() => {
    setBankIdData(null);
    onBankIdClose?.();
  }, [setBankIdData, onBankIdClose]);

  const handleBankIdFail = useCallback(
    (e: unknown) => {
      const defaultError = t('Something went wrong');

      if (error) {
        return;
      }

      if (e instanceof AxiosError) {
        setError(e.response?.data?.message ?? defaultError);
      } else if (e instanceof Error) {
        setError(e.message || defaultError);
      } else {
        setError((e as string) || defaultError);
      }

      handleOnBankIdClose();
    },
    [t, error, handleOnBankIdClose]
  );

  const handleBankIdAuthButtonOnError = useCallback(() => {
    handleBankIdFail('Failed to start BankID authentication');
  }, [handleBankIdFail]);

  const handleBankIdAuthButtonOnClick = useCallback(() => {
    clearData();
    onPressSignInBankid?.();

    // Trigger Auth Start
    void bankIdAuthStart({
      onComplete: onBankIdAuthComplete,
      onError: handleBankIdAuthButtonOnError
    });
  }, [
    bankIdAuthStart,
    clearData,
    handleBankIdAuthButtonOnError,
    onBankIdAuthComplete,
    onPressSignInBankid
  ]);

  const signIn = useCallback(
    async orderRef => {
      try {
        if (orderRef) {
          const response = await postBankIdLogin(orderRef);

          if (
            response.data.auth.idToken &&
            response.data.auth.refreshToken &&
            response.data.member
          ) {
            await startSession({
              dispatch,
              t,
              session: {
                auth: response.data.auth,
                member: response.data.member
              } as Session
            });

            onSignIn?.();
          } else {
            throw Error(
              t(
                'Something went wrong while authenticating with BankID, try again please'
              )
            );
          }
        }
      } catch (error: unknown) {
        handleBankIdFail?.(error);
      }
    },
    [dispatch, handleBankIdFail, onSignIn, t]
  );

  if (qrCode && autoStartToken && orderRef && !error) {
    return (
      <BankIdAuth
        initialQrCode={qrCode}
        autoStartToken={autoStartToken}
        orderRef={orderRef}
        onFail={handleBankIdFail}
        onClose={handleOnBankIdClose}
        onComplete={signIn}
      />
    );
  }

  return (
    <>
      {onClose && <AuthCloseButton onClose={onClose} />}
      <Stack rowGap={3} justify={'center'} align={'center'}>
        {!withNoTitle && (
          <StyledTitle data-testid="sign-in-title">{t('Sign in')}</StyledTitle>
        )}

        {error && (
          <Message fullWidth type="error">
            {error}
          </Message>
        )}

        {warning && (
          <Message fullWidth type="warning">
            {warning}
          </Message>
        )}

        {selectedSignInOption === 'BANKID' && (
          <BankIdAuthButton
            onClick={handleBankIdAuthButtonOnClick}
            isLoading={isBankIdAuthStartLoading}
          />
        )}

        {selectedSignInOption === 'EMAIL' && (
          <SignInForm
            onSignIn={onSignIn}
            onFail={setError}
            onPressForgotPassword={onPressForgotPassword}
            initialEmail={initialEmail}
          />
        )}

        <SignInOptionButtons
          activeSignInOption={selectedSignInOption}
          onPressBankId={handleOnPressBankIdOption}
          onPressEmail={handleOnPressEmailOption}
          onPressFacebook={handleOnPressFacebookOption}
          forceDisableBankId={forceDisableBankId}
        />

        {onPressCreateAccount && (
          <Stack rowGap={0.5} align={'center'}>
            <StyledTinyTitleBold>
              {t('Missing an account?')}
            </StyledTinyTitleBold>
            <CreateAccountButton
              variant="solid"
              color="secondary"
              onClick={onPressCreateAccount}
            >
              {t('Create an account')}
            </CreateAccountButton>
          </Stack>
        )}
      </Stack>
    </>
  );
};
