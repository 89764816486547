import { regexOnlyDigits } from '~/helpers/regex';
import { log } from '~/helpers/bugsnagHelper';

export const maskNationalIdentificationNumber = (
  identificationNumber: string
) => {
  if (typeof identificationNumber !== 'string') {
    log(
      new Error(
        `Unexpected identificationNumber type. Expects string but got ${typeof identificationNumber}.`
      )
    );
    return '';
  }

  const idNumberWithoutHyphen = identificationNumber.replace('-', '');
  const idNumberLength = idNumberWithoutHyphen.length;
  if (
    regexOnlyDigits.test(idNumberWithoutHyphen) &&
    (idNumberLength === 10 || idNumberLength === 12)
  ) {
    return `${idNumberWithoutHyphen.slice(0, -4)} - ****`;
  }

  return '';
};
