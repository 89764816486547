import dayjs from 'dayjs';
import { TranslateFunction } from '~/Locale';
import {
  ArticlesByCategoryAndSlugWithRichTextQuery,
  InlineimageRecord
} from '~/config/generated/graphql';
import React from 'react';

export const blogDateFormat = (date: string, t: TranslateFunction) =>
  dayjs(date).isValid() ? dayjs(date).format('D MMMM, YYYY') : t('Unpublished');

export const getArticleStructuredData = (
  article?: ArticlesByCategoryAndSlugWithRichTextQuery['article']
) => {
  const blocks = (article?.body?.blocks ?? []) as InlineimageRecord[];

  const allImageSrcs = blocks
    .map(block => block?.image?.responsiveImage?.src)
    .filter(src => Boolean(src));

  const ldJson = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: article?.title ?? '',
    image: [
      article?.articleimage?.responsiveImage?.src,
      ...allImageSrcs
    ].filter(src => Boolean(src)),
    datePublished: article?._firstPublishedAt ?? '',
    dateModified: article?._publishedAt ?? '',
    author: [
      {
        '@type': 'Organisation',
        name: 'Kvd',
        url: 'https://kvd.se'
      }
    ]
  };

  return <script type="application/ld+json">{JSON.stringify(ldJson)}</script>;
};
