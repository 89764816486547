import React from 'react';
import styled from 'styled-components';
import { Headline, mq, TinyTitleBold } from '@kvdbil/components';
import Link from '~/App/shared/components/Link';
import { TranslateFunction, useTranslation } from '~/Locale';
import { useCmsData } from '~/App/shared/hooks/useCmsData';
import {
  CarTreeAllBrandsDocument,
  CarTreeAllBrandsQuery,
  FilterPageDocument,
  FilterPageQuery
} from '~/config/generated/graphql';
import {
  CarTreeAllBrandsData,
  FilterPageData
} from '~/App/shared/interfaces/store/CmsData';
import StatusPageLoading from '~/App/shared/components/StatusPageLoading';
import Section from '~/App/shared/components/Layout/Section';
import { MainCategory } from '~/App/shared/types/MainCategoryTypes';
import { getPathFromMainCategory } from '~/helpers/filterTranslation';

const getTranslatedTitle = (
  t: TranslateFunction,
  mainCategory?: MainCategory
) => {
  switch (mainCategory) {
    case 'car':
      return t('Car Brands');
    case 'light_transport':
      return t('Light Transport Brands');
    case 'heavy_transport':
      return t('Heavy Transport Brands');
    case 'machines':
      return t('Machine Brands');
    case 'recreational_vehicle':
      return t('Recreational Vehicle Brands');
    default:
      return t('More Brands');
  }
};

const Heading = styled(Headline)`
  text-align: center;
  margin-bottom: 2rem;
`;

const List = styled.ul<{ $brandsCount: number }>`
  display: grid;
  row-gap: 0.75rem;
  grid-auto-flow: column;
  justify-content: space-between;
  grid-template-rows: repeat(
    ${({ $brandsCount }) => Math.ceil($brandsCount / 2)},
    max-content
  );
  padding: 0 1rem;

  ${mq('mobileL')} {
    grid-template-columns: repeat(3, max-content);
    grid-template-rows: repeat(
      ${({ $brandsCount }) => Math.ceil($brandsCount / 3)},
      max-content
    );
  }

  ${mq('tablet')} {
    grid-template-columns: repeat(4, max-content);
    grid-template-rows: repeat(
      ${({ $brandsCount }) => Math.ceil($brandsCount / 4)},
      max-content
    );
    padding: 0;
  }
`;

const StyledLink = styled(Link)`
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.typography.fontBaseBoldWeight};
`;

export const AllBrandsSection = ({
  mainCategory
}: {
  mainCategory: MainCategory;
}) => {
  const { t } = useTranslation();
  const { data: filterPageCmsData } = useCmsData<
    FilterPageQuery,
    FilterPageData
  >({
    storeKey: 'filterPage',
    storeNestedKey: mainCategory,
    query: FilterPageDocument,
    options: {
      variables: {
        mainCategory: mainCategory
      }
    }
  });

  const { data: cmsData, fetching } = useCmsData<
    CarTreeAllBrandsQuery,
    CarTreeAllBrandsData
  >({
    storeKey: 'carTreeAllBrands',
    storeNestedKey: mainCategory,
    query: CarTreeAllBrandsDocument,
    options: {
      variables: {
        filterPage: filterPageCmsData?.id
      },
      pause: !filterPageCmsData?.id
    }
  });

  const url = getPathFromMainCategory(t, mainCategory);

  if (cmsData?.length === 0) {
    return null;
  }

  return (
    <Section
      maxWidth={64}
      spacingTop={3.5}
      spacingBottom={3.5}
      backgroundColor="light"
      data-testid="all-brands-section"
    >
      {!fetching && (
        <Heading as="h2">{getTranslatedTitle(t, mainCategory)}</Heading>
      )}
      {fetching && <StatusPageLoading minHeight={15} unit="rem" />}
      {!fetching && cmsData?.length > 0 && (
        <List $brandsCount={cmsData.length}>
          {cmsData?.map(({ name, slug }) => (
            <TinyTitleBold as="li" key={name}>
              <StyledLink to={`${url}/${slug}`}>{name}</StyledLink>
            </TinyTitleBold>
          ))}
        </List>
      )}
    </Section>
  );
};

export default AllBrandsSection;
