import React from 'react';
import ContainerWithImage from '~/App/shared/components/ProductCard/components/ContainerWithImage';
import Title from '~/App/shared/components/ProductCard/components/Title';
import Financing from '~/App/shared/components/ProductCard/components/Financing';
import Auction from '~/App/shared/interfaces/Auction';
import { getAuctionLink } from '~/helpers/auction';
import Link from '~/App/shared/components/Link';
import useProductCardBaseProps from '~/App/shared/components/ProductCard/hooks/useProductCardBaseProps';
import { CarGuideCardProps } from '~/App/views/Carguide/types';
import CarGuideMatchingInfo from '~/App/shared/components/ProductCard/components/CarGuideMatchingInfo';
import CarGuideMatchingScore from '~/App/shared/components/ProductCard/components/CarGuideMatchingScore';
import Subtitle from '~/App/shared/components/ProductCard/components/Subtitle';
import useProductCardIconsProps from '~/App/shared/components/ProductCard/hooks/useProductCardIconsProps';
import Icons from '~/App/shared/components/ProductCard/components/Icons';

export interface ProductCardCarGuideProps {
  auction: Auction & { carGuideCardProps?: CarGuideCardProps };
}

const ProductCardCarGuide = ({ auction }: ProductCardCarGuideProps) => {
  const { imageProps, financingProps } = useProductCardBaseProps(auction);
  const {
    isCertified,
    isEcoFriendly,
    isReleasable,
    isFavourite,
    toggleFavourite
  } = useProductCardIconsProps(auction);
  const subtitle =
    auction.processObject.heading2 ?? auction.processObject.heading3 ?? '';

  return (
    <Link to={getAuctionLink(auction)} data-testid="product-card">
      <ContainerWithImage {...imageProps} actionOpenedAt={auction.openedAt}>
        <Icons
          isCertified={isCertified}
          isEcoFriendly={isEcoFriendly}
          isFavourite={isFavourite}
          isReleasable={isReleasable}
          toggleFavourite={toggleFavourite}
        />

        {auction?.carGuideCardProps && (
          <CarGuideMatchingScore score={auction.carGuideCardProps.score} />
        )}

        <Title>{auction.processObject.heading1}</Title>
        <Subtitle>{subtitle}</Subtitle>

        <Financing {...financingProps} />

        {auction?.carGuideCardProps && (
          <CarGuideMatchingInfo carGuideCardProps={auction.carGuideCardProps} />
        )}
      </ContainerWithImage>
    </Link>
  );
};

export default ProductCardCarGuide;
