import React from 'react';
import { Path } from 'react-hook-form';
import TextFieldWithControl from '~/App/shared/components/Fields/TextFieldWithControl';
import { TextFieldProps } from '../types';
import { LAST_NAME_MAX_LENGTH } from '~/App/shared/validation/constants';

const LastNameField = <FormState,>({
  control,
  t,
  name,
  rules,
  ...props
}: TextFieldProps<FormState>) => {
  const validationRules = {
    required: {
      value: true,
      message: t(`Last name can't be blank`)
    },
    maxLength: {
      value: LAST_NAME_MAX_LENGTH,
      message: t(
        'Last name is too long (maximum is %s characters)',
        LAST_NAME_MAX_LENGTH
      )
    },
    ...rules
  };

  const fieldName = name ?? ('lastName' as Path<FormState>);

  return (
    <TextFieldWithControl
      {...props}
      control={control}
      name={fieldName}
      label={t('Last name')}
      autoComplete="family-name"
      rules={validationRules}
    />
  );
};

export default LastNameField;
