import { compose, dissoc } from 'ramda';
import { loginFlowStart } from '~/App/shared/actions/loginFlowActions';
import {
  idToken,
  isAuthenticated,
  isHydratedSelector
} from '~/App/shared/selectors/sessionSelector';
import {
  deleteGuestSavedSearch,
  deleteMySavedSearch,
  editSavedSearch as editSavedSearchCall,
  getMySavedSearches,
  saveSearch as saveSearchCall,
  saveSearchGuest
} from '~/helpers/orchestration/myPages';
import { sendViewSavedSearch } from '~/helpers/orchestration/notifications';
import { runWithRecaptcha } from '~/helpers/recaptcha';
import { createSearchName, normalizeSearchObject } from '~/helpers/searches';
import { ReduxDispatch } from '../interfaces/Redux';
import { SavedSearch } from '../interfaces/SavedSearch';
import { ReduxStore } from '../interfaces/store';
import { AxiosError } from 'axios';
import { TranslateFunction } from '~/Locale';
import {
  getGAUserFromState,
  trackGA4NewsletterSignup,
  trackGA4SaveSearch
} from '~/helpers/client/ga4TrackEvent';

export const SAVE_SEARCH_REQUEST = 'SAVE_SEARCH_REQUEST';
const saveSearchRequest = (payload: SavedSearch) => ({
  type: SAVE_SEARCH_REQUEST,
  payload
});

export const SAVE_SEARCH_SUCCESS = 'SAVE_SEARCH_SUCCESS';
const saveSearchSuccess = (payload: SavedSearch) => ({
  type: SAVE_SEARCH_SUCCESS,
  payload
});

type SavedSearchError = unknown | Error | AxiosError;

export const SAVE_SEARCH_FAILURE = 'SAVE_SEARCH_FAILURE';
const saveSearchFailure = (payload: SavedSearchError) => ({
  type: SAVE_SEARCH_FAILURE,
  payload
});

export const saveSearch =
  (
    t: TranslateFunction,
    search: SavedSearch,
    email: string,
    navigateCallback: CallableFunction,
    addToNewsletter?: boolean
  ) =>
  (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();

    const storeToken = idToken(state);
    const storeAuthenticated = isAuthenticated(state);
    if (!storeAuthenticated && !email) {
      const { pathname, search: searchStr } = window.location;
      const origin = searchStr ? `${pathname}${searchStr}` : pathname;

      dispatch(
        loginFlowStart(t, {
          origin,
          callback: () => {
            dispatch(
              saveSearch(
                t,
                search,
                email,
                navigateCallback,
                addToNewsletter
              )(dispatch, getState)
            );
          },
          navigateCallback
        })
      );
      return { type: '@UNUSED' };
    }

    const searchQuery: SavedSearch = compose(dissoc('auctionType'))(search);
    const existingName = search.name ?? null;
    const searchName = existingName || createSearchName(t, search);
    dispatch(saveSearchRequest(searchQuery));

    const ga4User = getGAUserFromState(state);
    const isHydrated = isHydratedSelector(state);

    if (storeAuthenticated) {
      saveSearchCall({
        idToken: storeToken,
        savedSearch: {
          ...searchQuery,
          name: searchName,
          filters: searchQuery
        }
      })
        .then(result => {
          dispatch(saveSearchSuccess(result));
          isHydrated && trackGA4SaveSearch(ga4User);
        })
        .catch((err: SavedSearchError) => {
          dispatch(saveSearchFailure(err));
        });
    } else {
      runWithRecaptcha(googleResponse => {
        saveSearchGuest({
          savedSearch: {
            ...searchQuery,
            name: searchName,
            filters: searchQuery
          },
          email,
          gRecaptchaResponse: googleResponse,
          addToNewsletter: addToNewsletter
        })
          .then(result => {
            if (addToNewsletter && isHydrated) {
              trackGA4NewsletterSignup(ga4User);
            }
            dispatch(saveSearchSuccess(result));
            isHydrated && trackGA4SaveSearch(ga4User);
          })
          .catch((err: SavedSearchError) => {
            dispatch(saveSearchFailure(err));
          });
      });
    }

    return { type: '@UNUSED' };
  };

export const REMOVE_SAVED_SEARCH_REQUEST = 'REMOVE_SAVED_SEARCH_REQUEST';
export const REMOVE_SAVED_SEARCH_SUCCESS = 'REMOVE_SAVED_SEARCH_SUCCESS';
export const REMOVE_SAVED_SEARCH_FAILURE = 'REMOVE_SAVED_SEARCH_FAILURE';
export const removeSavedSearchRequest = () => ({
  type: REMOVE_SAVED_SEARCH_REQUEST
});

export const removeSavedSearchSuccess = (searchId: string) => ({
  type: REMOVE_SAVED_SEARCH_SUCCESS,
  payload: searchId
});

export const EDIT_SAVED_SEARCH_REQUEST = 'EDIT_SAVED_SEARCH_REQUEST';
export const EDIT_SAVED_SEARCH_SUCCESS = 'EDIT_SAVED_SEARCH_SUCCESS';
export const EDIT_SAVED_SEARCH_FAILURE = 'EDIT_SAVED_SEARCH_FAILURE';
export const editSavedSearch =
  (search: SavedSearch) =>
  (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const token = idToken(getState());
    dispatch({ type: EDIT_SAVED_SEARCH_REQUEST, payload: search });

    const saveSearch = normalizeSearchObject(search);

    editSavedSearchCall({ idToken: token, search: saveSearch })
      .then(res => dispatch({ type: EDIT_SAVED_SEARCH_SUCCESS, payload: res }))
      .catch((err: SavedSearchError) =>
        dispatch({ type: EDIT_SAVED_SEARCH_FAILURE, payload: err })
      );
  };

export const FETCH_SAVED_SEARCHES_REQUEST = 'FETCH_SAVED_SEARCHES_REQUEST';
export const FETCH_SAVED_SEARCHES_SUCCESS = 'FETCH_SAVED_SEARCHES_SUCCESS';
export const FETCH_SAVED_SEARCHES_FAILURE = 'FETCH_SAVED_SEARCHES_FAILURE';
export const fetchSavedSearchesSuccess = ({
  savedSearches
}: {
  savedSearches: SavedSearch[];
}) => ({
  type: FETCH_SAVED_SEARCHES_SUCCESS,
  payload: savedSearches
});
export const fetchSavedSearchesFailure = () => ({
  type: FETCH_SAVED_SEARCHES_FAILURE
});

const fetchSavedSearches =
  ({ idToken }: { idToken: string }) =>
  async (dispatch: ReduxDispatch) => {
    dispatch({ type: FETCH_SAVED_SEARCHES_REQUEST });

    return await getMySavedSearches({ idToken: idToken })
      .then(savedSearches => {
        dispatch(
          fetchSavedSearchesSuccess({
            savedSearches: savedSearches
          })
        );
      })
      .catch(() => {
        dispatch(fetchSavedSearchesFailure());
      });
  };

export const removeSavedSearch =
  (savedSearch: SavedSearch, isLoggedIn: boolean) =>
  (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();
    const token = idToken(state);
    dispatch(removeSavedSearchRequest());

    if (isLoggedIn) {
      deleteMySavedSearch({ idToken: token, searchId: savedSearch.id })
        .then(() => {
          dispatch(removeSavedSearchSuccess(savedSearch.id));
        })
        .catch(() => {
          dispatch({ type: REMOVE_SAVED_SEARCH_FAILURE });
        });
    } else {
      runWithRecaptcha(googleResponse => {
        deleteGuestSavedSearch({
          searchId: savedSearch.id,
          memberId: savedSearch.memberId,
          gRecaptchaResponse: googleResponse
        })
          .then(() => {
            dispatch(removeSavedSearchSuccess(savedSearch.id));
          })
          .catch(() => {
            dispatch({ type: REMOVE_SAVED_SEARCH_FAILURE });
          });
      });
    }
  };

export const VIEW_SAVED_SEARCH_REQUEST = 'VIEW_SAVED_SEARCH_REQUEST';
export const VIEW_SAVED_SEARCH_SUCCESS = 'VIEW_SAVED_SEARCH_SUCCESS';
export const VIEW_SAVED_SEARCH_FAILURE = 'VIEW_SAVED_SEARCH_FAILURE';
export const viewSavedSearch =
  (savedSearchId: string) =>
  (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const storeToken = idToken(getState());

    dispatch({ type: VIEW_SAVED_SEARCH_REQUEST });

    sendViewSavedSearch({ idToken: storeToken, savedSearchId })
      .then(res => {
        dispatch({
          type: VIEW_SAVED_SEARCH_SUCCESS,
          payload: res.data.savedSearchId
        });
      })
      .catch((err: SavedSearchError) => {
        dispatch({ type: VIEW_SAVED_SEARCH_FAILURE, payload: err });
      });
  };

export { saveSearchSuccess, saveSearchRequest, fetchSavedSearches };
