import React from 'react';
import { Switch, Route, matchPath, match } from 'react-router';
import { RouteComponentProps } from '~/App/shared/interfaces/routes';
import { RouteConfig } from '~/config/routes/types';

export const renderRoutes = (
  routes?: RouteConfig[],
  extraProps: Record<string, unknown> = {}
) => {
  return routes ? (
    <Switch>
      {routes.map((route, i) => (
        <Route
          key={route.key || i}
          path={route.path}
          exact={route.exact}
          strict={route.strict}
          render={(props: RouteComponentProps) =>
            route.render
              ? route.render({ ...props, ...extraProps, route: route })
              : route.component && (
                  <route.component {...props} {...extraProps} route={route} />
                )
          }
        />
      ))}
    </Switch>
  ) : null;
};

export type Branch = {
  route: RouteConfig;
  match: match;
};

export const matchRoutes = (
  routes: RouteConfig[],
  pathname: string,
  branch: Branch[] = []
) => {
  routes.some(route => {
    const match = route.path
      ? matchPath(pathname, route)
      : branch.length
      ? branch[branch.length - 1].match // use parent match
      : { path: '/', url: '/', params: {}, isExact: pathname === '/' };

    if (match) {
      branch.push({ route, match });

      if (route.routes) {
        matchRoutes(route.routes, pathname, branch);
      }
    }

    return match;
  });

  return branch;
};
