import { useState, useEffect } from 'react';

export const isDOM =
  typeof window !== 'undefined' &&
  window.document &&
  window.document.documentElement;

const isVisible = () => {
  return Boolean(isDOM && document.visibilityState === 'visible');
};

export const useDocumentVisible = () => {
  const [visible, setIsVisible] = useState(isVisible);

  useEffect(() => {
    if (!isDOM) {
      return;
    }

    setIsVisible(isVisible());

    const handler = () => {
      if (document.hidden) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    document.addEventListener('visibilitychange', handler);

    return () => {
      document.removeEventListener('visibilitychange', handler);
    };
  }, []);

  return visible;
};
