import React from 'react';
import styled from 'styled-components';
import {
  BodyText,
  ClockIcon,
  MailIcon,
  PhoneIcon,
  Subtitle
} from '@kvdbil/components';
import WeeklyCustomerService from '~/App/shared/components/WeeklyCustomerService';
import { useSelector } from 'react-redux';
import { localizationSelector } from '~/App/shared/selectors/localization';
import { Locale } from '~/App/shared/localization/types';
import { ContactItemRecord } from '~/config/generated/graphql';

const Container = styled.div`
  margin-bottom: 2rem;
`;

const DataList = styled.ul`
  display: table;
  width: 100%;
  border-collapse: collapse;
`;

const DataListItem = styled.li`
  display: flex;
`;

const ItemCell = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 2;
  align-items: center;
  padding: 0.625em 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.light3};

  svg {
    margin-right: 0.5rem;
    color: ${({ theme }) => theme.colors.gray.light1};
  }
`;

const Name = styled(Subtitle)`
  font-weight: ${({ theme }) => theme.typography.fontBaseBoldWeight};
`;

const Role = styled.span`
  color: ${({ theme }) => theme.colors.gray.light1};
  font-weight: ${({ theme }) => theme.typography.fontBaseBoldWeight};
`;

const DataContainer = styled.div`
  margin-bottom: 0.25rem;
`;

const Link = styled.a`
  display: inline-flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.typography.fontBaseBoldWeight};
  color: ${({ theme }) => theme.colors.info.main};
`;

interface Props {
  item: ContactItemRecord;
}

const ContactItem = ({ item }: Props) => {
  const { domainLocale } = useSelector(localizationSelector);

  const {
    personName,
    sectionName,
    roleOrInfo,
    county,
    phoneNumber,
    emailAddress,
    openingHours,
    showDetailedOpeningHours
  } = item;
  const name = personName || sectionName;
  const role = roleOrInfo?.name;
  const localizedPhoneNumber =
    domainLocale !== Locale.SV && phoneNumber?.startsWith('0')
      ? phoneNumber?.replace('0', '+46')
      : phoneNumber;
  const linkNumber = localizedPhoneNumber?.replace(/\D/g, '');

  return (
    <Container>
      <Name as="h2">{name}</Name>
      {role && <Role as="h3">{role}</Role>}
      {county && <Role as="h4">{county}</Role>}
      <DataContainer>
        <DataList>
          <DataListItem>
            <ItemCell>
              <Link href={`tel:${linkNumber}`}>
                <PhoneIcon />
                <span>{localizedPhoneNumber}</span>
              </Link>
            </ItemCell>
          </DataListItem>
          <DataListItem>
            <ItemCell>
              <Link href={`mailto:${emailAddress}`}>
                <MailIcon />
                <span>{emailAddress ?? ''}</span>
              </Link>
            </ItemCell>
          </DataListItem>

          {openingHours && (
            <DataListItem>
              <ItemCell>
                <ClockIcon />
                <BodyText as="span">{openingHours}</BodyText>
              </ItemCell>
            </DataListItem>
          )}
          {showDetailedOpeningHours && <WeeklyCustomerService onlyDays />}
        </DataList>
      </DataContainer>
    </Container>
  );
};

export default ContactItem;
