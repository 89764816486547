import styled from 'styled-components';
import React from 'react';
import { CarGuideCardProps } from '~/App/views/Carguide/types';
import { useTranslation } from '~/Locale';
import { mq } from '@kvdbil/components';

const Container = styled.div`
  font-family: ${({ theme }) => theme.typography.fontAlternativeFamily};
  font-weight: ${({ theme }) => theme.typography.fontAlternativeRegularWeight};
  font-size: 18px;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.text.dark};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: lowercase;

  ${mq('tablet')} {
    font-size: 1rem;
  }
`;

export interface Props {
  score: CarGuideCardProps['score'];
}

const CarGuideMatchingScore = ({ score }: Props) => {
  const { t } = useTranslation();
  return (
    <Container data-testid="car-guide-matching-score">
      {t('%1$s MATCHING', `${score}%`)}
    </Container>
  );
};

export default CarGuideMatchingScore;
