import React from 'react';
import styled from 'styled-components';
import { Spinner } from '@kvdbil/components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const LazyComponentFallback = () => (
  <Container aria-label="loading-component">
    <Spinner />
  </Container>
);

export default LazyComponentFallback;
