import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postBankIdVerify } from '~/helpers/orchestration/auth';
import { BankIdAuth } from '../../components/BankIdAuth';
import { idToken as idTokenSelector } from '~/App/shared/selectors/sessionSelector';
import { useTranslation } from '~/Locale';
import { sessionUpdate } from '~/App/shared/actions/session';

type Props = {
  title: string;
  subtitle: string;
  orderRef: string;
  qrCode?: string;
  autoStartToken?: string;
  onFail?(error: unknown): void;
  onClose?(): void;
  onComplete?(): void;
};

export const VerifyBankIDView = ({
  orderRef,
  qrCode,
  autoStartToken,
  onFail,
  onClose,
  onComplete,
  title,
  subtitle
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const idToken = useSelector(idTokenSelector);

  const verifyAccount = useCallback(
    async orderRef => {
      try {
        if (orderRef) {
          const response = await postBankIdVerify(orderRef, idToken);

          if (response.data.member) {
            dispatch(
              sessionUpdate({
                auth: {
                  idToken
                },
                member: response.data.member
              })
            );
            onComplete?.();
          } else {
            throw Error(
              t(
                'Something went wrong while authenticating with BankID, try again please'
              )
            );
          }
        }
      } catch (error: unknown) {
        onFail?.(error);
      }
    },
    [idToken, dispatch, onComplete, t, onFail]
  );

  return (
    <BankIdAuth
      initialQrCode={qrCode}
      autoStartToken={autoStartToken}
      orderRef={orderRef}
      onFail={onFail}
      onClose={onClose}
      onComplete={verifyAccount}
      title={title}
      subtitle={subtitle}
    />
  );
};

export default VerifyBankIDView;
