import React from 'react';
import { Spacer } from '@kvdbil/components';
import styled from 'styled-components';
import { skeletonAnimation } from './BlogSkeletons';

export const CardContainerSkeletonLoader = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray.light6};
  margin-bottom: 1.5rem;
`;

export const CardImageSkeletonLoader = styled.div`
  width: 100%;
  aspect-ratio: 133 / 89; // roughly the same ratio as temp images on cards
  ${skeletonAnimation}
`;

export const CardContentSkeletonLoader = styled.div`
  text-align: left;
  padding: 0.5rem 1rem;
`;

export const IconesSkeletonLoader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.25rem;
`;

export const IconSkeletonLoader = styled.div`
  height: 1.4rem;
  width: 1.4rem;
  ${skeletonAnimation}
`;

export const TiltleSkeletonLoader = styled.div`
  height: 1.2rem;
  width: 40%;
  margin-top: 0.5rem;
  ${skeletonAnimation}
`;

export const PropertiesSkeletonLoader = styled.div`
  height: 0.8rem;
  width: 60%;
  margin-top: 0.5rem;
  ${skeletonAnimation}
`;

export const FinancingContainerSkeletonLoader = styled.div`
  margin-top: 0.8rem;
  padding-top: 0.5rem;
  border-top-width: 3px;
  border-top-style: solid;
  border-top-color: #e5e5e5;
`;

export const FinanceRowSkeletonLoader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
`;

export const PriceLabelSkeletonLoader = styled.div`
  height: 1.2rem;
  width: 30%;
  ${skeletonAnimation}
`;

export const PriceValueSkeletonLoader = styled.div`
  height: 1.2rem;
  width: 40%;
  ${skeletonAnimation}
`;

export const CardSkeletonLoader = () => (
  <CardContainerSkeletonLoader data-testid="skeleton">
    <CardImageSkeletonLoader />

    <CardContentSkeletonLoader>
      <IconesSkeletonLoader>
        <IconSkeletonLoader />
        <IconSkeletonLoader />
      </IconesSkeletonLoader>
      <TiltleSkeletonLoader />
      <PropertiesSkeletonLoader />

      <FinancingContainerSkeletonLoader>
        <FinanceRowSkeletonLoader>
          <PriceLabelSkeletonLoader />
          <PriceValueSkeletonLoader />
        </FinanceRowSkeletonLoader>
        <Spacer height={1.5}></Spacer>
        <Spacer height={1.5}></Spacer>
      </FinancingContainerSkeletonLoader>
    </CardContentSkeletonLoader>
  </CardContainerSkeletonLoader>
);
