import React from 'react';
import { Path } from 'react-hook-form';
import CheckBoxWithControl from '~/App/shared/components/Fields/CheckBoxWithControl';
import { CheckboxProps } from '../types';

const MinimumAgeField = <FormState,>({
  control,
  t,
  name,
  rules,
  ...props
}: CheckboxProps<FormState>) => {
  const validationRules = {
    required: {
      value: true,
      message: t('Please accept the minimum age requirement')
    },
    ...rules
  };

  const fieldName = name ?? ('acceptsMinimumAgeRequirement' as Path<FormState>);

  return (
    <CheckBoxWithControl
      {...props}
      control={control}
      name={fieldName}
      rules={validationRules}
      data-testid="checkbox-age-requirement"
    />
  );
};

export default MinimumAgeField;
