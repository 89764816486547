import { TargetedAuctions } from './../interfaces/store/TargetedAuctions';
import { TargetedAuctionsActions } from './../actions/targetedAuctionsActions';
import { ReduxStore } from '../interfaces/store';

export const initialTargetedAuctionsState: TargetedAuctions = {
  auctions: [],
  statuses: [],
  isLoading: false,
  pollingThreads: []
};

const targetedAuctions = (
  state = initialTargetedAuctionsState,
  action: TargetedAuctionsActions
): ReduxStore['targetedAuctions'] => {
  switch (action.type) {
    case 'SET_POLLING_THREADS_TARGETED_AUCTIONS':
      return {
        ...state,
        pollingThreads: action.threadArray as unknown as number[]
      };
    case 'CLEAR_POLLING_THREADS_TARGETED_AUCTIONS': {
      const threads = state?.pollingThreads ?? [];

      if (threads.length > 0) {
        threads.forEach(thread => clearInterval(thread));
      }

      return { ...state, pollingThreads: [] };
    }
    case 'HYDRATE_TARGETED_AUCTIONS_REQUEST':
      return { ...state, isLoading: action.isLoading };

    case 'HYDRATE_TARGETED_AUCTIONS_SUCCESS': {
      return {
        ...state,
        auctions: action.targetedAuctions,
        isLoading: action.isLoading
      };
    }

    case 'HYDRATE_TARGETED_AUCTIONS_FAILED':
      return {
        ...state,
        isLoading: action.isLoading,
        auctions: []
      };

    case 'GET_HAS_SEEN_LATEST_AUCTIONS_STATES_SUCCESS': {
      const { groupId, hasSeenLatestAuctions } = action.payload;
      const { statuses } = state;

      const updatedStatuses = [...statuses];
      const selectedGroupStatus = statuses.find(
        status => status.groupId === groupId
      );

      if (!selectedGroupStatus) {
        updatedStatuses.push({
          groupId: groupId,
          hasSeen: hasSeenLatestAuctions
        });
      } else {
        selectedGroupStatus.hasSeen = hasSeenLatestAuctions;
      }

      return { ...state, statuses: updatedStatuses };
    }

    case 'SET_HAS_SEEN_LATEST_AUCTIONS_STATES_SUCCESS': {
      const { groupId, hasSeenLatestAuctions } = action.payload;
      const { statuses } = state;

      const updatedStatuses = [...statuses];
      const selectedGroupStatus = statuses.find(
        status => status.groupId === groupId
      );

      if (selectedGroupStatus) {
        selectedGroupStatus.hasSeen = hasSeenLatestAuctions;
      }

      return { ...state, statuses: updatedStatuses };
    }

    case 'CLEAR_HAS_SEEN_LATEST_AUCTIONS': {
      return { ...state, statuses: [] };
    }

    default:
      return state;
  }
};

export default targetedAuctions;
