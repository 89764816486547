import { ServicesPageQuery } from '~/config/generated/graphql';

export const handleServicesPage = ({ servicesPage }: ServicesPageQuery) => {
  return {
    hero: servicesPage?.optionalHero,
    buyOptions: {
      buyOptionsTitle: servicesPage?.buyOptionsTitle,
      buyOptions: servicesPage?.buyOptions
    },
    services: {
      servicesTitle: servicesPage?.servicesTitle,
      services: servicesPage?.services
    },
    featurePanel: {
      showFeaturePanel: servicesPage?.activateFeaturePanel,
      title: servicesPage?.featurePanelTitle,
      subtitle: servicesPage?.featurePanelSubtitle,
      sellingPoints: servicesPage?.featurePanelSellingPoints,
      button: servicesPage?.featurePanelButton,
      image: servicesPage?.featurePanelImage,
      color: servicesPage?.featurePanelColor
    }
  };
};
