/**
 * @deprecated TODO: [KVDBIL-1634] refactor...
 */

import { createSelector } from 'reselect';

import { ReduxStore } from '../interfaces/store';
import { AuctionsList } from '../interfaces/store/AuctionsList';
import { SelectOption } from '../interfaces/SelectOption';

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export const auctionListRoot = createSelector<
  ReduxStore,
  AuctionsList,
  AuctionsList
>(
  state => state.auctionsList,
  value => value
);

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export const auctionListBiddingRoot = createSelector(
  auctionListRoot,
  state => state?.bidding
);

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export const auctionListFixedPriceRoot = createSelector(
  auctionListRoot,
  state => state?.fixedPrice
);

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export const auctionListIsShowingFilters = createSelector(
  auctionListRoot,
  state => state?.showAuctionFilters
);

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export const auctionListFacilities = createSelector<
  ReduxStore,
  AuctionsList,
  SelectOption[]
>(auctionListRoot, state => state?.facilities || []);

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export const auctionListStepSize = createSelector(
  auctionListRoot,
  state => state?.stepSize
);
