import {
  T,
  F,
  not,
  when,
  pathEq,
  evolve,
  always,
  pathOr,
  compose,
  path
} from 'ramda';
import { VerifyPhoneNumberActions } from '../actions/verifyPhoneNumber';
import { ReduxStore } from '../interfaces/store';

const initialState = {
  error: '',
  generalError: false,
  onBackward: false,
  isVerified: false,
  isVerifying: false,
  isSendingCode: false,
  isEmailLoading: false,
  updatedPhoneNumber: false,
  isEmailAuthLoading: false,
  isMaxRetriesExceeded: false,
  isUpdatingPhoneNumber: false
};

const reducer = (
  state = initialState,
  action: VerifyPhoneNumberActions
): ReduxStore['verifyPhoneNumber'] => {
  switch (action.type) {
    case 'RESET_PHONE_VERIFICATION':
      return initialState;
    case 'SEND_VERIFICATION_CODE_REQUEST':
      return {
        ...state,
        isSendingCode: true,
        isVerified: false,
        isMaxRetriesExceeded: false,
        error: '',
        generalError: false
      };
    case 'SEND_VERIFICATION_CODE_SUCCESS':
      return { ...state, isSendingCode: false };
    case 'SEND_VERIFICATION_CODE_FAILURE':
      return { ...state, isSendingCode: false, generalError: true };
    case 'VERIFY_PHONE_NUMBER_REQUEST':
      return evolve(
        {
          isVerifying: T,
          error: always('')
        },
        state
      );
    case 'VERIFY_PHONE_NUMBER_SUCCESS':
      return evolve(
        {
          isVerifying: F,
          isVerified: T
        },
        state
      );
    case 'VERIFY_PHONE_NUMBER_FAILURE':
      const maxRetriesExceeded = pathEq(
        ['message', 'errorClass'],
        'MAX_TRIES_EXCEEDED'
      )(action.payload);

      return evolve(
        {
          isVerifying: F,
          isMaxRetriesExceeded: always(maxRetriesExceeded),
          onBackward: when(always(maxRetriesExceeded), not),
          error: compose(
            pathOr('', ['message', 'code']),
            always(action.payload)
          )
        },
        state
      );
    case 'UPDATE_PHONE_NUMBER_REQUEST':
      return {
        ...state,
        isUpdatingPhoneNumber: true,
        error: '',
        generalError: false
      };
    case 'UPDATE_PHONE_NUMBER_SUCCESS':
      return evolve(
        {
          error: always(''),
          updatedPhoneNumber: not,
          isUpdatingPhoneNumber: F
        },
        state
      );
    case 'UPDATE_PHONE_NUMBER_FAILURE':
      const [firstError]: string[] = path(
        ['message', 'person', 'phoneNumber'],
        action.payload
      ) ?? [''];

      return {
        ...state,
        isUpdatingPhoneNumber: false,
        error: firstError,
        generalError: firstError ? false : true
      };
    default:
      return state;
  }
};

export default reducer;
