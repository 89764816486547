import React from 'react';
import { Path } from 'react-hook-form';
import TextFieldWithControl from '~/App/shared/components/Fields/TextFieldWithControl';
import { TextFieldProps } from '../types';
import { FIRST_NAME_MAX_LENGTH } from '~/App/shared/validation/constants';

const FirstNameField = <FormState,>({
  control,
  t,
  name,
  rules,
  ...props
}: TextFieldProps<FormState>) => {
  const validationRules = {
    required: {
      value: true,
      message: t(`First name can't be blank`)
    },
    maxLength: {
      value: FIRST_NAME_MAX_LENGTH,
      message: t(
        'First name is too long (maximum is %s characters)',
        FIRST_NAME_MAX_LENGTH
      )
    },
    ...rules
  };

  const fieldName = name ?? ('firstName' as Path<FormState>);

  return (
    <TextFieldWithControl
      {...props}
      control={control}
      name={fieldName}
      autoComplete="given-name"
      label={t('First name')}
      rules={validationRules}
    />
  );
};

export default FirstNameField;
