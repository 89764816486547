import { ArrowLeftIcon, Flex } from '@kvdbil/components';
import { FlexProps } from '@kvdbil/components/types/components/Flex';
import React from 'react';

type Props = {
  onBack(): void;
  flexProps?: FlexProps;
  iconProps?: React.SVGProps<SVGSVGElement>;
};

const BackButton = ({ onBack, flexProps, ...iconProps }: Props) => {
  return (
    <Flex justify={'right'} align={'center'} {...flexProps}>
      <ArrowLeftIcon
        cursor={'pointer'}
        width={'2rem'}
        height={'2rem'}
        onClick={onBack}
        {...iconProps}
      />
    </Flex>
  );
};

export default BackButton;
