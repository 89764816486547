import { useEffect } from 'react';
import { startBankId } from '../helpers/bankIdHelpers';

interface Props {
  autoStartToken?: string;
  shouldStart: boolean;
  onAutoStart?: () => void;
}

export const useStartBankId = ({
  autoStartToken,
  shouldStart,
  onAutoStart
}: Props) => {
  useEffect(() => {
    if (autoStartToken && shouldStart) {
      startBankId(autoStartToken);
      onAutoStart?.();
    }
  }, [autoStartToken, shouldStart, onAutoStart]);
};
