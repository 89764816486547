import { createSelector } from 'reselect';
import {
  FeatureFlag,
  FeatureFlagVariant,
  FeatureFlagObject
} from '../interfaces/FeatureFlags';
import { ReduxStore } from '../interfaces/store';

type ReduxFeatureFlags = ReduxStore['featureFlags'];

const root = createSelector<ReduxStore, ReduxFeatureFlags, ReduxFeatureFlags>(
  root => root?.featureFlags,
  value => value
);

export const featureFlagsSelector = createSelector<
  ReduxStore,
  ReduxFeatureFlags,
  ReduxFeatureFlags['flags']
>(root, root => root?.flags);

export const featureFlagValueSelectorWithState = (
  state: ReduxStore,
  key: FeatureFlag
) => {
  return createSelector<
    ReduxStore,
    ReduxFeatureFlags['flags'],
    FeatureFlagVariant
  >(
    featureFlagsSelector,
    flags => (flags && flags[key]?.value) || false
  )(state);
};

export const featureFlagValueSelector = (key: FeatureFlag) =>
  createSelector(
    featureFlagsSelector,
    flags => (flags && flags[key])?.value || false
  );

export const featureFlagObjectSelectorWithState = (
  state: ReduxStore,
  key: FeatureFlag
) => {
  return createSelector(featureFlagsSelector, flags =>
    flags?.[key] ? flags[key] : { isExperiment: false, value: false }
  )(state) as FeatureFlagObject;
};
