import React from 'react';
import { Path } from 'react-hook-form';
import TextFieldWithControl from '~/App/shared/components/Fields/TextFieldWithControl';
import { ORGANISATION_NAME_MAX_LENGTH } from '~/App/shared/validation/constants';
import { TextFieldProps } from '../types';

const OrganisationNameField = <FormState,>({
  control,
  t,
  name,
  rules,
  ...props
}: TextFieldProps<FormState>) => {
  const validationRules = {
    required: {
      value: true,
      message: t(`Organisation name can't be blank`)
    },
    maxLength: {
      value: ORGANISATION_NAME_MAX_LENGTH,
      message: t(
        'Organisation name is too long (maximum is %s characters)',
        ORGANISATION_NAME_MAX_LENGTH
      )
    },
    ...rules
  };

  const fieldName = name ?? ('organisationName' as Path<FormState>);

  return (
    <TextFieldWithControl
      {...props}
      control={control}
      name={fieldName}
      label={t('Organisation name')}
      rules={validationRules}
    />
  );
};

export default OrganisationNameField;
