import React from 'react';
import Newsletter from '../../Start/components/Newsletter';
import CorporateNewsletterForm from '../../DynamicPage/components/forms/CorporateNewsletterForm';
import { NewsletterSignupRecord } from '~/config/generated/graphql';

const NewsletterTypeDispatcher = ({
  title,
  description,
  newsletterType
}: Pick<
  NewsletterSignupRecord,
  'title' | 'description' | 'newsletterType'
>) => {
  const commonProps = {
    title: title ?? undefined,
    description: description ?? undefined
  };

  switch (newsletterType) {
    case 'private':
      return <Newsletter {...commonProps} />;
    case 'corporate':
      return <CorporateNewsletterForm {...commonProps} />;
    default:
      return null;
  }
};

export default NewsletterTypeDispatcher;
