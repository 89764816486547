import { useCallback, useRef } from 'react';
import { setHeaderSlideAction } from '~/App/shared/actions/headerActions';
import {
  headerHeightSelector,
  isHeaderSlideEnabledSelector,
  isHeaderSlideOpenSelector
} from '~/App/shared/selectors/header';
import { isHydratedSelector } from '~/App/shared/selectors/sessionSelector';
import { useDispatch, useSelector } from 'react-redux';
import useOnScroll from '~/App/shared/hooks/useOnScroll';
import { isOnServer } from '~/helpers/isOnServer';

interface Props {
  scrollUpThreshold?: number;
}

export default function useHeaderScrollControl(props?: Props) {
  const defaultScrollUpThreshold = isOnServer ? 1000 : window.innerHeight;

  const { scrollUpThreshold = defaultScrollUpThreshold } = props ?? {};

  const headerHeight = useSelector(headerHeightSelector);
  const isHeaderSlideOpen = useSelector(isHeaderSlideOpenSelector);
  const isHeaderSlideEnabled = useSelector(isHeaderSlideEnabledSelector);

  const isHydrated = useSelector(isHydratedSelector);

  const scrollUpStart = useRef<number | null>(null);

  const dispatch = useDispatch();

  const onScroll = useCallback(
    (current: number | undefined, previous: number | undefined) => {
      if (!isHeaderSlideEnabled) {
        return;
      }

      const setHeaderSlideOpen = (slideUp: boolean) => {
        // Prevent exessive dispatches
        if (slideUp !== isHeaderSlideOpen) {
          dispatch(setHeaderSlideAction(slideUp));
        }
      };

      const isPastHeaderHeight = () => {
        if (
          typeof window !== 'undefined' &&
          headerHeight &&
          window.scrollY > headerHeight &&
          isHydrated // isHydrated prevents the header from being hidden when the page is loaded
        ) {
          return true;
        }

        return false;
      };

      if (isPastHeaderHeight()) {
        const isScrollingUp = current && previous && current < previous;

        if (isScrollingUp) {
          if (scrollUpStart.current === null) {
            // User just started scrolling up, store the current scroll position
            scrollUpStart.current = window.scrollY;
          } else if (
            window.scrollY <=
            scrollUpStart.current - scrollUpThreshold
          ) {
            // User has scrolled up by more than the threshold, show the header
            setHeaderSlideOpen(true);
            scrollUpStart.current = null;
          }
        } else {
          // User is scrolling down, hide the header and reset scrollUpStart
          setHeaderSlideOpen(false);
          scrollUpStart.current = null;
        }

        return;
      }

      setHeaderSlideOpen(true);
    },
    [
      headerHeight,
      isHydrated,
      isHeaderSlideEnabled,
      isHeaderSlideOpen,
      dispatch,
      scrollUpThreshold
    ]
  );

  useOnScroll({ onScroll });
}
