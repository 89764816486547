import { propEq, reject } from 'ramda';
import { combineReducers } from 'redux';
import { VIEW_AUCTION_SUCCESS } from '../actions/auction';
import { VIEW_SAVED_SEARCH_SUCCESS } from '../actions/saved-searches';
import { ReduxStore } from '../interfaces/store';
import {
  AuctionNotification,
  FavouriteNotification,
  SalesAgreementNotification,
  SavedSearchNotification
} from '../interfaces/store/Notifications';
import { GET_NOTIFICATIONS_SUCCESS } from '../middlewares/fetchNotificationsMiddleware';

type ViewSavedSearch = {
  type: typeof VIEW_SAVED_SEARCH_SUCCESS;
  payload?: string;
};

type ViewAuction = {
  type: typeof VIEW_AUCTION_SUCCESS;
  payload?: string;
};

type GetNotificationsSuccess = {
  type: typeof GET_NOTIFICATIONS_SUCCESS;
  payload?: {
    auctions: AuctionNotification[];
    favourites: FavouriteNotification[];
    savedSearches: SavedSearchNotification[];
    salesAgreements: SalesAgreementNotification[];
  };
};

export type NotificationAction =
  | GetNotificationsSuccess
  | ViewSavedSearch
  | ViewAuction;

const auctionsReducer = (state = [], action: NotificationAction) => {
  switch (action.type) {
    case 'GET_NOTIFICATIONS_SUCCESS':
      return action.payload?.auctions ?? state;
    case 'VIEW_AUCTION_SUCCESS':
      return reject(propEq('auctionId', action.payload))(state);
    default:
      return state;
  }
};

const favouritesReducer = (state = [], action: NotificationAction) => {
  switch (action.type) {
    case 'GET_NOTIFICATIONS_SUCCESS':
      return action.payload?.favourites ?? state;
    case 'VIEW_AUCTION_SUCCESS':
      return reject(propEq('auctionId', action.payload))(state);
    default:
      return state;
  }
};

export const savedSearchesReducer = (
  state = [] as SavedSearchNotification[],
  action: NotificationAction
): SavedSearchNotification[] => {
  switch (action.type) {
    case 'GET_NOTIFICATIONS_SUCCESS':
      return action.payload?.savedSearches ?? state;
    case 'VIEW_AUCTION_SUCCESS':
      return state.map(notification => {
        if (
          notification?.newAuctions &&
          notification.newAuctions.includes(action.payload as string)
        ) {
          return {
            ...notification,
            newAuctions: notification.newAuctions.filter(
              a => a !== action.payload
            )
          };
        }
        return notification;
      });
    case 'VIEW_SAVED_SEARCH_SUCCESS':
      return state.map(notification => {
        if (notification.savedSearchId === action.payload) {
          return {
            ...notification,
            newAuctions: []
          };
        }
        return notification;
      });
    default:
      return state;
  }
};

const salesAgreementsReducer = (state = [], action: NotificationAction) => {
  switch (action.type) {
    case 'GET_NOTIFICATIONS_SUCCESS':
      return action.payload?.salesAgreements ?? state;
    default:
      return state;
  }
};

export default combineReducers<ReduxStore['notifications']>({
  auctions: auctionsReducer,
  favourites: favouritesReducer,
  savedSearches: savedSearchesReducer,
  salesAgreements: salesAgreementsReducer
});
