import { createSelector } from 'reselect';
import { CHAT_FLOWS } from '~/config/constants';
import { salesFlowType } from './sellFlowSelectors';
import { ReduxStore } from '../interfaces/store';
import { FavouriteAuctions } from '../interfaces/store/FavouriteAuctions';

const root = createSelector<ReduxStore, ReduxStore, ReduxStore>(
  root => root,
  root => root
);

export const favouriteAuctions = createSelector<
  ReduxStore,
  ReduxStore,
  FavouriteAuctions
>(root, root => root?.favouriteAuctions ?? []);

export const mySavedAuctions = createSelector(
  root,
  root => root?.mySavedAuctions?.auctions ?? []
);

export const mySavedAuctionsLoading = createSelector(
  root,
  root => root?.mySavedAuctions?.loading
);

export const savedAuctions = createSelector(
  root,
  root => root?.myAuctions ?? []
);

export const timeDiff = createSelector(
  root,
  root => root?.time?.serverOffset ?? 0
);

export const app = createSelector(root, root => root?.app ?? {});

export const appIsLoading = createSelector(
  app,
  root => root?.isLoading ?? false
);

export const messagesBottom = createSelector(
  root,
  root => root?.messages?.bottom
);

export const messagesTop = createSelector(root, root => root.messages.top);

export const isContactButtonOpen = createSelector(
  root,
  root => root?.contactButton?.isOpen ?? false
);

export const isContactButtonHidden = createSelector(
  root,
  root => root?.contactButton?.isHidden ?? false
);

export const isDefaultTriggerEnabledSelector = createSelector(
  root,
  root => root?.contactButton?.isDefaultTriggerEnabled ?? true
);

export const contactButtonPositionSelector = createSelector(
  root,
  root => root?.contactButton?.position ?? {}
);

export const kundoIsOpenSelector = createSelector(
  root,
  root => root?.contactButton?.kundo?.isOpen ?? false
);

export const activeChatFlow = createSelector(salesFlowType, salesFlowType => {
  switch (salesFlowType) {
    case 'PRIVATE':
      return CHAT_FLOWS.SELL_PRIVATE;
    case 'COMPANY':
      return CHAT_FLOWS.SELL_COMPANY;
    default:
      return CHAT_FLOWS.CUSTOMER_SERVICE;
  }
});

export const hasFavouriteAuctions = createSelector(
  favouriteAuctions,
  root => Boolean(root?.length) ?? false
);

export const hasSavedAuctions = createSelector(
  savedAuctions,
  root => Boolean(root?.length) ?? false
);

export const isFooterHiddenSelector = createSelector(
  root,
  root => root?.app.isFooterHidden ?? false
);
