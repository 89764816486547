import React from 'react';
import styled from 'styled-components';
import {
  Status,
  mq,
  ArrowLeftIcon,
  resetButtonStyle
} from '@kvdbil/components';
import { useTranslation } from '~/Locale';

const Container = styled.div`
  ${mq('tablet')} {
    display: none;
  }
  display: flex;
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray.light5}`};
  margin: -1rem;
  padding: 0.5rem 1rem 0.25rem;
  padding-top: 1.5rem;
  margin-bottom: 1rem;
`;

const BackButton = styled.button`
  ${resetButtonStyle};
  display: flex;
  align-items: center;
  padding: 0.125rem;
  svg {
    font-size: 0.75rem;
    margin-right: 0.25rem;
  }
`;

interface Props {
  handleClose(): void;
}

const SearchFieldHeader = ({ handleClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <BackButton onClick={handleClose}>
        <ArrowLeftIcon />
        <Status>{t('Back')}</Status>
      </BackButton>
    </Container>
  );
};

export default SearchFieldHeader;
