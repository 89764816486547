import { createStore, applyMiddleware, Reducer } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import thunkMiddleware from 'redux-thunk';
import { socketMiddleware } from '~/helpers/client/websocketMiddleware';
import { timeSyncMiddleware } from '~/helpers/client/timeSyncMiddleware';
import { fetchNotificationsMiddleware } from '~/App/shared/middlewares/fetchNotificationsMiddleware';
import { rootReducer } from '~/App/shared/reducers';
import { environment } from '~/config/public';
import { initialState as getInitialState } from '~/config/public/environment';
import logger from '~/helpers/logger';

const configureStore = () => {
  const middlewares = [
    thunkMiddleware,
    timeSyncMiddleware,
    fetchNotificationsMiddleware
  ];

  if (environment.useWebsockets) {
    middlewares.push(socketMiddleware());
  }

  const initialState = getInitialState();

  const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares))
  );

  if (module.hot) {
    module.hot.accept('../../App/shared/reducers', () => {
      logger.info('[HMR] Reloaded rootReducer');

      const nextReducer: Reducer = require('../../App/shared/reducers')[
        'default'
      ];
      store.replaceReducer(nextReducer);
    });
  }

  return store;
};

export default configureStore;
