import {
  createRef,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import throttle from 'lodash.throttle';

export type refType = MutableRefObject<HTMLLIElement | null>;

export const useScroller = (objects: Record<string, unknown>[] | unknown[]) => {
  const scroller = useRef<HTMLUListElement | null>(null);
  const [childRefs, setRefs] = useState<refType[]>([]);

  const [scrollState, setScrollState] = useState({
    leftDisabled: true,
    leftStyle: { opacity: 0 },
    rightDisabled: true,
    rightStyle: { opacity: 0 }
  });

  const handleScroll = useCallback(() => {
    const left = scroller.current?.scrollLeft || 0;
    const length = objects?.length;
    let contentWidth = 0;
    let el: HTMLElement | null;
    for (let i = 0; i < length; i += 1) {
      el = childRefs[i]?.current;
      if (el) {
        contentWidth += parseInt(window.getComputedStyle(el).width);
        if (i < length - 1) {
          contentWidth += parseInt(window.getComputedStyle(el).marginRight);
        }
      }
    }
    if (scroller.current) {
      const right =
        contentWidth -
        (left +
          parseInt(
            window.getComputedStyle(scroller?.current as Element).width
          ));
      const newState = {
        ...scrollState,
        leftDisabled: left < 10,
        rightDisabled: right < 10,
        leftStyle: { opacity: left < 100 ? 0 : 1 },
        rightStyle: { opacity: right < 100 ? 0 : 1 }
      };
      if (JSON.stringify(scrollState) !== JSON.stringify(newState)) {
        setScrollState(newState);
      }
    }
  }, [childRefs, objects, scrollState]);

  const onScroll = throttle(handleScroll, 100);

  const handleMoveClick = useCallback(
    (direction: 'left' | 'right') => {
      const { leftDisabled, rightDisabled } = scrollState;
      const firstEl = childRefs[0]?.current;
      if (firstEl && scroller.current) {
        const style = window.getComputedStyle(firstEl);
        if (leftDisabled && direction === 'left') {
          return;
        }
        if (rightDisabled && direction === 'right') {
          return;
        }

        const scrollStart = scroller.current?.scrollLeft || 0;
        const width = parseInt(style.width, 10);
        const marginRight = parseInt(style.marginRight, 10);

        const distanceToNext = width + marginRight;
        if (direction === 'right') {
          const toNext = scrollStart + distanceToNext;
          scroller.current?.scrollTo({
            left: toNext,
            behavior: 'smooth'
          });
        } else {
          const toPrev = scrollStart - distanceToNext;
          scroller.current?.scrollTo({
            left: toPrev,
            behavior: 'smooth'
          });
        }
      }
    },
    [scrollState, childRefs]
  );

  useEffect(() => {
    handleScroll();
  }, [handleScroll]);

  useEffect(() => {
    setRefs(new Array(objects.length).fill(createRef()));
  }, [objects]);

  return {
    scroller,
    onScroll,
    childRefs,
    handleMoveClick,
    ...scrollState
  };
};
