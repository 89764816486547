import { EmailSubscriptionManagementQuery } from '~/config/generated/graphql';

export const handleEmailSubscriptions = ({
  emailSubscriptionManagement
}: EmailSubscriptionManagementQuery) => {
  return {
    profileSettingsPage: {
      title: emailSubscriptionManagement?.profileSettingsTitle,
      description: emailSubscriptionManagement?.profileSettingsDescription
    },
    unsubscribePage: {
      inProgressTitle: emailSubscriptionManagement?.unsubscribeInProgresTitle,
      doneTitle: emailSubscriptionManagement?.unsubscribeDoneTitle,
      doneConfirmation:
        emailSubscriptionManagement?.unsubscribeDoneConfirmation,
      showMore: emailSubscriptionManagement?.unsubscribeShowMore,
      subscriptionListDescription:
        emailSubscriptionManagement?.unsubscribeSubscriptionListDescription,
      footerText: emailSubscriptionManagement?.unsubscribeFooterText,
      footerLinkText: emailSubscriptionManagement?.unsubscribeFooterLinkText
    },
    subscriptions: emailSubscriptionManagement?.subscriptions?.filter(
      subscription => subscription.isActive
    )
  };
};
