import ProcessObject from '~/App/shared/interfaces/ProcessObject';
import { formatFact, translateFuel, translateGearbox } from './auction';
import { Properties } from '~/App/shared/interfaces/Properties';
import { TranslateFunction } from '~/Locale';
import { formatOdometerReading } from './odometer';
import { formatMoney } from 'accounting';

interface ProcessObjectStatus {
  isCertified: boolean;
  isReleasable: boolean;
  isEcoFriendly: boolean;
}

export const getProcessObjectStatus = (
  processObject: ProcessObject
): ProcessObjectStatus => {
  const isCertified = processObject?.certified ?? false;
  const isReleasable = processObject?.isReleasable ?? false;
  const isEcoFriendly = processObject?.properties?.ecoFriendly ?? false;

  return {
    isCertified,
    isReleasable,
    isEcoFriendly
  };
};

export const getTranslatedEnginePower = (
  t: TranslateFunction,
  processObject?: ProcessObject
) => {
  const fact = processObject?.facts?.additional?.find(
    fact => fact.type === 'engine_power'
  );

  return fact ? formatFact(t, fact, processObject?.objectType || 'OTHER') : '';
};

export function getExportText(
  exportable: boolean,
  mustExport: boolean,
  t: TranslateFunction
) {
  if (mustExport) return t('Export only');

  if (exportable) return t('Export: Yes');

  return t('Export: No');
}

export const getObjectPreviewProperties = (
  t: TranslateFunction,
  processObject: ProcessObject,
  isSummary: boolean
) => {
  const { properties = {} as Properties, objectType } = processObject;
  const {
    odometerUnit,
    odometerReading,
    operatingHours,
    modelYear,
    fuels,
    exportable,
    mustExport,
    gearbox,
    heavyEquipment: isHeavyEquipment
  } = properties;

  const shouldShowModelYear = Boolean(modelYear);
  const shouldShowOdometerReading = Boolean(odometerReading);
  const shouldFallbackToOdometerTim = !operatingHours && odometerUnit === 'tim';
  const shouldShowOperatingHours =
    operatingHours &&
    (isSummary || !odometerReading || !shouldFallbackToOdometerTim);
  const shouldShowFuel = !isHeavyEquipment || isSummary;
  const shouldShowGearbox = !isHeavyEquipment && isSummary;
  const shouldShowExport = isHeavyEquipment;

  const exportText = getExportText(exportable, mustExport, t);

  const previewProperties = [];

  if (shouldShowModelYear) {
    previewProperties.push(`${modelYear}`);
  }

  if (shouldShowOdometerReading) {
    previewProperties.push(
      formatOdometerReading(t, odometerReading, odometerUnit, objectType)
    );
  }

  if (operatingHours && shouldShowOperatingHours) {
    previewProperties.push(formatMoney(operatingHours, t('unit.hours')));
  }

  // old fallback... can be removed when we this has gone live
  if (shouldFallbackToOdometerTim) {
    previewProperties.push(formatMoney(odometerReading, t('unit.hours')));
  }

  if (shouldShowFuel) previewProperties.push(translateFuel(t, fuels));

  if (shouldShowGearbox) {
    previewProperties.push(translateGearbox(t, gearbox));
  }

  if (shouldShowExport) {
    previewProperties.push(exportText);
  }

  return [...previewProperties];
};
