import { AxiosRequestConfig, Canceler } from 'axios';
import axios from './axiosWithAuthHandling';

const axiosWithCancellation = ({ cancellable }: { cancellable: boolean }) => {
  let cancelRequest: Canceler = () => null;

  return Promise.resolve((args: AxiosRequestConfig) => {
    cancellable && cancelRequest();
    const promise = axios({
      ...args,
      cancelToken: new axios.CancelToken(c => {
        // An executor function receives a cancel function as a parameter
        cancelRequest = c;
      })
    });

    return promise;
  });
};

export default axiosWithCancellation;
