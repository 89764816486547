import React, { useState } from 'react';
import { ArrowRightIcon, Button, Message, mq, Title } from '@kvdbil/components';
import styled from 'styled-components';
import { translateRoute } from '~/App/shared/localization';
import {
  sellflowVehicleInfoSuccess,
  sellflowSetRegistrationPlate
} from '~/App/shared/actions/sellflowActions';
import { getLeadObject } from '~/helpers/orchestration/sale';
import { useTranslation } from '~/Locale';
import { useHistory } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { regexRegistrationPlate } from '~/helpers/regex';
import { useDispatch } from 'react-redux';
import { processErrorResponse } from '~/helpers/errorUtils';
import TextFieldWithControl from './Fields/TextFieldWithControl';
import { convertScandinavianMilesToKilometers } from '~/helpers/odometer';

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray.light5};
  padding: 1rem;
`;

const SubmitButton = styled(Button)`
  && {
    margin-top: 1rem;
    justify-content: center;
    flex: 1;
  }
`;

const SellTitle = styled(Title)`
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.primary.main};
`;

const FieldWrapper = styled.div`
  margin-top: 1rem;

  ${mq('tablet')} {
    margin-right: 0;
  }
`;

const Form = styled.form`
  position: relative;
  justify-content: space-between;
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
`;

const ErrorMessage = styled(Message)`
  margin-top: 1rem;
`;

type Form = {
  registrationPlate: string;
  odometerReading: string;
};

export interface InterstFormProps {
  title?: string;
  buttonText?: string;
  defaultValues?: Form;
}

const InterestForm = ({
  title,
  buttonText,
  defaultValues = { odometerReading: '', registrationPlate: '' }
}: InterstFormProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorReponse, setErrorResponse] = useState('');
  const dispatch = useDispatch();

  const { handleSubmit, control, getValues } = useForm<Form>({
    mode: 'onBlur',
    defaultValues
  });
  const currentOdometerReading = useWatch<Form>({
    control,
    name: 'odometerReading'
  });
  const { odometerReading, registrationPlate } = getValues();

  const onSubmit = () => {
    const convertedOdometerReading =
      convertScandinavianMilesToKilometers(odometerReading);
    setIsLoading(true);
    getLeadObject({
      params: {
        odometerReading: convertedOdometerReading,
        registrationPlate: registrationPlate
      }
    })
      .then(response => {
        dispatch(sellflowSetRegistrationPlate(registrationPlate));
        dispatch(sellflowVehicleInfoSuccess(response));
        const route = translateRoute(t, '/ROUTES.SELL/ROUTES.SELL_CAR_INFO');
        history.push(route);
      })
      .catch((err: unknown) => {
        setErrorResponse(processErrorResponse(err, t));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const isDisabled = !registrationPlate || !currentOdometerReading;

  return (
    <Container>
      {title && <SellTitle>{t('Sell your %1$s?', title)}</SellTitle>}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FieldWrapper>
          <TextFieldWithControl
            control={control}
            name="registrationPlate"
            placeholder={t('Enter license number')}
            rules={{
              required: {
                value: true,
                message: t(`Registration plate can't be blank`)
              },
              pattern: {
                value: regexRegistrationPlate,
                message: t('Enter license number')
              }
            }}
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextFieldWithControl
            control={control}
            type="number"
            min="0"
            name="odometerReading"
            placeholder={t('Enter mileage')}
            rules={{
              required: {
                value: true,
                message: t(`Odometer reading can't be blank`)
              }
            }}
          />
        </FieldWrapper>
        <SubmitButton
          isLoading={isLoading}
          isDisabled={isDisabled}
          sufixIcon={<ArrowRightIcon />}
          size="regular"
        >
          {buttonText || t('Send')}
        </SubmitButton>
      </Form>

      {errorReponse && <ErrorMessage type="error">{errorReponse}</ErrorMessage>}
    </Container>
  );
};

export default InterestForm;
