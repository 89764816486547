import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import styled from 'styled-components';
import { Title, Card, BodyText, Button, Spacer, mq } from '@kvdbil/components';
import { useTranslation } from '~/Locale';
import { TitleWithTextRecord } from '~/config/generated/graphql';
import { isEmptyDocument } from 'datocms-structured-text-utils';
import StructuredText from '~/App/components/DatoCMS/StructuredText';
import { DatoTitleTag } from '~/App/shared/interfaces/datoCMS';

const Content = styled.div`
  padding: 1rem 2rem;

  ${mq(null, 'mobileL')} {
    padding: 2rem 1rem;
  }
`;

const ExpandableText = styled(BodyText)<{ isExpanded: boolean }>`
  position: relative;
  overflow: hidden;
  max-height: ${({ isExpanded }) => (isExpanded ? '100%' : '4.5rem')};

  a {
    font-weight: ${({ theme }) => theme.typography.fontBaseBoldWeight};
  }

  * {
    margin-top: 1.38rem;
    &:first-child {
      margin-top: 0;
    }
  }

  noscript:nth-child(1) + * {
    margin-top: 0;
  }
`;

const ExpandWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  transition: all 0.2 ease;
  padding-top: 1rem;
`;

interface Props {
  content: TitleWithTextRecord;
  defaultExpandedState?: boolean;
}

const SEOInfo = ({ content, defaultExpandedState = false }: Props) => {
  const [expanded, setExpanded] = useState(defaultExpandedState);
  const { t } = useTranslation();
  const title = content?.title?.[0];
  const text = content?.text;

  if (!title?.text && isEmptyDocument(text)) {
    return null;
  }

  return (
    <Card>
      <Content>
        <Title as={(title?.tag ?? 'h3') as DatoTitleTag}>{title?.text}</Title>
        <Spacer height={0.5} />
        {!isEmptyDocument(text) && (
          <Collapse isOpened>
            <ExpandableText as="div" isExpanded={expanded}>
              <StructuredText data={text} />
            </ExpandableText>
          </Collapse>
        )}

        <ExpandWrapper>
          <Button
            onClick={() => setExpanded(prev => !prev)}
            size="regular"
            variant="flat"
            color="info"
          >
            {expanded ? (
              <span>{t('Minimize')} </span>
            ) : (
              <span>{t('Show more')} </span>
            )}
          </Button>
        </ExpandWrapper>
      </Content>
    </Card>
  );
};

export default SEOInfo;
