import React, { useEffect, useState } from 'react';
import { BodyText, Button, Spinner } from '@kvdbil/components';
import styled from 'styled-components';
import { useTranslation } from '~/Locale';
import { CHUNK_LOAD_ERROR_RELOAD_PAGE_TIME } from '~/config/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 60vh;
`;

const Text = styled(BodyText)`
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  margin-bottom: 2rem;
  min-width: 15rem;
`;

const SpinnerContainer = styled.div`
  position: relative;
`;

export const ChunkLoadErrorView = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const reloadPage = () => {
    window.location.reload();
    setIsLoading(true);
  };

  useEffect(() => {
    /* reload page on mount */
    setTimeout(() => {
      reloadPage();
    }, CHUNK_LOAD_ERROR_RELOAD_PAGE_TIME);
  }, []);

  return (
    <Container data-testid="chunk-load-error-view">
      <Text>{t('Reloading page, wait a bit ..')}</Text>

      <StyledButton color="secondary" onClick={reloadPage}>
        {t('Reload now')}
      </StyledButton>

      {isLoading && (
        <SpinnerContainer data-testid="spinner">
          <Spinner size="large" speed="fast" color="neutral" />
        </SpinnerContainer>
      )}
    </Container>
  );
};

export default ChunkLoadErrorView;
