import { mq, resetButtonStyle } from '@kvdbil/components';
import React, { MouseEvent, useCallback, useState } from 'react';
import styled from 'styled-components';

type ActionMessageVariant =
  | 'PRIMARY'
  | 'SECONDARY'
  | 'ERROR'
  | 'SUCCESS'
  | 'WARNING'
  | 'INFO';

type ActionMessageContainer = {
  variant: ActionMessageVariant;
};

const Container = styled.div<ActionMessageContainer>`
  padding: 0.5rem 1rem;
  color: ${({ theme }) => theme.colors.text.light};
  background: ${({ theme, variant }) => {
    switch (variant) {
      case 'ERROR':
        return theme.colors.error.main;
      case 'SUCCESS':
        return theme.colors.success.main;
      case 'WARNING':
        return theme.colors.warning.main;
      case 'INFO':
        return theme.colors.info.main;
      case 'PRIMARY':
        return theme.colors.primary.main;
      case 'SECONDARY':
      default:
        return theme.colors.secondary.main;
    }
  }};
  font-size: 0.75rem;

  ${mq('tablet')} {
    padding: 1rem 2rem;
    font-size: 1rem;
  }
`;

const Content = styled.div`
  max-width: 1024px;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ResendButton = styled.button`
  ${resetButtonStyle};
  color: white;
  text-decoration: underline;
`;

export interface ActionMessageProps {
  onError(): void;
  initialIsClicked?: boolean;
  message: string;
  buttonMessage: string;
  buttonClickedMessage?: string;
  onClickAction(): Promise<unknown>;
  variant?: ActionMessageVariant;
}
export const ActionMessage = ({
  onError,
  initialIsClicked = false,
  message,
  buttonMessage,
  buttonClickedMessage,
  onClickAction,
  variant = 'SECONDARY'
}: ActionMessageProps) => {
  const [isClicked, setIsClicked] = useState(initialIsClicked);

  const handleResend = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      return onClickAction()
        .then(() => setIsClicked(prev => !prev))
        .catch(onError);
    },
    [onClickAction, onError]
  );

  return (
    <Container data-test="message-confirm-email" variant={variant}>
      <Content>
        <span>{message}</span>

        <div>
          {buttonClickedMessage && isClicked ? (
            <span>{buttonClickedMessage}</span>
          ) : (
            <ResendButton
              onClick={handleResend}
              data-testid="auction-message-button"
            >
              {buttonMessage}
            </ResendButton>
          )}
        </div>
      </Content>
    </Container>
  );
};

export default ActionMessage;
