import { MainCategory, mainCategoryParams } from './MainCategoryTypes';

export const buyMethodTypes = ['buy_now', 'bidding'] as const;

export type CategoryOption = { value: MainCategory | ''; label: string };

export const toggleSearchParams = {
  auctionType: buyMethodTypes,
  vehicleType: mainCategoryParams
} as const;

export type ToggleSearchParamKey = keyof typeof toggleSearchParams;

export type BuyMethodType = (typeof toggleSearchParams)['auctionType'][number];

export const carSubCategories = ['car'] as const;

export type CarSubCategory = (typeof carSubCategories)[number];

export const lightTransportSubCategories = [
  'light_truck',
  'light_trailer',
  'light_transport_other'
] as const;

export type LightTransportSubCategory =
  (typeof lightTransportSubCategories)[number];

export const heavyTransportSubCategories = [
  'heavy_truck',
  'heavy_trailer',
  'heavy_transport_other'
] as const;

export type TransportSubCategoryHeavy =
  (typeof heavyTransportSubCategories)[number];

export const machineSubCategories = [
  'construction_machine',
  'excavator',
  'dumper_hauler',
  'loading_machine',
  'motorized_equipment',
  'compact_tractor',
  'riding_mower',
  'tractor',
  'machine_other'
] as const;

export type MachineSubCategory = (typeof machineSubCategories)[number];

export const recreationalSubCategories = [
  'trailer',
  'mc_moped',
  'camper',
  'mobile_home',
  'recreational_other',
  /**
   * @deprecated should use recreationalSubCategory in the future
   *  TODO: Removed in the future
   */
  'lighttruck',
  'recreationalvehicle',
  'recreational_vehicle',
  'caravan',
  'boat',
  'other'
] as const;

export type RecreationalSubCategory =
  (typeof recreationalSubCategories)[number];

export const subCategories = [
  ...carSubCategories,
  ...lightTransportSubCategories,
  ...heavyTransportSubCategories,
  ...machineSubCategories,
  ...recreationalSubCategories
] as const;

export const regularFuelOptions = [
  'petrol',
  'diesel',
  'electric_hybrid',
  'petrol_ethanol',
  'petrol_methane',
  /**
   * @deprecated should use recreationalSubCategory in the future
   *  TODO: Removed in the future
   */
  'hybrid',
  'ethanol',
  'electric_diesel',
  'electric_petrol',
  'petrol_lpg'
] as const;

export type RegularFuelOption = (typeof regularFuelOptions)[number];

export const environmentalFuelOptions = [
  'electric',
  'plugin_hybrid',
  'methane'
] as const;

export type EnvironmentalFuelOption = (typeof environmentalFuelOptions)[number];

export type FuelOption = RegularFuelOption | EnvironmentalFuelOption;

export const gearboxOptions = ['manual', 'automatic'] as const;

export type GearboxOption = (typeof gearboxOptions)[number];

export const otherSearchParams = {
  certified: 'certified',
  ecoFriendly: 'eco_friendly',
  exportable: 'exportable',
  countdownDate: 'countdown_date',
  deductibleVat: 'deductible_vat',
  releasable: 'releasable',
  reducedPrice: 'reduced_price'
} as const;

export type OtherSearchParamKey = keyof typeof otherSearchParams;

export const otherOptions = [...Object.values(otherSearchParams)] as const;

export type OtherOption = (typeof otherOptions)[number];

export const vehicleBodyTypes = [
  'sedan',
  'wagon',
  'suv',
  'coupe',
  'convertible',
  'minivan',
  'pick-up'
] as const;

export type VehicleBodyType = (typeof vehicleBodyTypes)[number];

/**
 * @deprecated should use recreationalSubCategory in the future
 *  TODO: Removed in the future
 */

export const colorOptions = [
  'red',
  'cyan',
  'gray',
  'grey',
  'blue',
  'green',
  'white',
  'black',
  'yellow',
  'orange',
  'silver',
  'purple',
  'brown'
] as const;

/**
 * @deprecated should use recreationalSubCategory in the future
 *  TODO: Removed in the future
 */
export type ColorOption = (typeof colorOptions)[number];

/**
 * @deprecated should use recreationalSubCategory in the future
 *  TODO: Removed in the future
 */
export const equipmentOptions = [
  'towbar',
  'cabin_heater',
  'full_leather',
  'parking_assist',
  'panoramic_roof',
  'awd',
  'gps_navigation',
  'automatic_folding_mirrors'
] as const;

/**
 * @deprecated should use recreationalSubCategory in the future
 *  TODO: Removed in the future
 */
export type EquipmentOption = (typeof equipmentOptions)[number];

export const CountyOptions = [
  'Blekinge',
  'Dalarna',
  'Gotland',
  'Gävleborg',
  'Halland',
  'Jämtland',
  'Jönköping',
  'Kalmar',
  'Kronoberg',
  'Norrbotten',
  'Skåne',
  'Stockholm',
  'Södermanland',
  'Uppsala',
  'Värmland',
  'Västerbotten',
  'Västernorrland',
  'Västmanland',
  'Västra Götaland',
  'Örebro',
  'Östergötland'
] as const;

export const multiselectSearchParams = {
  objectTypes: [
    ...carSubCategories,
    ...lightTransportSubCategories,
    ...heavyTransportSubCategories,
    ...machineSubCategories,
    ...recreationalSubCategories
  ],
  brand: [] as string[],
  facilities: [] as string[],
  familyName: [] as string[],
  fuel: [...regularFuelOptions, environmentalFuelOptions],
  gearbox: gearboxOptions,
  vehicleBody: vehicleBodyTypes,
  counties: CountyOptions,
  // legacy filters
  equipment: equipmentOptions,
  color: colorOptions
} as const;

export type MultiselectSearchParamKey = keyof typeof multiselectSearchParams;

export type SubCategory =
  (typeof multiselectSearchParams)['objectTypes'][number];
export type Brand = (typeof multiselectSearchParams)['brand'][number];
export type Facility = (typeof multiselectSearchParams)['facilities'][number];
export type FamilyName = (typeof multiselectSearchParams)['familyName'][number];
export type Fuel = (typeof multiselectSearchParams)['fuel'][number];
export type Gearbox = (typeof multiselectSearchParams)['gearbox'][number];
export type VehicleBody =
  (typeof multiselectSearchParams)['vehicleBody'][number];
export type Equipment = (typeof multiselectSearchParams)['equipment'][number];

export const intervalSearchParamLabelKeys = [
  'year',
  'odometerReading',
  'cardealerPrice',
  'buyNowAmount',
  'hp',
  'grade',
  'bid',
  'co2',
  'releasingCost',
  'loanCost',
  'operatingHours',
  'numSeats'
] as const;

export type IntervalSearchParamLabelKey =
  (typeof intervalSearchParamLabelKeys)[number];

export const intervalSearchParamKeys = [
  'yearFrom',
  'yearTo',
  'odometerReadingFrom',
  'odometerReadingTo',
  'cardealerPriceFrom',
  'cardealerPriceTo',
  'buyNowAmountFrom',
  'buyNowAmountTo',
  'hpFrom',
  'hpTo',
  'gradeFrom',
  'gradeTo',
  'bidFrom',
  'bidTo',
  'co2From',
  'co2To',
  'releasingCostFrom',
  'releasingCostTo',
  'loanCostFrom',
  'loanCostTo',
  'operatingHoursTo',
  'operatingHoursFrom',
  'numSeatsFrom',
  'numSeatsTo'
] as const;

export type IntervalSearchParamKey = (typeof intervalSearchParamKeys)[number];

export const termsSearchParams = 'terms' as const;
export type TermsSearchParamKey = typeof termsSearchParams;
export type TermsSearchParamValue = string;

export type FilterSearchParamsSingleValue = string | number | boolean | null;
export type FilterSearchParamsArrayValue =
  | string[]
  | number[]
  | boolean[]
  | null;

export const filterSearchParams = {
  ...toggleSearchParams,
  ...multiselectSearchParams,
  ...otherSearchParams,
  terms: termsSearchParams
} as const;

export const filterSearchParamValues = [
  ...mainCategoryParams,
  ...subCategories,
  ...buyMethodTypes,
  ...vehicleBodyTypes,
  ...regularFuelOptions,
  ...environmentalFuelOptions,
  ...gearboxOptions,
  ...otherOptions,
  ...colorOptions,
  ...equipmentOptions
] as const;

export type FilterSearchParams = {
  [key in FilterSearchParamKey]: FilterSearchParamsValue;
};

export type FilterSearchParamKey =
  | keyof typeof filterSearchParams
  | IntervalSearchParamKey
  | IntervalSearchParamLabelKey
  | 'other';

export type FilterSearchParamsValue = (typeof filterSearchParamValues)[number];

export type SearchParamsValueExtended =
  | FilterSearchParamsValue
  | FilterSearchParamsSingleValue
  | FilterSearchParamsArrayValue;

export const orderBySearchParamKey = 'orderBy' as const;

export type OrderSearchParamKey = typeof orderBySearchParamKey;

export type FilterSearchParamWithOrderKey =
  | FilterSearchParamKey
  | OrderSearchParamKey;

export type FilterValue = {
  label: string;
  value: string;
  searchParamKey: FilterSearchParamKey;
}; // Auction search stuff
