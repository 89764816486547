import { TextField as BaseTextField } from '@kvdbil/components';
import { TextFieldProps as BaseTextFieldProps } from '@kvdbil/components/types/components/Textfield';
import React, { RefObject } from 'react';
import {
  Control,
  Controller,
  Path,
  PathValue,
  RegisterOptions,
  UnpackNestedValue
} from 'react-hook-form';

type ControllerBaseValue = string | number | readonly string[] | undefined;
export type TextFieldProps<FormState> = Omit<BaseTextFieldProps, 'value'> & {
  control: Control<FormState>;
  name: Path<FormState>;
  rules?: RegisterOptions;
  defaultValue?: UnpackNestedValue<
    PathValue<FormState, (string | undefined) & Path<FormState>>
  >;
};

const Input = React.forwardRef((props: BaseTextFieldProps, ref) => (
  <BaseTextField {...props} forwardRef={ref as RefObject<HTMLInputElement>} />
));

const TextFieldWithControl = <FormState,>(props: TextFieldProps<FormState>) => {
  return (
    <Controller
      {...props}
      render={({ field, fieldState }) => {
        return (
          <Input
            {...props}
            {...field}
            value={field.value as ControllerBaseValue}
            errors={
              fieldState.error?.message
                ? [fieldState.error?.message]
                : undefined
            }
            hasError={Boolean(fieldState.error)}
          />
        );
      }}
    />
  );
};

export default TextFieldWithControl;
