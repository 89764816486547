import { Color } from '@kvdbil/components/types/Types';

export enum ProductCardVariant {
  Regular = 'regular',
  CarGuide = 'carGuide'
}

export interface ImageProps {
  imageSrc: string;
  imageSrcHover: string;
  imageAlt: string;
  isNew: boolean;
  changeImageOpacity: number;
}

export interface FinancingItemProps {
  label: string;
  value: string;
  isPrice: boolean;
  initialPrice?: number | null;
}

export interface FinancingProps {
  rows: FinancingItemProps[];
  color: Color;
}

export interface VehicleCardSmallProps {
  color: Color;
  imageProps: ImageProps;
  title: string;
  financingProps: FinancingProps;
}
