import { fromPairs } from 'ramda';
import logger from '~/helpers/logger';

export type GetAll = () => Map<string, string>[];

declare global {
  interface Window {
    ga?: {
      getAll: GetAll;
    } | null;
  }
}

export const formatCookieString = (cookieStr: string) => {
  const cookies = cookieStr.split(';');

  const pairs = cookies.map(cookie => {
    const [key, value] = cookie.split('=');
    return [key.trim(), value?.trim() ?? ''] as [string, string];
  });

  return fromPairs(pairs);
};

export const set = (name: string, value: string, days: number) => {
  let expires = '';

  if (days) {
    const date = new Date();

    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }

  document.cookie = `${name}=${value}${expires}; path=/`;
};

export const get = (name: string) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');

  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];

    while (c.startsWith(' ')) {
      c = c.substring(1, c.length);
    }

    if (c.startsWith(nameEQ)) {
      return c.substring(nameEQ.length, c.length);
    }
  }

  return null;
};

export const getGaClientId = () => {
  if (typeof window === undefined) {
    return;
  }

  const getAll = window?.ga?.getAll;

  try {
    if (getAll) {
      const [item] = getAll() ?? [];
      return item?.get('clientId') || null;
    }
  } catch (err: unknown) {
    logger.error(err);
  }

  return null;
};
