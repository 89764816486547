import { ActiveAuction } from './ActiveAuction';
import { PreviewImage } from './PreviewImage';
import ProcessObject from './ProcessObject';
import { Bid } from './Bid';
import { Releasing } from './Releasing';
import { CheckoutOrderBase } from './store/Checkout';
import { Nullable } from '../types/Nullable';

export enum AuctionType {
  'BIDDING' = 'BIDDING',
  'BUY_NOW' = 'BUY_NOW'
}

export default interface Auction {
  id: string;
  activeAuction: ActiveAuction | null;
  countdownStartAt: Nullable<string>;
  previewTitle?: string;
  previewPreamble?: string;
  previewPackage?: string;
  allowedToBid: boolean;

  auctionUrl: string;
  auctionType: AuctionType;
  auctionViews: number;
  biddingFee: string;
  biddingFeeCorporate: string;

  buyNow: boolean;
  buyNowAmount: Nullable<number>;
  buyNowAvailable: boolean;
  buyNowUntil?: string;
  isSoldByBuyNow: boolean;
  isReserved: boolean;

  winningBid: Nullable<Bid>;

  readyForCheckout: boolean;
  cancelledBids?: number[];

  countdownDuration: number;
  currency: string;
  eligibleForAdditionalServices: boolean;

  maxAllowedBid: Nullable<number>;
  maxBidRaise: number;
  mediationFee: Nullable<number>;
  mediationFeeCorporate: Nullable<number>;
  minBidRaise: number;
  reservationPrice: Nullable<number>;

  paymentLink?: string;
  previewImage: string;
  previewImages: PreviewImage[];

  processObject: ProcessObject;
  processObjectId: string;

  releasing: Nullable<Releasing>;

  sellMethod: Nullable<AuctionType>;
  similarAuctions?: Auction[];
  slug: string;
  soldFor: Nullable<string>;
  startBid: number;
  state:
    | 'PREPARING'
    | 'SCHEDULING'
    | 'STARTING'
    | 'OPEN'
    | 'ABORTED'
    | 'EVALUATING'
    | 'BROKERING'
    | 'WINNER_CHECKOUT_PENDING'
    | 'PAYMENT_SEQUENCE_PENDING'
    | 'FINISHED';

  targetedAuctionGroupId: Nullable<number>;
  winnerBid?: Bid;

  openedAt: string;
  closedAt: Nullable<string>;
  createdAt: string;
  updatedAt: string;

  checkoutOrder?: Nullable<CheckoutOrderBase>;

  comingSoon?: boolean;
  preliminaryPrice: Nullable<number>;

  eurBuyNowAmount?: Nullable<number>;
}
export interface AuctionLoggedInStates {
  isWinner: boolean;
  isLeading: boolean;
  isOverBidded: boolean;
  isParticipating: boolean;
}

export interface AuctionStates extends AuctionLoggedInStates {
  isCertified: boolean;
  isClosed: boolean;
  isLeading: boolean;
}

export interface AuctionNotFound {
  auctionId: string;
  brand: string;
  familyName: string;
  createdAt: string;
  updatedAt: boolean;
}

export interface AuctionSitemap {
  id: string;
  auctionType: AuctionType;
  slug: string;
  closedAt: string | null;
  updatedAt: string;
}
