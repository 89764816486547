import React from 'react';
import { ProductCardVariant } from '~/App/shared/components/ProductCard/types';
import Auction from '~/App/shared/interfaces/Auction';
import ProductCardRegular from '~/App/shared/components/ProductCard/ProductCardRegular';
import ProductCardCarGuide from '~/App/shared/components/ProductCard/ProductCardCarGuide';
import AuctionLoadingCard from '~/App/shared/components/AuctionLoadingCard';
import { CarGuideCardProps } from '~/App/views/Carguide/types';

export interface ProductCardProps {
  auction: Auction & {
    isLoader?: boolean;
    carGuideCardProps?: CarGuideCardProps;
  };
  variant: ProductCardVariant;
}
const ProductCard = ({ auction, variant }: ProductCardProps) => {
  if (auction?.isLoader) {
    return <AuctionLoadingCard />;
  }

  switch (variant) {
    case ProductCardVariant.Regular:
      return <ProductCardRegular auction={auction} />;
    case ProductCardVariant.CarGuide:
      return <ProductCardCarGuide auction={auction} />;
    default:
      return null;
  }
};

export default ProductCard;
