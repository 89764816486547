import React, { useState } from 'react';
import { useTranslation } from '~/Locale';
import { createUuid } from '~/helpers/id';
import { translateRoute } from '~/App/shared/localization';
import { Button, FacebookIcon } from '@kvdbil/components';
import { requestErrorHandler } from '~/helpers/notifyError';
import { loginSocial } from '~/helpers/orchestration/auth';
import { ButtonProps } from '@kvdbil/components/types/components/Button';
import styled from 'styled-components';

const StyledOptionsButton = styled(Button)`
  gap: 1rem;
`;

interface Props extends Omit<ButtonProps, 'children'> {
  children?: React.ReactNode;
}

const FacebookAuthButton = ({ children, ...props }: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const facebookSignIn = () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const nonceUuid = createUuid();
    const stateUuid = createUuid();
    let redirectOrigin = window.location.origin;

    if (!redirectOrigin) {
      redirectOrigin = `${window.location.protocol}//${window.location.host}`;
    }

    const translatedRegisterRoute = translateRoute(
      t,
      'ROUTES.MEMBER_REGISTER_PRIVATE'
    );
    const shouldRedirectToStart = window.location.pathname.includes(
      translatedRegisterRoute
    );

    const state = {
      token: stateUuid,
      route: shouldRedirectToStart ? redirectOrigin : window.location.pathname
    };

    window?.localStorage?.setItem('preSocialLoginState', JSON.stringify(state));

    loginSocial({
      state: stateUuid,
      nonce: nonceUuid,
      redirectUri: `${redirectOrigin}/social`,
      idpHint: 'facebook'
    })
      .then(response => {
        window.location.assign(response.data.uri);
      })
      .catch(requestErrorHandler)
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <StyledOptionsButton
      fullWidth
      onClick={facebookSignIn}
      isLoading={isLoading}
      variant="outline"
      color="neutral"
      style={{ justifyContent: 'center' }}
      {...props}
    >
      <FacebookIcon fontSize="1.5rem" />
      {children || t('Facebook')}
    </StyledOptionsButton>
  );
};

export default FacebookAuthButton;
