import { createClient, fetchExchange, cacheExchange } from 'urql/';
import { devtoolsExchange } from '@urql/devtools';
import {
  datoAccessToken,
  datoHost,
  isProductionEnvironment,
  isDevelopment,
  cmsProxyHost,
  baseUrl,
  isServer
} from './public/environment';

type Options = {
  useExternalCaching?: boolean;
};

export const createGraphqlClient = (opts?: Options) => {
  const { useExternalCaching = false } = opts ?? {};

  return createClient({
    url:
      useExternalCaching && isProductionEnvironment ? cmsProxyHost : datoHost,
    requestPolicy: 'network-only',
    fetchOptions: () => {
      return {
        headers: {
          authorization: `Bearer ${datoAccessToken}`,
          origin: isServer ? baseUrl : '',
          'X-Base-Editing-Url': 'https://kvd.admin.datocms.com'
        }
      };
    },
    suspense: isServer,
    exchanges: [
      ...(isDevelopment ? [devtoolsExchange] : []),
      cacheExchange,
      fetchExchange
    ]
  });
};
