import React, { useCallback } from 'react';
import { BodyLink, BodyText, Button, Stack } from '@kvdbil/components';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from '~/Locale';
import { sessionStart } from '~/App/shared/actions/session';
import {
  getGAUserFromState,
  trackGA4Login
} from '~/helpers/client/ga4TrackEvent';
import { clientMemberEmail as clientMemberEmailSelector } from '~/App/shared/selectors/sellFlowSelectors';
import { session as sessionSelector } from '~/App/shared/selectors/sessionSelector';
import { EmailField, PasswordField } from '../../../Fields/shared';
import { Session } from '~/App/shared/interfaces/store/Session';
import store from '~/config/store';
import { handleSessionErrors } from '../../helpers';

const Form = styled.form`
  text-align: left;
`;

const StyledButton = styled(Button)`
  padding: 1rem 1.5rem;
`;

const StyledBodyText = styled(BodyLink)`
  text-align: center;
  color: ${({ theme }) => theme.colors.text.dark};
  cursor: pointer;
`;

type Props = {
  onSignIn?(session?: Session): void;
  onFail(error: string): void;
  onPressForgotPassword(email?: string): void;
  initialEmail?: string;
};

export const SignInForm = ({
  onSignIn,
  onFail,
  onPressForgotPassword,
  initialEmail
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const session = useSelector(sessionSelector);
  const clientMemberEmail = useSelector(clientMemberEmailSelector);

  const { handleSubmit, control, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: initialEmail || clientMemberEmail || '',
      password: ''
    }
  });

  const onSubmit = useCallback(() => {
    const signIn = async () => {
      const { email, password } = getValues();

      const data = (await dispatch(
        sessionStart({ auth: { email: email, password: password } })
      )) as Session;

      if (data?.errors) {
        const errorMessage = handleSessionErrors(t, data.errors);
        onFail(errorMessage);
        return;
      }

      if (data?.isAuthenticated) {
        if (store) {
          // TODO: should be handled better then this...
          trackGA4Login(
            { login_type: 'kvd_login' },
            getGAUserFromState(store.getState())
          );
        }

        onSignIn?.(data);
      }
    };

    void signIn();
  }, [dispatch, getValues, onFail, onSignIn, t]);

  const email = useWatch({
    control,
    name: 'email'
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack rowGap={1}>
        {initialEmail && (
          <BodyText style={{ textAlign: 'center' }}>
            {t('Login with password...')}
          </BodyText>
        )}
        <EmailField
          control={control}
          t={t}
          data-testid="sign-in-email"
          isDisabled={Boolean(initialEmail)}
        />

        <PasswordField
          control={control}
          t={t}
          data-testid="sign-in-password"
          rules={{
            required: { value: true, message: t(`Password can't be blank`) }
          }}
        />

        <StyledButton
          aria-label="Login Button"
          variant="solid"
          color="primary"
          isLoading={session?.isLoading}
          data-testid="sign-in-submit"
        >
          {t('Sign in')}
        </StyledButton>

        <StyledBodyText
          onClick={() => onPressForgotPassword(email)}
          data-testid="forgot-password-link"
        >
          {t('Forgot password?')}
        </StyledBodyText>
      </Stack>
    </Form>
  );
};
