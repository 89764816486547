export const sortBy =
  <T>(key: keyof T) =>
  (a: T, b: T) => {
    const first = a[key];
    const second = b[key];
    const aName = typeof first === 'string' ? first.toLowerCase() : first;
    const bName = typeof second === 'string' ? second.toLowerCase() : second;

    if (aName < bName) {
      return -1;
    }

    if (aName > bName) {
      return 1;
    }

    return 0;
  };
