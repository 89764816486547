import { baseUrl } from '~/config/public/environment';
import { isUrlAbsolute } from '~/helpers/url';
import { TranslateFunction } from '~/Locale';

const trimInitialSlash = (url: string) =>
  url.startsWith('/') ? url.substring(1) : url;

export default function translateRoute(t: TranslateFunction, url?: string) {
  if (typeof url !== 'string') {
    return '';
  }

  if (isUrlAbsolute(url)) {
    return url;
  }

  const inputStartsWithSlash = url.startsWith('/');
  const { pathname, search } = new URL(url, baseUrl);

  const translatedPathname = pathname
    .split('/')
    .map(name => {
      if (name && name.startsWith('ROUTES.')) {
        return t(name) || name;
      }

      return name;
    })
    .join('/');

  const trimmedRoute = inputStartsWithSlash
    ? translatedPathname
    : trimInitialSlash(translatedPathname);

  return trimmedRoute + search;
}

export function getTranslatedDynamicPageRoute(
  t: TranslateFunction,
  routeKey: 'aboutKvdTerms' | 'testReadMore'
) {
  switch (routeKey) {
    case 'aboutKvdTerms':
      return t('ROUTES.ABOUT_KVD_TERMS');
    case 'testReadMore':
      return t('ROUTES.TEST_READ_MORE');
    default:
      throw Error('Missing route key for dynamic pages routes!!!');
  }
}
