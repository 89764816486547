import React from 'react';
import styled from 'styled-components';
import YouTubeBigThumbnail from './YouTubeBigThumbnail';

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 62.5%; // This is to keep the aspect ratio correct
`;

const StyledVideo = styled.div`
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const StyledIframe = styled.iframe`
  border: none;
`;

type Props = {
  videoId: string;
  altText?: string;
  autoplay?: boolean;
};

function YouTubePlayer({ videoId, autoplay = false, altText }: Props) {
  return (
    <VideoWrapper>
      <StyledVideo>
        <StyledIframe
          title={altText}
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${videoId}?autoplay=${autoplay}&enablejsapi=1&rel=0`}
          allow="autoplay"
        />
      </StyledVideo>
    </VideoWrapper>
  );
}

export const YouTubeMedia = ({
  playVideo,
  currentVideoId,
  videoId,
  thumbnail,
  altText
}: {
  playVideo: boolean;
  currentVideoId: string;
  videoId: string;
  thumbnail?: string;
  altText: string;
}) => {
  if (!thumbnail) {
    return <YouTubePlayer videoId={videoId} altText={altText} />;
  }

  if (playVideo && currentVideoId === videoId) {
    return (
      <YouTubePlayer videoId={videoId} autoplay={true} altText={altText} />
    );
  }

  return <YouTubeBigThumbnail thumbnailSrc={thumbnail} altText={altText} />;
};

export default YouTubePlayer;
