import { DrawerMenuAction } from '../actions/drawerMenu';
import { ReduxStore } from '../interfaces/store';

type DrawerMenuState = ReduxStore['drawerMenu'];

export const initialState: DrawerMenuState = {
  isOpen: false,
  view: null
};

export const drawerMenuReducer = (
  state: DrawerMenuState = initialState,
  action: DrawerMenuAction
): ReduxStore['drawerMenu'] => {
  switch (action.type) {
    case 'RESET_MENU_DRAWER':
      return initialState;
    case 'CLOSE_MENU_DRAWER':
      return {
        ...state,
        isOpen: false
      };
    case 'OPEN_MENU_DRAWER':
      return {
        ...state,
        isOpen: true,
        view: action.payload
      };
    default:
      return state;
  }
};

export default drawerMenuReducer;
