import {
  GetWonAuctionsSuccessAction,
  HydrateWonAuctionsSuccessAction
} from '~/App/shared/actions/wonAuctions';
import { ReduxStore } from '../interfaces/store';
import { WonAuctions } from '../interfaces/store/WonAuctions';

type WonAuctionsType =
  | HydrateWonAuctionsSuccessAction
  | GetWonAuctionsSuccessAction;

const wonAuctions = (
  state = [] as WonAuctions,
  action: WonAuctionsType
): ReduxStore['wonAuctions'] => {
  switch (action.type) {
    case 'HYDRATE_WON_AUCTIONS_SUCCESS':
      return action.payload;

    case 'GET_MY_WON_AUCTIONS_SUCCESS':
      return action.payload ?? [];
    default:
      return state;
  }
};

export default wonAuctions;
