import { orchestration } from '~/config/public';
import axios from '~/helpers/orchestration/axiosWithAuthHandling';

export const submitPartnerLeasingForm = ({
  fullName,
  email,
  phoneNumber
}: {
  fullName: string;
  email: string;
  phoneNumber: string;
}) => {
  return axios.post(
    `${orchestration.url}/common/form-submission`,
    {
      formSubmission: {
        fullName: fullName,
        email: email,
        phoneNumber: phoneNumber
      }
    },
    {
      responseType: 'json'
    }
  );
};
