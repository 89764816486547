import styled from 'styled-components';
import React from 'react';
import Status, {
  TextItem
} from '~/App/shared/components/ProductCard/components/Status';
import { mq } from '@kvdbil/components';

const Container = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;

  ${mq('tablet')} {
    margin-bottom: 0.25rem;
  }
`;

export interface AuctionStatusProps {
  items: TextItem[];
}

const AuctionStatusForCard = ({ items }: AuctionStatusProps) => {
  return (
    <Container>
      {items.map(item => (
        <Status
          key={item.label}
          color={item.color}
          icon={item.icon}
          label={item.label}
        />
      ))}
    </Container>
  );
};

export default AuctionStatusForCard;
