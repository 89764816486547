import { LDContext } from 'launchdarkly-node-server-sdk';
import React, {
  ComponentType,
  createContext,
  ReactNode,
  useContext
} from 'react';
import {
  FeatureFlags,
  FeatureFlag,
  FeatureFlagVariant,
  FeatureFlagsObject,
  LDClientClientSide
} from '../shared/interfaces/FeatureFlags';
import { requestErrorHandler } from '~/helpers/notifyError';
import { setFeatureFlagAction } from '../shared/reducers/featureFlags';
import { ReduxDispatch } from '../shared/interfaces/Redux';
import { isEmpty } from '../shared/utils';

export const LD_ANONYMOUS_KEY = 'anonymous';

interface FeatureFlagsProviderContext extends FeatureFlagsObject {
  client: LDClientClientSide | undefined;
}

export const FeatureFlagContext = createContext<FeatureFlagsProviderContext>({
  flags: {},
  user: {} as LDContext,
  client: {} as LDClientClientSide
});

/* DISCLAIMER: Do not export or use this function directly  */
const getFlags = (): FeatureFlags => {
  const flags = (global?._INITIAL_DATA_?.featureFlags as FeatureFlagsObject)
    ?.flags;
  return flags ?? {};
};

const getUser = () => {
  const user = (global?._INITIAL_DATA_?.featureFlags as FeatureFlagsObject)
    ?.user;
  return user;
};

export const createFeatureFlagObject = (
  value: FeatureFlagVariant | undefined,
  isExperiment: boolean | undefined
) => ({
  value: value ?? false,
  isExperiment: isExperiment ?? false
});

interface FeatureFlagProviderProps {
  flags?: FeatureFlags;
  user?: LDContext;
  client?: LDClientClientSide;
  children?: ReactNode;
}

export const FeatureFlagProvider = ({
  children,
  flags,
  user,
  client
}: FeatureFlagProviderProps) => {
  const featureFlags = getFlags();
  const featureFlagsUser = getUser();

  return (
    <FeatureFlagContext.Provider
      value={{
        flags: flags ?? featureFlags,
        user: user ?? featureFlagsUser,
        client
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlag = (flag: FeatureFlag) => {
  const { flags } = useContext(FeatureFlagContext);

  const data = isEmpty(flags) ? getFlags() : flags;
  const featureFlag = data?.[flag];

  return featureFlag ?? createFeatureFlagObject(false, false); // Return a default value
};
export const useFeatureFlagValue = (
  flag: FeatureFlag,
  variant?: FeatureFlagVariant
): boolean => {
  const { flags } = useContext(FeatureFlagContext);
  const data = isEmpty(flags) ? getFlags() : flags;
  const userFlag = data?.[flag]?.value;

  // When we don't have a value
  if (!userFlag && typeof userFlag !== 'boolean') {
    return false;
  }

  // When we have a boolean value with no variant
  if (typeof userFlag === 'boolean' && typeof variant === 'undefined') {
    return userFlag;
  }

  // When we have a variant
  return userFlag === variant;
};

export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagContext);
  return context;
};

export const useLDClient = () => {
  const context = useContext(FeatureFlagContext);
  return context?.client;
};

interface LDSendCustomEventArgs {
  client: LDClientClientSide | undefined;
  event: string;
  user?: LDContext;
  data?: object;
  isExperiment?: boolean;
}

// For client side use
export const LDSendCustomEvent = ({
  client,
  event,
  data,
  isExperiment // To prevent sending events to LD when we are not in an experiment
}: LDSendCustomEventArgs) => {
  if (client && isExperiment) {
    client.track(event, data); // Sending data works but can only be exported if we have the enterprise plan
    // Our workaround is to send events to our own event data store
    // Check postABTestEvent
  }
};

interface GetFeatureFlagVariationArgs {
  client: LDClientClientSide | undefined;
  user?: LDContext;
  flag: FeatureFlag;
  dispatch?: ReduxDispatch;
}

export const getFeatureFlagVariation = async <T extends boolean>({
  client,
  user,
  flag,
  dispatch
}: GetFeatureFlagVariationArgs) => {
  if (client) {
    return client
      ?.waitUntilReady()
      .then(async () => {
        if (user && client.getContext().key !== user.key) {
          await client.identify(user);
        }

        const variationDetail = client?.variationDetail(flag);
        const flagDetails = variationDetail.value;
        const flagValue: T = flagDetails.value;

        if (dispatch) {
          dispatch(
            setFeatureFlagAction({
              [flag]: createFeatureFlagObject(
                flagValue,
                flagDetails?.isExperiment
              )
            })
          );
        }
        return flagValue;
      })
      .catch((error: unknown) => {
        requestErrorHandler(error);
        return null;
      });
  }

  return null;
};

export const withFeature =
  (flag: FeatureFlag) =>
  (Page: ComponentType, Fallback?: ComponentType) =>
  (props: object): JSX.Element | null => {
    const isFeatureActive = useFeatureFlagValue(flag);

    if (isFeatureActive) {
      return <Page {...props} />;
    }

    return Fallback ? <Fallback /> : null;
  };

export const createLDUserContext = (
  user: Partial<LDContext>,
  key: string | null
) => {
  return {
    kind: 'user',
    ...user,
    key: key ?? LD_ANONYMOUS_KEY
  } as LDContext;
};

interface FeatureFlagProps {
  flag: FeatureFlag;
  variant?: FeatureFlagVariant;
  children: React.ReactNode;
}
export const Feature = ({ children, flag, variant }: FeatureFlagProps) => {
  const isActive = useFeatureFlagValue(flag, variant);

  if (!isActive) {
    return null;
  }

  return <React.Fragment>{children}</React.Fragment>;
};
