import { theme } from '@kvdbil/components';
import React from 'react';
import Select, { StylesConfig } from 'react-select';
import { objectSearchFieldOptionStyle } from './helpers';
import { CSSObject } from 'styled-components';

interface Props extends React.ComponentProps<typeof Select> {
  searchFieldOpen?: boolean;
}

const SearchFieldCategorySelectMobile = ({
  name = 'category',
  searchFieldOpen,
  ...props
}: Props) => {
  const styles: StylesConfig = {
    container: (styles, { selectProps: { menuIsOpen } }) => ({
      ...styles,
      ...({
        background: menuIsOpen ? theme.colors.background.light : 'inherit',
        borderTopLeftRadius: '30px',
        borderTopRightRadius: '30px'
      } as CSSObject)
    }),
    control: styles => ({
      ...styles,
      ...({
        border: 'none',
        color: theme.colors.text.dark,
        lineHeight: '1.5rem',
        cursor: 'pointer',
        boxShadow: 'none',
        height: '60px',
        background: 'inherit',
        paddingInline: '1rem',
        borderTopLeftRadius: '30px',
        borderTopRightRadius: '30px',
        '& svg': {
          color: theme.colors.text.dark,
          width: '1.5rem',
          height: '1.5rem'
        }
      } as CSSObject)
    }),
    placeholder: styles => ({
      ...styles,
      ...({
        color: theme.colors.text.dark
      } as CSSObject)
    }),
    menu: styles => ({
      ...styles,
      ...({
        position: 'absolute',
        borderRadius: 0,
        marginTop: 0,
        borderBottomLeftRadius: '30px',
        borderBottomRightRadius: '30px',
        paddingBottom: '1rem',
        boxShadow:
          '0px 5px 5px rgba(0, 0, 0, 0.15), 0px 4px 5px rgba(0, 0, 0, 0.09), 0px 2px 4px rgba(0, 0, 0, 0.1)', // theme.elevations.elevation4, but the Y axis is greater and spread is less so that it doesn't overlap the select
        top: 'auto',
        zIndex: 10000
      } as CSSObject)
    }),
    menuList: styles => ({
      ...styles,
      ...({
        paddingBlock: 0,
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px'
      } as CSSObject)
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      ...({
        ...objectSearchFieldOptionStyle(isSelected)
      } as CSSObject)
    })
  };

  return (
    <Select
      {...props}
      name={name}
      styles={styles}
      isSearchable={false}
      components={{
        IndicatorSeparator: () => null
      }}
    />
  );
};

export default SearchFieldCategorySelectMobile;
