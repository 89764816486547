import { Spinner, theme } from '@kvdbil/components';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from '~/Locale';
import { useDynamicScript } from '../hooks/useDynamicScript';
import { useInterval } from '../hooks/useIntervalHook';
import { Fade } from './Fade/Fade';
import Link from './Link';
import { useSelector } from 'react-redux';
import { localizationSelector } from '~/App/shared/selectors/localization';
import { Locale } from '~/App/shared/localization/types';

declare global {
  interface Window {
    Trustpilot: {
      loadFromElement: (e: HTMLElement | null, b: boolean) => void;
    };
  }
}

type TrustPilotEssentials =
  | 'Horizontal'
  | 'MicroCombo'
  | 'Mini'
  | 'MicroTrustScore';

type TrustPilotReviews =
  | 'Carousel'
  | 'Slider'
  | 'SliderSeller'
  | 'MiniCarousel';

export type TrustPilotType = TrustPilotEssentials | TrustPilotReviews;

type BoxConfig = {
  templateId: string;
  height: string;
  hasReviews?: boolean;
  tags?: string;
};

interface Props {
  type: TrustPilotType;
  stars?: number;
  checkDelay?: number;
  retries?: number;
  disableLinks?: boolean; // To prevent invalid DOM nesting because of links
}

const businessunitId = '58876b740000ff00059b61e7';
const boxTypes: Record<TrustPilotType, BoxConfig> = {
  Horizontal: {
    templateId: '5406e65db0d04a09e042d5fc',
    height: '30px'
  },
  MicroCombo: {
    templateId: '5419b6ffb0d04a076446a9af',
    height: '24px'
  },
  Mini: {
    templateId: '53aa8807dec7e10d38f59f32',
    height: '150px'
  },
  MicroTrustScore: {
    templateId: '5419b637fa0340045cd0c936',
    height: '24px'
  },
  Carousel: {
    templateId: '53aa8912dec7e10d38f59f36',
    height: '140px',
    hasReviews: true
  },
  Slider: {
    templateId: '54ad5defc6454f065c28af8b',
    height: '245px',
    hasReviews: true
  },
  SliderSeller: {
    templateId: '54ad5defc6454f065c28af8b',
    height: '240px',
    tags: 'SELLER',
    hasReviews: true
  },
  MiniCarousel: {
    templateId: '539ad0ffdec7e10e686debd7',
    height: '350px',
    hasReviews: true
  }
};

const Container = styled.div`
  width: 100%;
  min-height: 1.5rem;
`;

const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlaceholderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
`;

const InvisibleText = styled.span`
  display: none;
`;

const TrustPilot = ({
  type,
  stars = 5,
  checkDelay = 600,
  retries = 3,
  disableLinks = false
}: Props) => {
  const { currentLocale } = useSelector(localizationSelector);
  const locale = currentLocale ?? Locale.SV;
  const [retriedCount, setRetriedCount] = useState(0);
  const ref = useRef(null);
  const { isLoaded } = useDynamicScript(
    '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (window.Trustpilot && isLoaded) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [isLoaded]);

  useInterval(
    () => {
      if (isLoaded && !window.Trustpilot) {
        setRetriedCount(retriedCount + 1);
      }
    },
    checkDelay,
    retriedCount >= retries
  );

  const boxType = boxTypes[type];

  return (
    <Container>
      {isLoaded ? (
        <>
          <div
            className="trustpilot-widget"
            data-locale={locale}
            ref={ref}
            data-businessunit-id={businessunitId}
            data-template-id={boxType.templateId}
            data-theme="light"
            data-stars={stars}
            data-style-width="100%"
            data-font-family={'Open Sans'}
            data-text-color={theme.colors.text.dark}
            data-review-languages={boxType.hasReviews ? locale : undefined}
            data-style-height={boxType.height}
            data-tags={boxType.tags}
            data-testid="trustPilotContainer"
          >
            {!disableLinks ? (
              <a
                href="https://se.trustpilot.com/review/kvd.se"
                target="_blank"
                rel="noreferrer"
                hidden
              >
                Trustpilot
              </a>
            ) : (
              <InvisibleText>Trustpilot</InvisibleText>
            )}
          </div>
          {retriedCount > retries && (
            <PlaceholderContainer>
              <Fade visible duration={200}>
                {t('Disable adblocker text')}{' '}
                {!disableLinks ? (
                  <Link
                    to="https://se.trustpilot.com/review/kvd.se"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Trustpilot
                  </Link>
                ) : (
                  'Trustpilot'
                )}
              </Fade>
            </PlaceholderContainer>
          )}
        </>
      ) : (
        <LoaderContainer>
          <Spinner
            color="secondary"
            size={boxType.height === '24px' ? 1.5 : undefined}
          />
        </LoaderContainer>
      )}
    </Container>
  );
};

export default TrustPilot;
