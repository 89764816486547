import styled from 'styled-components';
import { mq } from '@kvdbil/components';
import React, { ReactNode } from 'react';

const Container = styled.p`
  && {
    font-family: ${({ theme }) => theme.typography.fontAlternativeFamily};
    font-weight: ${({ theme }) =>
      theme.typography.fontAlternativeRegularWeight};
    font-size: 1.5rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.colors.text.dark};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 1rem 0;

    ${mq('tablet')} {
      font-family: ${({ theme }) => theme.typography.fontBaseFamily};
      font-weight: ${({ theme }) => theme.typography.fontBaseBoldWeight};
      font-size: 1rem;
      line-height: 1.5rem;
      margin: 0.5rem 0;
    }
  }
`;

export interface TitleProps {
  children: ReactNode;
}

const Title = ({ children }: TitleProps) => {
  return <Container>{children}</Container>;
};

export default Title;
