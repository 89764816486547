import React, { useMemo, useReducer, useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import { TextField, Button, TextArea } from '@kvdbil/components';
import { emailValidator, phoneValidator } from '~/helpers/validation';
import { useTranslation } from '~/Locale';
import { submitCorporateContactForm } from '~/helpers/orchestration/sale';

export interface ContactFormState {
  organisationNumber: string;
  organisationName: string;
  name: string;
  email: string;
  phoneNumber: string;
  otherInformation: string;
}

type ActionType = 'CHANGE_FIELD';
type FieldName =
  | 'organisationName'
  | 'organisationNumber'
  | 'name'
  | 'email'
  | 'phoneNumber'
  | 'otherInformation';

interface FormAction {
  type: ActionType;
  payload: {
    field: FieldName;
    value: string;
  };
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 0.5rem;

  margin-top: 2rem;
`;

const ThankYouTitle = styled.h2`
  font-size: 2rem;
  text-align: center;
`;

const initialState: ContactFormState = {
  organisationNumber: '',
  organisationName: '',
  name: '',
  email: '',
  phoneNumber: '',
  otherInformation: ''
};

type ChangeEventType =
  | ChangeEvent<HTMLInputElement>
  | ChangeEvent<HTMLTextAreaElement>;

const buildAction =
  (fieldName: FieldName) =>
  (event: ChangeEventType): FormAction => {
    const { value } = event.target;

    return {
      type: 'CHANGE_FIELD',
      payload: {
        field: fieldName,
        value
      }
    };
  };

const reducer = (
  state: ContactFormState,
  action: FormAction
): ContactFormState => {
  switch (action.type) {
    case 'CHANGE_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value
      };
    default:
      return state;
  }
};

const organisationNumberValidator = (orgNumber: string) =>
  10 <= orgNumber.length && 12 >= orgNumber.length ? true : false;

const isValidForm = (state: ContactFormState) =>
  emailValidator(state.email) &&
  phoneValidator(state.phoneNumber) &&
  organisationNumberValidator(state.organisationNumber);

type FunType = (arg: ChangeEventType) => FormAction;

const CorporateCarsContactForm = () => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const withDispatch = useMemo(() => {
    return (fun: FunType) => {
      return (arg: ChangeEventType) => {
        dispatch(fun(arg));
      };
    };
  }, [dispatch]);

  if (formSubmitted) {
    return (
      <Wrapper>
        <ThankYouTitle>{t('Thanks, we will call you!')}</ThankYouTitle>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <TextField
        placeholder={t('Organisation number')}
        value={state.organisationNumber}
        onChange={withDispatch(buildAction('organisationNumber'))}
      />
      <TextField
        placeholder={t('Organisation name')}
        value={state.organisationName}
        onChange={withDispatch(buildAction('organisationName'))}
      />
      <TextField
        placeholder={t('Name')}
        value={state.name}
        onChange={withDispatch(buildAction('name'))}
      />
      <TextField
        placeholder={t('Email')}
        value={state.email}
        onChange={withDispatch(buildAction('email'))}
      />
      <TextField
        placeholder={t('Phone number')}
        value={state.phoneNumber}
        onChange={withDispatch(buildAction('phoneNumber'))}
      />
      <TextArea
        placeholder={t('Other information')}
        value={state.otherInformation}
        onChange={withDispatch(buildAction('otherInformation'))}
        rows={4}
      />
      <Button
        size="regular"
        onClick={() =>
          submitCorporateContactForm(state).then(() => setFormSubmitted(true))
        }
        isDisabled={!isValidForm(state)}
      >
        {t('Send')}
      </Button>
    </Wrapper>
  );
};

export default CorporateCarsContactForm;
