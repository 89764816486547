import { IntervalSearchParamLabelKey } from '~/App/shared/types/FilterSearchParam';
import { DomainLocale, Locale } from '~/App/shared/localization/types';
import { SliderType } from '~/App/views/FilterPage/components/FilterSelector/types';

// for cacluating monthly cost of Financing with car loan
export const MONTHLY_COST_MONTHS = 72;
export const MONTHLY_COST_RESIDUAL_VALUE_MONTHS = 36;
export const MONTHLY_COST_DOWNPAYMENT_PERCENT = 0.2;
export const FINANCING_MINIMUM_AMOUNT = 20000;

export const MAX_BID_AMOUNT = 100000000;
export const MEMBER_ALREADY_REGISTERED = 'MEMBER_ALREADY_REGISTERED';

export const REGISTRATION_PLATE_BLACKLIST = ['000000', '000001'];

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong, try again later';

export const RELEASING_DEFAULT_KM = 1000;
export const RELEASING_DEFAULT_MONTHS = 12;

export const TRUSTLY_PAYMENT_STATUSES = {
  DEBITED: 'DEBITED',
  CREDITED: 'CREDITED',
  CANCELLED: 'CANCELLED'
};

export const TRANSFER_SIGNING_UPDATE_DELAY = 1000;

export const TRANSFER_SIGNING_STATUS = {
  ERROR: 'error',
  FAILED: 'failed',
  PENDING: 'pending',
  COMPLETE: 'complete'
};

export const TRANSFER_SIGNING_HINT_CODES = {
  USER_SIGN: 'userSign',
  EXPIRED: 'expiredTransation',
  QR_CODE_SCAN_FAIL: 'startFailed RP uses QRcode',
  NO_SIGNING_METHOD: 'startFailed RP does not use QR code',
  CERTIFICATE_ERROR: 'certificateErr'
};

export const TRANSFER_SIGNING_ERROR_CODES = {
  TIMEOUT: 'requestTimeout',
  CANCELLED: 'userCancel'
};

export const AB_TESTS = {
  SELLFLOW_REGISTRATION_PLATE_INPUT_ON_START:
    'SELLFLOW_REGISTRATION_PLATE_INPUT_ON_START'
};

export const CHAT_FLOWS = {
  CUSTOMER_SERVICE: 'kundcenter-tfotfwk8',
  SELL_PRIVATE: 'innesalj-privat-cie5iwjh',
  SELL_COMPANY: 'innesalj-foretag-wfe4n550'
};

export const SELL_FLOWS = {
  CONSUMER_CARS: 'CONSUMER_CARS',
  CORPORATE_CARS: 'CORPORATE_CARS',
  NOTICE_OF_INTEREST: 'NOTICE_OF_INTEREST',
  HEAVY_EQUIPMENT: 'HEAVY_EQUIPMENT',
  CORPORATE_CARS_SMALL: 'CORPORATE_CARS_SMALL'
};

export const VWO_GOAL_CONVERSIONS = {
  SUBMIT_CONTACT_DETAILS: 203
};

export const MEMBER_TYPES = {
  PERSON: 'PERSON',
  ORGANISATION: 'ORGANISATION',
  ORGANISATION_PERSON: 'ORGANISATION_PERSON'
};

export const MEMBER_LEVELS = {
  SELLER: 'SELLER',
  BIDDER: 'BIDDER'
};

export const BASE_URLS: Record<DomainLocale, string> = {
  en: 'https://www.kvdcars.com',
  sv: 'https://www.kvd.se'
};

export const BASE_DEV_URLS: Record<DomainLocale, string> = {
  en: 'https://www-kvdcars-com-staging.development.kvd.se',
  sv: 'https://www-kvd-se-staging.development.kvd.se'
};

export const PROD_SUBDOMAINS = ['fleet-manager', 'jobb', 'kundobjekt'];

export const LEAD_INITIATION_TYPES = {
  PUBLIC_WEBSITE: 'PUBLIC_WEBSITE'
};

export const SERVER_LOCALE_CACHE_TIME =
  process.env.NODE_ENV === 'production' ? 1000 * 60 * 5 : 1000 * 15;

export const DEFAULT_VISITOR_TYPE = 'unknown';

export const META_TITLE_MIN_BREAK_POINT = 45;
export const DEFAULT_HEADER_CLOSE_DELAY = 0;

// Reload time for ChunkLoadError
export const CHUNK_LOAD_ERROR_RELOAD_PAGE_TIME = 2 * 1000;
export const CHUNK_LOAD_ERROR_NAME = 'ChunkLoadError';
export const INVALID_STATE_ERROR = 'InvalidStateError';

export const FEATURE_FLAGS = [
  'partnership-page',
  'sell-flow-valuation-gatekeeper',
  'cms-proxy-caching',
  'sign-in-facebook',
  'orderable-extra-tires',
  'kvd-helper',
  'log-invalid-link',
  'push-notification'
] as const;

const currentYear = new Date().getFullYear();

export const minMaxIntervalValues: {
  [K in IntervalSearchParamLabelKey]: SliderType;
} = {
  year: [2000, currentYear],
  odometerReading: [0, 400000],
  operatingHours: [0, 50000],
  cardealerPrice: [0, 500000],
  buyNowAmount: [0, 500000],
  hp: [0, 1500],
  grade: [1, 5],
  bid: [0, 500000],
  co2: [0, 1000],
  releasingCost: [0, 20000],
  loanCost: [0, 20000],
  numSeats: [2, 9]
};

export type MinMaxIntervalKey = keyof typeof minMaxIntervalValues;

export const FILTER_PAGE_STEP_SIZE = 20 as const;

export const ALL_LOCALE_CODES: Locale[] = [
  Locale.SV,
  Locale.EN,
  Locale.PL,
  Locale.ET,
  Locale.FI
];
export const ALL_DOMAIN_LOCALE_CODES: Locale[] = [Locale.SV, Locale.EN];

export const BASE_CARGO_URL = 'https://cargo.kvd.se';
export const BASE_CARGO_DEV_URL =
  'https://cargo-kvd-se-staging.development.kvd.se';

export const SCARAB_COOKIE_KEYS = [
  'xp',
  's',
  'cdv',
  'scarab.profile',
  'scarab.visitor'
] as const;
export const SCARAB_STORAGE_KEYS = [
  'scarab.visitor',
  'wps-1',
  'wpsStore',
  'wps-user-session',
  '_wp_storage_test'
] as const;
