import { combineReducers } from 'redux';
import { LoginFlowActions } from '../actions/loginFlowActions';
import { ReduxStore } from '../interfaces/store';

const offCanvas = (state = false, action: LoginFlowActions) => {
  switch (action.type) {
    case 'LOGIN_FLOW_OFF_CANVAS':
      return action.offCanvas;

    default:
      return state;
  }
};

const origin = (state = '', action: LoginFlowActions) => {
  switch (action.type) {
    case 'LOGIN_FLOW_REGISTER_STEP':
      return action.payload.origin;

    case 'LOGIN_FLOW_END':
      return '';

    default:
      return state;
  }
};

const callback = (state = null, action: LoginFlowActions) => {
  switch (action.type) {
    case 'LOGIN_FLOW_REGISTER_STEP':
      return action.payload.callback || state;
    default:
      return state;
  }
};

const loginFlow = combineReducers<ReduxStore['loginFlow']>({
  offCanvas: offCanvas,
  origin: origin,
  callback: callback
});

export default loginFlow;
