import { localForageGetItem } from '~/helpers/client/localForage';
import { Session } from '~/App/shared/interfaces/store/Session';

export const getDefaultHeaders = async (idToken?: string) => {
  const session = await localForageGetItem<Session>('session');
  const headers = {
    SessionId: session?.sessionId,
    Authorization: idToken ? `Bearer ${idToken}` : undefined
  };

  return Object.fromEntries(
    Object.entries(headers).filter(([_key, val]) => Boolean(val))
  );
};
