import {
  Flex,
  HatchbackIcon,
  Subtitle,
  TruckIcon,
  VanIcon,
  TractorIcon,
  CamperIcon,
  mq
} from '@kvdbil/components';
import React from 'react';
import styled from 'styled-components';
import { IncludeContentBase } from './MediaQueryContainer';
import { Link } from 'react-router-dom';
import { useFilterSearchParams } from '~/App/views/FilterPage/hooks/useFilterSearchParams';
import { Theme } from '@kvdbil/components/types/theme';

const IncludeContent = styled(IncludeContentBase)`
  justify-content: center;
  align-items: center;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.text.dark};
`;

// Could be implemented in kvd-components instead
type BackgroundColor = keyof Theme['colors']['background'];

type IconContainerProps = {
  $backgroundColor: BackgroundColor;
};

const IconContainer = styled(Flex)<IconContainerProps>`
  background: ${({ theme, $backgroundColor }) =>
    theme.colors.background[$backgroundColor] ?? theme.colors.background.sand};

  ${mq(null, 'tablet')} {
    display: flex;
    flex: 1;
    padding: 0.6rem 1rem;
    border-radius: 0.5rem;
  }

  ${mq('tablet')} {
    padding: 1.5rem;
    border-radius: 8rem;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  font-size: 3rem;
  width: 3rem;
  height: 3rem;

  ${mq('tablet')} {
    font-size: 5rem;
    width: 5rem;
    height: 5rem;
  }

  justify-content: center;
  align-items: center;
`;

type MainCategoryIconSwitchProps = {
  icon: IconName;
};

const MainCategoryIconSwitch = ({ icon }: MainCategoryIconSwitchProps) => {
  switch (icon) {
    case 'van-icon':
      return <VanIcon />;
    case 'truck-icon':
      return <TruckIcon />;
    case 'tractor-icon':
      return <TractorIcon />;
    case 'camper-icon':
      return <CamperIcon />;
    case 'hatchback-icon':
    default:
      return <HatchbackIcon />;
  }
};

type IconName =
  | 'hatchback-icon'
  | 'van-icon'
  | 'truck-icon'
  | 'tractor-icon'
  | 'camper-icon';

export type MainCategoryBlurb = {
  text: string;
  imageIcon: [{ icon: { title: IconName } }];
  backgroundColor: BackgroundColor;
  link: string;
}[];

const MainCategoriesSection = ({
  mainCategories
}: {
  mainCategories: MainCategoryBlurb;
}) => {
  const { filterSearchParams, filterSearchParamsSize } =
    useFilterSearchParams();

  return (
    <Flex
      direction={{
        mobileS: 'column',
        tablet: 'row'
      }}
      justify="space-between"
      align={{
        tablet: 'center'
      }}
      gap="1rem"
      grow={1}
      data-testid="main-categories-section"
    >
      {mainCategories?.map(category => (
        <StyledLink
          to={`${category.link}${
            filterSearchParamsSize > 0
              ? `?${filterSearchParams.toString()}`
              : ''
          }`}
          key={category.text}
        >
          <Flex direction="column" gap="1rem">
            {/* a extra flex confiner was needed here for IconContainer to stay as a circle... */}
            <Flex>
              <IconContainer
                $backgroundColor={category.backgroundColor}
                justify={{
                  mobileS: 'start',
                  tablet: 'center'
                }}
                align={{
                  mobileS: 'center',
                  tablet: 'start'
                }}
              >
                <IconWrapper>
                  <MainCategoryIconSwitch
                    icon={category?.imageIcon?.[0]?.icon?.title ?? ''}
                  />
                </IconWrapper>
                {/* mobile text */}
                <IncludeContent display="flex" to="tablet">
                  <Flex justify="start" align="center">
                    <Subtitle>{category.text}</Subtitle>
                  </Flex>
                </IncludeContent>
              </IconContainer>
            </Flex>

            {/* from tablet text */}
            <IncludeContent display="flex" from="tablet">
              <Flex justify="center" align="center">
                <Subtitle>{category.text}</Subtitle>
              </Flex>
            </IncludeContent>
          </Flex>
        </StyledLink>
      ))}
    </Flex>
  );
};

export default MainCategoriesSection;
