import React, { MouseEvent, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  CertifiedIcon,
  CheckmarkIcon,
  ReleasingIcon,
  theme
} from '@kvdbil/components';
import { TooltipPortal } from '~/App/components/Portal';
import { useObjectPageCMSData } from '~/App/views/ObjectPage/hooks';

const PopupWindow = styled.div<{ top: number; left: number; width: number }>`
  position: absolute;
  color: ${({ theme }) => theme.colors.text.dark};
  background-color: ${({ theme }) => theme.colors.background.light};
  border-radius: 0.25rem;
  box-shadow: ${theme.elevations.elevation2};
  padding: 0.5rem 0.75rem 0 0.25rem;
  font-size: 0.85rem;
  font-weight: ${({ theme }) => theme.typography.fontBaseRegularWeight};
  line-height: 1.25rem;
  z-index: 999;

  ${({ top, left, width }) =>
    `top: ${top}px; left: ${left}px; width: ${width}px;`};
`;

const IconContainer = styled.span`
  display: flex;
`;

const HelperDescription = styled.span`
  display: block;
  margin: 0 0.5rem 0.5rem;
`;

const HelperTextRow = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0.5rem 0;

  svg {
    margin: -0.15rem 0.5rem 0;
  }
`;

interface Coords {
  top: number;
  left: number;
}

const HelperText = styled.span`
  flex: 1;
  user-select: text;
  margin-top: -0.55em;
`;

interface Props {
  icon: typeof ReleasingIcon | typeof CertifiedIcon;
  dataTestId: string;
  cmsKey: 'releasing' | 'certified' | 'ecoFriendly';
  isVehicleCardHovered: boolean;
}

export const PopupIcon = ({ icon: Icon, dataTestId, cmsKey }: Props) => {
  const [tooltipWindowWidth, setTooltipWindowWidth] = useState<number>(250);
  const [hovered, setHovered] = useState<boolean>(false);
  const [enterTimeoutId, setEnterTimeoutId] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const [coords, setCoords] = useState<Coords | null>(null);

  const { data } = useObjectPageCMSData();

  const cmsData = data?.[cmsKey];

  const bulletPoints = cmsData?.bulletPoints ?? [];
  const description = cmsData?.description;

  const iconRef = useRef<HTMLSpanElement>(null);

  const getLeftCoord = (rect: DOMRect) => {
    if (tooltipWindowWidth > document.documentElement.clientWidth) {
      setTooltipWindowWidth(document.documentElement.clientWidth);
      return 0;
    }
    if (
      Number(rect.x) + Number(rect.width) + tooltipWindowWidth >
      document.documentElement.clientWidth
    ) {
      return document.documentElement.clientWidth - tooltipWindowWidth;
    }
    return Number(rect.x) + Number(rect.width);
  };

  const updateTooltipCoords = (icon: EventTarget) => {
    const rect = (icon as SVGSVGElement).getBoundingClientRect();
    setCoords({
      left: getLeftCoord(rect),
      top: Number(rect?.y) + Number(rect?.width) + window?.scrollY
    });
  };

  const handleMouseEnter = (event: MouseEvent<SVGSVGElement>) => {
    const timeoutId = setTimeout(() => {
      setHovered(true);
      updateTooltipCoords(event.target);
    }, 250);
    setEnterTimeoutId(timeoutId);
  };

  const handleMouseLeave = () => {
    if (enterTimeoutId) {
      clearTimeout(enterTimeoutId);
      setEnterTimeoutId(null);
    }
    setTimeout(() => {
      setHovered(false);
    }, 150);
  };

  return (
    <>
      <IconContainer ref={iconRef}>
        <Icon
          data-testid={dataTestId}
          onMouseEnter={e => handleMouseEnter(e)}
          onMouseLeave={handleMouseLeave}
        />
      </IconContainer>
      {hovered && coords && (description || bulletPoints) && (
        <TooltipPortal>
          <PopupWindow
            top={coords.top}
            left={coords.left}
            width={tooltipWindowWidth}
          >
            {description && (
              <HelperDescription>{description}</HelperDescription>
            )}
            {bulletPoints.map(text => (
              <HelperTextRow key={text}>
                <CheckmarkIcon />
                <HelperText>{text}</HelperText>
              </HelperTextRow>
            ))}
          </PopupWindow>
        </TooltipPortal>
      )}
    </>
  );
};
