import React from 'react';
import Link from '~/App/shared/components/Link';
import Auction from '~/App/shared/interfaces/Auction';
import { FacilityFilters } from '~/App/shared/interfaces/Facility';

/**
 * @deprecated TODO: [KVDBIL-1634] refactor...
 */

import ProcessObject from '~/App/shared/interfaces/ProcessObject';
import { MainCategory } from '~/App/shared/types/MainCategoryTypes';
import { TranslateFunction } from '~/Locale';
import {
  getObjectTypeOptions,
  getPathFromMainCategory
} from '~/helpers/filterTranslation';
import { getVehicleTypeTranslation } from '~/helpers/vehicleType';

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export const equipmentExists = (process: ProcessObject) => {
  if (
    !process?.properties?.formattedEquipment &&
    process?.valuation?.endUserTestProtocol?.equipment &&
    process?.valuation?.endUserTestProtocol?.equipment.length <= 0
  ) {
    return false;
  }

  return true;
};

export const isHeavyEquipmentObject = (processObject: ProcessObject) => {
  return Boolean(processObject?.properties?.heavyEquipment);
};

export const isOffsiteLocation = (processObject: ProcessObject | undefined) => {
  return Boolean(processObject?.offsiteSalesContact);
};

export const getObjectLocationData = (processObject: ProcessObject) => {
  const isOffSite = isOffsiteLocation(processObject);

  if (isOffSite) {
    const data = processObject?.offsiteSalesContact;
    return {
      isOffSite,
      longitude: data?.cityLongitude,
      latitude: data?.cityLatitude,
      city: data?.city,
      name: data?.city
    };
  } else {
    const facility = processObject?.locationInfo?.facility;

    return {
      isOffSite,
      longitude: facility?.longitude,
      latitude: facility?.latitude,
      city: facility?.city,
      name: facility?.name
    };
  }
};

export const getBreadcrumbs = (
  auction: Auction,
  t: TranslateFunction,
  isMobile: boolean
) => {
  const processObject = auction.processObject;
  const { vehicleType, properties } = processObject;
  const isHeavyEquipment = properties?.heavyEquipment;
  const brand = properties?.brand;
  const familyName = properties?.familyName;
  const modelName = properties?.modelName;
  const encodedBrand = encodeURIComponent(brand);
  const encodedFamilyName = encodeURIComponent(familyName);

  const filterPageUri = getPathFromMainCategory(t, vehicleType);
  const objectTypeOption = getObjectTypeOptions(
    t,
    processObject.vehicleType.toLowerCase() as MainCategory
  ).filter(option => {
    return option.value === processObject.objectType.toLowerCase();
  })[0];

  const vehicleTypeLink = {
    component: (
      <Link to={filterPageUri}>
        {getVehicleTypeTranslation(
          vehicleType.toUpperCase() as FacilityFilters,
          t
        )}
      </Link>
    )
  };

  const objectTypeLink = {
    component: (
      <Link to={`${filterPageUri}?objectTypes=${objectTypeOption?.value}`}>
        {objectTypeOption?.label}
      </Link>
    )
  };

  const brandLink = {
    component: (
      <Link to={`${filterPageUri}?brand=${encodedBrand}`}>{brand}</Link>
    )
  };

  const familyNameLink = {
    component: (
      <Link
        to={`${filterPageUri}?brand=${encodedBrand}&familyName=${encodedFamilyName}`}
      >
        {familyName}
      </Link>
    )
  };

  const mobileLink = isHeavyEquipment
    ? vehicleTypeLink
    : Boolean(Boolean(brand) && Boolean(familyName)) && familyNameLink;

  const breadcrumbLinks = [];

  if (!isMobile) {
    breadcrumbLinks.push(
      ...[
        vehicleTypeLink,
        isHeavyEquipment && objectTypeLink,
        Boolean(brand) && brandLink,
        Boolean(Boolean(brand) && Boolean(familyName)) && familyNameLink,
        Boolean(Boolean(modelName) || Boolean(auction.previewTitle)) && {
          component: modelName || auction.previewTitle
        }
      ]
    );
  } else {
    breadcrumbLinks.push(
      ...[
        mobileLink,
        {
          component: <strong>...</strong>
        }
      ]
    );
  }

  return breadcrumbLinks.filter(Boolean) as { component: JSX.Element }[];
};
