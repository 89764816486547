import {
  BodyText,
  CheckCircleIcon,
  Flex,
  Stack,
  Title
} from '@kvdbil/components';
import React from 'react';
import styled from 'styled-components';
import AuthCloseButton from '../../components/AuthCloseButton';

const Status = styled.div`
  color: ${({ theme }) => theme.colors.secondary.main};

  svg {
    font-size: 6rem;
  }
`;

type Props = {
  title?: string;
  subtitle?: string;
  onClose(): void;
};

export const VerifyBankIDCompletedView = ({
  title,
  subtitle,
  onClose
}: Props) => {
  return (
    <Stack align={'center'}>
      <AuthCloseButton onClose={onClose} />

      {title && <Title style={{ textAlign: 'center' }}>{title}</Title>}

      {subtitle && (
        <BodyText style={{ textAlign: 'center' }}>{subtitle}</BodyText>
      )}

      <Flex justify={'center'}>
        <Status>
          <CheckCircleIcon />
        </Status>
      </Flex>
    </Stack>
  );
};

export default VerifyBankIDCompletedView;
