import {
  EstoniaFlagIcon,
  FinlandFlagIcon,
  PolandFlagIcon,
  UnitedKingdomFlagIcon,
  SwedishFlagIcon
} from '@kvdbil/components';
import { Locale } from '~/App/shared/localization/types';
import React from 'react';

export type Country = {
  value: Locale;
  label: string;
  icon: React.ReactNode;
};

export const countriesWithoutSweden: Country[] = [
  {
    value: Locale.ET,
    label: 'Eesti keel',
    icon: <EstoniaFlagIcon />
  },
  {
    value: Locale.EN,
    label: 'English',
    icon: <UnitedKingdomFlagIcon />
  },
  {
    value: Locale.PL,
    label: 'Polski',
    icon: <PolandFlagIcon />
  },
  {
    value: Locale.FI,
    label: 'Suomalainen',
    icon: <FinlandFlagIcon />
  }
];

export const countries: Country[] = [
  {
    value: Locale.SV,
    label: 'Svenska',
    icon: <SwedishFlagIcon />
  },
  ...countriesWithoutSweden
];
