import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { localizationSelector } from '../selectors/localization';
import { locale } from '~/config/public/environment';

const useLocalization = () => {
  const { currentLocale } = useSelector(localizationSelector);

  const isSwedishLocale = useMemo(
    () => (currentLocale ?? locale) === 'sv',
    [currentLocale]
  );

  return {
    locale: currentLocale,
    isSwedishLocale
  };
};

export default useLocalization;
