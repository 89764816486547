import { createSelector } from 'reselect';
import { ReduxStore } from '../interfaces/store';
import { reSerializeFilters } from '~/helpers/storeObjects';
import { VehicleTypes } from '../types/VehicleType';
import { FilterValue } from '../types/FilterSearchParam';

const storeObjects = (state: ReduxStore) => state?.storeObjects ?? null;

const storeObjectsEntities = createSelector(
  storeObjects,
  storeObjects => storeObjects?.entities ?? {}
);

const storeObjectsFiltered = createSelector(
  storeObjects,
  storeObjects => storeObjects?.filtered ?? {}
);

export const storeObjectsIsLoading = createSelector(
  storeObjects,
  storeObjects => storeObjects?.isLoading ?? false
);

export const storeObjectsError = createSelector(
  storeObjects,
  storeObjects => storeObjects?.error ?? null
);

const filteredByKeySelector = (searchQuery: string) => {
  const searchParamKey = reSerializeFilters(searchQuery);

  return createSelector(storeObjectsFiltered, filtered => {
    return (
      filtered?.[searchParamKey] ?? {
        ids: [],
        total: 0,
        hits: 0
      }
    );
  });
};

const filteredIdsSelector = (searchQuery: string) =>
  createSelector(filteredByKeySelector(searchQuery), filtered => {
    return filtered?.ids ?? [];
  });

export const filteredEntitiesSelector = (searchQuery: string) =>
  createSelector(
    storeObjectsEntities,
    filteredIdsSelector(searchQuery),
    (entities, ids) => {
      return ids?.map(id => entities[id]).filter(Boolean) ?? [];
    }
  );

export const totalSelector = (searchQuery: string) =>
  createSelector(filteredByKeySelector(searchQuery), filtered => {
    return filtered?.total ?? 0;
  });

export const hitsSelector = (searchQuery: string) =>
  createSelector(filteredByKeySelector(searchQuery), filtered => {
    return filtered?.hits ?? 0;
  });

export const isFetchingMoreSelector = (searchQuery: string) =>
  createSelector(
    filteredByKeySelector(searchQuery),
    storeObjectsIsLoading,
    (filter, isLoading) => {
      return filter?.ids?.length > 0 && isLoading;
    }
  );

export const remainingSelector = (searchQuery: string) =>
  createSelector(
    filteredIdsSelector(searchQuery),
    hitsSelector(searchQuery),
    (ids, hits) => {
      return Math.max(hits - ids.length, 0);
    }
  );

export const dynamicFilterOptions = createSelector(
  storeObjects,
  storeObjects => storeObjects?.dynamicFilterOptions ?? {}
);

const reduceDuplicateFilterValues = (acc: FilterValue[], curr: FilterValue) => {
  if (!acc.some(item => item.value === curr.value)) {
    acc.push(curr);
  }

  return acc;
};

export const brandFilterOptionsSelector = (mainCategory?: VehicleTypes) => {
  return createSelector(dynamicFilterOptions, dynamicFilterOptions => {
    if (!mainCategory) return [];

    return (
      dynamicFilterOptions?.[mainCategory]?.brands.reduce(
        reduceDuplicateFilterValues,
        []
      ) ?? []
    );
  });
};

export const familyNameFilterOptionsSelector = (
  brands: string[],
  mainCategory?: VehicleTypes
) => {
  return createSelector(dynamicFilterOptions, dynamicFilterOptions => {
    if (!mainCategory) return [];

    if (brands?.length <= 0) {
      return Object.values(
        dynamicFilterOptions?.[mainCategory]?.familyNames ?? {}
      )
        .flat()
        .reduce(reduceDuplicateFilterValues, []);
    }

    return brands
      .map(brand => {
        return dynamicFilterOptions?.[mainCategory]?.familyNames?.[brand];
      })
      .flat()
      .reduce(reduceDuplicateFilterValues, []);
  });
};

export const facilityFilterOptionsSelector = createSelector(
  storeObjects,
  storeObjects => storeObjects?.facilityFilterOptions ?? []
);

export const isRememberScrollActiveSelector = createSelector(
  storeObjects,
  storeObjects => storeObjects?.scrollPosition?.isRememberScrollActive ?? false
);

export const scrollPositionAuctionIdSelector = createSelector(
  storeObjects,
  storeObjects => storeObjects?.scrollPosition?.auctionId ?? null
);

export const scrollFilterURLSelector = createSelector(
  storeObjects,
  storeObjects => storeObjects?.scrollPosition?.url ?? null
);
