import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { mq, Title, Button } from '@kvdbil/components';
import Link from '~/App/shared/components/Link';
import { Color } from '@kvdbil/components/types/Types';

const Container = styled.div<{ isHitsCounter: boolean }>`
  color: ${({ theme }) => theme.colors.text.dark};
  margin: 0 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${({ isHitsCounter }) => (isHitsCounter ? mq('tablet') : mq('mobileL'))} {
    flex-direction: row;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${mq(null, 'mobileL')} {
    width: 90%;
    text-align: center;
    justify-content: center;
  }
`;

const AuctionTypeIconWrapper = styled.span<{ color: Color }>`
  color: ${({ theme, color }) => theme.colors[color].main};
  margin-right: 0.5rem;
  height: 2rem;
  font-size: 2rem;
  line-height: 2rem;
`;

const FilterPageLinkWrapper = styled.span`
  user-select: none;
`;

export interface HeaderProps {
  title: string;
  titleIcon?: ReactElement;
  hitsCounter?: { hits: number; total: number } | null;
  link?: { label: string; url: string } | null;
  color: Color;
}

const Header = ({
  title,
  titleIcon,
  hitsCounter,
  link,
  color
}: HeaderProps) => {
  const isHitsCounter = Boolean(hitsCounter);

  return (
    <>
      <Container isHitsCounter={isHitsCounter}>
        <TitleContainer>
          {titleIcon && (
            <AuctionTypeIconWrapper color={color}>
              {titleIcon}
            </AuctionTypeIconWrapper>
          )}
          <Title as="span">{title}</Title>
        </TitleContainer>

        {link && (
          <FilterPageLinkWrapper>
            <Link to={link.url}>
              <Button size="regular" color="info" variant="flat" as="span">
                {link.label}
                {isHitsCounter ? ` (${hitsCounter?.hits})` : ''}
              </Button>
            </Link>
          </FilterPageLinkWrapper>
        )}
      </Container>
    </>
  );
};

export default Header;
