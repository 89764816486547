import React from 'react';
import styled from 'styled-components';
import { SpinnerThin } from '@kvdbil/components';

const Container = styled.div`
  position: absolute;
`;

const StyledSpinner = styled(SpinnerThin)<{ isLoading: boolean }>`
  position: absolute;
  margin-left: -1.75rem;
  border-width: 1px;
  transition: 0.02s ease-in-out !important, width transition 0s !important;

  ${({ theme, isLoading }) =>
    !isLoading &&
    `
        animation: none;
        border: 1px solid ${theme.colors.gray.dark3};
    `}
`;

export interface SpinnerComponentProps {
  isLoading: boolean;
}

const SpinnerComponent = ({ isLoading }: SpinnerComponentProps) => {
  return (
    <Container>
      <StyledSpinner isLoading={isLoading} />
    </Container>
  );
};

export default SpinnerComponent;
