import { matchRoutes } from '~/config/routes/renderRoutes';
import { Store } from 'redux';
import { ReduxStore } from '~/App/shared/interfaces/store';
import { translateRoute } from '~/App/shared/localization';
import { PrefetchProps } from '~/server/prefetchData';
import { TranslateFunction } from '~/Locale';
import { DomainLocale } from '~/App/shared/localization/types';
import { BASE_URLS, PROD_SUBDOMAINS } from '~/config/constants';
import { RouteLocation } from '~/App/shared/interfaces/routes';

export type ConstructPrefetchArgumentsInput = {
  location?: RouteLocation;
  props: PrefetchProps;
  store?: Store<ReduxStore>;
};

export const constructPrefetchArguments = ({
  location,
  props,
  store
}: ConstructPrefetchArgumentsInput) => {
  const { pathname = '' } = location ?? {};
  const args = {
    location,
    ...props,
    ...store,
    params: { ...props.params, splat: pathname.slice(1) }
  };

  if (props.routes) {
    const matches = matchRoutes(props.routes, pathname);
    const { match, route } = matches.pop() ?? {};

    args['match'] = match;
    args['route'] = route;
  }

  return args;
};

export const isActiveUrl = (
  t: TranslateFunction,
  url: string,
  pathname: string | undefined,
  options = { exact: true }
) => {
  const translatedUrl = decodeURIComponent(translateRoute(t, url));
  const decodedPathname = decodeURIComponent(pathname ?? '');
  if (options.exact) {
    return translatedUrl === decodedPathname;
  } else {
    return (
      decodedPathname.startsWith?.(translatedUrl) ||
      translatedUrl.includes?.(decodedPathname)
    );
  }
};

export const validURL = (value: string) => {
  return /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()\\*+,;=]|:|@)|\/|\?)*)?$/i.test(
    value
  );
};

export const pathnameStartsWithRoute = (
  t: TranslateFunction,
  pathname: string,
  route: string
) =>
  encodeURIComponent(pathname?.split('/')?.[1])?.includes(
    translateRoute(t, route)
  );

export const domainFromUrl = (url: string) => {
  const match = url.match(
    /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?=]+)/im
  );

  if (match) {
    const domain = match[1];
    const rootDomainMatch = domain.match(/^[^.]+\.(.+\..+)$/);

    if (rootDomainMatch) {
      return {
        rootDomain: rootDomainMatch[1],
        subdomain: domain.slice(0, -(rootDomainMatch[1].length + 1))
      };
    }

    return {
      rootDomain: domain,
      subdomain: ''
    };
  }
  return { rootDomain: '', subdomain: '' };
};

const trustedDomains = ['kvd.se', 'kvdcars.com'];

export const isKvdUrlValid = (url: string) => {
  const { rootDomain } = domainFromUrl(url);
  const urlIsValid = validURL(url);
  return trustedDomains.includes(rootDomain) && urlIsValid;
};

export const validateKvdUrl = (url: string) => {
  return isKvdUrlValid(url) ? url : '/';
};

export const isTrustedDomain = (domain: string) => {
  return trustedDomains.includes(domain);
};

export const isHostDomain = (domain: string, locale: DomainLocale) => {
  const { rootDomain } = domainFromUrl(BASE_URLS[locale]);
  return rootDomain === domain;
};

export const isKvdProdSubdomain = (subdomain: string) => {
  return PROD_SUBDOMAINS.includes(subdomain);
};
