import { SubscriptionType } from '../interfaces/EmailSubscription';

export function createEmailSubscriptionNames(
  personalCarSubscription: boolean,
  heavyVehiclesSubscription: boolean,
  corporateSubscription?: boolean
) {
  const emailSubscriptionNames: SubscriptionType[] = [];

  personalCarSubscription &&
    emailSubscriptionNames.push(SubscriptionType.WeeklyPersonalCar);
  heavyVehiclesSubscription &&
    emailSubscriptionNames.push(SubscriptionType.WeeklyHeavyVehicles);
  corporateSubscription &&
    emailSubscriptionNames.push(SubscriptionType.Corporate);

  return emailSubscriptionNames;
}
