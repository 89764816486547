import { SessionActions } from '../actions/session';
import { ADD_SNACK, EAT_SNACK } from '../actions/snackbarActions';
import { ReduxStore } from '../interfaces/store';
import { Snack } from '../interfaces/store/Snackbar';

export type AddSnackbarAction = {
  type: typeof ADD_SNACK;
  payload: Snack;
};

export type RemoveSnackbarAction = {
  type: typeof EAT_SNACK;
  payload: number;
};

const reducer = (
  state = [] as Snack[],
  action: AddSnackbarAction | RemoveSnackbarAction | SessionActions
): ReduxStore['snackbar'] => {
  switch (action.type) {
    case 'snackbar/ADD_SNACK':
      return [...state, action.payload];
    case 'snackbar/EAT_SNACK':
      return state.filter(snack => snack.key !== action.payload);
    case 'SESSION_UPDATE': {
      const snack: Snack = {
        key: action.payload?.key ?? 0,
        message: action.payload?.message ?? 'Updated member settings',
        type: 'success',
        color: 'secondary'
      };

      return [...state, snack];
    }
    default:
      return state;
  }
};

export default reducer;
