import { Button, Hero, theme } from '@kvdbil/components';
import { Color, ColorShade, TextColor } from '@kvdbil/components/types/Types';
import React, { memo } from 'react';
import styled from 'styled-components';
import { DarkOrLight } from '~/App/shared/interfaces/Theme';
import { OptionalHeroRecord } from '~/config/generated/graphql';
import { CmsColors } from '../views/Start/components/CampaignBanners/SmallPromoBanner';
import Link from '~/App/shared/components/Link';
import HeroImage from '~/App/components/DatoCMS/HeroImage';

const Wrapper = styled.div<Pick<CmsColors, 'textColor'>>`
  span {
    color: ${({ textColor }) => theme.colors.text[textColor]};
  }
`;

type Props = {
  data: OptionalHeroRecord;
  dynamicGutter?: {
    tabletVw: number;
    laptopVw: number;
  };
  contentMaxWidth?: number;
  isLoading?: boolean;
};

function OptionalHero({
  data,
  dynamicGutter,
  contentMaxWidth,
  isLoading
}: Props) {
  const button = data?.button?.[0];

  if (!data?.showHero) {
    return null;
  }

  return (
    <Wrapper textColor={button?.textColor as TextColor}>
      <Hero
        imageElement={
          data?.image?.responsiveImage && (
            <HeroImage
              responsiveImage={data?.image?.responsiveImage}
              focalPoint={data?.image?.focalPoint}
            />
          )
        }
        heading={data.title}
        subheading={data.subtitle}
        headingColor={data.textColor as DarkOrLight}
        button={
          button?.link && button?.text ? (
            <Link to={button?.link}>
              <Button
                as="span"
                color={button?.backgroundColor as Color}
                colorShade={button?.backgroundColorShade as ColorShade}
                size="large"
              >
                {button?.text}
              </Button>
            </Link>
          ) : undefined
        }
        contentCentered={data.contentAlignment === 'center'}
        dynamicGutter={dynamicGutter}
        elementShadow
        contentMaxWidth={contentMaxWidth}
        isLoading={isLoading}
      />
    </Wrapper>
  );
}

export default memo(OptionalHero);
