import {
  GlobalConfigurationQuery,
  SiteMessagesQuery
} from '~/config/generated/graphql';

export const handleGlobalConfiguration = ({
  globalConfiguration
}: GlobalConfigurationQuery) => ({
  financingInterest: parseFloat(globalConfiguration?.financingInterest ?? '0')
});

export const handleSiteMessages = ({
  allSiteMessages
}: Partial<SiteMessagesQuery>) => ({
  top: allSiteMessages?.filter(m => m?.placement === 'top'),
  bottom: allSiteMessages?.filter(m => m?.placement === 'bottom')
});
