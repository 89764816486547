import { useRef, useEffect } from 'react';

type Callback = () => void;
export const useInterval = (
  callback: Callback,
  delay: number | null,
  cancel?: boolean
) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };

    if (delay !== null) {
      const interval = setInterval(tick, delay);

      if (cancel) {
        clearInterval(interval);
      }

      return () => clearInterval(interval);
    }
  }, [delay, cancel]);
};
