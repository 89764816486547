import { StoreObjectsAction } from '../actions/storeObjectActions';
import { ReduxStore } from '../interfaces/store';
import { StoreObjectsState } from '../interfaces/store/StoreObject';

export const initialState: StoreObjectsState = {
  entities: {},
  ids: [],
  filtered: {},
  isLoading: false,
  error: null,
  dynamicFilterOptions: {
    car: {
      brands: [],
      familyNames: {}
    },
    heavy_transport: {
      brands: [],
      familyNames: {}
    },
    light_transport: {
      brands: [],
      familyNames: {}
    },
    machines: {
      brands: [],
      familyNames: {}
    },
    recreational: {
      brands: [],
      familyNames: {}
    },

    // TODO: [KVDBIL-1213] remove on split of vehicleTypes
    transport_vehicle: {
      brands: [],
      familyNames: {}
    },
    // TODO: [KVDBIL-1213] remove on renaming of vehicleTypes
    recreational_vehicle: {
      brands: [],
      familyNames: {}
    }
  },
  facilityFilterOptions: [],
  scrollPosition: {
    isRememberScrollActive: false,
    auctionId: null,
    url: null
  }
};

const mergeArrays = <T>(state: T[] = [], actionArray: T[] = []) =>
  [...new Set([...state, ...actionArray])] as T[];

const storeObjectsReducer = (
  state = initialState,
  action: StoreObjectsAction
): ReduxStore['storeObjects'] => {
  switch (action.type) {
    case 'FETCH_STORE_ITEMS_START':
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case 'FETCH_STORE_ITEMS_SUCCESS':
      const allEntities = { ...state.entities, ...action.payload.entities };
      const allIds = mergeArrays<string>(state.ids, action.payload.filters.ids); // Ensure no duplicate IDs
      const filteredIds = mergeArrays<string>(
        state.filtered[action.payload.searchParamKey]?.ids,
        action.payload.filters.ids
      );

      // recalculate hits and total
      const previewIds =
        state.filtered[action.payload.searchParamKey]?.ids ?? [];
      const newlyIds = action.payload.filters.ids ?? [];
      const duplicateHits = newlyIds.filter(id =>
        previewIds.includes(id)
      ).length;

      // This should prevent backends lies about hits in pagination
      const nextHits = Math.max(
        action.payload.filters.hits - duplicateHits,
        filteredIds.length
      );

      // Populate entities, ids, and filters from action.payload
      return {
        ...state,
        entities: allEntities,
        ids: allIds, // Ensure no duplicate IDs
        filtered: {
          ...state.filtered,
          [action.payload.searchParamKey]: {
            ...state.filtered[action.payload.searchParamKey],
            ids: filteredIds,
            hits: nextHits,
            total: action.payload.filters.total
          }
        },
        isLoading: false,
        error: null
      };
    case 'FETCH_STORE_ITEMS_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case 'FETCH_DYNAMIC_FILTERS_SUCCESS':
      return {
        ...state,
        dynamicFilterOptions: {
          ...state.dynamicFilterOptions,
          [action.payload.mainCategory]: {
            brands: action.payload.brands ?? [],
            familyNames: action.payload.familyNames ?? {}
          }
        }
      };

    case 'FETCH_FACILITIES_SUCCESS':
      return {
        ...state,
        facilityFilterOptions: action.payload
      };

    case 'FILTER_PAGE_REMEMBER_SCROLL_ACTIVE':
      return {
        ...state,
        scrollPosition: {
          ...state.scrollPosition,
          isRememberScrollActive: action.payload
        }
      };

    case 'FILTER_PAGE_SET_SCROLL_DATA':
      return {
        ...state,
        scrollPosition: {
          ...state.scrollPosition,
          auctionId: action.payload.auctionId ?? null,
          url: action.payload.url ?? null
        }
      };

    default:
      return state;
  }
};

export default storeObjectsReducer;
