import React from 'react';
import { DeepPartial } from '@test/mocks';
import { ArticleRecord } from '~/config/generated/graphql';

import ArticleCardSkeletonLoader from './ArticleCardSkeleton';
import ArticleCard from './ArticleCard';
import styled from 'styled-components';
import { mq } from '@kvdbil/components';

const ArticleCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 0.5rem));
  grid-gap: 2rem 1rem;

  ${mq('tablet')} {
    grid-template-columns: repeat(4, calc(25% - 0.75rem));
  }
`;

interface Props {
  isLoading: boolean;
  articles?: DeepPartial<ArticleRecord>[] | undefined;
  limit?: number;
}

function ArticleList({ isLoading, articles, limit = 0 }: Props) {
  const skeletonArr: string[] = new Array(articles?.length ?? limit).fill('');

  if (isLoading) {
    return (
      <ArticleCardsContainer>
        {skeletonArr.map((_, index) => (
          <ArticleCardSkeletonLoader key={index} />
        ))}
      </ArticleCardsContainer>
    );
  } else {
    return (
      <ArticleCardsContainer>
        {articles?.map(article => (
          <ArticleCard key={article.title} article={article} />
        ))}
      </ArticleCardsContainer>
    );
  }
}

export default ArticleList;
