import { createSelector } from 'reselect';
import {
  prop,
  identity,
  reject,
  groupBy,
  toString,
  compose,
  propOr
} from 'ramda';
import { TargetedAuctions } from '../interfaces/store/TargetedAuctions';
import { ReduxStore } from '../interfaces/store';
import Auction from '../interfaces/Auction';

const root = createSelector<ReduxStore, TargetedAuctions, TargetedAuctions>(
  prop('targetedAuctions'),
  identity
);

export const targetedAuctionsStatuses = createSelector(root, prop('statuses'));

export const targetedAuctionsIsLoading = createSelector(
  root,
  prop('isLoading')
);

export const targetedAuctions = createSelector(root, prop('auctions'));

export const notSeenTargetedAuctionGroups = createSelector(
  targetedAuctionsStatuses,
  (statuses = []) => {
    return reject(prop('hasSeen'), statuses);
  }
);

const groupByTargetedAuctionId = compose<Auction, number, string>(
  toString,
  propOr(0, 'targetedAuctionGroupId')
);

export const targetedAuctionsWithIds = createSelector(
  targetedAuctions,
  groupBy(groupByTargetedAuctionId)
);
