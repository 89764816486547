import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  BodyText,
  Button,
  Caption,
  Message,
  Stack,
  Title
} from '@kvdbil/components';
import { useTranslation } from '~/Locale';
import {
  sendVerificationCode,
  updatePhoneNumberAndSendVerificationCode
} from '~/App/shared/actions/verifyPhoneNumber';
import { useDispatch, useSelector } from 'react-redux';
import {
  phoneVerificationIsSending,
  phoneVerificationIsUpdatingPhoneNumber,
  verifyPhoneNumberSelector
} from '~/App/shared/selectors/verifyPhoneNumberSelector';
import { phoneNumber as phoneNumberSelector } from '~/App/shared/selectors/sessionSelector';
import { useForm } from 'react-hook-form';
import { regexPhoneNumber } from '~/helpers/regex';
import { PhoneNumberField } from '../../Fields/shared';

const StyledStack = styled(Stack)`
  text-align: center;
`;

type Form = {
  phoneNumber: string;
};

export interface SendCodeProps {
  setStep(step: string): void;
}
const SendCodeWithUpdatePhoneNumber = ({ setStep }: SendCodeProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const phoneNumber = useSelector(phoneNumberSelector);
  const isSendingCode = useSelector(phoneVerificationIsSending);
  const isUpdatingPhoneNumber = useSelector(
    phoneVerificationIsUpdatingPhoneNumber
  );
  const { error, generalError } = useSelector(verifyPhoneNumberSelector);

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
    resetField
  } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      phoneNumber
    }
  });

  const onCodeSendingSuccess = () => setStep('enterCode');

  const handleSendClick = ({ phoneNumber }: Form) => {
    if (isDirty) {
      dispatch(
        updatePhoneNumberAndSendVerificationCode(
          phoneNumber,
          onCodeSendingSuccess
        )
      );
    } else {
      dispatch(sendVerificationCode(onCodeSendingSuccess));
    }
  };

  useEffect(() => {
    resetField('phoneNumber', {
      keepDirty: false,
      defaultValue: phoneNumber
    });
  }, [phoneNumber, resetField]);

  return (
    <Stack rowGap={2}>
      <StyledStack>
        <Title as="h3">{t('Verify your information')}</Title>
        <BodyText>{t('You must verify your information to proceed.')}</BodyText>
      </StyledStack>

      <Stack rowGap={0.25}>
        <PhoneNumberField
          control={control}
          name="phoneNumber"
          label={t('Mobile number')}
          t={t}
          rules={{
            pattern: {
              value: regexPhoneNumber,
              message: t('Invalid phone number')
            }
          }}
        />

        <Caption>
          {t(
            'We need your mobile number to be able to contact you if you win the bidding and to send out bidding information.'
          )}
        </Caption>
      </Stack>

      {(error || generalError) && (
        <Message type="error" fullWidth>
          {error || t('Something went wrong, please try again later.')}
        </Message>
      )}

      <Button
        aria-label="send-code"
        onClick={handleSubmit(handleSendClick)}
        isLoading={isUpdatingPhoneNumber || isSendingCode}
        fullWidth
        size="regular"
        isDisabled={!isValid}
      >
        {t('Send verification code')}
      </Button>
    </Stack>
  );
};

export default SendCodeWithUpdatePhoneNumber;
