import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {

    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const Fade = styled.div<{
  visible: boolean;
  duration?: number;
  delay?: number;
}>`
  opacity: 0;
  display: inline-block;
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  animation-name: ${props => (props.visible ? fadeIn : fadeOut)};
  animation-duration: ${props => props.duration || 2000}ms;
  animation-timing-function: ease-in-out;
  animation-delay: ${props =>
    props.visible && props.delay ? `${props.delay}ms` : 0};
  animation-fill-mode: forwards;
  transition: visibility ${props => props.duration || 2000}ms ease-in-out;
`;
