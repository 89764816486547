import { createSelector } from 'reselect';
import { isLoggedIn } from './session';
import { getServerOffsetTime } from '~/helpers/time';
import { timeDiff } from './global';
import { WonAuctions } from '../interfaces/store/WonAuctions';
import { Bid } from '../interfaces/Bid';
import { ActiveAuction } from '../interfaces/ActiveAuction';
import { getProcessObjectStatus } from '~/helpers/processObject';
import Auction, { AuctionLoggedInStates } from '../interfaces/Auction';

import { viewingAuctionID } from './viewingAuction';
import { ReduxStore } from '../interfaces/store';
import { Auctions } from '../interfaces/store/Auctions';
import { wonAuctions } from './wonAuctions';

export const auctionsSelector = createSelector<ReduxStore, Auctions, Auctions>(
  state => state?.auctions,
  value => value
);

export const viewingAuction = createSelector(
  auctionsSelector,
  viewingAuctionID,
  (auctions, id) => {
    const auction: Auction = auctions[id];
    return auction;
  }
);

export const auctionById = (auctionId: number | string) => {
  const id = Number(auctionId);
  return createSelector(auctionsSelector, auctions => auctions[id]);
};

export const viewingAuctionActive = createSelector(
  viewingAuction,
  state => state?.activeAuction
);

function getIsLeading(leadingBid: Bid, activeAuction: ActiveAuction | null) {
  if (!leadingBid) {
    return false;
  }

  return leadingBid.bidderAlias === activeAuction?.myBidderAlias;
}

function getLoggedInStates(
  auction: Auction,
  wonAuctions: WonAuctions,
  isLoggedIn: boolean | string
): AuctionLoggedInStates {
  if (!isLoggedIn) {
    return {
      isParticipating: false,
      isLeading: false,
      isOverBidded: false,
      isWinner: false
    };
  }

  const { sellMethod } = auction;
  const activeAuction = auction?.activeAuction;
  const bids = activeAuction?.bids ?? [];
  const isClosed = activeAuction?.closed;

  const winningBid = auction?.activeAuction?.highestBid;
  const auctionId = auction?.id;

  const sortedBids = [...bids].sort((a, b) => b.id - a.id);
  const leadingBid = sortedBids?.[0];
  const memberBids = [...sortedBids].filter(
    bid => bid.bidderAlias === activeAuction?.myBidderAlias
  );
  const highestMemberBid = memberBids.sort((a, b) => b.amount - a.amount)?.[0];

  const isParticipating = memberBids.length > 0;
  const isLeading = isParticipating && getIsLeading(leadingBid, activeAuction);

  return {
    isLeading,
    isParticipating,
    isOverBidded: !isLeading,
    isWinner:
      (isClosed &&
        winningBid &&
        winningBid.id === highestMemberBid?.id &&
        sellMethod !== 'BUY_NOW') ||
      wonAuctions.includes(parseInt(auctionId, 10))
  };
}

export const activeAuctionStates = createSelector(
  viewingAuction,
  isLoggedIn,
  timeDiff,
  wonAuctions,
  (auction, isLoggedIn, timeDiff, wonAuctions) => {
    const { activeAuction } = auction;

    const now = getServerOffsetTime(timeDiff);
    const morning = now.startOf('day');
    const preliminaryCloseAt = getServerOffsetTime(
      timeDiff,
      new Date(activeAuction?.preliminaryCloseAt ?? '')
    );
    const diffDays = Math.abs(morning.diff(preliminaryCloseAt, 'days'));

    const isClosed = activeAuction?.closed;
    const isTonight = diffDays === 0 && preliminaryCloseAt.hour() >= 17; // Today and after 17:00
    const isCounting = activeAuction?.countdownInProgress && !isClosed;

    const { isWinner, isLeading, isOverBidded, isParticipating } =
      getLoggedInStates(auction, wonAuctions, isLoggedIn);

    const { isCertified, isReleasable, isEcoFriendly } = getProcessObjectStatus(
      auction.processObject
    );

    return {
      isWinner: isWinner,
      isClosed: isClosed,
      isLeading: isLeading,
      isTonight: isTonight,
      isCertified: isCertified,
      isReleasable: isReleasable,
      isEcoFriendly: isEcoFriendly,
      isCounting: isCounting,
      isOverbidded: isOverBidded,
      isParticipating: isParticipating
    };
  }
);
