import Auction, { AuctionNotFound } from '../interfaces/Auction';
import { RecentlyViewedAuctions } from '../interfaces/store/RecentlyViewedAuctions';

export const ADD_VIEWED_AUCTION = 'ADD_VIEWED_AUCTION';
export const HYDRATE_VIEWED_AUCTIONS = 'HYDRATE_VIEWED_AUCTIONS';

export interface AddViewedAuctionAction {
  type: typeof ADD_VIEWED_AUCTION;
  payload?: Auction;
}

export interface HydrateViewedAuctionsAction {
  type: typeof HYDRATE_VIEWED_AUCTIONS;
  payload: Omit<RecentlyViewedAuctions, 'isHydrated'>;
}

export const addViewedAuction = ({
  auction
}: {
  auction: Auction | AuctionNotFound;
}) => ({
  type: ADD_VIEWED_AUCTION,
  payload: auction
});

export const recentlyViewedAuctionsHydrate = ({
  byId,
  allIds
}: Omit<
  RecentlyViewedAuctions,
  'isHydrated'
>): HydrateViewedAuctionsAction => ({
  type: HYDRATE_VIEWED_AUCTIONS,
  payload: {
    byId: byId,
    allIds: allIds
  }
});
