import { TranslateFunction } from '~/Locale';
import { regexSwedishZipCode, regexZipCode } from './regex';

export const getCountries = (t: TranslateFunction) => {
  return [
    { value: 'af', label: t('AFGHANISTAN') },
    { value: 'al', label: t('ALBANIA') },
    { value: 'dz', label: t('ALGERIA') },
    { value: 'as', label: t('AMERICAN_SAMOA') },
    { value: 'ad', label: t('ANDORRA') },
    { value: 'ao', label: t('ANGOLA') },
    { value: 'ai', label: t('ANGUILLA') },
    { value: 'aq', label: t('ANTARCTICA') },
    { value: 'ag', label: t('ANTIGUA_AND_BARBUDA') },
    { value: 'ar', label: t('ARGENTINA') },
    { value: 'am', label: t('ARMENIA') },
    { value: 'aw', label: t('ARUBA') },
    { value: 'au', label: t('AUSTRALIA') },
    { value: 'at', label: t('AUSTRIA') },
    { value: 'az', label: t('AZERBAIJAN') },
    { value: 'bs', label: t('BAHAMAS') },
    { value: 'bh', label: t('BAHRAIN') },
    { value: 'bd', label: t('BANGLADESH') },
    { value: 'bb', label: t('BARBADOS') },
    { value: 'by', label: t('BELARUS') },
    { value: 'be', label: t('BELGIUM') },
    { value: 'bz', label: t('BELIZE') },
    { value: 'bj', label: t('BENIN') },
    { value: 'bm', label: t('BERMUDA') },
    { value: 'bt', label: t('BHUTAN') },
    { value: 'bo', label: t('BOLIVIA') },
    { value: 'ba', label: t('BOSNIA_AND_HERZEGOVINA') },
    { value: 'bw', label: t('BOTSWANA') },
    { value: 'br', label: t('BRAZIL') },
    { value: 'io', label: t('BRITISH_INDIAN_OCEAN_TERRITORY') },
    { value: 'vg', label: t('BRITISH_VIRGIN_ISLANDS') },
    { value: 'bn', label: t('BRUNEI') },
    { value: 'bg', label: t('BULGARIA') },
    { value: 'bf', label: t('BURKINA_FASO') },
    { value: 'bi', label: t('BURUNDI') },
    { value: 'kh', label: t('CAMBODIA') },
    { value: 'cm', label: t('CAMEROON') },
    { value: 'ca', label: t('CANADA') },
    { value: 'cv', label: t('CAPE_VERDE') },
    { value: 'ky', label: t('CAYMAN_ISLANDS') },
    { value: 'cf', label: t('CENTRAL_AFRICAN_REPUBLIC') },
    { value: 'td', label: t('CHAD') },
    { value: 'cl', label: t('CHILE') },
    { value: 'cn', label: t('CHINA') },
    { value: 'cx', label: t('CHRISTMAS_ISLAND') },
    { value: 'cc', label: t('COCOS_ISLANDS') },
    { value: 'co', label: t('COLOMBIA') },
    { value: 'km', label: t('COMOROS') },
    { value: 'ck', label: t('COOK_ISLANDS') },
    { value: 'cr', label: t('COSTA_RICA') },
    { value: 'hr', label: t('CROATIA') },
    { value: 'cu', label: t('CUBA') },
    { value: 'cw', label: t('CURACAO') },
    { value: 'cy', label: t('CYPRUS') },
    { value: 'cz', label: t('CZECH_REPUBLIC') },
    { value: 'cd', label: t('DEMOCRATIC_REPUBLIC_OF_THE_CONGO') },
    { value: 'dk', label: t('DENMARK') },
    { value: 'dj', label: t('DJIBOUTI') },
    { value: 'dm', label: t('DOMINICA') },
    { value: 'do', label: t('DOMINICAN_REPUBLIC') },
    { value: 'tl', label: t('EAST_TIMOR') },
    { value: 'ec', label: t('ECUADOR') },
    { value: 'eg', label: t('EGYPT') },
    { value: 'sv', label: t('EL_SALVADOR') },
    { value: 'gq', label: t('EQUATORIAL_GUINEA') },
    { value: 'er', label: t('ERITREA') },
    { value: 'ee', label: t('ESTONIA') },
    { value: 'et', label: t('ETHIOPIA') },
    { value: 'fk', label: t('FALKLAND_ISLANDS') },
    { value: 'fo', label: t('FAROE_ISLANDS') },
    { value: 'fj', label: t('FIJI') },
    { value: 'fi', label: t('FINLAND') },
    { value: 'fr', label: t('FRANCE') },
    { value: 'pf', label: t('FRENCH_POLYNESIA') },
    { value: 'ga', label: t('GABON') },
    { value: 'gm', label: t('GAMBIA') },
    { value: 'ge', label: t('GEORGIA') },
    { value: 'de', label: t('GERMANY') },
    { value: 'gh', label: t('GHANA') },
    { value: 'gi', label: t('GIBRALTAR') },
    { value: 'gr', label: t('GREECE') },
    { value: 'gl', label: t('GREENLAND') },
    { value: 'gd', label: t('GRENADA') },
    { value: 'gu', label: t('GUAM') },
    { value: 'gt', label: t('GUATEMALA') },
    { value: 'gg', label: t('GUERNSEY') },
    { value: 'gn', label: t('GUINEA') },
    { value: 'gw', label: t('GUINEA_BISSAU') },
    { value: 'gy', label: t('GUYANA') },
    { value: 'ht', label: t('HAITI') },
    { value: 'hn', label: t('HONDURAS') },
    { value: 'hk', label: t('HONG_KONG') },
    { value: 'hu', label: t('HUNGARY') },
    { value: 'is', label: t('ICELAND') },
    { value: 'in', label: t('INDIA') },
    { value: 'id', label: t('INDONESIA') },
    { value: 'ir', label: t('IRAN') },
    { value: 'iq', label: t('IRAQ') },
    { value: 'ie', label: t('IRELAND') },
    { value: 'im', label: t('ISLE_OF_MAN') },
    { value: 'il', label: t('ISRAEL') },
    { value: 'it', label: t('ITALY') },
    { value: 'ci', label: t('IVORY_COAST') },
    { value: 'jm', label: t('JAMAICA') },
    { value: 'jp', label: t('JAPAN') },
    { value: 'je', label: t('JERSEY') },
    { value: 'jo', label: t('JORDAN') },
    { value: 'kz', label: t('KAZAKHSTAN') },
    { value: 'ke', label: t('KENYA') },
    { value: 'ki', label: t('KIRIBATI') },
    { value: 'xk', label: t('KOSOVO') },
    { value: 'kw', label: t('KUWAIT') },
    { value: 'kg', label: t('KYRGYZSTAN') },
    { value: 'la', label: t('LAOS') },
    { value: 'lv', label: t('LATVIA') },
    { value: 'lb', label: t('LEBANON') },
    { value: 'ls', label: t('LESOTHO') },
    { value: 'lr', label: t('LIBERIA') },
    { value: 'ly', label: t('LIBYA') },
    { value: 'li', label: t('LIECHTENSTEIN') },
    { value: 'lt', label: t('LITHUANIA') },
    { value: 'lu', label: t('LUXEMBOURG') },
    { value: 'mo', label: t('MACAU') },
    { value: 'mk', label: t('MACEDONIA') },
    { value: 'mg', label: t('MADAGASCAR') },
    { value: 'mw', label: t('MALAWI') },
    { value: 'my', label: t('MALAYSIA') },
    { value: 'mv', label: t('MALDIVES') },
    { value: 'ml', label: t('MALI') },
    { value: 'mt', label: t('MALTA') },
    { value: 'mh', label: t('MARSHALL_ISLANDS') },
    { value: 'mr', label: t('MAURITANIA') },
    { value: 'mu', label: t('MAURITIUS') },
    { value: 'yt', label: t('MAYOTTE') },
    { value: 'mx', label: t('MEXICO') },
    { value: 'fm', label: t('MICRONESIA') },
    { value: 'md', label: t('MOLDOVA') },
    { value: 'mc', label: t('MONACO') },
    { value: 'mn', label: t('MONGOLIA') },
    { value: 'me', label: t('MONTENEGRO') },
    { value: 'ms', label: t('MONTSERRAT') },
    { value: 'ma', label: t('MOROCCO') },
    { value: 'mz', label: t('MOZAMBIQUE') },
    { value: 'mm', label: t('MYANMAR') },
    { value: 'na', label: t('NAMIBIA') },
    { value: 'nr', label: t('NAURU') },
    { value: 'np', label: t('NEPAL') },
    { value: 'nl', label: t('NETHERLANDS') },
    { value: 'an', label: t('NETHERLANDS_ANTILLES') },
    { value: 'nc', label: t('NEW_CALEDONIA') },
    { value: 'nz', label: t('NEW_ZEALAND') },
    { value: 'ni', label: t('NICARAGUA') },
    { value: 'ne', label: t('NIGER') },
    { value: 'ng', label: t('NIGERIA') },
    { value: 'nu', label: t('NIUE') },
    { value: 'kp', label: t('NORTH_KOREA') },
    { value: 'mp', label: t('NORTHERN_MARIANA_ISLANDS') },
    { value: 'no', label: t('NORWAY') },
    { value: 'om', label: t('OMAN') },
    { value: 'pk', label: t('PAKISTAN') },
    { value: 'pw', label: t('PALAU') },
    { value: 'ps', label: t('PALESTINE') },
    { value: 'pa', label: t('PANAMA') },
    { value: 'pg', label: t('PAPUA_NEW_GUINEA') },
    { value: 'py', label: t('PARAGUAY') },
    { value: 'pe', label: t('PERU') },
    { value: 'ph', label: t('PHILIPPINES') },
    { value: 'pn', label: t('PITCAIRN') },
    { value: 'pl', label: t('POLAND') },
    { value: 'pt', label: t('PORTUGAL') },
    { value: 'pr', label: t('PUERTO_RICO') },
    { value: 'qa', label: t('QATAR') },
    { value: 'cg', label: t('REPUBLIC_OF_THE_CONGO') },
    { value: 're', label: t('REUNION') },
    { value: 'ro', label: t('ROMANIA') },
    { value: 'ru', label: t('RUSSIA') },
    { value: 'rw', label: t('RWANDA') },
    { value: 'bl', label: t('SAINT_BARTHELEMY') },
    { value: 'sh', label: t('SAINT_HELENA') },
    { value: 'kn', label: t('SAINT_KITTS_AND_NEVIS') },
    { value: 'lc', label: t('SAINT_LUCIA') },
    { value: 'mf', label: t('SAINT_MARTIN') },
    { value: 'pm', label: t('SAINT_PIERRE_AND_MIQUELON') },
    { value: 'vc', label: t('SAINT_VINCENT_AND_THE_GRENADINES') },
    { value: 'ws', label: t('SAMOA') },
    { value: 'sm', label: t('SAN_MARINO') },
    { value: 'st', label: t('SAO_TOME_AND_PRINCIPE') },
    { value: 'sa', label: t('SAUDI_ARABIA') },
    { value: 'sn', label: t('SENEGAL') },
    { value: 'rs', label: t('SERBIA') },
    { value: 'sc', label: t('SEYCHELLES') },
    { value: 'sl', label: t('SIERRA_LEONE') },
    { value: 'sg', label: t('SINGAPORE') },
    { value: 'sx', label: t('SINT_MAARTEN') },
    { value: 'sk', label: t('SLOVAKIA') },
    { value: 'si', label: t('SLOVENIA') },
    { value: 'sb', label: t('SOLOMON_ISLANDS') },
    { value: 'so', label: t('SOMALIA') },
    { value: 'za', label: t('SOUTH_AFRICA') },
    { value: 'kr', label: t('SOUTH_KOREA') },
    { value: 'ss', label: t('SOUTH_SUDAN') },
    { value: 'es', label: t('SPAIN') },
    { value: 'lk', label: t('SRI_LANKA') },
    { value: 'sd', label: t('SUDAN') },
    { value: 'sr', label: t('SURINAME') },
    { value: 'sj', label: t('SVALBARD_AND_JAN_MAYEN') },
    { value: 'sz', label: t('SWAZILAND') },
    { value: 'se', label: t('SWEDEN') },
    { value: 'ch', label: t('SWITZERLAND') },
    { value: 'sy', label: t('SYRIA') },
    { value: 'tw', label: t('TAIWAN') },
    { value: 'tj', label: t('TAJIKISTAN') },
    { value: 'tz', label: t('TANZANIA') },
    { value: 'th', label: t('THAILAND') },
    { value: 'tg', label: t('TOGO') },
    { value: 'tk', label: t('TOKELAU') },
    { value: 'to', label: t('TONGA') },
    { value: 'tt', label: t('TRINIDAD_AND_TOBAGO') },
    { value: 'tn', label: t('TUNISIA') },
    { value: 'tr', label: t('TURKEY') },
    { value: 'tm', label: t('TURKMENISTAN') },
    { value: 'tc', label: t('TURKS_AND_CAICOS_ISLANDS') },
    { value: 'tv', label: t('TUVALU') },
    { value: 'vi', label: t('US_VIRGIN_ISLANDS') },
    { value: 'ug', label: t('UGANDA') },
    { value: 'ua', label: t('UKRAINE') },
    { value: 'ae', label: t('UNITED_ARAB_EMIRATES') },
    { value: 'gb', label: t('UNITED_KINGDOM') },
    { value: 'us', label: t('UNITED_STATES') },
    { value: 'uy', label: t('URUGUAY') },
    { value: 'uz', label: t('UZBEKISTAN') },
    { value: 'vu', label: t('VANUATU') },
    { value: 'va', label: t('VATICAN') },
    { value: 've', label: t('VENEZUELA') },
    { value: 'vn', label: t('VIETNAM') },
    { value: 'wf', label: t('WALLIS_AND_FUTUNA') },
    { value: 'eh', label: t('WESTERN_SAHARA') },
    { value: 'ye', label: t('YEMEN') },
    { value: 'zm', label: t('ZAMBIA') },
    { value: 'zw', label: t('ZIMBABWE') },
    { value: 'ax', label: t('ÅLAND') }
  ];
};

export type CountryOption = ReturnType<typeof getCountries>[number];

export const EU_ISO_CODES = [
  'at',
  'be',
  'bg',
  'cy',
  'cz',
  'de',
  'dk',
  'ee',
  'es',
  'fi',
  'fr',
  'gr',
  'hr',
  'hu',
  'ie',
  'it',
  'lt',
  'lu',
  'lv',
  'mt',
  'nl',
  'pl',
  'pt',
  'ro',
  'se',
  'si',
  'sk'
];

export const EEA_ISO_CODES = EU_ISO_CODES.concat(['no', 'is', 'li']);

export const getCountryFromValue = (value: string, t: TranslateFunction) => {
  const countries = getCountries(t);
  return countries.find(country => country.value === value) || null;
};

export const getZipCodeValidationRules = (
  countryValue?: CountryOption['value']
) => {
  switch (countryValue) {
    case 'se':
      return regexSwedishZipCode;
    // add more rules here...
    default:
      return regexZipCode;
  }
};
