import { createSelector } from 'reselect';

import { ReduxStore } from '../interfaces/store';
import { WebsocketBids } from '../interfaces/store/WebsocketBids';
import { WebsocketAuctions } from '../interfaces/store/WebsocketAuctions';

const websocketBids = createSelector<ReduxStore, WebsocketBids, WebsocketBids>(
  root => root?.websocketBids,
  root => root
);

export const websocketBidsById = createSelector(
  websocketBids,
  root => root?.byId ?? {}
);

const websocketAuctions = createSelector<
  ReduxStore,
  WebsocketAuctions,
  WebsocketAuctions
>(
  root => root?.websocketAuctions,
  root => root
);

export const websocketAuctionsById = createSelector(
  websocketAuctions,
  root => root?.byId ?? {}
);
