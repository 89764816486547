import React, { useEffect } from 'react';
import loadable from '@loadable/component';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from '~/Locale';
import { getBaseCanonicalUrl } from '~/helpers/baseCanonicalUrl';
import { session as sessionSelector } from '~/App/shared/selectors/sessionSelector';
import Head from '~/App/shared/components/Head';
import AboutArea from '~/App/views/Start/components/AboutArea';
import SEOInfo from '~/App/shared/components/SEOInfo';
import Section from '~/App/shared/components/Layout/Section';
import Content from '~/App/shared/components/Layout/Content';
import LazyComponentFallback from '~/App/shared/components/LazyComponentFallback';
import Newsletter from './components/Newsletter';
import UserAuctions from './components/UserAuctions';
import LargeFeaturedCard from './components/LargeFeaturedCard';
import SmallFeatureCards from './components/SmallFeatureCards';
import {
  Flex,
  Headline,
  Spacer,
  Subtitle,
  Title,
  mq
} from '@kvdbil/components';
import { requestErrorHandler } from '~/helpers/notifyError';
import { getAuctionSlugByProcessObjectId } from '~/helpers/orchestration/auction';
import { Size, Color } from '@kvdbil/components/types/Types';
import {
  AboutAreaRecord,
  FeaturedCardRecord,
  BlockButtonRecord,
  TitleWithTextRecord,
  StartPageQuery,
  StartPageDocument,
  SmallPromoBannerRecord,
  ContactSectionRecord,
  SmallFeatureCardsBlockRecord,
  OptionalHeroRecord
} from '~/config/generated/graphql';
import { useLocation } from 'react-router';
import { metaHelper } from '~/helpers/seo';
import AllBrandsSection from '~/App/views/CarTreeAndCategory/components/AllBrandsSection';
import { useCmsData } from '~/App/shared/hooks/useCmsData';
import { StartPageData } from '~/App/shared/interfaces/store/CmsData';
import PromoBanners from './components/CampaignBanners/PromoBanners';
import TrustPilotTop from '~/App/shared/components/TrustPilotTop';
import MainCategoriesSection, {
  MainCategoryBlurb
} from '~/App/shared/components/MainCategoriesSection';
import { IncludeContent } from '~/App/shared/components/MediaQueryContainer';
import FeatureBlocks from './components/FeatureBlocks';
import Contact from '../ObjectPage/components/Contact';
import TrustPilot from '~/App/shared/components/TrustPilot';
import KvdHelperWrapper from '~/App/shared/components/KvdHelperWrapper';

const MainCategoryContainer = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background.light};
  padding: 2rem 1rem;

  ${mq('tablet')} {
    padding: 3rem 1.5rem;
    max-width: 64rem;
  }
`;

const FeaturedCardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;

  ${mq(null, 'tablet')} {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
`;

const TrustPilotContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FeaturedObjects = loadable(
  () => import('../../shared/components/FeaturedObjects'),
  {
    fallback: <LazyComponentFallback />
  }
);

export type StartPageButtons = {
  color: Color;
  size: Size;
  text: string;
  url: string;
}[];

export const Start = () => {
  const { t } = useTranslation();
  const session = useSelector(sessionSelector);
  const { search, pathname } = useLocation();

  const { data: cmsData, fetching } = useCmsData<StartPageQuery, StartPageData>(
    {
      storeKey: 'startPage',
      query: StartPageDocument
    }
  );

  const showSmallTrustPilot = cmsData?.showSmallTrustPilot;
  const smallPromoBanner = cmsData?.promoBanners?.smallPromoBanner;

  const largePromoBanner = cmsData?.promoBanners?.largePromoBanner;
  const renderPromoBanner =
    (smallPromoBanner && smallPromoBanner[0]?.showSmallBanner) ||
    (largePromoBanner && largePromoBanner[0]?.showHero);

  const myVehiclesHeadline = cmsData?.myVehiclesHeadline as string | undefined;
  const mainCategories = cmsData?.mainCategories;
  const featuredBlocks = cmsData?.featuredBlocks;
  const featuredObjects = cmsData?.featuredObjects;
  const companyInfo = cmsData?.companyInfo?.[0];
  const contactSection = cmsData?.contactSection?.[0];
  const isFeatureCardsActive = cmsData?.featureCards.isActive;
  const smallFeatureCards = cmsData?.featureCards?.smallFeatureCards?.[0];
  const largeFeatureCards = cmsData?.featureCards?.largeFeatureCards ?? [];
  const trustPilotTitle = cmsData?.bigTrustPilotTitle;
  const seoInformationStart = cmsData?.seoInfo?.seoInformationStart;
  const seoInformationStartCorporate =
    cmsData?.seoInfo?.seoInformationStartCorporate;
  const seo = cmsData?.seo;
  const datoUrl = cmsData?.kvdHelperUrl;

  const routeAuctions = t('ROUTES.AUCTIONS');

  const { ref: featuredObjectsRef, inView: featuredObjectsInView } = useInView({
    rootMargin: '100px',
    triggerOnce: true
  });

  const searchParams = new URLSearchParams(search);
  const objnr = searchParams.get('objnr');
  const blnr = searchParams.get('blnr');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const processObjectId = objnr || blnr;

      if (processObjectId) {
        getAuctionSlugByProcessObjectId(processObjectId)
          .then(path => {
            let url = `${routeAuctions}/${path}`;

            if (blnr) {
              const utmSource = 'blocket.se';
              const utmMedium = 'referral';
              const utmCampaign = 'blocket-radannons';
              const utmTerm = path.substring(0, path.indexOf('-'));

              url += `?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&utm_term=${utmTerm}`;
            }
            window.location.replace(url);
          })
          .catch(requestErrorHandler);
      }
    }
  }, [objnr, blnr, routeAuctions]);

  return (
    <Content>
      <Head
        title={seo?.title ?? undefined}
        meta={metaHelper({
          t,
          description: seo?.description,
          seoTitle: seo?.title,
          seoDescription: seo?.description,
          seoImage: seo?.image?.url
        })}
        link={[{ rel: 'canonical', href: getBaseCanonicalUrl(pathname) }]}
      />
      <KvdHelperWrapper url={datoUrl ?? ''}>
        <div>
          {showSmallTrustPilot &&
            !session.isAuthenticated &&
            !renderPromoBanner && <TrustPilotTop />}

          {renderPromoBanner && (
            <PromoBanners
              smallPromoBanner={smallPromoBanner as SmallPromoBannerRecord[]}
              largePromoBanner={largePromoBanner as OptionalHeroRecord[]}
            />
          )}

          {session.isAuthenticated && (
            <UserAuctions headline={myVehiclesHeadline} />
          )}

          <Flex justify="center" align="center">
            <MainCategoryContainer
              direction="column"
              justify="center"
              basis={1}
              grow={1}
              gap={{
                mobileS: '1.5rem',
                tablet: '1rem'
              }}
            >
              <IncludeContent to="tablet">
                <Spacer height={0.5} />
                <Flex justify="center" align="center">
                  <Headline>{mainCategories?.headline}</Headline>
                </Flex>
              </IncludeContent>

              <IncludeContent from="tablet">
                <Flex justify="center" align="center">
                  <Subtitle>{mainCategories?.headline}</Subtitle>
                </Flex>
              </IncludeContent>
              <MainCategoriesSection
                mainCategories={mainCategories?.blurbs as MainCategoryBlurb}
              />
            </MainCategoryContainer>
          </Flex>

          {featuredBlocks && (
            <FeatureBlocks
              title={featuredBlocks?.title as string}
              blockButtons={featuredBlocks?.blockButtons as BlockButtonRecord[]}
            />
          )}

          {featuredObjects && featuredObjects?.length > 0 && (
            <div ref={featuredObjectsRef}>
              {featuredObjectsInView && (
                <FeaturedObjects lists={featuredObjects} />
              )}
            </div>
          )}

          <Section spacing={3} maxWidth={83} backgroundColor="gray">
            {companyInfo && <AboutArea {...(companyInfo as AboutAreaRecord)} />}
            <Spacer height={3} />
            {contactSection && (
              <Contact {...(contactSection as ContactSectionRecord)} />
            )}
            {(smallFeatureCards || largeFeatureCards?.length > 0) && (
              <>
                {smallFeatureCards && isFeatureCardsActive && (
                  <>
                    <Spacer height={3} />
                    <SmallFeatureCards
                      {...(smallFeatureCards as SmallFeatureCardsBlockRecord)}
                      isLoading={fetching}
                    />
                  </>
                )}

                <Spacer height={3} />

                {largeFeatureCards?.length > 0 && (
                  <FeaturedCardGrid>
                    {largeFeatureCards.map(
                      (featuredCard: FeaturedCardRecord, key: number) => (
                        <LargeFeaturedCard key={key} {...featuredCard} />
                      )
                    )}
                  </FeaturedCardGrid>
                )}
              </>
            )}
            <Spacer height={3} />
            {seoInformationStart && (
              <SEOInfo content={seoInformationStart as TitleWithTextRecord} />
            )}

            <Spacer height={3} />
            {seoInformationStartCorporate && (
              <SEOInfo
                content={seoInformationStartCorporate as TitleWithTextRecord}
              />
            )}
          </Section>

          <Section spacing={2} backgroundColor="light">
            <TrustPilotContainer>
              {trustPilotTitle && <Title as={'h2'}>{trustPilotTitle}</Title>}
              <Spacer height={2} />
              <TrustPilot type="Horizontal" />
              <Spacer height={2} />
              <TrustPilot type="Slider" />
            </TrustPilotContainer>
          </Section>

          <Section spacing={3} backgroundColor="gray" maxWidth={28.375}>
            <Newsletter />
          </Section>

          <AllBrandsSection mainCategory="car" />
        </div>
      </KvdHelperWrapper>
    </Content>
  );
};

export default Start;
