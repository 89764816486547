import React, { useRef } from 'react';
import ContainerWithImage from '~/App/shared/components/ProductCard/components/ContainerWithImage';
import Title from '~/App/shared/components/ProductCard/components/Title';
import Financing from '~/App/shared/components/ProductCard/components/Financing';
import Auction from '~/App/shared/interfaces/Auction';
import { getAuctionLink } from '~/helpers/auction';
import Link from '~/App/shared/components/Link';
import useProductCardBaseProps from '~/App/shared/components/ProductCard/hooks/useProductCardBaseProps';
import Subtitle from '~/App/shared/components/ProductCard/components/Subtitle';
import useProductCardIconsProps from '~/App/shared/components/ProductCard/hooks/useProductCardIconsProps';
import Icons from '~/App/shared/components/ProductCard/components/Icons';
import Properties from '~/App/shared/components/ProductCard/components/Properties';
import { useTranslation } from '~/Locale';
import AuctionStatusForCard from '~/App/shared/components/ProductCard/components/AuctionStatusForCard';
import useAuctionStatuses from '~/App/shared/components/ProductCard/hooks/useAuctionStatuses';
import { getObjectPreviewProperties } from '~/helpers/processObject';
import {
  getObjectLocationData,
  isHeavyEquipmentObject
} from '~/App/views/ObjectPage/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { isRememberScrollActiveSelector } from '../../selectors/storeObjectsSelectors';
import {
  setFilterPageActiveAction,
  setFilterPageScrollData
} from '../../actions/storeObjectActions';
import { useScrollToProductCard } from '~/App/views/FilterPage/hooks/filterPageScrollHooks';
import { useLocation } from 'react-router';

export interface ProductCardRegularProps {
  auction: Auction;
}

const ProductCardRegular = ({ auction }: ProductCardRegularProps) => {
  const { t } = useTranslation();
  const { imageProps, financingProps, processedAuction } =
    useProductCardBaseProps(auction);
  const {
    isCertified,
    isEcoFriendly,
    isReleasable,
    isFavourite,
    toggleFavourite
  } = useProductCardIconsProps(auction);
  const auctionStatusItems = useAuctionStatuses(processedAuction, {
    processingPause: imageProps.isFixedPriceOnly
  });
  const isHeavyEquipment = isHeavyEquipmentObject(auction?.processObject);

  const properties = getObjectPreviewProperties(
    t,
    auction.processObject,
    false
  );

  const locationToDisplay = getObjectLocationData(auction.processObject).name;

  const dispatch = useDispatch();
  const ref = useRef<HTMLAnchorElement>(null);
  const isRememberScrollActive = useSelector(isRememberScrollActiveSelector);
  const location = useLocation();

  const handleClick = () => {
    if (isRememberScrollActive) {
      dispatch(setFilterPageActiveAction(false)); // Prevent scroll while navigating
      dispatch(
        setFilterPageScrollData({
          auctionId: auction.id,
          url: location.pathname
        })
      );
    }
  };

  useScrollToProductCard({ auctionId: auction.id, ref });

  return (
    <span ref={ref}>
      <Link
        to={getAuctionLink(auction)}
        onClick={handleClick}
        data-testid="product-card"
      >
        <ContainerWithImage
          {...imageProps}
          actionOpenedAt={auction.openedAt}
          objectLocation={locationToDisplay}
          isHeavyEquipment={isHeavyEquipment}
        >
          {auctionStatusItems.length !== 0 && (
            <AuctionStatusForCard items={auctionStatusItems} />
          )}
          <Icons
            isCertified={isCertified}
            isEcoFriendly={isEcoFriendly}
            isFavourite={isFavourite}
            isReleasable={isReleasable}
            toggleFavourite={toggleFavourite}
          />
          <Title>{auction.processObject.heading1}</Title>
          <Subtitle>
            {auction.processObject.heading2 ??
              auction.processObject.heading3 ??
              ''}
          </Subtitle>
          <Properties properties={properties} />
          <Financing {...financingProps} />
        </ContainerWithImage>
      </Link>
    </span>
  );
};

export default ProductCardRegular;
