import { createSelector } from 'reselect';
import { ReduxStore } from '../interfaces/store';

const storeObjectRoot = (state: ReduxStore) => state.objectView;

export const storeObjectsSelector = createSelector(
  storeObjectRoot,
  state => state?.storeObjects
);

export const storeObjectById = (auctionId: number | string) => {
  const id = Number(auctionId);
  return createSelector(storeObjectsSelector, auctions => auctions?.[id]);
};

export const isFetching = createSelector(
  storeObjectRoot,
  state => state?.isFetching ?? false
);

export const storeObjectAuctionId = createSelector(storeObjectRoot, state => {
  return state?.auctionId;
});

export const objectViewDrawerAuction = createSelector(
  storeObjectsSelector,
  storeObjectAuctionId,
  (auctions, id) => {
    if (!id) return undefined;
    return auctions[parseInt(id, 10)];
  }
);

export const objectViewDrawerFlow = createSelector(
  storeObjectRoot,
  biddingView => biddingView?.flow
);

export const objectViewDrawerBuyMethod = createSelector(
  storeObjectRoot,
  biddingView => biddingView?.buyMethod
);

export const objectViewDrawerSupportScheduleSelector = createSelector(
  storeObjectRoot,
  biddingView => biddingView?.supportSchedule?.schedule ?? []
);

export const objectViewDrawerSupportLastUpdatedSelector = createSelector(
  storeObjectRoot,
  biddingView => biddingView?.supportSchedule?.lastUpdate ?? null
);

export const allBids = createSelector(
  storeObjectRoot,
  state => state?.allBids ?? []
);

export const isObjectViewDrawerOpen = createSelector(
  storeObjectRoot,
  state => state?.isOpen
);
