import {
  BodyText,
  Button,
  Modal,
  mq,
  SwedishFlagIcon,
  Title
} from '@kvdbil/components';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from '~/Locale';
import { Locale } from '~/App/shared/localization/types';
import { useSelector } from 'react-redux';
import { localizationSelector } from '~/App/shared/selectors/localization';
import LanguageSelectModal from '~/App/shared/localization/components/LanguageSelectModal';

const Container = styled.div`
  padding: 1rem 1.5rem;
`;

const Buttons = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  justify-content: center;

  ${mq('laptop')} {
    grid-template-columns: 1fr 1fr;
  }
`;

const StyledButton = styled(Button)`
  justify-content: center;
`;

const StyledSwedishFlagIcon = styled(SwedishFlagIcon)`
  font-size: 1.5rem;
`;

function LanguageModal() {
  const { t } = useTranslation();
  const { domainLocale } = useSelector(localizationSelector);
  const [isModalOpen, setIsModalOpen] = useState(() => {
    if (typeof window === 'undefined') return false;

    const languageModalShown = localStorage.getItem('languageModalShown');

    if (
      (!languageModalShown || !JSON.parse(languageModalShown)) &&
      !navigator.language.startsWith(Locale.SV) &&
      domainLocale !== Locale.EN
    )
      return true;

    return false;
  });

  const handleModalClose = useCallback(() => {
    localStorage.setItem('languageModalShown', 'true');
    setIsModalOpen(false);
  }, []);

  return (
    <Modal isOpen={isModalOpen} onClose={handleModalClose}>
      <Container>
        <Title>Preferred language</Title>
        <BodyText style={{ marginTop: '1rem' }}>{t('LANGUAGE_MODAL')}</BodyText>

        <Buttons>
          <StyledButton
            fullWidth
            color="neutral"
            variant="flat"
            prefixIcon={<StyledSwedishFlagIcon />}
            onClick={handleModalClose}
          >
            {t('Continue in Swedish')}
          </StyledButton>
          <LanguageSelectModal />
        </Buttons>
      </Container>
    </Modal>
  );
}

export default LanguageModal;
