import { mqSizes } from '@kvdbil/components';

export type MQ_SIZE = keyof typeof mqSizes;

export type MQSizeValues<T = number> = {
  [key in MQ_SIZE]: T;
};

export type PartialMQSizeValues<T = number> = Partial<MQSizeValues<T>>;

const mqSizesOrder: MQSizeValues<number> = {
  mobileS: 1,
  mobileM: 2,
  mobileL: 3,
  tablet: 4,
  laptop: 5,
  laptopL: 6,
  laptopXL: 7,
  desktop: 8
};

// gets all mq sizes in order from smallest to largest
export const mqSizesOrderSorted = (Object.keys(mqSizesOrder) as MQ_SIZE[]).sort(
  (a, b) => mqSizesOrder[a] - mqSizesOrder[b]
);

// gets all mq sizes from smallest to defined from size
export const getMqSizesFromSmallestTo = (to: MQ_SIZE): MQ_SIZE[] => {
  const fromIndex = mqSizesOrderSorted.indexOf(to);

  return mqSizesOrderSorted.slice(0, fromIndex + 1);
};

export const getMqSizesWithFallbacks = <T = number>(
  props: PartialMQSizeValues<T>
) =>
  mqSizesOrderSorted.reduce((acc, curr) => {
    // retrives all sizes from smallest to current
    const mqSizes = getMqSizesFromSmallestTo(curr).reverse();

    // find the first mqSize that is set
    const mqSize = mqSizes.find(mqSize => props[mqSize]);

    return { ...acc, [curr]: mqSize ? props[mqSize] : undefined };
  }, {} as PartialMQSizeValues);
