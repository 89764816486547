import React from 'react';
import styled from 'styled-components';
import { theme } from '@kvdbil/components';
import ContentLoader from 'react-content-loader';

const ContentLoaderCardWrapper = styled.div`
  border: ${({ theme }) => `1px solid ${theme.colors.gray.light4}`};
  border-radius: 0.25rem;
  margin: auto;
  display: flex;
  width: 100%;

  svg {
    height: 346px;
    width: 100%;
  }
`;

const AuctionLoadingCard = () => (
  <ContentLoaderCardWrapper data-testid="product-card-loader">
    <ContentLoader
      animate={false}
      height={346}
      width={244}
      backgroundColor={theme.colors.gray.light4}
      foregroundColor={theme.colors.gray.light5}
    >
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="162" />
      <rect x="10" y="180" rx="3" ry="3" width="160" height="12" />
      <rect x="10" y="200" rx="3" ry="3" width="100" height="12" />
      <rect x="10" y="240" rx="0" ry="0" width="234" height="1" />
      <rect x="10" y="300" rx="3" ry="3" width="82" height="12" />
      <rect x="140" y="300" rx="3" ry="3" width="82" height="12" />
    </ContentLoader>
  </ContentLoaderCardWrapper>
);

export default AuctionLoadingCard;
