import axios from '~/helpers/orchestration/axiosWithAuthHandling';
import { orchestration } from '~/config/public';
import {
  MONTHLY_COST_DOWNPAYMENT_PERCENT,
  MONTHLY_COST_MONTHS
} from '~/config/constants';
import ProcessObject from '~/App/shared/interfaces/ProcessObject';

type FacilityTransportPrice = {
  currency: string;
  fromFacilityId: string;
  price: number;
  toFacilityId: string;
};
type TransporCostResponse = {
  facilityTransportPrice: FacilityTransportPrice[];
};
export const calculateTransport = (
  fromFacilityId: string,
  toFacilityId: string,
  memberId: string,
  processObjectId: string
) =>
  axios
    .get<TransporCostResponse>(`${orchestration.url}/common/transport-costs`, {
      params: {
        fromFacilityId: fromFacilityId,
        toFacilityId: toFacilityId,
        memberId: memberId,
        processObjectId
      }
    })
    .then(({ data }) => {
      const [item] = data.facilityTransportPrice;
      return item;
    });

export interface CalculateFinanceInput {
  price: number;
  downPayment: number;
  months: number;
  interestRate: number;
  residualPercentage?: number;
}
export const calculateFinance = ({
  downPayment,
  interestRate,
  months,
  price,
  residualPercentage = 0
}: CalculateFinanceInput) => {
  const normalizedInterestRate = interestRate / 100;
  const residualValue =
    residualPercentage > 0 ? price * (1 - residualPercentage) : 0;
  const loan = price - residualValue - downPayment;
  const rate = normalizedInterestRate / 12;

  /* Calculate interest rate for residual value. */
  const residualValueMonthlyCost = residualValue * rate;

  return (
    Math.round(loan / ((1 * (1 - Math.pow(1 + rate, -months))) / rate)) +
    Math.round(residualValueMonthlyCost)
  );
};

export const calculateBaseLoan = (price: number, globalInterestRate: number) =>
  calculateFinance({
    price,
    downPayment: MONTHLY_COST_DOWNPAYMENT_PERCENT * price,
    months: MONTHLY_COST_MONTHS,
    interestRate: globalInterestRate
  });

export type WarrantyType = 'bas' | 'xtra' | 'maxi';

export const calculateWarranty = (
  mileage: number,
  age: number
): WarrantyType => {
  if (mileage <= 15000) {
    if (age <= 8) {
      return 'maxi';
    }
  }

  if (mileage < 18000) {
    if (age <= 9) {
      return 'xtra';
    }
  }

  return 'bas';
};

export const RESIDUAL_LOAN_MAX_ODOMETER_READING = 200000;
export const RESIDUAL_LOAN_MAX_AGE = 10;
export const getAllowedResidualLoan = (processObject: ProcessObject) => {
  const currentYear = new Date().getFullYear();
  const age = currentYear - processObject.properties?.modelYear;

  return (
    processObject.properties?.odometerReading <=
      RESIDUAL_LOAN_MAX_ODOMETER_READING && age <= RESIDUAL_LOAN_MAX_AGE
  );
};
