import {
  ObjectPageQuery,
  FixedPriceReservationTipsQuery,
  ObjectServiceRecord,
  ObjectServicesQuery,
  TextRecord
} from '~/config/generated/graphql';

// Type gen messes up and makes all "BooleanTypes" to any and "Omit + extend" doesn't work
type MissingTypes = {
  contactSectionControllerFixedPrice: boolean;
  contactSectionControllerAuction: boolean;
};

export type CMSDataObjectPageService = ObjectServiceRecord;

type ObjectServiceArray = CMSDataObjectPageService[] | undefined;

const findService = (services: ObjectServiceArray, serviceType: string) => {
  return services?.find(service => service?.serviceType === serviceType);
};

const serviceTypes = [
  'SELL_OBJECT',
  'SERVICE_DEAL',
  'SERVICE_DEAL_RELEASING',
  'LEASING',
  'FINANCING',
  'TRANSPORT',
  'WARRANTY'
] as const;

const handleServices = (services: ObjectServiceArray) => {
  return serviceTypes.reduce((acc, serviceType) => {
    acc[serviceType] = findService(services, serviceType);
    return acc;
  }, {} as Record<(typeof serviceTypes)[number], CMSDataObjectPageService | undefined>);
};

const getBulletPoints = (bulletPoints?: TextRecord[]) =>
  bulletPoints?.map(bullet => bullet?.text).filter(Boolean);
export const handleObjectPageData = ({
  objectPage
}: Partial<ObjectPageQuery>) => {
  return {
    contactSection: {
      contact: objectPage?.contact[0],
      fixedPriceController: (objectPage as MissingTypes)
        ?.contactSectionControllerFixedPrice,
      auctionController: (objectPage as MissingTypes)
        ?.contactSectionControllerAuction
    },
    buyMethods: {
      biddingTutorialLink: objectPage?.buyMethodsBiddingTutorialLink,
      fixedPriceTutorialLink: objectPage?.buyMethodsFixedPriceTutorialLink
    },
    heavyEquipment: {
      descriptionWarning: objectPage?.heavyEquipmentDescriptionWarning,
      facilityExplanation: objectPage?.heavyEquipmentFacilityExplanation,
      salesTermsTitle: objectPage?.heavyEquipmentSalesTermsTitle,
      salesTerms: objectPage?.heavyEquipmentSalesTerms,
      vehicleFactsDisclaimer: objectPage?.heavyEquipmentVehicleFactsDisclaimer,
      servicesTitle: objectPage?.heavyEquipmentServicesTitle,
      services: objectPage?.heavyEquipmentServices
    },
    services: {
      servicesData: {
        ...handleServices(objectPage?.services as ObjectServiceArray)
      },
      servicesTitle: objectPage?.servicesTitle
    },
    biddingGuide: {
      steps: objectPage?.guideSteps,
      buttons: {
        nextButton: objectPage?.nextButtonText,
        exitButton: objectPage?.exitButtonText,
        finalButton: objectPage?.finalButtonText
      },
      finalLink: objectPage?.finalLink
    },
    ecoFriendly: {
      title: objectPage?.ecoTitle,
      subtitle: objectPage?.ecoSubtitle,
      description: objectPage?.ecoDescription,
      bulletPoints: getBulletPoints(
        objectPage?.ecoBulletPoints as TextRecord[]
      ),
      imageDescription: objectPage?.ecoImageDescription,
      readMoreUrl: objectPage?.ecoReadMoreUrl,
      readMoreText: objectPage?.ecoReadMoreText
    },
    certified: {
      title: objectPage?.certifiedTitle,
      description: objectPage?.certifiedDescription,
      bulletPoints: getBulletPoints(
        objectPage?.certifiedBulletPoints as TextRecord[]
      ),
      readMoreText: objectPage?.certifiedReadMoreText,
      readMoreUrl: objectPage?.certifiedReadMoreUrl,
      whatIsCertified: objectPage?.certifiedWhatIsCertified
    },
    test: {
      showContent: objectPage?.testShowContent ?? '',
      hideContent: objectPage?.testHideContent ?? '',
      wearAndTear: objectPage?.testWearAndTear
    },
    releasing: {
      description: objectPage?.releasingDescription,
      bulletPoints: getBulletPoints(
        objectPage?.releasingBulletPoints as TextRecord[]
      )
    },
    kvdHelperUrl: objectPage?._editingUrl
  };
};

export const handleObjectServicesData = ({
  objectPage
}: Partial<ObjectServicesQuery>) => {
  return {
    services: {
      ...handleServices(objectPage?.services as ObjectServiceArray)
    }
  };
};

export const handleFixedPriceReservationTipsData = ({
  fixedPriceTipsModel
}: Partial<FixedPriceReservationTipsQuery>) => {
  return {
    header: fixedPriceTipsModel?.header,
    tips: fixedPriceTipsModel?.fixedPriceTipsList,
    tipsString: fixedPriceTipsModel?.fixedPriceTipsListTemp
  };
};
