// This is needed for the provideDataHook, we don't know exactly what kind of data it wants to compare.
export function areDeepEquals<T>(a: T, b: T): boolean {
  if (a === b) return true;

  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
      if (!areDeepEquals(a[i], b[i])) return false;
    }
    return true;
  }

  if (
    typeof a === 'object' &&
    typeof b === 'object' &&
    a !== null &&
    b !== null
  ) {
    const keysA = Object.keys(a) as (keyof typeof a)[];
    const keysB = Object.keys(b) as (keyof typeof b)[];
    if (keysA.length !== keysB.length) return false;
    for (const key of keysA) {
      if (!areDeepEquals(a[key], b[key])) return false;
    }
    return true;
  }

  return false;
}

export function isEmpty<T>(value: T) {
  if (typeof value === 'number' || typeof value === 'boolean') return false;
  if (typeof value === 'string' || Array.isArray(value))
    return value.length === 0;

  if (!value) return false;

  return Object.keys(value).length === 0;
}
