import {
  convertFilterSearchParamsToSearchQuery,
  createSearchName
} from '~/helpers/searches';
import { SavingSearch } from '../interfaces/SavedSearch';
import { ReduxDispatch } from '../interfaces/Redux';
import { TranslateFunction } from '~/Locale';

export const SAVE_RECENT_SEARCH = 'SAVE_RECENT_SEARCH';
export type SaveRecentSearchAction = {
  type: typeof SAVE_RECENT_SEARCH;
  payload?: SavingSearch;
};
export const saveRecentSearch = (
  payload?: SavingSearch
): SaveRecentSearchAction => ({
  type: SAVE_RECENT_SEARCH,
  payload
});

export const RECENTLY_VIEWED_SEARCHES_HYDRATE =
  'RECENTLY_VIEWED_SEARCHES_HYDRATE';
export type RecentlyViewedSearchesHydrateAction = {
  type: typeof RECENTLY_VIEWED_SEARCHES_HYDRATE;
  payload: SavingSearch[];
};
export const recentlyViewedSearchesHydrate = (
  payload: SavingSearch[]
): RecentlyViewedSearchesHydrateAction => ({
  type: 'RECENTLY_VIEWED_SEARCHES_HYDRATE',
  payload
});

export const saveSearchAsRecent =
  (t: TranslateFunction, terms: string, filterSearchParams: URLSearchParams) =>
  (dispatch: ReduxDispatch): SaveRecentSearchAction => {
    const savedSearchQuery =
      convertFilterSearchParamsToSearchQuery(filterSearchParams);

    const filters = { terms, ...savedSearchQuery };
    const name = createSearchName(t, filters);

    return dispatch(saveRecentSearch({ filters, name }));
  };

export const CLEAR_RECENT_SEARCHES = 'CLEAR_RECENT_SEARCHES';
export type ClearRecentSearchesAction = {
  type: typeof CLEAR_RECENT_SEARCHES;
};
export const clearRecentSearches = (): ClearRecentSearchesAction => ({
  type: CLEAR_RECENT_SEARCHES
});

export type RecentlyViewedAction =
  | SaveRecentSearchAction
  | RecentlyViewedSearchesHydrateAction
  | ClearRecentSearchesAction;
