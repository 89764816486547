import React, { MouseEvent, ReactNode } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import styled from 'styled-components';
import { resetButtonStyle } from '@kvdbil/components';
import { translateRoute } from '~/App/shared/localization';
import { isUrlAbsolute } from '~/helpers/url';
import { useTranslation } from '~/Locale';
import {
  domainFromUrl,
  isHostDomain,
  isKvdProdSubdomain,
  isTrustedDomain,
  validURL
} from '~/helpers/router';
import { useSelector } from 'react-redux';
import { localizationSelector } from '~/App/shared/selectors/localization';

const LinkButton = styled.button`
  ${resetButtonStyle};
  color: ${({ theme }) => theme.colors.info.main};
`;

export interface LinkProps {
  to?: string | null;
  children: ReactNode;
  isDisabled?: boolean;
  state?: Record<string, unknown>;
  className?: string;
  target?: '_blank';
  rel?: string;
  onClick?(event: MouseEvent): void;
  customAttrs?: { attrName: string; attrValue: string }[];
  noTranslation?: boolean;
}

export default function Link({
  state,
  to = '',
  children,
  isDisabled,
  customAttrs,
  noTranslation = false,
  ...props
}: LinkProps) {
  const { t } = useTranslation();
  const { domainLocale } = useSelector(localizationSelector);

  if (!to) {
    return <LinkButton {...props}>{children}</LinkButton>;
  }

  const customAttrProps: Record<string, string> = {};
  customAttrs?.forEach(({ attrName, attrValue }) => {
    if (attrName in customAttrProps) {
      customAttrProps[attrName] = `${customAttrProps[attrName]} ${attrValue}`;
    } else {
      customAttrProps[attrName] = attrValue;
    }
  });

  if (isUrlAbsolute(to)) {
    const isValidUrl = validURL(to);
    const { rootDomain, subdomain } = domainFromUrl(to);

    if (
      isValidUrl &&
      domainLocale &&
      isHostDomain(rootDomain, domainLocale) &&
      !isKvdProdSubdomain(subdomain)
    ) {
      // In cms app sometimes url are defined as absolute instead of pathname.
      // To support a multilingual website, we must have a pathname+search.
      const parsedUrl = new URL(to);
      to = parsedUrl.pathname + parsedUrl.search;
    } else {
      const relNofollow =
        isValidUrl && !isTrustedDomain(rootDomain) ? 'nofollow' : undefined;
      if (!('rel' in customAttrProps) && relNofollow) {
        customAttrProps['rel'] = relNofollow;
      }

      return (
        <a href={to} {...props} {...customAttrProps}>
          {children}
        </a>
      );
    }
  }

  const preventDefault = () => (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
  };

  if (state) {
    return (
      <ReactLink
        data-testid="react-link-state"
        to={{ pathname: noTranslation ? to : translateRoute(t, to), state }}
        onClick={isDisabled ? preventDefault() : undefined}
        {...props}
        {...customAttrProps}
      >
        {children}
      </ReactLink>
    );
  }

  return (
    <ReactLink
      data-testid="react-link"
      to={noTranslation ? to : translateRoute(t, to)}
      onClick={isDisabled ? preventDefault() : undefined}
      {...props}
      {...customAttrProps}
    >
      {children}
    </ReactLink>
  );
}
