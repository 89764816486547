import { ReduxStore } from '~/App/shared/interfaces/store';
import { FeatureFlags } from '~/App/shared/interfaces/FeatureFlags';

const SET_FEATURE_FLAG_TYPE = 'SET_FEATURE_FLAG' as const;
export type SetFeatureFlagPayload = Partial<FeatureFlags>;
export const setFeatureFlagAction = (payload: SetFeatureFlagPayload) => ({
  type: SET_FEATURE_FLAG_TYPE,
  payload
});

const featureFlagReducer = (
  state = {} as ReduxStore['featureFlags'],
  action: ReturnType<typeof setFeatureFlagAction>
) => {
  switch (action.type) {
    case SET_FEATURE_FLAG_TYPE:
      return {
        ...state,
        flags: {
          ...state.flags,
          ...action.payload
        }
      };

    default:
      return state;
  }
};

export default featureFlagReducer;
