import React from 'react';
import { Path } from 'react-hook-form';
import { getCountries } from '~/helpers/countries';
import SelectWithControl from '~/App/shared/components/Fields/SelectWithControl';
import { SelectProps } from '../types';

interface CountryFieldProps<FormState> extends SelectProps<FormState> {
  disableSweden?: boolean;
}

const CountryField = <FormState,>({
  control,
  t,
  name,
  rules,
  disableSweden,
  ...props
}: CountryFieldProps<FormState>) => {
  const validationRules = {
    required: {
      value: true,
      message: t(`Country can't be blank`)
    },
    validate: (value: { label: string; value: string }) => {
      return value.value !== '' || t(`Country can't be blank`);
    },
    ...rules
  };

  const fieldName = name ?? ('country' as Path<FormState>);

  return (
    <SelectWithControl
      {...props}
      control={control}
      name={fieldName}
      label={t('Country')}
      options={getCountries(t)}
      rules={validationRules}
      isOptionDisabled={option =>
        Boolean(disableSweden) && option.value === 'se'
      }
    />
  );
};

export default CountryField;
