import React, { useState, useEffect, RefObject } from 'react';
import qs from 'qs';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

type ObjectPositionX = number | 'center' | 'left' | 'right';
type ObjectPositionY = number | 'center' | 'top' | 'bottom';

type ImageParams = {
  w?: number;
  h?: number;
  fit?: 'fill';

  x?: ObjectPositionX;
  y?: ObjectPositionY;
};

const Figure = styled.figure`
  position: relative;
  margin: 0;
  min-height: 1px;

  .media__size {
    content: ' ';
    display: block;
    position: relative;
    height: 0;
    width: 100%;
  }

  .media__image {
    position: absolute;
    top: -2px;
    left: -2px;
    z-index: 1;
    display: block;
    margin: 0 auto;
    width: calc(100% + 4px);
    height: auto;
  }
`;

const mediaSizes = {
  THUMBNAIL: {
    tiny: 490,
    small: 400,
    medium: 320,
    big: 250,
    large: 250,
    huge: 250,
    enormous: 250
  },
  PRESENTATION: {
    tiny: 320,
    small: 490,
    medium: 710,
    big: 1024,
    large: 1200,
    huge: 1560,
    enormous: 1920
  },
  // Basically THUMBNAIL, but a little bigger, as we only have at most 3 columns
  CARGUIDE: {
    tiny: 725,
    small: 725,
    medium: 725,
    big: 340,
    large: 340,
    huge: 340,
    enormous: 340
  },
  /* Currently used in the fullscreenGallery */
  HIGH_QUALITY: {
    tiny: 1170,
    small: 1170,
    medium: 1170,
    big: 1920,
    large: 1920,
    huge: 1920,
    enormous: 1920
  }
};

type MediaSize = keyof (typeof mediaSizes)['THUMBNAIL'];
type MediaImageType = keyof typeof mediaSizes;
interface SizeParams {
  q?: number;
  w?: number;
  auto?: string;
}

interface GetSizedUrlProps {
  imageType: MediaImageType;
  size: MediaSize;
  quality?: number;
  imageParams?: ImageParams;
}

const getSizedUrl = (baseUrl = '', options: GetSizedUrlProps) => {
  if (typeof baseUrl !== 'string') {
    return '';
  }

  if (
    baseUrl &&
    !baseUrl.includes('imgix') &&
    !baseUrl.includes('ctfassets') &&
    !baseUrl.includes('datocms')
  ) {
    return baseUrl;
  }

  const { quality, imageType, size } = options;

  const params: SizeParams = {
    q: quality,
    w: mediaSizes[imageType][size]
  };

  if (!quality) {
    delete params.q;
  }

  if (baseUrl.includes('imgix')) {
    params['auto'] = 'format';
  }

  const paramString = qs.stringify({ ...params, ...options.imageParams });

  return `${baseUrl}?${paramString}`;
};

interface MediaProps {
  src: string;
  alt?: string;
  ratio?: number;
  width?: number;
  height?: number;
  quality?: number;
  onLoad?(): void;
  onClick?(): void;
  isInView?: boolean;
  imageType?: MediaImageType;
  forwardRef?: RefObject<HTMLElement>;
  alwaysLoad?: boolean;
  dataTestId?: string;
  imageParams?: ImageParams;
}

const Media = (props: MediaProps) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '0px 0px 10% 0px',
    threshold: 0,
    initialInView: props.isInView
  });

  /* The type defined in useInView is wrong.. */
  const _ref = ref as unknown as RefObject<HTMLElement>;

  return <MediaInner {...props} forwardRef={_ref} isInView={inView} />;
};

export const MediaInner = ({
  src,
  alt,
  ratio,
  width,
  height,
  onLoad,
  isInView,
  quality,
  imageType = 'PRESENTATION',
  forwardRef,
  alwaysLoad,
  onClick,
  dataTestId,
  imageParams,
  ...props
}: MediaProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (width && onLoad && !isLoaded) {
      onLoad();
      setIsLoaded(true);
    }
  }, [isLoaded, onLoad, width]);

  let paddingTop = 0;
  let mergeProps = { ...props };

  if (width && height) {
    paddingTop = 100 / (width / height);

    mergeProps = {
      width,
      height
    };
  }

  if (ratio) {
    paddingTop = 100 / ratio;
  }

  const size = {
    paddingTop: `${paddingTop}%`
  };

  const getSizedUrlFactory = (size: MediaSize) => {
    return getSizedUrl(src, {
      imageType,
      size,
      quality,
      imageParams
    });
  };

  return (
    <Figure ref={forwardRef} data-testid={dataTestId}>
      {isInView || alwaysLoad || isLoaded ? (
        <picture>
          <source
            data-testid="image-source"
            srcSet={getSizedUrlFactory('enormous')}
            media="(min-width: 1920px)"
          />
          <source
            data-testid="image-source"
            srcSet={getSizedUrlFactory('huge')}
            media="(min-width: 1560px)"
          />
          <source
            data-testid="image-source"
            srcSet={getSizedUrlFactory('large')}
            media="(min-width: 1200px)"
          />
          <source
            data-testid="image-source"
            srcSet={getSizedUrlFactory('big')}
            media="(min-width: 1024px)"
          />
          <source
            data-testid="image-source"
            srcSet={getSizedUrlFactory('medium')}
            media="(min-width: 545px)"
          />
          <source
            data-testid="image-source"
            srcSet={getSizedUrlFactory('small')}
            media="(min-width: 320px)"
          />
          <source
            data-testid="image-source"
            srcSet={getSizedUrlFactory('tiny')}
            media="(max-width: 320px)"
          />

          <img
            className="media__image"
            data-testid="full-image"
            src={src}
            alt={alt}
            onLoad={onLoad}
            {...mergeProps}
          />
        </picture>
      ) : null}

      <div className="media__size" style={size} />
    </Figure>
  );
};

export default Media;
