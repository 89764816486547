import { AxiosResponse } from 'axios';
import qs from 'qs';
import { ReservationPriceObj } from '~/App/shared/actions/sellFlowAsyncActions';
import {
  BusinessProposal,
  BusinessProposalResponseForId,
  Interval,
  LeadObjectInformation,
  SalesAgreement,
  SalesAgreementProvider,
  SigningInstanceStatus,
  VehicleObject
} from '~/App/shared/interfaces/store/SellFlow';
import { TimePickerSlot } from '~/App/shared/types/TimePickerSlot';
import { TimeSlot } from '~/App/shared/types/TimeSlot';
import { Nullable } from '~/App/shared/types/Nullable';
import { orchestration } from '~/config/public';
import axios from '~/helpers/orchestration/axiosWithAuthHandling';
import { ContactFormState } from '~/App/views/DynamicPage/components/forms/CorporateCarsContactForm';
import {
  BusinessProposalInput,
  InquiryInput
} from '~/App/shared/interfaces/Sale';
import { BookedAppointment } from '~/App/views/SellCar/views/Confirmation/PrivateAgreementSigned';
import BaseObject from '~/App/shared/interfaces/BaseObject';

type Params = Record<string, unknown>;
const getLeadObject = ({ params }: { params: Params }) =>
  axios
    .get<{ leadObjectInformation: LeadObjectInformation }>(
      `${orchestration.url}/sales/lead-object-information`,
      {
        params: params || {},
        paramsSerializer: (params: Params) =>
          qs.stringify(params, { arrayFormat: 'repeat' }),
        responseType: 'json'
      }
    )
    .then(({ data }) => data.leadObjectInformation);

export interface BusinessProposalCreateResponse {
  businessProposal: {
    baseObjectId: string;
    baseObject?: BaseObject;
    clientHasMemberAccount: boolean;
    clientMemberEmail: string;
    clientMemberId: string;
    isTradeIn: boolean;
    automaticValuationSuccessful?: boolean; // for not trade in
    requiresManualValuation?: boolean; // for not trade in
    reservationPriceInterval?: Interval; // for not trade in
  };
  token?: string; // for not trade in
}

const createBusinessProposal = ({
  businessProposal
}: {
  businessProposal: BusinessProposalInput;
}): Promise<BusinessProposalCreateResponse> =>
  axios
    .post<BusinessProposalCreateResponse>(
      `${orchestration.url}/sales/business-proposals`,
      {
        businessProposal: businessProposal
      },
      { responseType: 'json', timeout: 60000 }
    )
    .then(({ data }) => data);

const createInquiry = ({
  noticeOfInterest
}: {
  noticeOfInterest: InquiryInput;
}) =>
  axios
    .post<VehicleObject>(
      `${orchestration.url}/sales/notice-of-interest`,
      {
        noticeOfInterest: noticeOfInterest
      },
      {
        withCredentials: true,
        responseType: 'json'
      }
    )
    .then(({ data }) => data);

const getProposalJwt = ({ id, idToken }: { id: string; idToken: string }) =>
  axios
    .get<{ businessProposal: BusinessProposal }>(
      `${orchestration.url}/sales/business-proposals/${id}`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`
        },
        withCredentials: true,
        responseType: 'json'
      }
    )
    .then(({ data }) => data.businessProposal);

const getProposal = ({
  id,
  token
}: {
  id: string;
  token: string;
}): Promise<BusinessProposalResponseForId> =>
  axios
    .get<{ businessProposal: BusinessProposalResponseForId }>(
      `${orchestration.url}/sales/business-proposals/${id}`,
      {
        params: {
          token: token
        },
        responseType: 'json'
      }
    )
    .then(({ data }) => data.businessProposal);

interface SelectReservationPriceResponse {
  reservationPrice: { reservationPrice: number };
}
const selectReservationPrice = ({
  id,
  reservationPrice
}: ReservationPriceObj): Promise<
  AxiosResponse<SelectReservationPriceResponse>
> =>
  axios.post(
    `${orchestration.url}/sales/business-proposals/${id}/reservation-price`,
    {
      reservationPrice: reservationPrice
    },
    {
      responseType: 'json'
    }
  );

export interface SalesAgreementCreateResponse {
  bankIdAutoStartToken: Nullable<string>;
  id: string;
  status: SigningInstanceStatus;
  documentSigningUrl: string;
  signedAt: Nullable<string>;
  createdAt: string;
  updatedAt: Nullable<string>;
  provider: SalesAgreementProvider;
}

export const getSigninStatus = (signingId: string) =>
  axios
    .get<{
      signingInstance: { status: SigningInstanceStatus };
    }>(`${orchestration.url}/sales/sales-agreements/${signingId}/status`, {
      responseType: 'json'
    })
    .then(({ data }) => data?.signingInstance ?? {});

export const getBankIdAutoStartToken = ({
  id,
  token
}: {
  id: string;
  token: string;
}): Promise<SalesAgreementCreateResponse> =>
  axios
    .post<SalesAgreementCreateResponse>(
      `${orchestration.url}/sales/sales-agreements/${id}/request-bank-id-token`,
      {},
      {
        params: {
          token: token
        },
        responseType: 'json'
      }
    )
    .then(({ data }) => data);

const salesAgreementCreate = (
  salesAgreement: Partial<SalesAgreement>
): Promise<SalesAgreementCreateResponse> =>
  axios
    .post<{ salesAgreement: SalesAgreementCreateResponse }>(
      `${orchestration.url}/sales/sales-agreements`,
      { salesAgreement },
      {
        responseType: 'json'
      }
    )
    .then(({ data }) => data.salesAgreement);

export interface SalesAgreementFinalizeResponse {
  processObjectId: Nullable<string>;
  bookedAppointment: Nullable<BookedAppointment>;
  contactIsOwner: boolean;
}

const salesAgreementFinalize = ({
  signingId
}: {
  signingId: string;
}): Promise<SalesAgreementFinalizeResponse> =>
  axios
    .get<SalesAgreementFinalizeResponse>(
      `${orchestration.url}/sales/sales-agreements/${signingId}/get_signed_info`,
      {
        responseType: 'json'
      }
    )
    .then(({ data }) => data);

type GetSubmissionTimesProps = {
  params?: {
    [key: string]: string;
  };
  facilityId: string;
};

const getSubmissionTimes = ({ params, facilityId }: GetSubmissionTimesProps) =>
  axios
    .get<TimePickerSlot[]>(
      `${orchestration.url}/sales/submission-times/${facilityId}`,
      {
        params: params || {},
        responseType: 'json'
      }
    )
    .then(({ data }) => data);

type SelectSubmissionTimeParams = {
  token: string;
  facilityId: string;
  calendarId: string;
  processObjectId: string;
  timeslot: TimeSlot;
};
const selectSubmissionTime = ({
  timeslot,
  calendarId,
  facilityId,
  processObjectId,
  token
}: SelectSubmissionTimeParams) =>
  axios
    .post<{ timeslot: VehicleObject }>(
      `${orchestration.url}/sales/submission-times/${facilityId}`,
      {
        timeslot: {
          ...timeslot,
          calendarId,
          processObjectId
        },
        token: token
      },
      { responseType: 'json' }
    )
    .then(({ data }) => data.timeslot);

export interface CampaignCodeResponse {
  campaign?: {
    status: 'ACTIVE' | 'INACTIVE';
    swedishTitle: string;
    englishTitle: string;
    swedishDescription: string;
    englishDescription: string;
    corporateCampaign?: boolean;
  };
}

const getCampaignByCode = async (
  campaignCode: string
): Promise<AxiosResponse<CampaignCodeResponse>> =>
  axios.get(
    `${orchestration.url}/sales/campaign-codes/${campaignCode}/campaign`,
    {
      responseType: 'json'
    }
  );

export interface CorporateContactFormResponse {
  extraInformation: {
    email: string;
    name: string;
    organisationName: string;
    organisationNumber: string;
    otherInformation: string;
    phoneNumber: string;
  };

  message: string;
  role: string;
  title: string;
}

const submitCorporateContactForm = (corporateContactForm: ContactFormState) =>
  axios.post<CorporateContactFormResponse>(
    `${orchestration.url}/sales/corporate-contact-form`,
    {
      corporateContactForm
    },
    {
      responseType: 'json'
    }
  );

export {
  getLeadObject,
  createBusinessProposal,
  createInquiry,
  getProposalJwt,
  getProposal,
  selectReservationPrice,
  salesAgreementCreate,
  salesAgreementFinalize,
  getSubmissionTimes,
  selectSubmissionTime,
  getCampaignByCode,
  submitCorporateContactForm
};
