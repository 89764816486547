import { BodyLink, BodyText, Chip } from '@kvdbil/components';
import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import styled from 'styled-components';
import Link from '~/App/shared/components/Link';
import { ArticleRecord, FileField } from '~/config/generated/graphql';
import { DeepPartial } from '@test/mocks';
import { blogDateFormat } from '../helpers';
import { useTranslation } from '~/Locale';

const ImageWrapper = styled.div<{
  image: DeepPartial<Maybe<FileField>>;
}>`
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  margin-bottom: 1rem;

  ${({ image, theme }) =>
    image?.url || image?.responsiveImage?.src
      ? `background: url(${image?.url || image?.responsiveImage?.src});
        background-repeat: no-repeat;
        // background-size: 100% auto;
        ${
          image?.focalPoint
            ? `background-position: ${Number(image?.focalPoint?.x) * 100}% ${
                Number(image?.focalPoint?.y) * 100
              }%;`
            : 'background-position: center;'
        }`
      : `background: ${theme.colors.secondary.main};`}
`;

const StyledChip = styled(Chip)`
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  cursor: pointer;
`;

const ArticleText = styled.div`
  font-weight: ${({ theme }) => theme.typography.fontBaseBoldWeight};
`;

const ArticleTitle = styled(BodyLink)`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

interface Props {
  article: DeepPartial<ArticleRecord>;
}

const ArticleCard = ({ article }: Props) => {
  const { title, mainCategory, _firstPublishedAt, slug, miniatureImage } =
    article;
  const { t } = useTranslation();

  return (
    <div data-testid="blog-article">
      <Link to={`/ROUTES.BLOG/${mainCategory?.slug}/${slug}`}>
        <ImageWrapper image={miniatureImage} data-testid="image wrapper">
          <StyledChip color="neutralLight" size="small">
            {mainCategory?.name}
          </StyledChip>
        </ImageWrapper>
      </Link>
      <ArticleText>
        <ArticleTitle>{title}</ArticleTitle>
        <BodyText>
          {blogDateFormat(_firstPublishedAt as unknown as string, t)}
        </BodyText>
      </ArticleText>
    </div>
  );
};

export default ArticleCard;
