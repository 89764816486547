import React from 'react';
import TrustPilot from './TrustPilot';
import styled from 'styled-components';
import { Flex, mq, theme } from '@kvdbil/components';

const TrustPilotContainer = styled(Flex)<{ isFilterPage: boolean }>`
  padding: 0.75rem 3rem;
  background-color: ${theme.colors.background.light};

  ${mq(null, 'tablet')} {
    background-color: ${({ isFilterPage, theme }) =>
      isFilterPage ? theme.colors.gray.light6 : theme.colors.background.light};
  }
`;

type Props = { isFilterPage?: boolean };

function TrustPilotTop({ isFilterPage = false }: Props) {
  return (
    <TrustPilotContainer
      data-testid="smallTrustPilot"
      isFilterPage={isFilterPage}
    >
      <TrustPilot type="Horizontal" />
    </TrustPilotContainer>
  );
}

export default TrustPilotTop;
