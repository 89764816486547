import React, { KeyboardEvent, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Flex, mq, Stack, Status } from '@kvdbil/components';
import { AuctionSearchQuery } from '~/App/shared/interfaces/AuctionSearchQuery';
import {
  capitalizeStr,
  getOrderAlternatives
} from '~/helpers/filterTranslation';
import { useKeypressEffect } from '~/App/shared/hooks/keyPressHook';
import { AuctionType } from '~/App/shared/interfaces/Auction';
import { useTranslation } from '~/Locale';
import {
  auctionTypeIsFixedPrice,
  auctionTypeIsBidding
} from '~/helpers/buyCar';
import { useIsCarguideFinished } from '~/App/views/Carguide/hooks';
import { FilterSearchParams } from '../../types/FilterSearchParam';
import { MainCategory } from '../../types/MainCategoryTypes';
import { Nullable } from '../../types/Nullable';

const Container = styled.div<{ hasResults: boolean }>`
  width: 100%; // keeps results list responsive to relative parent
  ${mq('tablet')} {
    background-color: ${({ theme }) => theme.colors.background.light};
    z-index: 15;
  }
`;

const GrayStatus = styled(Status)`
  color: ${({ theme }) => theme.colors.gray.light1};
`;

const ListItem = styled.div`
  cursor: pointer;
  outline: none;
  padding: 0.5rem 1.5rem;
  border-radius: 30px;

  color: ${({ theme }) => theme.colors.text.dark};
  background-color: ${({ theme }) => theme.colors.background.gray};

  :active,
  :hover,
  :focus {
    background-color: ${({ theme }) => theme.colors.gray.light5};
  }
`;

export interface AutoCompleteSearch {
  name?: string;
  filters?: Partial<AuctionSearchQuery>;
}

interface Props {
  localTerms: string;
  isAutoComplete: boolean;
  auctionType?: AuctionType;
  clearRecentSearches?(): void;
  searchAuctions(
    searchParams: Partial<FilterSearchParams>,
    mainCategory: Nullable<MainCategory>,
    orderBy: string
  ): void;
  searchOptions: AutoCompleteSearch[];
  handleClose(): void;
  mainCategory?: MainCategory | '';
}

const onEnter =
  (callback: CallableFunction) => (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      callback();
    }
  };

const highlightMatchingParts = (str: string, match: string) => {
  const loweredStr = str.toLowerCase();
  const loweredMatch = match.toLowerCase();

  const parts = loweredStr.split(loweredMatch);

  if (loweredStr.startsWith(loweredMatch)) {
    return (
      <>
        <b>{capitalizeStr(loweredMatch)}</b>
        {loweredStr.replace(loweredMatch, '')}
      </>
    );
  }

  return (
    <>
      {capitalizeStr(parts[0])}
      <b>{parts.length > 1 && match}</b>
      {parts[1]}
    </>
  );
};

const ResultList = ({
  handleClose,
  searchAuctions,
  searchOptions = [],
  localTerms,
  isAutoComplete,
  clearRecentSearches,
  auctionType,
  mainCategory = ''
}: Props) => {
  const { t } = useTranslation();

  const [activeIndex, setActiveIndex] = useState(-1);
  const hasResults = searchOptions.length > 0;
  const limitedSearchResults = hasResults && searchOptions.slice(0, 10);

  const listItems = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    setActiveIndex(-1);
  }, [localTerms]);

  const handlePrev = () => {
    const newIndex =
      activeIndex <= 0 ? searchOptions.length - 1 : activeIndex - 1;

    setActiveIndex(newIndex);
    listItems.current?.[newIndex]?.focus();
  };

  const handleNext = () => {
    const newIndex = (activeIndex + 1) % searchOptions.length;
    setActiveIndex(newIndex);

    listItems.current?.[newIndex]?.focus();
  };

  const { isCarguideFinished } = useIsCarguideFinished();

  const getOrderBy = (storedOrderBy: string) => {
    const orderAlternatives = getOrderAlternatives(
      t,
      isCarguideFinished,
      auctionTypeIsFixedPrice(auctionType),
      auctionTypeIsBidding(auctionType)
    ).map(order => order.value);
    return orderAlternatives.includes(storedOrderBy) ? storedOrderBy : '-grade';
  };

  useKeypressEffect('ArrowUp', handlePrev, {
    preventDefault: true
  });
  useKeypressEffect('ArrowDown', handleNext, {
    preventDefault: true
  });
  useKeypressEffect('Tab', handlePrev, {
    preventDefault: true,
    onShift: true
  });
  useKeypressEffect('Tab', handleNext, {
    preventDefault: true
  });
  useKeypressEffect('Escape', handleClose, {
    preventDefault: true
  });

  return (
    <Container hasResults={hasResults} data-testid="search-results">
      {!isAutoComplete && hasResults && (
        <Flex justify={'space-between'} style={{ marginBottom: '1rem' }}>
          <GrayStatus>{t('Previous searches')}</GrayStatus>
          <Status onClick={clearRecentSearches} style={{ cursor: 'pointer' }}>
            {t('Clear')}
          </Status>
        </Flex>
      )}

      {limitedSearchResults && (
        <Stack spacing={0.5} data-testid="search-results-list">
          {limitedSearchResults.map((search: AutoCompleteSearch, index) => {
            const searchWithCurrentParams = () => {
              const orderBy = getOrderBy(search.filters?.orderBy ?? '');

              search.filters &&
                searchAuctions(
                  search.filters as Partial<FilterSearchParams>,
                  (search.filters?.vehicleType as MainCategory) ?? mainCategory,
                  orderBy
                );
            };

            return (
              <ListItem
                ref={node => {
                  if (node) {
                    listItems.current[index] = node;
                  }
                }}
                tabIndex={0}
                onKeyDown={onEnter(searchWithCurrentParams)}
                key={search?.name ?? index}
                onClick={searchWithCurrentParams}
              >
                {isAutoComplete
                  ? highlightMatchingParts(
                      search?.filters?.terms ?? '',
                      localTerms
                    )
                  : search?.name}
              </ListItem>
            );
          })}
        </Stack>
      )}
    </Container>
  );
};

export default ResultList;
