import React from 'react';
import { Path } from 'react-hook-form';
import { PASSWORD_MIN_LENGTH } from '~/App/shared/validation/constants';
import PasswordFieldWithControl from '~/App/shared/components/Fields/PasswordFieldWithControl';
import { TextFieldProps } from '../types';

const PasswordField = <FormState,>({
  control,
  t,
  name,
  rules,
  ...props
}: TextFieldProps<FormState>) => {
  const validationRules = {
    required: {
      value: true,
      message: t(`Current password can't be blank`)
    },
    minLength: {
      value: PASSWORD_MIN_LENGTH,
      message: `${t(
        'Password is too short (minimum is %s characters)',
        PASSWORD_MIN_LENGTH
      )}`
    },
    ...rules
  };

  const fieldName = name ?? ('password' as Path<FormState>);

  return (
    <PasswordFieldWithControl
      {...props}
      control={control}
      name={fieldName}
      label={t('Password')}
      rules={validationRules}
    />
  );
};

export default PasswordField;
