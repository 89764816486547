import { PlayIcon } from '@kvdbil/components';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  padding-bottom: 62.5%;
  background-color: black;
  cursor: pointer;

  &:hover {
    & svg {
      opacity: 0.7;
    }
  }
`;

const StyledImage = styled.img`
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  opacity: 70%;
`;

const StyledIcon = styled(PlayIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.background.light};
  height: 10rem;
  width: 10rem;

  & path {
    stroke-width: 1.2px;
  }
`;

type Props = {
  thumbnailSrc: string;
  altText: string;
};

function YouTubeBigThumbnail({ thumbnailSrc, altText }: Props) {
  return (
    <Container>
      <StyledImage src={thumbnailSrc} alt={altText} />
      <StyledIcon />
    </Container>
  );
}

export default YouTubeBigThumbnail;
