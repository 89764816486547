import { useEffect } from 'react';
import { init, cleanUp } from './firebase';
import { isClient } from '~/config/public/environment';
import { useFeatureFlagValue } from '../components/FeatureFlags';

export const useInitFirebase = () => {
  const isPushNotification = useFeatureFlagValue('push-notification');

  useEffect(() => {
    if (isClient) {
      if (isPushNotification) {
        void init();
      } else {
        void cleanUp();
      }
    }
  }, [isPushNotification]);

  return null;
};
