import { combineReducers } from 'redux';
import { SellFlowAction } from '../actions/sellflowActions';
import {
  ClientMemberActions,
  SetClientEmailRegistrationAction,
  VehicleObjectAction,
  VehicleObjectHydrateAction
} from '../actions/store-vehicle-object';
import { GetValuationSuccessAction } from '../actions/valuationActions';
import { ReduxStore } from '../interfaces/store';
import { SellFlow, VehicleObject } from '../interfaces/store/SellFlow';

type SellflowRoot = ReduxStore['sellFlow'];
const vehicleObject = (
  state: VehicleObject = {},
  action: VehicleObjectAction | SellFlowAction | GetValuationSuccessAction
) => {
  switch (action.type) {
    case 'VEHICLE_OBJECT_CHANGED':
      return action.vehicleObject ?? state;
    case 'GET_VALUATION_SUCCESS':
    case 'SELL_FLOW_VEHICLE_INFO_SUCCESS':
      return action.payload;
    case 'SELLFLOW_BUSINESS_PROPOSAL_SUCCESS':
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        ...action.payload?.businessProposal,
        ...action.payload?.businessProposal?.baseObject
      };
    case 'VEHICLE_OBJECT_HYDRATE':
      return { ...state, ...action.vehicleObject };

    default:
      return state;
  }
};

const hydrated = (
  state = false,
  action: Pick<VehicleObjectHydrateAction, 'type'>
) => {
  switch (action.type) {
    case 'VEHICLE_OBJECT_HYDRATE':
      return true;
    default:
      return state;
  }
};

interface ClientMemberEmailAction {
  type: ClientMemberActions['type'];
  clientMemberEmail?: string;
}

const clientMemberEmail = (
  state = '',
  action: ClientMemberEmailAction
): SellflowRoot['clientMemberEmail'] => {
  switch (action.type) {
    case 'CLIENT_HYDRATE':
      return action?.clientMemberEmail ?? state;
    case 'CLIENT_REGISTERED':
      return action?.clientMemberEmail ?? state;
    default:
      return state;
  }
};

interface EmailForAccountRegistrationAction {
  type: SetClientEmailRegistrationAction['type'];
  email?: string;
  accountType?: string;
}

const emailForAccountRegistration = (
  state = { email: '', accountType: '' },
  action: EmailForAccountRegistrationAction
): SellflowRoot['emailForAccountRegistration'] => {
  switch (action.type) {
    case 'SET_CLIENT_EMAIL_FOR_ACCOUNT_REGISTRATION':
      const { email = '', accountType = '' } = action;
      return { email, accountType };
    default:
      return state;
  }
};

interface ClientHasMemberAccountAction {
  type: ClientMemberActions['type'];
  clientHasMemberAccount?: ClientMemberActions['clientHasMemberAccount'];
}

const clientHasMemberAccount = (
  state = false,
  action: ClientHasMemberAccountAction
): SellflowRoot['clientHasMemberAccount'] => {
  switch (action.type) {
    case 'CLIENT_HYDRATE':
      return action.clientHasMemberAccount ?? state;
    case 'CLIENT_REGISTERED':
      return action.clientHasMemberAccount ?? state;
    default:
      return state;
  }
};

export const getSellFlow = (state: SellFlow) => state.vehicleObject;

const sellFlowReducer = combineReducers<ReduxStore['sellFlow']>({
  vehicleObject,
  hydrated,
  clientMemberEmail,
  clientHasMemberAccount,
  emailForAccountRegistration
});

export default sellFlowReducer;
