import { AxiosError } from 'axios';
import { Path } from 'react-hook-form';
import dot from 'dot-object';
import { ErrorResponse, isAxiosError } from './axiosUtils';
import { TranslateFunction } from '~/Locale';

type MappedError<Name> = {
  type: string;
  name: Name;
  message: string;
};

export const getAllErrorMesseges = (
  error: unknown,
  errors: string[]
): string[] => {
  if (!error) {
    return errors;
  }

  if (typeof error === 'string') {
    errors.push(error);
  } else if (Array.isArray(error)) {
    error.forEach(err => getAllErrorMesseges(err, errors));
  } else if (error && typeof error === 'object') {
    Object.keys(error).forEach(key =>
      getAllErrorMesseges((error as Record<string, unknown>)[key], errors)
    );
  }
  return errors;
};

export const mapRequestErrorToForm = <Form extends Record<string, unknown>>(
  error: AxiosError<ErrorResponse<Path<Form>>>
): MappedError<Path<Form>>[] => {
  const message = error.response?.data.message;

  if (!message) {
    return [];
  }

  dot.keepArray = true;
  const errors: Record<string, string> = dot.dot(message);

  return Object.entries(errors).map(([name, message]: [Path<Form>, string]) => {
    return {
      type: 'manual',
      name,
      message
    };
  });
};

type ErrorMessageValue = string | string[];

type ProcessableErrorResponse = {
  status: number;
  data?: {
    message: {
      [s: string]: ErrorMessageValue | { [s: string]: ErrorMessageValue };
    };
  };
};

export const getErrorCodes = (t: TranslateFunction) => ({
  503: t(
    'Oops, it seems to be a problem with the server. We are looking in to it as soon as we can'
  ),
  500: t(
    'Oops, it seems to be a problem with the server. We are looking in to it as soon as we can'
  ),
  404: t('Something went wrong, resource not found.'),
  403: t('Something went wrong, forbidden.'),
  400: t('Something went wrong, please try again later.')
});

export const processErrorResponse = (
  error: unknown | AxiosError<ProcessableErrorResponse>,
  t: TranslateFunction
): string => {
  if (!isAxiosError(error)) {
    return '';
  }

  if (error.message?.includes('Network Error')) {
    return t('Something went wrong, network error.');
  }

  if (!error.response) {
    return '';
  }

  const { response } = error;
  const { data, status } = response;

  const errors = getErrorCodes(t);
  const errorMessage = data?.message;

  if (status && ![200, 204].includes(status) && !errorMessage) {
    return (
      errors[status as keyof typeof errors] ??
      t('Something went wrong, please try again later.')
    );
  }

  if (!errorMessage) {
    return t('Something went wrong, please try again later.');
  }

  const errorMessages: string[] = [];
  getAllErrorMesseges(errorMessage, errorMessages);

  return errorMessages.join(', ');
};
