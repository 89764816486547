import { BookTimeslotAction } from '../../actions/checkoutTimeslotActions';
import { TimeSlot } from '../../types/TimeSlot';
import { GetOrderSuccessAction } from './checkoutReducer';

export const bookedTimeslotReducer = (
  state: TimeSlot | null = null,
  action: GetOrderSuccessAction | BookTimeslotAction
) => {
  switch (action.type) {
    case 'GET_ORDER_SUCCESS':
      return action?.payload?.timeslot ?? null;
    case 'BOOK_PICKUP_TIMESLOT_SUCCESS':
      return action?.payload ?? null;
    default:
      return state;
  }
};
