import React, { useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  Button,
  mq,
  theme,
  Logo,
  MenuIcon,
  TinyTitleRegular
} from '@kvdbil/components';
import { Locale } from '@kvdbil/components/types/Types';
import { environment } from '~/config/public';
import DrawerMenu from '~/App/components/Header/components/DrawerMenu';
import HeaderMessages from './components/HeaderMessages';
import { setContactButtonIsHidden } from '~/App/shared/actions/contactButton';
import { useHistory } from 'react-router';
import Link from '~/App/shared/components/Link';
import { useTranslation } from '~/Locale';
import ObjectSearchField from '~/App/shared/components/ObjectSearchField';
import {
  NavigationDocument,
  NavigationQuery,
  TextIconWithLinkRecord
} from '~/config/generated/graphql';
import { useCmsData } from '~/App/shared/hooks/useCmsData';
import { NavigationData } from '~/App/shared/interfaces/store/CmsData';
import UtilityBar from './components/UtilityBar';
import {
  closeMenuDrawer,
  openMenuDrawer
} from '~/App/shared/actions/drawerMenu';
import { selectIsDrawerOpen } from '~/App/shared/selectors/drawerMenuSelector';
import { Drawer } from '../Drawer';
import {
  headerHeightSelector,
  isHeaderHiddenSelector,
  isHeaderSlideOpenSelector
} from '~/App/shared/selectors/header';
import { setHeaderHeightAction } from '~/App/shared/actions/headerActions';
import useHeaderScrollControl from './components/useHeaderScrollControl';
import logger from '~/helpers/logger';

export const HEADER_MIN_HEIGHT = 97.83 + 40; // 40px is the height of the utility bar
export const HEADER_MIN_HEIGHT_MOBILE = 217.83;

interface HeaderWrapperProps {
  isHidden: boolean;
  isHeaderSlideOpen: boolean;
}

const HeaderWrapper = styled.div<HeaderWrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 101;
  height: auto;

  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};

  ${mq(null, 'tablet')} {
    transition: transform 0.3s ease-in-out;
    transform: ${({ isHeaderSlideOpen }) =>
      isHeaderSlideOpen ? 'translateY(0)' : 'translateY(-100%)'};
  }

  ${mq('tablet')} {
    ul {
      padding: 0.82rem 0 0.82rem;
    }
  }
`;

const HeaderContainer = styled.nav`
  display: block;
  width: 100%;
  z-index: 101;
  position: relative;
  color: ${theme.colors.text.dark};
  background: ${({ theme }) => theme.colors.primary.main};
  transition: 300ms;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem;
  max-width: 1440px;
  margin: 0 auto;
`;

const SearchFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;

  ${mq(null, 'tablet')} {
    flex-basis: 100%;
  }

  ${mq('tablet')} {
    padding: 0 2.5rem;
  }

  ${mq('laptop')} {
    padding: 0 5rem;
  }
`;

const MenuButton = styled(Button)`
  &,
  &:hover {
    border: 1px solid ${theme.colors.text.dark};
    color: ${theme.colors.text.dark};
  }

  ${mq('tablet')} {
    order: 3;
  }
`;

const DrawerMenuContainer = styled.div<{ isSub?: boolean }>`
  width: 100%;
  height: 100%;

  ${({ isSub }) => isSub && 'z-index: 103'}
`;

interface HeightProps {
  height: number | null;
}

const Height = styled.div<HeightProps>`
  background-color: ${({ theme }) => theme.colors.background.light};
  height: ${({ height }) => height ?? HEADER_MIN_HEIGHT}px;

  ${mq(null, 'tablet')} {
    height: ${({ height }) => height ?? HEADER_MIN_HEIGHT_MOBILE}px;
  }
`;

const Header = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  const headerRef = useRef<HTMLDivElement>(null);
  const headerHeight = useSelector(headerHeightSelector);
  const isHeaderHidden = useSelector(isHeaderHiddenSelector);
  const isHeaderSlideOpen = useSelector(isHeaderSlideOpenSelector);

  const isDrawerOpen = useSelector(selectIsDrawerOpen);

  useHeaderScrollControl(); // Hides header based on scroll

  const { data: cmsData } = useCmsData<NavigationQuery, NavigationData>({
    storeKey: 'navigation',
    query: NavigationDocument
  });

  const handleOnClose = useCallback(() => {
    dispatch(closeMenuDrawer());
  }, [dispatch]);

  const handleOpenMenu = useCallback(() => {
    dispatch(openMenuDrawer('MAIN'));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setContactButtonIsHidden(isDrawerOpen));
  }, [isDrawerOpen, dispatch]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      handleOnClose();
    });

    return () => {
      unlisten();
    };
  }, [handleOnClose, history, dispatch]);

  const menuText = t('Menu');

  useEffect(() => {
    try {
      const headerElement = headerRef.current;

      const resizeObserver = new ResizeObserver(entries => {
        dispatch(
          setHeaderHeightAction(
            Math.floor(entries[0].contentRect.height) ?? null
          )
        );
      });

      if (headerElement) {
        resizeObserver.observe(headerElement);
      }

      return () => {
        if (headerElement) {
          resizeObserver.unobserve(headerElement);
        }
      };
    } catch (e) {
      logger.error(e);
    }
  }, [headerRef, dispatch]);

  return (
    <>
      <HeaderWrapper
        data-testid="header"
        isHidden={isHeaderHidden}
        isHeaderSlideOpen={isHeaderSlideOpen}
        ref={headerRef}
      >
        <HeaderMessages />

        <UtilityBar
          textIconWithLinkList={
            (cmsData?.utilityBarNavigation ?? []) as TextIconWithLinkRecord[]
          }
        />

        <HeaderContainer>
          <HeaderContent>
            <Link to="/">
              <Logo locale={environment.locale as Locale} />
            </Link>

            <MenuButton
              aria-label={menuText}
              size="large"
              color="neutral"
              variant="outline"
              prefixIcon={<MenuIcon />}
              onClick={handleOpenMenu}
            >
              <TinyTitleRegular>{menuText}</TinyTitleRegular>
            </MenuButton>

            <SearchFieldWrapper>
              <ObjectSearchField />
            </SearchFieldWrapper>
          </HeaderContent>
        </HeaderContainer>
      </HeaderWrapper>

      {!isHeaderHidden && <Height height={headerHeight} />}

      <Drawer
        isOpen={isDrawerOpen}
        onBackdropClick={handleOnClose}
        unmountOnClose={false}
      >
        <DrawerMenuContainer>
          <DrawerMenu />
        </DrawerMenuContainer>
      </Drawer>
    </>
  );
};

export default Header;
