import styled from 'styled-components';
import { mq } from '@kvdbil/components';
import React from 'react';

const Container = styled.div`
  font-family: ${({ theme }) => theme.typography.fontBaseFamily};
  font-weight: ${({ theme }) => theme.typography.fontBaseRegularWeight};
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.dark};

  span {
    :not(:last-child)::after {
      content: '/';
      margin: 0 0.25rem;
      color: ${({ theme }) => theme.colors.gray.light4};
    }
  }

  ${mq('tablet')} {
    font-size: 0.75rem;
  }
`;

export interface PropertiesProps {
  properties: string[];
}

const Properties = ({ properties }: PropertiesProps) => {
  return (
    <Container data-testid="properties">
      {properties.filter(Boolean).map(property => (
        <span key={property}>{property}</span>
      ))}
    </Container>
  );
};

export default Properties;
