import { Snack } from '../interfaces/store/Snackbar';

export const ADD_SNACK = 'snackbar/ADD_SNACK';
export const addSnack = (snack: Omit<Snack, 'key'>) => ({
  type: ADD_SNACK,
  payload: {
    ...snack,
    key: Date.now()
  }
});

export const EAT_SNACK = 'snackbar/EAT_SNACK';
export const eatSnack = (payload: number) => ({
  type: EAT_SNACK,
  payload
});
