import React, { useState } from 'react';
import { useTranslation } from '~/Locale';
import {
  Tabs,
  Tab,
  Spacer,
  BodyText,
  Flex,
  Title,
  Stack,
  Message
} from '@kvdbil/components';
import PrivateRegistrationView from '../components/PrivateRegistration';
import { CompanyRegistration } from '../components/CompanyRegistration';
import { getRegistrationTitle } from '../registrationHelpers';
import CreatePrivateAccountForm from '../components/CreatePrivateAccountForm';
import { RegistrationView } from '../types';
import AuthCloseButton from '../../components/AuthCloseButton';
import BackButton from '../../components/BackButton';
import { SubscriptionType } from '~/App/shared/interfaces/EmailSubscription';

interface Props {
  callback?: CallableFunction;
  onClose?(): void;
  onBack?(): void;
  showTitle?: boolean;
  showDescription?: boolean;
  initialTab?: 'company' | 'private';
  onPrivateClick?(): void;
  onCompanyClick?(): void;
  onBankIdAuthStart: (
    email: string,
    emailSubscriptionNames: SubscriptionType[]
  ) => void;
  isBankIdAuthStartLoading: boolean;
  changeView: (view: RegistrationView) => void;
  view: RegistrationView;
  email?: string | null;
  isStandaloneView?: boolean;
  error: string | null;
}

const RegistrationTabView = ({
  onClose,
  onBack,
  showTitle = true,
  showDescription = false,
  initialTab,
  onPrivateClick,
  onCompanyClick,
  onBankIdAuthStart,
  isBankIdAuthStartLoading,
  changeView,
  view,
  email = null,
  isStandaloneView = false,
  error
}: Props) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(initialTab === 'company' ? 1 : 0);

  const isCompany = activeTab === 1;
  const showNoBankidView = view === 'NO_BANKID';

  return (
    <Stack>
      {onBack && onClose && (
        <Flex justify={'space-between'}>
          <BackButton onBack={onBack} />
          <AuthCloseButton onClose={onClose} />
        </Flex>
      )}

      {onClose && !onBack && !isStandaloneView && (
        <Flex justify={'flex-end'}>
          <AuthCloseButton onClose={onClose} />
        </Flex>
      )}

      {onBack && !onClose && (
        <Flex justify={'flex-start'}>
          <BackButton onBack={onBack} />
        </Flex>
      )}
      <Stack spacing={3}>
        {showTitle && (
          <Title as="h2" style={{ textAlign: 'center' }}>
            {getRegistrationTitle(isCompany, t)}
          </Title>
        )}

        {error && (
          <Message fullWidth type="error">
            {typeof error === 'string'
              ? error
              : t('Something went wrong, please try again later.')}
          </Message>
        )}

        {showDescription && (
          <BodyText style={{ textAlign: 'center' }}>
            {t('Create a free account and set up notifications or buy cars.')}
          </BodyText>
        )}

        <Tabs
          justify={'space-around'}
          activeColor="secondary"
          activeIndex={activeTab ? 1 : 0}
        >
          <Tab
            label={t('Private')}
            onClick={() => {
              setActiveTab(0);
              onPrivateClick?.();
            }}
          >
            <Spacer />
            {showNoBankidView && (
              <PrivateRegistrationView changeView={changeView} />
            )}

            {!showNoBankidView && (
              <CreatePrivateAccountForm
                onBankIdAuthStart={onBankIdAuthStart}
                isLoading={isBankIdAuthStartLoading}
                changeView={changeView}
                email={email}
              />
            )}
          </Tab>

          <Tab
            label={t('Organization')}
            onClick={() => {
              setActiveTab(1);
              onCompanyClick?.();
            }}
          >
            <Spacer />
            <CompanyRegistration changeView={changeView} />
          </Tab>
        </Tabs>
      </Stack>
    </Stack>
  );
};

export default RegistrationTabView;
