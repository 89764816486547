import React, { useCallback } from 'react';
import { useTranslation } from '~/Locale';
import { BodyLink, Button } from '@kvdbil/components';
import { startBankId } from '~/App/shared/helpers/bankIdHelpers';
import { ButtonProps } from '@kvdbil/components/types/components/Button';
import { useStartBankId } from '~/App/shared/hooks/bankIdHooks';
import { useIsDevice } from '~/App/shared/hooks/useIsDevice';

type Props = {
  autoStartToken?: string;
  shouldAutoStartOnMobile?: boolean;
  onClick?(): void;
  onAutoStart?(): void;
} & Partial<ButtonProps>;

export const BankIdStartOnDeviceButton = ({
  autoStartToken,
  shouldAutoStartOnMobile = true,
  onClick,
  onAutoStart,
  children,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useIsDevice();

  const handleOnSameDeviceClick = useCallback(() => {
    autoStartToken && startBankId(autoStartToken);
    onClick?.();
  }, [autoStartToken, onClick]);

  useStartBankId({
    autoStartToken,
    shouldStart: isMobile && shouldAutoStartOnMobile,
    onAutoStart: onAutoStart
  });

  return (
    <Button
      variant="flat"
      color="info"
      {...props}
      onClick={handleOnSameDeviceClick}
    >
      {children ?? <BodyLink>{t('Use BankID on this device')}</BodyLink>}
    </Button>
  );
};
