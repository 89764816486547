import { ValuationAction } from '../actions/valuationActions';
import { ReduxStore } from '../interfaces/store';

const initialState: ReduxStore['valuation'] = {
  isError: false,
  isLoading: false
};

const reducer = (
  state = initialState,
  action: ValuationAction
): ReduxStore['valuation'] => {
  switch (action.type) {
    case 'GET_VALUATION_REQUEST':
      return { ...state, isLoading: true };

    case 'GET_VALUATION_FAILURE':
      return { ...state, isLoading: false, isError: true };

    case 'CLEAR_VALUATION_ERROR':
      return { ...state, isError: false };

    default:
      return state;
  }
};

export default reducer;
