import {
  pipe,
  converge,
  unapply,
  all,
  equals,
  test,
  length,
  gte,
  path
} from 'ramda';
import {
  MEMBER_ALREADY_REGISTERED,
  REGISTRATION_PLATE_BLACKLIST
} from '~/config/constants';
import { EMAIL_MAX_LENGTH } from '~/App/shared/validation/constants';
import { FormEventHandler } from 'react';

export type ObjectWithError = Record<string, unknown> & {
  errors?: Record<string, string>[];
};

export const recursiveStringFind = (
  obj: Record<string, unknown> | Record<string, unknown>[],
  array: string[] = []
): string[] => {
  let arr = array;

  if (typeof obj === 'string') {
    arr.push(obj);

    return arr;
  }

  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i += 1) {
      arr = recursiveStringFind(obj[i], arr);
    }
  } else {
    Object.keys(obj).forEach(key => {
      arr = recursiveStringFind(obj[key] as Record<string, unknown>, arr);
    });
  }

  return arr;
};

const formHasErrors = (
  validation: Record<string, object> | null,
  submit: ObjectWithError
) => {
  if (Object.keys(validation?.errors ?? {}).length > 0) {
    return true;
  }

  if (Object.keys(submit?.errors ?? {}).length > 0) {
    return recursiveStringFind(submit?.errors ?? {}).length > 0;
  }

  return false;
};

const mergeFormErrors = (
  validation: ObjectWithError,
  submit: ObjectWithError
) => {
  const retSub = recursiveStringFind(submit?.errors ?? {}, []);
  const retVal = recursiveStringFind(validation?.errors ?? {}, []);

  return [...retSub, ...retVal];
};

const formErrors = (
  submit: ObjectWithError,
  location: string,
  asArray = false
) => {
  const errors = path<Record<string, unknown>>(
    location.split('.'),
    submit.errors
  );

  if (errors) {
    const errorMessages = recursiveStringFind(errors);

    if (errorMessages.length) {
      if (asArray) {
        return errorMessages;
      }
      return errorMessages.join(' ');
    }

    return 'The form contains errors';
  }

  return 'There was an error';
};

function getNestedValue(obj: Record<string, unknown>, path: string) {
  const keys = path.split('.');
  return keys.reduce((acc, key) => acc?.[key], obj);
}

export type FieldValidation = Record<string, unknown> & {
  errors: Record<string, string[]>;
  showError(path: string): void;
  isValid(path: string): boolean;
  isValidated: boolean;
};

export type FieldSubmit = {
  handleSubmit: FormEventHandler<HTMLFormElement>;
  isLoading: boolean;
  errors: Record<string, string>;
};

const mergeFieldErrors = (
  validation: ObjectWithError | FieldValidation,
  submit: ObjectWithError | FieldSubmit,
  path: string,
  submitPath?: string
): string[] => {
  // validation is/can be objects of the form { 'bla.bla.bla': value }, thus the '-s are needed.
  // @ts-expect-error complicated
  const val = recursiveStringFind(validation?.errors?.[path] ?? {}, []);
  const subPath = submitPath || path;
  const submitObject = getNestedValue(
    submit?.errors as Record<string, string>,
    subPath
  ) as Record<string, unknown>;
  const sub = recursiveStringFind(submitObject, []);

  return [...val, ...sub];
};

const getEmailValidationError = (
  submit: ObjectWithError,
  validation: FieldValidation
):
  | (Record<string, unknown> & { text?: string; type?: string | null })[]
  | string[] => {
  // get client validation error
  const emailValidationErrors = mergeFieldErrors(
    validation,
    submit,
    'member.person.email'
  );
  // get server validation error
  const errorMessage = getNestedValue(
    submit,
    'errors.response.data.message.member.person.email'
  );
  const serverError = {
    status: 400,
    errorMessage
  };

  if (serverError.status === 400 && serverError.errorMessage) {
    const errorObject = {
      text: serverError.errorMessage,
      type: MEMBER_ALREADY_REGISTERED
    };
    emailValidationErrors.push(errorObject as never);
  }

  return emailValidationErrors;
};

const changeErrorContent = (
  submit: Record<string, unknown> & { errors: Record<string, string>[] },
  validation: FieldValidation
) => {
  const error = getEmailValidationError(submit, validation);

  switch (typeof error[0]) {
    case 'string':
      error[0] = { text: error[0], type: null };

      return error;
    case 'object':
      error[0] = { text: error[0].text, type: error[0].type };

      return error;
    default:
      return [{}];
  }
};

export const isValidSSN = (ssn: string) =>
  ssn.length !== 12 ? false : /^\d+$/.test(ssn);

const isInvalidRegistrationPlate = (r: string) =>
  (r || []).length > 7 ||
  (r || []).length < 6 ||
  REGISTRATION_PLATE_BLACKLIST.includes(r.replace(/ /g, ''));

const emailValidator = converge(unapply(all(equals(true))), [
  test(/^[a-z0-9.+_-]+@([a-z0-9-]+\.)+[a-z]{2,}$/i),
  pipe(length, gte(EMAIL_MAX_LENGTH))
]);

// might start with + and includes numbers, spaces and dashes
const phoneValidator = test(/^(\+)?[0-9\s-()]{6,18}$/);

export {
  formErrors,
  formHasErrors,
  mergeFormErrors,
  mergeFieldErrors,
  changeErrorContent,
  isInvalidRegistrationPlate,
  emailValidator,
  phoneValidator
};
