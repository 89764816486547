import { resetButtonStyle } from '@kvdbil/components';
import React, { ReactNode, MouseEventHandler } from 'react';
import styled from 'styled-components';

const Button = styled.button`
  ${resetButtonStyle};
`;

interface Props {
  children: ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const CookiesButtonLink = ({ children, onClick }: Props) => (
  <Button onClick={onClick}>{children}</Button>
);

export default CookiesButtonLink;
