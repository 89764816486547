import { standardDeviation, median, mean } from 'simple-statistics';
import { SetServerTimeAction } from '../actions/time';
import { ReduxStore } from '../interfaces/store';

const initialState: {
  serverOffset: number;
  measurements: number[];
} = {
  serverOffset: 0,
  measurements: []
};

const time = (
  state = initialState,
  action: SetServerTimeAction
): ReduxStore['time'] => {
  switch (action.type) {
    case 'SET_SERVER_TIME_OFFSET': {
      // The purpose of this function is to estimate the difference between local clock and server clock.
      // We do this by keeping a list of measurements (we get one every five seconds or so), then applying
      // a statistical computation to find an accurate estimate.
      // Current test: Ignore measurements 1.5 standard deviations from the median, then calculate the mean
      // of the rest. This should give us a robust approximation that is also smooth (changes slowly).

      const currentMeasurement = action.payload ?? 0;

      let measurements = [...state.measurements];
      measurements.push(currentMeasurement);

      // Keep the last few measurements. The chosen number is not important, larger gives more stable result.
      measurements = measurements.slice(-64);

      let serverOffset = state.serverOffset;

      if (measurements.length > 2) {
        const m = median(measurements);
        const stddev = standardDeviation(measurements);
        const filtered = measurements.filter(
          value => Math.abs(value - m) < stddev * 1.5
        );

        if (filtered.length > 0) {
          serverOffset = mean(filtered);
        }
      } else {
        serverOffset = currentMeasurement;
      }

      const diff = serverOffset - state.serverOffset;
      /* If diff is less than 50ms, don't update serverOffset */
      if (Math.abs(diff) < 50) {
        return {
          ...state,
          measurements
        };
      }

      return {
        ...state,
        serverOffset: serverOffset,
        measurements: measurements
      };
    }
    default:
      return state;
  }
};

export default time;
