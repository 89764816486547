import React from 'react';
import { DynamicPageCustomElementRecord } from '~/config/generated/graphql';
import CustomerService from '~/App/shared/components/CustomerService';
import CookieDeclarationInjection from '~/App/shared/components/CookieDeclarationInjection';

type Props = {
  record: DynamicPageCustomElementRecord;
};

const DynamicPageCustomElemDispatcher = ({ record }: Props) => {
  const { usageType } = record;

  switch (usageType) {
    case 'customerService':
      return <CustomerService />;
    case 'cookieDeclarationTable':
      return <CookieDeclarationInjection />;
    default:
      return null;
  }
};

export default DynamicPageCustomElemDispatcher;
