import React from 'react';
import { Path } from 'react-hook-form';
import { EMAIL_MAX_LENGTH } from '~/App/shared/validation/constants';
import { regexEmail } from '~/helpers/regex';
import TextFieldWithControl from '~/App/shared/components/Fields/TextFieldWithControl';
import { TextFieldProps } from '../types';

const EmailField = <FormState,>({
  control,
  t,
  name,
  rules,
  ...props
}: TextFieldProps<FormState>) => {
  const validationRules = {
    required: {
      value: true,
      message: t(`Email can't be blank`)
    },
    maxLength: {
      value: EMAIL_MAX_LENGTH,
      message: `${t(
        'Email is too long (maximum %s characters)',
        EMAIL_MAX_LENGTH
      )}`
    },
    pattern: {
      value: regexEmail,
      message: t('Invalid email address')
    },
    ...rules
  };

  const fieldName = name ?? ('email' as Path<FormState>);

  return (
    <TextFieldWithControl
      {...props}
      control={control}
      name={fieldName}
      label={t('Email')}
      autoComplete="email"
      type="email"
      rules={validationRules}
    />
  );
};

export default EmailField;
