import accounting from 'accounting';
import { Nullable } from '~/App/shared/types/Nullable';

accounting.settings = {
  currency: {
    format: '%v %s',
    decimal: ',',
    thousand: ' ',
    precision: 0
  },

  number: {
    decimal: ',',
    thousand: ' ',
    precision: 0
  }
};

interface FormatMoney {
  value: number | string;
  currency?: string;
  precision?: number;
}
export const formatMoney = ({
  value,
  currency = '',
  precision = 0
}: FormatMoney) => accounting.formatMoney(value, currency, precision);

export const formatMoneyWithoutCurrency = (value: number | string) =>
  accounting.formatMoney(value, { format: '%v', thousand: ' ' });

export const inclVATtoExclVAT = (
  inclVAT?: Nullable<number>,
  VAT = 0.8 // 20%
): number => {
  if (!inclVAT) return 0;

  return inclVAT * VAT;
};

export const vatOfInclVAT = (
  inclVAT?: Nullable<number>,
  VAT = 0.2 // 20%
): number => {
  if (!inclVAT) return 0;

  return inclVAT * VAT;
};
