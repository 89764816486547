import React from 'react';
import styled from 'styled-components';
import { theme } from '@kvdbil/components';

const SwitchWrapper = styled.div`
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-weight: ${theme.typography.fontBaseBoldWeight};
  display: flex;
  align-items: center;
  position: relative;
  .switchContainer {
    margin-right: 0.25rem;
  }
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${theme.colors.gray.light3};
    transition: 0.3s;
    border-radius: 34px;
    &:before {
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      content: 'Off';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: ${theme.colors.background.light};
      transition: 0.3s;
    }
  }

  input:checked + .slider {
    background-color: ${theme.colors.info.main};
  }

  input:focus + .slider {
    box-shadow: 0 0 1px ${theme.colors.info.main};
  }

  input:checked + .slider:before {
    content: 'On';
    transform: translateX(26px);
  }
`;

interface SwitchProps {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  children?: React.ReactNode;
  on?: boolean;
}

const SwitchButton = ({ onChange, children, on }: SwitchProps) => (
  <SwitchWrapper>
    <span className="switchContainer">{children}</span>
    <Switch className="switch">
      <input type="checkbox" checked={on} onChange={onChange} />
      <span className="slider round"></span>
    </Switch>
  </SwitchWrapper>
);

export default SwitchButton;
