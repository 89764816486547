import localForage from 'localforage';
import { log } from '~/helpers/bugsnagHelper';
export const config = {
  // use WebSQL if available,
  // if not fall back to indexedDB
  // lastly fall back to localStorage if indexedDB unavailable.
  driver: [localForage.WEBSQL, localForage.INDEXEDDB, localForage.LOCALSTORAGE],
  size: 5000000 // Size of database, in bytes. WebSQL-only for now. should at least help safari users.
};

const loadLocalForage = async () => {
  // Wait for localForage to load
  await localForage.ready();

  try {
    localForage.config(config);
    return;
  } catch (error: unknown) {
    log(new Error('Failed to access local storage - localForage'), {
      severity: 'info',
      error
    });
    return;
  }
};

const localForageGetItem = async <T>(
  key: string
): Promise<T | null | undefined> => {
  if (typeof window === 'undefined') {
    return null;
  }

  await localForage.ready();

  const localStorageKeys = await localForage.keys();

  // 5 is the expected keys
  // carguide, recentlyViewedAuctions, recentlyViewedSearches, sellFlow, session
  if (localStorageKeys.length > 5) {
    const data = { keys: localStorageKeys };
    try {
      for (key of localStorageKeys) {
        // @ts-expect-error ???
        data[key] = await localForage.getItem(key);
      }
    } catch (error: unknown) {
      log(new Error('Error while iterating over local storage data'), {
        error,
        data
      });
    }
    // We might want to clear the database here, but only logging for now.
    log(
      new Error(
        'localstorage length is larger than expected, perhaps the data is weird..'
      ),
      { severity: 'info', data }
    );
  }

  try {
    return await localForage.getItem<T>(key);
  } catch (error: unknown) {
    log(new Error('Failed to access local storage'), { severity: 'info' });
  }
};

const localForageSetItem = async (key: string, payload: string | unknown) => {
  await localForage.ready();

  try {
    await localForage.setItem(key, payload);
  } catch (error: unknown) {
    log(new Error('Failed to access local storage'), { severity: 'info' });
  }
};

const localForageRemoveItem = async (key: string) => {
  await localForage.ready();

  try {
    await localForage.removeItem(key);
  } catch (error: unknown) {
    log(new Error('Failed to access local storage'), { severity: 'info' });
  }
};

export {
  loadLocalForage,
  localForageSetItem,
  localForageGetItem,
  localForageRemoveItem
};
