import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig
} from 'axios';
import {
  sessionDestroySuccess,
  sessionStartSuccess
} from '~/App/shared/actions/session';
import { clearPollingTargetedAuctions } from '~/App/shared/actions/targetedAuctionsActions';
import { isProduction, isServer, locale } from '~/config/public/environment';
import { url } from '~/config/public/orchestration';
import { ReduxStore } from '~/App/shared/interfaces/store';
import { Store } from 'redux';
import { Session } from '~/App/shared/interfaces/store/Session';
import { log } from '../bugsnagHelper';
import { refreshTokenSelector } from '~/App/shared/selectors/sessionSelector';
import logger from '../logger';
import { getCookieConsent } from '../cookieConsent';

const UNAUTHORIZED = 401;

export enum AxiosErrorType {
  TIMEOUT_MSG = 'timeout of 30000ms exceeded',
  CODE_503 = 503
}

export interface ConfigWithRetries extends InternalAxiosRequestConfig {
  _retry: boolean;
}

export interface AxiosErrorWithRetryData extends AxiosError {
  config: ConfigWithRetries;
}

interface AxiosRetryObject {
  resolve: CallableFunction;
  reject: CallableFunction;
}

interface ErrorInterceptor {
  reduxStore: Store<ReduxStore>;
  fetcher?: typeof axios;
  tokenRenewalFunction?: typeof getRenewedTokens;
}

interface ErrorInterceptorInput {
  default: Store<ReduxStore>;
}

axios.defaults.headers.common['Accept-Language'] =
  locale === 'sv' ? 'sv_SE' : 'en_GB';

axios.defaults.timeout = 30000;

// for multiple requests
let isRefreshing = false;
let failedQueue: AxiosRetryObject[] = [];

const processQueue = (
  error: AxiosErrorWithRetryData | null,
  token: string | null
) => {
  failedQueue.forEach(retry => {
    if (token) {
      retry.resolve(token);
    } else {
      retry.reject(error);
    }
  });

  failedQueue = [];
};

export const getRenewedTokens = (refreshToken: string) => {
  return axios.post<Session>(
    `${url}/common/auth/reauthenticate`,
    {
      auth: {
        refreshToken,
        cookieConsent: getCookieConsent()
      }
    },
    {
      responseType: 'json'
    }
  );
};

const queueRequestsWhileRefreshing = (
  originalConfig: ConfigWithRetries,
  fetcher: typeof axios,
  reduxStore: Store<ReduxStore>
) => {
  return new Promise<AxiosResponse>(resolve => {
    const reso = (token: string): void => {
      originalConfig.headers['Authorization'] = 'Bearer ' + token;
      return resolve(fetcher(originalConfig));
    };

    const reject = (error: AxiosError) => {
      if (!axios.isCancel(error)) {
        // Failed getting a new token with refreshtoken, log the user out
        reduxStore.dispatch(sessionDestroySuccess());
        reduxStore.dispatch(clearPollingTargetedAuctions());
      }

      throw error;
    };

    const retry: AxiosRetryObject = {
      resolve: reso,
      reject
    };

    failedQueue.push(retry);
  });
};

const handleReauthRejection = (
  error: AxiosErrorWithRetryData,
  reduxStore: Store<ReduxStore>
) => {
  isRefreshing = false;
  reduxStore.dispatch(sessionDestroySuccess());
  reduxStore.dispatch(clearPollingTargetedAuctions());
  processQueue(error, null);
  return Promise.reject(error);
};

const handleErrorWithRetryData = (
  error: AxiosErrorWithRetryData,
  reduxStore: Store<ReduxStore>
) => {
  // Only logout on 401 or 503
  if (error?.response?.status && [401, 503].includes(error.response.status)) {
    reduxStore.dispatch(sessionDestroySuccess());
    reduxStore.dispatch(clearPollingTargetedAuctions());
  }

  /* Reject all promises in the queue. */
  processQueue(error, null);

  return Promise.reject(error);
};

export const errorInterceptor =
  ({
    tokenRenewalFunction = getRenewedTokens,
    reduxStore,
    fetcher = axios
  }: ErrorInterceptor) =>
  async (
    error: AxiosErrorWithRetryData
  ): Promise<AxiosResponse<void> | void> => {
    const originalConfig = error.config || { _retry: false, headers: [] };
    const responseCode = error?.response?.status || null;

    const requestUrl = error?.response?.config?.url || '';
    const isReauthCall = requestUrl.includes('reauth');

    const refreshToken = refreshTokenSelector(reduxStore.getState());

    /* Handles refreshing but no reauth calls */
    if (isRefreshing && !isReauthCall) {
      return await queueRequestsWhileRefreshing(
        originalConfig,
        fetcher,
        reduxStore
      );
    }

    /* Handles reauth calls with missing refresh tokens */
    if (!refreshToken || isReauthCall) {
      return handleReauthRejection(error, reduxStore);
    }

    /* retried reqest failes agien logout and reject */
    if (responseCode === UNAUTHORIZED && originalConfig._retry) {
      return handleErrorWithRetryData(error, reduxStore);
    }

    /* Handles normal error, not a 401 */
    if (responseCode !== UNAUTHORIZED || originalConfig._retry) {
      return Promise.reject(error);
    }

    /* now we have a 401 and a refresh token, so we can try to get a new token */
    originalConfig._retry = true;
    isRefreshing = true;

    try {
      const tokenRenewalResponse = await tokenRenewalFunction(refreshToken);

      const { auth, member } = tokenRenewalResponse.data;
      const { idToken, socialIdpSession } = auth;

      /* Save the new tokens & member data */
      reduxStore.dispatch(
        sessionStartSuccess({
          auth: auth,
          member: member,
          socialIdpSession: socialIdpSession || false
        })
      );

      /* Retry the original request, and any other that failed during the time a new token was being fetched */
      originalConfig.headers['Authorization'] = `Bearer ${idToken}`;
      isRefreshing = false;

      /* Resolve all promises in the queue with the new Token */
      processQueue(null, idToken);

      /* Retry the original failed reqest */
      return fetcher(originalConfig);
    } catch (error: unknown) {
      isRefreshing = false;
      return handleErrorWithRetryData(
        error as AxiosErrorWithRetryData,
        reduxStore
      );
    } finally {
      isRefreshing = false;
    }
  };

const requestLogger = (request: InternalAxiosRequestConfig) => {
  const searchParams = new URLSearchParams(request.params ?? {});
  const url = `${request.url}?${searchParams.toString()}`;

  logger.info(`Starting Request: ${url}`);
  return request;
};

// When imported the normal way, the store seemed to be undefined at the the
// time of this initialization Did this instead of grabbing it from the global
// scope as this is better from a testability standpoint
import('../../config/store')
  .then(({ default: store }: ErrorInterceptorInput) => {
    // if development, log all requests with logger
    if (!isProduction && isServer) {
      axios.interceptors.request.use(requestLogger);
    }

    axios.interceptors.response.use(
      // If the response is not an error, simply return it
      value => value,
      errorInterceptor({
        reduxStore: store
      })
    );
  })
  .catch(log);

export default axios;
