/* eslint-disable max-lines */
import { assoc, join, pick, pipe, propEq, values } from 'ramda';
import { createSelector } from 'reselect';
import { calculateFinance, getAllowedResidualLoan } from '~/helpers/calculator';
import {
  calculateCheckoutPrices,
  CalculatedPrice,
  WarrantyOption,
  calculateMonthlyCost,
  formatOrgNumber,
  GetCheckoutPricesInput
} from '~/helpers/checkout';
import { maskNationalIdentificationNumber } from '~/helpers/nationalIdNumber';
import {
  formatMoney,
  inclVATtoExclVAT,
  vatOfInclVAT
} from '~/helpers/currency';
import { Facility } from '../interfaces/Facility';
import { ReduxStore } from '../interfaces/store';
import { Checkout } from '../interfaces/store/Checkout';
import {
  globalInterestRate,
  loanProcessingFee,
  loanMonthlyInvoiceFee
} from './finance';
import { getEffectiveInterestRate } from '~/helpers/loan';
import dayjs from 'dayjs';
import {
  PICKUP_LOCATIONS,
  PICKUP_PERSON,
  WarrantyMonths
} from '~/App/views/Checkout/constants';
import { TranslateFunction } from '~/Locale';
import {
  capitalizeStr,
  isHeavyEquipmentFlow,
  translateVehicleType
} from '~/App/views/Checkout/utils';
import { isOffsiteLocation } from '~/App/views/ObjectPage/helpers';

const root = createSelector<ReduxStore, Checkout, Checkout>(
  state => state?.checkout,
  value => value
);

export const financingRoot = createSelector(root, state => state?.financing);

export const transportRoot = createSelector(root, state => state?.transport);

export const servicesRoot = createSelector(root, state => state?.services);

export const orderRoot = createSelector(root, root => root?.order);

export const isCheckoutComplete = createSelector(orderRoot, order => {
  return order?.state === 'COMPLETE';
});

export const isCheckoutExpired = createSelector(orderRoot, order => {
  return order?.state === 'EXPIRED';
});

export const checkoutQueueNumber = createSelector(
  orderRoot,
  order => order?.reservationQueueNumber
);

export const totalPrice = createSelector(
  orderRoot,
  order => order?.totalPrice ?? ''
);

export const basePrice = createSelector(
  orderRoot,
  order => order?.basePrice ?? ''
);

export const flowType = createSelector(orderRoot, order => order?.checkoutFlow);

export const checkoutAuctionId = createSelector(
  orderRoot,
  order => order?.auctionId
);

export const checkoutIsEligibleToUpdateDelayedBooking = createSelector(
  orderRoot,
  order => order?.isEligibleToUpdateDelayedBooking
);

export const checkoutErrors = createSelector(root, state => state?.errors);

export const checkoutType = createSelector(root, state => state?.type);

export const checkoutSelectedServices = createSelector(
  root,
  state => state?.selectedServices
);

export const checkoutStep = createSelector(root, state => state?.step);

export const checkoutBuyer = createSelector(root, state => state?.buyer);

export const checkoutAvailableUntil = createSelector(
  orderRoot,
  order => order?.availableUntil
);

export const isCheckoutLoading = createSelector(
  root,
  state => state?.isLoading
);
export const isCheckoutPriceLoading = createSelector(
  root,
  state => state?.isPriceLoading
);

export const checkoutCompletedSteps = createSelector(
  root,
  state => state?.completedSteps
);

export const checkoutPricesRoot = createSelector(root, state => state?.prices);

export const warrantyChoices = createSelector(
  checkoutPricesRoot,
  state => state?.warranty
);

export const interestedInExtraTires = createSelector(
  root,
  state => state?.interestInExtraTires
);

export const extraTiresPrice = createSelector(
  orderRoot,
  interestedInExtraTires,
  (order, isInterested) => {
    if (isInterested) {
      return order?.extras
        ? Number(
            order?.extras.find(optional => optional.extraType === 'EXTRA_TIRES')
              ?.price
          )
        : 0;
    } else return 0;
  }
);

export const extraTiresOptional = createSelector(
  orderRoot,
  order =>
    order?.extras &&
    order?.extras.find(optional => optional.extraType === 'EXTRA_TIRES')
);

export const isInterestedInFinancingSelector = createSelector(
  orderRoot,
  order => order?.interestInFinancing ?? false
);

export const pickupMethodSelector = createSelector(
  orderRoot,
  order => order?.pickupMethod
);

export const isWarranty24Available = createSelector(
  checkoutPricesRoot,
  prices => {
    const choices = prices.warranty?.choices || [];
    const warrantyItem = choices.find(warranty => warranty.period === 24);
    return Boolean(warrantyItem);
  }
);

export const pickupPerson = createSelector(
  transportRoot,
  state => state?.pickupPerson
);

export const pickupLocation = createSelector(
  transportRoot,
  state => state?.pickupLocation
);

export const isCarDealer = createSelector(
  flowType,
  flowType => flowType === 'SWEDISH_CAR_DEALERS'
);

export const isHeavyEquipmentFlowSelector = createSelector(flowType, flowType =>
  isHeavyEquipmentFlow(flowType)
);

export const availablePickupTimeslots = createSelector(
  transportRoot,
  state => state?.availablePickupTimeslots
);

export const checkoutSelectedTimeslot = createSelector(
  transportRoot,
  state => state?.selectedTimeslot
);

export const pickupProxySelected = createSelector(
  transportRoot,
  state => state?.pickupProxySelected
);

export const pickupProxyPerson = createSelector(
  transportRoot,
  state => state?.pickupProxyPerson
);

export const pickupProxyTransportCompanySelector = createSelector(
  transportRoot,
  state => state?.pickupProxyTransportCompany
);

export const checkoutToFacilityId = createSelector(
  transportRoot,
  state => state?.toFacilityId
);

export const checkoutFacilities = createSelector(
  transportRoot,
  root => root?.facilities ?? []
);

export const checkoutToFacility = createSelector(
  checkoutToFacilityId,
  checkoutFacilities,
  (id, facilities = []) => {
    return facilities.find(propEq('id', id));
  }
);

export const checkoutCurrentFacility = createSelector(
  orderRoot,
  checkoutFacilities,
  (order, facilities = []) => {
    return facilities.find(propEq('id', order?.fromFacilityId));
  }
);

export const checkoutHomeDelivery = createSelector(
  transportRoot,
  state => state?.homeDelivery
);

export const checkoutDeliveryLocation = createSelector(
  transportRoot,
  state => state?.deliveryLocation
);

export const checkoutTransportCosts = createSelector(
  transportRoot,
  state => state?.costs
);

export const orderTransport = createSelector(
  orderRoot,
  order => order?.transport
);

export const checkoutToFacilityCost = createSelector(
  pickupLocation,
  checkoutTransportCosts,
  checkoutToFacilityId,
  checkoutHomeDelivery,
  (pickupLocation, transportCosts = [], toFacilityId, homeDelivery) => {
    const isHomeDelivery = pickupLocation === PICKUP_LOCATIONS.LOCATION;

    const toFacilityIdCost = transportCosts.find(
      cost => cost.toFacilityId === toFacilityId
    );

    return isHomeDelivery
      ? parseInt(homeDelivery?.transportToFacilityCost ?? '0', 10) || 0
      : toFacilityIdCost?.price ?? 0;
  }
);

const checkoutWarranty = createSelector(servicesRoot, state => state?.warranty);

export const checkoutOrderId = createSelector(
  orderRoot,
  order => order?.orderId ?? 0
);

export const checkoutOrderAuthToken = createSelector(
  orderRoot,
  order => order?.authToken ?? ''
);

export const isOrderingMember = createSelector(
  checkoutBuyer,
  state => state?.isOrderingMember
);

export const pickupByBuyer = createSelector(
  orderRoot,
  order => order?.pickupByBuyer
);

export const checkoutProcessObject = createSelector(
  orderRoot,
  order => order?.processObject
);

export const isLoanResidualValueSelector = createSelector(
  checkoutProcessObject,
  processObject =>
    processObject ? getAllowedResidualLoan(processObject) : false
);

export const orderMediationFee = createSelector(
  orderRoot,
  order => order?.mediationFee ?? ''
);

export const orderBiddingFee = createSelector(
  orderRoot,
  order => order?.biddingFee ?? ''
);

export const checkoutFacilityId = createSelector(
  orderRoot,
  order => order?.fromFacilityId ?? ''
);

export const checkoutMedia = createSelector(orderRoot, order => order?.media);

export const checkoutIsForeignCustomer = createSelector(
  orderRoot,
  order => order?.isForeignCustomer ?? false
);

export const checkoutOrderPrice = createSelector(
  orderRoot,
  order => order?.basePrice ?? ''
);

export const checkoutBookedTimeslot = createSelector(
  root,
  root => root?.bookedTimeslot
);

export const checkoutWarrantyMonths = createSelector(
  checkoutWarranty,
  state => state?.months
);

export const checkoutPaymentMethod = createSelector(
  financingRoot,
  state => state?.paymentMethod
);

export const checkoutDownPayment = createSelector(
  financingRoot,
  state => state?.downPayment
);

export const checkoutLoanAmount = createSelector(
  financingRoot,
  state => state?.amount
);
export const checkoutLoanPeriod = createSelector(
  financingRoot,
  state => state?.paymentPeriodMonths
);
export const checkoutLoanDownPayment = createSelector(
  financingRoot,
  state => state?.downPayment
);

export const checkoutEffectiveInterestRate = createSelector(
  checkoutLoanAmount,
  globalInterestRate,
  loanProcessingFee,
  loanMonthlyInvoiceFee,
  checkoutLoanPeriod,
  checkoutLoanDownPayment,
  (
    loanAmount: string,
    interestRate: number,
    processingFee: number,
    monthlyInvoiceFee: number,
    loanPeriod: number,
    downPayment: number
  ) => {
    const parsedLoanAmount = Number(loanAmount);
    if (!parsedLoanAmount || !globalInterestRate) return null;

    return getEffectiveInterestRate(
      processingFee,
      interestRate,
      loanPeriod,
      parsedLoanAmount,
      downPayment,
      monthlyInvoiceFee
    );
  }
);

export const checkoutPaymentPeriod = createSelector(
  financingRoot,
  state => state?.paymentPeriodMonths
);

const getFacilityAddress = (facility?: Facility) => {
  if (!facility) {
    return '';
  }

  return `${facility.address}, ${facility.postCode} ${facility.city}`;
};

export const checkoutFacilityAddress = createSelector(
  checkoutToFacility,
  getFacilityAddress
);

export const checkoutCurrentFacilityAddress = createSelector(
  checkoutCurrentFacility,
  getFacilityAddress
);

export const checkoutHomeDeliveryAddress = createSelector(
  checkoutDeliveryLocation,
  deliveryLocation => {
    const { city, streetName, streetNumber, zipCode } = deliveryLocation || {};
    return `${streetName} ${streetNumber}, ${zipCode} ${city}`;
  }
);

export const checkoutTransportAddress = createSelector(
  pickupLocation,
  checkoutFacilityAddress,
  checkoutCurrentFacilityAddress,
  checkoutHomeDeliveryAddress,
  (
    pickupLocation,
    currentFacilityAddress,
    facilityAddress,
    homeDeliveryAddress
  ) => {
    if (pickupLocation === PICKUP_LOCATIONS.LOCATION) {
      return homeDeliveryAddress;
    }

    return currentFacilityAddress || facilityAddress;
  }
);

export interface CheckoutSelectorPrices {
  downPayment: number;
  downPaymentPercent: number;
  loanFromPrice: number;

  vehicle: CalculatedPrice;
  biddingFee: CalculatedPrice;
  mediationFee: CalculatedPrice;
  insurance: CalculatedPrice;
  warranty: CalculatedPrice;
  serviceAgreement: CalculatedPrice;
  total: CalculatedPrice;
  selectedWarrantyMonths: WarrantyMonths;
  warrantyOptions: WarrantyOption;
  includesFreeWarranty: boolean;
  warrantyIsAvailable: boolean;

  /* Possible split it like this - By payment / checkout type. (Financing alternatives) */
  cash: {
    total: CalculatedPrice;
    warranty: CalculatedPrice;
  };
  loan: {
    total: CalculatedPrice;
    cash: CalculatedPrice;
    warranty: CalculatedPrice;
    serviceAgreement: CalculatedPrice;
  };

  transport: {
    total: CalculatedPrice;
    toFacility: CalculatedPrice;
    toLocation: CalculatedPrice;
  };

  vat: {
    price: CalculatedPrice;
    biddingFee: CalculatedPrice;
    mediationFee: CalculatedPrice;
    total: CalculatedPrice;
  };

  excVat: {
    price: CalculatedPrice;
    biddingFee: CalculatedPrice;
    mediationFee: CalculatedPrice;
    total: CalculatedPrice;
  };
}

export const checkoutPrices = createSelector(
  checkoutPricesRoot,
  checkoutWarrantyMonths,
  checkoutPaymentPeriod,
  checkoutOrderPrice,
  checkoutSelectedServices,
  checkoutPaymentMethod,
  checkoutType,
  checkoutDownPayment,
  checkoutToFacilityCost,
  globalInterestRate,
  extraTiresPrice,
  checkoutProcessObject,
  (
    prices,
    warrantyMonths,
    paymentPeriod = 72,
    orderPrice = '0',
    selectedServices,
    paymentMethod,
    checkoutType,
    downPayment,
    toFacilityCost,
    interestRate,
    extraTiresPrice,
    processObject
  ): CheckoutSelectorPrices => {
    const isMainMonthly = paymentMethod !== 'CASH';
    const isBuyNow = checkoutType === 'BUY_NOW';
    const isResidualValue = processObject
      ? getAllowedResidualLoan(processObject)
      : false;
    const residualPercentage = isResidualValue ? 0.5 : 0;

    const calculatePrice = (
      input: Pick<
        GetCheckoutPricesInput,
        | 'price'
        | 'downPayment'
        | 'available'
        | 'residualPercentage'
        | 'originalPrice'
      >
    ) => {
      return calculateCheckoutPrices({
        ...input,
        months: paymentPeriod,
        isMainMonthly,
        interestRate
      });
    };

    const vehicle = calculatePrice({
      price: parseInt(orderPrice, 10),
      downPayment
    });

    const insurance = calculatePrice({ ...prices?.insurance, downPayment: 0 });
    const biddingFee = calculatePrice({ ...prices?.biddingFee, downPayment });
    const mediationFee = calculatePrice(prices?.mediationFee);

    const warrantyChoices = prices?.warranty?.choices || [];

    const warrantyPriceItem = warrantyChoices.find(priceItem => {
      return (
        priceItem.paymentMethod === paymentMethod &&
        priceItem.period === warrantyMonths
      );
    }) || {
      price: 0,
      originalPrice: 0
    };

    const serviceAgreementPriceItem = calculatePrice({
      price: prices?.serviceAgreement?.price ?? 0,
      available: prices?.serviceAgreement?.available
    });

    const servicesCosts =
      selectedServices?.map(service => {
        switch (service) {
          case 'INSURANCE':
            return insurance.price || 0;
          case 'WARRANTY':
            return warrantyPriceItem.price || 0;
          case 'SERVICE_AGREEMENT':
            return serviceAgreementPriceItem.price || 0;
          default:
            return 0;
        }
      }) ?? [];

    /* Get transport cost */
    const homeDelivery = prices?.homeDelivery || {};
    const homeDeliveryCost = homeDelivery.homeDeliveryCost ?? 0;

    const totalTransportCost = toFacilityCost + homeDeliveryCost;
    const transport = {
      total: calculatePrice({
        price: totalTransportCost,
        downPayment
      }),
      toFacility: calculatePrice({
        price: toFacilityCost,
        downPayment
      }),
      toLocation: calculatePrice({
        price: homeDeliveryCost,
        downPayment
      })
    };

    /* Warranty - Loan */
    const warrantyLoanPriceItem = warrantyChoices.find(priceItem => {
      return (
        priceItem.paymentMethod === 'LOAN' &&
        priceItem.period === warrantyMonths
      );
    }) || {
      price: 0,
      monthlyCost: 0,
      available: false,
      originalPrice: 0
    };

    /* Warranty - Cash */
    const warrantyCashPriceItem = warrantyChoices.find(priceItem => {
      return (
        priceItem.paymentMethod === 'CASH' &&
        priceItem.period === warrantyMonths
      );
    }) || {
      price: 0,
      available: false,
      originalPrice: 0
    };

    /* Total - Cash */
    const cashSums = [
      totalTransportCost,
      vehicle.price,
      ...servicesCosts,
      biddingFee.price,
      mediationFee.price,
      extraTiresPrice
    ];

    const cashTotalAmount = cashSums.reduce((sum, value) => sum + value, 0);

    const cash = {
      warranty: calculatePrice({
        available: true,
        ...warrantyCashPriceItem,
        downPayment
      }),
      total: calculatePrice({
        price: cashTotalAmount,
        downPayment
      })
    };

    /* Total - Loan */
    const loanSums = [
      vehicle.price,
      ...servicesCosts,
      prices?.biddingFee.price,
      prices?.mediationFee.price,
      extraTiresPrice
    ];
    const loanTotalAmount = loanSums.reduce((sum, value) => sum + value, 0);

    /*
      If the downpaymentPercent is 20%, we just remove serviceAgreemntPrice from the totalPrice,
      since the minimum downpayment will be increased by 20%.
    */
    const rawDownPaymentPercent = downPayment / loanTotalAmount;
    const downPaymentPercent = parseFloat(rawDownPaymentPercent.toFixed(6));

    const warrantyLoanPrice = warrantyLoanPriceItem.price || 0;
    const warrantyLoanOriginalPrice = warrantyLoanPriceItem.originalPrice || 0;

    const warrantyLoanCost = calculateMonthlyCost({
      price: warrantyLoanPrice,
      loanPeriod: paymentPeriod,
      downPayment: warrantyLoanPrice * downPaymentPercent,
      interestRate,
      residualPercentage
    });

    const warrantyLoanOriginalCost = calculateMonthlyCost({
      price: warrantyLoanOriginalPrice,
      loanPeriod: paymentPeriod,
      downPayment: warrantyLoanOriginalPrice * downPaymentPercent,
      interestRate,
      residualPercentage
    });

    const loan = {
      warranty: {
        ...calculatePrice({
          available: true,
          ...warrantyLoanPriceItem,
          downPayment
        }),
        loanOriginalPrice: warrantyLoanOriginalCost,
        loanPrice: warrantyLoanCost,
        main: warrantyLoanCost
      },
      total: calculatePrice({
        price: loanTotalAmount,
        downPayment,
        residualPercentage
      }),
      cash: calculatePrice({
        price: [downPayment, totalTransportCost].reduce(
          (sum, value) => sum + value,
          0
        ),
        downPayment
      }),
      serviceAgreement: calculatePrice({
        available: prices?.serviceAgreement?.available,
        price: prices?.serviceAgreement?.price ?? 0,
        originalPrice: prices?.serviceAgreement?.originalPrice,
        downPayment: 0
      })
    };

    /* Calculate the total price */
    const total = calculatePrice({
      price: loanTotalAmount,
      downPayment
    });

    const loanFromPrice = calculateFinance({
      price: vehicle.price,
      downPayment: vehicle.price * 0.2, // DownPayment
      months: isResidualValue ? 36 : 72, // LoanPeriod
      interestRate,
      residualPercentage
    });

    let warranty = cash.warranty;
    if (paymentMethod === 'LOAN') {
      warranty = loan.warranty;
    }

    const serviceAgreement = loan.serviceAgreement;

    // returns the warranty options cost depending on CASH or LOAN
    // key for returned dictionary is either 6, 12, 24 or 36
    // and it returns the calculated monthly cost for either period
    const warrantyOptions = warrantyChoices.reduce((objects, c) => {
      if (c.period === 6 && c.price === 0 && isBuyNow) {
        return objects;
      } else if (c.paymentMethod === 'LOAN') {
        const servicesCosts = selectedServices?.map(service => {
          switch (service) {
            case 'INSURANCE':
              return insurance.price || 0;
            case 'SERVICE_AGREEMENT':
              return serviceAgreementPriceItem.price || 0;
            default:
              return 0;
          }
        });

        const loanSums = [vehicle.price, ...servicesCosts, c.price];
        const loanTotalAmount = loanSums.reduce((sum, value) => sum + value, 0);
        const warrantyLoanPrice = c.price || 0;
        const rawDownPaymentPercent = downPayment / loanTotalAmount;
        const downPaymentPercent = parseFloat(rawDownPaymentPercent.toFixed(6));
        const cost = calculateMonthlyCost({
          price: warrantyLoanPrice,
          loanPeriod: paymentPeriod,
          downPayment: downPaymentPercent * warrantyLoanPrice,
          interestRate,
          residualPercentage
        });
        return { ...objects, [c.period]: { ...c, price: cost } };
      } else {
        return { ...objects, [c.period]: c };
      }
    }, {});

    // SERVICEREQ-8992 - free 6 months warranty for fixed price cars will be removed
    // consider to remove it in the future
    const includesFreeWarranty = warrantyChoices.some(
      c => c.period === 6 && c.price === 0 && checkoutType === 'BUY_NOW'
    );

    const warrantyIsAvailable = Boolean(prices?.warranty?.available);

    const priceVat = vatOfInclVAT(vehicle.price);
    const biddingFeeVat = vatOfInclVAT(prices?.biddingFee.price);
    const mediationFeeVat = vatOfInclVAT(prices?.mediationFee.price);

    const totalVat = [priceVat, biddingFeeVat, mediationFeeVat].reduce(
      (sum, value) => sum + value,
      0
    );

    const vat = {
      price: calculatePrice({
        price: priceVat,
        downPayment: 0
      }),
      biddingFee: calculatePrice({
        price: vatOfInclVAT(prices?.biddingFee.price),
        downPayment: 0
      }),
      mediationFee: calculatePrice({
        price: vatOfInclVAT(prices?.mediationFee.price),
        downPayment: 0
      }),
      total: calculatePrice({
        price: totalVat,
        downPayment: 0
      })
    };

    const priceExcVat = inclVATtoExclVAT(vehicle.price);
    const biddingFeeExcVat = inclVATtoExclVAT(prices?.biddingFee.price);
    const mediationFeeExcVat = inclVATtoExclVAT(prices?.mediationFee.price);
    const totalExcVat = [
      priceExcVat,
      biddingFeeExcVat,
      mediationFeeExcVat
    ].reduce((sum, value) => sum + value, 0);

    const excVat = {
      price: calculatePrice({
        price: priceExcVat,
        downPayment: 0
      }),
      biddingFee: calculatePrice({
        price: biddingFeeExcVat,
        downPayment: 0
      }),
      mediationFee: calculatePrice({
        price: mediationFeeExcVat,
        downPayment: 0
      }),
      total: calculatePrice({
        price: totalExcVat,
        downPayment: 0
      })
    };

    return {
      vehicle,
      loanFromPrice,
      insurance,
      warranty,
      transport,
      downPayment,
      downPaymentPercent,
      biddingFee,
      mediationFee,
      serviceAgreement,
      total,
      cash,
      loan,
      warrantyOptions,
      selectedWarrantyMonths: warrantyMonths,
      includesFreeWarranty,
      warrantyIsAvailable,
      vat,
      excVat
    };
  }
);

export const checkoutConfigureStr = (t: TranslateFunction) =>
  createSelector(
    checkoutPaymentMethod,
    checkoutPrices,
    checkoutSelectedServices,
    checkoutPaymentPeriod,
    checkoutDownPayment,
    isCarDealer,
    isHeavyEquipmentFlowSelector,
    interestedInExtraTires,
    (
      paymentMethod,
      prices,
      selectedServices,
      paymentPeriodMonths,
      downPayment,
      isCarDealer,
      isHeavyEquipmentFlow,
      interestedInExtraTires
    ) => {
      const configureChoices: (string | undefined)[] = [];
      const totalPrice = formatMoney({
        value: prices.total.price || 0,
        currency: t('SEK')
      });

      if (isHeavyEquipmentFlow) {
        return `${t('Cash')}, ${t('To pay:')} ${formatMoney({
          value: prices.total.price,
          currency: t('SEK')
        })}`;
      }

      if (isCarDealer) {
        return `${t('Cash')} \n ${t('To pay:')} ${formatMoney({
          value: prices.total.price,
          currency: t('SEK')
        })}`;
      }

      /* Add the payment method choosen */
      switch (paymentMethod) {
        case 'CASH': {
          configureChoices.push(t('Cash'));
          configureChoices.push(`${t('To pay:')} ${totalPrice}`);
          break;
        }

        case 'LOAN': {
          configureChoices.push(t('Loan'));

          const downPaymentText = formatMoney({
            value: downPayment || 0,
            currency: t('SEK')
          });

          configureChoices.push(`${downPaymentText} ${t('down payment')}`);

          configureChoices.push(
            `${paymentPeriodMonths} ${t('months')} ${t(
              'months payment period'
            )}`
          );
          break;
        }
        default:
          break;
      }

      if (selectedServices.length > 0 && !isHeavyEquipmentFlow) {
        configureChoices.push(
          `${selectedServices.length + Number(interestedInExtraTires)} ${t(
            'choosen services'
          )}`
        );
      }

      return configureChoices.filter(Boolean).join(', ');
    }
  );

export const checkoutPickupPersonText = (t: TranslateFunction) =>
  createSelector(
    pickupLocation,
    pickupProxySelected,
    flowType,
    (pickupLocation, pickupProxySelected, checkoutFlow) => {
      const isHomeDelivery = pickupLocation === PICKUP_LOCATIONS.LOCATION;

      const pickupPersonCheck = pickupProxySelected
        ? PICKUP_PERSON.SOMEONE_ELSE
        : PICKUP_PERSON.ME;

      const pickupText =
        pickupPersonCheck === PICKUP_PERSON.ME
          ? t(
              'Owner retrieves %s',
              translateVehicleType({ t, checkoutFlow, definitive: true })
            )
          : t(
              'Someone else retrieves %s',
              translateVehicleType({ t, checkoutFlow, definitive: true })
            );
      const pickupLocationText =
        pickupPersonCheck === PICKUP_PERSON.ME
          ? t(
              'Owner receives %s',
              translateVehicleType({ t, checkoutFlow, definitive: true })
            )
          : t(
              'Someone else receives %s',
              translateVehicleType({ t, checkoutFlow, definitive: true })
            );

      return isHomeDelivery ? pickupLocationText : pickupText;
    }
  );

export const checkoutBuyerStr = createSelector(checkoutBuyer, buyer => {
  if (buyer.organisationName) {
    return `${buyer.organisationName}, ${formatOrgNumber(
      buyer.organisationNumber
    )}`;
  }

  const nationalId = buyer.nationalIdentificationNumber || '';

  return pipe(
    assoc('name', `${buyer.firstName} ${buyer.lastName}`),
    assoc(
      'nationalIdentificationNumber',
      maskNationalIdentificationNumber(nationalId)
    ),
    pick(['name', 'nationalIdentificationNumber']),
    values,
    join(', ')
  )(buyer);
});

export const pickupAvailable = createSelector(
  checkoutCurrentFacility,
  checkoutToFacilityId,
  (currentFacility, toFacilityId) => currentFacility?.id === toFacilityId
);

export const checkoutDeliveryStr = (t: TranslateFunction) =>
  createSelector(
    checkoutDeliveryLocation,
    pickupLocation,
    checkoutTransportAddress,
    checkoutPickupPersonText(t),
    isCarDealer,
    flowType,
    checkoutSelectedTimeslot,
    pickupAvailable,
    checkoutProcessObject,
    (
      deliveryLocation,
      pickupLocation,
      facilityAddress,
      pickupPersonText,
      isCarDealer,
      checkoutFlow,
      selectedTimeslot,
      pickupAvailable,
      processObject
    ) => {
      const isOffsite = isOffsiteLocation(processObject);
      const objectLocation = isOffsite
        ? processObject?.offsiteSalesContact?.city ?? ''
        : facilityAddress;

      const vehicleString = capitalizeStr(
        translateVehicleType({ t, checkoutFlow, definitive: true })
      );

      const facilityText = isOffsite
        ? t('%s is retrieved in %s', vehicleString, objectLocation)
        : t('%s is retrieved at %s', vehicleString, objectLocation);

      if (isHeavyEquipmentFlow(checkoutFlow)) {
        if (pickupLocation === PICKUP_LOCATIONS.TRANSPORT_QUOTE) {
          return `${t('Quote for transport requested')}
          ${pickupPersonText}`;
        }
      }

      const { streetName, streetNumber, city } = deliveryLocation || {};
      const homeDeliveryText = `${t(
        'Home delivery to'
      )} ${streetName} ${streetNumber}, ${city}`;

      let deliveryText = facilityText;
      if (pickupLocation === PICKUP_LOCATIONS.LOCATION) {
        deliveryText = homeDeliveryText;
      }

      if (isCarDealer && pickupLocation !== PICKUP_LOCATIONS.DELAYED_BOOKING) {
        const timeBookingText = pickupAvailable
          ? `${t('Can be collected ')} ${dayjs(selectedTimeslot?.date).format(
              'dddd DD MMMM'
            )} kl ${dayjs(selectedTimeslot?.date).format('HH:mm')}.`
          : t("You'll get a mail when the vehicle is at the facility");

        return `${pickupPersonText}. \n\n${deliveryText}. \n${
          pickupLocation !== PICKUP_LOCATIONS.LOCATION ? timeBookingText : ''
        }`;
      }

      return `${pickupPersonText}. \n${deliveryText}`;
    }
  );

export const checkoutSummaryStr = (t: TranslateFunction) =>
  createSelector(
    checkoutPrices,
    checkoutDownPayment,
    checkoutPaymentMethod,
    (prices, downPayment, paymentMethod) => {
      if (paymentMethod === 'CASH') {
        return pipe(
          assoc(
            'price',
            `${t('Pay now')}: ${formatMoney({
              value: prices.total.price,
              currency: t('SEK')
            })}`
          ),
          pick(['price']),
          values,
          join(', ')
        )(prices);
      }

      return pipe(
        assoc(
          'price',
          `${t('Monthly cost')}: ${formatMoney({
            value: prices.total.loanPrice,
            currency: t('SEK / MONTH')
          })}`
        ),
        assoc(
          'downPayment',
          `${t('Down payment')}: ${formatMoney({
            value: downPayment,
            currency: t('SEK')
          })}`
        ),
        pick(['price', 'downPayment']),
        values,
        join('\n')
      )(prices);
    }
  );

export const checkoutVehiclePrice = createSelector(
  checkoutPrices,
  prices => prices?.vehicle
);

export const checkoutBiddingFee = createSelector(
  checkoutPrices,
  prices => prices?.biddingFee
);

export const checkoutMediationFee = createSelector(
  checkoutPrices,
  prices => prices?.mediationFee
);

export const checkoutTransportPrice = createSelector(
  checkoutPrices,
  prices => prices?.transport
);

export const checkoutTotalPrice = createSelector(
  checkoutPrices,
  prices => prices?.total
);
export const checkoutCashPrice = createSelector(
  checkoutPrices,
  prices => prices?.cash
);
export const checkoutLoanPrice = createSelector(
  checkoutPrices,
  prices => prices?.loan
);

export const checkoutVat = createSelector(
  checkoutPrices,
  prices => prices?.vat
);

export const checkoutExcVat = createSelector(
  checkoutPrices,
  prices => prices?.excVat
);

export const isCarPriceChanged = createSelector(
  root,
  root => root?.isCarPriceChanged
);

export const orderGA4ObjectSelector = createSelector(
  root,
  root => root.orderGA4Object
);
