export const regexPhoneNumber = /^\d{6,12}$|^(\+)?\d{8,14}$/;
export const regexNationalIdentificationNumber =
  /^(\d{6}|\d{8})[-|(\s)]{0,1}\d{4}$/;
export const regexZipCode = /^[0-9]{0,10}$/;
export const regexSwedishZipCode = /^[0-9]{3}\s?[0-9]{2}$/;
export const regexOrganisationNumber = /\d{6}-?\d{4}/;
export const regexRegistrationPlate = /^(\w{3}\s?\d{2}.)$/;
export const regexName = /^[A-ZÅÄÖ-\s]+$/i;
export const regexEmail =
  // eslint-disable-next-line no-control-regex
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const regexBankClearingNumber =
  /^8[0-9]{4}$|^8[0-9]{3}-[0-9]{1}$|^[0-9]{4}$/; // Swedbank | Swedbank with dash | Other
export const regexBankAccountNumber = /^[0-9]{7,10}$|^[0-9]{7,9}-?[0-9]{1}$/;
export const regexOnlyDigits = /^\d+$/;
