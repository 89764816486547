import { KvdHelperAction } from '../actions/kvdHelperActions';
import { ReduxStore } from '../interfaces/store';

type KvdHelperState = ReduxStore['kvdHelper'];

const initialState: KvdHelperState = {
  showDato: false
};

const kvdHelperReducer = (
  state: KvdHelperState = initialState,
  action: KvdHelperAction
): ReduxStore['kvdHelper'] => {
  switch (action.type) {
    case 'TOGGLE_SHOW_DATO':
      return {
        ...state,
        showDato: !state.showDato
      };
    default:
      return state;
  }
};

export default kvdHelperReducer;
