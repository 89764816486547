import { AxiosError } from 'axios';
import { isAxiosError, isNetworkError } from './axiosUtils';
import { log } from './bugsnagHelper';

export const requestErrorHandler = (err?: unknown | AxiosError | Error) => {
  if (!err) {
    return;
  }

  // axiosError is also instanceof Error.
  if (err instanceof Error && !isAxiosError(err)) {
    log(err, { unhandled: true });
    return;
  }

  if (!isAxiosError(err)) {
    return;
  }

  if (err.response?.status === 401) {
    /* If this is a 401 error, we do not log it */
    return;
  }

  if (err.response?.status === 503) {
    log(err, { severity: 'warning' });
    return;
  }

  if (isNetworkError(err)) {
    log(err, { severity: 'warning' });
  }
};

export const localForageErrorHandler = (err: Error) => {
  log(err);
};
