import React from 'react';
import Link from '~/App/shared/components/Link';
import { Button, mq } from '@kvdbil/components';
import styled from 'styled-components';
import { FooterNavigationData } from '~/App/shared/interfaces/store/CmsData';
import { Color, Size, Variant } from '@kvdbil/components/types/Types';

const Container = styled.ul`
  display: flex;
  gap: 1rem;
  padding: 1rem 0;

  & > * {
    flex: 1;
  }

  ${mq(null, 'tablet')} {
    display: none;
  }
`;

interface Props {
  buttons: FooterNavigationData['footerButtons'];
}

const Buttons = ({ buttons }: Props) => {
  return (
    <Container>
      {buttons?.map((button, index) => (
        <li key={button?.url ?? index}>
          <Link
            to={button?.url}
            target={button?.openLinkInNewWindow ? '_blank' : undefined}
          >
            <Button
              as="span"
              color={(button?.color as Color) || 'secondary'}
              size={(button?.size as Size) || 'regular'}
              variant={(button?.variant as Variant) || 'solid'}
              fullWidth
            >
              {button?.text}
            </Button>
          </Link>
        </li>
      ))}
    </Container>
  );
};

export default Buttons;
