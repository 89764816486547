import { SavingSearch } from './../interfaces/SavedSearch';
import { RecentlyViewedAction } from '../actions/recently-viewed-searches';
import { ReduxStore } from '../interfaces/store';
import { areDeepEquals } from '../utils';

type Searches = SavingSearch[] | [];
type State = { searches: Searches; isHydrated: boolean };

function createUniqueSearches(arr: SavingSearch[]) {
  const uniqueSearches: SavingSearch[] = [];

  arr.forEach(search => {
    let isUnique = true;
    uniqueSearches.forEach(uniqueSearch => {
      if (areDeepEquals(search, uniqueSearch)) {
        isUnique = false;
      }
    });
    if (isUnique && !!search) {
      uniqueSearches.push(search);
    }
  });

  return uniqueSearches;
}

const recentlyViewedSearches = (
  state: State = { searches: [], isHydrated: false },
  action: RecentlyViewedAction
): ReduxStore['recentlyViewedSearches'] => {
  switch (action.type) {
    case 'SAVE_RECENT_SEARCH': {
      const searches = action.payload
        ? createUniqueSearches([...state.searches, action.payload]).slice(-10)
        : state.searches;

      return {
        ...state,
        searches
      };
    }

    case 'RECENTLY_VIEWED_SEARCHES_HYDRATE': {
      const searches = createUniqueSearches(action.payload).slice(-10);

      return { ...state, searches, isHydrated: true };
    }

    case 'CLEAR_RECENT_SEARCHES': {
      return { ...state, searches: [] };
    }

    default: {
      return state;
    }
  }
};

export default recentlyViewedSearches;
