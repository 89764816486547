import React, { HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import { useBodyLockScroll } from '../hooks/useBodyLockScroll';

const Overlay = styled.div<{ backgroundColor: string }>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100000;
  background-color: ${({ backgroundColor }) => backgroundColor};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props extends HTMLAttributes<HTMLDivElement> {
  lockBody?: boolean;
  backgroundColor?: string;
  children: ReactNode;
}

const AppLoaderOverlay = ({
  lockBody = true,
  backgroundColor = 'rgba(0, 0, 0, 0.8)',
  children,
  ...props
}: Props) => {
  useBodyLockScroll(lockBody);

  return (
    <Overlay backgroundColor={backgroundColor} {...props}>
      {children}
    </Overlay>
  );
};

export default AppLoaderOverlay;
