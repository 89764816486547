export const CHECKOUT_DATE_FORMAT = 'dddd D/M, HH:mm';

export const CHECKOUT_STEPS = {
  FINANCING: 0,
  BUYER: 1,
  TRANSPORT: 2,
  SUMMARY: 3,

  0: 'FINANCING',
  1: 'OWNER_DETAILS',
  2: 'DELIVERY',
  3: 'SUMMARY'
};

export enum PICKUP_LOCATIONS {
  CURRENT_FACILITY = 'CURRENT_FACILITY',
  FACILITY = 'FACILITY',
  LOCATION = 'LOCATION',
  DELAYED_BOOKING = 'DELAYED_BOOKING/OWN_TRANSPORT',
  TRANSPORT_QUOTE = 'QUOTE_OF_TRANSPORT',
  SELLER_LOCATION = 'SELLER_LOCATION'
}

export enum PICKUP_PERSON {
  ME,
  SOMEONE_ELSE
}

export type WarrantyMonths = 6 | 12 | 18 | 24 | 36 | null;
