import {
  CheckoutFlowType,
  CheckoutType
} from '~/App/shared/types/CheckoutType';
import dayjs from 'dayjs';
import { formatMoney } from '~/helpers/currency';
import { TranslateFunction } from '~/Locale';
import { Media } from '~/App/shared/interfaces/ProcessObject';
import { CheckoutData } from '~/App/shared/interfaces/store/CmsData';
import logger from '~/helpers/logger';

export const getHeaderTitle = (
  cmsData: CheckoutData,
  checkoutType: CheckoutType
) => {
  switch (checkoutType) {
    case 'AUCTION':
      return cmsData?.auctionTitle ?? '';

    case 'BUY_NOW':
    default:
      return cmsData?.title ?? '';
  }
};

export const getDisabledDays = (
  availableDays: dayjs.Dayjs[],
  firstBookableDay?: dayjs.Dayjs,
  lastBookableDay?: dayjs.Dayjs
) => {
  let day = firstBookableDay;
  const disabledDays: Date[] = [];

  if (day && lastBookableDay) {
    while (day <= lastBookableDay) {
      if (!availableDays.find(availableDay => availableDay.isSame(day))) {
        disabledDays.push(new Date(day.format('YYYY-MM-DD')));
      }

      day = day.add(1, 'days');
    }
  }

  return disabledDays;
};

export const getCarDealerCheckoutSummaryStr = (
  extraTiresPrice: number,
  orderMediationFee: string,
  price: string,
  stringToPay: string,
  stringSEK: string
): string => {
  return `${stringToPay} ${formatMoney({
    value: extraTiresPrice + parseInt(price) + parseInt(orderMediationFee),
    currency: stringSEK
  })}`;
};

export const isHeavyEquipmentFlow = (
  checkoutFlow: CheckoutFlowType | undefined
) => {
  return checkoutFlow === 'HEAVY_EQUIPMENT';
};

interface TranslateVehicle {
  t: TranslateFunction;
  checkoutFlow?: CheckoutFlowType;
  definitive?: boolean;
}

export const translateVehicleType = ({
  t,
  checkoutFlow,
  definitive
}: TranslateVehicle) => {
  switch (checkoutFlow) {
    case 'GENERAL':
    case 'SWEDISH_CAR_DEALERS':
    default:
      if (definitive) {
        return t('the car');
      }
      return t('car');
    case 'HEAVY_EQUIPMENT':
      if (definitive) {
        return t('the vehicle');
      }
      return t('vehicle');
  }
};

export const capitalizeStr = (str: string) => {
  try {
    return str.charAt(0).toUpperCase() + str.slice(1) ?? '';
  } catch (error) {
    logger.error(error);
    return str;
  }
};

export const getObjectImage = (media: Media[], index = 0) => {
  return media.filter(
    m => m.contentType === 'image' && m.type === 'PRESENTATION'
  )?.[index];
};

/**
 * Returns Heavy Equipment contact details if it's a heavy equipment flow.
 * @param t - TranslateFunction
 * @param isHeavyEquipmentFlow - boolean
 * @returns {phone: string | undefined, email: string | undefined}
 */
export const getHeavyEquipmentContactDetails = (
  t: TranslateFunction,
  isHeavyEquipmentFlow: boolean
) => {
  const phone = isHeavyEquipmentFlow ? t('KVDPRO_SUPPORT_PHONE') : undefined;
  const email = isHeavyEquipmentFlow ? t('KVDPRO_SUPPORT_EMAIL') : undefined;

  return { phone, email };
};
