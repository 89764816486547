import { sprintf } from 'sprintf-js';
import { log } from '~/helpers/bugsnagHelper';
import { UseQueryArgs } from 'urql';
import { createGraphqlClient } from '~/config/graphqlClient';
import { CmsDataStoreKey } from '~/App/shared/interfaces/store/CmsData';
import { cmsDataByStoreKeySelector } from '~/App/shared/selectors/cmsData';
import {
  getCmsDataHandler,
  setCmsData
} from '~/App/shared/actions/cmsDataActions';
import { ReduxDispatch } from '~/App/shared/interfaces/Redux';
import { ReduxStore } from '~/App/shared/interfaces/store';

import { StampData } from '~/App/shared/interfaces/PromoSettingCMSData';
import { StampRecord } from '~/config/generated/graphql';
import { Color, StampType, Variant } from '@kvdbil/components/types/Types';
import {
  CustomRecord,
  StructuredTextData
} from '~/App/shared/interfaces/datoCMS';
import { GraphQLRequestParams } from '@urql/core/dist/urql-core-chunk';
import logger from '~/helpers/logger';

export const getFormattedText = (
  template?: string | null,
  ...args: (string | null | undefined)[]
) => {
  if (!template || typeof template !== 'string') return null;

  try {
    return sprintf(template, ...args);
  } catch (error: unknown) {
    log(
      new Error(
        `Error in cmsDato string formating (sprintf) for template: ${template}`
      ),
      {
        error
      }
    );
  }
  return template;
};

export const getFormattedStructuredText = (
  template?: StructuredTextData | null,
  ...args: (string | null | undefined)[]
): StructuredTextData | null => {
  if (!template || typeof template !== 'object') return null;

  const newTemplate = { ...template };

  try {
    const formattedValueChildren: Record<string, unknown>[] =
      template?.value?.document?.children?.map(
        (children1: { children?: { value?: string }[] }) => {
          const formattedChildren = children1?.children?.map(children2 => {
            return children2?.value
              ? {
                  ...children2,
                  value: getFormattedText(children2?.value, ...args)
                }
              : children2;
          });

          return formattedChildren
            ? { ...children1, children: formattedChildren }
            : children1;
        }
      );

    const formattedBlocks = (template?.blocks as { text?: string }[])?.map(
      block => {
        return (
          block?.text
            ? { ...block, text: getFormattedText(block?.text, ...args) }
            : block
        ) as CustomRecord;
      }
    );

    if (newTemplate?.value?.document?.children) {
      newTemplate.value.document.children = formattedValueChildren;
    }
    if (newTemplate?.blocks) {
      newTemplate.blocks = formattedBlocks;
    }
  } catch (e) {
    log(
      new Error('Error occurred during processing getFormattedStructuredText'),
      {
        severity: 'error',
        e
      }
    );
  }

  return newTemplate;
};

type GetSingleRecordProps = {
  query: UseQueryArgs['query'];
  variables?: GraphQLRequestParams['variables'];
  useExternalCaching?: boolean;
};

export const getSingleRecord = async <QueryResp>({
  query,
  variables = {},
  useExternalCaching
}: GetSingleRecordProps): Promise<QueryResp | undefined> => {
  let response: QueryResp | undefined = undefined;
  const client = createGraphqlClient({ useExternalCaching });

  try {
    const { data } = await client
      .query<QueryResp>(query, {
        ...variables
      })
      .toPromise();

    response = data;
  } catch (error: unknown) {
    log(new Error('Error occurred during processing getAllRecors'), {
      severity: 'error',
      error
    });
  }

  return response;
};

type RespKey<T> = { parentKey: keyof T; childKeys?: string[] };

type GetAllRecordsProps<QueryResp> = {
  query: UseQueryArgs['query'];
  respKeys: RespKey<QueryResp>[];
  limit?: number;
  variables?: GraphQLRequestParams['variables'];
  useExternalCaching?: boolean;
};

/*
In order to get all existing records, the query must contain
"limit" and "skip" variables definition ($limit: IntType!, $skip: IntType!)
*/
export const getAllRecords = async <QueryResp>({
  query,
  limit = 100,
  respKeys,
  variables = {},
  useExternalCaching
}: GetAllRecordsProps<QueryResp>): Promise<QueryResp | undefined> => {
  let skip = 0;
  let keepQuerying = true;
  let response: QueryResp | undefined = undefined;
  const client = createGraphqlClient({
    useExternalCaching
  });

  while (keepQuerying) {
    try {
      const { data } = await client
        .query<QueryResp>(query, {
          limit,
          skip,
          ...variables
        })
        .toPromise();

      if (!response) {
        response = data;
      } else {
        respKeys?.forEach(respKey => {
          const parentKey = respKey.parentKey;
          const updatedResponse = {
            ...response,
            [parentKey]: [
              ...((response?.[parentKey] ?? []) as unknown[]),
              ...((data?.[parentKey] ?? []) as unknown[])
            ]
          } as QueryResp;

          response = updatedResponse;
        });
      }

      skip += limit;

      const isDone = respKeys?.every(respKey => {
        const isDoneForParentKeys =
          ((data?.[respKey.parentKey] ?? []) as unknown[]).length < limit;
        return isDoneForParentKeys;
      });

      if (isDone) {
        keepQuerying = false;
      }
    } catch (error: unknown) {
      logger.error(error);
      log(new Error('Error occurred during processing getAllRecors'), {
        severity: 'error',
        error
      });
      keepQuerying = false;
    }
  }

  return response;
};

type GetCmsDataProps<QueryResp> = {
  dispatch: ReduxDispatch;
  getState: () => ReduxStore;
  storeKey: CmsDataStoreKey;
  storeNestedKey?: string;
  query: UseQueryArgs['query'];
  respKeys?: RespKey<QueryResp>[];
  variables?: GraphQLRequestParams['variables'];
  options?: { manyRecords?: boolean; useStore?: boolean };
};

export const getCmsData = async <QueryResp, StoreData>({
  dispatch,
  getState,
  storeKey,
  storeNestedKey,
  query,
  respKeys,
  variables,
  options = {}
}: GetCmsDataProps<QueryResp>): Promise<StoreData | undefined> => {
  const cmsData = cmsDataByStoreKeySelector<StoreData>(
    storeKey,
    storeNestedKey
  )(getState());

  if (cmsData) {
    return cmsData;
  }

  const { value: cmsProxyCaching } =
    getState()?.featureFlags?.flags?.['cms-proxy-caching'] ?? {};

  let queryData: QueryResp | undefined;
  const { manyRecords = false, useStore = true } = options;
  if (!manyRecords) {
    queryData = await getSingleRecord<QueryResp>({
      query,
      variables,
      useExternalCaching: Boolean(cmsProxyCaching)
    });
  } else {
    queryData =
      respKeys &&
      (await getAllRecords<QueryResp>({
        query,
        respKeys,
        variables,
        useExternalCaching: Boolean(cmsProxyCaching)
      }));
  }

  if (queryData) {
    useStore && dispatch(setCmsData(storeKey, queryData, storeNestedKey));
    return getCmsDataHandler(storeKey)(queryData) as unknown as StoreData;
  }
};

export const getStamp = (stamp: Partial<StampRecord>): StampData | null => {
  if (!stamp) return null;
  if (!stamp.stampType) return null;
  if (!stamp.stampColor) return null;
  if (!stamp.stampAppearance) return null;

  return {
    stampType: stamp.stampType as StampType,
    stampColor: stamp.stampColor as Color,
    stampAppearance: stamp.stampAppearance as Exclude<Variant, 'flat'>,
    stampSize: (stamp.stampSize as number) ?? undefined
  };
};

export const isCustomRecordArray = (
  blocks: CustomRecord[] | string[] = []
): blocks is CustomRecord[] =>
  Boolean(
    (blocks as CustomRecord[]).find((block: CustomRecord | string) => {
      return typeof block === 'string';
    })
  ) === false;
