import { Member } from '~/App/shared/interfaces/Member';
import { Address } from '~/App/shared/interfaces/Address';
import { Session } from '~/App/shared/interfaces/store/Session';
import { isEmpty } from '~/App/shared/utils';
import { Person } from '~/App/shared/interfaces/Person';

export const hasForeignConnections = (session?: Session) => {
  const { person, addresses = [] } = session?.member ?? {};
  const { registrationCountry } = person?.organisation ?? {};

  if (
    typeof registrationCountry === 'undefined' ||
    registrationCountry === 'se'
  ) {
    const memberCountry = addresses[0]?.country;
    const { nationalIdentificationNumber } = person ?? {};

    if (!memberCountry && nationalIdentificationNumber) {
      return false;
    }

    return memberCountry !== 'se';
  }

  return true;
};

export const isLoggedIn = (session?: Session): boolean => {
  return Boolean(session?.isAuthenticated) && Boolean(session?.auth?.idToken);
};

export const getPersonFromObject = (defaultState?: Member) => {
  if (defaultState?.person) {
    const { addresses = [] } = defaultState;
    const address = addresses[0] ?? {};
    return {
      address: {
        address: address.address1 ?? '',
        zipCode: address.zipCode ?? '',
        city: address.city ?? '',
        country: address.country ?? ''
      },
      nationalIdentificationNumber:
        defaultState.person.nationalIdentificationNumber ?? '',
      phoneNumber: defaultState.person.phoneNumber ?? ''
    };
  }

  return {
    address: {
      address: '',
      zipCode: '',
      city: ''
    },
    nationalIdentificationNumber: '',
    phoneNumber: ''
  };
};

export const checkAddressValidity = (address: Address) => {
  const { country, address1, zipCode, city } = address;

  return (
    Boolean(city) && Boolean(zipCode) && Boolean(address1) && Boolean(country)
  );
};

export const getIsAccountComplete = (session?: Session) => {
  if (!session?.member || isEmpty(session?.member)) {
    return false;
  }

  const { memberType, person, addresses = [] } = session.member;
  const isOrganisationPerson = memberType === 'ORGANISATION_PERSON';
  const address = addresses?.find(checkAddressValidity);

  const { phoneNumber, nationalIdentificationNumber } = person ?? {};
  const { organisationNumber, vatNumber } = person?.organisation ?? {};

  const { country } = addresses.find(address => address.country) ?? {};
  const hasCountry = Boolean(country);
  const hasPhoneNumber = Boolean(phoneNumber);
  const hasNationalIdentificationNumber = Boolean(nationalIdentificationNumber);

  if (isOrganisationPerson) {
    const hasVatNumber = Boolean(vatNumber);
    const hasOrganisationNumber = Boolean(organisationNumber);

    const hasValidVatOrOrg =
      country === 'se' ? hasOrganisationNumber : hasVatNumber;

    return hasPhoneNumber && hasValidVatOrOrg && Boolean(address);
  }

  const isForeginMember = hasCountry && country !== 'se';
  return hasPhoneNumber && (hasNationalIdentificationNumber || isForeginMember);
};

export const getIsPrivateAccountDetailsComplete = (
  address: Address | undefined
) => {
  return Boolean(address && checkAddressValidity(address));
};

export const getIsOrgAccountDetailsComplete = (
  address: Address | undefined,
  person: Person | undefined
) => {
  const isMainAddressComplete = address && checkAddressValidity(address);
  if (!isMainAddressComplete) {
    return false;
  }

  const { organisation, phoneNumber } = person ?? {};

  const isSwedishOrg = address?.country === 'se';
  if (isSwedishOrg) {
    return Boolean(phoneNumber && organisation?.organisationNumber);
  }

  return Boolean(
    phoneNumber && organisation?.organisationNumber && organisation?.vatNumber
  );
};

export const isCarDealer = (member?: Member) => {
  return member?.person?.isCarDealer ?? false;
};
