import React from 'react';
import styled from 'styled-components';
import Link from '~/App/shared/components/Link';
import { getContactInfo } from '~/helpers/contactButton';
import {
  mq,
  resetButtonStyle,
  CloseIcon,
  PhoneIcon,
  MailIcon,
  ArrowRightIcon,
  ChatIcon
} from '@kvdbil/components';
import { SalesFlowType } from '~/App/shared/types/SalesFlowType';
import { useTranslation } from '~/Locale';
import { useDynamicScript } from '~/App/shared/hooks/useDynamicScript';

interface Props {
  isHidden: boolean;
  openChat(): void;
  salesFlow?: SalesFlowType;
  location: string;
}

interface OptionProps {
  isDisabled?: boolean;
}

const Option = styled.div<OptionProps>`
  display: flex;
  align-items: center;
  margin: 1.25rem 0;
  font-size: 1rem;
  cursor: pointer;

  ${({ isDisabled, theme }) =>
    isDisabled &&
    `
    color: ${theme.colors.gray.light2};
  `}

  & > svg {
    font-size: 1.25rem;
    margin-right: 1rem;
  }

  ${mq('mobileM')} {
    margin: 1.5rem 0;

    & > svg {
      margin-right: 2rem;
    }
  }
`;

const Close = styled.button`
  ${resetButtonStyle};
  text-align: right;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 1.25rem;
  margin: 1.25rem;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.gray.dark4};
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const ChatText = styled.button`
  ${resetButtonStyle};
`;

const ContactDetails = ({ salesFlow, isHidden, openChat, location }: Props) => {
  const { t } = useTranslation();
  const { telephoneNumber, email } = getContactInfo(t, salesFlow, location);
  const linkNumber = telephoneNumber.replace(/[^\d]/g, '');
  useDynamicScript('https://static-chat.kundo.se/chat-js/org/890/widget.js');

  return (
    <div hidden={isHidden}>
      <Close>
        <CloseIcon />
      </Close>

      <Option>
        <PhoneIcon />
        <Link to={`tel:${linkNumber}`}>{telephoneNumber}</Link>
      </Option>

      <Option>
        <MailIcon />
        <StyledLink to={`mailto:${email}`}>
          {email}
          <ArrowRightIcon />
        </StyledLink>
      </Option>

      <Option>
        <ChatIcon />
        <ChatText onClick={openChat}>
          {t('Open chat')} <ArrowRightIcon />
        </ChatText>
      </Option>
    </div>
  );
};

export default ContactDetails;
