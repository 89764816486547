import { mq } from '@kvdbil/components';
import React, { MutableRefObject, ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.ul<{ isSlider: boolean }>`
  display: ${({ isSlider }) => (isSlider ? 'inline-flex' : 'flex')};
  flex-wrap: ${({ isSlider }) => (isSlider ? 'nowrap' : 'wrap')};
  margin: ${({ isSlider }) => (isSlider ? '-0.5rem 0' : '-0.5rem')};
  width: inherit;

  ${({ isSlider }) =>
    isSlider &&
    `
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
    z-index: 2;
    
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    /* Hide scrollbar for IE, Edge and Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    
    ${mq(null, 'mobileL')} {
      scroll-snap-type: x mandatory;
      scroll-padding: 0 0;
      padding-left: 1.4rem;
      padding-right: 1.4rem;
    }
    
  `}
`;

export interface VehicleCardListProps {
  children: ReactNode;
  isSlider?: boolean;
  innerRef?: MutableRefObject<HTMLUListElement | null>;
  onScroll?: () => void;
}

export const VehicleCardListContainer = ({
  children,
  isSlider = false,
  innerRef,
  onScroll
}: VehicleCardListProps) => {
  return (
    <Container
      isSlider={isSlider}
      ref={innerRef}
      onScroll={onScroll}
      data-testid="vehicle-cards-container"
    >
      {children}
    </Container>
  );
};

export default VehicleCardListContainer;
