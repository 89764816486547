import { GA4Event } from '~/helpers/client/ga4TrackEvent';
import { useDebounce } from './useDebounce';

export const DEFAULT_GA4_DEBOUNCE_DELAY = 100;

export const useDebounceGA4Event = (delay = DEFAULT_GA4_DEBOUNCE_DELAY) => {
  const debounceGA4Event = useDebounce(
    (viewEventCallback: (...args: unknown[]) => void, ...args: unknown[]) => {
      if (
        window.dataLayer?.some(
          (e: GA4Event) => e.event === 'page_view' || 'site_search'
        )
      ) {
        viewEventCallback(...args);
      }
    },
    delay
  );

  return debounceGA4Event;
};
