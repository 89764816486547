import {
  ToggleSearchParamKey,
  toggleSearchParams,
  IntervalSearchParamLabelKey,
  MultiselectSearchParamKey,
  multiselectSearchParams,
  OtherSearchParamKey,
  otherSearchParams,
  FilterSearchParamKey,
  filterSearchParamValues,
  FilterSearchParamsValue,
  OrderSearchParamKey,
  intervalSearchParamKeys,
  IntervalSearchParamKey,
  termsSearchParams,
  TermsSearchParamKey
} from '~/App/shared/types/FilterSearchParam';
import {
  MainCategory,
  mainCategoryParams
} from '~/App/shared/types/MainCategoryTypes';

export const isFilterSearchParamKey = (
  key: string | FilterSearchParamKey
): key is FilterSearchParamKey => {
  if (isToggleSearchParamKey(key)) {
    return true;
  }

  if (isMultiselectSearchParamKey(key)) {
    return true;
  }

  if (isIntervalSearchParamKey(key)) {
    return true;
  }

  if (isOtherSearchParams(key)) {
    return true;
  }

  if (isTermsSearchParamKey(key)) {
    return true;
  }

  return false;
};

export const isFilterSearchParamValue = (
  value: string | FilterSearchParamsValue
): value is FilterSearchParamsValue => {
  return filterSearchParamValues.includes(value as FilterSearchParamsValue);
};

export const isToggleSearchParamKey = (
  key: string | FilterSearchParamKey
): key is ToggleSearchParamKey => {
  return Object.keys(toggleSearchParams).includes(key);
};

export const isMultiselectSearchParamKey = (
  key: string | FilterSearchParamKey
): key is MultiselectSearchParamKey => {
  return Object.keys(multiselectSearchParams).includes(
    key as MultiselectSearchParamKey
  );
};

export const isIntervalSearchParamKey = (
  key: string | IntervalSearchParamKey
): key is IntervalSearchParamKey => {
  return intervalSearchParamKeys.includes(key as IntervalSearchParamKey);
};

export const isIntervalSearchParamLabelKey = (
  key: string | IntervalSearchParamKey
): key is IntervalSearchParamKey => {
  return intervalSearchParamKeys
    .map(key => getKeyWithoutFromOrTo(key))
    .includes(key as IntervalSearchParamLabelKey);
};

export const getKeyWithoutFromOrTo = (
  key: IntervalSearchParamKey
): IntervalSearchParamLabelKey => {
  return key
    .replace('From', '')
    .replace('To', '') as IntervalSearchParamLabelKey;
};

export const isOtherSearchParams = (
  key: string | FilterSearchParamKey
): key is OtherSearchParamKey => {
  return Object.keys(otherSearchParams).includes(key as OtherSearchParamKey);
};

export const isOrderSearchParamKey = (
  key: string | OrderSearchParamKey
): key is OrderSearchParamKey => {
  return key === 'orderBy';
};

export const isMainCategory = (
  value?: string | FilterSearchParamsValue | MainCategory | null
): value is MainCategory => {
  return mainCategoryParams.includes(value as MainCategory);
};

export const isTermsSearchParamKey = (
  key: string | FilterSearchParamKey
): key is TermsSearchParamKey => {
  return key === termsSearchParams;
};
