import { AxiosError, AxiosResponse } from 'axios';
import {
  FinancingPostData as FinancingPost,
  PaymentMethod
} from '~/App/shared/actions/checkoutActions';
import { DeliveryLocation } from '~/App/shared/interfaces/DeliveryLocation';
import { FacilityTransportCost } from '~/App/shared/interfaces/FacilityTransportCost';
import {
  CheckoutBuyer,
  CheckoutOrder,
  PickupProxyPerson,
  PickupProxyTransportCompany
} from '~/App/shared/interfaces/store/Checkout';
import { CheckoutType } from '~/App/shared/types/CheckoutType';
import { PICKUP_LOCATIONS } from '~/App/views/Checkout/constants';
import { orchestration } from '~/config/public';
import axios from '~/helpers/orchestration/axiosWithAuthHandling';

interface StartCheckoutOrder {
  idToken?: string | undefined;
  auctionId: string;
  checkoutType: CheckoutType;
}

export const startCheckoutOrder = async ({
  idToken,
  auctionId,
  checkoutType
}: StartCheckoutOrder) => {
  return await axios.post<{ order: CheckoutOrder }>(
    `${orchestration.url}/delivery/orders/start`,
    {
      start: {
        auctionId,
        checkoutType
      }
    },
    {
      responseType: 'json',
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }
  );
};

export const getCheckoutOrderByAuctionId = async ({
  idToken,
  auctionId,
  checkoutType
}: StartCheckoutOrder) => {
  return await axios.get<{ order: CheckoutOrder }>(
    `${orchestration.url}/delivery/orders/by_auction_id`,
    {
      params: {
        checkoutType,
        auctionId
      },
      responseType: 'json',
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }
  );
};

interface GetOrderRequest {
  orderId: string;
  idToken: string;
  authToken?: string;
}
interface GetOrderResponse {
  order: CheckoutOrder;
}
export const getOrder = ({ orderId, idToken, authToken }: GetOrderRequest) => {
  return axios.get<GetOrderResponse>(
    `${orchestration.url}/delivery/orders/${orderId}`,
    {
      withCredentials: !authToken,
      params: { authToken },
      headers: {
        Authorization: !authToken && `Bearer ${idToken}`
      }
    }
  );
};

interface GetOrderIdRequest {
  idToken: string;
  auctionId: string;
  orderType: CheckoutType;
}
export const getOrderIdFromAuction = async ({
  auctionId,
  idToken,
  orderType
}: GetOrderIdRequest) => {
  return await axios.post(
    `${orchestration.url}/delivery/order`,
    {
      auctionId,
      orderType
    },
    {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }
  );
};

export type GetHomeDeliveryCostResponse =
  | { available: false }
  | {
      available: true;
      homeDeliveryCost: string;
      missingMeasurement: boolean;
      toFacilityId: string | null;
      toFacilityName: string | null;
      transportToFacilityCost: number;
    };

export interface CheckoutHomeDelivery {
  available: boolean;
  missingMeasurement?: boolean;

  toFacilityId?: string | null;
  toFacilityName?: string | null;

  homeDeliveryCost?: number;
  transportToFacilityCost?: string;
}

export const getHomeDeliveryCost = async (
  orderId: number,
  address: DeliveryLocation,
  idToken?: string
) => {
  return await axios.post<GetHomeDeliveryCostResponse>(
    `${orchestration.url}/delivery/orders/${orderId}/transport-cost/home-delivery`,
    {
      homeDeliveryCost: address
    },
    {
      responseType: 'json',
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }
  );
};

export const updateCheckoutFinancing = async (
  orderId: number,
  idToken: string,
  financing: FinancingPost
) => {
  return await axios.post<{ order: CheckoutOrder }>(
    `${orchestration.url}/delivery/orders/${orderId}/financing`,
    {
      financing
    },
    {
      responseType: 'json',
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }
  );
};

export const updateCheckoutHeavyEquipmentFinancing = async (
  orderId: number,
  idToken: string,
  data: { paymentMethod: PaymentMethod; interestInFinancing: boolean }
) => {
  return await axios.post<{ order: CheckoutOrder }>(
    `${orchestration.url}/delivery/orders/${orderId}/financing`,
    {
      financing: {
        paymentMethod: data.paymentMethod,
        interestInFinancing: data.interestInFinancing,
        warranty: null,
        insurance: null,
        warrantyPeriod: null,
        serviceAgreement: null
      }
    },
    {
      responseType: 'json',
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }
  );
};

export interface CheckoutConfigureCarDealerPayload {
  introduction: {
    shownToOrderingMember: boolean;
    acceptsExtraTires: boolean;
  };
}

export const updateCheckoutConfigureCarDealer = async (
  orderId: number,
  idToken: string,
  payload: CheckoutConfigureCarDealerPayload
) =>
  await axios.post<{ order: CheckoutOrder }>(
    `${orchestration.url}/delivery/orders/${orderId}/introduction`,
    payload,
    {
      responseType: 'json',
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }
  );

export const updateCheckoutBuyer = async (
  orderId: number,
  idToken: string,
  buyer: CheckoutBuyer
) => {
  return await axios.post<{ order: CheckoutOrder }>(
    `${orchestration.url}/delivery/orders/${orderId}/buyer`,
    {
      buyer
    },
    {
      responseType: 'json',
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }
  );
};

interface DeliveryFacility {
  destinationFacilityId: string;
}

export interface DeliveryHeavyEquipment {
  pickupMethod: PICKUP_LOCATIONS;
}

export const updateCheckoutDelivery = (
  orderId: number,
  idToken: string,
  transport:
    | { deliveryLocation: DeliveryLocation | null }
    | DeliveryFacility
    | DeliveryHeavyEquipment
) => {
  return axios.post<{ order: CheckoutOrder }>(
    `${orchestration.url}/delivery/orders/${orderId}/transport`,
    {
      transport
    },
    {
      responseType: 'json',
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }
  );
};
export const updateCheckoutPickupBy = (
  orderId: number,
  idToken: string,
  pickupByBuyer: boolean
) => {
  return axios.post(
    `${orchestration.url}/delivery/orders/${orderId}/pickup`,
    {
      pickup: { pickupByBuyer }
    },
    {
      responseType: 'json',
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }
  );
};

type UpdatePickupProxyResponse = {
  order: {
    pickupProxy: PickupProxyPerson;
  };
};

export interface SetPickupPersonCarDealerInput {
  authToken?: string;
  deliveryPolicyAccepted?: boolean;
  pickupProxyPolicyAccepted?: boolean;
  pickupProxyPerson: PickupProxyPerson | null;
  pickupProxyTransportCompany: PickupProxyTransportCompany | null;
}
export const updatePickupProxy = (
  orderId: number,
  idToken: string,
  data: SetPickupPersonCarDealerInput
) => {
  return axios.post<UpdatePickupProxyResponse>(
    `${orchestration.url}/delivery/orders/${orderId}/pickup-proxy`,
    data,
    {
      responseType: 'json',
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }
  );
};

export const getTransportCosts = (
  orderId: number | string,
  idToken: string
) => {
  return axios
    .get<FacilityTransportCost[]>(
      `${orchestration.url}/delivery/orders/${orderId}/transport_cost/facilities`,
      {
        responseType: 'json',
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      }
    )
    .then(res => {
      return res?.data;
    });
};

export type WarrantyChoice = {
  paymentMethod: PaymentMethod;
  price: number;
  period: number;
  originalPrice: number;
  monthlyCost?: null | number;
};

export type CheckoutPricesResponse = AxiosResponse & {
  INSURANCE: {
    available: boolean;
    included: boolean;
    price?: number;
  };
  SERVICE_AGREEMENT: {
    available: boolean;
    included: boolean;
    price?: number;
  };
  WARRANTY: {
    available: boolean;
    included: boolean;
    choices: WarrantyChoice[];
  };
};

export interface FetchCheckoutPricesInput {
  orderId: string;
  idToken: string;
  paymentMethod: PaymentMethod;
  paymentPeriod: number;
  downPaymentPercent: number;
}
export const fetchCheckoutPrices = ({
  orderId,
  idToken,
  paymentMethod,
  paymentPeriod,
  downPaymentPercent
}: FetchCheckoutPricesInput) => {
  const paymentPeriodKey =
    paymentMethod === 'LOAN' ? 'loanPeriod' : 'leasingPeriod';

  return axios.get<CheckoutPricesResponse>(
    `${orchestration.url}/delivery/orders/${orderId}/service_availability`,
    {
      params: {
        paymentMethod,
        [paymentPeriodKey]:
          paymentMethod === 'CASH' ? undefined : paymentPeriod,
        downPaymentPercent
      },
      responseType: 'json',
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }
  );
};

export const checkoutSubmit = async (
  orderId: number,
  idToken: string,
  submit: Record<string, unknown>
) => {
  return await axios.post<{ order: CheckoutOrder }>(
    `${orchestration.url}/delivery/orders/${orderId}/submit`,
    {
      submit
    },
    {
      responseType: 'json',
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }
  );
};

export interface LeasingCalculator {
  kmPerYear: number;
  leasingPeriod: number;
}
export const getLeasingCost = async (
  orderId: number,
  idToken: string,
  leasingCalculator: LeasingCalculator
) => {
  return await axios.post<CheckoutOrder>(
    `${orchestration.url}/delivery/orders/${orderId}/calculator/leasing`,
    {
      leasingCalculator
    },
    {
      responseType: 'json',
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }
  );
};

export interface GetLoanCostInput {
  orderId: number;
  idToken: string;
  price: number;
  downPayment: number;
  loanPeriod: number;
  residualValuePercentage: number;
}
export const getLoanCost = async ({
  idToken,
  orderId,
  ...data
}: GetLoanCostInput) => {
  return await axios.post<CheckoutOrder>(
    `${orchestration.url}/delivery/orders/${orderId}/calculator/loan`,
    {
      loanCalculator: data
    },
    {
      responseType: 'json',
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }
  );
};

export type CheckoutError = unknown | AxiosError;
