import { pick, uniq, take, concat } from 'ramda';

import {
  AddViewedAuctionAction,
  HydrateViewedAuctionsAction
} from '~/App/shared/actions/recently-viewed-auctions';
import Auction from '../interfaces/Auction';
import { RecentlyViewedAuctions } from '../interfaces/store/RecentlyViewedAuctions';

const addViewedAuction = (auctionIds: string[], auction: Auction) => {
  if (!auction) {
    return auctionIds;
  }

  const tempState = auctionIds;
  const i = auctionIds.findIndex(item => item === auction.id);

  if (i !== -1) {
    tempState.splice(i, 1);
  }

  return [auction.id, ...tempState].slice(0, 10);
};

type RecentlyViewedAuctionAction =
  | AddViewedAuctionAction
  | HydrateViewedAuctionsAction;

const initialState = {
  allIds: [],
  byId: {},
  isHydrated: false
};

const createAuctionsObject = (
  existingIds: Record<string, Auction>,
  savedIds: Record<string, Auction>,
  includedIds: string[]
): Record<string, Auction> => {
  const byIdsWithNewAuction = { ...existingIds, ...savedIds };
  return pick(includedIds, byIdsWithNewAuction);
};

const reducer = (
  state: RecentlyViewedAuctions = initialState,
  action: RecentlyViewedAuctionAction
): RecentlyViewedAuctions => {
  switch (action.type) {
    case 'ADD_VIEWED_AUCTION': {
      if (!action.payload) {
        return state;
      }

      const latestViewedAuctions = addViewedAuction(
        state.allIds,
        action.payload
      );

      const byIdsWithNewAuction = {
        ...state.byId,
        [action.payload.id]: action.payload
      };
      return {
        ...state,
        allIds: latestViewedAuctions,
        byId: pick(latestViewedAuctions, byIdsWithNewAuction)
      };
    }
    case 'HYDRATE_VIEWED_AUCTIONS': {
      const mergedIds = concat(state.allIds, action.payload.allIds);
      const checkedIds = take(10, uniq(mergedIds));

      return {
        allIds: checkedIds,
        byId: createAuctionsObject(state.byId, action.payload.byId, mergedIds),
        isHydrated: true
      };
    }
    default:
      return state;
  }
};

export default reducer;

// TODO: Convert to selector by Reselect
export const getAllRecentlyViewedAuctions = (state: RecentlyViewedAuctions) =>
  state.allIds.map(id => state.byId[id]);
