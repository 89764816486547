import { SavedSearchNotification } from '~/App/shared/interfaces/store/Notifications';

export const countSavedSearchNotifications = (
  savedSearchesNotifications: SavedSearchNotification[]
) => {
  const count = savedSearchesNotifications.filter(
    notification => notification.newAuctions.length > 0
  ).length;
  return count;
};
