import { I18N } from '../interfaces/Localization';
import Jed from 'jed';
import { LocaleData } from './loadLocaleOnServer';

const mock: I18N = {
  gettext: str => str,
  sprintf: (...args) => args[0]
};

let currentLocale: I18N = mock;

const getLocale = () => currentLocale;

export default function createLocale(locale: LocaleData): I18N {
  currentLocale = new Jed(locale);
  return currentLocale;
}

export { getLocale };
