import { Display3, Title } from '@kvdbil/components';

import styled, { keyframes, css } from 'styled-components';

const colorAnimationDark = keyframes`
  0% {
    background-color:#D9D9D9;
  }
  50%{
    background-color: #f2f2f2;
  }
  100% {
    background-color:#D9D9D9;
  }
`;

const colorAnimationLight = keyframes`
  0% {
    background-color:#f5f5f5;
  }
  
  50%{
    background-color: #e5e5e5;
  }
  
  100% {
    background-color:#f5f5f5; 
  }
`;

export const skeletonTextStyles = css`
  animation: ${colorAnimationDark} 2s linear infinite;
  color: transparent;
`;

export const SkeletonHeroHeading = styled(Display3)`
  ${skeletonTextStyles}
  width: 33%;
`;
export const SkeletonHeroSubHeading = styled(Title)`
  ${skeletonTextStyles}
  width: 23%;
  margin-top: 1rem;
`;

export const skeletonAnimation = css`
  animation: ${colorAnimationLight} 2s linear infinite;
`;

export const BodyTextSkeleton = styled.div<{ height?: number }>`
  height: ${({ height }) => height ?? 1}rem;
  ${skeletonAnimation}
`;

export const HeadlineSkeleton = styled(BodyTextSkeleton)`
  width: 10rem;
  height: 2rem !important;
  margin-bottom: 0;
`;
