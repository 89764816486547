import axios from 'axios';
import { MainCategory } from '~/App/shared/types/MainCategoryTypes';
import { url } from '~/config/public/orchestration';

export type AutoCompleteItem = {
  terms: string;
};

interface AutoCompleteSuggestion {
  suggestions: AutoCompleteItem[];
}
export const autoCompleteSearch = async (
  terms: string,
  mainCategory?: MainCategory
) => {
  const response = await axios.get<AutoCompleteSuggestion>(
    `${url}/auction/autocomplete`,
    {
      params: { terms, ...(mainCategory ? { vehicleType: mainCategory } : {}) },
      responseType: 'json'
    }
  );

  return response?.data.suggestions;
};
