import { useEffect } from 'react';
import { setViewingAuction } from '../shared/actions/viewingAuction';
import { connect } from 'react-redux';
import { Location } from 'history';
import { RouteComponentProps, withRouter } from 'react-router';
import { getIdFromSlug } from '~/helpers/auction';

interface Props extends RouteComponentProps {
  location: Location;
  setViewingAuction(auctionId: number): void;
}

const UpdateViewingAuction = ({ location, setViewingAuction }: Props) => {
  const { pathname } = location;
  const auctionId = getIdFromSlug(pathname);

  useEffect(() => {
    if (auctionId) {
      setViewingAuction(parseInt(auctionId, 10));
    }
  }, [auctionId, setViewingAuction]);

  return null;
};

const mapDispatchToProps = {
  setViewingAuction: setViewingAuction
};

export default withRouter(
  connect(null, mapDispatchToProps)(UpdateViewingAuction)
);
