/**
 * @deprecated TODO: [KVDBIL-1634] refactor...
 */

import {
  FromToNumber,
  FromToString
} from '~/App/shared/interfaces/AuctionSearchParams';
import { auctionListFacilities } from '~/App/shared/selectors/auctionListSelector';
import { MinMaxIntervalKey } from '~/config/constants';
import store from '~/config/store';
import { TranslateFunction } from '~/Locale';
import { formatMoneyWithoutCurrency } from './currency';
import {
  translatedFilterValue,
  TranslateLabels,
  translateIntervalValues
} from './filterTranslation';
import { MainCategory } from '~/App/shared/types/MainCategoryTypes';
import { getMinMaxIntervalValues } from '~/App/views/FilterPage/helpers';
import { SliderType } from '~/App/views/FilterPage/components/FilterSelector/types';

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export const translateFilterValueString = (
  filterValueString: string | Record<string, string>,
  t: TranslateFunction
): string => {
  if (typeof filterValueString === 'string') {
    const list = filterValueString.split(',');
    const translated = list.map(item => translatedFilterValue(t, item));

    if (list.length > 1) {
      return translated.join(' ,');
    }

    return translated[0];
  }

  if (typeof filterValueString === 'object') {
    return filterValueString.value;
  }

  return filterValueString;
};

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export type FilterType =
  | 'interval'
  | 'default'
  | 'vehicleType'
  | 'facilities'
  | 'currency';

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export interface Filter {
  key: string;
  value:
    | string
    | number
    | boolean
    | FromToNumber
    | FromToString
    | string[]
    | null;
  type: FilterType;
}

const getPrefix = (t: TranslateFunction, filter: Filter) => {
  switch (filter.key) {
    case 'numSeats':
      return TranslateLabels(t, filter.key);
    default:
      return '';
  }
};

const translatedFacilityValue = (value: string) => {
  const state = store.getState();
  const facilities = auctionListFacilities(state);
  const found = facilities.find(facility => facility.value === value);

  return (found && found.label) || '';
};

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export const getFilterTagLabel = (
  t: TranslateFunction,
  filter: Filter,
  mainCategory?: MainCategory
) => {
  if (typeof filter.value === 'string' && filter.value.includes(',')) {
    filter.value = filter.value.split(',');
  }
  // if interval filter value is a single value this should be ignored
  if (filter.type === 'interval' && Array.isArray(filter.value)) {
    const minMaxIntervalValues = getMinMaxIntervalValues(mainCategory);
    return translateIntervalValues(
      t,
      filter.key as MinMaxIntervalKey,
      filter.value as SliderType,
      minMaxIntervalValues
    );
  }

  if (filter.key === 'facilities' || filter.key === 'facility') {
    if (Array.isArray(filter.value)) {
      const facilities = (filter.value as string[])
        .map(facility => translatedFacilityValue(facility))
        .filter(x => x);
      return facilities.join(', ');
    }
    return translatedFacilityValue(filter.value ? String(filter.value) : '');
  }

  if (filter.key === 'terms') {
    return filter.value;
  }

  const translatedValue = TranslateLabels(t, filter.key);
  const ret = translatedValue === 'none' ? filter.value : translatedValue;
  const prefix = getPrefix(t, filter);

  if (Array.isArray(filter.value)) {
    const valueText = filter.value
      .map((filterValue: string | number | null) =>
        translatedFilterValue(t, filterValue)
      )
      .join(', ');

    return `${prefix} ${valueText}`;
  }

  if (typeof filter.value === 'string' && translatedValue === 'none') {
    return translatedFilterValue(t, filter.value);
  }

  if (typeof filter.value === 'number') {
    return `${ret} ${formatMoneyWithoutCurrency(filter.value)}`;
  }

  return ret;
};
