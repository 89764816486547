import { Action, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { io, Socket, ManagerOptions } from 'socket.io-client';
import { websocket } from '~/config/public';
import { updateMember } from '~/App/shared/actions/member';
import {
  AuctionClosedEvent,
  AuctionCloseTimeUpdatedEvent,
  AuctionCountdownStartedEvent
} from '~/App/shared/interfaces/events/AuctionEvent';
import { BidEvent } from '~/App/shared/interfaces/events/BidEvent';
import { ReleasingCostUpdatedEvent } from '~/App/shared/interfaces/events/ReleasingCostEvent';
import { ReduxStore } from '~/App/shared/interfaces/store';
import {
  auctionBidCancelled,
  auctionBidPlaced,
  auctionClosed,
  auctionCloseTimeUpdated,
  auctionCountdownStarted,
  auctionReleasingCostUpdated,
  timeSyncResponse,
  websocketConnected,
  websocketDisconnected,
  websocketReconnected
} from '~/App/shared/actions/connection-events';
import { verifyMemberEmail } from '~/App/shared/actions/session';
import { ActionWithPayload } from '~/App/shared/types/Action';
import { isDOM } from '~/App/shared/hooks/useDocumentVisible';

const connectParams: Partial<ManagerOptions> = {
  transports: ['websocket'],
  reconnectionDelay: 1000,
  reconnectionDelayMax: 30000,
  reconnectionAttempts: 200
};

type MiddlewareStore = MiddlewareAPI<
  Dispatch<Action<unknown>, ReduxStore>,
  ReduxStore
>;
const handleConnectionStatus = (store: MiddlewareStore, socket: Socket) => {
  socket.on('connect', () => {
    store.dispatch(websocketConnected());
  });

  // Error & disconnection handling
  socket.on('error', () => {
    store.dispatch(websocketDisconnected());
  });

  socket.on('connect_error', () => {
    store.dispatch(websocketDisconnected());
  });

  socket.on('disconnect', () => {
    store.dispatch(websocketDisconnected());
  });

  socket.on('connect_failed', () => {
    store.dispatch(websocketDisconnected());
  });

  socket.on('reconnect_failed', () => {
    store.dispatch(websocketDisconnected());
  });

  // reconnection
  socket.on('reconnect', () => {
    store.dispatch(websocketReconnected());
  });
};

const handleIndividualMessages = (store: MiddlewareStore, socket: Socket) => {
  socket.on('email_verified', () => {
    store.dispatch(verifyMemberEmail());
  });

  socket.on('member_updated', () => {
    void store.dispatch(updateMember());
  });
};

const handleMessages = (store: MiddlewareStore, socket: Socket) => {
  socket.on(
    'time_response',
    (payload: { clientTimeStart: number; serverTime: number }) => {
      store.dispatch(timeSyncResponse(payload));
    }
  );

  socket.on('auction.closed', (payload: AuctionClosedEvent) => {
    store.dispatch(auctionClosed(payload));
  });

  socket.on('auction.bid.placed', (payload: BidEvent) => {
    store.dispatch(auctionBidPlaced(payload));
  });

  socket.on(
    'customer_financing.releasing_cost_updated',
    (payload: ReleasingCostUpdatedEvent) => {
      store.dispatch(auctionReleasingCostUpdated(payload));
    }
  );

  socket.on('auction.bid.cancelled', (payload: BidEvent) => {
    store.dispatch(auctionBidCancelled(payload));
  });

  socket.on(
    'auction.countdown_started',
    (payload: AuctionCountdownStartedEvent) => {
      store.dispatch(auctionCountdownStarted(payload));
    }
  );

  socket.on(
    'auction.close_time_updated',
    (payload: AuctionCloseTimeUpdatedEvent) => {
      store.dispatch(auctionCloseTimeUpdated(payload));
    }
  );
};

type ActionWithRoutingKey = ActionWithPayload & { routingKey: string };

const socketMiddleware = (): Middleware<Record<string, string>, ReduxStore> => {
  const socket = io(websocket.url, connectParams);

  isDOM &&
    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        // the socket will not try to reconnect if browser tab is not active
        socket.disconnected && socket.disconnect();
      } else {
        // turns on the reconnection feature if the socket is disconnected and browser tab is active again
        socket.disconnected && socket.connect();
      }
    });

  return store => {
    handleMessages(store, socket);
    handleConnectionStatus(store, socket);
    handleIndividualMessages(store, socket);

    return (next: (action: ActionWithRoutingKey) => void) =>
      (action: ActionWithRoutingKey) => {
        // Emit a message on the websocket connection when requested by an action
        if (action.type === 'WEBSOCKET_SEND_MESSAGE') {
          socket.emit(action.routingKey, action.payload);
        }
        return next(action);
      };
  };
};

export { socketMiddleware };
