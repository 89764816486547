import { NavigationQuery } from '~/config/generated/graphql';

export const handleNavigationCmsData = ({ navigation }: NavigationQuery) => ({
  navigationTitle: navigation?.navigationTitle,
  primaryNavigation: navigation?.primaryNavigation,
  secondaryNavigationTitle: navigation?.secondaryNavigationTitle,
  secondaryNavigation: navigation?.secondaryNavigation,
  utilityBarNavigation: navigation?.utilityBarNavigation ?? [],
  loggedInNavigationTitle: navigation?.loggedInNavigationTitle,
  loggedInNavigation: navigation?.loggedInNavigation
});
