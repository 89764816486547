import { ReduxStore } from '../interfaces/store';
import {
  RemoveCmsDataAction,
  SetCmsDataAction
} from '~/App/shared/actions/cmsDataActions';

export const cmsData = (
  state = {} as ReduxStore['cmsData'],
  action: SetCmsDataAction | RemoveCmsDataAction
) => {
  switch (action.type) {
    case 'SET_CMS_DATA':
      return {
        ...state,
        [action.key]: action.nestedKey
          ? { ...state[action.key], [action.nestedKey]: action.payload }
          : action.payload
      };
    case 'REMOVE_CMS_DATA':
      return {
        ...state,
        [action.key]: action.nestedKey
          ? { ...state[action.key], [action.nestedKey]: undefined }
          : null
      };
    default:
      return state;
  }
};

export default cmsData;
