import logger from '~/helpers/logger';

declare global {
  interface Window {
    LOCALE: string;
  }
}

export const parseStringifiedLocaleData = (stringifiedLocaleData: string) => {
  const decodedLocale = unescape(stringifiedLocaleData);

  try {
    return JSON.parse(decodedLocale);
  } catch (error: unknown) {
    logger.error(error);

    return {};
  }
};

export default function rehydrateLocale() {
  const locale = window.LOCALE;

  return parseStringifiedLocaleData(locale);
}
