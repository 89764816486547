import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { BodyText, CloseIcon, resetButtonStyle } from '@kvdbil/components';
import { useTranslation } from '~/Locale';

const Container = styled.div`
  width: 15rem;
  padding: 1rem;
`;

const Close = styled.button`
  ${resetButtonStyle};
  text-align: right;
  position: absolute;
  right: 0;
  top: 0;
  margin: 1.25rem;
  font-size: 1.25rem;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.gray.dark1};
  }
`;

const StyledBodyText = styled(BodyText)`
  margin: 1rem 0;
`;

interface Props {
  isHidden: boolean;
  handleClose: MouseEventHandler<HTMLButtonElement>;
}

const Nudge = ({ handleClose, isHidden }: Props) => {
  const { t } = useTranslation();

  return (
    <Container hidden={isHidden} data-testid="nudge-container">
      <Close onClick={handleClose}>
        <CloseIcon />
      </Close>

      <StyledBodyText>
        {t('Hey! Do not hesitate to contact us if you have any questions.')}
      </StyledBodyText>

      <StyledBodyText>{t('Call, email, or chat. :)')}</StyledBodyText>
    </Container>
  );
};

export default Nudge;
