import { ReduxStore } from '~/App/shared/interfaces/store';
import { idToken } from '~/App/shared/selectors/sessionSelector';
import {
  resendPhoneVerification,
  verifyMemberPhone
} from '~/helpers/orchestration/verification';
import { updateMemberSimple } from '~/helpers/orchestration/member';
import { Member } from '../interfaces/Member';
import { ReduxDispatch } from '../interfaces/Redux';

export type VerifyPhonePayload = {
  member: Member;
};

export interface FailureResponse {
  response: {
    data: FailurePayload;
  };
}

export interface FailurePayload {
  message: {
    errorClass?: string;
    person?: {
      phoneNumber?: string;
    };
  };
}

export const resetPhoneVerification = () => ({
  type: 'RESET_PHONE_VERIFICATION' as const
});

export const sendVerificationCodeRequest = () => ({
  type: 'SEND_VERIFICATION_CODE_REQUEST' as const
});

export const sendVerificationCodeSuccess = () => ({
  type: 'SEND_VERIFICATION_CODE_SUCCESS' as const
});

export const sendVerificationCodeFailure = () => ({
  type: 'SEND_VERIFICATION_CODE_FAILURE' as const
});

export const verifyPhoneNumberRequest = () => ({
  type: 'VERIFY_PHONE_NUMBER_REQUEST' as const
});

export const verifyPhoneNumberSuccess = (payload: VerifyPhonePayload) => ({
  type: 'VERIFY_PHONE_NUMBER_SUCCESS' as const,
  payload
});

export const verifyPhoneNumberFailure = (payload: FailurePayload) => ({
  type: 'VERIFY_PHONE_NUMBER_FAILURE' as const,
  payload
});

export const updatePhoneNumberRequest = () => ({
  type: 'UPDATE_PHONE_NUMBER_REQUEST' as const
});

export const updatePhoneNumberSuccess = (payload: VerifyPhonePayload) => ({
  type: 'UPDATE_PHONE_NUMBER_SUCCESS' as const,
  payload
});

export const updatePhoneNumberFailure = (payload: FailurePayload) => ({
  type: 'UPDATE_PHONE_NUMBER_FAILURE' as const,
  payload
});

export const sendVerificationCode =
  (onSuccess?: () => void) =>
  (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const token = idToken(getState());
    dispatch(sendVerificationCodeRequest());

    resendPhoneVerification({ idToken: token })
      .then(() => {
        onSuccess && onSuccess();
        return dispatch(sendVerificationCodeSuccess());
      })
      .catch(() => dispatch(sendVerificationCodeFailure()));
  };

export const verifyPhoneNumber =
  ({ code, delay = 0 }: { code: string; delay: number }) =>
  (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const token = idToken(getState());

    dispatch(verifyPhoneNumberRequest());

    setTimeout(() => {
      verifyMemberPhone({ idToken: token, code })
        .then(({ data }) => dispatch(verifyPhoneNumberSuccess(data)))
        .catch(({ response }: FailureResponse) =>
          dispatch(verifyPhoneNumberFailure(response.data))
        );
    }, delay);
  };

export const updateMemberPhoneNumber =
  (newNumber: string) =>
  async (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();
    const token = idToken(state);

    dispatch(updatePhoneNumberRequest());

    return updateMemberSimple({
      data: { person: { phoneNumber: newNumber } },
      idToken: token
    })
      .then(({ data }: { data: VerifyPhonePayload }) =>
        dispatch(updatePhoneNumberSuccess(data))
      )
      .catch(({ response }: FailureResponse) =>
        dispatch(updatePhoneNumberFailure(response?.data))
      );
  };

export const updatePhoneNumberAndSendVerificationCode =
  (newNumber: string, onSuccess: () => void) =>
  (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const token = idToken(getState());
    dispatch(updatePhoneNumberRequest());

    updateMemberSimple({
      data: { person: { phoneNumber: newNumber } },
      idToken: token
    })
      .then(({ data }: { data: VerifyPhonePayload }) => {
        dispatch(updatePhoneNumberSuccess(data));
        dispatch(sendVerificationCodeRequest());

        return resendPhoneVerification({ idToken: token });
      })
      .then(() => {
        onSuccess && onSuccess();
        return dispatch(sendVerificationCodeSuccess());
      })
      .catch(({ response }: FailureResponse) => {
        dispatch(updatePhoneNumberFailure(response?.data));
        dispatch(sendVerificationCodeFailure());
      });
  };

export type VerifyPhoneNumberActions = ReturnType<
  | typeof resetPhoneVerification
  | typeof sendVerificationCodeRequest
  | typeof sendVerificationCodeSuccess
  | typeof sendVerificationCodeFailure
  | typeof verifyPhoneNumberRequest
  | typeof verifyPhoneNumberSuccess
  | typeof verifyPhoneNumberFailure
  | typeof updatePhoneNumberRequest
  | typeof updatePhoneNumberSuccess
  | typeof updatePhoneNumberFailure
>;
