import { Properties } from '~/App/shared/interfaces/Properties';
import { TranslateFunction } from '~/Locale';
import { BaseObjectType } from '~/App/shared/types/BaseObjectType';
import { environment } from '~/config/public';
import { formatMoney } from './currency';

const KM_TO_MILES = 1.621371192;

// Takes an odometer reading in swedish miles and converts to kilometers.
export const convertScandinavianMilesToKilometers = (
  odometerReading: number | string
) => Number(odometerReading) * 10;

export const convertScandinavianMilesToEnglishMiles = (
  odometerReading: number | string
) => (Number(odometerReading) * 10) / KM_TO_MILES;

export const convertKilometersToScandinavianMiles = (
  odometerReading: number | string
) => Number(odometerReading) / 10;

export const convertKilometersToEnglishMiles = (
  odometerReading: number | string
) => Number(odometerReading) / KM_TO_MILES;

export const convertEnglishMilesToKilometers = (
  odometerReading: number | string
) => Number(odometerReading) * KM_TO_MILES;

export const convertEnglishMilesToScandinavianMiles = (
  odometerReading: number | string
) => (Number(odometerReading) * KM_TO_MILES) / 10;

export const getTranslatedOdometerUnit = (
  t: TranslateFunction,
  odometerUnit: Properties['odometerUnit']
) => {
  switch (odometerUnit) {
    case 'km':
      return t('unit.kilometer');
    case 'mil':
      return t('unit.scandinavian_miles');
    case 'miles':
      return t('unit.english_miles');
    case 'tim':
      return t('unit.hours');
    default:
      return '';
  }
};

export const convertOdometerReading = (
  odometerReading: number,
  fromUnit: Properties['odometerUnit'],
  toUnit: Properties['odometerUnit']
) => {
  if (fromUnit === toUnit) return odometerReading;

  if (fromUnit === 'tim') return odometerReading;

  if (fromUnit === 'km' && toUnit === 'mil') {
    return convertKilometersToScandinavianMiles(odometerReading);
  }

  if (fromUnit === 'km' && toUnit === 'miles') {
    return convertKilometersToEnglishMiles(odometerReading);
  }

  if (fromUnit === 'mil' && toUnit === 'km') {
    return convertScandinavianMilesToKilometers(odometerReading);
  }

  if (fromUnit === 'mil' && toUnit === 'miles') {
    return convertScandinavianMilesToKilometers(odometerReading) / 1.609344;
  }

  if (fromUnit === 'miles' && toUnit === 'km') {
    return convertEnglishMilesToKilometers(odometerReading);
  }

  if (fromUnit === 'miles' && toUnit === 'mil') {
    return convertKilometersToScandinavianMiles(odometerReading) * 1.609344;
  }

  return odometerReading;
};

export const localMileage = (
  t: TranslateFunction,
  distance: number,
  fromUnit: Properties['odometerUnit']
) => {
  // Assume we get milage
  if (['sv', 'no'].includes(environment.locale)) {
    const convertedDistance = convertOdometerReading(distance, fromUnit, 'mil');
    return formatMoney({
      value: convertedDistance,
      currency: t('unit.scandinavian_miles')
    });
  }

  // In the future we might want to convert to km
  const convertedDistance = convertOdometerReading(distance, fromUnit, 'km');
  return formatMoney({
    value: convertedDistance,
    currency: t('unit.kilometer')
  });
};

export const formatOdometerReading = (
  t: TranslateFunction,
  odometerReading: number | string,
  odometerUnit: Properties['odometerUnit'],
  objectType: BaseObjectType
) => {
  // We should never display hours as odometer reading anymore
  if (odometerUnit === 'tim') return '';

  // Handle displaying of milage
  switch (objectType) {
    case 'CAR':
    case 'LIGHT_TRUCK':
    case 'MC_MOPED':
    case 'CAMPER':
      return localMileage(t, Number(odometerReading), odometerUnit);

    // display odometer reading as kilometers
    default:
      return formatMoney({
        value: convertOdometerReading(
          Number(odometerReading),
          odometerUnit,
          'km'
        ),
        currency: t('unit.kilometer')
      });
  }
};
