import { getMessages } from '~/helpers/orchestration/messages';
import { set as setCookie } from '~/helpers/client/cookie';
import { targetedAuctionsFeatureEnabled } from '~/config/public/environment';
import { Messages } from '../interfaces/store/Messages';
import { Cookies } from '../interfaces/Cookies';
import { ReduxDispatch } from '../interfaces/Redux';
import { ReduxStore } from '~/App/shared/interfaces/store';
import { localizationSelector } from '~/App/shared/selectors/localization';
import { SiteMessageRecord } from '~/config/generated/graphql';

export const messageRequest = () => ({
  type: 'MESSAGES_REQUEST' as const
});

export const messageSuccess = (messages: Messages) => ({
  type: 'MESSAGES_SUCCESS' as const,
  messages
});

export const messageFailure = (error: unknown) => ({
  type: 'MESSAGES_FAILURE' as const,
  error: true,
  message: error
});

export const messageDismiss = (id: string) => ({
  type: 'MESSAGES_DISMISS' as const,
  id
});

export const dismissMessage =
  (id: string, type: string) => (dispatch: ReduxDispatch) => {
    switch (type) {
      case 'RefreshPageWarning':
        setCookie('showVersionNotice', 'false', 1);
        break;
      default:
        setCookie(id, '1', 365);
    }

    dispatch(messageDismiss(id));
  };

export const fetchMessages =
  (cookies: Cookies) =>
  async (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const { currentLocale } = localizationSelector(getState());
    try {
      dispatch(messageRequest());
      const messages =
        currentLocale && (await getMessages(currentLocale, dispatch, getState));

      const filterPersistedDismissals = (m: SiteMessageRecord) => {
        switch (m.siteMessageType) {
          case 'RefreshPageWarning': {
            return cookies?.showVersionNotice === 'true';
          }
          case 'TargetedAuctionInformation':
            return targetedAuctionsFeatureEnabled;
          default:
            return cookies[m.id] !== '1';
        }
      };

      const visibleMessages: Messages = {
        top: messages?.top?.filter(filterPersistedDismissals) ?? [],
        bottom: messages?.bottom?.filter(filterPersistedDismissals) ?? []
      };
      return dispatch(messageSuccess(visibleMessages));
    } catch (e: unknown) {
      return dispatch(messageFailure(e));
    }
  };

export type MessagesActions =
  | ReturnType<typeof messageRequest>
  | ReturnType<typeof messageSuccess>
  | ReturnType<typeof messageFailure>
  | ReturnType<typeof messageDismiss>;
