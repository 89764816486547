import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { websocketSubscribeToChannel } from '../actions/connection-events';
import { memberIdSelector as memberIdSelector } from '../selectors/sessionSelector';

const WebsocketIndividualConnection = () => {
  const memberId = useSelector(memberIdSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (memberId) {
      dispatch(websocketSubscribeToChannel(memberId));
    }
  }, [dispatch, memberId]);

  return null;
};

export default WebsocketIndividualConnection;
