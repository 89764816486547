import React from 'react';
import { BodyText, Flex } from '@kvdbil/components';
import CheckBoxWithControl from '../../../Fields/CheckBoxWithControl';
import { useTranslation } from '~/Locale';
import { Control, FieldValues, Path } from 'react-hook-form';

interface Props<FormState extends FieldValues> {
  control: Control<FormState>;
  isCompany?: boolean;
}
export const NewsletterCheckboxes = <FormState extends FieldValues>({
  control,
  isCompany
}: Props<FormState>) => {
  const { t } = useTranslation();

  return (
    <Flex direction="column" gap={0.5}>
      <BodyText>{t('I want to receive newsletters about...')}</BodyText>
      <CheckBoxWithControl
        control={control}
        name={'personalCarSubscription' as Path<FormState>}
        label={t('Personal car')}
        color="info"
      />
      <CheckBoxWithControl
        control={control}
        name={'heavyVehiclesSubscription' as Path<FormState>}
        label={t('Heavy vehicles & machines')}
        color="info"
      />
      {isCompany && (
        <CheckBoxWithControl
          control={control}
          name={'corporateSubscription' as Path<FormState>}
          label={t('Fleet managers')}
          color="info"
        />
      )}
    </Flex>
  );
};
