import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

export const useOutsideClick = (callback: CallableFunction) => {
  const ref: MutableRefObject<HTMLDivElement | null> = useRef(null);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      const element = ref.current;
      const target = event.target as Node;

      if (!element) {
        return;
      }

      const isOutside = !element.contains(target);
      if (isOutside) {
        callback();
      }
    },
    [callback]
  );

  useEffect(() => {
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [callback, handleClick, ref]);

  return ref;
};
