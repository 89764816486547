import { GroupBase, StylesConfig } from 'react-select';
import { Country } from '~/App/shared/localization/components/constants';
import { theme } from '@kvdbil/components';
import { CSSObject } from 'styled-components';

const selectedOptionStyle = (isSelected: boolean) => {
  const selectedColor = theme.colors.text.light;
  const selectedBackground = theme.colors.info.main;

  return {
    color: isSelected ? selectedColor : theme.colors.text.dark,
    background: isSelected ? selectedBackground : 'none',
    '&:hover': {
      background: isSelected
        ? selectedBackground
        : theme.colors.background.gray,
      color: isSelected ? selectedColor : theme.colors.primary.main
    }
  };
};

export const getLanguageSelectModalStyles = (
  isPlacedInHeader: boolean
): StylesConfig<Country, false, GroupBase<Country>> => ({
  control: styles => ({
    ...styles,
    ...({
      display: 'flex',
      gap: '0.5rem',
      padding: isPlacedInHeader ? '0 0 0 1rem' : '0.75rem 1rem',
      backgroundColor: 'none',
      border: 'none',
      borderRadius: 0,
      color: theme.colors.text.dark,
      lineHeight: '1.5rem',
      fontFamily: theme.typography.fontBaseFamily,
      fontSize: isPlacedInHeader ? '0.75rem' : '1rem',
      fontWeight: isPlacedInHeader ? 600 : 400,
      cursor: 'pointer',
      boxShadow: 'none',
      minHeight: '40px',
      '&:hover': {
        backgroundColor: theme.colors.background.gray
      },
      '& svg': {
        width: isPlacedInHeader ? '1rem' : '1.5rem',
        height: isPlacedInHeader ? '1rem' : '1.5rem',
        marginLeft: isPlacedInHeader ? '-0.5rem' : '0',
        color: theme.colors.gray.dark1
      }
    } as CSSObject)
  }),
  valueContainer: styles => ({ ...styles, ...({ padding: 0 } as CSSObject) }),
  indicatorsContainer: styles => ({
    ...styles,
    // Indicator is a div element, it will get weird positioning without this
    ...({
      '& div': {
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center'
      }
    } as CSSObject)
  }),
  singleValue: styles => ({
    ...styles,
    ...({
      display: 'flex',
      flexDirection: isPlacedInHeader ? 'row-reverse' : 'row',
      gap: '1rem',
      margin: 0,
      alignItems: 'center',
      justifyContent: 'start',
      color: theme.colors.text.dark,
      fontFamily: isPlacedInHeader
        ? theme.typography.fontBaseFamily
        : theme.typography.fontAlternativeFamily,
      '& svg': {
        width: isPlacedInHeader ? '1rem' : '1.5rem',
        height: isPlacedInHeader ? '1rem' : '1.5rem'
      }
    } as CSSObject)
  }),
  input: styles => ({
    ...styles,
    ...({ height: 'auto', margin: 0, padding: 0 } as CSSObject)
  }),
  menu: styles => ({
    ...styles,
    ...({
      position: isPlacedInHeader ? 'absolute' : 'initial',
      width: isPlacedInHeader ? 'auto' : '100%',
      backgroundColor: isPlacedInHeader
        ? theme.colors.background.light
        : 'none',
      borderRadius: 0,
      boxShadow: 'none',
      marginTop: '-0.1rem',
      top: 'auto',
      zIndex: 10000
    } as CSSObject)
  }),
  menuList: styles => ({
    ...styles,
    ...({
      gap: isPlacedInHeader ? '0' : '0.5rem',
      padding: '0',
      display: 'flex',
      flexDirection: 'column'
    } as CSSObject)
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    ...({
      padding: '0.5rem 1rem',
      gap: '0.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      fontFamily: isPlacedInHeader
        ? 'Open Sans'
        : theme.typography.fontBaseFamily,
      fontSize: isPlacedInHeader ? '0.75rem' : '1rem',
      fontWeight: isPlacedInHeader ? 600 : 400,
      cursor: 'pointer',
      ...selectedOptionStyle(isSelected),
      '& svg': {
        width: isPlacedInHeader ? '1rem' : '1.5rem',
        height: isPlacedInHeader ? '1rem' : '1.5rem',
        marginRight: '0.5rem'
      }
    } as CSSObject)
  })
});

export const languageSelectModalStyles: StylesConfig<
  Country,
  false,
  GroupBase<Country>
> = {
  control: styles => ({
    ...styles,
    ...({
      width: '100%',
      border: `2px solid ${theme.colors.text.dark}`,
      ':hover': {
        borderColor: theme.colors.text.dark
      },
      borderRadius: 0,
      backgroundColor: 'none',
      boxShadow: 'none',
      minHeight: '60px'
    } as CSSObject)
  }),
  valueContainer: styles => ({
    ...styles,
    ...({ paddingLeft: '1.5rem' } as CSSObject)
  }),
  indicatorsContainer: styles => ({
    ...styles,
    ...({
      '& svg': {
        width: '1.5rem',
        height: '1.5rem',
        color: theme.colors.gray.dark1
      }
    } as CSSObject)
  }),
  placeholder: styles => ({
    ...styles,
    ...({ color: theme.colors.text.dark } as CSSObject)
  }),
  singleValue: styles => ({
    ...styles,
    ...({
      display: 'flex',
      gap: '1rem',
      margin: 0,
      alignItems: 'center'
    } as CSSObject)
  }),
  menu: styles => ({
    ...styles,
    ...({
      backgroundColor: theme.colors.background.light,
      border: `2px solid ${theme.colors.text.dark}`,
      borderRadius: 0,
      margin: '-2px 0',
      boxShadow: 'none'
    } as CSSObject)
  }),
  menuList: styles => ({
    ...styles,
    ...({
      padding: 0
    } as CSSObject)
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    ...({
      padding: '1rem 1.5rem',
      gap: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      cursor: 'pointer',
      ...selectedOptionStyle(isSelected)
    } as CSSObject)
  }),
  menuPortal: styles => ({ ...styles, zIndex: 9999 })
};
