import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { TimeSlot } from '~/App/shared/types/TimeSlot';
dayjs.extend(duration);

export const getServerOffsetTime = (timeDiff: number, clientDate?: Date) => {
  return dayjs(clientDate ?? new Date()).subtract(timeDiff);
};

export const getDurationTo = (serverTimeDiff: number, serverDate: Date) => {
  const endDate = dayjs(serverDate);

  const serverNow = getServerOffsetTime(serverTimeDiff);

  return dayjs.duration(endDate.diff(serverNow));
};

export const isPast = (timeslot: TimeSlot) => {
  return dayjs(timeslot.startTime).isBefore(dayjs(new Date()));
};
