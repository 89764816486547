import axios from '~/helpers/orchestration/axiosWithAuthHandling';
import { orchestration } from '~/config/public';
import { SubscriptionType } from '~/App/shared/interfaces/EmailSubscription';

type CreateSubscriberData = {
  member: {
    email: string;
    emailSubscriptionNames: string[];
  };
};

export const createSubscriber = ({ member }: CreateSubscriberData) =>
  axios.post<{ member: { id: string } }>(
    `${orchestration.url}/common/members/subscribers`,
    {
      member: member || {},
      responseType: 'json'
    }
  );

type CreateBusinessSubscriberData = {
  email: string;
  confirmation: boolean;
};

export const createBusinessSubscriber = ({
  email,
  confirmation
}: CreateBusinessSubscriberData) =>
  axios.post<unknown>(
    `${orchestration.url}/common/business_newsletter`,
    {
      email: email,
      confirmation: confirmation
    },
    {
      responseType: 'json'
    }
  );

export const getSubscriptions = ({
  idToken,
  hash
}: {
  idToken?: string;
  hash?: string;
}) =>
  axios
    .get<{
      subscriptions: {
        optIn: SubscriptionType[];
        optOut: SubscriptionType[];
      };
    }>(
      `${orchestration.url}/common/members/subscriptions${
        hash ? `?hash=${hash}` : ''
      }`,
      {
        headers:
          idToken && !hash
            ? {
                Authorization: `Bearer ${idToken}`
              }
            : undefined,
        withCredentials: true,
        responseType: 'json'
      }
    )
    .then(({ data }) => data.subscriptions);

export type UpdateSubscriptionsArgs = {
  hash?: string;
  idToken?: string;
  optIn: SubscriptionType[];
  optOut: SubscriptionType[];
};
export const updateSubscriptions = ({
  hash,
  idToken,
  optIn,
  optOut
}: UpdateSubscriptionsArgs) =>
  axios
    .put<{
      subscriptions: {
        optIn: SubscriptionType[];
        optOut: SubscriptionType[];
      };
    }>(
      `${orchestration.url}/common/members/subscriptions`,
      {
        subscriptions: {
          hash,
          optIn,
          optOut
        }
      },
      {
        withCredentials: true,
        responseType: 'json',
        headers:
          idToken && !hash
            ? {
                Authorization: `Bearer ${idToken}`
              }
            : undefined
      }
    )
    .then(({ data }) => data);

export interface UnsubscribeNotificationParams {
  hash: string;
  template: string;
  memberId: string;
}

// TODO: Type the result of this.
export const unsubscribeNotification = (
  unsubscribeDetails: UnsubscribeNotificationParams
) =>
  axios.put<unknown>(
    `${orchestration.url}/common/members/${unsubscribeDetails.memberId}/notifications/unsubscribe`,
    {
      unsubscribe: {
        hash: unsubscribeDetails.hash,
        template: unsubscribeDetails.template
      }
    },
    {
      responseType: 'json',
      withCredentials: false
    }
  );

export interface UnsubscribeNewsletterParams {
  hash: string;
  newsletterId: string;
}

// TODO: Type the result of this.
export const unsubscribeNewsletter = (
  unsubscribeDetails: UnsubscribeNewsletterParams
) =>
  axios.put<unknown>(
    `${orchestration.url}/common/members/newsletter-unsubscribe`,
    {
      responseType: 'json',
      withCredentials: false,
      params: {
        newsletterId: unsubscribeDetails.newsletterId,
        hash: unsubscribeDetails.hash
      },
      data: {}
    }
  );
