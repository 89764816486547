import { Color, ColorShade, TextColor } from '@kvdbil/components/types/Types';
import React from 'react';
import {
  OptionalHeroRecord,
  SmallPromoBannerRecord
} from '~/config/generated/graphql';
import SmallPromoBanner from './SmallPromoBanner';
import OptionalHero from '../../../../components/OptionalHero';

type Props = {
  smallPromoBanner?: SmallPromoBannerRecord[];
  largePromoBanner?: OptionalHeroRecord[];
};

function PromoBanners({ smallPromoBanner, largePromoBanner }: Props) {
  const showSmallBanner = smallPromoBanner?.[0].showSmallBanner;

  return (
    <>
      {showSmallBanner && (
        <SmallPromoBanner
          backgroundColor={smallPromoBanner?.[0].backgroundColor as Color}
          backgroundColorVersion={
            smallPromoBanner?.[0].backgroundColorShade as ColorShade
          }
          textColor={smallPromoBanner?.[0].textColor as TextColor}
          text={smallPromoBanner?.[0].text as string}
          button={smallPromoBanner?.[0].button[0]}
        />
      )}
      <OptionalHero data={largePromoBanner?.[0] as OptionalHeroRecord} />
    </>
  );
}

export default PromoBanners;
