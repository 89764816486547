import { TranslateFunction } from '~/Locale';
import { isIOSMobileDevice } from '~/helpers/isMobile';
import { HintCode } from '~/helpers/orchestration/auth';

const createReturnUrl = () => {
  // Here we create the URL that the BankID app should open when the authentication
  // is completed. On iOS all links open in Safari, even when another browser is
  // set as the default browser. So here we check if we're in another browser and
  // if so we replace the protocol with one provided by the specific browser so we
  // know it opens correctly. In some browsers we need to specify the full URL.

  const isChromeOnAppleDevice = Boolean(navigator.userAgent.match(/CriOS/));
  const isFirefoxOnAppleDevice = Boolean(navigator.userAgent.match(/FxiOS/));
  const isOperaTouchOnAppleDevice = Boolean(navigator.userAgent.match(/OPT/));

  if (isChromeOnAppleDevice) {
    return encodeURIComponent('googlechrome://');
  }
  if (isFirefoxOnAppleDevice) {
    return encodeURIComponent('firefox://');
  }
  if (isOperaTouchOnAppleDevice) {
    return encodeURIComponent(
      `${window.location.href.replace('http', 'touch-http')}`
    );
  }

  // Auto redirection to the browser is not implemented for Safari and other browsers
  // The reason is that when BankID returns to the browser, it reloads the page
  return 'null';
};

export const getBankIdStartUrl = (autoStartToken: string) => {
  const isIOS = isIOSMobileDevice();
  const returnUrl = isIOS ? createReturnUrl() : 'null';
  const baseUrl = 'bankid://';

  return `${baseUrl}/?autostarttoken=${autoStartToken}&redirect=${returnUrl}`;
};

export const startBankId = (autoStartToken?: string) => {
  if (!autoStartToken) {
    return;
  }

  const bankIdUrl = getBankIdStartUrl(autoStartToken);
  window.open(bankIdUrl, '_self');
};

export const getBankIdErrorMessage = (
  t: TranslateFunction,
  hintCode: HintCode | undefined
) => {
  const defaultError = t('Something went wrong');

  const translations = {
    started: t(`BANK_ID_SIGNING_started`),
    noClient: t(`BANK_ID_SIGNING_noClient`),
    userSign: t(`BANK_ID_SIGNING_userSign`),
    userCancel: t(`BANK_ID_SIGNING_userCancel`),
    startFailed: t(`BANK_ID_SIGNING_startFailed`),
    maintenance: t(`BANK_ID_SIGNING_maintenance`),
    unauthorized: t(`BANK_ID_SIGNING_unauthorized`),
    internalError: t(`BANK_ID_SIGNING_internalError`),
    undefinedError: t(`BANK_ID_SIGNING_undefinedError`),
    certificateErr: t(`BANK_ID_SIGNING_certificateErr`),
    requestTimeout: t(`BANK_ID_SIGNING_requestTimeout`),
    invalidParameters: t(`BANK_ID_SIGNING_invalidParameters`),
    expiredTransaction: t(`BANK_ID_SIGNING_expiredTransaction`),
    alreadyInProgress: t(`BANK_ID_SIGNING_alreadyInProgress`),
    unsupportedMediaType: t(`BANK_ID_SIGNING_unsupportedMediaType`),
    outstandingTransaction: t(`BANK_ID_SIGNING_outstandingTransaction`)
  };

  if (hintCode) {
    return translations?.[hintCode] ?? defaultError;
  }

  return defaultError;
};
