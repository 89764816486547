import { Select as BaseSelect } from '@kvdbil/components';
import { SelectProps as BaseSelectProps } from '@kvdbil/components/types/components/Select';
import React from 'react';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
  RegisterOptions,
  UnpackNestedValue
} from 'react-hook-form';
import { SelectOption } from '../../interfaces/SelectOption';

export type SelectProps<FormState extends FieldValues> = Omit<
  BaseSelectProps,
  'onChange'
> & {
  control: Control<FormState>;
  name: Path<FormState>;
  rules?: RegisterOptions;
  defaultValue?: UnpackNestedValue<
    PathValue<FormState, (string | undefined) & Path<FormState>>
  >;
};

const Select = React.forwardRef((props: BaseSelectProps, ref) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore next-line
  <BaseSelect {...props} forwardRef={ref} />
));

const SelectWithControl = <FormState extends FieldValues>(
  props: SelectProps<FormState>
) => {
  const { helperText, ...restProps } = props;
  return (
    <Controller
      {...restProps}
      render={({ field, fieldState }) => {
        const hasError = Boolean(fieldState.error);
        return (
          <Select
            {...restProps}
            {...field}
            value={field.value as SelectOption}
            hasError={hasError}
            helperText={hasError ? fieldState?.error?.message : helperText}
          />
        );
      }}
    />
  );
};

export default SelectWithControl;
