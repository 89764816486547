import { CheckoutOrder } from '~/App/shared/interfaces/store/Checkout';

export const getCheckoutStepState = (
  step: 'BUYER' | 'FINANCING' | 'PICKUP' | 'TRANSPORT' | 'INTRODUCTION',
  order?: CheckoutOrder
) => {
  let currentStep = step;
  if (order?.checkoutFlow === 'SWEDISH_CAR_DEALERS' && step === 'FINANCING') {
    currentStep = 'INTRODUCTION';
  }
  return order?.checkoutProgress?.steps[currentStep]?.state ?? 'NOT_COMPLETED';
};
