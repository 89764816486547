import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { localizationSelector } from '~/App/shared/selectors/localization';
import { Locale } from '~/App/shared/localization/types';
import { getRedirectBaseUrl } from '~/App/shared/localization/helpers';
import { isKeyOfObject } from '~/App/shared/types/helpers';
import { BASE_URLS } from '~/config/constants';

export const useLanguageSelection = () => {
  const { domainLocale } = useSelector(localizationSelector);

  const getRedirectUrl = useCallback(
    (locale: Locale, generatedPath: string) => {
      const baseUrl = getRedirectBaseUrl({
        requestedlocale: locale,
        domainLocale,
        originUrl: window.location.origin
      });
      if (isKeyOfObject(locale, BASE_URLS)) {
        return `${baseUrl}${generatedPath}`;
      }
      return `${baseUrl}/${locale}${generatedPath}`;
    },
    [domainLocale]
  );

  return { getRedirectUrl };
};
