import React from 'react';
import { Helmet } from 'react-helmet';
import {
  map,
  useWith,
  mergeRight,
  indexBy,
  prop,
  values,
  compose
} from 'ramda';
import { useTranslation } from '~/Locale';
import { environment } from '~/config/public';

const isOgTag = (tag: string) => tag && tag.includes('og:');

type MetaItem = { content: string; property: string; name?: string };

const relabelOgTags = (metaItem: MetaItem): MetaItem => {
  const { name, ...meta } = metaItem;
  if (name && isOgTag(name)) {
    return { ...meta, property: name };
  }
  if (name) {
    return { ...meta, name };
  }
  return meta;
};

export interface Props {
  title?: string;
  pageTitle?: string;
  language?: string;
  meta?: Record<string, string | string[]>[];
  link?: Record<string, string>[];
  children?: React.ReactNode;
}

const Head = ({
  pageTitle,
  title,
  language,
  meta = [],
  link,
  children
}: Props) => {
  const { t } = useTranslation();
  const defaultMeta = [
    {
      name: 'description',
      content: t('PAGE_META.DEFAULT_DESCRIPTION')
    },
    {
      name: 'og:description',
      content: t('PAGE_META.DEFAULT_OG_DESCRIPTION')
    },
    { name: 'og:title', content: t('PAGE_META.DEFAULT_OG_TITLE') },
    { name: 'og:image', content: t('PAGE_META.DEFAULT_OG_IMAGE') },
    { name: 'og:type', content: t('PAGE_META.DEFAULT_OG_TYPE') },
    {
      name: 'og:site_name',
      content: t('PAGE_META.DEFAULT_OG_SITE_NAME')
    },
    { name: 'og:url', content: t('PAGE_META.DEFAULT_OG_URL') }
  ];

  const byIndex = indexBy<MetaItem>(prop('property'));
  const relabelAndIndex = compose(byIndex, map(relabelOgTags));
  const mergedMeta: MetaItem[] = useWith(compose(values, mergeRight), [
    relabelAndIndex,
    relabelAndIndex
  ])(defaultMeta, meta);

  const metaWithFixedImage = mergedMeta.map((metaItem: MetaItem) => {
    if (metaItem.property === 'og:image') {
      return { ...metaItem, content: metaItem.content };
    }
    return metaItem;
  });

  const props = {
    htmlAttributes: {
      // override language if provided else use default, should probably never be overridden
      ...((language && { lang: language }) || {
        lang: environment.locale
      })
    },
    title: pageTitle || title,
    titleTemplate: `%s | ${t('PAGE_TITLES.TEMPLATE')}`,
    defaultTitle: t('PAGE_TITLES.DEFAULT'),
    meta: metaWithFixedImage,
    link: link || []
  };

  return <Helmet {...props}>{children}</Helmet>;
};

export default Head;
