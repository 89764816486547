import { History, Location } from 'history';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Route, useHistory, useLocation } from 'react-router';
import { Branch, matchRoutes } from '~/config/routes/renderRoutes';
import { Store } from 'redux';
import { log } from '~/helpers/bugsnagHelper';
import { constructPrefetchArguments } from '~/helpers/router';
import { PrefetchProps } from '~/server/prefetchData';
import {
  appStartLoading,
  appStopLoading,
  previousUrl,
  setCurrentRoute
} from '../shared/actions/app';
import { usePrevious } from '../shared/hooks/usePrevious';
import { useUserId } from '../shared/hooks/useUserId';
import { ReduxStore } from '../shared/interfaces/store';
import { TranslateFunction, useTranslation } from '~/Locale';
import buildRoutes from '~/routes';
import { trackGA4PageView } from '~/helpers/client/ga4TrackEvent';
import { useGA4UserIsHydrated } from '../shared/hooks/useGA4User';
import useBrowserBackStack from '../shared/hooks/useBrowserBackStack';
import { setContactButtonIsHidden } from '../shared/actions/contactButton';
import { setHeaderSlideAction } from '../shared/actions/headerActions';
import { useDispatch } from 'react-redux';
import { useResetFilterPageScrollPosition } from '../views/FilterPage/hooks/filterPageScrollHooks';
import {
  DataHookFunctionProps,
  StaticComponentProps
} from '~/helpers/provideDataHook';
import { LoadableComponentWithProps } from '~/config/routes/types';
import logger from '~/helpers/logger';

type ComponentWithLoad = React.ComponentType & {
  [loadableModuleName: string]: StaticComponentProps;
};

type ComponentWithStaticProps = React.ComponentType & StaticComponentProps;

const fetchData = async (
  props: AsyncRouterProps,
  history: History,
  location: Location,
  t: TranslateFunction,
  matchedRoutes: Branch[]
) => {
  props.store.dispatch(setContactButtonIsHidden(true));

  const params = constructPrefetchArguments({
    location,
    props: props,
    store: props.store
  });

  const initialComponent = params.route
    ?.component as LoadableComponentWithProps;

  const handleSpinner =
    !initialComponent?.skipSpinner &&
    setTimeout(() => {
      props.store.dispatch(appStartLoading());
    }, 500);

  /* Load component */
  let component: ComponentWithLoad | undefined;

  try {
    component =
      (await initialComponent?.load?.()) as unknown as ComponentWithLoad;
  } catch (error: unknown) {
    log(error, { severity: 'info' });
  }

  if (!component) {
    if (handleSpinner) clearTimeout(handleSpinner);
    props.store.dispatch(setContactButtonIsHidden(false));
    props.store.dispatch(setHeaderSlideAction(true));
    props.store.dispatch(appStopLoading());
    return;
  }

  const loadableModuleKey =
    Object.keys(component).find(key => component?.[key]?.uniqueIdentifier) ??
    'default';

  const uniqueIdentifier =
    component?.[loadableModuleKey]?.uniqueIdentifier ?? '';

  const prefetchProps = {
    ...props,
    ...params,
    ...props.store,
    history,
    location,
    t
  } as unknown as DataHookFunctionProps;

  const res = await component?.[loadableModuleKey]?.preRenderFetch?.(
    prefetchProps
  );

  window._INITIAL_DATA_ = {
    ...window._INITIAL_DATA_,
    prefetchedRequests: {
      ...window._INITIAL_DATA_?.prefetchedRequests,
      [uniqueIdentifier]: res
    }
  };

  const matchingComponents: ComponentWithStaticProps[] = [...matchedRoutes]
    .map(component => component.route.component as ComponentWithStaticProps)
    .filter(Boolean)
    .filter(
      component =>
        component?.uniqueIdentifier !== uniqueIdentifier &&
        Boolean(component?.uniqueIdentifier) &&
        Boolean(component?.preRenderFetch)
    );

  await Promise.all(
    matchingComponents.map(async component => {
      const { preRenderFetch, uniqueIdentifier = 'not_used' } = component ?? {};
      const res = await preRenderFetch?.(prefetchProps);
      window._INITIAL_DATA_ = {
        ...window._INITIAL_DATA_,
        prefetchedRequests: {
          ...window._INITIAL_DATA_?.prefetchedRequests,
          [uniqueIdentifier]: res
        }
      };
    })
  );

  if (handleSpinner) clearTimeout(handleSpinner);
  props.store.dispatch(appStopLoading());
  props.store.dispatch(setContactButtonIsHidden(false));
  props.store.dispatch(setHeaderSlideAction(true));
};

interface AsyncRouterProps extends PrefetchProps {
  children: ReactNode;
  store: Store<ReduxStore>;
}
const AsyncRouter = (props: AsyncRouterProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const userId = useUserId();
  const [renderLocation, setRenderLocation] = useState<Location>(location);
  const previousValue = usePrevious<Location | null>(location);

  const routeHasChanged = useMemo(() => {
    return location.pathname !== previousValue?.pathname;
  }, [location.pathname, previousValue?.pathname]);

  const [isInitialPageView, setIsInitialPageView] = useState(true);
  const { ga4User, isHydrated } = useGA4UserIsHydrated();

  const backStack = useBrowserBackStack();

  useResetFilterPageScrollPosition();

  useEffect(() => {
    const trackView = () => {
      trackGA4PageView(
        {
          document_href: window.location.href,
          document_title: document.title,
          pageview_type: isInitialPageView ? 'hard' : 'dynamic',
          previous_url:
            backStack?.[backStack.length - 2]?.fullURL || document.referrer
        },
        ga4User
      );
      setIsInitialPageView(false);
    };

    /* session must be hydrated before tracking */
    if (!isHydrated) {
      return;
    }

    /* Route has not changed. Do not track pageview but ignore if inital page view*/
    if (!isInitialPageView && !routeHasChanged) {
      return;
    }

    /* Route has changed, track pageview. */
    trackView();
  }, [
    location,
    ga4User,
    isInitialPageView,
    routeHasChanged,
    isHydrated,
    backStack
  ]);

  const builtRoutes = useMemo(() => buildRoutes(t), [t]);
  const matchedRoutes = useMemo(
    () => matchRoutes(builtRoutes, location.pathname),
    [builtRoutes, location.pathname]
  );

  useEffect(() => {
    const propsWithRoutes = {
      ...props,
      routes: [...(props.routes ?? []), ...builtRoutes]
    };

    /* No previous route */
    if (!previousValue) {
      setRenderLocation(location);
      return;
    }

    /* Route has not changed */
    if (!routeHasChanged) {
      setRenderLocation(location);
      return;
    }

    fetchData(propsWithRoutes, history, location, t, matchedRoutes)
      .then(() => {
        setRenderLocation(location);
        window.scrollTo(0, 0);
      })
      .catch((err: unknown) => {
        logger.error(err);
      });
  }, [
    history,
    location,
    previousValue,
    props,
    routeHasChanged,
    userId,
    t,
    ga4User,
    builtRoutes,
    matchedRoutes
  ]);

  useEffect(() => {
    const nonTranslatedPath = matchedRoutes?.[0]?.route?.nonTranslatedPath;
    const routeParams = matchedRoutes?.[0]?.match?.params;
    dispatch(
      setCurrentRoute({
        originalPathname: nonTranslatedPath ?? '',
        params: routeParams
      })
    );
    dispatch(
      previousUrl(
        backStack?.[backStack.length - 2]?.fullURL || document.referrer
      )
    );
  }, [matchedRoutes, dispatch, backStack]);

  return (
    <Route location={renderLocation} render={() => <>{props.children}</>} />
  );
};

export default AsyncRouter;
