import React from 'react';
import styled from 'styled-components';
import {
  AnswerType,
  CarGuideCriteria,
  CarguideQuestion
} from '~/App/views/Carguide/types';
import { useTranslation } from '~/Locale';
import ImportantAnswerIcon from '~/App/views/Carguide/components/ImportantAnswerIcon';
import { Status, mq } from '@kvdbil/components';

const MatchedTitle = styled.div`
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
`;

const MatchedTitleText = styled(Status)``;

const IconWrapper = styled.div`
  margin-right: 0.5rem;
  svg {
    width: 0.625rem;
    height: 0.625rem;
  }
`;

const MatchedElem = styled.span`
  color: ${({ theme }) => theme.colors.text.dark};
  font-family: ${({ theme }) => theme.typography.fontBaseFamily};
  font-weight: ${({ theme }) => theme.typography.fontBaseRegularWeight};
  font-size: 1rem;
  line-height: 1.5rem;

  ${mq('tablet')} {
    font-size: 0.75rem;
  }
`;

export interface CarGuideMatchedListProps {
  title: string;
  matched:
    | CarGuideCriteria['important']
    | CarGuideCriteria['niceToHave']
    | CarGuideCriteria['notAtAll'];
  questions: CarguideQuestion[];
}

const CarGuideMatchedList = ({
  title,
  matched,
  questions
}: CarGuideMatchedListProps) => {
  const { t } = useTranslation();
  const findQuestionTitle = (elem: string, questions: CarguideQuestion[]) => {
    const question = questions.find(({ tag }) => tag === elem);
    return (question && question.title) || elem;
  };
  const answerType: AnswerType = 'important';

  return (
    <>
      <MatchedTitle data-testid="car-guide-matched-title">
        <IconWrapper>
          <ImportantAnswerIcon type={answerType} size={'small'} />
        </IconWrapper>
        <MatchedTitleText as="span">
          {title} {t('important for you')}
        </MatchedTitleText>
      </MatchedTitle>
      {matched.map((elem, i) => (
        <MatchedElem key={elem} data-testid="car-guide-matched-elem">
          {findQuestionTitle(elem, questions)}
          {i + 1 !== matched.length && ', '}
        </MatchedElem>
      ))}
    </>
  );
};

export default CarGuideMatchedList;
