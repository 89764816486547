import React, { CSSProperties, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { mq, mqSizes } from '@kvdbil/components';

export type MqSize = keyof typeof mqSizes;

export interface IncludeContentProps {
  from?: MqSize;
  to?: MqSize;
  display?: CSSProperties['display'];
  children: ReactNode;
  as?: keyof JSX.IntrinsicElements;
}

export const IncludeContentBase = styled.div<IncludeContentProps>`
  display: none;

  ${({ from, to, display: display }) =>
    css`
      ${mq(from ?? null, to)} {
        display: ${display};
      }
    `}
`;

export const IncludeContent = ({ as, ...props }: IncludeContentProps) => {
  return <IncludeContentBase display="block" {...props} />;
};
