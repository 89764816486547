import {
  CarTreeFamilyBySlugQuery,
  CarTreeAllBrandsQuery,
  AllBrandAndFamilySlugsQuery,
  CarBrandAndCategoryBySlugQuery
} from '~/config/generated/graphql';
import {
  getFormattedStructuredText,
  getFormattedText
} from '~/helpers/cmsDatoUtils';
import { isEmptyDocument } from 'datocms-structured-text-utils';
import { log } from '~/helpers/bugsnagHelper';

export const handleCarTreeBrandCmsData = ({
  carBrandPage
}: CarBrandAndCategoryBySlugQuery) => ({
  name: carBrandPage?.name,
  slug: carBrandPage?.slug,
  logo: carBrandPage?.logo,
  headingText:
    carBrandPage?.heading ||
    getFormattedText(
      carBrandPage?.headingTemplate?.template,
      carBrandPage?.name
    ) ||
    carBrandPage?.name,
  headingTag: carBrandPage?.headingTag,
  preamble: !isEmptyDocument(carBrandPage?.preamble)
    ? carBrandPage?.preamble
    : getFormattedStructuredText(
        carBrandPage?.preambleTemplate?.template,
        carBrandPage?.name
      ),
  queryParams: carBrandPage?.queryParams,
  text: carBrandPage?.text,
  allFamily: carBrandPage?._allReferencingCarFamilyPages,
  seoInfo: !isEmptyDocument(carBrandPage?.seoInfo)
    ? carBrandPage?.seoInfo
    : getFormattedStructuredText(
        carBrandPage?.seoInfoTemplate?.template,
        carBrandPage?.name
      ),
  seoTitle:
    carBrandPage?.seo?.title ||
    getFormattedText(
      carBrandPage?.seoTemplate?.template?.title,
      carBrandPage?.name
    ),
  seoDescription:
    carBrandPage?.seo?.description ||
    getFormattedText(
      carBrandPage?.seoTemplate?.template?.description,
      carBrandPage?.name
    ),
  seoImageSrc:
    carBrandPage?.seo?.image?.responsiveImage?.src ||
    carBrandPage?.seoTemplate?.template?.image?.responsiveImage?.src
});

export const handleCarTreeFamilyCmsData = ({
  carFamilyPage
}: CarTreeFamilyBySlugQuery) => {
  if (!carFamilyPage) {
    return { notFound: true };
  }
  const displayedName = `${carFamilyPage?.brand?.name} ${carFamilyPage?.name}`;
  return {
    name: carFamilyPage?.name,
    slug: carFamilyPage?.slug,
    logo: carFamilyPage?.brand?.logo,
    headingText:
      getFormattedText(
        carFamilyPage?.brand?.headingTemplate?.template,
        displayedName
      ) || displayedName,
    headingTag: carFamilyPage?.brand?.headingTag,
    preamble: !isEmptyDocument(carFamilyPage?.preamble)
      ? carFamilyPage?.preamble
      : getFormattedStructuredText(
          carFamilyPage?.brand?.familyPreambleTemplate?.template,
          displayedName
        ),
    queryParams: carFamilyPage?.queryParams,
    text: carFamilyPage?.text,
    brand: {
      name: carFamilyPage?.brand?.name,
      slug: carFamilyPage?.brand?.slug
    },
    allFamily: carFamilyPage?.brand?._allReferencingCarFamilyPages,
    seoInfo:
      carFamilyPage?.displaySeoText &&
      !isEmptyDocument(carFamilyPage?.brand?.seoInfoTemplate?.template)
        ? getFormattedStructuredText(
            carFamilyPage?.brand?.seoInfoTemplate?.template,
            displayedName
          )
        : null,
    seoTitle:
      carFamilyPage?.brand?.seo?.title ||
      getFormattedText(
        carFamilyPage?.brand?.seoTemplate?.template?.title,
        displayedName
      ),
    seoDescription:
      carFamilyPage?.brand?.seo?.description ||
      getFormattedText(
        carFamilyPage?.brand?.seoTemplate?.template?.description,
        displayedName
      ),
    seoImageSrc:
      carFamilyPage?.brand?.seo?.image?.responsiveImage?.src ||
      carFamilyPage?.brand?.seoTemplate?.template?.image?.responsiveImage?.src
  };
};

export const handleCarTreeAllBrandsCmsData = ({
  allCarBrandPages
}: Partial<CarTreeAllBrandsQuery>) => {
  return allCarBrandPages?.map(({ name, slug }) => ({ name, slug })) ?? [];
};

export const handleCarCategoryCmsData = ({
  carCategoryPage
}: CarBrandAndCategoryBySlugQuery) => ({
  name: carCategoryPage?.name,
  slug: carCategoryPage?.slug,
  headingText: carCategoryPage?.heading || carCategoryPage?.name,
  headingTag: carCategoryPage?.headingTag,
  preamble: carCategoryPage?.preamble,
  queryParams: carCategoryPage?.queryParams,
  text: carCategoryPage?.text,
  seoInfo: !isEmptyDocument(carCategoryPage?.seoInfo)
    ? carCategoryPage?.seoInfo
    : getFormattedStructuredText(
        carCategoryPage?.seoInfoTemplate?.template,
        carCategoryPage?.name
      ),
  seoTitle:
    carCategoryPage?.seo?.title ||
    getFormattedText(
      carCategoryPage?.seoTemplate?.template?.title,
      carCategoryPage?.name
    ),
  seoDescription:
    carCategoryPage?.seo?.description ||
    getFormattedText(
      carCategoryPage?.seoTemplate?.template?.description,
      carCategoryPage?.name
    ),
  seoImageSrc:
    carCategoryPage?.seo?.image?.responsiveImage?.src ||
    carCategoryPage?.seoTemplate?.template?.image?.responsiveImage?.src,
  logo: null,
  allFamily: null
});

export const handleCarAllBrandAndFamilySlugsData = ({
  allCarBrandPages
}: Partial<AllBrandAndFamilySlugsQuery>) => {
  let familySlugs: string[] = [];

  allCarBrandPages?.forEach(({ _allReferencingCarFamilyPages }) => {
    const slugs =
      _allReferencingCarFamilyPages?.map(({ slug }) => slug)?.filter(Boolean) ??
      [];
    familySlugs = familySlugs.concat(slugs as string[]);
  }) ?? [];

  return {
    brandSlugs: allCarBrandPages?.map(({ slug }) => slug) ?? [],
    familySlugs
  };
};

export const handleCarBrandAndCategoryData = ({
  carBrandPage,
  carCategoryPage
}: CarBrandAndCategoryBySlugQuery) => {
  if (carBrandPage && carCategoryPage) {
    log(new Error('Duplicated slug value of car brand and category!'), {
      brandSlug: carBrandPage?.slug,
      categorySlug: carCategoryPage?.slug,
      severity: 'error'
    });
    return { notFound: true };
  }

  return (
    (carBrandPage && handleCarTreeBrandCmsData({ carBrandPage })) ||
    (carCategoryPage && handleCarCategoryCmsData({ carCategoryPage })) || {
      notFound: true
    }
  );
};
