import { Action, Location } from 'history';
import { useEffect, useReducer } from 'react';
import { useHistory } from 'react-router';

interface BackStack extends Location {
  fullURL: string;
}

type ReducerAction = {
  type: string;
  location: Location;
  url: string;
};

function reducer(state: BackStack[], { location, type, url }: ReducerAction) {
  switch (type) {
    case 'POP':
      return state.slice(0, state.length - 1);

    case 'PUSH':
      return [...state, { ...location, fullURL: url }];

    case 'REPLACE':
      return [
        ...state.slice(0, state.length - 1),
        { ...location, fullURL: url }
      ];
  }
}

const useBrowserBackStack = () => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, [
    { ...history.location, fullURL: window.location.href }
  ]);

  useEffect(() => {
    // FIXME: fix react-router or history typing
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore next-line
    const unListen = history.listen((location: Location, action: Action) => {
      dispatch({ type: action, location: location, url: window.location.href });
    });
    return () => {
      unListen();
    };
  }, [history]);
  return state;
};

export default useBrowserBackStack;
