import { createSelector } from 'reselect';
import { RecentlyViewedAuctions } from '../interfaces/store/RecentlyViewedAuctions';
import { ReduxStore } from '../interfaces/store';

const root = createSelector<
  ReduxStore,
  RecentlyViewedAuctions,
  RecentlyViewedAuctions
>(
  state => state?.recentlyViewedAuctions,
  value => value
);

export const recentlyViewedAuctionsAllIds = createSelector(
  root,
  state => state?.allIds
);

export const recentlyViewedAuctionsById = createSelector(
  root,
  state => state?.byId
);

export const recentlyViewedAuctionsList = createSelector(
  recentlyViewedAuctionsAllIds,
  recentlyViewedAuctionsById,
  (allIds, byId) => allIds.map(id => byId[id])
);
