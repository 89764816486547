import { AxiosError } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from '~/Locale';
import { useBankIdAuth } from '../hooks/bankIdAuthHooks';
import VerifyBankIDCompletedView from './views/VerifyBankIDCompletedView';
import VerifyBankIDLoadingView from './views/VerifyBankIDLoadingView';
import VerifyBankIDRetryView from './views/VerifyBankIDRetryView';
import VerifyBankIDView from './views/VerifyBankIDView';

type Props = {
  onClose?(): void;
  onSuccess?(): void;
};

export const VerifyBankID = ({ onClose, onSuccess }: Props) => {
  const { t } = useTranslation();

  const [error, setError] = useState<string | null>(null);
  const [showCompletedView, setShowCompletedView] = useState(false);
  const hasTriggedBankIdRef = React.useRef(false);

  const {
    qrCode,
    autoStartToken,
    orderRef,
    setData: setBankIdData,
    bankIdAuthStart
  } = useBankIdAuth();

  const handleBankIdFail = useCallback(
    (e: unknown) => {
      const defaultError = t('Something went wrong, please try again later.');

      if (error) {
        return;
      }

      if (e instanceof AxiosError) {
        setError(e.response?.data?.message ?? defaultError);
      } else if (e instanceof Error) {
        setError(e.message || defaultError);
      } else {
        setError((e as string) || defaultError);
      }
    },
    [error, t]
  );

  const handleBankIdAuthStart = useCallback(async () => {
    error && setError(null);

    await bankIdAuthStart({
      onError: handleBankIdFail
    });
  }, [bankIdAuthStart, error, handleBankIdFail]);

  const handleOnBankIdClose = useCallback(() => {
    setBankIdData(null);
    onClose?.();
  }, [setBankIdData, onClose]);

  const handleVerifyOnComplete = useCallback(() => {
    onSuccess?.() ?? setShowCompletedView(true);
  }, [onSuccess]);

  // Just used to to make sure and trigger the bankIdAuthStart on mount but only once
  useEffect(() => {
    if (!hasTriggedBankIdRef.current) {
      void handleBankIdAuthStart();
      hasTriggedBankIdRef.current = true;
    }
  }, [bankIdAuthStart, handleBankIdAuthStart, handleBankIdFail]);

  if (error) {
    return (
      <VerifyBankIDRetryView
        title={t('Verify with BankID')}
        subtitle={t(
          'You need to verify your information to be able to continue'
        )}
        error={error}
        onRetry={handleBankIdAuthStart}
        onClose={handleOnBankIdClose}
      />
    );
  }

  if (showCompletedView) {
    return (
      <VerifyBankIDCompletedView
        title={t('Verify with BankID')}
        subtitle={t(
          'You need to verify your information to be able to continue'
        )}
        onClose={handleOnBankIdClose}
      />
    );
  }

  if (qrCode && autoStartToken && orderRef && !error) {
    return (
      <VerifyBankIDView
        title={t('Verify with BankID')}
        subtitle={t(
          'You need to verify your information to be able to continue'
        )}
        qrCode={qrCode}
        autoStartToken={autoStartToken}
        orderRef={orderRef}
        onFail={handleBankIdFail}
        onClose={handleOnBankIdClose}
        onComplete={handleVerifyOnComplete}
      />
    );
  }

  return (
    <VerifyBankIDLoadingView
      title={t('Verify with BankID')}
      subtitle={t('You need to verify your information to be able to continue')}
      onClose={handleOnBankIdClose}
    />
  );
};
