import axios from 'axios';
import React, { useCallback, useRef, useState } from 'react';
import { useParamFromQuery } from '~/App/shared/hooks/useQueryParams';
import { useTranslation } from '~/Locale';
import { useBankIdAuth } from '../hooks/bankIdAuthHooks';
import CompletedView from './views/CompletedView';
import { RegistrationView } from './types';
import CreateAccountVerifyBankIDView from './views/CreateAccountVerifyBankIDView';
import RegistrationTabView from './views/RegistrationTabView';
import { SignIn } from '../SignIn/components/SignIn';
import { translateRoute } from '~/App/shared/localization';
import { useHistory } from 'react-router';
import { loginFlowStep } from '~/App/shared/actions/loginFlowActions';
import { useDispatch } from 'react-redux';
import { useDynamicScript } from '@kvdbil/components';
import { googleRecaptchaSource } from '~/config/dynamicScriptsTags';
import { runWithRecaptcha } from '~/helpers/recaptcha';
import { postCheckEmail } from '~/helpers/orchestration/auth';
import { VerifyBankID } from '../VerifyBankID';
import { Session } from '~/App/shared/interfaces/store/Session';
import useLocalization from '~/App/shared/hooks/useLocalization';
import { SubscriptionType } from '~/App/shared/interfaces/EmailSubscription';

type Props = {
  onClose?(): void;
  onBack?(): void;
  showTitle?: boolean;
  showDescription?: boolean;
  initialTab?: 'company' | 'private';
  onPrivateClick?(): void;
  onCompanyClick?(): void;
  isStandaloneView?: boolean;
};

const Registration = ({
  onClose,
  onBack,
  showTitle = true,
  showDescription = false,
  initialTab,
  onPrivateClick,
  onCompanyClick,
  isStandaloneView = false
}: Props) => {
  useDynamicScript(googleRecaptchaSource);

  const { isSwedishLocale } = useLocalization();
  const history = useHistory();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [view, setView] = useState<RegistrationView>(
    isSwedishLocale ? 'CREATE_ACCOUNT' : 'NO_BANKID'
  );
  const [isEmailChecking, setIsEmailChecking] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const emailFromParams = useParamFromQuery('email');
  const emailRef = useRef(emailFromParams);
  const [emailSubscriptionNames, setEmailSubscriptionNames] = useState<
    SubscriptionType[] | undefined
  >();

  const changeView = useCallback(
    (newView: RegistrationView) => setView(newView),
    []
  );

  const {
    qrCode,
    autoStartToken,
    orderRef,
    setData: setBankIdData,
    bankIdAuthStart,
    isLoading: isBankIdAuthStartLoading
  } = useBankIdAuth();

  const handlePressForgotPassword = useCallback(
    (email?: string) => {
      const query = email ? new URLSearchParams({ email }).toString() : '';
      const url =
        translateRoute(t, '/ROUTES.MEMBER/ROUTES.MEMBER_FORGOT_PASSWORD') +
        (query ? '?' + query : '');

      history.push(url);
    },
    [history, t]
  );

  const handleOnSignIn = useCallback(
    (data?: Session) => {
      dispatch(loginFlowStep());
      // trigger bankid verification
      if (data?.member?.person?.bankidVerified) {
        setView('COMPLETED');
      } else {
        setView('BANKID_VERIFY');
      }
    },
    [dispatch]
  );

  const handleBankIdFail = useCallback(
    (e: unknown) => {
      setBankIdData(null);
      const defaultError = t('Something went wrong, please try again later.');

      if (error) {
        return;
      }

      if (axios.isAxiosError<{ message: string }>(e)) {
        setError(e.response?.data?.message ?? defaultError);
      } else if (e instanceof Error) {
        setError(e.message || defaultError);
      } else {
        setError((e as string) || defaultError);
      }
    },
    [error, t, setBankIdData]
  );

  const handleOnBankIdClose = useCallback(() => {
    setBankIdData(null);
    !isStandaloneView && onClose?.();
    isStandaloneView && changeView('CREATE_ACCOUNT');
  }, [onClose, setBankIdData, isStandaloneView, changeView]);

  const handleEmailCheck = useCallback(
    (email: string, emailSubscriptionNames: SubscriptionType[]) => {
      setIsEmailChecking(true);
      error && setError(null);

      emailRef.current = email;

      runWithRecaptcha(async googleResponse => {
        try {
          const response = await postCheckEmail(email, googleResponse);
          setIsEmailChecking(false);

          if (response.data.email) {
            changeView('EMAIL_SIGN_IN');
          } else {
            setEmailSubscriptionNames(emailSubscriptionNames);

            await bankIdAuthStart({
              onError: handleBankIdFail
            });
          }
        } catch (e) {
          handleBankIdFail(e);
          setIsEmailChecking(false);
        }
      });
    },
    [bankIdAuthStart, changeView, error, handleBankIdFail]
  );

  if (
    qrCode &&
    autoStartToken &&
    orderRef &&
    !error &&
    emailRef.current &&
    view !== 'COMPLETED'
  ) {
    return (
      <CreateAccountVerifyBankIDView
        title={t('Verify with BankID')}
        subtitle={t(
          'You need to verify your information to be able to continue'
        )}
        qrCode={qrCode}
        autoStartToken={autoStartToken}
        orderRef={orderRef}
        onFail={handleBankIdFail}
        onClose={handleOnBankIdClose}
        onBack={() => {
          setBankIdData(null);
          changeView('CREATE_ACCOUNT');
        }}
        onComplete={() => changeView('COMPLETED')}
        email={emailRef.current}
        emailSubscriptionNames={emailSubscriptionNames}
        isStandaloneView={isStandaloneView}
      />
    );
  }

  if (view === 'COMPLETED') {
    return (
      <CompletedView onClose={onClose} isStandaloneView={isStandaloneView} />
    );
  }

  if (view === 'EMAIL_SIGN_IN') {
    return (
      <SignIn
        onPressForgotPassword={handlePressForgotPassword}
        onSignIn={handleOnSignIn}
        onClose={
          isStandaloneView ? () => changeView('CREATE_ACCOUNT') : onClose
        }
        warningMessage={t(
          'The email you entered already exists on an existing account. Enter your password or verify your access to the email to proceed.'
        )}
        forceDisableBankId
        initialEmail={emailRef.current ?? ''}
      />
    );
  }

  if (view === 'BANKID_VERIFY') {
    return (
      <VerifyBankID
        onClose={handleOnBankIdClose}
        onSuccess={() => changeView('COMPLETED')}
      />
    );
  }

  return (
    <RegistrationTabView
      onClose={onClose}
      onBack={onBack}
      showTitle={showTitle}
      showDescription={showDescription}
      initialTab={initialTab}
      onPrivateClick={onPrivateClick}
      onCompanyClick={onCompanyClick}
      onBankIdAuthStart={handleEmailCheck}
      isBankIdAuthStartLoading={isEmailChecking || isBankIdAuthStartLoading}
      changeView={changeView}
      view={view}
      email={emailRef.current}
      isStandaloneView={isStandaloneView}
      error={error}
    />
  );
};

export default Registration;
