import { useCallback, useEffect, useRef, useState } from 'react';
import { useOnMount } from './useOnMount';
import throttle from 'lodash.throttle';
import { useWindowFocus } from './useWindowFocus';

export type UseIdleOptions = {
  debounce?: number;
};

export const useIdle = (timeout: number, options?: UseIdleOptions) => {
  const { debounce = 500 } = options ?? {};
  const [isIdle, setIsIdle] = useState(false);

  const isWindowFocused = useWindowFocus();
  const timer = useRef<ReturnType<typeof setTimeout>>();

  const startIdle = () => {
    setIsIdle(true);
  };

  const updateTimer = useCallback(() => {
    setIsIdle(false);

    timer.current && clearTimeout(timer.current);
    timer.current = setTimeout(startIdle, timeout);
  }, [timeout]);

  useEffect(() => {
    if (!isWindowFocused) {
      timer.current && clearTimeout(timer.current);
      return;
    }

    updateTimer();
  }, [isWindowFocused, updateTimer]);

  const throttledTimeout = throttle(updateTimer, debounce);

  useOnMount(
    () => {
      window.addEventListener('mousemove', throttledTimeout);
    },
    () => {
      window.removeEventListener('mousemove', throttledTimeout);
    }
  );

  return isIdle;
};
