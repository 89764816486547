export enum SubscriptionType {
  TargetedAuction = 'TARGETED_AUCTIONS',
  Member = 'MEMBER',
  Bidding = 'BIDDING',
  SalesProcessInformation = 'SALES_PROCESS_INFORMATION',
  BuyingProcessInformation = 'BUYING_PROCESS_INFORMATION',
  WeeklyPersonalCar = 'WEEKLY',
  WeeklyHeavyVehicles = 'WEEKLY_HEAVY_VEHICLES',
  SavedSearch = 'SAVED_SEARCH',
  MemberClub = 'MEMBER_CLUB',
  CarDealer = 'CAR_DEALER',
  Corporate = 'CORPORATE'
}

export interface EmailSubscription {
  name: string;
  type: string;

  createdAt: string;
  signupMethod: string;
}

export const allSubscriptionTypes: SubscriptionType[] = [
  SubscriptionType.WeeklyPersonalCar,
  SubscriptionType.WeeklyHeavyVehicles,
  SubscriptionType.Bidding,
  SubscriptionType.BuyingProcessInformation,
  SubscriptionType.Member,
  SubscriptionType.SalesProcessInformation,
  SubscriptionType.TargetedAuction,
  SubscriptionType.SavedSearch,
  SubscriptionType.MemberClub,
  SubscriptionType.CarDealer,
  SubscriptionType.Corporate
];

export const isSubscriptionType = (
  value?: string | null
): value is SubscriptionType => {
  return allSubscriptionTypes.includes(value as SubscriptionType);
};
