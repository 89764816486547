/**
 * @deprecated TODO: [KVDBIL-1634] refactor...
 */

import { take, append, join, map, compose, concat, toPairs } from 'ramda';
import { SavedSearch } from '~/App/shared/interfaces/SavedSearch';
import {
  getFilterTagLabel,
  Filter,
  FilterType
} from '~/helpers/auctionsFilter';
import { TranslateFunction } from '~/Locale';
import {
  isIntervalSearchParamKey,
  isIntervalSearchParamLabelKey,
  isMultiselectSearchParamKey
} from './filterSearchParams';
import { AuctionSearchQuery } from '~/App/shared/interfaces/AuctionSearchQuery';
import { MainCategory } from '~/App/shared/types/MainCategoryTypes';

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export const createQueryString = compose(
  concat('?'),
  join('&'),
  map(join('=')),
  toPairs
);

const getType = (filter: Filter): FilterType => {
  const { key, value } = filter;

  if (isIntervalSearchParamKey(key) || isIntervalSearchParamLabelKey(key)) {
    return 'interval';
  }

  if (typeof value === 'number') {
    return 'currency';
  }

  return 'default';
};

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export const createSearchName = (
  t: TranslateFunction,
  query: SavedSearch['filters']
) => {
  const name: string[] = [];

  Object.entries(query).map(([key, value]) => {
    if (key === 'orderBy' || key === 'auctionType') return null;

    const type: FilterType = getType({ key, value, type: 'default' });
    const label = getFilterTagLabel(
      t,
      {
        key,
        value,
        type
      },
      query?.vehicleType as MainCategory
    ) as string;

    label && name.push(label);
  });

  if (!name.length) {
    return t('Latest search');
  }

  if (name.length > 3) {
    name.push(`+${name.length - 3} ${t('More')}`);

    return append(name.pop())(take(3, name)).join(', ');
  }
  return name.join(', ');
};

export const normalizeSearchObject = (search: SavedSearch) => {
  const filters = Object.entries(search.filters).reduce(
    (prev, [key, value]) => {
      const newvalue = Array.isArray(value) ? value.join(',') : value;
      return { ...prev, [key]: newvalue };
    },
    search.filters
  );

  return {
    ...search,
    filters
  };
};

/**
 * @deprecated [KVDBIL-1634] this should be moved or removed when AuctionSearchQuery gets deprecated....
 * @description Get current filterSearchParams from url, convert it to SavedSearch.
 */
export const convertFilterSearchParamsToSearchQuery = (
  filterSearchParams: URLSearchParams
) => {
  const filters: Record<string, string | number> = {};

  filterSearchParams.forEach((value, key: string) => {
    // if value is numeric, convert to number but avoid converting familyName e.g. "208" for Peugeot brand
    if (!isMultiselectSearchParamKey(key) && !isNaN(Number(value))) {
      filters[key] = Number(value);
    } else {
      filters[key] = value;
    }
  });

  return filters as Partial<AuctionSearchQuery>;
};
