import { SessionActions } from './../actions/session';
import {
  REMOVE_FAVOURITE_AUCTION_SUCCESS,
  STORE_FAVOURITE_AUCTION_SUCCESS
} from '../actions/favouriteAuctions';

type RemoveFavouriteAuctionsAction = {
  type: typeof REMOVE_FAVOURITE_AUCTION_SUCCESS;
  payload?: string;
};

type StoreFavouriteAuctionsAction = {
  type: typeof STORE_FAVOURITE_AUCTION_SUCCESS;
  payload?: {
    favouriteAuction: Record<string, string>;
  };
};

type FavouriteAuctionsAction =
  | RemoveFavouriteAuctionsAction
  | StoreFavouriteAuctionsAction;

type HydrateFavouriteAuctionsAction = {
  type: 'HYDRATE_FAVOURITE_AUCTIONS_SUCCESS';
  favouriteAuctions: Record<string, string>[];
};

const favouriteAuctions = (
  state = [],
  action:
    | SessionActions
    | FavouriteAuctionsAction
    | HydrateFavouriteAuctionsAction
) => {
  switch (action.type) {
    case 'STORE_FAVOURITE_AUCTION_SUCCESS':
      const auctionId = action.payload?.favouriteAuction?.auctionId;
      if (auctionId) {
        return [...state, auctionId];
      }
      return state;
    case 'REMOVE_FAVOURITE_AUCTION_SUCCESS':
      return state.filter(auctionId => auctionId !== action.payload);
    case 'HYDRATE_FAVOURITE_AUCTIONS_SUCCESS':
      return action.favouriteAuctions.map(favourite => favourite.id);
    case 'SESSION_DESTROY_SUCCESS':
      return [];
    default:
      return state;
  }
};

export default favouriteAuctions;
