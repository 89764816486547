import React, { useEffect } from 'react';
import { useTranslation } from '~/Locale';
import TransportCalculatorForm from '~/App/shared/components/TransportCalculator/TransportCalculatorForm';
import { useTransportCalculator } from '~/App/shared/components/TransportCalculator/useTransportCalculator';

type Props = {
  title?: string;
};

const TransportCostForm = ({ title }: Props) => {
  const { t } = useTranslation();
  const { facilities, getFacilities } = useTransportCalculator();

  useEffect(() => {
    getFacilities();
  }, [getFacilities]);

  return (
    <TransportCalculatorForm
      facilities={facilities}
      heading={title || t('TRANSPORT_CALCULATOR_HEADING')}
      fromDropdownLabel={t('TRANSPORT_CALCULATOR_FROM_DROPDOWN_LABEL')}
      toDropdownLabel={t('TRANSPORT_CALCULATOR_TO_DROPDOWN_LABEL')}
    />
  );
};

export default TransportCostForm;
