import { translateRoute } from '~/App/shared/localization';
import { SalesFlowType } from '~/App/shared/types/SalesFlowType';
import { TranslateFunction } from '~/Locale';
import { baseUrl } from '~/config/public/environment';

const percentColors = [
  { pct: 0.0, color: { r: 0x2a, g: 0x9d, b: 0x8f } },
  { pct: 0.5, color: { r: 0xf5, g: 0xb4, b: 0x00 } },
  { pct: 1.0, color: { r: 0xf0, g: 0x5c, b: 0x5c } }
];

export const getValueDescription = (
  percentage: number,
  t: TranslateFunction
) => {
  if (percentage < 0.2) {
    return t(
      'With this reservation price you have the best chance to get the most bidders! ...'
    );
  } else if (percentage < 0.4) {
    return t('Here´s a reservation price where you get in many bidders, ...');
  } else if (percentage < 0.6) {
    return t(
      'This is a reservation price where you begin to risk getting fewer bidders. ...'
    );
  } else if (percentage < 0.8) {
    return t(
      'This is a reservation price where you get fewer bidders and often a lower ...'
    );
  } else {
    return t(
      'Here is a reservation price where it is likely that the bidding effect will ...'
    );
  }
};

export const getValueTitle = (percentage: number, t: TranslateFunction) => {
  if (percentage < 0.4) {
    return t('Low');
  } else if (percentage < 0.6) {
    return t('Recommended');
  } else {
    return t('High');
  }
};

export const getPriceIntervalColor = (percentage: number) => {
  let pct = percentage;
  let i = 0;

  if (pct < 0) {
    pct = 0;
  }

  for (i; i < percentColors.length - 1; i += 1) {
    if (pct < percentColors[i].pct) {
      break;
    }
  }
  const lower = percentColors[i - 1];
  const upper = percentColors[i];
  const range = upper.pct - lower.pct;
  const rangePct = (pct - lower.pct) / range;
  const pctLower = 1 - rangePct;
  const pctUpper = rangePct;
  const color = {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
  };
  const rgb = [color.r, color.g, color.b].join(',');

  return `rgb(${rgb})`;
};

export const getSalesAgreementReturnAndSentUrl = ({
  t,
  isCompanySale,
  baseObjectId,
  token
}: {
  t: TranslateFunction;
  isCompanySale: boolean;
  baseObjectId: string;
  token: string;
}) => {
  const routes = {
    private: {
      returnUrl: 'ROUTES.SELL/ROUTES.SELL_PRIVATE/ROUTES.SELL_AGREEMENT_SIGNED',
      agreementSentRoute:
        '/ROUTES.SELL/ROUTES.SELL_PRIVATE/ROUTES.SELL_AGREEMENT_SENT'
    },
    company: {
      returnUrl:
        'ROUTES.SELL/ROUTES.SELL_ORGANIZATION/ROUTES.SELL_AGREEMENT_SIGNED',
      agreementSentRoute:
        '/ROUTES.SELL/ROUTES.SELL_ORGANIZATION/ROUTES.SELL_AGREEMENT_SENT'
    }
  };
  const typeKey = isCompanySale ? 'company' : 'private';

  const baseReturnUrl = `${baseUrl}/${translateRoute(
    t,
    routes[typeKey].returnUrl
  )}`;
  const returnUrl = isCompanySale
    ? `${baseReturnUrl}/${baseObjectId}/${token}`
    : baseReturnUrl;

  const agreementSentRoute = translateRoute(
    t,
    routes[typeKey].agreementSentRoute
  );

  return { returnUrl, agreementSentRoute };
};

export const getSalesAgreementDigitalSigningRoute = ({
  t,
  baseObjectId,
  token
}: {
  t: TranslateFunction;
  baseObjectId: string;
  token: string;
}) => {
  return translateRoute(
    t,
    `/ROUTES.SELL/ROUTES.SELL_TERMS_AND_CONDITIONS/${baseObjectId}/${token}`
  );
};

export const getPrivateOrCompanySalesFlow = (salesFlow?: SalesFlowType) => {
  switch (salesFlow) {
    case 'CONSUMER_CARS':
      return 'PRIVATE';
    case 'CORPORATE_CARS':
    case 'CORPORATE_CARS_SMALL':
      return 'COMPANY';
    default:
      return null;
  }
};
