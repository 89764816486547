import { AxiosError } from 'axios';
import Auction from '~/App/shared/interfaces/Auction';
import { log } from '~/helpers/bugsnagHelper';
import {
  getHasSeenLatestAuctionsStatus as getHasSeenLatestAuctionsStatusCall,
  getTargetedAuctionsById,
  setUserHasSeenLatestAuctions as setUserHasSeenLatestAuctionsCall
} from '~/helpers/orchestration/myPages';
import { ReduxDispatch } from '../interfaces/Redux';

type GroupMemberState = {
  groupId: number;
  hasSeenLatestAuctions: boolean;
};

export const clearPollingTargetedAuctions = () => ({
  type: 'CLEAR_POLLING_THREADS_TARGETED_AUCTIONS' as const
});

export const setPollingTargetedAuctions = (
  threadArray: ReturnType<typeof setInterval>[]
) => ({
  type: 'SET_POLLING_THREADS_TARGETED_AUCTIONS' as const,
  threadArray: threadArray
});

export const hydrateTargetedAuctionsRequest = () => ({
  type: 'HYDRATE_TARGETED_AUCTIONS_REQUEST' as const,
  isLoading: true
});

export const getHasSeenLatestAuctionsStatusSuccess = (
  groupMemberState: GroupMemberState
) => ({
  type: 'GET_HAS_SEEN_LATEST_AUCTIONS_STATES_SUCCESS' as const,
  payload: groupMemberState
});

export const setHasSeenLatestAuctionsStatusSuccess = (
  groupMemberState: GroupMemberState
) => ({
  type: 'SET_HAS_SEEN_LATEST_AUCTIONS_STATES_SUCCESS' as const,
  payload: groupMemberState
});

export const clearHasSeenLatestAuctions = () => ({
  type: 'CLEAR_HAS_SEEN_LATEST_AUCTIONS' as const
});

export const hydrateTargetedAuctionsSuccess = (
  targetedAuctions: Auction[]
) => ({
  type: 'HYDRATE_TARGETED_AUCTIONS_SUCCESS' as const,
  targetedAuctions: targetedAuctions,
  isLoading: false
});

export const hydrateTargetedAuctionsFailed = () => ({
  type: 'HYDRATE_TARGETED_AUCTIONS_FAILED' as const,
  targetedAuctions: [],
  isLoading: false
});

type HydrateTargetedAuctionsArgs = {
  idToken: string;
  targetedAuctionId: number;
};

export const hydrateTargetedAuctions =
  ({ idToken, targetedAuctionId }: HydrateTargetedAuctionsArgs) =>
  async (dispatch: ReduxDispatch) => {
    dispatch(hydrateTargetedAuctionsRequest());

    try {
      const resp = await getTargetedAuctionsById({
        idToken,
        targetedAuctionId
      });

      dispatch(hydrateTargetedAuctionsSuccess(resp));
    } catch (error: unknown) {
      dispatch(hydrateTargetedAuctionsFailed());
    }
  };

type BaseStatusArgs = {
  idToken: string;
  groupId: number;
  memberId: string;
};

export const getHasSeenLatestAuctionsStatus =
  ({ idToken, groupId, memberId }: BaseStatusArgs) =>
  async (dispatch: ReduxDispatch) => {
    try {
      const groupMember = await getHasSeenLatestAuctionsStatusCall({
        idToken,
        groupId,
        memberId
      });
      dispatch(getHasSeenLatestAuctionsStatusSuccess(groupMember));
    } catch (error: unknown) {
      const err = error as AxiosError;
      if (err?.response && err?.response?.status === 403) {
        dispatch(clearPollingTargetedAuctions());
      }
    }
  };

type LastSeenAuctionArgs = BaseStatusArgs & { hasSeen: boolean };

export const setUserHasSeenLatestAuctions =
  ({ idToken, groupId, memberId, hasSeen }: LastSeenAuctionArgs) =>
  async (dispatch: ReduxDispatch) => {
    try {
      const groupMember = await setUserHasSeenLatestAuctionsCall({
        idToken,
        groupId,
        memberId,
        hasSeen
      });

      dispatch(setHasSeenLatestAuctionsStatusSuccess(groupMember));
    } catch (error: unknown) {
      log(error);
    }
  };

export type TargetedAuctionsActions = ReturnType<
  | typeof clearPollingTargetedAuctions
  | typeof setPollingTargetedAuctions
  | typeof hydrateTargetedAuctionsRequest
  | typeof hydrateTargetedAuctionsSuccess
  | typeof hydrateTargetedAuctionsFailed
  | typeof getHasSeenLatestAuctionsStatusSuccess
  | typeof setHasSeenLatestAuctionsStatusSuccess
  | typeof clearHasSeenLatestAuctions
>;
