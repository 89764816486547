import {
  DetailedHTMLProps,
  ScriptHTMLAttributes,
  useEffect,
  useRef,
  useState
} from 'react';

type HTMLScriptProps = DetailedHTMLProps<
  ScriptHTMLAttributes<HTMLScriptElement>,
  HTMLScriptElement
>;

type ValueType = string | boolean;

type Attributes<Type extends Record<string, ValueType>> = Type &
  HTMLScriptProps;

export const useDynamicScript = <
  T extends Record<string, ValueType> = Record<string, never>
>(
  src: string,
  options?: { attributes?: Attributes<T>; load?: boolean }
) => {
  const [state, setState] = useState<{
    id?: string;
    isLoaded: boolean;
    scriptTag: HTMLElement | null;
  }>({
    id: options?.attributes?.id ?? '',
    isLoaded: false,
    scriptTag: null
  });

  const attributesRef = useRef(options?.attributes);

  const load = options?.load ?? true;

  useEffect(() => {
    const attrs = attributesRef.current;
    const scriptId = attrs?.id ?? `dynamic-script-${src}`;
    const scriptTag = document.getElementById(scriptId);

    if (!scriptTag && load) {
      const element = document.createElement('script');
      element.setAttribute('id', scriptId);
      element.setAttribute('src', src);

      if (attrs) {
        Object.entries(attrs).forEach(([name, value]) => {
          /* Boolean attributes are considered to be true if they're present on the element at all. 
          set as empty string or the string corresponding to the element name
          https://developer.mozilla.org/en-US/docs/Web/API/Element/setAttribute#example */
          const val = typeof value === 'boolean' ? '' : value;
          element.setAttribute(name, val);
        });
      }

      document.body.appendChild(element);
    }

    setState({
      id: scriptId,
      isLoaded: true,
      scriptTag: document.getElementById(scriptId)
    });
  }, [src, attributesRef, load]);

  return state;
};
