import styled from 'styled-components';

const StatusPageContainer = styled.div<{
  $minHeight?: number;
  $unit?: 'rem' | 'vh';
}>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ $minHeight, $unit = 'vh' }) =>
    $minHeight && `min-height: ${$minHeight}${$unit};`}
`;

export default StatusPageContainer;
