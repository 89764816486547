import { ContactButton } from '../interfaces/store/ContactButton';

export const setContactBubble = (payload: boolean) => ({
  type: 'SET_CONTACT_BUBBLE' as const,
  payload
});

export const setContactButtonIsHidden = (payload: boolean) => ({
  type: 'SET_CONTACT_BUBBLE_IS_HIDDEN' as const,
  payload
});

export const setContactButtonPosition = (
  payload: ContactButton['position']
) => ({
  type: 'SET_CONTACT_BUTTON_POSITION' as const,
  payload
});

export const setKundoOpenAction = (payload: boolean) => ({
  type: 'SET_KUNDO_OPEN' as const,
  payload
});

export const setContactButtonDefaultTriggerEnabled = (payload: boolean) => ({
  type: 'SET_CONTACT_BUTTON_DEFAULT_TRIGGER_ENABLED' as const,
  payload
});

export type ContactButtonActions =
  | ReturnType<typeof setContactBubble>
  | ReturnType<typeof setContactButtonIsHidden>
  | ReturnType<typeof setContactButtonPosition>
  | ReturnType<typeof setContactButtonDefaultTriggerEnabled>
  | ReturnType<typeof setKundoOpenAction>;
