import React from 'react';
import { useSelector } from 'react-redux';
import {
  memberFirstName,
  memberLastName,
  nationalSecurityNumber,
  email as emailSelector,
  isMemberOrganisationPersonSelector,
  organisationNameSelector,
  memberOrganisationOrgNumber,
  member as memberSelector,
  memberOrganisationVatNumber
} from '~/App/shared/selectors/sessionSelector';
import {
  BodyText,
  Button,
  Select,
  Stack,
  TextField,
  Title
} from '@kvdbil/components';
import { useTranslation } from '~/Locale';
import AuthCloseButton from '../../components/AuthCloseButton';
import { formatNationalIdentificationNumber } from '~/helpers/checkout';
import { getCountries, getCountryFromValue } from '~/helpers/countries';

type Props = {
  onClose?(): void;
  isStandaloneView?: boolean;
};

const CompletedView = ({ onClose, isStandaloneView }: Props) => {
  const { t } = useTranslation();
  const firstName = useSelector(memberFirstName);
  const lastName = useSelector(memberLastName);
  const idNr = useSelector(nationalSecurityNumber);
  const email = useSelector(emailSelector);
  const isOrganisation = useSelector(isMemberOrganisationPersonSelector);
  const organisationName = useSelector(organisationNameSelector);
  const organisationNumber = useSelector(memberOrganisationOrgNumber);
  const vatNumber = useSelector(memberOrganisationVatNumber);
  const { addresses } = useSelector(memberSelector);
  const country = addresses?.[0]?.country;
  const isSwedish = country === 'se';

  return (
    <Stack>
      {onClose && !isStandaloneView && <AuthCloseButton onClose={onClose} />}
      <Stack spacing={3}>
        <Title as="h2" style={{ textAlign: 'center' }}>
          {t('Completed!')}
        </Title>

        <Stack>
          <BodyText style={{ textAlign: 'center' }}>
            {t('Welcome! Now you can start looking for a car that suits you.')}
          </BodyText>

          {!isOrganisation && idNr && (
            <TextField
              value={
                isSwedish ? formatNationalIdentificationNumber(idNr) : idNr
              }
              isDisabled
              label={
                isSwedish
                  ? t('National identification number')
                  : t('National identification number (optional)')
              }
              data-testid="input-national-identification-number"
            />
          )}

          <TextField
            value={firstName}
            isDisabled
            label={t('First name')}
            data-testid="input-first-name"
          />
          <TextField
            value={lastName}
            isDisabled
            label={t('Last name')}
            data-testid="input-last-name"
          />
          {isOrganisation && (
            <>
              <TextField
                value={organisationName}
                isDisabled
                label={t('Organisation name')}
                data-testid="input-organisation-name"
              />
              <TextField
                value={organisationNumber}
                isDisabled
                label={t('Organisation number')}
                data-testid="input-organisation-number"
              />
              {!isSwedish && (
                <>
                  {vatNumber && (
                    <TextField
                      value={vatNumber}
                      isDisabled
                      label={t('VAT number')}
                    />
                  )}
                  <Select
                    name=""
                    onChange={() => null}
                    label={t('Country')}
                    value={getCountryFromValue(country ?? '', t)}
                    options={getCountries(t)}
                    isDisabled
                  />
                </>
              )}
            </>
          )}
          <TextField
            value={email}
            isDisabled
            label={t('Email')}
            data-testid="input-email"
          />
        </Stack>

        <Button size="regular" fullWidth onClick={onClose} color="primary">
          {t('Close')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default CompletedView;
