import React, { useEffect, useMemo } from 'react';
import { Tabs, Tab } from '@kvdbil/components';
import AuctionsSlideShow from '~/App/shared/components/AuctionsSlideShow';
import { useTranslation } from '~/Locale';
import { useDispatch, useSelector } from 'react-redux';
import { recentlyViewedAuctionsList } from '~/App/shared/selectors/recentlyViewedAuctions';
import {
  getMyAuctions,
  getMyFavouriteAuctions
} from '~/App/shared/actions/auctions';
import { mySavedAuctions, savedAuctions } from '~/App/shared/selectors/global';
import styled from 'styled-components';
import Section from '~/App/shared/components/Layout/Section';

// To prevent wrapping on mobile
const Container = styled.div`
  padding: 3rem auto;

  & ul {
    flex-wrap: nowrap !important;
  }
`;

const UserAuctions = ({ headline }: { headline?: string }) => {
  const savedByMeAuctions = useSelector(mySavedAuctions);
  const biddedOnAuctions = useSelector(savedAuctions);
  const recentlyViewedAuctions = useSelector(recentlyViewedAuctionsList);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const preferredTabLabel = t('Latest Auctions');

  const tabs = useMemo(
    () => [
      {
        label: t('My bids'),
        auctions: biddedOnAuctions,
        isActive: Boolean(biddedOnAuctions?.length)
      },
      {
        label: t('Saved Auctions'),
        auctions: savedByMeAuctions,
        isActive: Boolean(savedByMeAuctions?.length)
      },
      {
        label: preferredTabLabel,
        auctions: recentlyViewedAuctions,
        isActive: Boolean(recentlyViewedAuctions?.length)
      }
    ],
    [
      biddedOnAuctions,
      recentlyViewedAuctions,
      savedByMeAuctions,
      t,
      preferredTabLabel
    ]
  );

  const activeIndex = useMemo(() => {
    const tab = tabs.find(tab => tab.label === preferredTabLabel);
    return tab?.isActive ? tabs.indexOf(tab) : -1;
  }, [tabs, preferredTabLabel]);

  useEffect(() => {
    dispatch(getMyAuctions());
    dispatch(getMyFavouriteAuctions());
  }, [dispatch]);

  const hasAuctionsToShow = tabs.some(tab => {
    return tab.auctions.length > 0;
  });

  if (!hasAuctionsToShow) return <></>;

  return (
    <Container>
      <Section backgroundColor="gray">
        <Tabs
          activeIndex={activeIndex}
          borderColor="light5"
          headline={headline}
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} isDisabled={!tab.isActive}>
              <AuctionsSlideShow auctions={tab.auctions} />
            </Tab>
          ))}
        </Tabs>
      </Section>
    </Container>
  );
};

export default UserAuctions;
