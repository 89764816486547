import Auction from '~/App/shared/interfaces/Auction';
import { useTranslation } from '~/Locale';
import { useDispatch, useSelector } from 'react-redux';
import { favouriteAuctions as favouriteAuctionsSelector } from '~/App/shared/selectors/global';
import { toggleFavourite as toggleFavouriteAction } from '~/App/shared/actions/favouriteAuctions';
import { useHistory } from 'react-router';

const useProductCardIconsProps = (auction: Auction) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const favouriteAuctions = useSelector(favouriteAuctionsSelector);

  const toggleFavourite = () => {
    dispatch(toggleFavouriteAction(t, auction.id, history.push));
  };

  return {
    isCertified: auction.processObject?.certified,
    isEcoFriendly: auction.processObject?.properties?.ecoFriendly,
    isReleasable: auction.processObject?.isReleasable,
    isFavourite: favouriteAuctions.includes(auction.id),
    toggleFavourite
  };
};

export default useProductCardIconsProps;
