import React, { useState, useEffect, useRef, MouseEventHandler } from 'react';
import dayjs from 'dayjs';
import { requestErrorHandler } from '~/helpers/notifyError';
import { BidIcon, PhoneIcon, resetButtonStyle } from '@kvdbil/components';
import styled from 'styled-components';
import { useTranslation } from '~/Locale';
import { OpeningHour } from '../interfaces/OpeningHours';
import { getOpeningHours } from '~/helpers/orchestration/customerService';

type ImboxEvent = [string] | [string, (status: string) => void];

declare global {
  interface Window {
    _imbox: {
      push(event: ImboxEvent): void;
    };
  }
}

type State = {
  day?: OpeningHour | null;
  chatStatus: string;
};

const IconLink = styled.button`
  ${resetButtonStyle};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.secondary.main};
  svg {
    color: inherit;
    margin-right: 0.5rem;
  }
`;

const PhoneLink = styled.a`
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.5rem;
    color: ${({ theme }) => theme.colors.gray.light1};
  }
`;

const ListItem = styled.li`
  display: flex;
  font-weight: ${({ theme }) => theme.typography.fontBaseBoldWeight};
  justify-content: space-between;
`;

const Cell = styled.div<{ textAlign?: 'left' | 'right'; isAlert?: boolean }>`
  color: ${({ isAlert, theme }) =>
    // TODO: replace this with theme.colors.error.main when theme is updated
    isAlert ? '#ef5b5b' : theme.colors.gray.light1};
  width: 100%;
  padding: 0.625em 0;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray.light3}`};
  text-align: ${({ textAlign }) => textAlign || 'left'};
`;

const CustomerService = () => {
  const [state, setState] = useState<State>({ day: null, chatStatus: '' });
  const { t } = useTranslation();
  const thread = useRef<ReturnType<typeof setTimeout> | null>();
  const imbox = typeof window !== 'undefined' && window._imbox;

  useEffect(() => {
    const checkImboxStatus = () => {
      if (imbox) {
        imbox.push([
          'getOnlineStatus',
          status => {
            setState(s => ({
              ...s,
              chatStatus: status
            }));
          }
        ]);
      }
    };

    thread.current = setInterval(() => {
      if (typeof window !== 'undefined' && thread.current) {
        window.clearInterval(thread.current);
        thread.current = setInterval(() => {
          checkImboxStatus();
        }, 5000);
      }
    }, 1000);
  }, [imbox]);

  useEffect(() => {
    const currentDate = dayjs().format('YYYY-MM-DD');

    getOpeningHours(currentDate)
      .then(data => {
        if (data?.length < 0) {
          return;
        }

        data.forEach((day: OpeningHour) => {
          const isCurrentDate = day.date.format('YYYY-MM-DD') === currentDate;
          if (isCurrentDate) {
            setState(s => ({ ...s, day }));
          }
        });
      })
      .catch(requestErrorHandler);

    return () => {
      if (thread.current) {
        window.clearInterval(thread.current);
      }
    };
  }, [imbox]);

  const handleToggleChat: MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault();
    if (imbox) {
      imbox.push(['openChat']);
    }
  };

  const { day, chatStatus } = state;
  const dayType = day?.type;

  const openUntilText = `${t('Open until')} ${
    dayjs.isDayjs(day?.close) ? day?.close.format('HH:mm') : ''
  } ${t('today')}`;

  return (
    <div>
      <h3>{t('Customer Service')}</h3>
      <ul>
        <ListItem>
          <Cell>
            <PhoneLink href="tel:+46101673000">
              <PhoneIcon />
              <span>010 167 3000</span>
            </PhoneLink>
          </Cell>
          {dayType === 'CLOSED' && (
            <Cell textAlign="right" isAlert>
              {t('Customer Service Closed')}
            </Cell>
          )}
          {(dayType === 'DEFAULT' || dayType === 'EXTRA') && (
            <Cell textAlign="right">{openUntilText}</Cell>
          )}
        </ListItem>
        {chatStatus === '1' && (
          <ListItem>
            <Cell>
              <IconLink onClick={handleToggleChat}>
                <BidIcon />
                <span>{t('Chat with us')}</span>
              </IconLink>
            </Cell>
            {dayType === 'CLOSED' && (
              <Cell textAlign="right" isAlert>
                {t('Customer Service Closed')}
              </Cell>
            )}
            {(dayType === 'DEFAULT' || dayType === 'EXTRA') && (
              <Cell textAlign="right">{openUntilText}</Cell>
            )}
          </ListItem>
        )}
      </ul>
    </div>
  );
};

export default CustomerService;
