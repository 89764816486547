import React from 'react';
import { Helmet } from 'react-helmet';
import { getBaseCanonicalUrl } from '~/helpers/baseCanonicalUrl';

interface Props {
  pathname: string;
}

const CanonicalHeadLink = ({ pathname }: Props) => {
  const canonicalUrl = getBaseCanonicalUrl(pathname);

  return (
    <Helmet>
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
};

export default CanonicalHeadLink;
