import { PartnershipPageQuery } from '~/config/generated/graphql';

export const handlePartnershipPage = ({
  page,
  allPartnershipItems
}: Partial<PartnershipPageQuery>) => {
  return {
    name: page?.name,
    loggedInTitle: page?.loggedInTitle,
    publicTitle: page?.publicTitle,
    loggedInContent: page?.loggedInContent,
    publicContent: page?.publicContent,
    seo: page?.seo,
    optionalHero: page?.optionalHero,
    allPartnershipItems,
    kvdHelperUrl: page?._editingUrl
  };
};
