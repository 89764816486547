import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Locale } from '~/App/shared/localization/types';
import 'dayjs/locale/sv';
import 'dayjs/locale/pl';
import 'dayjs/locale/et';
import 'dayjs/locale/fi';

const customConfigs: Partial<Record<Locale, Record<string, unknown>>> = {
  sv: {
    calendar: {
      sameDay: '[Idag] HH:mm',
      nextDay: '[Imorgon] HH:mm',
      lastDay: '[Igår] HH:mm',
      nextWeek: '[På] dddd HH:mm',
      lastWeek: '[I] dddd[s] HH:mm',
      sameElse: 'YYYY-MM-DD'
    }
  }
};

dayjs.extend(updateLocale);
dayjs.extend(advancedFormat);

export const loadDayJSLocale = (language: Locale) => {
  if (language === Locale.EN) {
    return;
  }

  if (customConfigs?.[language]) {
    dayjs.updateLocale(
      language,
      customConfigs[language] as Record<string, unknown>
    );
  }

  dayjs.locale(language);
};

export default dayjs;
