import { theme } from '@kvdbil/components';
import { AnswerSize, AnswerType } from './types';
import { TranslateFunction } from '~/Locale';

export const answers = [
  {
    titleKey: 'Not at all',
    value: 'not_at_all'
  },
  {
    titleKey: 'A little',
    value: 'nice_to_have'
  },
  {
    titleKey: 'Very',
    value: 'important'
  }
];

export const getAnswerTitleByValue = (value: AnswerType) => {
  const index = answers.findIndex(el => el.value === value);
  return index > -1 ? answers[index].titleKey : '';
};

export const getColor = (value: AnswerType) => {
  switch (value) {
    case 'nice_to_have':
      return theme.colors.secondary.lighter;
    case 'important':
      return theme.colors.secondary.main;
    case 'not_at_all':
    default:
      return theme.colors.gray.dark4;
  }
};

export const getSizeInPx = (size: AnswerSize) => {
  switch (size) {
    case 'big':
      return '40px';
    case 'small':
      return '24px';
    case 'medium':
    default:
      return '32px';
  }
};

export const setSize = (sizePx: string) =>
  `width: ${sizePx}; height: ${sizePx}`;

export const getTranslatedAnswerText = (
  t: TranslateFunction,
  answerValue: AnswerType
) => {
  switch (answerValue) {
    case 'nice_to_have':
      return t('A little');
    case 'important':
      return t('Very');
    case 'not_at_all':
      return t('Not at all');
    default:
      return '';
  }
};
