import { Button, Message, Modal, Stack } from '@kvdbil/components';
import React, { useState } from 'react';
import Select, {
  components,
  GroupBase,
  OptionProps,
  SingleValueProps
} from 'react-select';
import { ALL_LOCALE_CODES } from '~/config/constants';
import { useDispatch, useSelector } from 'react-redux';
import { localizationSelector } from '~/App/shared/selectors/localization';
import { useTranslation } from '~/Locale';
import { generatePath } from 'react-router';
import { useLocation } from 'react-router-dom';
import { appStartLoading } from '~/App/shared/actions/app';
import styled from 'styled-components';
import { trimTrailingSlash } from '~/App/shared/localization/helpers';
import { fetchLocalizedRoute } from '~/helpers/orchestration/app';
import { getLanguageSelectModalStyles } from '~/App/shared/localization/components/selectStyles';
import {
  Country,
  countries
} from '~/App/shared/localization/components/constants';
import { useLanguageSelection } from '~/App/shared/localization/hooks/useLanguageSelection';

const StyledMessage = styled(Message)`
  margin: 1.5rem;
`;

const Option = (props: OptionProps<Country, false, GroupBase<Country>>) => {
  return (
    <components.Option {...props}>
      {props.data.icon}
      {props.data.label}
    </components.Option>
  );
};

type Props = {
  isPlacedInHeader?: boolean;
};

function LanguageSelect({ isPlacedInHeader = false }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const {
    currentLocale,
    currentRoute: { originalPathname, params }
  } = useSelector(localizationSelector);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const filteredCountries = countries.filter(country =>
    ALL_LOCALE_CODES.includes(country.value)
  );

  const [selectedCountry, setSelectedCountry] = useState<Country | undefined>(
    () => {
      return filteredCountries.find(country => country.value === currentLocale);
    }
  );

  const { getRedirectUrl } = useLanguageSelection();

  const handleChange = async (selectedCountry: Country) => {
    if (selectedCountry.value === currentLocale) return;

    setSelectedCountry(
      filteredCountries.find(country => country.value === selectedCountry.value)
    );

    if (!originalPathname || !currentLocale) {
      setIsModalOpen(true);
      return;
    }

    if (originalPathname === '/') {
      dispatch(appStartLoading());
      window.location.href = getRedirectUrl(selectedCountry.value, search);
      return;
    }

    try {
      const translatedPath = await fetchLocalizedRoute({
        currentLocale,
        requestedLocale: selectedCountry.value,
        originalRoute: originalPathname,
        currentPathname: pathname,
        params
      });

      const generatedPath = trimTrailingSlash(
        generatePath(translatedPath, params)
      );
      const redirectUrl = getRedirectUrl(
        selectedCountry.value,
        `${generatedPath}${search}`
      );

      dispatch(appStartLoading());

      window.location.href = redirectUrl;
    } catch (e) {
      setIsModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setSelectedCountry(
      filteredCountries.find(country => country.value === currentLocale)
    );
    setIsModalOpen(false);
  };

  const SingleValue = ({
    children,
    ...props
  }: SingleValueProps<Country, false, GroupBase<Country>>) => (
    <components.SingleValue {...props}>
      {selectedCountry?.icon}
      {children}
    </components.SingleValue>
  );

  return (
    <>
      <Select<Country, false, GroupBase<Country>>
        value={selectedCountry}
        options={filteredCountries}
        onChange={handleChange}
        components={{
          IndicatorSeparator: () => null,
          Option,
          SingleValue
        }}
        styles={{ ...getLanguageSelectModalStyles(isPlacedInHeader) }}
        isSearchable={false}
      />
      <Modal isOpen={isModalOpen} onClose={handleModalClose} withPortal>
        <StyledMessage withIcon type="warning">
          {t('The current page cannot be switched to the selected language.')}
        </StyledMessage>

        {selectedCountry && (
          <Stack align="center">
            <Button
              color="info"
              variant="outline"
              as="span"
              prefixIcon={selectedCountry?.icon}
              style={{ width: 'fit-content' }}
              onClick={() => {
                if (selectedCountry?.value) {
                  dispatch(appStartLoading());
                  window.location.href = getRedirectUrl(
                    selectedCountry?.value,
                    '/'
                  );
                }
              }}
            >
              {t('Go to startpage')}
            </Button>
          </Stack>
        )}
      </Modal>
    </>
  );
}

export default LanguageSelect;
