import React from 'react';
import styled from 'styled-components';

import { Button } from '@kvdbil/components';
import { useTranslation } from '~/Locale';

const Container = styled.div`
  display: flex;
  margin-top: auto;
  align-items: center;
  flex-direction: column;
`;

const ButtonExplanation = styled.span`
  font-size: 13px;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.gray.dark3};
`;

export interface VerifyFooterProps {
  handleClose?(): void;
}

const VerifyFooter = ({ handleClose }: VerifyFooterProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Button variant="outline" color="neutral" onClick={handleClose} fullWidth>
        {t('Go back')}
      </Button>
      <ButtonExplanation>
        {t('You will not be able to place a bid until ...')}
      </ButtonExplanation>
    </Container>
  );
};

export default VerifyFooter;
