import { Locale } from '~/App/shared/localization/types';
import axios from '~/helpers/orchestration/axiosWithAuthHandling';

export interface FetchLocalizedRouteResponse {
  localizedRoute: string;
}
export const fetchLocalizedRoute = ({
  currentLocale,
  requestedLocale,
  originalRoute,
  currentPathname,
  params
}: {
  requestedLocale: Locale;
  currentLocale: Locale;
  originalRoute: string;
  currentPathname: string;
  params: Record<string, string>;
}) => {
  let stringifiedParams = '';
  try {
    stringifiedParams = JSON.stringify(params);
  } catch (e: unknown) {}
  const queryParams = new URLSearchParams({
    currentLocale,
    requestedLocale,
    originalRoute: encodeURIComponent(originalRoute),
    currentPathname: encodeURIComponent(currentPathname),
    params: stringifiedParams
  });

  return axios
    .get<FetchLocalizedRouteResponse>(
      `/api/v1/localized-route?${queryParams.toString()}`,
      {
        responseType: 'json'
      }
    )
    .then(({ data }) => data.localizedRoute);
};
