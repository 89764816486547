import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import createLocale, {
  getLocale
} from './App/shared/localization/createLocale';
import { log } from '~/helpers/bugsnagHelper';
import { LocaleData } from './App/shared/localization/loadLocaleOnServer';
import Jed from 'jed';
import { I18N } from './App/shared/interfaces/Localization';

interface Options {
  showKeys?: boolean;
}
interface ContextProps {
  locale: LocaleData | null;
  options?: Options;
  setShowKeys: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

const logSprintfError = (err: unknown, value: string) =>
  log(
    new Error(
      `Error in translate function during string formating (i18n.sprintf) for value: ${value}`
    ),
    {
      err
    }
  );

export const Localization = createContext<ContextProps>({
  locale: null,
  setShowKeys: () => {
    //
  },
  options: {
    showKeys: false
  }
});

interface LocalizationProps extends ContextProps {
  children: ReactNode;
  options?: Options;
}

export const LocalizationProvider = ({
  children,
  locale,
  options
}: Omit<LocalizationProps, 'setShowKeys'>) => {
  const [showKeys, setShowKeys] = useState(options?.showKeys);
  return (
    <Localization.Provider
      value={{ locale, options: { ...options, showKeys }, setShowKeys }}
    >
      {children}
    </Localization.Provider>
  );
};

export type TranslateFunction = (
  value: string,
  ...args: (number | string)[]
) => string;

export const useTranslation = () => {
  const { locale: localeData, setShowKeys, options } = useContext(Localization);
  const i18n = useMemo(
    () => (localeData ? createLocale(localeData) : getLocale()),
    [localeData]
  );

  const translate: TranslateFunction = useCallback(
    (value: string, ...args: (number | string)[]) => {
      const returnValue = options?.showKeys
        ? `§-${value}`
        : i18n.gettext(value);

      if (args.length) {
        try {
          return i18n.sprintf(returnValue, ...args);
        } catch (error: unknown) {
          logSprintfError(error, value);
        }
      }

      return returnValue;
    },
    [i18n, options?.showKeys]
  );

  return { i18n, t: translate, setShowKeys, options };
};

const translate =
  (i18n: I18N): TranslateFunction =>
  (value: string, ...args: (number | string)[]) => {
    const returnValue = i18n.gettext(value);

    if (args.length) {
      try {
        return i18n.sprintf(returnValue, ...args);
      } catch (error: unknown) {
        logSprintfError(error, value);
      }
    }

    return returnValue;
  };

/*
  Basically this function should be use only in DynamiMeta component and
  sitemap request handler where both sv and en translation are needed
  at the same time.
*/
export const getSvAndEnTranslation = ({
  localeSV,
  localeEN
}: {
  localeSV: LocaleData;
  localeEN: LocaleData;
}) => {
  const i18nSV: I18N = new Jed(localeSV);
  const i18nEN: I18N = new Jed(localeEN);
  return { i18nSV, i18nEN, tSV: translate(i18nSV), tEN: translate(i18nEN) };
};

/*
  Basically this function should be use only in non-React function. For React
  components useTranslation hook can be used instead.
*/
export const getCurrentTranslation = ({
  currentLocale
}: {
  currentLocale: LocaleData;
}) => {
  const i18n: I18N = new Jed(currentLocale);
  return { i18n, t: translate(i18n) };
};
