import React, { useState } from 'react';
import { Image, ResponsiveImageType } from 'react-datocms';
import { FileField } from '~/config/generated/graphql';

type Props = {
  responsiveImage: ResponsiveImageType;
  focalPoint: FileField['focalPoint'];
};

const HeroImage = ({ responsiveImage, focalPoint }: Props) => {
  const [loaded, setLoaded] = useState(false);

  const focalX = Number(focalPoint?.x) * 100;
  const focalY = Number(focalPoint?.y) * 100;

  // height or aspectRatio could break height of hero container
  const responsiveImageData = {
    ...responsiveImage,
    height: undefined,
    aspectRatio: undefined
  };

  return (
    <Image
      data={responsiveImageData}
      objectFit="cover"
      style={{ position: 'static', maxWidth: undefined, height: 0 }}
      placeholderStyle={{
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        opacity: 1
      }}
      pictureStyle={{ opacity: loaded ? 1 : 0 }}
      objectPosition={
        !isNaN(focalX) && !isNaN(focalY) ? `${focalX}% ${focalY}%` : undefined
      }
      onLoad={() => setLoaded(true)}
      srcSetCandidates={[0.25, 0.5, 0.75, 1]}
      priority
      className="heroImage"
    />
  );
};

export default HeroImage;
