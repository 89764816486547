import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { hexToRGB, mq } from '@kvdbil/components';

const Container = styled.div<{ $useRedBackground: boolean }>`
  font-family: ${({ theme }) => theme.typography.fontAlternativeFamily};
  font-weight: ${({ theme }) => theme.typography.fontAlternativeBoldWeight};
  font-size: 1rem;
  line-height: 1rem;
  display: flex;
  padding: 0.25rem;
  align-items: center;
  background-color: ${({ theme, $useRedBackground }) =>
    $useRedBackground
      ? theme.colors.error.main
      : hexToRGB(theme.colors.background.gray, 0.66)};
  color: ${({ theme, $useRedBackground }) =>
    $useRedBackground ? theme.colors.text.light : theme.colors.text.dark};

  svg {
    font-size: inherit;
    margin-right: 0.25rem;
    width: 0.75rem;
    height: 0.75rem;
  }

  ${mq('tablet')} {
    font-size: 0.75rem;
    line-height: 0.75rem;
  }
`;

interface Props {
  useRedBackground?: boolean;
  children: ReactNode;
}

export const StatusTagWrapper = ({
  useRedBackground = false,
  children
}: Props) => {
  return <Container $useRedBackground={useRedBackground}>{children}</Container>;
};
