import React from 'react';
import { ContactItemListRecord } from '~/config/generated/graphql';
import ContactItem from '~/App/views/DynamicPage/components/ContactItem';

type Props = {
  items: ContactItemListRecord['items'];
};

const ContactPageItems = ({ items }: Props) => {
  return (
    <>
      {items?.links?.map((item, key) => (
        <ContactItem key={item?.id ?? key} item={item} />
      ))}
    </>
  );
};

export default ContactPageItems;
