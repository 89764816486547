import React from 'react';
import { TextTabsBlockRecord } from '~/config/generated/graphql';
import { Tab, Tabs } from '@kvdbil/components';
import StructuredText from '~/App/components/DatoCMS/StructuredText';

type Props = {
  record: TextTabsBlockRecord;
};

const TextTabsBlock = ({ record }: Props) => {
  return (
    <Tabs headline={record?.title ?? ''} activeColor="secondary">
      {record?.tabs?.map(({ id, tabName, body }, key) => {
        return (
          <Tab key={id ?? key} label={tabName}>
            <StructuredText data={body} />
          </Tab>
        );
      })}
    </Tabs>
  );
};

export default TextTabsBlock;
