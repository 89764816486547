import { combineReducers } from 'redux';
import {
  AppLocalizationAction,
  AppStartStopAction,
  setFooterHiddenAction
} from '../actions/app';
import { ReduxStore } from '../interfaces/store';

const isFooterHidden = (
  state = false,
  action: ReturnType<typeof setFooterHiddenAction>
): ReduxStore['app']['isFooterHidden'] => {
  switch (action.type) {
    case 'APP_SET_FOOTER_HIDDEN':
      return action.payload;

    default:
      return state;
  }
};

const isLoading = (
  state = false,
  action: AppStartStopAction
): ReduxStore['app']['isLoading'] => {
  switch (action.type) {
    case 'APP_START_LOADING':
      return true;

    case 'APP_STOP_LOADING':
      return false;

    default:
      return state;
  }
};

const currentRouteInitailState = {
  originalPathname: null,
  params: {}
};

const initialLocalizationState = {
  domainLocale: null,
  currentLocale: null,
  currentRoute: currentRouteInitailState,
  previousURL: ''
};

const localization = (
  state = initialLocalizationState,
  action: AppLocalizationAction
): ReduxStore['app']['localization'] => {
  switch (action.type) {
    case 'APP_LOCALIZATION_LOCALE_CODE':
      return { ...state, [action.payload.key]: action.payload.localeCode };
    case 'APP_LOCALIZATION_CURRENT_ROUTE':
      return { ...state, currentRoute: action.payload };
    case 'APP_LOCALIZATION_PREVIOUS_URL':
      return { ...state, previousURL: action.payload };

    default:
      return state;
  }
};

const app = combineReducers({
  isLoading: isLoading,
  localization,
  isFooterHidden
});

export default app;
