import { SalesFlowType } from '~/App/shared/types/SalesFlowType';
import { TranslateFunction } from '~/Locale';

export const getContactInfo = (
  t: TranslateFunction,
  salesFlow?: SalesFlowType,
  location = ''
) => {
  const defaultInfo = {
    telephoneNumber: t('CUSTOMER_CENTER_TELEPHONE'),
    email: 'kundcenter@kvdbil.se'
  };

  if (location === '/foretagsbil') {
    return {
      telephoneNumber: t('CORPORATE_CARS_TELEPHONE'),
      email: t('CORPORATE_CARS_EMAIL')
    };
  }

  switch (salesFlow) {
    case 'CORPORATE_CARS':
      return {
        telephoneNumber: t('CORPORATE_CARS_TELEPHONE'),
        email: t('CORPORATE_CARS_EMAIL')
      };
    case 'CORPORATE_CARS_SMALL':
      return {
        telephoneNumber: t('CORPORATE_CARS_SMALL_TELEPHONE'),
        email: t('CORPORATE_CARS_SMALL_EMAIL')
      };
    case 'CONSUMER_CARS':
      return {
        telephoneNumber: t('CONSUMER_CARS_TELEPHONE'),
        email: t('CONSUMER_CARS_EMAIL')
      };
    default:
      return defaultInfo;
  }
};
