import {
  BodyText,
  Button,
  CheckBox,
  Message,
  mq,
  Title
} from '@kvdbil/components';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import TextFieldWithControl from '~/App/shared/components/Fields/TextFieldWithControl';
import { EMAIL_MAX_LENGTH } from '~/App/shared/validation/constants';
import { requestErrorHandler } from '~/helpers/notifyError';
import { submitPartnerLeasingForm } from '~/helpers/orchestration/partnerLeasing';
import { regexEmail, regexPhoneNumber } from '~/helpers/regex';
import { useTranslation } from '~/Locale';

const Container = styled.div`
  ${mq(null, 'tablet')} {
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const ContentWrapper = styled.div`
  padding: 1em 2em;
  border: 0.1em solid ${({ theme }) => theme.colors.gray.light3};
`;

const Row = styled.div<{ isButtonRow?: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ isButtonRow }) => (isButtonRow ? '0.5rem' : '1rem')};

  ${mq(null, 'tablet')} {
    justify-content: flex-start;
  }
`;

const Form = styled.form`
  max-width: 500px;
  flex: 2;

  ${mq(null, 'tablet')} {
    max-width: none;
    width: auto;
    flex-direction: row;
  }
`;

const CheckBoxWrapper = styled.div`
  margin-top: 1rem;
`;

const FieldWrapper = styled.div`
  margin-top: 1em;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${mq('tablet')} {
    flex-direction: row;
  }
`;

const ContentText = styled.div`
  flex: 1;
  padding-top: 0.2rem;

  ${mq(null, 'tablet')} {
    margin-top: 1rem;
  }

  ${mq('tablet')} {
    padding-left: 1rem;
    margin-top: 0.5em;
  }
`;

export type InterstFormInput = {
  fullName: string;
  email: string;
  phoneNumber: string;
  nationalIdentificationNumber: string;
};

type STATUS = 'UNSUBMITTED' | 'PROCESSING' | 'SUCCESS' | 'FAILED';

interface Props {
  title?: string;
  description?: string;
  sendButton?: string;
}

const PartnerLeasingForm = ({ title, description, sendButton }: Props) => {
  const [status, setStatus] = useState<STATUS>('UNSUBMITTED');
  const [hasApprovedGDPR, setHasApprovedGDPR] = useState(false);

  const { t } = useTranslation();
  const { handleSubmit, control, reset, getValues } = useForm({
    defaultValues: {
      fullName: '',
      email: '',
      phoneNumber: ''
    }
  });

  const submitForm = async () => {
    try {
      const values = getValues();
      setStatus('PROCESSING');
      await submitPartnerLeasingForm(values);
      reset();
      setStatus('SUCCESS');
    } catch (error: unknown) {
      requestErrorHandler(error);
      setStatus('FAILED');
    }
  };

  const isLoading = status === 'PROCESSING';
  const isFailed = status === 'FAILED';
  const isSuccessful = status === 'SUCCESS';

  return (
    <Container>
      {!isSuccessful ? (
        <ContentWrapper>
          {title && <Title as="h2">{title}</Title>}
          <Content>
            <Form onSubmit={handleSubmit(submitForm)}>
              <FieldWrapper>
                <TextFieldWithControl
                  control={control}
                  name="fullName"
                  label={t('Name')}
                  rules={{
                    required: {
                      value: true,
                      message: t("First name can't be blank")
                    }
                  }}
                />
              </FieldWrapper>
              <FieldWrapper>
                <TextFieldWithControl
                  control={control}
                  name="email"
                  label={t('Email')}
                  type="email"
                  rules={{
                    pattern: {
                      value: regexEmail,
                      message: t('Invalid email address')
                    },
                    required: {
                      value: true,
                      message: t("Email can't be blank")
                    },
                    maxLength: {
                      value: EMAIL_MAX_LENGTH,
                      message: t(
                        'Email is too long (maximum is %s characters)',
                        EMAIL_MAX_LENGTH
                      )
                    }
                  }}
                />
              </FieldWrapper>
              <FieldWrapper>
                <TextFieldWithControl
                  control={control}
                  name="phoneNumber"
                  label={t('Phone number')}
                  rules={{
                    required: {
                      value: true,
                      message: t("Phone number can't be blank")
                    },
                    pattern: {
                      value: regexPhoneNumber,
                      message: t('Invalid phone number')
                    }
                  }}
                />
              </FieldWrapper>

              {isFailed && (
                <Row>
                  <Message type="error">
                    {t('PartnerLeasing-send-failed')}
                  </Message>
                </Row>
              )}

              <CheckBoxWrapper>
                <CheckBox
                  onChange={e => {
                    setHasApprovedGDPR(e.target.checked);
                  }}
                  name="approvedGDPR"
                  checked={hasApprovedGDPR}
                  label={t('PartnerLeasing-GDPR-approval')}
                />
              </CheckBoxWrapper>

              <FieldWrapper>
                <Row isButtonRow>
                  <Button
                    color="secondary"
                    fullWidth
                    type="submit"
                    isLoading={isLoading}
                    isDisabled={!hasApprovedGDPR}
                  >
                    {sendButton}
                  </Button>
                </Row>
              </FieldWrapper>
            </Form>

            <ContentText>
              <BodyText>{description}</BodyText>
            </ContentText>
          </Content>
        </ContentWrapper>
      ) : (
        <Message type="success">
          {t('Partner leasing confirmation message.')}
        </Message>
      )}
    </Container>
  );
};

export default PartnerLeasingForm;
