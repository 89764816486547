import throttle from 'lodash.throttle';
import { useRef } from 'react';

interface ThrottleSettings {
  leading?: boolean;
  trailing?: boolean;
}

type anyArgsFunction = (...args: unknown[]) => void;

export const useThrottle = (
  callback: anyArgsFunction,
  delay: number,
  settings?: ThrottleSettings
) => {
  const throttledFunc = useRef(throttle(callback, delay, settings));
  return throttledFunc.current;
};
