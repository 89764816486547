import { Location } from 'history';
import {
  FilterSearchParamWithOrderKey,
  FilterSearchParamsValue
} from '~/App/shared/types/FilterSearchParam';
import {
  isOrderSearchParamKey,
  isFilterSearchParamKey,
  isTermsSearchParamKey
} from './filterSearchParams';

export const serializeFilters = (
  deserializedFilters: Record<FilterSearchParamWithOrderKey, string>
): string => {
  return Object.entries(deserializedFilters)
    .sort(([key1], [key2]) => key1.localeCompare(key2))
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
};

export const deserializationFilters = (
  serializedFilters: string,
  drop?: FilterSearchParamWithOrderKey[]
): Record<FilterSearchParamWithOrderKey, FilterSearchParamsValue> => {
  const params = new URLSearchParams(serializedFilters);
  const filters: Map<FilterSearchParamWithOrderKey, string> = new Map();

  for (const [key, value] of params.entries()) {
    if (
      !isFilterSearchParamKey(key) &&
      !isOrderSearchParamKey(key) &&
      !isTermsSearchParamKey(key)
    )
      continue;

    if (drop?.includes(key as FilterSearchParamWithOrderKey)) continue;

    filters.set(key, value);
  }

  return Object.fromEntries(filters) as Record<
    FilterSearchParamWithOrderKey,
    FilterSearchParamsValue
  >;
};

export const reSerializeFilters = (
  serializedFilters: string,
  drop?: FilterSearchParamWithOrderKey[]
): string => {
  return serializeFilters(deserializationFilters(serializedFilters, drop));
};

export const locationToFilters = (
  location: Location,
  drop?: FilterSearchParamWithOrderKey[]
): Record<FilterSearchParamWithOrderKey, FilterSearchParamsValue> => {
  const { search } = location;
  return deserializationFilters(search, drop);
};
