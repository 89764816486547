import axios from '~/helpers/orchestration/axiosWithAuthHandling';
import { orchestration } from '~/config/public';
import { VerifyPhonePayload } from '~/App/shared/actions/verifyPhoneNumber';

export const resendEmailVerification = ({ idToken }: { idToken: string }) =>
  axios.put<undefined>(
    `${orchestration.url}/common/members/me/email-verification/resend`,
    {},
    {
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      withCredentials: true,
      responseType: 'json'
    }
  );

export const resendPhoneVerification = ({ idToken }: { idToken: string }) =>
  axios.post<undefined>(
    `${orchestration.url}/common/members/me/phone-number-verification/resend`,
    {},
    {
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      withCredentials: true,
      responseType: 'json'
    }
  );

export const verifyMemberEmail = (code: string) =>
  axios.post<{ emailVerification: { isVerified: boolean } }>(
    `${orchestration.url}/common/members/me/email-verification`,
    {
      code
    },
    {
      responseType: 'json'
    }
  );

type VerifyPhoneNumberArgs = {
  code: string;
  idToken: string;
};

export const verifyMemberPhone = ({ code, idToken }: VerifyPhoneNumberArgs) =>
  axios.post<VerifyPhonePayload>(
    `${orchestration.url}/common/members/me/phone-number-verification`,
    {
      code
    },
    {
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      withCredentials: true,
      responseType: 'json'
    }
  );
