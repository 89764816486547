import React, { useEffect, useState } from 'react';
import { searchAuctions } from '~/helpers/orchestration/auctions';
import { extractParamsFromQueryString } from '~/helpers/extractParamsFromQueryString';
import { requestErrorHandler } from '~/helpers/notifyError';
import Auction from '~/App/shared/interfaces/Auction';
import SlideShow from '~/App/shared/components/SlideShow';
import styled from 'styled-components';
import Header from '~/App/shared/components/VehicleCardListBlock/components/Header';
import { CardSkeletonLoader } from './CardSkeletonLoader';
import ProductCard from '~/App/shared/components/ProductCard/ProductCard';
import { ProductCardVariant } from '~/App/shared/components/ProductCard/types';
import { PartialMQSizeValues } from '~/helpers/mq';

const SlideShowWrapper = styled.div`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray.light5};
  margin-bottom: 1rem;
`;

interface Props {
  heading: string;
  linkText: string;
  queryString: string;
  prefetchedObjects?: Auction[];
  itemsPerSlide?: PartialMQSizeValues;
}

const CarInspirationList = ({
  queryString,
  heading,
  linkText,
  prefetchedObjects,
  itemsPerSlide
}: Props) => {
  const [isLoading, setIsLoading] = useState(!prefetchedObjects);
  const [featuredCars, setFeaturedCars] = useState<Auction[]>(
    prefetchedObjects ?? []
  );

  useEffect(() => {
    if (!featuredCars?.length) {
      const fetchFeaturedCars = async () => {
        if (!queryString || queryString.split('?').length !== 2) return;
        const params = extractParamsFromQueryString(queryString);
        return await searchAuctions({
          idToken: '',
          cancellable: false,
          offset: 0,
          limit: 20,
          params
        });
      };
      fetchFeaturedCars()
        .then(results => {
          if (results) {
            setFeaturedCars(results.auctions);
          }
        })
        .catch(requestErrorHandler)
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [setIsLoading, setFeaturedCars, queryString, featuredCars?.length]);

  if (!isLoading && featuredCars?.length === 0) return null;

  return (
    <>
      <Header
        title={heading}
        color="secondary"
        link={{
          label: linkText,
          url: queryString
        }}
      />

      <Divider />

      <SlideShowWrapper>
        <SlideShow
          itemsPerSlide={itemsPerSlide ?? { tablet: 2, laptop: 2, laptopXL: 2 }}
          alwaysShowPagination
        >
          {isLoading
            ? [1, 2].map(i => <CardSkeletonLoader key={i} />)
            : featuredCars.map((auction: Auction) => (
                <ProductCard
                  key={auction.id}
                  auction={auction}
                  variant={ProductCardVariant.Regular}
                />
              ))}
        </SlideShow>
      </SlideShowWrapper>
    </>
  );
};

export default CarInspirationList;
