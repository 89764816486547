import React from 'react';
import styled from 'styled-components';
import { BodyText, GetPaidIcon, Title } from '@kvdbil/components';
import InterestForm from '~/App/shared/components/InterestForm';

const Container = styled.div`
  background: ${({ theme }) => theme.colors.background.light};
  border: ${({ theme }) => `1px solid ${theme.colors.gray.light3}`};
  padding: 2.25rem;
  text-align: center;
  max-width: 36.25rem;
  margin: 1.875rem auto;
`;

const Description = styled(BodyText)`
  padding: 0.25rem 0 1rem 0;
`;

const Icon = styled.span`
  svg {
    margin: 0.5rem auto 1rem;
    width: 4.75rem;
    height: 4.75rem;
    display: block;
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

type Props = {
  title?: string;
  description?: string;
  sendButton?: string;
};

const SellCarForm = ({ title, description, sendButton }: Props) => {
  return (
    <Container>
      <Icon>
        <GetPaidIcon />
      </Icon>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <InterestForm buttonText={sendButton} />
    </Container>
  );
};

export default SellCarForm;
