import { getMember } from '~/helpers/orchestration/member';
import { idToken } from '../selectors/sessionSelector';
import { sessionUpdate } from './session';
import { ReduxStore } from '../interfaces/store';
import { ReduxDispatch } from '../interfaces/Redux';

export const updateMember =
  () =>
  async (
    dispatch: ReduxDispatch,
    getState: () => ReduxStore
  ): Promise<void> => {
    const state = getState();
    const token = idToken(state);
    const member = await getMember(token);

    dispatch(sessionUpdate(member));
  };
