import React from 'react';
import Link from '~/App/shared/components/Link';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  mq,
  YoutubeIcon
} from '@kvdbil/components';
import { useTranslation } from '~/Locale';
import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  justify-items: center;
  padding: 1rem 0;

  display: grid;
  grid-template-rows: 1fr;
  row-gap: 2.5rem;
`;

const SocialIcons = styled.ul`
  display: flex;
  font-size: 1.5rem;
  order: 2;
  align-items: center;
  a {
    color: ${({ theme }) => theme.colors.text.dark};

    &:hover {
      color: ${({ theme }) => theme.colors.gray.dark3};
    }
  }
  ${mq('tablet')} {
    order: 3;
    justify-self: end;
  }
`;

const SocialIcon = styled.li`
  margin: 0 0.5em;

  a {
    color: ${({ theme }) => theme.colors.gray.dark3};
    display: flex;
    align-content: center;
  }

  ${mq('tablet')} {
    &:last-child {
      margin-right: 0;
    }
  }
`;

const AAAImage = styled.img`
  height: 2.75rem;
  width: 4.43rem;
  margin: 0 1rem;
`;

const Links = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <AAAImage src="/images/uc-logo.png" alt="uc-gold-logo" />
      <SocialIcons>
        <SocialIcon>
          <Link to={t('FOOTER_FACEBOOK_LINK')}>
            <FacebookIcon aria-label="kvd on facebook" />
          </Link>
        </SocialIcon>
        <SocialIcon>
          <Link to={t('FOOTER_INSTAGRAM_LINK')}>
            <InstagramIcon aria-label="kvd on instagram" />
          </Link>
        </SocialIcon>
        <SocialIcon>
          <Link to={t('FOOTER_LINKEDIN_LINK')}>
            <LinkedinIcon aria-label="kvd on linkedin" />
          </Link>
        </SocialIcon>
        <SocialIcon>
          <Link to={t('FOOTER_YOUTUBE_LINK')}>
            <YoutubeIcon aria-label="kvd on youtube" />
          </Link>
        </SocialIcon>
      </SocialIcons>
    </Container>
  );
};

export default Links;
