import { FacilityFilters } from '~/App/shared/interfaces/Facility';
import { TranslateFunction } from '~/Locale';

export const getVehicleTypeTranslation = (
  filter: FacilityFilters,
  t: TranslateFunction
) => {
  switch (filter) {
    case 'CAR':
      return t('Passenger car');
    case 'LIGHT_TRANSPORT':
      return t('Light transport');
    case 'RECREATIONAL_VEHICLE':
      return t('Recreational vehicle');
    case 'HEAVY_TRANSPORT':
      return t('Heavy vehicles');
    case 'MACHINES':
      return t('Machines');
    default:
      return t('All vehicles');
  }
};
