import { Button, Flex, Title, mq, theme } from '@kvdbil/components';
import React from 'react';
import styled from 'styled-components';
import { SmallFeatureCardsBlockRecord } from '~/config/generated/graphql';
import StructuredText from '~/App/components/DatoCMS/StructuredText';
import { isEmptyDocument } from 'datocms-structured-text-utils';
import ArticleList from '../../Blog/components/ArticleList';
import { Color } from '@kvdbil/components/types/Types';
import Link from '~/App/shared/components/Link';

const Card = styled.div`
  padding: 2rem;
  background-color: ${theme.colors.background.light};

  ${mq(null, 'mobileL')} {
    padding: 2rem 1rem;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  p {
    margin: 0.5rem 0 2rem;
  }
`;

const StyledFlex = styled(Flex)`
  max-width: 24.125rem;
  background-color: red;
  margin: 2rem auto auto;

  a {
    width: 100%;
  }
`;

interface Props extends SmallFeatureCardsBlockRecord {
  isLoading: boolean;
}

const SmallFeatureCards = ({
  title,
  text,
  articles,
  isLoading,
  button
}: Props) => {
  return (
    <Card>
      <ContentContainer>
        <div>
          {title && <Title as={'h3'}>{title}</Title>}
          {!isEmptyDocument(text) && <StructuredText data={text} />}
        </div>
      </ContentContainer>
      <ArticleList isLoading={isLoading} articles={articles} />
      <StyledFlex>
        <Link to={button[0].url}>
          <Button size="large" color={button[0].color as Color} fullWidth>
            {button[0].text}
          </Button>
        </Link>
      </StyledFlex>
    </Card>
  );
};

export default SmallFeatureCards;
