import { FooterNavigationQuery } from '~/config/generated/graphql';

export const handleFooterNavigation = ({
  footerNavigation
}: FooterNavigationQuery) => {
  return {
    linkGroups: footerNavigation?.linkGroups,
    footerButtons: footerNavigation?.footerButtons,
    kvdHelperUrl: footerNavigation?._editingUrl
  };
};
