import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { postBankIdRegister } from '~/helpers/orchestration/auth';
import { useTranslation } from '~/Locale';
import { BankIdAuth } from '../../components/BankIdAuth';
import { startSession } from '../../helpers';
import { Session } from '~/App/shared/interfaces/store/Session';
import { AxiosError } from 'axios';
import { SubscriptionType } from '~/App/shared/interfaces/EmailSubscription';
import { trackGA4AccountCreated } from '~/helpers/client/ga4TrackEvent';

type Props = {
  title: string;
  subtitle: string;
  orderRef: string;
  qrCode?: string;
  autoStartToken?: string;
  onFail?(error: unknown): void;
  onClose?(): void;
  onBack?(): void;
  onComplete?(): void;
  email: string;
  emailSubscriptionNames?: SubscriptionType[];
  isStandaloneView?: boolean;
};

const CreateAccountVerifyBankIDView = ({
  orderRef,
  qrCode,
  autoStartToken,
  onFail,
  onClose,
  onBack,
  onComplete,
  title,
  subtitle,
  email,
  emailSubscriptionNames,
  isStandaloneView
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const createAccount = useCallback(
    async orderRef => {
      try {
        if (orderRef) {
          const response = await postBankIdRegister(
            orderRef,
            email,
            emailSubscriptionNames
          );

          const member = response.data.member;

          if (
            response.data.auth.idToken &&
            response.data.auth.refreshToken &&
            member
          ) {
            await startSession({
              dispatch,
              t,
              session: {
                auth: response.data.auth,
                member: member
              } as Session
            });

            onComplete?.();

            trackGA4AccountCreated({
              user_id: member.id,
              user_type: member.memberType === 'PERSON' ? 'private' : 'company',
              is_car_dealer: member.person.isCarDealer
            });
          } else {
            throw Error(
              t(
                'Something went wrong while authenticating with BankID, try again please'
              )
            );
          }
        }
      } catch (e: unknown) {
        const defaultError = t('Something went wrong, please try again later.');

        if (e instanceof AxiosError) {
          return onFail?.(e.response?.data?.message?.error ?? defaultError);
        }
        if (e instanceof Error) {
          return onFail?.(e.message || defaultError);
        }
        onFail?.((e as string) || defaultError);
      }
    },
    [email, dispatch, t, onComplete, onFail, emailSubscriptionNames]
  );

  return (
    <BankIdAuth
      initialQrCode={qrCode}
      autoStartToken={autoStartToken}
      orderRef={orderRef}
      onFail={onFail}
      onClose={isStandaloneView ? undefined : onClose}
      onBack={onBack}
      onComplete={createAccount}
      title={title}
      subtitle={subtitle}
      isStandaloneView={isStandaloneView}
    />
  );
};

export default CreateAccountVerifyBankIDView;
