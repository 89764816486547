import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{ height: number }>`
  ${({ theme, height }) =>
    `
    position: relative;
    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: ${height}px;
      background: ${theme.colors.background.gray};
    }`}
`;

interface DividerProps {
  height?: number;
}

const Divider = ({ height = 2 }: DividerProps) => {
  return <Container height={height} />;
};

export default Divider;
