import { MainCategory } from '~/App/shared/types/MainCategoryTypes';
import {
  MinMaxIntervalKey,
  minMaxIntervalValues as initialMinMaxIntervalValues
} from '~/config/constants';
import { SliderType } from './components/FilterSelector/types';
import { ObjectListDevice } from './components/ObjectListContainer';

export const getMinMaxIntervalValues = (mainCategory?: MainCategory) => {
  switch (mainCategory) {
    case 'heavy_transport':
    case 'machines':
      return {
        ...initialMinMaxIntervalValues,
        odometerReading: [0, 2500000]
      } as Record<MinMaxIntervalKey, SliderType>;

    default:
      return initialMinMaxIntervalValues;
  }
};

export const getInitialAmountOfSkeletons = (device: ObjectListDevice) => {
  switch (device) {
    case 'mobile':
      return 4;
    case 'tablet':
      return 6;
    case 'desktop':
      return 8;
  }
};
