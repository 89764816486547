import axios from '~/helpers/orchestration/axiosWithAuthHandling';
import axiosWithCancellation from './axiosWithCancellation';
import { createUuid } from '~/helpers/id';
import { orchestration } from '~/config/public';
import Auction, { AuctionNotFound } from '~/App/shared/interfaces/Auction';
import { AxiosInstance, AxiosPromise, AxiosResponse } from 'axios';
import { getDefaultHeaders } from '~/helpers/orchestration/shared';
import { getGaClientId } from '../client/cookie';
import { VehicleHitsType } from '~/App/shared/types/VehicleHitsType';
import ProcessObject from '~/App/shared/interfaces/ProcessObject';
import { AuctionSearchQuery } from '~/App/shared/interfaces/AuctionSearchQuery';
import { EvolvedSearchParams } from '~/App/shared/interfaces/AuctionSearchParams';
import {
  FavouriteAuction,
  FavouriteAuctionWithAuction
} from '~/App/shared/interfaces/FavouriteAuction';
import logger from '../logger';

/*
  This is an initializer for an cancellable axios handler, which uses a token
  to decide what network requests to cancel, Thus there needs to be one
  instance per type of network call that we want to cancel, therefore if you
  want another call to be canceled, create another one of these following the
  same pattern.
*/
let searchAuctionsCancellableAxios = null as unknown as (
  ...args: unknown[]
) => AxiosPromise | null;
let lastCancellable = true;

interface AuctionRequest {
  id: string;
  idToken?: string;
  params?: Record<string, unknown>;
}

type GetAuctionResponse = {
  auction: Auction | AuctionNotFound;
};

const getAuction = async (request: AuctionRequest) => {
  try {
    const response = await axios.get<GetAuctionResponse>(
      `${orchestration.url}/auction/auctions/${request.id}`,
      {
        withCredentials: true,
        params: request.params || {},
        headers: {
          Authorization: `Bearer ${request.idToken || ''}`
        },
        responseType: 'json'
      }
    );

    return response.data.auction;
  } catch (error: unknown) {
    logger.warn(error);
  }
};

interface AuctionsRequest {
  params?: Record<string, unknown>;
  idToken?: string;
}

type GetAuctionsResponse = {
  auctions: Auction[];
};
const getAuctions = async (request: AuctionsRequest) => {
  return await axios
    .get<GetAuctionsResponse>(`${orchestration.url}/auction/auctions`, {
      withCredentials: true,
      params: request.params || {},
      headers: {
        Authorization: `Bearer ${request.idToken || ''}`
      },
      responseType: 'json'
    })
    .then(response => response.data.auctions);
};

export interface SearchAuctionsRequest {
  idToken: string;
  limit: number;
  offset: number;
  params: EvolvedSearchParams | Partial<AuctionSearchQuery>;
  cancellable: boolean;
}

export type SearchAuctionsResponse = {
  auctions: Auction[];
  carGuideAuctions?: {
    [index: number]: Auction;
  };
  hits: number;
  total: number;
  vehicleTypeHits: VehicleHitsType;
  params?: EvolvedSearchParams | Partial<AuctionSearchQuery>;
};

export type SearchAuctionsResponseWithMeta = SearchAuctionsResponse & {
  meta: {
    lastSearchWithToken: boolean;
  };
  search: string;
};

const searchAuctions = async (request: SearchAuctionsRequest) => {
  if (
    !searchAuctionsCancellableAxios ||
    lastCancellable !== request.cancellable
  ) {
    searchAuctionsCancellableAxios = await axiosWithCancellation({
      cancellable: request.cancellable
    });
  }

  lastCancellable = request.cancellable;
  const params = {
    ...request.params,
    limit: request.limit,
    offset: request.offset
  };

  // uuid query param should be added only for orderBy=carguide
  if (params?.orderBy === 'carguide') {
    params['uuid'] = createUuid();
  }

  const headers = await getDefaultHeaders(request.idToken);

  /* Null check. TS doesn't get this :( */
  if (searchAuctionsCancellableAxios === null) {
    return;
  }

  const search = searchAuctionsCancellableAxios as (
    ...args: unknown[]
  ) => Promise<AxiosResponse<SearchAuctionsResponse>>;

  return search({
    url: `${orchestration.url}/auction/search`,
    headers,
    method: 'get',
    params: params || {},
    withCredentials: true,
    responseType: 'json'
  }).then(({ data }) => data);
};

type MyAuctionsResponse = {
  auctions: Auction[];
};
type GetMyAuctionsInput = {
  idToken: string;
  params: Record<string, string>;
};
const getMyAuctions = ({ params, idToken }: GetMyAuctionsInput) =>
  axios
    .get<MyAuctionsResponse>(
      `${orchestration.url}/mypages/auctions?limit=100`,
      {
        params: params || {},
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${idToken}`
        },
        responseType: 'json'
      }
    )
    .then(({ data }) => data.auctions);

type GetMyAuctionInfoInput = {
  idToken: string;
  auctionId: string | number;
};
const getMyAuctionInfo = ({ auctionId, idToken }: GetMyAuctionInfoInput) =>
  axios
    .get<{ auction: Auction }>(
      `${orchestration.url}/mypages/auctions/${auctionId}`,
      {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${idToken}`
        },
        responseType: 'json'
      }
    )
    .then(({ data }) => data.auction);

export type GetFavouriteAuctionsResponse = {
  favouriteAuctions: FavouriteAuctionWithAuction[];
};
const getFavouriteAuctions = ({ idToken }: { idToken: string }) =>
  axios
    .get<GetFavouriteAuctionsResponse>(
      `${orchestration.url}/mypages/favourite-auctions?limit=100`,
      {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${idToken}`
        },
        responseType: 'json'
      }
    )
    .then(
      ({ data }) =>
        data?.favouriteAuctions.map(favourite => ({
          ...favourite.auctionInformation,
          activeAuction: favourite.activeAuction
        })) ?? []
    );

type FavouriteAuctionArgs = {
  id: string;
  idToken: string;
  config?: { fetcher: AxiosInstance['request']; fetchGAClient: () => void };
};

export type FavouriteResponse = {
  auction: Auction;
  favouriteAuction: FavouriteAuction;
};

const storeFavouriteAuction = ({
  id,
  idToken,
  config = { fetcher: axios.request, fetchGAClient: getGaClientId }
}: FavouriteAuctionArgs) =>
  config
    .fetcher<FavouriteResponse>({
      url: `${orchestration.url}/mypages/favourite-auctions`,
      data: {
        favouriteAuction: {
          id: id
        },
        gaClientId: config.fetchGAClient()
      },
      withCredentials: true,
      method: 'post',
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      responseType: 'json'
    })
    .then(({ data }) => data);

const removeFavouriteAuction = ({
  id,
  idToken,
  config = { fetcher: axios.request, fetchGAClient: getGaClientId }
}: FavouriteAuctionArgs) =>
  config
    .fetcher<{ favouriteAuction: FavouriteResponse['favouriteAuction'] }>({
      url: `${orchestration.url}/mypages/favourite-auctions/${id}`,
      method: 'delete',
      withCredentials: true,
      data: {
        gaClientId: config.fetchGAClient()
      },
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      responseType: 'json'
    })
    .then(({ data }) => data.favouriteAuction);

type WithId<T> = T & { id: string };

type PreviewProcessArgs = WithId<{
  jwt: string | null;
}>;

const getPreviewProcessObject = ({
  id,
  jwt
}: PreviewProcessArgs): Promise<ProcessObject> =>
  axios
    .get<{ processObject: ProcessObject }>(
      `${orchestration.url}/auction/process-objects/${id}?jwt=${jwt}`,
      {
        responseType: 'json',
        withCredentials: false
      }
    )
    .then(({ data }) => data.processObject);

type PreviewProcessWithAccessHashArgs = WithId<{
  accessHash: string | null;
}>;

const getPreviewProcessObjectWithAccessHash = ({
  id,
  accessHash
}: PreviewProcessWithAccessHashArgs) =>
  axios
    .get<{ processObject: ProcessObject }>(
      `${orchestration.url}/auction/process-objects/by-access-hash/${id}?accessHash=${accessHash}`,
      {
        responseType: 'json',
        withCredentials: false
      }
    )
    .then(({ data }) => data.processObject);

type PreviewObjectArgs = PreviewProcessArgs & PreviewProcessWithAccessHashArgs;

export const getPreviewObject = ({
  id,
  jwt,
  accessHash
}: PreviewObjectArgs): Promise<ProcessObject> =>
  jwt
    ? getPreviewProcessObject({ id, jwt })
    : getPreviewProcessObjectWithAccessHash({ id, accessHash });

const getWonAuctions = ({ idToken }: { idToken: string }) =>
  axios
    .get<{ auctions: string[] }>(`${orchestration.url}/mypages/won_auctions`, {
      method: 'get',
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      responseType: 'json'
    })
    .then(({ data }) => data.auctions);

export {
  getAuction,
  getAuctions,
  getMyAuctions,
  getWonAuctions,
  searchAuctions,
  getMyAuctionInfo,
  getFavouriteAuctions,
  storeFavouriteAuction,
  removeFavouriteAuction,
  getPreviewProcessObject,
  getPreviewProcessObjectWithAccessHash
};
