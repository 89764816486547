import Bugsnag from '@bugsnag/js';
import { AxiosError } from 'axios';

type ErrorSeverity = 'error' | 'warning' | 'info';

interface Options {
  severity?: ErrorSeverity;
  unhandled?: boolean;
  [key: string]: unknown;
}

export const hideSensitiveData = (
  obj: Record<string, unknown> | null,
  keys: string[]
) => {
  if (typeof obj !== 'object' || obj === null) return;

  for (const prop in obj) {
    if (obj[prop] === Object(obj[prop]))
      hideSensitiveData(obj[prop] as Record<string, unknown>, keys);
    else if (keys.includes(prop)) obj[prop] = 'HIDDEN';
  }
};

export const isValidError = (error: unknown): error is Error | AxiosError => {
  return error instanceof Error;
};

export const log = (
  error?: Error | AxiosError | unknown,
  options: Options = {}
) => {
  if (!error || !isValidError(error)) {
    return;
  }

  /* If this is not a Error or AxiosError */
  if (!(error instanceof Error) && !('response' in error)) {
    return;
  }

  const { severity = 'error', ...extraData } = options;
  Bugsnag.notify(error, event => {
    if (Object.keys(extraData).length > 0) {
      hideSensitiveData(extraData, ['Authorization']);
      event.addMetadata('extraData', extraData);
    }

    event.unhandled = Boolean(extraData.unhandled);
    event.severity = severity;
  });
};
