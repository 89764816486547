import { TranslateFunction } from '~/Locale';

export interface StructuredSEOItem {
  name: string;
  item: string;
}

export const createStructuredBreadcrumbs = (items: StructuredSEOItem[]) => {
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: items.map((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: item.name,
      item: item.item
    }))
  };

  return schema;
};
type MetaItem = { content: string; name?: string };

interface MetaArgs {
  t: TranslateFunction;
  description?: string | null;
  seoTitle?: string | null;
  seoDescription?: string | null;
  seoImage?: string | null;
  url?: string | null;
}
export const metaHelper = ({
  t,
  description,
  seoTitle,
  seoDescription,
  seoImage,
  url
}: MetaArgs) => {
  const metaArr: MetaItem[] = [
    {
      name: 'description',
      content: description || t('PAGE_META.DEFAULT_DESCRIPTION')
    },
    {
      name: 'og:title',
      content: seoTitle || t('PAGE_META.DEFAULT_OG_TITLE')
    },
    {
      name: 'og:description',
      content: seoDescription || t('PAGE_META.DEFAULT_DESCRIPTION')
    },
    {
      name: 'og:image',
      content: seoImage || t('PAGE_META.DEFAULT_OG_IMAGE')
    },
    {
      name: 'og:url',
      content: url || t('PAGE_META.DEFAULT_OG_URL')
    }
  ];
  return metaArr;
};

export const findMetaTag = (tags: HTMLMetaElement[], tagName: string) => {
  const tagContent = tags.find(tag => {
    return (
      tag.name === tagName ||
      (tag as unknown as { property: string }).property === tagName
    );
  })?.content;
  return tagContent;
};
