import { useCmsData } from '~/App/shared/hooks/useCmsData';
import { ObjectPageData } from '~/App/shared/interfaces/store/CmsData';
import {
  ObjectPageDocument,
  ObjectPageQuery
} from '~/config/generated/graphql';

export const useObjectPageCMSData = () => {
  return useCmsData<ObjectPageQuery, ObjectPageData>({
    storeKey: 'objectPage',
    query: ObjectPageDocument
  });
};
