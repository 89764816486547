import { MessagesActions } from '../actions/messages';
import { Messages } from '../interfaces/store/Messages';
import { SiteMessageRecord } from '~/config/generated/graphql';

const initialState = {
  top: [],
  bottom: []
};

const filterEmpty = (m: SiteMessageRecord) =>
  m && Object.keys(m).length > 0 && m.message;

const messages = (state: Messages = initialState, action: MessagesActions) => {
  switch (action.type) {
    case 'MESSAGES_DISMISS':
      return {
        top: state.top.filter(m => m.id !== action.id),
        bottom: state.bottom.filter(m => m.id !== action.id)
      };
    case 'MESSAGES_SUCCESS':
      const top = action.messages.top.filter(filterEmpty);
      const bottom = action.messages.bottom.filter(filterEmpty);
      return {
        ...state,
        ...{ top, bottom }
      };
    case 'MESSAGES_REQUEST':
    case 'MESSAGES_FAILURE':
    default:
      return state;
  }
};

export default messages;
