import { log } from '~/helpers/bugsnagHelper';
import { SearchAuctionObjectsSuccessAction } from '~/App/shared/actions/auctions';
import {
  auctionClosed,
  auctionCloseTimeUpdated,
  auctionBidPlaced,
  auctionBidCancelled,
  auctionReleasingCostUpdated,
  auctionCountdownStarted
} from '~/App/shared/actions/connection-events';
import Auction from '../interfaces/Auction';
import {
  biddingViewSetAllBids,
  updateMyBidderAlias
} from '../actions/objectViewDrawerActions';
import { ReduxStore } from '../interfaces/store';
import { AuctionRequestsType } from '../actions/auction';
import { unionBy } from '~/helpers/unionBy';

/* This reducer stores data about auctions in the format of hashtables where the auction id's are the keys
 * The main reason is to move data to redux for ease of debugging
 * but also to remove the need to merge auction, websocketBids, websocketAuctions everywhere where we use an auctionObject
 */

const auctionClosedReducer = (
  state: ReduxStore['auctions'],
  action: ReturnType<typeof auctionClosed>
) => {
  const { auctionId, highestBid } = action.payload;
  const auction: Auction = state[auctionId];

  if (!auction || !auction.activeAuction) {
    return state;
  }

  const prevBids = auction.activeAuction?.bids ?? [];
  const newBids = unionBy([...prevBids, highestBid], 'id');

  return {
    ...state,
    [auctionId]: {
      ...auction,
      activeAuction: {
        ...auction.activeAuction,
        auctionId,
        closed: true,
        countdownInProgress: false,
        highestBid: highestBid,
        bids: newBids
      }
    }
  };
};

const auctionBidPlacedReducer = (
  state: ReduxStore['auctions'],
  action: ReturnType<typeof auctionBidPlaced>
) => {
  const auction: Auction = state[action.payload.auctionId];

  if (!auction || !auction.activeAuction) {
    return state;
  }

  const oldBids = auction.activeAuction.bids;
  const bidFromEvent = {
    ...action.payload,
    auctionId: Number(action.payload.auctionId)
  };

  return {
    ...state,
    [action.payload.auctionId]: {
      ...auction,
      activeAuction: {
        ...auction.activeAuction,
        reservationPriceReached: action.payload.reservationPriceReached,
        bids: [bidFromEvent, ...oldBids]
      }
    }
  };
};

const auctionReleasingCostUpdatedReducer = (
  state: ReduxStore['auctions'],
  action: ReturnType<typeof auctionReleasingCostUpdated>
) => {
  const { auctionId, releasingCost, releasingCostStatus } = action.payload;
  const auction: Auction = state[auctionId];
  if (!auction) {
    return state;
  }

  const releasing = {
    monthlyCost: releasingCost,
    monthlyCostStatus: releasingCostStatus,
    monthlyCostBuyNow: auction.releasing?.monthlyCostBuyNow ?? null
  };

  return {
    ...state,
    [action.payload.auctionId]: {
      ...auction,
      releasing
    }
  };
};

const auctionBidCancelledReducer = (
  state: ReduxStore['auctions'],
  action: ReturnType<typeof auctionBidCancelled>
) => {
  const { auctionId } = action.payload;
  const auction: Auction = state[auctionId];

  if (!auction || !auction.activeAuction) {
    return state;
  }

  const bids = auction.activeAuction.bids ?? [];
  const newBids = bids.filter(bid => bid.id !== action.payload.id);

  return {
    ...state,
    [action.payload.auctionId]: {
      ...auction,
      activeAuction: {
        ...auction.activeAuction,
        reservationPriceReached: action.payload.reservationPriceReached,
        bids: newBids
      }
    }
  };
};

type AuctionsAction =
  | ReturnType<typeof auctionClosed>
  | ReturnType<typeof auctionCloseTimeUpdated>
  | ReturnType<typeof auctionBidPlaced>
  | ReturnType<typeof auctionBidCancelled>
  | ReturnType<typeof auctionReleasingCostUpdated>
  | ReturnType<typeof auctionCountdownStarted>
  | ReturnType<typeof updateMyBidderAlias>
  | Partial<ReturnType<typeof biddingViewSetAllBids>>
  | AuctionRequestsType
  | SearchAuctionObjectsSuccessAction;

type AuctionsState = {
  [key: string]: Auction;
};

export const auctions = (
  state: AuctionsState = {},
  action: AuctionsAction
): ReduxStore['auctions'] => {
  switch (action.type) {
    case 'SEARCH_BUY_CAR_OBJECTS_SUCCESS': {
      const auctionMap: Record<string, Auction> = { ...state };
      const resultAuctions = action.payload.auctions ?? [];
      resultAuctions.forEach(auction => {
        auctionMap[auction.id] = auction;
      });

      return auctionMap;
    }

    // Websocket events
    case 'AUCTION_CLOSED':
      return auctionClosedReducer(state, action);

    case 'AUCTION_BID_PLACED':
      return auctionBidPlacedReducer(state, action);

    case 'AUCTION_BID_CANCELLED':
      return auctionBidCancelledReducer(state, action);

    case 'AUCTION_RELEASING_COST_UPDATED':
      return auctionReleasingCostUpdatedReducer(state, action);

    case 'AUCTION_COUNTDOWN_STARTED': {
      const { auctionId, countdownDuration } = action.payload;
      const auction: Auction = state[auctionId];

      if (!auction || !auction.activeAuction) {
        return state;
      }

      return {
        ...state,
        [auctionId]: {
          ...auction,
          countdownDuration: countdownDuration,
          activeAuction: {
            ...auction.activeAuction,
            countdownInProgress: true
          }
        }
      };
    }

    case 'AUCTION_CLOSE_TIME_UPDATED': {
      const { auctionId, preliminaryCloseAt } = action.payload;
      const auction: Auction = state[auctionId];

      if (!auction || !auction.activeAuction) {
        return state;
      }

      return {
        ...state,
        [auctionId]: {
          ...auction,
          id: auctionId,
          activeAuction: {
            ...auction.activeAuction,
            preliminaryCloseAt
          }
        }
      };
    }

    case 'UPDATE_MY_BIDDER_ALIAS': {
      const { auctionId, myBidderAlias } = action.payload;
      const auction: Auction = state[auctionId];

      if (!auction || !auction.activeAuction) {
        return state;
      }

      const newAuction = {
        ...auction,
        activeAuction: {
          ...auction.activeAuction,
          myBidderAlias
        }
      };

      return {
        ...state,
        [auctionId]: newAuction
      };
    }

    case 'BIDDING_VIEW_SET_ALL_BIDS': {
      if (!action?.payload) {
        return state;
      }
      const bids = action.payload.map(bid => ({
        ...bid,
        auctionId: String(bid.auctionId)
      }));

      const [bid] = bids;

      const auctionId = bid?.auctionId;
      const prevAuction: Auction = state[auctionId];
      const activeAuction = prevAuction?.activeAuction;

      if (!auctionId || !prevAuction || !activeAuction) {
        return state;
      }

      const prevBids = activeAuction?.bids ?? [];
      const newBids = unionBy([...prevBids, ...bids], 'id');

      const newBidsIds = bids.map(bid => bid.id);
      const oldBidsIds = prevBids.map(bid => bid.id);
      const missedBids = newBidsIds.filter(
        bidId => !oldBidsIds.includes(bidId)
      );

      if (missedBids.length > 0) {
        log(
          new Error(
            `Polling found ${missedBids.length} bids for auction ${auctionId} that hadn't arrived by websocket`
          ),
          { missedBidsAmount: missedBids.length }
        );
      }

      return {
        ...state,
        [auctionId]: {
          ...prevAuction,
          activeAuction: {
            ...activeAuction,
            bids: newBids
          }
        }
      };
    }

    default:
      return state;
  }
};

export default auctions;

export const getAuction = (
  state: ReduxStore['auctions'],
  auctionId: string
): Auction | null => {
  const auction = state?.[auctionId];
  return auction ?? null;
};
