export const EMAIL_MAX_LENGTH = 320;

export const PASSWORD_MIN_LENGTH = 8;

export const NATIONAL_IDENTIFICATION_NUMBER_MAX_LENGTH = 25;

export const FIRST_NAME_MAX_LENGTH = 25;
export const LAST_NAME_MAX_LENGTH = 25;

export const ORGANISATION_NAME_MAX_LENGTH = 100;
export const VAT_NUMBER_MAX_LENGTH = 25;
export const VAT_NUMBER_MIN_LENGTH = 5;

export const ODOMETER_MAX = 30000;
export const ODOMETER_YEARLY_MAX = 5000;
