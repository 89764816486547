import { createSelector } from 'reselect';
import { ReduxStore } from '../interfaces/store';
import { initialState } from '../reducers/drawerMenuReducer';

const selectDrawerMenu = (state: ReduxStore) =>
  state.drawerMenu || initialState;

export const selectIsDrawerOpen = createSelector(
  selectDrawerMenu,
  drawerMenu => drawerMenu.isOpen
);

export const selectDrawerMenuView = createSelector(
  selectDrawerMenu,
  drawerMenu => drawerMenu.view
);
