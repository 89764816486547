import { useCallback, useEffect } from 'react';

interface Config {
  preventDefault?: boolean;
  onShift?: boolean;
}

export const useKeypressEffect = (
  targetKeyCode: 'ArrowDown' | 'ArrowUp' | 'Tab' | 'Escape', // Is there a keyof we can use here?
  callback: CallableFunction,
  config: Config
) => {
  const downHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === targetKeyCode && config.preventDefault) {
        event.preventDefault();
      }
    },
    [config.preventDefault, targetKeyCode]
  );

  const pressHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === targetKeyCode && (event.shiftKey || !config.onShift)) {
        callback();
      }
    },
    [callback, config.onShift, targetKeyCode]
  );

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', pressHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', pressHandler);
    };
  }, [callback, pressHandler, downHandler]);

  return null;
};
