import styled from 'styled-components';
import React from 'react';
import { StatusTagWrapper } from '~/App/shared/components/ProductCard/components/ContainerWithImage/components/StatusTagWrapper';
import { useTranslation } from '~/Locale';
import { CountdownIcon } from '@kvdbil/components';

const OverlayButtons = styled.div`
  display: flex;
  align-items: center;
`;

const StateText = styled.span`
  color: ${({ theme }) => theme.colors.error.main};
`;

export interface Props {
  isComingSoon: boolean;
  isReserved: boolean;
  isSold: boolean;
  isPriceReduced: boolean;
}

const FixedPriceImageHeader = ({
  isComingSoon,
  isReserved,
  isSold,
  isPriceReduced
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {(isComingSoon || isReserved || isSold || isPriceReduced) && (
        <OverlayButtons>
          {isPriceReduced && !isReserved && !isSold && !isComingSoon && (
            <StatusTagWrapper useRedBackground>
              {t('Reduced price')}
            </StatusTagWrapper>
          )}

          {(isComingSoon || isReserved || isSold) && (
            <StatusTagWrapper>
              {isComingSoon && (
                <>
                  <CountdownIcon />
                  {t('Soon on KVD')}
                </>
              )}

              {isReserved && <StateText>{t('Is reserved')}</StateText>}
              {isSold && <StateText>{t('Sold')}</StateText>}
            </StatusTagWrapper>
          )}
        </OverlayButtons>
      )}
    </>
  );
};

export default FixedPriceImageHeader;
