import React from 'react';
import { useTranslation } from '~/Locale';
import FacebookAuthButton from '../../../components/FacebookAuthButton';
import PrivateRegistrationForm from './Form';
import { BodyText, Button, Flex, Spacer } from '@kvdbil/components';
import styled from 'styled-components';
import { useFeatureFlagValue } from '~/App/components/FeatureFlags';
import { RegistrationView } from '../../types';

const ButtonWrapper = styled(Flex)`
  margin-top: 2rem;
`;

const CenteredBodyText = styled(BodyText)`
  text-align: center;
`;

type Props = {
  changeView: (view: RegistrationView) => void;
};

export const PrivateRegistrationView = ({ changeView }: Props) => {
  const { t } = useTranslation();
  const isFacebookEnabled = useFeatureFlagValue('sign-in-facebook');

  return (
    <div>
      {isFacebookEnabled && (
        <>
          <CenteredBodyText>
            {t('Create an account with Facebook...')}
          </CenteredBodyText>

          <Spacer />

          <FacebookAuthButton />

          <Spacer height={2} />

          <CenteredBodyText>
            {t('Or create an account with e-mail...')}
          </CenteredBodyText>

          <Spacer />
        </>
      )}

      <PrivateRegistrationForm onSuccess={() => changeView('COMPLETED')} />

      <ButtonWrapper>
        <Button
          onClick={() => changeView('CREATE_ACCOUNT')}
          variant="flat"
          color="neutral"
        >
          {t('I want to use BankID instead')}
        </Button>
      </ButtonWrapper>
    </div>
  );
};

export default PrivateRegistrationView;
