import React from 'react';
import { useTranslation } from '~/Locale';
import { StatusTagWrapper } from './StatusTagWrapper';
import withTimer, {
  Props as TimerProps
} from '~/App/shared/components/withTimer';
import { ClockIcon } from '@kvdbil/components';

const AuctionCardTimer = ({ minutes, seconds }: TimerProps) => {
  const { t } = useTranslation();
  let status = t(
    '%1$s:%2$s left',
    minutes.toString().padStart(2, '0'),
    seconds.toString().padStart(2, '0')
  );

  if (minutes <= 0 && seconds < 10) {
    status = t('Closing...');
  }

  return (
    <StatusTagWrapper useRedBackground>
      <ClockIcon />
      {status}
    </StatusTagWrapper>
  );
};

export default withTimer(AuctionCardTimer);
