import {
  BusinessProposalCreateResponse,
  SalesAgreementCreateResponse
} from '~/helpers/orchestration/sale';
import {
  LeadObjectInformation,
  SalesMethodTypes,
  BusinessProposalResponseForId,
  ContactDetails,
  DefaultContactDetails,
  SalesAgreement
} from '../interfaces/store/SellFlow';
import { SpecItem } from '../interfaces/TechnicalSpecification';
import { ValuationAction } from './valuationActions';

export const sellflowSetDefaultContactDetails = (
  payload?: Partial<DefaultContactDetails>
) => ({
  type: 'SELLFLOW_SET_DEFAULT_CONTACT_DETAILS' as const,
  payload
});

export const sellflowSetContactDetails = (
  payload: Partial<ContactDetails>
) => ({
  type: 'SELLFLOW_SET_CONTACT_DETAILS' as const,
  payload
});

export const sellflowSetOdometerReading = (payload: number) => ({
  type: 'SELLFLOW_SET_ODOMETER_READING' as const,
  payload
});

export const sellflowSetToken = (payload: string) => ({
  type: 'SELLFLOW_SET_TOKEN' as const,
  payload
});

export const sellflowSetRegistrationPlate = (payload: string) => ({
  type: 'SELLFLOW_SET_REGISTRATION_PLATE' as const,
  payload
});

export const sellflowPrefillRegistrationPlate = (payload: string) => ({
  type: 'SELLFLOW_PREFILLED_REGISTRATION_PLATE' as const,
  payload
});

export const sellflowVehicleInfoLock = () => ({
  type: 'SELLFLOW_VEHICLE_INFO_LOCK' as const
});

export const sellflowVehicleInfoUnLock = () => ({
  type: 'SELLFLOW_VEHICLE_INFO_UNLOCK' as const
});

export const sellflowOdometerLock = (payload: boolean) => ({
  type: 'SELLFLOW_ODOMETER_LOCK' as const,
  payload
});

export const sellflowOdometerUnknown = () => ({
  type: 'SELLFLOW_ODOMETER_UNKNOWN' as const
});

export const sellflowPrefilledOdometerReading = (payload: number) => ({
  type: 'SELLFLOW_PREFILLED_ODOMETER_READING' as const,
  payload
});

export const sellflowSelectAddon = (payload: SpecItem) => ({
  type: 'SELLFLOW_SELECT_ADDON' as const,
  payload
});

export const sellflowSelectPackage = (payload: SpecItem) => ({
  type: 'SELLFLOW_SELECT_PACKAGE' as const,
  payload
});

export const sellflowSelectValuationAddon = (payload: SpecItem) => ({
  type: 'SELLFLOW_SELECT_VALUATION_ADDON' as const,
  payload
});

export const sellflowVehicleInfoRequest = () => ({
  type: 'SELL_FLOW_VEHICLE_INFO_REQUEST' as const
});

export const sellflowVehicleInfoSuccess = (payload: LeadObjectInformation) => ({
  type: 'SELL_FLOW_VEHICLE_INFO_SUCCESS' as const,
  payload
});

export const sellflowVehicleInfoFailure = (payload: string) => ({
  type: 'SELL_FLOW_VEHICLE_INFO_FAILURE' as const,
  payload
});

export const sellflowOdometerRequest = () => ({
  type: 'SELL_FLOW_ODOMETER_REQUEST' as const
});

export const sellflowOdometerSuccess = (payload: LeadObjectInformation) => ({
  type: 'SELL_FLOW_ODOMETER_SUCCESS' as const,
  payload
});

export const sellflowOdometerFailure = (payload: string) => ({
  type: 'SELL_FLOW_ODOMETER_FAILURE' as const,
  payload
});

export const sellflowTrimOptionsRequest = () => ({
  type: 'SELLFLOW_TRIM_OPTIONS_REQUEST' as const
});

export const sellflowTrimOptionsSuccess = (payload: LeadObjectInformation) => ({
  type: 'SELLFLOW_TRIM_OPTIONS_SUCCESS' as const,
  payload
});

export const sellflowTrimOptionsFailure = (payload: string) => ({
  type: 'SELLFLOW_TRIM_OPTIONS_FAILURE' as const,
  payload
});

export const sellflowVehicleInfoDefaults = (
  payload: LeadObjectInformation
) => ({
  type: 'SELLFLOW_VEHICLE_INFO_DEFAULTS' as const,
  payload
});

export const sellflowBusinessProposalRequest = () => ({
  type: 'SELLFLOW_BUSINESS_PROPOSAL_REQUEST' as const
});

export interface BusinessProposalSuccessPayload {
  businessProposal:
    | BusinessProposalResponseForId
    | BusinessProposalCreateResponse['businessProposal'];
  token?: string;
}
export const sellflowBusinessProposalSuccess = (
  payload: BusinessProposalSuccessPayload
) => ({
  type: 'SELLFLOW_BUSINESS_PROPOSAL_SUCCESS' as const,
  payload
});

export const sellflowBusinessProposalFailure = (payload: string) => ({
  type: 'SELLFLOW_BUSINESS_PROPOSAL_FAILURE' as const,
  payload
});

export const sellflowSalesAgreementRequest = () => ({
  type: 'SELLFLOW_SALES_AGREEMENT_REQUEST' as const
});

export const sellflowSalesAgreementSuccess = (
  payload: SalesAgreementCreateResponse | SalesAgreement
) => ({
  type: 'SELLFLOW_SALES_AGREEMENT_SUCCESS' as const,
  payload
});

export const sellflowSalesAgreementLoading = (payload: boolean) => ({
  type: 'SELLFLOW_SALES_AGREEMENT_LOADING' as const,
  payload
});

export const sellflowSalesAgreementFailure = (payload: string) => ({
  type: 'SELLFLOW_SALES_AGREEMENT_FAILURE' as const,
  payload
});

export const sellflowSelectReservationPriceRequest = () => ({
  type: 'SELLFLOW_SELECT_RESERVATION_PRICE_REQUEST' as const
});

export const sellflowSelectReservationPriceSuccess = () => ({
  type: 'SELLFLOW_SELECT_RESERVATION_PRICE_SUCCESS' as const
});

export const sellflowSelectReservationPriceFailure = (payload?: string) => ({
  type: 'SELLFLOW_SELECT_RESERVATION_PRICE_FAILURE' as const,
  payload
});

export const sellflowTrimOptionsLock = (payload: boolean) => ({
  type: 'SELLFLOW_TRIM_OPTIONS_LOCK' as const,
  payload
});

export const sellflowTrimOptionsUnknown = (payload: boolean) => ({
  type: 'SELLFLOW_TRIM_OPTIONS_UNKNOWN' as const,
  payload
});

export const sellflowPackageUnknown = (payload: boolean) => ({
  type: 'SELLFLOW_PACKAGE_UNKNOWN' as const,
  payload
});

export const sellflowValuationClose = (payload: boolean) => ({
  type: 'SELLFLOW_VALUATION_CLOSE' as const,
  payload
});

export const sellflowSalesMethodClose = (payload: boolean) => ({
  type: 'SELLFLOW_SALES_METHOD_CLOSE' as const,
  payload
});

export const sellflowSetSalesMethod = (payload: SalesMethodTypes) => ({
  type: 'SELLFLOW_SET_SALES_METHOD' as const,
  payload
});

export const sellflowReset = () => ({
  type: 'SELLFLOW_RESET' as const
});

export const sellflowErrorFallbackSolution = () => ({
  type: 'SELLFLOW_ERROR_FALLBACK_SOLUTION' as const
});

export type SellFlowAction =
  | ReturnType<typeof sellflowReset>
  | ReturnType<typeof sellflowSetRegistrationPlate>
  | ReturnType<typeof sellflowSetOdometerReading>
  | ReturnType<typeof sellflowOdometerUnknown>
  | ReturnType<typeof sellflowSetToken>
  | ReturnType<typeof sellflowPrefillRegistrationPlate>
  | ReturnType<typeof sellflowPrefilledOdometerReading>
  | ReturnType<typeof sellflowVehicleInfoLock>
  | ReturnType<typeof sellflowVehicleInfoUnLock>
  | ReturnType<typeof sellflowOdometerLock>
  | ReturnType<typeof sellflowVehicleInfoRequest>
  | ReturnType<typeof sellflowVehicleInfoSuccess>
  | ReturnType<typeof sellflowVehicleInfoFailure>
  | ReturnType<typeof sellflowOdometerRequest>
  | ReturnType<typeof sellflowOdometerSuccess>
  | ReturnType<typeof sellflowOdometerFailure>
  | ReturnType<typeof sellflowTrimOptionsRequest>
  | ReturnType<typeof sellflowTrimOptionsSuccess>
  | ReturnType<typeof sellflowTrimOptionsFailure>
  | ReturnType<typeof sellflowVehicleInfoDefaults>
  | ReturnType<typeof sellflowBusinessProposalRequest>
  | ReturnType<typeof sellflowBusinessProposalSuccess>
  | ReturnType<typeof sellflowBusinessProposalFailure>
  | ReturnType<typeof sellflowSalesAgreementRequest>
  | ReturnType<typeof sellflowSalesAgreementSuccess>
  | ReturnType<typeof sellflowSalesAgreementLoading>
  | ReturnType<typeof sellflowSalesAgreementFailure>
  | ReturnType<typeof sellflowSelectReservationPriceRequest>
  | ReturnType<typeof sellflowSelectReservationPriceSuccess>
  | ReturnType<typeof sellflowSelectReservationPriceFailure>
  | ReturnType<typeof sellflowSetDefaultContactDetails>
  | ReturnType<typeof sellflowSetContactDetails>
  | ReturnType<typeof sellflowSelectPackage>
  | ReturnType<typeof sellflowSelectAddon>
  | ReturnType<typeof sellflowSelectValuationAddon>
  | ReturnType<typeof sellflowTrimOptionsLock>
  | ReturnType<typeof sellflowTrimOptionsUnknown>
  | ReturnType<typeof sellflowPackageUnknown>
  | ReturnType<typeof sellflowValuationClose>
  | ReturnType<typeof sellflowSalesMethodClose>
  | ReturnType<typeof sellflowSetSalesMethod>
  | ReturnType<typeof sellflowErrorFallbackSolution>
  | ValuationAction;
