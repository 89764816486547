import { Locale } from '~/App/shared/localization/types';
import { getCmsData } from '../cmsDatoUtils';
import { SiteMessagesDocument } from '~/config/generated/graphql';
import { ReduxDispatch } from '~/App/shared/interfaces/Redux';
import { ReduxStore } from '~/App/shared/interfaces/store';
import { CmsData } from '~/App/shared/interfaces/store/CmsData';
import logger from '~/helpers/logger';

export const getMessages = async (
  locale: Locale,
  dispatch: ReduxDispatch,
  getState: () => ReduxStore
) => {
  try {
    const data = await getCmsData<
      CmsData['siteMessages'],
      CmsData['siteMessages']
    >({
      dispatch,
      getState,
      storeKey: 'siteMessages',
      query: SiteMessagesDocument,
      variables: { locale },
      options: { useStore: false }
    });

    if (data) {
      return data;
    } else {
      throw new Error('failed to fetch data');
    }
  } catch (error) {
    logger.error(error);
  }
};
