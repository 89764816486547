import { useEffect, useRef } from 'react';

export const useOnMount = (
  onMount: CallableFunction,
  onUnmount?: CallableFunction
) => {
  const callback = useRef({ mount: onMount, unmount: onUnmount });

  useEffect(() => {
    const current = callback.current;
    current.mount();

    return () => {
      current.unmount?.();
    };
  }, []);
};
