import React from 'react';
import { useTranslation } from '~/Locale';
import { BankidIcon, Button } from '@kvdbil/components';
import { ButtonProps } from '@kvdbil/components/types/components/Button';

interface Props extends Omit<ButtonProps, 'children' | 'onError' | 'onClick'> {
  onClick?(): void;
  children?: React.ReactNode;
}

const BankIdAuthButton = ({
  children,
  onClick,
  isLoading,
  ...props
}: Props) => {
  const { t } = useTranslation();

  return (
    <Button
      variant="solid"
      color="primary"
      size="large"
      onClick={onClick}
      data-testid="bankid-auth-button"
      isLoading={isLoading}
      prefixIcon={<BankidIcon height={'1.5rem'} width={'1.5rem'} />}
      style={{ justifyContent: 'center' }}
      {...props}
    >
      {children ?? t('Sign in with BankID')}
    </Button>
  );
};

export default BankIdAuthButton;
