import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { mq, Headline, TinyTitleRegular } from '@kvdbil/components';
import { translateRoute } from '~/App/shared/localization';
import { regexRegistrationPlate } from '~/helpers/regex';
import { useTranslation } from '~/Locale';
import RegistrationPlateInput from '~/App/components/RegistrationPlateInput';
import { sellflowReset } from '~/App/shared/actions/sellflowActions';
import { trackGA4SellCarView } from '~/helpers/client/ga4TrackEvent';
import { useGA4UserIsHydrated } from '~/App/shared/hooks/useGA4User';
import { useDebounceGA4Event } from '~/App/shared/hooks/useDebounceGA4Event';
import { DynamicPageValuationFormRecord } from '~/config/generated/graphql';
import { getUrlSearchParams } from '~/helpers/url';
import { isIframe } from '~/helpers/iframe';

const Subtitle = styled(TinyTitleRegular)`
  margin-top: 0.5rem;
`;

const InputContainer = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 26rem;
`;

const TextFieldsContainer = styled.div`
  margin-top: 2rem;
  width: 100%;

  > * {
    margin: 0.75rem 0;
  }
`;

const ScaleIcon = styled.img`
  display: block;
  margin: auto;
  width: 10rem;

  ${mq('tablet')} {
    width: 15rem;
  }
`;

const getScaleUrl = ({
  hasAnimated,
  animationHash,
  urlInitial,
  urlAnimation,
  urlDone
}: {
  hasAnimated: boolean;
  animationHash: string;
  urlInitial?: string;
  urlAnimation?: string;
  urlDone?: string;
}) => {
  /* Check if browser is IE11, if so, return "done animation" */
  if (
    typeof window !== 'undefined' &&
    window.document &&
    'documentMode' in window.document
  ) {
    return urlDone;
  }

  return hasAnimated ? `${urlAnimation}?hash=${animationHash}` : urlInitial;
};

interface Props {
  record: DynamicPageValuationFormRecord;
}

const ValuationForm = ({ record }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = getUrlSearchParams(search);
  const r = searchParams?.r;

  const [hasAnimated, setHasAnimated] = useState(false);
  const [animationHash, setAnimationHash] = useState('');

  const [registrationPlate, setRegistrationPlate] = useState(r || '');

  const { ga4User, isHydrated } = useGA4UserIsHydrated();
  const ga4ViewEvent = useDebounceGA4Event();

  const isRegistrationPlateValid =
    regexRegistrationPlate.test(registrationPlate);

  useEffect(() => {
    if (r) {
      window.scrollTo(0, 0);
      setTimeout(() => {
        setHasAnimated(true);
      }, 1500);
    }
  }, [r]);

  useEffect(() => {
    if (!hasAnimated && isRegistrationPlateValid) {
      setAnimationHash(Math.random().toString());
      setHasAnimated(true);
    }
  }, [isRegistrationPlateValid, hasAnimated]);

  useEffect(() => {
    if (isHydrated) {
      ga4ViewEvent(
        trackGA4SellCarView,
        {
          page_type: 'value_car_page'
        },
        ga4User
      );
    }
  }, [ga4ViewEvent, ga4User, isHydrated]);

  const scaleUrl = getScaleUrl({
    hasAnimated,
    animationHash,
    urlInitial: record?.imageBefore?.url,
    urlAnimation: record?.imageAnimation?.url,
    urlDone: record?.imageAfter?.url
  });

  const onRegistrationPlateSubmit = (plate: string) => {
    setAnimationHash(plate);
    const campaignIdentifier = searchParams?.campaign_identifier
      ? `&campaign_identifier=${searchParams?.campaign_identifier}`
      : ``;

    const route = `${translateRoute(
      t,
      '/ROUTES.SELL/ROUTES.SELL_CAR_INFO'
    )}?r=${plate}${campaignIdentifier}`;

    dispatch(sellflowReset());

    if (isIframe() && window.top) {
      window.top.location.href =
        route + `&ref=${encodeURIComponent(window.top.location.href)}`; // Redirect parent of iframe and include where they came from
    } else {
      history.push(route);
    }
  };

  return (
    <>
      <ScaleIcon src={scaleUrl} alt="scale-car" />
      <InputContainer>
        <Headline as="h1">{record?.title}</Headline>
        <Subtitle>{record?.subtitle}</Subtitle>

        <TextFieldsContainer>
          <RegistrationPlateInput
            title={t('Next')}
            sellingText={record?.formHeading ?? ''}
            onSubmit={onRegistrationPlateSubmit}
            value={registrationPlate}
            inputProps={{
              onChange: (event: ChangeEvent<HTMLInputElement>) => {
                setRegistrationPlate(event.target.value);
              }
            }}
          />
        </TextFieldsContainer>
      </InputContainer>
    </>
  );
};

export default ValuationForm;
