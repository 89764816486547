import { createSelector } from 'reselect';
import { ReduxStore } from '../interfaces/store';

const root = (state: ReduxStore) => state.header;

export const headerHeightSelector = createSelector(
  root,
  header => header?.headerHeight ?? null
);

export const isHeaderHiddenSelector = createSelector(
  root,
  header => header?.isHeaderHidden ?? false
);

export const isHeaderSlideOpenSelector = createSelector(
  root,
  header => header?.isHeaderSlideOpen ?? true
);

export const isHeaderSlideEnabledSelector = createSelector(
  root,
  header => header?.isHeaderSlideEnabled ?? true
);
