import jwtDecode from 'jwt-decode';
import store from '~/config/store';
import { requestErrorHandler } from './notifyError';
import { getHasSeenLatestAuctionsStatus } from '~/App/shared/actions/targetedAuctionsActions';
import Auction from '~/App/shared/interfaces/Auction';
import { Session } from '~/App/shared/interfaces/store/Session';

// TODO: Hardcoded for now, endpoint for that is currently missing
// If there's more possible TARGETED_AUCTION_GROUPS they have to be added here as a constant
export const TARGETED_AUCTION_GROUPS = [
  {
    id: 1,
    name: 'Volkswagen Finans',
    url: 'Volkswagen-Finans',
    typeId: 1
  }
];

export const getUsersTargetedAuctionGroupIds = (idToken: string) => {
  const decodedJwt: Record<string, string> = jwtDecode(idToken);
  const groupIds = decodedJwt?.targeted_auction_groups ?? '';
  const parsedGroupIds = groupIds?.split(',').map(e => parseInt(e, 10));

  const groupsToShow = parsedGroupIds.filter(gr =>
    TARGETED_AUCTION_GROUPS.find(o => o.id === gr)
  );

  return groupsToShow;
};

export const getTargetedAuctionGroupUrlById = (id: number) => {
  const item = TARGETED_AUCTION_GROUPS.find(group => group.id === id);

  return item?.url ?? '';
};

export const getTargetedAuctionGroupNameById = (id: number) => {
  const item = TARGETED_AUCTION_GROUPS.find(group => group.id === id);

  return item?.name ?? '';
};

export const getTargetedAuctionGroupIdByName = (name: string) => {
  const item = TARGETED_AUCTION_GROUPS.find(group => group.name === name);

  return item?.id ?? '';
};

export const getTargetedAuctionGroupUrl = (auction: Auction) => {
  const id = auction.id;

  if (id) {
    return getTargetedAuctionGroupUrlById(parseInt(id));
  }

  return '';
};

export const pollTargetedAuctions = (group: number, session: Session) => {
  store
    .dispatch(
      getHasSeenLatestAuctionsStatus({
        idToken: session.auth.idToken,
        groupId: group,
        memberId: session.member.id
      })
    )
    .catch(requestErrorHandler);
};

export const TARGETED_AUCTIONS_POLLING_INTERVALL_MILLISECONDS = 1800000;
