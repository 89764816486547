import { CarguideQuery } from '~/config/generated/graphql';
import { CarguideQuestionType } from './types';

export const handleCarguideCmsData = ({ carGuide }: CarguideQuery) => ({
  title: carGuide?.title ?? '',
  description: carGuide?.description ?? '',
  startButtonText: carGuide?.startButtonText ?? '',
  image: carGuide?.image?.responsiveImage?.src ?? '',
  questions:
    carGuide?.questions.map(q => {
      return {
        ...q,
        image: q?.image?.responsiveImage?.src ?? '',
        tag: (q?.tag ?? '') as CarguideQuestionType
      };
    }) ?? [],
  seo: carGuide?.seo ?? { title: '', description: '' },
  kvdHelperUrl: carGuide?._editingUrl ?? ''
});
