import React, { MutableRefObject, ReactNode } from 'react';
import styled from 'styled-components';
import { mq } from '@kvdbil/components';
import {
  PartialMQSizeValues,
  mqSizesOrderSorted,
  getMqSizesWithFallbacks
} from '~/helpers/mq';

/*
withExtraElement - if set true it means that for screen from tablet to laptopXL
one column is occupied by an additional element (for filter page it's a
filter sidebar)

- is used to preserve the old withExtraElement behavior */
export const WithExtraElementShownOnScreenSize: PartialMQSizeValues = {
  tablet: 2,
  laptop: 3,
  laptopXL: 4
};

export const Container = styled.li<PartialMQSizeValues>`
  padding: 0.5rem;
  width: 100%;
  min-width: 100%;

  @media (min-width: 34rem) {
    width: 50%;
    min-width: 50%;
  }

  ${mq(null, 'mobileL')} {
    padding: 0.25rem;

    scroll-snap-align: center;
  }

  /* generats all sizes for all meadia queries with fallbacks */
  ${(props: PartialMQSizeValues) => {
    // generates all media queries but ignores undefined values
    return mqSizesOrderSorted.map(
      size =>
        props[size] &&
        `${mq(size)} {
            width: calc(100% / ${props[size]});
            min-width: calc(100% / ${props[size]});
        }`
    );
  }}
`;

export type VehicleCardWrapperProps = {
  children: ReactNode;
  innerRef?: MutableRefObject<HTMLLIElement | null>;
} & PartialMQSizeValues;

export const VehicleCardListItem = ({
  children,
  innerRef,
  ...props
}: VehicleCardWrapperProps) => {
  return (
    <Container
      ref={innerRef}
      data-testid="vehicle-cards-item"
      // sets all mq sizes and fallbacks if not set, defualts to undefined
      {...getMqSizesWithFallbacks(props)}
    >
      {children}
    </Container>
  );
};

// Defualts to old behavior if the new mq props is not set
VehicleCardListItem.defaultProps = {
  tablet: 3,
  laptop: 4,
  laptopXL: 4
};

export default VehicleCardListItem;
