export const getIsWebsocketSupported = () => {
  const isWindowDefined = typeof window !== 'undefined';

  if (!isWindowDefined) {
    return false;
  }

  if (navigator.userAgent.indexOf('Chrome-Lighthouse') !== -1) {
    return false;
  }

  const isWebsocketSupported =
    'WebSocket' in window || 'MozWebSocket' in window;

  return isWebsocketSupported;
};
