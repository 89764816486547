import { theme } from '@kvdbil/components';
import { TranslateFunction } from '~/Locale';
import { getMainCategoryFromPath } from '~/helpers/filterTranslation';
import { CategoryOption } from '../../types/FilterSearchParam';

export const objectSearchFieldOptionStyle = (isSelected: boolean) => {
  const selectedColor = theme.colors.text.light;
  const selectedBackground = theme.colors.info.main;

  return {
    color: isSelected ? selectedColor : theme.colors.text.dark,
    background: isSelected ? selectedBackground : 'none',
    '&:hover': {
      background: isSelected ? selectedBackground : theme.colors.background.gray
    },
    paddingLeft: '1.5rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  };
};

export const getDefaultCategoryOption = (
  t: TranslateFunction,
  path: string,
  categoryOptions: CategoryOption[]
) => {
  const mainCategory = getMainCategoryFromPath(path, t);
  const categoryOption = categoryOptions.find(
    ({ value }) => value === mainCategory
  );

  return categoryOption ?? categoryOptions[0];
};
