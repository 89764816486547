import { useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useQueryParams } from './useQueryParams';

export const useSearchParams: () => [
  URLSearchParams,
  (name: string, value: unknown | unknown[]) => void,
  (params: Record<string, unknown | unknown[]>) => void
] = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = useQueryParams();

  const update = useCallback(
    (params: URLSearchParams, name: string, value: string) => {
      if (value === '') {
        params.delete(name);
        return params;
      }

      if (typeof value === 'undefined' || value === undefined) {
        params.delete(name);
        return params;
      }

      if (Array.isArray(value) && value.length === 0) {
        params.delete(name);
        return params;
      }

      params.set(name, value);

      return params;
    },
    []
  );

  const historyReplaceSearchParam = useCallback(
    (params: URLSearchParams) => {
      // makes sure we have the same order of params
      const orderedParams = params.toString();

      history.replace({
        pathname: location.pathname,
        search: orderedParams
      });
    },
    [history, location]
  );

  const setSearchParam = useCallback(
    (name: string, value: string) => {
      const newParams = new URLSearchParams(location.search);

      update(newParams, name, value);

      historyReplaceSearchParam(newParams);
    },
    [update, historyReplaceSearchParam, location.search]
  );

  const setSearchParams = useCallback(
    (params: Record<string, string>) => {
      const newParams = new URLSearchParams(location.search);

      Object.entries(params).forEach(([key, value]) => {
        update(newParams, key, value);
      });

      historyReplaceSearchParam(newParams);
    },
    [update, historyReplaceSearchParam, location.search]
  );

  return [searchParams, setSearchParam, setSearchParams];
};
