import styled from 'styled-components';
import React from 'react';
import { CarGuideCardProps } from '~/App/views/Carguide/types';
import CarGuideMatchedList from '~/App/shared/components/ProductCard/components/CarGuideMatchedList';
import { useTranslation } from '~/Locale';
import { mq } from '@kvdbil/components';

const Container = styled.div`
  position: relative;
  margin-top: 1rem;

  ${mq('tablet')} {
    margin-top: 0.5rem;
  }
`;

const Divider = styled.div`
  margin-bottom: 0.5rem;
  border-top: 2px solid ${({ theme }) => theme.colors.gray.light6};

  ${mq('tablet')} {
    margin-bottom: 0;
  }
`;

export interface Props {
  carGuideCardProps: CarGuideCardProps;
}

const CarGuideMatchingInfo = ({ carGuideCardProps }: Props) => {
  const { t } = useTranslation();
  return (
    <Container data-testid="car-guide-matching-info">
      <Divider />
      {carGuideCardProps.criterias.important.length !== 0 && (
        <CarGuideMatchedList
          matched={carGuideCardProps.criterias.important}
          title={t('Very')}
          questions={carGuideCardProps.questions}
        />
      )}
      {carGuideCardProps.criterias.niceToHave.length !== 0 && (
        <CarGuideMatchedList
          matched={carGuideCardProps.criterias.niceToHave}
          title={t('A little')}
          questions={carGuideCardProps.questions}
        />
      )}
    </Container>
  );
};

export default CarGuideMatchingInfo;
