import { useCallback, useEffect, useMemo, useState } from 'react';
import logger from '~/helpers/logger';
import {
  BankIdAuthResponse,
  postBankIdAuthStart
} from '~/helpers/orchestration/auth';

type bankIdAuthStartArgs = {
  onComplete?: (data: BankIdAuthResponse) => void;
  onError?: (error: unknown) => void;
};

export const useBankIdAuth = () => {
  const [authData, setAuthData] = useState<BankIdAuthResponse | null>(null);
  const [error, setErrors] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const authAbortController = useMemo(() => new AbortController(), []);

  const setBankIdAuthData = useCallback((data: BankIdAuthResponse | null) => {
    setAuthData(data);
  }, []);

  const setBankIdError = useCallback((error: string) => {
    setErrors(error);
  }, []);

  const bankIdAuthStart = useCallback(
    async ({ onComplete, onError }: bankIdAuthStartArgs) => {
      setIsLoading(true);

      try {
        const result = await postBankIdAuthStart(authAbortController.signal);
        setIsLoading(false);
        onComplete?.(result?.data);
        setAuthData(result?.data);
      } catch (error: unknown) {
        if (error instanceof Error && error.name === 'CanceledError') {
          return;
        }
        setIsLoading(false);
        logger.error('Failed to start BankID authentication', error);
        onError?.(error);
      }
    },
    [authAbortController.signal]
  );

  // Aborts auth request on unmount to prevent memory leaks
  useEffect(() => {
    return () => {
      authAbortController.abort();
    };
  }, [authAbortController]);

  return {
    qrCode: authData?.qrCode,
    autoStartToken: authData?.autoStartToken,
    orderRef: authData?.orderRef,
    error,
    setData: setBankIdAuthData,
    setError: setBankIdError,
    bankIdAuthStart,
    isLoading
  };
};

const BANKID_AUTH_TIMEOUT = 60000;

interface UseBankIdAuthTimeoutProps {
  isActive: boolean;
  onTimeout?: () => void;
  timeoutDuration?: number;
}

export const useBankIdAuthTimeout = ({
  isActive,
  onTimeout,
  timeoutDuration = BANKID_AUTH_TIMEOUT
}: UseBankIdAuthTimeoutProps) => {
  useEffect(() => {
    let timeoutRef: ReturnType<typeof setTimeout>;
    if (isActive) {
      timeoutRef = setTimeout(() => {
        onTimeout?.();
      }, timeoutDuration);
    }

    return () => {
      clearTimeout(timeoutRef);
    };
  }, [isActive, onTimeout, timeoutDuration]);
};
