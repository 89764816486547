/**
 * @deprecated TODO: [KVDBIL-1634] refactor...
 */

import {
  getFavouriteAuctions,
  getMyAuctions as getMyAuctionsCall,
  searchAuctions as searchAuctionsCall,
  SearchAuctionsRequest,
  SearchAuctionsResponse,
  SearchAuctionsResponseWithMeta
} from '~/helpers/orchestration/auctions';

import { auctionListStepSize } from '~/App/shared/selectors/auctionListSelector';
import { idToken } from '~/App/shared/selectors/sessionSelector';
import { requestErrorHandler } from '~/helpers/notifyError';
import Auction from '../interfaces/Auction';
import { ReduxStore } from '../interfaces/store';
import { buildStateUrl } from '../selectors/auctionSearchSelector';
import { EvolvedSearchParams } from '../interfaces/AuctionSearchParams';
import { AuctionSearchQuery } from '../interfaces/AuctionSearchQuery';
import { ReduxDispatch } from '../interfaces/Redux';

/**
 * @deprecated TODO: [KVDBIL-1634] remove this
 */
export const SEARCH_OBJECTS_REQUEST = 'SEARCH_OBJECTS_REQUEST';
/**
 * @deprecated TODO: [KVDBIL-1634] remove this
 */
export const SEARCH_OBJECTS_SUCCESS = 'SEARCH_OBJECTS_SUCCESS';
/**
 * @deprecated TODO: [KVDBIL-1634] remove this
 */
export const SEARCH_OBJECTS_FAILURE = 'SEARCH_OBJECTS_FAILURE';

/**
 * @deprecated TODO: [KVDBIL-1634] remove this
 */
export const searchObjectsRequest = (payload: {
  terms?: string;
  params?: EvolvedSearchParams | Partial<AuctionSearchQuery>;
  isFixedPriceSearch: boolean;
  isBiddingSearch: boolean;
}) => ({
  type: 'SEARCH_OBJECTS_REQUEST' as const,
  payload
});

/**
 * @deprecated TODO: [KVDBIL-1634] remove this
 */
export const searchObjectsSuccess = (
  payload: SearchAuctionsResponseWithMeta
) => ({
  type: 'SEARCH_OBJECTS_SUCCESS' as const,
  payload
});

/**
 * @deprecated TODO: [KVDBIL-1634] remove this
 */
export const searchObjectsFailure = (payload: unknown | Error) => ({
  type: 'SEARCH_OBJECTS_FAILURE' as const,
  payload
});

/**
 * @deprecated TODO: [KVDBIL-1634] remove this
 */
export type SearchObjectsRequestAction = ReturnType<
  typeof searchObjectsRequest
>;

/**
 * @deprecated TODO: [KVDBIL-1634] remove this
 */
export type SearchObjectsSuccessAction = ReturnType<
  typeof searchObjectsSuccess
>;

/**
 * @deprecated TODO: [KVDBIL-1634] remove this
 */
export type SearchObjectsFailureAction = ReturnType<
  typeof searchObjectsFailure
>;

/**
 * @deprecated TODO: [KVDBIL-1634] remove this
 */
export type SearchObjectsAction =
  | SearchObjectsRequestAction
  | SearchObjectsSuccessAction
  | SearchObjectsFailureAction;

/**
 * @deprecated TODO: [KVDBIL-1634] remove this
 */
export const searchBuyCarObjectsSuccess = (
  payload: SearchAuctionsResponse
) => ({
  type: 'SEARCH_BUY_CAR_OBJECTS_SUCCESS' as const,
  payload
});

/**
 * @deprecated TODO: [KVDBIL-1634] remove this
 */
export type SearchAuctionObjectsSuccessAction = ReturnType<
  typeof searchBuyCarObjectsSuccess
>;

/**
 * @deprecated TODO: [KVDBIL-1634] remove this
 */
type SearchParamsArgs = {
  params?: EvolvedSearchParams | Partial<AuctionSearchQuery>;
  offset?: number;
  limit?: number;
  cancellable?: boolean;
  showLoading?: boolean;
  fetchFunction?: (
    request: SearchAuctionsRequest
  ) => Promise<SearchAuctionsResponse | undefined>;
  isFixedPriceSearch?: boolean;
  isBiddingSearch?: boolean;
};

/**
 * @deprecated TODO: [KVDBIL-1634] remove this
 */
export const searchAuctions =
  ({
    params,
    offset = 0,
    limit = 20,
    cancellable = true,
    showLoading = true,
    fetchFunction = searchAuctionsCall,
    isFixedPriceSearch = false,
    isBiddingSearch = false
  }: SearchParamsArgs) =>
  async (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();

    const token = idToken(state);
    const stepSize = auctionListStepSize(state);

    const searchParams = params || {};
    const search = buildStateUrl(searchParams);

    showLoading &&
      dispatch(
        searchObjectsRequest({
          terms: searchParams.terms,
          params: searchParams,
          isFixedPriceSearch,
          isBiddingSearch
        })
      );

    const meta = {
      lastSearchWithToken: Boolean(token)
    };

    try {
      const response = await fetchFunction({
        cancellable,
        idToken: token,
        params: searchParams,
        limit: limit || stepSize,
        offset: offset
      });

      if (!response) {
        return;
      }

      dispatch(
        searchObjectsSuccess({
          ...response,
          search,
          meta
        })
      );

      dispatch(
        searchBuyCarObjectsSuccess({ ...response, params: searchParams })
      );
    } catch (err: unknown) {
      requestErrorHandler(err);
      return dispatch({
        type: SEARCH_OBJECTS_FAILURE,
        payload: err
      });
    }
  };

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved
 */
export const GET_MY_AUCTIONS_REQUEST = 'GET_MY_AUCTIONS_REQUEST';

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved
 */
export const GET_MY_AUCTIONS_SUCCESS = 'GET_MY_AUCTIONS_SUCCESS';

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved
 */
export const GET_MY_AUCTIONS_FAILURE = 'GET_MY_AUCTIONS_FAILURE';

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved
 */
export const getMyAuctions =
  () => (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const token = idToken(getState());

    getMyAuctionsCall({ params: { status: 'OPEN' }, idToken: token })
      .then(auctions => {
        dispatch({ type: GET_MY_AUCTIONS_SUCCESS, payload: auctions });
      })
      .catch((err: unknown) => {
        dispatch({ type: GET_MY_AUCTIONS_FAILURE, payload: err });
      });
  };

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved
 */
export const GET_MY_FAVOURITE_AUCTIONS_REQUEST =
  'GET_MY_FAVOURITE_AUCTIONS_REQUEST';

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved
 */
export const GET_MY_FAVOURITE_AUCTIONS_SUCCESS =
  'GET_MY_FAVOURITE_AUCTIONS_SUCCESS';

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved
 */
export const GET_MY_FAVOURITE_AUCTIONS_FAILURE =
  'GET_MY_FAVOURITE_AUCTIONS_FAILURE';

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved
 */
const getMyFavouriteAuctionsRequest = () => ({
  type: 'GET_MY_FAVOURITE_AUCTIONS_REQUEST' as const
});

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved
 */
const getMyFavouriteAuctionsSuccess = (payload: Auction[]) => ({
  type: 'GET_MY_FAVOURITE_AUCTIONS_SUCCESS' as const,
  payload
});

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved
 */
const getMyFavouriteAuctionsFailure = (payload: unknown | Error) => ({
  type: 'GET_MY_FAVOURITE_AUCTIONS_FAILURE' as const,
  payload
});

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved
 */
type GetMyFavouriteAuctionsRequestAction = ReturnType<
  typeof getMyFavouriteAuctionsRequest
>;

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved
 */
type GetMyFavouriteAuctionsSuccessAction = ReturnType<
  typeof getMyFavouriteAuctionsSuccess
>;

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved
 */
type GetMyFavouriteAuctionsFailureAction = ReturnType<
  typeof getMyFavouriteAuctionsFailure
>;

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved
 */
export type GetMyFavouriteAuctionsAction =
  | GetMyFavouriteAuctionsRequestAction
  | GetMyFavouriteAuctionsSuccessAction
  | GetMyFavouriteAuctionsFailureAction;

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved
 */
export const getMyFavouriteAuctions =
  () =>
  (dispatch: ReduxDispatch, getState: () => ReduxStore): Promise<void> => {
    const token = idToken(getState());

    dispatch(getMyFavouriteAuctionsRequest());

    return getFavouriteAuctions({ idToken: token })
      .then(auctions => {
        dispatch(getMyFavouriteAuctionsSuccess(auctions));
      })
      .catch((error: unknown) => {
        dispatch(getMyFavouriteAuctionsFailure(error));
      });
  };
