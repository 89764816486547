import { Button, Flex, TinyTitleBold, theme } from '@kvdbil/components';
import { Color, ColorShade, TextColor } from '@kvdbil/components/types/Types';
import React from 'react';
import styled, { css } from 'styled-components';
import Link from '~/App/shared/components/Link';
import { PromoButtonRecord } from '~/config/generated/graphql';

export type CmsColors = {
  backgroundColor: Color;
  backgroundColorVersion: ColorShade;
  textColor: TextColor;
};

const Container = styled(Flex)<CmsColors>`
  ${({ backgroundColor, backgroundColorVersion, textColor }) => css`
    background-color: ${theme.colors[backgroundColor][backgroundColorVersion]};
    color: ${theme.colors.text[textColor]};
  `}

  padding: 1rem 1rem;
  width: 100%;
`;

const StyledButton = styled(Button)<Pick<CmsColors, 'textColor'>>`
  color: ${({ textColor }) => theme.colors.text[textColor]};
`;

type Props = {
  backgroundColor: Color;
  backgroundColorVersion: ColorShade;
  textColor: TextColor;
  text: string;
  button?: PromoButtonRecord;
};

function SmallPromoBanner({
  backgroundColor,
  backgroundColorVersion,
  textColor,
  text,
  button
}: Props) {
  return (
    <Container
      direction={{ mobileS: 'column', tablet: 'row' }}
      backgroundColor={backgroundColor}
      backgroundColorVersion={backgroundColorVersion}
      textColor={textColor}
    >
      <TinyTitleBold>{text}</TinyTitleBold>
      {button && (
        <Link to={button.link}>
          <StyledButton
            size="regular"
            color={button.backgroundColor as Color}
            colorShade={button.backgroundColorShade as ColorShade}
            textColor={button.textColor as TextColor}
          >
            {button.text}
          </StyledButton>
        </Link>
      )}
    </Container>
  );
}

export default SmallPromoBanner;
