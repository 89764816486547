import React from 'react';
import styled from 'styled-components';

import { getColor, getSizeInPx, setSize } from '../helper';
import { AnswerSize, AnswerType, StyledAnswerIconProps } from '../types';
import { theme, RatingIcon } from '@kvdbil/components';

const fillIconImportant = (fillColor = 'white') => `
  cursor: pointer;
  color: ${fillColor};
  transition: color 0.2s;
`;

const fillCircle = (color: string) => `
  cursor: pointer;
  border: 1.5px solid  ${theme.colors.background.light};
  background-color: ${color}};
  transition: background-color 0.2s;
`;

export const IconImportant = styled.div<StyledAnswerIconProps>`
  ${({ sizePx }) => setSize(sizePx)};
  display: flex;
  stroke-width: 1.5px;
  stroke: ${({ color }) => color};
  color: ${({ color }) => color};
  transition: color 0.2s;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  ${({ filled, fillColor, touch }) =>
    (touch || filled) && fillIconImportant(fillColor)}

  @media (hover: hover) and (pointer: fine) {
    ${({ isClickable, fillColor }) =>
      isClickable &&
      `:hover {
        ${fillIconImportant(fillColor)}
    }`}
  }
`;

export const Circle = styled.div<StyledAnswerIconProps>`
  display: grid;
  ${({ sizePx }) => setSize(sizePx)};
  border-radius: 100%;
  border: ${({ size }) => (size === 'small' ? `1px` : `1.5px`)} solid
    ${({ color }) => color};
  transition: border-color 0.2s;
  align-items: center;
  justify-items: center;

  ${({ filled, color, touch }) => (touch || filled) && fillCircle(color)}

  @media (hover: hover) and (pointer: fine) {
    ${({ isClickable, color }) =>
      isClickable &&
      `:hover {
        ${fillCircle(color)}
    }`}
  }
`;

interface Props {
  size: AnswerSize;
  type: AnswerType;
  title?: string;
  isClickable?: boolean;
  filled?: boolean;
  touch?: boolean;
}

const ImportantAnswerIcon = ({
  size,
  type,
  isClickable = false,
  filled = false,
  touch = false,
  title = type
}: Props) => {
  const color = getColor(type);
  return (
    <IconImportant
      data-testid="important-icon"
      color={color}
      touch={touch}
      filled={filled}
      isClickable={isClickable}
      sizePx={getSizeInPx(size)}
    >
      <Circle
        color={color}
        touch={touch}
        isClickable={isClickable}
        size={size}
        sizePx={getSizeInPx(size)}
        filled={filled}
      >
        <RatingIcon title={title} />
      </Circle>
    </IconImportant>
  );
};

export default ImportantAnswerIcon;
