import React from 'react';
import { Path } from 'react-hook-form';
import TextFieldWithControl from '~/App/shared/components/Fields/TextFieldWithControl';
import {
  VAT_NUMBER_MAX_LENGTH,
  VAT_NUMBER_MIN_LENGTH
} from '~/App/shared/validation/constants';
import { TextFieldProps } from '../types';

const VatNumberField = <FormState,>({
  control,
  t,
  name,
  rules,
  ...props
}: TextFieldProps<FormState>) => {
  const validationRules = {
    required: {
      value: true,
      message: t(`VAT number can't be blank`)
    },
    minLength: {
      value: VAT_NUMBER_MIN_LENGTH,
      message: t('VAT number is too short')
    },
    maxLength: {
      value: VAT_NUMBER_MAX_LENGTH,
      message: t('VAT number is too long')
    },
    ...rules
  };

  const fieldName = name ?? ('vatNumber' as Path<FormState>);

  return (
    <TextFieldWithControl
      {...props}
      control={control}
      name={fieldName}
      label={t('VAT number')}
      rules={validationRules}
    />
  );
};

export default VatNumberField;
