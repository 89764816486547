import { mqNumberSizes } from '@kvdbil/components';
import { useWindowSize } from './useWindowSize';
import { MqSize } from '../components/MediaQueryContainer';
import { useCallback } from 'react';

/**
 * Returns an object with boolean properties indicating the current device size.
 * Also provides utility functions to check if the device is smaller or greater than a specific size.
 * This hook will not run server side, so it will cause a flicker on the first render.
 *
 * @returns An object with the following properties:
 * - `isMobile`: `true` if the device is a mobile device (width < 768px), `false` otherwise.
 * - `isTablet`: `true` if the device is a tablet device (768px <= width < 1024px), `false` otherwise.
 * - `isLaptop`: `true` if the device is a laptop device (1024px <= width < 1440px), `false` otherwise.
 * - `isDesktop`: `true` if the device is a desktop device (width >= 1440px), `false` otherwise.
 * - `isSmallerThan(device)`: A function that returns `true` if the device width is smaller than the specified device size.
 * - `isGreaterThan(device)`: A function that returns `true` if the device width is greater than the specified device size.
 */

export const useIsDevice = () => {
  const { width } = useWindowSize();

  const isMobile = Boolean(width && width < mqNumberSizes.tablet);
  const isTablet = !isMobile && Boolean(width && width < mqNumberSizes.laptop);
  const isLaptop =
    !isMobile && !isTablet && Boolean(width && width < mqNumberSizes.desktop);
  const isDesktop =
    !isMobile &&
    !isTablet &&
    !isLaptop &&
    Boolean(width && width <= mqNumberSizes.desktop);

  const isSmallerThan = useCallback(
    (device: MqSize) => Boolean(width && width < mqNumberSizes[device]),
    [width]
  );
  const isGreaterThan = useCallback(
    (device: MqSize) => Boolean(width && width >= mqNumberSizes[device]),
    [width]
  );

  return {
    isMobile,
    isTablet,
    isLaptop,
    isDesktop,
    isSmallerThan,
    isGreaterThan,
    isBeingDetermined: typeof width === 'undefined'
  };
};
