import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { regexRegistrationPlate } from '~/helpers/regex';
import { mq, TextField, Button, Stack, Caption } from '@kvdbil/components';
import { useTranslation } from '~/Locale';
import Link from '../shared/components/Link';

const Container = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.text.dark};
`;

const ResponsiveStack = styled(Stack)`
  width: 100%;

  & button {
    width: 100%;
  }

  margin: auto;

  ${mq('tablet')} {
    margin: auto;
  }
`;

const SellingText = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colors.text.dark};
  margin-bottom: 0.5rem;
`;

interface Props {
  sellingText?: string;
  title: string;
  label?: string;
  css?: string;
  value?: string;
  linkToHeavyEquipment?: boolean;
  onSubmit(registrationPlate: string): void;
  inputProps?: Record<string, unknown>;
  isLoading?: boolean;
}

const RegistrationPlateInput = ({
  title,
  sellingText,
  label,
  onSubmit,
  inputProps,
  value = '',
  linkToHeavyEquipment,
  isLoading,
  ...props
}: Props) => {
  const [registrationPlate, setRegistrationPlate] = useState(value);
  const { t } = useTranslation();

  useEffect(() => {
    setRegistrationPlate(value.toUpperCase());
  }, [value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRegistrationPlate(e.target.value.toUpperCase());
  };

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onSubmit(registrationPlate);
  };

  const isRegistrationPlateValid =
    regexRegistrationPlate.test(registrationPlate);

  return (
    <Container {...props}>
      {sellingText && <SellingText>{sellingText}</SellingText>}
      <form>
        <ResponsiveStack spacing={0.5}>
          <TextField
            onChange={handleChange}
            value={registrationPlate}
            placeholder={t('Enter license number')}
            label={label}
            {...inputProps}
          />

          {linkToHeavyEquipment && (
            <Caption>
              {t('Is the vehicle missing a reg. number?')}{' '}
              <Link to="/ROUTES.SELL_HEAVY_EQUIPMENT">{t('Click here!')}</Link>
            </Caption>
          )}

          <Button
            size="regular"
            onClick={handleSubmit}
            isDisabled={!isRegistrationPlateValid || isLoading}
            isLoading={isLoading}
          >
            {title}
          </Button>
        </ResponsiveStack>
      </form>
    </Container>
  );
};

export default RegistrationPlateInput;
