import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { resetPhoneVerification } from '~/App/shared/actions/verifyPhoneNumber';
import {
  phoneVerificationOnBackward,
  phoneVerificationIsVerified,
  phoneVerificationHasUpdatedNumber
} from '~/App/shared/selectors/verifyPhoneNumberSelector';
import SendCodeWithUpdatePhoneNumber from './components/SendCodeWithUpdatePhoneNumber';
import EnterCode from './components/EnterCode';
import { CloseIcon } from '@kvdbil/components';
import Section from '../Layout/Section';

const PhoneVerificationWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 2.5rem;

  svg {
    font-size: 1.75rem;
    cursor: pointer;
  }
`;

interface Props {
  isBiddingView?: boolean;
  handleClose?(): void;
}

const VerifyPhoneNumber = ({ handleClose, isBiddingView }: Props) => {
  const dispatch = useDispatch();
  const isVerified = useSelector(phoneVerificationIsVerified);
  const onBackward = useSelector(phoneVerificationOnBackward);
  const hasUpdatedNumber = useSelector(phoneVerificationHasUpdatedNumber);
  const [step, setStep] = useState('sendCode');

  useEffect(() => {
    if (isVerified) {
      setTimeout(() => {
        typeof handleClose === 'function' && handleClose();
        dispatch(resetPhoneVerification());
      }, 1500);
    }
  }, [isVerified, handleClose, dispatch]);

  useEffect(() => {
    setStep('sendCode');
  }, [hasUpdatedNumber]);

  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    setTimeout(() => {
      setStep('sendCode');
    }, 2000);
  }, [onBackward]);

  const Container = isBiddingView ? React.Fragment : Section;

  return (
    <Container {...(isBiddingView ? {} : { maxWidth: 21.5, spacing: 2.5 })}>
      {!isBiddingView && (
        <CloseButtonContainer>
          <CloseIcon onClick={handleClose} />
        </CloseButtonContainer>
      )}

      <PhoneVerificationWrapper
        id="verifyPhoneNumber"
        data-testid="phone-number-verification"
      >
        {step === 'sendCode' && (
          <SendCodeWithUpdatePhoneNumber setStep={setStep} />
        )}
        {step === 'enterCode' && <EnterCode handleClose={handleClose} />}
      </PhoneVerificationWrapper>
    </Container>
  );
};

export default VerifyPhoneNumber;
