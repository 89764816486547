import React, { useCallback, useState } from 'react';
import { Select, Subtitle, Spinner } from '@kvdbil/components';
import styled from 'styled-components';
import ErrorMessage from '~/App/shared/components/ErrorMessage';
import { formatMoney } from '~/helpers/currency';
import ProcessObject from '../../interfaces/ProcessObject';
import { Facility, FacilityFilters } from '../../interfaces/Facility';
import { useTranslation } from '~/Locale';
import { useSelector } from 'react-redux';
import { calculateTransport } from '~/helpers/calculator';
import { session as sessionSelector } from '~/App/shared/selectors/sessionSelector';
import { SelectOption } from '../../interfaces/SelectOption';

const TransportCalculatorContainer = styled.div`
  padding-top: 2rem;
  max-width: 19rem;
  margin: 0 auto;
`;

const TransportCalculatorHeading = styled(Subtitle)`
  text-align: center;
  margin-bottom: 0.5rem;
`;

const TransportCalculatorAmount = styled(Subtitle)`
  text-align: center;
`;

const SelectWrapper = styled.li`
  margin: 1rem 0;
  text-align: left;
`;

const TransportCalculatorSpinner = styled.div`
  display: flex;
  justify-content: center;
`;

const TransportCalculatorErrorMessage = styled.div`
  margin: 1rem 0;
`;

interface Props {
  toDropdownLabel: string;
  fromDropdownLabel: string;
  heading: string;
  amountlabel?: string;
  initialPrice?: string;
  toFacility?: SelectOption;
  fromFacility?: SelectOption;
  disableFromOption?: boolean;
  processObject?: ProcessObject;
  includeFacilitiesByObjectTypes?: FacilityFilters[];
  facilities: Facility[];
  initialFromFacility?: SelectOption;
  initialToFacility?: SelectOption;
}

const TransportCalculatorForm = ({
  fromDropdownLabel,
  toDropdownLabel,
  heading,
  amountlabel = '',
  initialPrice,
  disableFromOption,
  processObject,
  facilities,
  initialFromFacility,
  initialToFacility
}: Props) => {
  const { t } = useTranslation();
  const [price, setPrice] = useState<string>(initialPrice ?? '-');
  const [fromFacility, setFromFacility] = useState<SelectOption | null>(
    initialFromFacility ?? null
  );
  const [toFacility, setToFacility] = useState<SelectOption | null>(
    initialToFacility ?? null
  );
  const session = useSelector(sessionSelector);

  const [isFetchingTransportCost, setIsFetchingTransportCost] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [currency, setCurrency] = useState<string>('');

  const memberId = session?.member?.id ?? '';
  const processObjectId = processObject?.id ?? '';

  const fromOptions = facilities
    .filter(facility => {
      if (!facility.transportFromAvailable) {
        return false;
      }

      return true;
    })
    .map(facility => {
      if (fromFacility && fromFacility.value === facility.id) {
        return { value: facility.id, label: facility.name, isDisabled: true };
      }

      return { value: facility.id, label: facility.name };
    });

  const toOptions = facilities
    .filter(facility => {
      if (!facility.transportToAvailable) {
        return false;
      }

      return true;
    })
    .map(facility => {
      if (fromFacility && fromFacility.value === facility.id) {
        return { value: facility.id, label: facility.name, isDisabled: true };
      }

      return { value: facility.id, label: facility.name };
    });

  const handlePriceChange = useCallback(
    (fromFacility: string, toFacility: string) => {
      if (fromFacility === 'default' || toFacility === 'default') {
        return;
      }

      if (!fromFacility || !toFacility) {
        return;
      }

      if (fromFacility === toFacility) {
        return;
      }
      setIsFetchingTransportCost(true);

      calculateTransport(fromFacility, toFacility, memberId, processObjectId)
        .then(transport => {
          setPrice(String(transport.price));
          setCurrency(transport.currency);
          setErrorMessage(null);
        })
        .catch(() => {
          setErrorMessage(t('Something went wrong, please try again later.'));
        })
        .finally(() => {
          setIsFetchingTransportCost(false);
        });
    },
    [t, processObjectId, memberId]
  );

  const handleToFacilityChange = useCallback(
    (facility: SelectOption) => {
      if (facility) {
        if (fromFacility) {
          handlePriceChange(fromFacility.value, facility.value);
        }
        setToFacility(facility);
      } else {
        setToFacility(null);
      }
    },
    [fromFacility, handlePriceChange]
  );

  const handleFromFacilityChange = useCallback(
    (facility: SelectOption) => {
      if (facility) {
        if (toFacility) {
          handlePriceChange(facility.value, toFacility.value);
        }
        setFromFacility(facility);
      } else {
        setFromFacility(null);
      }
    },
    [toFacility, handlePriceChange]
  );

  return (
    <TransportCalculatorContainer>
      <TransportCalculatorHeading>{heading}</TransportCalculatorHeading>
      <ul>
        <SelectWrapper>
          <Select
            isSearchable={false}
            name="fromFacility"
            value={fromFacility}
            options={fromOptions}
            onChange={handleFromFacilityChange}
            isDisabled={disableFromOption}
            label={fromDropdownLabel}
            instanceId="1"
          />
        </SelectWrapper>
        <SelectWrapper>
          <Select
            isSearchable={false}
            name="toFacility"
            value={toFacility}
            options={toOptions}
            onChange={handleToFacilityChange}
            label={toDropdownLabel}
            instanceId="2"
          />
        </SelectWrapper>
      </ul>
      {isFetchingTransportCost && (
        <TransportCalculatorSpinner>
          <Spinner color="neutral" speed="fast" size={2} />
        </TransportCalculatorSpinner>
      )}
      {!isFetchingTransportCost && !errorMessage && currency && (
        <TransportCalculatorAmount>
          {`${amountlabel} ${formatMoney({
            value: price,
            currency
          })}*`}
        </TransportCalculatorAmount>
      )}
      {!isFetchingTransportCost && errorMessage && (
        <TransportCalculatorErrorMessage>
          <ErrorMessage errorMessage={errorMessage} />
        </TransportCalculatorErrorMessage>
      )}
    </TransportCalculatorContainer>
  );
};

export default TransportCalculatorForm;
