import React from 'react';
import Bugsnag, { Event } from '@bugsnag/js';
import bugsnagPluginExpress from '@bugsnag/plugin-express';
import BugsnagPluginReact, {
  BugsnagErrorBoundary as BugsnagErrorBoundaryType
} from '@bugsnag/plugin-react';
import { version, bugsnagKey, environment } from '~/config/public/environment';
import store from '~/config/store';
import { CHUNK_LOAD_ERROR_NAME, INVALID_STATE_ERROR } from '~/config/constants';
import {
  isEmailVerified,
  isMemberOrganisationPersonSelector,
  isPhoneNumberVerifiedSelector,
  memberIdSelector,
  memberLevel
} from '~/App/shared/selectors/sessionSelector';
import { isConnectedSelector } from '~/App/shared/selectors/connectionSelector';
import { abTests } from '~/App/shared/selectors/abTestsSelector';
import localforage from 'localforage';
import { getIsWebsocketSupported } from '~/helpers/websocket';
import { featureFlagsSelector } from '~/App/shared/selectors/featureFlagsSelector';

interface ExtendedEvent extends Event {
  originalError: Error | undefined;
}

const handleClientError = async (event: ExtendedEvent) => {
  const state = store.getState();
  const userId = memberIdSelector(state);
  const error: Error | undefined = event.originalError;

  /* Mark as handled & update severity as info */
  if (error?.name === CHUNK_LOAD_ERROR_NAME) {
    event.unhandled = false;
    event.severity = 'info';
  }

  if (error?.name === INVALID_STATE_ERROR) {
    event.unhandled = false;
    await localforage.clear();
  }

  if (userId) {
    event.setUser(userId);

    event.addMetadata('user', {
      memberLevel: memberLevel(state),
      isEmailVerified: isEmailVerified(state),
      isPhoneNumberVerified: isPhoneNumberVerifiedSelector(state),
      isMemberOrganisationPerson: isMemberOrganisationPersonSelector(state)
    });

    const flags = featureFlagsSelector(state);

    event.addMetadata('featureFlags', flags);
  }

  /* Misc */
  event.addMetadata('misc', {
    isWebsocketSupported: getIsWebsocketSupported(),
    isConnected: isConnectedSelector(state),
    abTests: abTests(state)
  });

  event.addMetadata('impersonating', {
    isImpersonating: Boolean(state?.session?.isImpersonating)
  });
};

if (typeof window !== 'undefined') {
  /* Client */
  Bugsnag.start({
    apiKey: bugsnagKey,
    appVersion: version || 'Local',
    releaseStage: environment === 'production' ? 'production' : 'development',
    plugins: [new BugsnagPluginReact()],
    onError: handleClientError
  });
} else {
  /* Server */
  Bugsnag.start({
    apiKey: bugsnagKey,
    releaseStage: environment === 'production' ? 'production' : 'development',
    appVersion: version || 'Local',
    logger: null,
    plugins: [bugsnagPluginExpress]
  });
}

const ReactPlugin = Bugsnag.getPlugin('react');
export const BugsnagErrorBoundary = ReactPlugin?.createErrorBoundary(
  React
) as BugsnagErrorBoundaryType;
