import React from 'react';

export const FatalGraphic = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  >
) => {
  return (
    <span {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 236 165.9"
      >
        <path
          d="M34.2,112.1l35.7,5.5l42.3,6.5l18.2,2.6c3.3,0.5,5-0.4,6.5-2.3l0,0c0.6-0.7,1.1-1.5,1.4-2.4l5.8-17.7
	c1.6-4.8,1.6-11.8,0.3-17.6c2.6-0.6,4.8-2.3,5.3-6.1c1.1-7.3-5.6-9-11-9.1l0.1-0.4l-9.5-23.1c-3.4-8.3-12.7-15.5-21.5-16.8
	l-48.3-7.4c-8.8-1.3-19.9,2.8-25.6,9.6l-16,19.2L17.9,53c-5.2-1.4-12.1-1.9-13.2,5.4c-0.6,3.8,0.9,6,3.3,7.4C5,71,2.9,77.6,3,82.7
	l0.3,18.6c0,0.9,0.4,3.5,7.5,5.4 M43.3,41.3c2.9-3.5,9.8-6.1,14.3-5.4l48.3,7.4c4.6,0.7,10.3,5.1,12.1,9.4l7.5,18.1L30.8,56.3
	L43.3,41.3z M27.3,92.6c-5-0.8-8.5-5.4-7.7-10.4c0.8-5,5.4-8.4,10.4-7.6c5,0.8,8.5,5.4,7.7,10.4C37,90,32.3,93.4,27.3,92.6z
	 M94,111.5l-40.8-6.2l2.1-13.6l40.8,6.2L94,111.5z M118,106.5c-5-0.8-8.5-5.4-7.7-10.4c0.8-5,5.4-8.4,10.4-7.6
	c5,0.8,8.5,5.4,7.7,10.4C127.6,103.9,123,107.3,118,106.5z"
        />
        <path d="M9.9,120.8v-18.2c0-3.3,2.7-6,6-6h12.7c3.3,0,6,2.7,6,6v18.2c0,3.3-2.7,6-6,6H15.9C12.6,126.8,9.9,124.1,9.9,120.8z" />
        <path d="M175.8,102.1h52.2c3.3,0,6,2.7,6,6v12.7c0,3.3-2.7,6-6,6h-52.2c-3.3,0-6-2.7-6-6v-12.7C169.8,104.8,172.5,102.1,175.8,102.1z" />
      </svg>
    </span>
  );
};

export default FatalGraphic;
