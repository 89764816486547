import axios from '~/helpers/orchestration/axiosWithAuthHandling';
import { getGaClientId } from '~/helpers/client/cookie';
import { orchestration } from '~/config/public';
import { AxiosRequestConfig } from 'axios';
import { Bid } from '~/App/shared/interfaces/Bid';

export interface PlaceBidArgs {
  amount: number;
  idToken: string;
  auctionId: string;
}
export const placeBid = ({ amount, idToken, auctionId }: PlaceBidArgs) => {
  return axios.post(
    `${orchestration.url}/auction/auctions/${auctionId}/max-bids`,
    {
      maxBid: {
        amount: amount
      },
      GaClientId: getGaClientId()
    },
    {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      responseType: 'json'
    }
  );
};

interface PostInquiryArgs {
  auctionId?: string;
  idToken: string;
  data: {
    name: string;
    phoneNumber: string;
  };
}

export const postInquiry = ({ auctionId, idToken, data }: PostInquiryArgs) => {
  return axios.post(
    `${orchestration.url}/auction/auctions/${auctionId}/inquiry`,
    {
      inquiry: {
        ...data
      }
    },
    {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      responseType: 'json'
    }
  );
};

export interface EditMaxBidInput {
  amount: number;
  idToken: string;
  maxBidId: number;
  auctionId: string;
}
export const editMaxBid = ({
  amount,
  idToken,
  auctionId,
  maxBidId
}: EditMaxBidInput) =>
  axios.put(
    `${orchestration.url}/auction/auctions/${auctionId}/max-bids/${maxBidId}`,
    {
      maxBid: {
        amount: amount
      },
      GaClientId: getGaClientId()
    },
    {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      responseType: 'json'
    }
  );

export interface GetBrandsResponse {
  brands: {
    [key: string]: string[];
  };
}
export const getBrands = (params = {}) =>
  axios
    .get<GetBrandsResponse>(`${orchestration.url}/auction/brands`, {
      params: params,
      responseType: 'json'
    })
    .then(({ data }) => data.brands);

export const getAuctionSlugByProcessObjectId = (processObjectId: string) =>
  axios
    .get<string>(
      `${orchestration.url}/auction/auction_url/by_process_object_id/${processObjectId}`,
      {
        responseType: 'json'
      }
    )
    .then(({ data }) => data);

interface RequestArgs extends AxiosRequestConfig {
  headers?: Record<string, string>;
}

export const getAuctionBids = (auctionId: string, idToken: string) => {
  const hasJWT = Boolean(idToken);

  const requestArgs: RequestArgs = {
    withCredentials: hasJWT,
    responseType: 'json'
  };

  if (hasJWT) {
    const authHeaders: Record<string, string> = {
      Authorization: `Bearer ${idToken}`
    };

    requestArgs.headers = authHeaders;
  }

  return axios
    .get<{ bids: Bid[] }>(
      `${orchestration.url}/auction/auctions/${auctionId}/bids`,
      requestArgs
    )
    .then(({ data }) => data);
};
