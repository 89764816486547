export const isUrlAbsolute = (url = '') => {
  return (
    url.indexOf('://') > 0 ||
    url.startsWith('//') ||
    url.startsWith('mailto:') ||
    url.startsWith('tel:')
  );
};

export const getUrlSearchParams = (queryString?: string) => {
  if (!queryString) {
    return {};
  }

  const hasParams = queryString.includes('?');
  if (!hasParams) {
    return {};
  }

  const searchParams = new URLSearchParams(queryString.split('?')[1]);

  return Array.from(searchParams.entries()).reduce(
    (acc: Record<string, string>, [key, value]) => ({ ...acc, [key]: value }),
    {}
  );
};
