import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useTranslation } from '~/Locale';
import {
  BodyText,
  Button,
  Flex,
  Spinner,
  Stack,
  Title
} from '@kvdbil/components';
import {
  BankIdAuthStatusResponse,
  getBankIdStatus,
  postBankIdCancel
} from '~/helpers/orchestration/auth';
import logger from '~/helpers/logger';
import QRCode from 'qrcode.react';
import { BankIdStartOnDeviceButton } from './BankIdStartOnDeviceButton';
import AuthCloseButton from './AuthCloseButton';
import { getBankIdErrorMessage } from '~/App/shared/helpers/bankIdHelpers';
import styled from 'styled-components';
import { useBankIdAuthTimeout } from '../hooks/bankIdAuthHooks';

const MaxWidth = styled.div`
  max-width: 340px;
  margin: 0 auto;
  text-align: left;
`;

const POLL_INTERVAL = 1000;

type Props = {
  orderRef?: string;
  autoStartToken?: string;
  initialQrCode?: string;
  pollInterval?: number;
  title?: string;
  subtitle?: string;
  description?: ReactNode;
  onComplete?(orderRef: string): Promise<void>;
  onFail?(error: unknown): void;
  onClose?(): void;
  onBankIdVerificationInProgress?(): void;
  onBack?(): void;
  isStandaloneView?: boolean;
};

export const BankIdAuth = ({
  onComplete,
  onFail,
  onClose,
  onBankIdVerificationInProgress,
  onBack,
  orderRef,
  autoStartToken,
  initialQrCode,
  pollInterval = POLL_INTERVAL,
  title,
  subtitle,
  description,
  isStandaloneView
}: Props) => {
  const { t } = useTranslation();
  const [qrCode, setQrCode] = useState<string | undefined>(initialQrCode);
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);

  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const performComplete = useCallback(async () => {
    try {
      if (orderRef) {
        await onComplete?.(orderRef);
      }
    } catch (error: unknown) {
      onFail?.(error);
    }
  }, [onComplete, onFail, orderRef]);

  const cancelPolling = useCallback(() => {
    intervalRef.current && clearInterval(intervalRef.current);
  }, []);

  const checkStatus = useCallback(async () => {
    if (orderRef && !isRequestInProgress) {
      setIsRequestInProgress(true);

      try {
        const response = await getBankIdStatus<BankIdAuthStatusResponse>(
          orderRef
        );

        const { qrCode, status, hintCode } = response?.data ?? {};

        if (status === 'pending') {
          setQrCode(qrCode);

          if (hintCode === 'userSign') {
            setIsLoading(true);
            onBankIdVerificationInProgress?.();
          }

          setIsRequestInProgress(false);
          return;
        }

        if (status === 'complete') {
          try {
            cancelPolling();
            await performComplete();
          } catch (error: unknown) {
            cancelPolling();
            logger.error('Error while completing BankID auth', error);
            onFail?.(
              t('Something went wrong while authenticating with BankID')
            );
          }
          return;
        } else {
          cancelPolling();
          onFail?.(getBankIdErrorMessage(t, hintCode));
          return;
        }
      } catch (error: unknown) {
        cancelPolling();
        logger.error('Error while polling BankID', error);

        onFail?.(t('Something went wrong while authenticating with BankID'));
        return;
      }
    }
  }, [
    performComplete,
    onBankIdVerificationInProgress,
    orderRef,
    onFail,
    cancelPolling,
    t,
    isRequestInProgress
  ]);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      void checkStatus();
    }, pollInterval);

    return () => {
      intervalRef.current && clearInterval(intervalRef.current);
    };
  }, [checkStatus, pollInterval]);

  const handleOnTimeout = useCallback(() => {
    onClose?.();
    onBack?.();
    onFail?.(
      t('Took too long to verify identity with BankID, try again please.')
    );
  }, [t, onFail, onClose, onBack]);

  useBankIdAuthTimeout({
    isActive: isLoading,
    onTimeout: handleOnTimeout
  });

  const cancelBankIdSession = useCallback(async () => {
    try {
      orderRef && (await postBankIdCancel(orderRef));
    } catch (error: unknown) {
      logger.error(
        `Failed to cancel BankID session, either it's already canceled or doesn't exist.`,
        error
      );
    }
  }, [orderRef]);

  const handleBankIdOnClose = useCallback(() => {
    void cancelBankIdSession();
    onClose?.();
  }, [onClose, cancelBankIdSession]);

  const handleBankIdOnBack = useCallback(() => {
    void cancelBankIdSession();
    onBack?.();
  }, [cancelBankIdSession, onBack]);

  return (
    <Stack spacing={3}>
      <Stack align={'center'}>
        {!isStandaloneView && <AuthCloseButton onClose={handleBankIdOnClose} />}

        <Title style={{ textAlign: 'center' }}>
          {title || t('Sign in with BankID')}
        </Title>

        {subtitle && (
          <BodyText style={{ textAlign: 'center' }}>{subtitle}</BodyText>
        )}

        {description || (
          <MaxWidth>
            <BodyText>{t('BankID authentication bullet point 1')}</BodyText>
            <BodyText>{t('BankID authentication bullet point 2')}</BodyText>
            <BodyText>{t('BankID authentication bullet point 3')}</BodyText>
          </MaxWidth>
        )}

        <QRCode
          renderAs="svg"
          level="Q"
          size={184}
          value={qrCode ?? ''}
          data-testid="bankid-qr-code"
        />

        <BankIdStartOnDeviceButton autoStartToken={autoStartToken} />

        {isLoading && (
          <Flex justify="center" align="center">
            <Spinner color="neutral" />
          </Flex>
        )}
      </Stack>
      {onBack && (
        <Button
          size="regular"
          variant="flat"
          color="neutral"
          onClick={handleBankIdOnBack}
          fullWidth
        >
          {t('Go back')}
        </Button>
      )}
    </Stack>
  );
};
