import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { BodyText, mq, Subtitle } from '@kvdbil/components';
import { useTranslation } from '~/Locale';
import dayjs from 'dayjs';
import Section from '~/App/shared/components/Layout/Section';
import Buttons from './components/Buttons';
import SubMenuItems from './components/SubMenuItems';
import Links from './components/Links';
import { hideFooterButtons } from '~/helpers/footerHelpers';
import { FooterNavigationData } from '~/App/shared/interfaces/store/CmsData';
import {
  FooterNavigationDocument,
  FooterNavigationQuery
} from '~/config/generated/graphql';
import { useCmsData } from '~/App/shared/hooks/useCmsData';
import { useSelector } from 'react-redux';
import { isFooterHiddenSelector } from '~/App/shared/selectors/global';
import KvdHelperWrapper from '~/App/shared/components/KvdHelperWrapper';

const Nav = styled.nav`
  padding: 1rem;
  display: grid;
  grid-template-rows: 1fr;
  row-gap: 1rem;

  ${mq('tablet')} {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1rem;
  }
`;

const Category = styled(Subtitle)`
  margin-bottom: 1.3em;
  color: ${({ theme }) => theme.colors.text.dark};
`;

const MenuColumn = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.light3};
  width: 100%;
  padding-bottom: 1rem;

  ${mq('tablet')} {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const CopyRight = styled(BodyText)`
  padding: 1rem 0;
  text-align: center;
`;

const Footer = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isFooterHidden = useSelector(isFooterHiddenSelector);

  const { data: cmsData } = useCmsData<
    FooterNavigationQuery,
    FooterNavigationData
  >({
    storeKey: 'footerNavigation',
    query: FooterNavigationDocument,
    options: { pause: isFooterHidden }
  });

  const footerButtons = useMemo(
    () => cmsData?.footerButtons,
    [cmsData?.footerButtons]
  );

  if (isFooterHidden) {
    return null;
  }

  return (
    <KvdHelperWrapper url={cmsData?.kvdHelperUrl ?? ''}>
      <Section backgroundColor="darkGray" spacing={2.5} footer>
        <footer>
          {!hideFooterButtons(t, pathname) && (
            <Buttons buttons={footerButtons} />
          )}

          <Nav>
            {cmsData?.linkGroups?.map(({ title, links }, index) => (
              <MenuColumn key={`header-${index}`}>
                <Category>{title}</Category>
                {links?.length > 0 && (
                  <ul key={`desktop-${index}`}>
                    <SubMenuItems items={links} />
                  </ul>
                )}
              </MenuColumn>
            ))}
          </Nav>

          <Links />

          <CopyRight>
            {`${t(
              'Copyright © %1$s kvd.se All rights reserved',
              dayjs(new Date()).format('YYYY')
            )}.`}
          </CopyRight>
        </footer>
      </Section>
    </KvdHelperWrapper>
  );
};

export default Footer;
