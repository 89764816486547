import { BodyText, Flex, Spinner, Stack, Title } from '@kvdbil/components';
import React from 'react';
import AuthCloseButton from '../../components/AuthCloseButton';

type Props = {
  title?: string;
  subtitle?: string;
  onClose(): void;
};

export const VerifyBankIDLoadingView = ({
  title,
  subtitle,
  onClose
}: Props) => {
  return (
    <Stack align={'center'}>
      <AuthCloseButton onClose={onClose} />

      {title && <Title style={{ textAlign: 'center' }}>{title}</Title>}

      {subtitle && (
        <BodyText style={{ textAlign: 'center' }}>{subtitle}</BodyText>
      )}

      <Flex justify={'center'}>
        <Spinner size={'large'} />
      </Flex>
    </Stack>
  );
};

export default VerifyBankIDLoadingView;
