import { StartPageQuery } from '~/config/generated/graphql';

export const handleStartPage = ({ startPage }: StartPageQuery) => {
  return {
    showSmallTrustPilot: startPage?.showSmallTrustpilot,
    promoBanners: {
      smallPromoBanner: startPage?.smallPromoBanner,
      largePromoBanner: startPage?.largePromoBanner
    },
    myVehiclesHeadline: startPage?.myVehiclesHeadline,
    featuredBlocks: {
      title: startPage?.featuredBlocksTitle,
      blockButtons: startPage?.blockButtons
    },
    featuredObjects: startPage?.featuredObjects,
    companyInfo: startPage?.aboutArea,
    contactSection: startPage?.contactSection,
    featureCards: {
      isActive: startPage?.featureCardsIsActive,
      largeFeatureCards: startPage?.largeFeaturedCards,
      smallFeatureCards: startPage?.smallFeatureCards
    },
    bigTrustPilotTitle: startPage?.trustpilotTitle,
    seoInfo: {
      seoInformationStart: startPage?.seoInformation?.[0]?.content?.[0],
      seoInformationStartCorporate: startPage?.seoInformation?.[1]?.content?.[0]
    },
    seo: startPage?.seo,
    mainCategories: {
      headline: startPage?.categoryBlurbsHeadline,
      blurbs: startPage?.categoryBlurbs
    },
    kvdHelperUrl: startPage?._editingUrl
  };
};
