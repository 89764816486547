import { AxiosError } from 'axios';
import Auction from '~/App/shared/interfaces/Auction';
import BaseObject from '~/App/shared/interfaces/BaseObject';
import {
  NotificationSettings,
  NotificationType
} from '~/App/shared/interfaces/NotificationSettings';
import { PayoutDetails } from '~/App/shared/interfaces/PayoutDetails';
import ProcessObject from '~/App/shared/interfaces/ProcessObject';
import { SaleStatus } from '~/App/shared/interfaces/SaleStatus';
import { SavedSearch } from '~/App/shared/interfaces/SavedSearch';
import {
  BusinessProposal,
  SalesAgreement
} from '~/App/shared/interfaces/store/SellFlow';
import { orchestration } from '~/config/public';
import axios from '~/helpers/orchestration/axiosWithAuthHandling';
import { getGaClientId } from '../client/cookie';

export const getBusinessProposals = ({ idToken }: { idToken: string }) =>
  axios
    .get<{ businessProposals: BusinessProposal[] }>(
      `${orchestration.url}/mypages/business-proposals/me?limit=100`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`
        },
        responseType: 'json'
      }
    )
    .then(({ data }) => data.businessProposals);

export const abortBusinessProposal = ({
  idToken,
  proposalId
}: {
  idToken: string;
  proposalId: string;
}) =>
  axios
    .put<{ baseObject: BaseObject }>(
      `${orchestration.url}/mypages/base-objects/${proposalId}/abort`,
      {},
      {
        headers: {
          Authorization: `Bearer ${idToken}`
        },
        responseType: 'json'
      }
    )
    .then(({ data }) => data.baseObject);

export const getProcessObjects = ({ idToken }: { idToken: string }) =>
  axios
    .get<{ processObjects: ProcessObject[] }>(
      `${orchestration.url}/mypages/process-objects/me?limit=100`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`
        },
        responseType: 'json'
      }
    )
    .then(({ data }) => data.processObjects);

interface ProcessObjectPayoutDetails extends ProcessObject {
  payoutDetails: PayoutDetails;
}

export const updateMyPayoutDetails = ({
  idToken,
  processObjectId,
  payoutDetails
}: {
  idToken: string;
  processObjectId: string;
  payoutDetails: Partial<PayoutDetails> | null;
}) =>
  axios
    .put<{ processObject: ProcessObjectPayoutDetails }>(
      `${orchestration.url}/mypages/process-objects/me/${processObjectId}`,
      {
        processObject: {
          payoutDetails
        }
      },
      {
        headers: {
          Authorization: `Bearer ${idToken}`
        },
        withCredentials: true,
        responseType: 'json'
      }
    )
    .then(({ data }) => data.processObject);

export type KvdDocument = {
  key: string;
  text: string;
  url: string;
};

export type GetMySaleResponse = {
  baseObject: BaseObject;
  businessProposalToken: string;
  documents: KvdDocument[];
  processObject: ProcessObject;
  salesStatuses: SaleStatus[];
  salesAgreement: SalesAgreement;
};

export const getMySale = ({
  idToken,
  processObjectId
}: {
  idToken: string;
  processObjectId: string;
}) =>
  axios
    .get<GetMySaleResponse>(
      `${orchestration.url}/mypages/sales/${processObjectId}`,
      {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${idToken}`
        },
        responseType: 'json'
      }
    )
    .then(({ data }) => data);

export const getMySavedSearches = ({ idToken }: { idToken: string }) =>
  axios
    .get<{ savedSearches: SavedSearch[] }>(
      `${orchestration.url}/mypages/saved-searches`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`
        },
        withCredentials: true,
        responseType: 'json'
      }
    )
    .then(({ data }) => data.savedSearches);

export const getTargetedAuctionsById = ({
  idToken,
  targetedAuctionId
}: {
  idToken: string;
  targetedAuctionId: number;
}) =>
  axios
    .get<{ auctions: Auction[] }>(
      `${orchestration.url}/mypages/targeted-auctions/${targetedAuctionId}`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`
        },
        withCredentials: true,
        responseType: 'json'
      }
    )
    .then(({ data }) => data.auctions);

export const getHasSeenLatestAuctionsStatus = ({
  idToken,
  groupId,
  memberId
}: {
  idToken: string;
  groupId: number;
  memberId: string;
}) =>
  axios
    .get<{
      groupMember: { groupId: number; hasSeenLatestAuctions: boolean };
    }>(
      `${orchestration.url}/mypages/member-groups/${groupId}/members/${memberId}`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`
        },
        withCredentials: true,
        responseType: 'json'
      }
    )
    .then(({ data }) => data.groupMember);

export const setUserHasSeenLatestAuctions = ({
  idToken,
  groupId,
  memberId,
  hasSeen
}: {
  idToken: string;
  groupId: number;
  memberId: string;
  hasSeen: boolean;
}) =>
  axios
    .put<{
      groupMember: { groupId: number; hasSeenLatestAuctions: boolean };
    }>(
      `${orchestration.url}/mypages/member-groups/${groupId}/members/${memberId}`,
      {
        group_member: {
          hasSeenLatestAuctions: hasSeen
        }
      },
      {
        headers: { Authorization: `Bearer ${idToken}` },
        withCredentials: true,
        responseType: 'json'
      }
    )
    .then(({ data }) => data.groupMember);

export const saveSearch = ({
  idToken,
  savedSearch
}: {
  idToken: string;
  savedSearch: SavedSearch;
}) =>
  axios
    .post<{ savedSearch: SavedSearch }>(
      `${orchestration.url}/mypages/saved-searches`,
      {
        savedSearch,
        GaClientId: getGaClientId()
      },
      {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${idToken}`
        },
        responseType: 'json'
      }
    )
    .then(({ data }) => data.savedSearch);

export const saveSearchGuest = ({
  email,
  savedSearch,
  addToNewsletter,
  gRecaptchaResponse
}: {
  email: string;
  savedSearch: SavedSearch;
  addToNewsletter?: boolean;
  gRecaptchaResponse: string;
}) =>
  axios
    .post<{ savedSearch: SavedSearch }>(
      `${orchestration.url}/mypages/guest-saved-searches`,
      {
        savedSearch,
        email,
        gRecaptchaResponse,
        emailSubscriptionNames: (addToNewsletter && ['WEEKLY']) || []
      },
      { responseType: 'json' }
    )
    .then(({ data }) => data.savedSearch);

export const editSavedSearch = ({
  idToken,
  search
}: {
  idToken: string;
  search: SavedSearch;
}) =>
  axios
    .put<{ savedSearch: SavedSearch }>(
      `${orchestration.url}/mypages/saved-searches/${search.id}`,
      {
        savedSearch: search,
        GaClientId: getGaClientId()
      },
      {
        headers: {
          Authorization: `Bearer ${idToken}`
        },
        withCredentials: true,
        responseType: 'json'
      }
    )
    .then(({ data }) => data.savedSearch);

type AcceptHighestBidResponse = {
  auctionId: string;
  evaluationType: string;
  reason: string;
  winningBidId: number;
  winningMemberId: string;
};

export const acceptHighestBid = ({
  idToken,
  auctionId
}: {
  idToken: string;
  auctionId: string;
}) =>
  axios
    .put<{ auction: AcceptHighestBidResponse | AxiosError }>(
      `${orchestration.url}/mypages/auctions/${auctionId}/accept-highest-bid`,
      {},
      {
        headers: {
          Authorization: `Bearer ${idToken}`
        },
        withCredentials: true,
        responseType: 'json'
      }
    )
    .then(({ data }) => data);

export const deleteMySavedSearch = ({
  idToken,
  searchId
}: {
  idToken: string;
  searchId: string;
}) =>
  axios.delete<{ savedSearch: SavedSearch }>(
    `${orchestration.url}/mypages/saved-searches/${searchId}`,
    {
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      data: {
        GaClientId: getGaClientId()
      },
      withCredentials: true,
      responseType: 'json'
    }
  );

export const deleteGuestSavedSearch = ({
  searchId,
  memberId,
  gRecaptchaResponse
}: {
  searchId: string;
  memberId: string;
  gRecaptchaResponse: string;
}) =>
  axios.delete<{ savedSearch: SavedSearch }>(
    `${orchestration.url}/mypages/guest-saved-searches/${searchId}`,
    {
      data: {
        GaClientId: getGaClientId(),
        memberId,
        gRecaptchaResponse
      },
      responseType: 'json'
    }
  );

export const getAccountNotificationSettings = ({
  idToken
}: {
  idToken: string;
}) =>
  axios
    .get<{ notificationSettings: NotificationSettings }>(
      `${orchestration.url}/mypages/notification-settings/me`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`
        },
        withCredentials: true,
        responseType: 'json'
      }
    )
    .then(({ data }) => data.notificationSettings);

export const saveAccountNotificationSettings = ({
  idToken,
  notificationSettings
}: {
  idToken: string;
  notificationSettings: {
    favourites: NotificationType[];
    auctions: NotificationType[];
    savedSearches: NotificationType[];
  };
}) =>
  axios.put<{ notificationSettings: NotificationSettings }>(
    `${orchestration.url}/mypages/notification-settings/me`,
    { notificationSettings: notificationSettings },
    {
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      withCredentials: true,
      responseType: 'json'
    }
  );

export const confirmUserSettings = ({ idToken }: { idToken: string }) =>
  axios.put<unknown>(
    `${orchestration.url}/mypages/member-settings/me`,
    {
      memberSettings: {
        consentAcquired: true
      }
    },
    {
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      responseType: 'json'
    }
  );
