import { CloseIcon, Flex } from '@kvdbil/components';
import { FlexProps } from '@kvdbil/components/types/components/Flex';
import React from 'react';

type Props = {
  onClose(): void;
  flexProps?: FlexProps;
  iconProps?: React.SVGProps<SVGSVGElement>;
};

const AuthCloseButton = ({ onClose, flexProps, ...iconProps }: Props) => {
  return (
    <Flex justify={'right'} align={'center'} {...flexProps}>
      <CloseIcon
        cursor={'pointer'}
        width={'2rem'}
        height={'2rem'}
        onClick={onClose}
        {...iconProps}
      />
    </Flex>
  );
};

export default AuthCloseButton;
