import { createSelector } from 'reselect';
import dayjs from 'dayjs';
import { ReduxStore } from '../interfaces/store';
import { ConnectionState } from '../interfaces/store/ConnectionState';

const root = createSelector<ReduxStore, ConnectionState, ConnectionState>(
  state => state?.connectionState,
  value => value
);

export const isConnectedSelector = createSelector(
  root,
  state => state?.connected
);

export const isConnectingSelector = createSelector(
  root,
  state => state?.connecting
);

export const shouldFetchAfterReconnect = createSelector(
  root,
  state => state?.shouldFetchAfterReconnect
);

export const isConnectionError = createSelector(
  isConnectedSelector,
  isConnectingSelector,
  (isConnected: boolean, isConnecting: boolean) => {
    return !isConnected && !isConnecting;
  }
);

const failedToFetch = createSelector(root, state => state?.failedToFetch);

const lastFetch = createSelector(root, state => state?.lastFetch);

const disconnectedAt = createSelector(root, state => state?.disconnectedAt);

export const secondsSinceLastFetch = createSelector(lastFetch, lastFetch => {
  if (!lastFetch) {
    return -1;
  }

  const fetchDate = dayjs(lastFetch);
  const secondsSinceFetch = dayjs().diff(fetchDate, 'seconds');

  return secondsSinceFetch;
});

export const isDisconnectedSelector = createSelector(
  failedToFetch,
  disconnectedAt,
  isConnectedSelector,
  (failedToFetch, disconnectedAt, isConnected) => {
    const disconnectDate = dayjs(disconnectedAt || '');
    const secondsSinceDisconnect = dayjs().diff(disconnectDate, 'seconds');
    const wsDisconnected = secondsSinceDisconnect >= 1 && !isConnected;

    return wsDisconnected || failedToFetch;
  }
);
