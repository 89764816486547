import { combineReducers } from 'redux';
import { BidEvent } from '../interfaces/events/BidEvent';
import { ReduxStore } from '../interfaces/store';

export type Action = {
  type: 'AUCTION_BID_PLACED';
  payload?: BidEvent;
};

export const initialState = {
  allIds: [],
  byId: {}
};

const byId = (
  state = initialState.byId,
  action: Action
): ReduxStore['websocketBids']['byId'] => {
  switch (action.type) {
    case 'AUCTION_BID_PLACED': {
      if (action.payload) {
        return {
          ...state,
          [action.payload.id]: { ...(action.payload || {}) }
        };
      }
      return state;
    }

    default:
      return state;
  }
};

const allIds = (
  state = initialState.allIds,
  action: Action
): ReduxStore['websocketBids']['allIds'] => {
  switch (action.type) {
    case 'AUCTION_BID_PLACED': {
      if (action.payload) {
        return [action.payload.id, ...state];
      }
      return state;
    }

    default:
      return state;
  }
};

const websocketBids = combineReducers<ReduxStore['websocketBids']>({
  byId,
  allIds
});

export default websocketBids;
