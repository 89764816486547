import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { connect, useDispatch } from 'react-redux';
import throttle from 'lodash.throttle';
import { theme, mq, ContactIcon } from '@kvdbil/components';
import { translateRoute } from '~/App/shared/localization';
import Nudge from './Nudge';
import ContactDetails from './ContactDetails';
import { useTranslation } from '~/Locale';
import { SalesFlowType } from '~/App/shared/types/SalesFlowType';
import { useIdle } from '~/App/shared/hooks/useIdle';
import {
  activeChatFlow,
  contactButtonPositionSelector,
  isContactButtonHidden,
  isContactButtonOpen,
  isDefaultTriggerEnabledSelector,
  messagesBottom
} from '~/App/shared/selectors/global';
import { salesFlow } from '~/App/shared/selectors/sellFlowSelectors';
import { auctionListIsShowingFilters } from '~/App/shared/selectors/auctionListSelector';
import {
  setContactBubble,
  setContactButtonIsHidden,
  setKundoOpenAction
} from '~/App/shared/actions/contactButton';
import { ReduxDispatch } from '~/App/shared/interfaces/Redux';
import { useIsDevice } from '~/App/shared/hooks/useIsDevice';
import { ContactButton as IContactButton } from '~/App/shared/interfaces/store/ContactButton';
import useOnScroll from '~/App/shared/hooks/useOnScroll';
import { SiteMessageRecord } from '~/config/generated/graphql';

declare global {
  interface Window {
    $kundo_chat: {
      start_chat(flow: string): void;
      disable_widget: boolean;
      custom_texts: Record<string, string>;
    };
  }
}

const handlePositionCSS = (position: IContactButton['position']) =>
  Object.entries(position)
    .map(([key, value]) => `${key}: ${value};`)
    .join(' ');

type ContainerProps = {
  marginBottom: number;
  isHidden: boolean;
  isNudge: boolean;
  position?: IContactButton['position'];
};

const Container = styled.div<ContainerProps>`
  position: fixed;
  right: 0.75rem;
  bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom * 4}rem` : '2rem'};
  ${({ position }) =>
    position?.bottom || position?.top || position?.left || position?.right
      ? css`
          ${handlePositionCSS(position)}
        `
      : ''};
  z-index: 500000;

  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;

  width: 10.25rem;
  height: 3.75rem;

  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.light};
  background-color: ${theme.colors.secondary.main};
  box-shadow: ${theme.elevations.elevation16};
  transition: bottom 300ms ease-in-out;
  border-radius: 20rem;

  font-family: ${({ theme }) => theme.typography.fontAlternativeFamily};
  font-weight: ${({ theme }) => theme.typography.fontAlternativeBoldWeight};
  font-size: 1.25rem;
`;

const OpenContainer = styled.div`
  position: absolute;
  bottom: 5rem;
  border-radius: 2rem;
  padding: 1rem 2.25rem 1rem 1.75rem;
  right: 0;
  color: ${({ theme }) => theme.colors.gray.dark3};
  background-color: ${({ theme }) => theme.colors.background.light};
  filter: drop-shadow(0 5px 10px rgba(0, 0, 0, 0.1));

  ${mq('mobileL')} {
    padding: 1rem 2.25rem 1rem 2.25rem;
  }

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    right: 2.75rem;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: 12px solid white;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
  }
`;

interface Props {
  salesFlow?: SalesFlowType;
  isContactButtonHidden: boolean;
  isDefaultTriggerEnabled?: boolean;
  buttonPosition?: IContactButton['position'];
  activeChatFlow: string;
  auctionListIsShowingFilters: boolean;
  messagesBottom?: SiteMessageRecord[];
  location: string;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

export const ContactButton = ({
  activeChatFlow,
  messagesBottom,
  auctionListIsShowingFilters,
  isContactButtonHidden,
  isDefaultTriggerEnabled = true,
  buttonPosition,
  location,
  salesFlow,
  isOpen,
  setIsOpen
}: Props) => {
  const { t } = useTranslation();
  const isIdle = useIdle(1000 * 15);
  const containerRef = useRef<null | HTMLDivElement>(null);
  const [isNudge, setIsNudge] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);
  const [isKundoOpen, setKundoOpen] = useState(false);
  const [isMobileAuctionFilters, setIsMobileAuctionFilters] = useState(false);
  const kundoChat = typeof window !== 'undefined' && window.$kundo_chat;
  const { isMobile } = useIsDevice();
  const dispatch = useDispatch();

  const handleKundoOpen = useCallback(
    (isOpen: boolean) => {
      setKundoOpen(isOpen);
      dispatch(setKundoOpenAction(isOpen));
    },
    [dispatch, setKundoOpen]
  );

  useEffect(() => {
    if (isIdle) {
      const isSellFlow = encodeURIComponent(location).includes(
        translateRoute(t, 'ROUTES.SELL')
      );

      const isLocalStorageSupported = typeof localStorage !== 'undefined';
      const isFirstTime =
        isLocalStorageSupported &&
        localStorage.getItem('hasOpenedContactButton') === null;

      if (isSellFlow && isFirstTime && !isOpen) {
        setIsNudge(true);
        setIsOpen(true);
        isLocalStorageSupported &&
          localStorage.setItem('hasOpenedContactButton', 'true');
      }
    }
  }, [t, isIdle, isOpen, location, setIsOpen]);

  const handleClose = () => {
    setIsOpen(false);
    setIsNudge(false);
  };

  const softClose = () => {
    if (isNudge) {
      setIsNudge(false);
    } else {
      setIsOpen(!isOpen);
    }
  };

  const handleOutsideClick = useCallback(
    (event: MouseEvent) => {
      const contactButton = containerRef.current;

      if (
        isOpen &&
        contactButton &&
        !contactButton.contains(event?.target as Node)
      ) {
        setIsOpen(false);
        setIsNudge(false);
      }
    },
    [isOpen, setIsOpen]
  );

  const handleScroll = useCallback(
    (current: number) => {
      const hasScrolled = current < 50;

      if (hasScrolled !== isAtTop) {
        setIsAtTop(hasScrolled);
      }

      if (isDefaultTriggerEnabled && hasScrolled && isContactButtonHidden) {
        dispatch(setContactButtonIsHidden(false));
      }
    },
    [dispatch, isContactButtonHidden, isAtTop, isDefaultTriggerEnabled]
  );

  const throttledScrollHandler = throttle(handleScroll, 200);

  useOnScroll({ onScroll: throttledScrollHandler });

  useEffect(() => {
    setIsMobileAuctionFilters(auctionListIsShowingFilters && isMobile);
  }, [auctionListIsShowingFilters, isMobile]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('click', handleOutsideClick);
      return () => window.removeEventListener('click', handleOutsideClick);
    }
  }, [handleOutsideClick]);

  useEffect(() => {
    document.addEventListener('kundo-chat:chat-closed', () =>
      handleKundoOpen(false)
    );

    return () => {
      document.removeEventListener('kundo-chat:chat-closed', () =>
        handleKundoOpen(false)
      );
    };
  }, [handleKundoOpen]);

  const openChat = () => {
    if (kundoChat) {
      kundoChat.start_chat(activeChatFlow);
      handleKundoOpen(true);

      typeof localStorage !== 'undefined' &&
        localStorage.setItem('hasOpenedContactButton', 'true');
    }
  };

  const isHidden =
    (isAtTop ||
      isMobileAuctionFilters ||
      isContactButtonHidden ||
      isKundoOpen) &&
    isDefaultTriggerEnabled;

  return (
    <Container
      position={buttonPosition}
      marginBottom={messagesBottom?.length ?? 0}
      onKeyDown={softClose}
      role="button"
      tabIndex={0}
      ref={containerRef}
      isNudge={isNudge}
      isHidden={
        (isDefaultTriggerEnabled && isHidden) ||
        (!isDefaultTriggerEnabled && isContactButtonHidden) ||
        isKundoOpen
      }
      onClick={softClose}
    >
      <ContactIcon />
      {t('Contact')}

      <OpenContainer hidden={!isOpen}>
        <Nudge handleClose={handleClose} isHidden={!isNudge} />

        {isOpen && (
          <ContactDetails
            isHidden={isNudge}
            openChat={openChat}
            salesFlow={salesFlow}
            location={location}
          />
        )}
      </OpenContainer>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  salesFlow,
  activeChatFlow: activeChatFlow,
  messagesBottom: messagesBottom,
  isContactButtonHidden: isContactButtonHidden,
  isDefaultTriggerEnabled: isDefaultTriggerEnabledSelector,
  buttonPosition: contactButtonPositionSelector,
  auctionListIsShowingFilters: auctionListIsShowingFilters,
  isOpen: isContactButtonOpen
});

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  setIsOpen: (open: boolean) => dispatch(setContactBubble(open))
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactButton);
