import { requestErrorHandler } from '~/helpers/notifyError';
import { getWonAuctions } from '~/helpers/orchestration/auctions';
import { ReduxDispatch } from '../interfaces/Redux';
import { WonAuctions } from '../interfaces/store/WonAuctions';

export const GET_MY_WON_AUCTIONS_REQUEST = 'GET_MY_WON_AUCTIONS_REQUEST';
export const GET_MY_WON_AUCTIONS_SUCCESS = 'GET_MY_WON_AUCTIONS_SUCCESS';
export const GET_MY_WON_AUCTIONS_FAILURE = 'GET_MY_WON_AUCTIONS_FAILURE';

const hydrateWonAuctionsRequest = () => ({
  type: 'HYDRATE_WON_AUCTIONS_REQUEST'
});

export const HYDRATE_WON_AUCTIONS_SUCCESS = 'HYDRATE_WON_AUCTIONS_SUCCESS';
const hydrateWonAuctionsSuccess = (wonAuctions: string[]) => ({
  type: HYDRATE_WON_AUCTIONS_SUCCESS,
  payload: wonAuctions
});

export type HydrateWonAuctionsSuccessAction = {
  type: typeof HYDRATE_WON_AUCTIONS_SUCCESS;
  payload: WonAuctions;
};

export type GetWonAuctionsSuccessAction = {
  type: typeof GET_MY_WON_AUCTIONS_SUCCESS;
  payload?: WonAuctions;
};

interface HydrateWonAuctionsArgs {
  idToken: string;
}

export const hydrateWonAuctions =
  ({ idToken }: HydrateWonAuctionsArgs) =>
  (dispatch: ReduxDispatch) => {
    dispatch(hydrateWonAuctionsRequest());

    return getWonAuctions({ idToken: idToken })
      .then(wonAuctions => {
        dispatch(hydrateWonAuctionsSuccess(wonAuctions));
      })
      .catch(requestErrorHandler);
  };
