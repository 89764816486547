import React, { useState, ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { getDurationTo } from '~/helpers/time';
import { timeDiff as timeDiffSelector } from '~/App/shared/selectors/global';
import { useInterval } from '../hooks/useIntervalHook';

interface P {
  closeAt: Date;
}

export interface Props {
  minutes: number;
  seconds: number;
}

const intervalDelay = 1000;

const withTimer =
  (WrappedComponent: ComponentType<Props>): ComponentType<P> =>
  (props: P) => {
    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const timeDiff = useSelector(timeDiffSelector);

    useInterval(() => {
      const duration = getDurationTo(timeDiff, props.closeAt);

      let minutes = duration.minutes();
      let seconds = duration.seconds();

      if (minutes < 0) {
        minutes = 0;
      }

      if (seconds < 0) {
        seconds = 0;
      }

      setSeconds(seconds);
      setMinutes(minutes);
    }, intervalDelay);

    return <WrappedComponent minutes={minutes} seconds={seconds} />;
  };

export default (c: ComponentType<Props>) => withTimer(c);
