import {
  OpeningHour,
  OpeningHourType
} from '~/App/shared/interfaces/OpeningHours';
import axios from '~/helpers/orchestration/axiosWithAuthHandling';
import { orchestration } from '~/config/public';
import dayjs from 'dayjs';
import { AxiosResponse } from 'axios';

// Types and closed
// no post = closed, set type closed
// EXTRA_OFF = closed on regular day (red days, like xmas) same as closed
// DEFAULT = regular open hrs day
// EXTRA = most likely extended opening hours this day, same case as  defualt.

interface AvailableOpeningHours {
  defaultHoursId: string;
  type: OpeningHourType;
  openAt: string;
  closeAt: string;
  capacity?: Record<string, string>;
  resourceTypes?: string[];
}

type Availability = Record<string, AvailableOpeningHours[]>;

interface Availabilities {
  calendarId: string;
  type: string;
  tag: string;
  availability: Availability;
}

interface ResponseData {
  availabilities: Availabilities[];
}

const getOpeningHours = (date: string, daysAmount = 8) => {
  return axios
    .get(`${orchestration.url}/common/customer-service-availabilities`, {
      responseType: 'json',
      params: {
        start_date: date,
        stop_date: dayjs(date).add(daysAmount, 'days').format('YYYY-MM-DD')
      }
    })
    .then(({ data }: AxiosResponse<ResponseData>) => {
      if (data.availabilities.length <= 0) {
        return [];
      }
      const [item] = data.availabilities;
      const availability = item?.availability;
      const newAvailability: OpeningHour[] = [];

      for (let i = 0; i < daysAmount; i += 1) {
        const newDay = dayjs(date).add(i, 'days').format('YYYY-MM-DD');
        const openingHours: OpeningHour = {
          type: 'DEFAULT',
          date: dayjs(newDay)
        };

        const currentAvailability = availability?.[newDay];

        if (currentAvailability) {
          currentAvailability.forEach(day => {
            if (day.closeAt) {
              openingHours.close = dayjs(day.closeAt);
            }

            if (day.openAt) {
              openingHours.open = dayjs(day.openAt);
            }

            if (day.type) {
              openingHours.type = day.type;
            }
          });
        } else {
          openingHours.type = 'CLOSED';
        }

        if (openingHours.type === 'EXTRA_OFF') {
          openingHours.type = 'CLOSED';
        }

        newAvailability.push(openingHours);
      }

      return newAvailability;
    });
};

export { getOpeningHours };
