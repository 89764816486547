import { SetInterestRateAction } from '../actions/finance';
import { ReduxStore } from '../interfaces/store';

const initialState = {
  processingFee: 0,
  invoiceFee: 0
};

const financeReducer = (
  state = initialState,
  action: SetInterestRateAction
): ReduxStore['finance'] => {
  switch (action.type) {
    default:
      return state;
  }
};

export default financeReducer;
