import { FaqPageQuery, FaqQuestionNewRecord } from '~/config/generated/graphql';
import { CmsData } from '../../interfaces/store/CmsData';

export type FaqCategory = CmsData['faqPage']['categories'][number];
export type FaqQuestion = CmsData['faqPage']['questions'][number];

export const handleFaqPageCmsData = ({ faq }: FaqPageQuery) => {
  // Filter questions from categories that are missing question or slug
  const categories =
    faq?.categories?.map(category => ({
      ...category,
      questions: category?.questions?.filter(
        ({ question, slug }) => Boolean(question) && Boolean(slug)
      )
    })) ?? [];
  const questions = categories.flatMap(category => category.questions ?? []);

  const questionObjectBySlug = questions.reduce(
    (acc, question: FaqQuestionNewRecord) => {
      acc[question?.slug ?? ''] = question;
      return acc;
    },
    {} as Record<string, FaqQuestionNewRecord>
  );

  return {
    title: faq?.title ?? '',
    subtitle: faq?.subtitle ?? '',
    categories: categories,
    questionsBySlug: questionObjectBySlug,
    questions: questions
  };
};
