import React, { useState } from 'react';
import { theme } from '@kvdbil/components';
import styled from 'styled-components';
import { useTranslation } from '~/Locale';
import CogIcon from './CogIcon';
import SwitchButton from './SwitchButton';
import { environment } from '~/config/public';

const DebugWrapper = styled.div<{ show: boolean }>`
  color: ${theme.colors.gray.dark4};
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0.5rem;
  background-color: ${theme.colors.gray.light5};
  border-top: 2px solid ${theme.colors.gray.light3};
  width: 100%;
  transform: ${({ show }) => `translateY(${show ? 0 : '100%'})`};
  transition: 0.3s;
  z-index: 3;
`;

const IconWrapper = styled.div`
  border-top: 2px solid ${theme.colors.gray.light3};
  border-left: 2px solid ${theme.colors.gray.light3};
  border-right: 2px solid ${theme.colors.gray.light3};
  width: 2.5rem;
  height: 2.5rem;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  margin-top: -3rem;
  background-color: ${theme.colors.gray.light5};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: ${theme.colors.gray.dark4};
  }
`;

const ContentWrapper = styled.div`
  padding-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Version = styled.span`
  font-size: 0.75rem;
  font-weight: ${theme.typography.fontBaseBoldWeight};
`;

const DebugBar = () => {
  const { setShowKeys, options } = useTranslation();
  const [show, setShow] = useState(false);

  return (
    <DebugWrapper show={show}>
      <IconWrapper onClick={() => setShow(s => !s)}>
        <CogIcon />
      </IconWrapper>
      <ContentWrapper>
        <SwitchButton
          on={options?.showKeys}
          onChange={() => setShowKeys(s => !s)}
        >
          Show keys
        </SwitchButton>
        {environment.version && <Version>{environment.version}</Version>}
      </ContentWrapper>
    </DebugWrapper>
  );
};

export default DebugBar;
