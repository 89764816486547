import styled from 'styled-components';
import {
  AlertIcon,
  ButtonRegularText,
  Flex,
  LocationIcon
} from '@kvdbil/components';
import React, { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from '~/Locale';
import Media from '~/App/shared/components/Media';
import FixedPriceImageHeader from '~/App/shared/components/ProductCard/components/ContainerWithImage/components/FixedPriceImageHeader';
import AuctionImageHeader from '~/App/shared/components/ProductCard/components/ContainerWithImage/components/AuctionImageHeader';
import { ProcessTimeStatuses } from '~/helpers/auction';
import { useSelector } from 'react-redux';
import { savedSearchesNotifications as savedSearchesNotificationsSelector } from '~/App/shared/selectors/notificationsSelector';
import { StatusTagWrapper } from '~/App/shared/components/ProductCard/components/ContainerWithImage/components/StatusTagWrapper';

export const NEW_WITHIN_HOURS = 24;

const Container = styled.div`
  position: relative;
  color: ${({ theme }) => theme.colors.text.dark};
  background-color: ${({ theme }) => theme.colors.background.light};
  box-shadow: ${({ theme }) => theme.elevations.elevation2};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
`;

const ImageContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.gray.light4};
`;

const HeaderContainer = styled.div`
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const NewTag = styled.div`
  top: 0;
  right: 0;
  z-index: 2;
  position: absolute;
  display: flex;
`;

const OpacityWrapper = styled.div<{ imageOpacity: number }>`
  opacity: ${({ imageOpacity }) => imageOpacity};
`;

const ObjectLocation = styled(Flex)`
  width: 100%;
  height: 1.25rem;
  background: rgba(242, 242, 242, 0.66);
  position: absolute;
  bottom: 0;
  z-index: 2;
  text-align: center;

  & span,
  & svg {
    font-size: 0.75rem;
  }
`;

const Content = styled.div`
  text-align: left;
  margin: 0.5rem 1rem 1rem 1rem;
`;

export interface ContainerWithImageProps {
  auctionId: string;
  imageSrc: string;
  imageSrcHover: string;
  imageAlt: string;
  isFixedPriceOnly: boolean;
  children?: ReactNode;
  headerProps: {
    isComingSoon: boolean;
    isReserved: boolean;
    isSold: boolean;
    isSoldByFixedPrice: boolean;
    isPriceReduced: boolean;
    isCounting: boolean;
    preliminaryCloseAt?: string;
    processTimeStatuses: ProcessTimeStatuses;
  };
  actionOpenedAt: string;
  objectLocation?: string;
  isHeavyEquipment?: boolean;
}

const ContainerWithImage = ({
  auctionId,
  children,
  headerProps,
  isFixedPriceOnly,
  imageSrc,
  imageSrcHover,
  imageAlt,
  actionOpenedAt,
  objectLocation,
  isHeavyEquipment
}: ContainerWithImageProps) => {
  const { t } = useTranslation();
  const [isHovering, setIsHovering] = useState(false);
  const savedSearchesNotifications = useSelector(
    savedSearchesNotificationsSelector
  );

  const currentImageSrc =
    isHovering && imageSrcHover ? imageSrcHover : imageSrc;

  const {
    isComingSoon,
    isReserved,
    isSold,
    isSoldByFixedPrice,
    isPriceReduced,
    isCounting,
    preliminaryCloseAt,
    processTimeStatuses
  } = headerProps;

  const isNew = useMemo(() => {
    // Calculate if auction is opened within NEW_WITHIN_HOURS hours
    const openedWithin48Hours = Boolean(
      actionOpenedAt &&
        new Date(actionOpenedAt).getTime() + NEW_WITHIN_HOURS * 60 * 60 * 1000 >
          new Date().getTime()
    );

    const foundNotification = Boolean(
      savedSearchesNotifications.find(notification =>
        notification.newAuctions.includes(auctionId)
      )
    );

    return openedWithin48Hours || foundNotification;
  }, [actionOpenedAt, auctionId, savedSearchesNotifications]);

  const displayFixedPriceHeader = isFixedPriceOnly || isSoldByFixedPrice;

  return (
    <Container
      data-testid="vehicle-card"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <ImageContainer>
        <HeaderContainer>
          {displayFixedPriceHeader && (
            <FixedPriceImageHeader
              isComingSoon={isComingSoon}
              isReserved={isReserved}
              isSold={isSold}
              isPriceReduced={isPriceReduced}
            />
          )}
          {!displayFixedPriceHeader && (
            <AuctionImageHeader
              isCounting={isCounting}
              preliminaryCloseAt={preliminaryCloseAt}
              processTimeStatuses={processTimeStatuses}
              isComingSoon={isComingSoon}
            />
          )}
        </HeaderContainer>
        {isNew && (
          <NewTag>
            <StatusTagWrapper>
              <AlertIcon />
              {t('New!')}
            </StatusTagWrapper>
          </NewTag>
        )}
        <OpacityWrapper
          imageOpacity={isFixedPriceOnly && (isSold || isReserved) ? 0.2 : 1}
        >
          <div>
            <Media
              quality={70}
              imageType="THUMBNAIL"
              src={currentImageSrc}
              alt={imageAlt}
              width={238}
              height={160}
            />
          </div>
        </OpacityWrapper>

        {isHeavyEquipment && objectLocation && (
          <ObjectLocation justify={'center'} align={'center'} gap={'0.25rem'}>
            <LocationIcon />
            <ButtonRegularText>{objectLocation}</ButtonRegularText>
          </ObjectLocation>
        )}
      </ImageContainer>
      <Content>{children}</Content>
    </Container>
  );
};

export default ContainerWithImage;
