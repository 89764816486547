import { useQuery, UseQueryArgs } from 'urql';
import { useSelector, useDispatch } from 'react-redux';
import { cmsDataByStoreKeySelector } from '~/App/shared/selectors/cmsData';
import { CmsDataStoreKey } from '~/App/shared/interfaces/store/CmsData';
import { useCallback, useEffect } from 'react';
import { setCmsData } from '~/App/shared/actions/cmsDataActions';
import { locale } from '~/config/public/environment';
import { isOnServer } from '~/helpers/isOnServer';
import { localizationSelector } from '~/App/shared/selectors/localization';

type Props = {
  storeKey: CmsDataStoreKey;
  storeNestedKey?: string;
  query: UseQueryArgs['query'];
  options?: Omit<UseQueryArgs, 'query'> & { useLocaleVar?: boolean };
};

export const useCmsData = <RespData, StoreData>({
  storeKey,
  storeNestedKey,
  query,
  options = {}
}: Props) => {
  const dispatch = useDispatch();
  const cmsData = useSelector(
    cmsDataByStoreKeySelector<StoreData>(storeKey, storeNestedKey)
  );
  const { currentLocale } = useSelector(localizationSelector);
  const { variables, useLocaleVar = true, pause, ...restOptions } = options;
  const [{ data: respData, fetching: dataFetching, error }] =
    useQuery<RespData>({
      query,
      ...restOptions,
      pause: pause || Boolean(cmsData),
      variables: useLocaleVar
        ? {
            ...variables,
            locale: currentLocale ? currentLocale : locale
          }
        : variables
    });
  const fetching = dataFetching || Boolean(respData && !cmsData);

  const dispatchSetCmsData = useCallback(() => {
    if (respData && !cmsData) {
      dispatch(setCmsData(storeKey, respData, storeNestedKey));
    }
  }, [dispatch, storeKey, respData, cmsData, storeNestedKey]);

  useEffect(() => {
    !isOnServer && dispatchSetCmsData();
  }, [dispatchSetCmsData]);

  // on SSR dispatchSetCmsData must be triggered manually - no useEffect triggering
  isOnServer && dispatchSetCmsData();

  return { data: cmsData, fetching, error };
};
