export const formatPhoneNumber = (phoneNumber: string) => {
  const numericOnly = phoneNumber.replace(/\D/g, '');

  if (numericOnly.startsWith('0') && !numericOnly.startsWith('00')) {
    // Format as "012-345 67 89"
    return numericOnly.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1-$2 $3 $4');
  } else if (numericOnly.startsWith('46')) {
    // Format as "+4612-345 67 89"
    return numericOnly.replace(
      /(\d{2})(\d{2})(\d{3})(\d{2})(\d{2})/,
      '+$1$2-$3 $4 $5'
    );
  }

  return phoneNumber;
};

export const formatPhoneNumberForLink = (phoneNumber: string) => {
  if (phoneNumber.startsWith('+')) {
    return phoneNumber;
  }

  let formattedNumber = phoneNumber.replace(/\D/g, '');

  if (formattedNumber.startsWith('0') && !formattedNumber.startsWith('00')) {
    formattedNumber = formattedNumber.slice(1);
    return `+46${formattedNumber}`;
  } else if (formattedNumber.startsWith('46')) {
    return `+${formattedNumber}`;
  }

  return formattedNumber;
};
