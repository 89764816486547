import styled from 'styled-components';
import { mq } from '@kvdbil/components';
import React from 'react';

const Container = styled.p`
  font-family: ${({ theme }) => theme.typography.fontBaseFamily};
  font-weight: ${({ theme }) => theme.typography.fontBaseRegularWeight};
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.text.dark};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.25rem;

  ${mq('tablet')} {
    font-size: 0.75rem;
    margin-bottom: 0;
  }
`;

export interface SubtitleProps {
  children: string;
}

const Subtitle = ({ children }: SubtitleProps) => {
  return <Container>{children}</Container>;
};

export default Subtitle;
