import { useTranslation } from '~/Locale';
import { useSelector } from 'react-redux';
import { session as sessionSelector } from '~/App/shared/selectors/sessionSelector';
import {
  auctionNotifications as auctionNotificationsSelector,
  favouriteNotifications as favouriteNotificationsSelector
} from '~/App/shared/selectors/notificationsSelector';
import { wonAuctions as wonAuctionsSelector } from '~/App/shared/selectors/wonAuctions';
import { processAuctionStates } from '~/helpers/auction';
import { getAuctionStatusTexts } from '~/App/shared/components/ProductCard/components/Status';
import { ErrorFilledIcon, theme } from '@kvdbil/components';
import React from 'react';
import { ProcessedAuction } from '~/App/shared/interfaces/ProcessedAuction';

const useAuctionStatuses = (
  processedAuction: ProcessedAuction,
  options: { processingPause: boolean }
) => {
  const { t } = useTranslation();
  const session = useSelector(sessionSelector);
  const auctionNotifications = useSelector(auctionNotificationsSelector);
  const favouriteNotifications = useSelector(favouriteNotificationsSelector);
  const wonAuctions = useSelector(wonAuctionsSelector);
  const { processingPause } = options;

  if (processingPause) {
    return [];
  }

  const auctionStates = processAuctionStates({
    auction: processedAuction,
    session,
    wonAuctions: wonAuctions
  });
  const auctionStatusItems = getAuctionStatusTexts(
    auctionStates,
    Boolean(processedAuction?.activeAuction?.reservationPriceReached),
    t
  );

  /* Notifications - Check for "NEW BID" and "NEW!" */
  const hasAuctionNotification = auctionNotifications?.find(
    notification => notification.auctionId === processedAuction.id
  );
  const hasFavouriteNotification = favouriteNotifications?.find(
    notification => notification.auctionId === processedAuction.id
  );

  if (hasFavouriteNotification || hasAuctionNotification) {
    auctionStatusItems.push({
      label: t('New bid'),
      icon: <ErrorFilledIcon />,
      color: theme.colors.text.dark
    });
  }

  return auctionStatusItems;
};

export default useAuctionStatuses;
