interface Params {
  [key: string]: string;
}

export const extractParamsFromQueryString = (query: string): Params => {
  const returnObject: Params = {};
  const params = query.split('?')[1].split('&');
  params.forEach(param => {
    const [key, value] = param.split('=');
    returnObject[key] = value;
  });
  return returnObject;
};
