import React from 'react';
import { Path } from 'react-hook-form';
import { regexOrganisationNumber } from '~/helpers/regex';
import TextFieldWithControl from '~/App/shared/components/Fields/TextFieldWithControl';
import { TextFieldProps } from '../types';

const OrganisationNumberField = <FormState,>({
  control,
  t,
  name,
  rules,
  country = 'se',
  ...props
}: TextFieldProps<FormState> & { country?: string }) => {
  const validationRules = {
    required: {
      value: true,
      message: t(`Organisation number can't be blank`)
    },
    pattern:
      country === 'se'
        ? {
            value: regexOrganisationNumber,
            message: t('Organisation number is invalid')
          }
        : undefined,
    ...rules
  };

  const fieldName = name ?? ('organisationNumber' as Path<FormState>);

  return (
    <TextFieldWithControl
      {...props}
      control={control}
      name={fieldName}
      label={t('Organisation number')}
      rules={validationRules}
    />
  );
};

export default OrganisationNumberField;
