import { AbTestAction } from '../actions/abtest';
import { ReduxStore } from '../interfaces/store';

const abTestReducer = (
  state = [],
  action: AbTestAction
): ReduxStore['abTests'] => {
  switch (action.type) {
    case 'ADD_AB_TEST':
      if (!action.payload) {
        return state;
      }

      return [...state, action.payload];
    default:
      return state;
  }
};

export default abTestReducer;
