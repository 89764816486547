import { useEffect, useRef } from 'react';

interface Props {
  onScroll: (current: number | undefined, previous: number | undefined) => void;
}

export default function useOnScroll({ onScroll }: Props) {
  const store = useRef<number | undefined>();
  const target = typeof window !== 'undefined' ? window : null;

  useEffect(() => {
    const handleScroll = () => {
      const previous = store.current;

      if (target) {
        store.current = target.scrollY;
      }

      onScroll(store.current, previous);
    };

    target?.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      target?.removeEventListener('scroll', handleScroll);
    };
  }, [onScroll, target]);
}
