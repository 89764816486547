import React, { ReactNode, ComponentPropsWithoutRef } from 'react';
import styled, { css } from 'styled-components';
import { mq } from '@kvdbil/components';
import { Theme } from '@kvdbil/components/types/theme';

export type ContentBackgroundColor = keyof Theme['colors']['background'];
export type BackgroundColor = ContentBackgroundColor | 'darkGray';
type Divider = 'top' | 'bottom' | 'topAndBottom';
type Layout = keyof typeof layouts;

type SpacingProps = {
  spacingTopBottom?: number;
  spacingTop?: number;
  spacingBottom?: number;
  footer?: boolean;
};

type ContainerStylingProps = SpacingProps & {
  divider?: Divider;
  backgroundColor?: BackgroundColor;
  layout?: Layout;
  padding: boolean;
};

type ContentStylingProps = SpacingProps & {
  divider?: Divider;
  backgroundColor?: BackgroundColor;
  maxWidth: number | 'full';
  padding?: string;
};

const layouts = {
  default: css``,
  centered: css`
    display: flex;
    text-align: center;
    justify-content: center;
  `
};

const getDivider = (theme: Theme, type: Divider) =>
  `
    &:${type === 'top' ? 'before' : 'after'} {
      position: absolute;
      content: '';
      ${type}: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: ${theme.colors.background.gray};
    }
  `;

const divider = css<{ divider?: Divider }>`
  ${({ theme, divider }) =>
    divider &&
    `
      position: relative;
      ${
        divider === 'topAndBottom'
          ? getDivider(theme, 'top') + getDivider(theme, 'bottom')
          : getDivider(theme, divider)
      }
      `}
`;

const spacing = css<SpacingProps>`
  ${({ spacingTopBottom }) =>
    spacingTopBottom &&
    `
    padding-top: ${Math.round(spacingTopBottom) / 2}rem;
    padding-bottom: ${Math.round(spacingTopBottom) / 2}rem;

    ${mq('tablet')} {
      padding-top: ${spacingTopBottom}rem;
      padding-bottom: ${spacingTopBottom}rem;
    }
    `}

  ${({ spacingTop }) =>
    typeof spacingTop !== 'undefined' &&
    `
    padding-top: ${Math.round(spacingTop) / 2}rem;

    ${mq('tablet')} {
      padding-top: ${spacingTop}rem;
    }
    `}

  ${({ spacingBottom }) =>
    typeof spacingBottom !== 'undefined' &&
    `
    padding-bottom: ${Math.round(spacingBottom) / 2}rem;

    ${mq('tablet')} {
      padding-bottom: ${spacingBottom}rem;
    }
    `}

  ${({ footer }) =>
    footer &&
    `
    ${mq('mobileS', 'tablet')} {
      padding-bottom: 4rem;
    }
    `}
`;

const Container = styled.div<ContainerStylingProps>`
  padding-left: 1rem;
  padding-right: 1rem;
  ${spacing};

  ${({ theme, backgroundColor }) =>
    backgroundColor &&
    `
    background: ${
      backgroundColor === 'darkGray'
        ? theme.colors.gray.light5
        : theme.colors.background[backgroundColor]
    };`}

  ${({ padding }) =>
    padding
      ? `
    ${mq('tablet')} {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  `
      : `${mq(null, 'laptop')} {
      padding-left: 0;
      padding-right: 0;
    }


  `}

  ${divider};
  ${({ layout }) => layouts[layout ?? 'default']};
`;
const Content = styled.div<ContentStylingProps>`
  margin-left: auto;
  margin-right: auto;
  ${({ maxWidth }) =>
    typeof maxWidth === 'number' &&
    `
    max-width: ${maxWidth}rem;`}
  ${({ theme, backgroundColor }) =>
    backgroundColor &&
    `
    background-color: ${
      backgroundColor === 'darkGray'
        ? theme.colors.gray.light5
        : theme.colors.background[backgroundColor]
    };`}

  ${spacing};
  ${divider};

  ${({ padding }) =>
    padding &&
    `
    padding: ${padding};`}
`;

export interface Props extends ComponentPropsWithoutRef<'div'> {
  children: ReactNode;
  backgroundColor?: BackgroundColor;
  contentBackgroundColor?: ContentBackgroundColor;
  contentPadding?: string;
  spacing?: number;
  spacingTop?: number;
  spacingBottom?: number;
  maxWidth?: number | 'full';
  divider?: Divider;
  shortDivider?: boolean;
  layout?: Layout;
  footer?: boolean;
  padding?: boolean;
}

const Section = ({
  children,
  backgroundColor,
  contentBackgroundColor,
  contentPadding,
  spacing = 2,
  spacingTop,
  spacingBottom,
  maxWidth = 64,
  divider,
  shortDivider = false,
  padding = true,
  ...rest
}: Props) => {
  const spacingAndDividerProps = {
    divider,
    spacingTop,
    spacingBottom,
    spacingTopBottom: spacing
  };

  return (
    <Container
      padding={padding}
      {...rest}
      backgroundColor={backgroundColor}
      {...(!shortDivider ? spacingAndDividerProps : {})}
    >
      <Content
        backgroundColor={contentBackgroundColor}
        maxWidth={maxWidth}
        {...(shortDivider ? spacingAndDividerProps : {})}
        padding={contentPadding}
      >
        {children}
      </Content>
    </Container>
  );
};

export default Section;
