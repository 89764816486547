import { AxiosPromise } from 'axios';
import { InterstFormInput } from '~/App/views/DynamicPage/components/forms/InterestReportForm';
import { orchestration } from '~/config/public';
import axios from '~/helpers/orchestration/axiosWithAuthHandling';

const submitInterestReport = ({
  fullName,
  email,
  phoneNumber,
  nationalIdentificationNumber
}: InterstFormInput): AxiosPromise<{ message: string }> => {
  return axios.post(
    `${orchestration.url}/common/car-loan`,
    {
      carLoan: {
        fullName: fullName,
        email: email,
        phoneNumber: phoneNumber,
        nationalIdentificationNumber: nationalIdentificationNumber
      }
    },
    {
      responseType: 'json'
    }
  );
};

export { submitInterestReport };
