import { googleRecaptchaSiteKey } from '~/config/public/environment';
import { requestErrorHandler } from './notifyError';

export const runWithRecaptcha = (func: (googleResponse: string) => void) => {
  if (
    typeof window !== 'undefined' &&
    typeof window.grecaptcha !== 'undefined'
  ) {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(googleRecaptchaSiteKey, {
          action: 'submit'
        })
        .then(googleResponse => {
          func(googleResponse);
        })
        .catch(requestErrorHandler);
    });
  }
};

export const getRecaptcha = async () => {
  if (
    typeof window === 'undefined' ||
    typeof window.grecaptcha === 'undefined'
  ) {
    return;
  }

  return new Promise<string>(resolve => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(googleRecaptchaSiteKey, {
          action: 'submit'
        })
        .then(resolve)
        .catch(requestErrorHandler);
    });
  });
};
