import {
  BodyText,
  Flex,
  MailIcon,
  PhoneIcon,
  Title,
  mq,
  theme
} from '@kvdbil/components';
import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import styled from 'styled-components';
import { ContactSectionRecord } from '~/config/generated/graphql';

const Container = styled.div`
  background-color: ${theme.colors.background.light};
  padding: 2rem 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${mq(null, 'tablet')} {
    flex-direction: column;
  }
`;

const TextContainer = styled.div`
  text-align: center;
`;

const Buttons = styled(Flex)`
  width: 100%;
  max-width: 343px;

  margin: 0 2rem;
  ${mq(null, 'tablet')} {
    margin: 2rem auto;
  }
`;

/* TODO: [KVDBIL-2201] We need a better way of to inherit styles from the button component
 * This is a copy of the button component with some small changes
 */
const StyledButtonA = styled.a`
  font-family: ${({ theme }) => theme.typography.fontAlternativeFamily};
  font-weight: ${({ theme }) => theme.typography.fontAlternativeBoldWeight};
  font-size: 1.25rem;
  line-height: 1.25rem;

  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  user-select: none;
  position: relative;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;

  padding: 0.25rem 1.5rem;
  min-height: 3.75rem;

  background-color: transparent;
  transition: color 0.3s ease, background-color 0.3s ease, border 0.3s ease;

  color: ${theme.colors['info'].main};
  border: 2px solid ${theme.colors['info'].main};
  background-color: transparent;

  :hover {
    border: 2px solid ${theme.colors['info'].dark};
    color: ${theme.colors['info'].dark};
  }

  :active {
    border: 2px solid ${theme.colors['info'].light};
    color: ${theme.colors['info'].dark};
  }

  &:focus-visible {
    box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.colors.gray.light5};
  }

  justify-content: center;
  gap: 1rem;
`;

const StyledButtonText = styled.span`
  flex-shrink: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IconSwitch = ({ icon }: { icon: Maybe<string> | undefined }) => {
  switch (icon) {
    case 'PhoneIcon':
      return <PhoneIcon />;
    case 'MailIcon':
    default:
      return <MailIcon />;
  }
};

function Contact({
  title,
  subtitle,
  days,
  extraInfo,
  buttons
}: ContactSectionRecord) {
  return (
    <Container>
      <TextContainer>
        <Title>{title}</Title>
        <BodyText>{subtitle}</BodyText>
      </TextContainer>
      <Buttons direction={'column'}>
        {buttons.map(button => {
          return (
            <StyledButtonA key={button.text} href={button.href ?? ''}>
              <IconSwitch icon={button.icon} />
              <StyledButtonText>{button.text}</StyledButtonText>
            </StyledButtonA>
          );
        })}
      </Buttons>

      <Flex direction={'column'} gap={0}>
        <BodyText>{days}</BodyText>
        {extraInfo && <BodyText>{extraInfo}</BodyText>}
      </Flex>
    </Container>
  );
}

export default Contact;
