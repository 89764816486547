import { useMemo } from 'react';
import { useSearchParams } from '~/App/shared/hooks/useSearchParams';
import { FilterSearchParamKey } from '~/App/shared/types/FilterSearchParam';
import {
  isIntervalSearchParamKey,
  isMultiselectSearchParamKey,
  isOtherSearchParams,
  isTermsSearchParamKey,
  isToggleSearchParamKey
} from '~/helpers/filterSearchParams';

export const useFilterSearchParams = (
  drop: FilterSearchParamKey[] = [],
  searchQuery?: string
) => {
  const [searchParams] = useSearchParams();

  const filterSearchParams = useMemo(() => {
    const filterSearchParams = new URLSearchParams();

    const urlParams = searchQuery
      ? new URLSearchParams(searchQuery)
      : searchParams;

    // drop params that should not be included in the filter search params
    drop.forEach(key => {
      if (urlParams.has(key)) urlParams.delete(key);
    });

    urlParams.forEach((value: string, key: FilterSearchParamKey) => {
      if (isIntervalSearchParamKey(key)) {
        filterSearchParams.set(key, value);
      } else if (isMultiselectSearchParamKey(key)) {
        filterSearchParams.set(key, value);
      } else if (isOtherSearchParams(key)) {
        filterSearchParams.set(key, value);
      } else if (isToggleSearchParamKey(key)) {
        filterSearchParams.set(key, value);
      } else if (isTermsSearchParamKey(key)) {
        filterSearchParams.set(key, value);
      }
    });

    return filterSearchParams;
  }, [drop, searchParams, searchQuery]);

  return {
    filterSearchParams,
    filterSearchParamsSize:
      filterSearchParams?.size || [...new Set(filterSearchParams.keys())].length // size doesn't work on jest
  };
};
