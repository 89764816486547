import {
  getFavouriteAuctions,
  storeFavouriteAuction as storeFavouriteAuctionCall,
  removeFavouriteAuction as removeFavouriteAuctionCall,
  FavouriteResponse
} from '~/helpers/orchestration/auctions';
import {
  idToken,
  isAuthenticated,
  isHydratedSelector
} from '~/App/shared/selectors/sessionSelector';
import { loginFlowStart } from './loginFlowActions';
import { requestErrorHandler } from '~/helpers/notifyError';
import Auction from '../interfaces/Auction';
import { ReduxStore } from '../interfaces/store';
import { favouriteAuctions } from '../selectors/global';
import { ReduxDispatch } from '../interfaces/Redux';
import { ActiveAuction } from '../interfaces/ActiveAuction';
import { TranslateFunction } from '~/Locale';
import { auctionById } from '~/App/shared/selectors/auctions';
import {
  getGAUserFromState,
  objectPropertiesFromAuction,
  trackGA4Event
} from '~/helpers/client/ga4TrackEvent';

export const storeFavouriteAuctionRequest = () => ({
  type: 'STORE_FAVOURITE_AUCTION_REQUEST' as const
});

export const STORE_FAVOURITE_AUCTION_SUCCESS =
  'STORE_FAVOURITE_AUCTION_SUCCESS';
export const storeFavouriteAuctionSuccess = (payload: FavouriteResponse) => ({
  type: 'STORE_FAVOURITE_AUCTION_SUCCESS' as const,
  payload
});

export type StoreFavouriteAuctionAction =
  | ReturnType<typeof storeFavouriteAuctionRequest>
  | ReturnType<typeof storeFavouriteAuctionSuccess>;

export const storeFavouriteAuction =
  (auctionId: string) =>
  (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();
    const token = idToken(state);

    dispatch(storeFavouriteAuctionRequest());

    storeFavouriteAuctionCall({
      id: auctionId,
      idToken: token
    })
      .then(auction => {
        dispatch(storeFavouriteAuctionSuccess(auction));
      })
      .catch(requestErrorHandler);
  };

export const REMOVE_FAVOURITE_AUCTION_REQUEST =
  'REMOVE_FAVOURITE_AUCTION_REQUEST';
export const REMOVE_FAVOURITE_AUCTION_SUCCESS =
  'REMOVE_FAVOURITE_AUCTION_SUCCESS';

export const removeFavouriteAuctionRequest = () => ({
  type: 'REMOVE_FAVOURITE_AUCTION_REQUEST' as const
});
export const removeFavouriteAuctionSuccess = (auctionId: string) => ({
  type: 'REMOVE_FAVOURITE_AUCTION_SUCCESS' as const,
  payload: auctionId
});

export type RemoveFavouriteAuctionAction =
  | ReturnType<typeof storeFavouriteAuctionRequest>
  | ReturnType<typeof removeFavouriteAuctionSuccess>;

export const removeFavouriteAuction =
  (auctionId: string) =>
  (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();
    const token = idToken(state);
    dispatch(removeFavouriteAuctionRequest);

    removeFavouriteAuctionCall({
      id: auctionId,
      idToken: token
    })
      .then(({ auctionId }) => {
        dispatch(removeFavouriteAuctionSuccess(auctionId));
      })
      .catch(requestErrorHandler);
  };

const HYDRATE_FAVOURITE_AUCTIONS_REQUEST = 'HYDRATE_FAVOURITE_AUCTIONS_REQUEST';
const HYDRATE_FAVOURITE_AUCTIONS_SUCCESS = 'HYDRATE_FAVOURITE_AUCTIONS_SUCCESS';
type FavoriteAuction = Auction & ActiveAuction;
interface HydrateFavouriteAuctionsSuccessArgs {
  favouriteAuctions: FavoriteAuction[];
}
const hydrateFavouriteAuctionsSuccess = ({
  favouriteAuctions
}: HydrateFavouriteAuctionsSuccessArgs) => ({
  type: HYDRATE_FAVOURITE_AUCTIONS_SUCCESS,
  favouriteAuctions: favouriteAuctions
});

export const hydrateFavouriteAuctions =
  (idToken: string) => async (dispatch: ReduxDispatch) => {
    dispatch({ type: HYDRATE_FAVOURITE_AUCTIONS_REQUEST });

    try {
      const favouriteAuctions = await getFavouriteAuctions({
        idToken: idToken
      });

      dispatch(
        hydrateFavouriteAuctionsSuccess({
          favouriteAuctions: favouriteAuctions as FavoriteAuction[]
        })
      );
    } catch (err: unknown) {
      return requestErrorHandler(err);
    }
  };

export const toggleFavourite =
  (
    t: TranslateFunction,
    auctionId: string,
    navigateCallback: CallableFunction
  ) =>
  (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();

    const auctions = favouriteAuctions(state);
    const storeAuthenticated = isAuthenticated(state);

    if (!storeAuthenticated) {
      const { pathname, search } = window.location;
      const origin = search ? `${pathname}${search}` : pathname;

      dispatch(
        loginFlowStart(t, {
          origin,
          callback: () => {
            dispatch(toggleFavourite(t, auctionId, navigateCallback));
          },
          navigateCallback
        })
      );
      return;
    }

    if (!auctions.includes(auctionId)) {
      dispatch(storeFavouriteAuction(auctionId));

      const auction = auctionById(auctionId)(state);
      const ga4User = getGAUserFromState(state);
      const isHydrated = isHydratedSelector(state);
      const ga4Object = auction && objectPropertiesFromAuction(auction);

      isHydrated &&
        trackGA4Event('save_favourite', { object: ga4Object }, ga4User);
    } else {
      dispatch(removeFavouriteAuction(auctionId));
    }
  };
