import { mq } from '@kvdbil/components';
import styled from 'styled-components';

const Content = styled.div`
  margin-top: -1px;
  background: ${({ theme }) => theme.colors.background.light};
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  ${mq('tablet')} {
    margin-top: 0;
  }
`;

export default Content;
