import { setServerTimeOffset } from '~/App/shared/actions/time';
import {
  WebsocketAction,
  WebsocketEventAction,
  websocketSendMessage
} from '~/App/shared/actions/connection-events';
import { ReduxStore } from '~/App/shared/interfaces/store';
import { Store } from 'redux';
import { ActionWithPayload } from '~/App/shared/types/Action';

const FIRST_PROBE_INTERVAL = 3000;
const SHORT_PROBE_INTERVAL = 5000;
const LONG_PROBE_INTERVAL = 60000;

type ActionWithTimePayload = ActionWithPayload<
  {
    clientTimeStart?: number;
    serverTime: number;
  },
  WebsocketEventAction['type'] | WebsocketAction['type']
>;

const timeSyncMiddleware = (store: Store<ReduxStore>) => {
  let timer: null | ReturnType<typeof setInterval>;
  let currentInterval: null | number = null;
  let probes = 0;

  const probe = () => {
    // Send a time sync message to server
    const payload = {
      clientTimeStart: Date.now()
    };
    store.dispatch(websocketSendMessage('time_request', payload));
    probes++;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    scheduleProber();
  };

  const scheduleProber = () => {
    let interval: null | number = null;
    if (probes === 0) {
      // Add some uncertainty to the first probe to avoid server cold start problems with many clients
      interval = FIRST_PROBE_INTERVAL * Math.random();
    } else if (probes < 16) {
      interval = SHORT_PROBE_INTERVAL;
    } else {
      interval = LONG_PROBE_INTERVAL;
    }

    if (interval === currentInterval) {
      return;
    }

    // New interval, reschedule timer
    if (timer) {
      clearInterval(timer);
    }

    currentInterval = interval;
    timer = setInterval(probe, interval);
  };

  return (next: (action: ActionWithTimePayload) => void) =>
    (action: ActionWithTimePayload) => {
      switch (action.type) {
        case 'WEBSOCKET_CONNECTED':
          if (!timer) {
            scheduleProber();
          }
          break;

        case 'WEBSOCKET_DISCONNECTED':
          if (timer) {
            clearInterval(timer);
            timer = null;
            probes = 0;
          }
          break;

        case 'TIME_SYNC_RESPONSE':
          const payload = action.payload;
          // Validate message
          if (typeof payload !== 'object') {
            break;
          }

          if (!payload.clientTimeStart || !payload.serverTime) {
            break;
          }

          // Adjust for network round-trip time
          const rtt = Date.now() - (payload?.clientTimeStart ?? 0);
          const diff = Date.now() - payload.serverTime - rtt / 2;
          store.dispatch(setServerTimeOffset(diff));
          break;
      }

      return next(action ?? { type: '' });
    };
};

export { timeSyncMiddleware };
