import { combineReducers } from 'redux';
import {
  T,
  F,
  always,
  evolve,
  pathOr,
  compose,
  ifElse,
  prop,
  propEq,
  path
} from 'ramda';
import {
  GET_TRANSFER_DELIVERY_REQUEST,
  GET_TRANSFER_DELIVERY_SUCCESS,
  GET_TRANSFER_DELIVERY_FAILURE,
  GET_TRANSFER_TIMESLOTS_REQUEST,
  GET_TRANSFER_TIMESLOTS_FAILURE,
  GET_TRANSFER_TIMESLOTS_SUCCESS,
  BOOK_TRANSFER_TIMESLOT_REQUEST,
  BOOK_TRANSFER_TIMESLOT_FAILURE,
  BOOK_TRANSFER_TIMESLOT_SUCCESS,
  TRANSFER_SET_CHANGE_BUYER,
  TRANSFER_SET_CHANGE_PICKUP,
  TRANSFER_CLEAR_BUYER_ERROR,
  TRANSFER_CLEAR_PICKUP_ERROR,
  SET_TRANSFER_NEW_BUYER_REQUEST,
  SET_TRANSFER_NEW_BUYER_SUCCESS,
  SET_TRANSFER_NEW_BUYER_FAILURE,
  SET_TRANSFER_NEW_PICKUP_REQUEST,
  SET_TRANSFER_NEW_PICKUP_SUCCESS,
  SET_TRANSFER_NEW_PICKUP_FAILURE,
  TRANSFER_SIGNING_UPDATE_STATUS_SUCCESS
} from '../actions/transferActions';
import {
  DEFAULT_ERROR_MESSAGE,
  TRANSFER_SIGNING_STATUS
} from '~/config/constants';
import { Facility } from '../interfaces/Facility';
import { ReduxStore } from '../interfaces/store';
import { TimeSlot } from '../types/TimeSlot';
import { TimePickerSlot } from '../types/TimePickerSlot';
import { Contact } from '../interfaces/store/DeliveryContact';
import { TransferPickupContact } from '../interfaces/store/Transfer';

const intialDataState: ReduxStore['transfer']['data'] = {
  delivery: {
    processObject: undefined,
    buyerIndividual: undefined,
    buyerOrganisation: undefined
  },
  shouldChangeBuyer: false,
  shouldChangePickup: false,
  errorMessage: '',
  deliveryErrorMessage: '',
  isTokenInvalid: false,
  isDeliveryLoading: false
};

type PendingContactPayload = {
  pickupIndividual: Contact;
  pickupOrganisation: Contact;
};

type GetTransferDeliverySuccess = {
  type: typeof GET_TRANSFER_DELIVERY_SUCCESS;
  payload?: {
    delivery: PendingContactPayload;
  };
};

type TransferDataAction = {
  type:
    | typeof GET_TRANSFER_DELIVERY_REQUEST
    | typeof GET_TRANSFER_DELIVERY_FAILURE;
  payload?: Record<string, Record<string, unknown>>[];
};

type TransferDataSetAction = {
  type: typeof TRANSFER_SET_CHANGE_PICKUP | typeof TRANSFER_SET_CHANGE_BUYER;
  payload: boolean;
};

const dataReducer = (
  state = intialDataState,
  action:
    | TransferDataAction
    | GetTransferDeliverySuccess
    | TransferDataSetAction
): ReduxStore['transfer']['data'] => {
  switch (action.type) {
    case 'GET_TRANSFER_DELIVERY_REQUEST':
      return evolve(
        {
          isDeliveryLoading: T,
          isTokenInvalid: F
        },
        state
      );
    case 'GET_TRANSFER_DELIVERY_SUCCESS':
      return evolve(
        {
          isDeliveryLoading: F,
          delivery: always(action.payload?.delivery ?? {})
        },
        state
      );
    case 'GET_TRANSFER_DELIVERY_FAILURE':
      return evolve(
        {
          isDeliveryLoading: F,
          isTokenInvalid: T,
          deliveryErrorMessage: compose(
            always,
            pathOr(DEFAULT_ERROR_MESSAGE, ['response', 'data', 'message'])
          )(action.payload)
        },
        state
      );
    case 'TRANSFER_SET_CHANGE_PICKUP':
      return evolve(
        {
          shouldChangePickup: always(action.payload ?? false)
        },
        state
      );
    case 'TRANSFER_SET_CHANGE_BUYER':
      return evolve(
        {
          shouldChangeBuyer: always(action.payload ?? false)
        },
        state
      );
    default:
      return state;
  }
};

const initialSigningState = {
  id: undefined,
  status: '',
  hintCode: '',
  errorCode: '',
  startToken: '',
  deliveryTransferId: 0
};

type TransferUpdateSigningStatusAction = {
  type: typeof TRANSFER_SIGNING_UPDATE_STATUS_SUCCESS;
  payload: {
    hintCode: string;
    errorCode: string;
    status: keyof typeof TRANSFER_SIGNING_STATUS;
  };
};

type SetTransferPickup = {
  type: typeof SET_TRANSFER_NEW_PICKUP_SUCCESS;
  payload?: {
    transfer: {
      id: number;
      signingId: string;
      autoStartToken: string;
      contact: Contact;
      orderRef: string;
      qrCode: string;
    };
  };
};

type TransferSigningAction = {
  type: typeof SET_TRANSFER_NEW_BUYER_SUCCESS;
  payload?: {
    transfer: {
      id: number;
      signingId: string;
      autoStartToken: string;
      orderRef: string;
      qrCode: string;
    };
  };
};

const signingReducer = (
  state = initialSigningState,
  action:
    | SetTransferPickup
    | TransferSigningAction
    | TransferUpdateSigningStatusAction
): ReduxStore['transfer']['signing'] => {
  if (action.type === 'TRANSFER_SIGNING_UPDATE_STATUS_SUCCESS') {
    switch (action.payload.status) {
      case TRANSFER_SIGNING_STATUS.FAILED:
      case TRANSFER_SIGNING_STATUS.COMPLETE:
        return {
          ...state,
          id: '',
          startToken: '',
          deliveryTransferId: 0,
          status: action.payload.status,
          hintCode: action.payload.hintCode,
          errorCode: action.payload.errorCode
        };

      default:
        return {
          ...state,
          status: action.payload.status,
          hintCode: action.payload.hintCode
        };
    }
  }

  switch (action.type) {
    case 'SET_TRANSFER_NEW_BUYER_SUCCESS':
    case 'SET_TRANSFER_NEW_PICKUP_SUCCESS':
      return {
        ...state,
        id: action.payload?.transfer.signingId,
        status: TRANSFER_SIGNING_STATUS.PENDING,
        deliveryTransferId: action.payload?.transfer.id ?? 0,
        startToken: action.payload?.transfer.autoStartToken,
        orderRef: action.payload?.transfer.orderRef,
        qrCode: action.payload?.transfer.qrCode
      };
    default:
      return state;
  }
};

const initialBookingState = {
  facility: null,
  timeslots: [],
  bookingError: '',
  timeslotsError: '',
  bookedTimeslot: null,
  isTimesLoading: false,
  isBookingLoading: false
};

type TransferBookingAction = {
  type:
    | typeof GET_TRANSFER_DELIVERY_SUCCESS
    | typeof GET_TRANSFER_TIMESLOTS_REQUEST
    | typeof GET_TRANSFER_TIMESLOTS_SUCCESS
    | typeof GET_TRANSFER_TIMESLOTS_FAILURE
    | typeof BOOK_TRANSFER_TIMESLOT_REQUEST
    | typeof BOOK_TRANSFER_TIMESLOT_SUCCESS
    | typeof BOOK_TRANSFER_TIMESLOT_FAILURE;
  payload?: {
    facility: Facility;
    timeslot: TimeSlot;
    timeslots: TimeSlot[];
    bookingError: string[];
    delivery: {
      timeslot?: TimeSlot;
    };
  };
};

const bookingReducer = (
  state = initialBookingState,
  action: TransferBookingAction
): ReduxStore['transfer']['booking'] => {
  switch (action.type) {
    case 'GET_TRANSFER_DELIVERY_SUCCESS':
      return evolve(
        {
          bookedTimeslot: always(action.payload?.delivery.timeslot)
        },
        state
      );
    case 'GET_TRANSFER_TIMESLOTS_REQUEST':
      return evolve(
        {
          isTimesLoading: T
        },
        state
      );
    case 'GET_TRANSFER_TIMESLOTS_SUCCESS': {
      const availableDays = Object.entries(action.payload?.timeslots ?? {}).map(
        ([key, timeslots]) => ({
          calendarId: '',
          date: key,
          timeslots
        })
      ) as unknown as TimePickerSlot[];

      return evolve(
        {
          isTimesLoading: F,
          timeslots: always(availableDays ?? []),
          facility: always(action.payload?.facility)
        },
        state
      );
    }
    case 'GET_TRANSFER_TIMESLOTS_FAILURE':
      return evolve(
        {
          isTimesLoading: F,
          timeslotsError: compose(
            always,
            pathOr(DEFAULT_ERROR_MESSAGE, ['response', 'data', 'message'])
          )(action.payload)
        },
        state
      );
    case 'BOOK_TRANSFER_TIMESLOT_REQUEST':
      return evolve(
        {
          isBookingLoading: T,
          bookingError: always('')
        },
        state
      );
    case 'BOOK_TRANSFER_TIMESLOT_SUCCESS':
      return evolve(
        {
          isBookingLoading: F,
          bookedTimeslot: always(action.payload?.timeslot)
        },
        state
      );
    case 'BOOK_TRANSFER_TIMESLOT_FAILURE':
      return evolve(
        {
          isBookingLoading: F,
          bookingError: always(
            path(['payload', 'response', 'data', 'message'], action) ??
              DEFAULT_ERROR_MESSAGE
          )
        },
        state
      );
    default:
      return state;
  }
};

type TransferBuyerAction = {
  type:
    | typeof TRANSFER_CLEAR_BUYER_ERROR
    | typeof GET_TRANSFER_DELIVERY_SUCCESS;
  payload?: {
    delivery: {
      buyerIndividual: Contact;
      buyerOrganisation: Contact;
    };
  };
};

type TransferSetNewBuyerRequestAction = {
  type: typeof SET_TRANSFER_NEW_BUYER_REQUEST;
  payload?: TransferPickupContact;
};

type TransferSetNewBuyerSuccessAction = {
  type: typeof SET_TRANSFER_NEW_BUYER_SUCCESS;
  payload?: {
    transfer: { contact: Contact };
  };
};

type TransferSetNewBuyerFailureAction = {
  type: typeof SET_TRANSFER_NEW_BUYER_FAILURE;
  payload?: ReduxStore['transfer']['buyer']['errors'];
};

const initialBuyerState = {
  errors: [],
  contact: null,
  pendingContact: undefined,
  isLoading: false
};

const buyerReducer = (
  state = initialBuyerState,
  action:
    | TransferBuyerAction
    | TransferSetNewBuyerRequestAction
    | TransferSetNewBuyerSuccessAction
    | TransferSetNewBuyerFailureAction
): ReduxStore['transfer']['buyer'] => {
  switch (action.type) {
    case 'GET_TRANSFER_DELIVERY_SUCCESS':
      return evolve(
        {
          contact: compose(
            always,
            ifElse(
              propEq('buyerIndividual', null),
              prop('buyerOrganisation'),
              prop('buyerIndividual')
            )
          )(action.payload?.delivery ?? {})
        },
        state
      );
    case 'SET_TRANSFER_NEW_BUYER_REQUEST':
      return {
        ...state,
        isLoading: true,
        pendingContact: action.payload
      };
    case 'SET_TRANSFER_NEW_BUYER_SUCCESS':
      return {
        ...state,
        isLoading: false,
        contact: action.payload?.transfer.contact
      };
    case 'SET_TRANSFER_NEW_BUYER_FAILURE':
      return {
        ...state,
        isLoading: false,
        errors: action.payload ?? []
      };
    case 'TRANSFER_CLEAR_BUYER_ERROR':
      return {
        ...state,
        isLoading: false,
        errors: []
      };
    default:
      return state;
  }
};

type SetNewPickup = {
  type: typeof SET_TRANSFER_NEW_PICKUP_REQUEST;
  payload?: TransferPickupContact;
};

type ClearPickupError = {
  type: typeof TRANSFER_CLEAR_PICKUP_ERROR;
};

type SetTransferPickupError = {
  type: typeof SET_TRANSFER_NEW_PICKUP_FAILURE;
  payload?: Record<string, string>[];
};

const initialPickupState = {
  errors: [],
  contact: null,
  pendingContact: {},
  isLoading: false
};

const pickupReducer = (
  state = initialPickupState,
  action:
    | SetNewPickup
    | ClearPickupError
    | SetTransferPickup
    | SetTransferPickupError
    | GetTransferDeliverySuccess
): ReduxStore['transfer']['pickup'] => {
  switch (action.type) {
    case 'GET_TRANSFER_DELIVERY_SUCCESS':
      return evolve(
        {
          contact: compose(
            always,
            ifElse(
              propEq('pickupIndividual', null),
              prop('pickupOrganisation'),
              prop('pickupIndividual')
            )
          )(action.payload?.delivery)
        },
        state
      );
    case 'SET_TRANSFER_NEW_PICKUP_REQUEST':
      return {
        ...state,
        isLoading: true,
        pendingContact: action.payload
      };
    case 'SET_TRANSFER_NEW_PICKUP_SUCCESS':
      return {
        ...state,
        isLoading: false,
        contact: action.payload?.transfer.contact
      };
    case 'SET_TRANSFER_NEW_PICKUP_FAILURE':
      return {
        ...state,
        isLoading: false,
        errors: action.payload ?? []
      };
    case 'TRANSFER_CLEAR_PICKUP_ERROR':
      return {
        ...state,
        isLoading: false,
        errors: []
      };
    default:
      return state;
  }
};

export default combineReducers<ReduxStore['transfer']>({
  data: dataReducer,
  buyer: buyerReducer,
  pickup: pickupReducer,
  signing: signingReducer,
  booking: bookingReducer
});
