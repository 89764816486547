import {
  AuctionClosedEvent,
  AuctionCloseTimeUpdatedEvent,
  AuctionCountdownStartedEvent
} from '../interfaces/events/AuctionEvent';
import { BidEvent } from '../interfaces/events/BidEvent';
import { ReleasingCostUpdatedEvent } from '../interfaces/events/ReleasingCostEvent';

export const auctionClosed = (payload: AuctionClosedEvent) => ({
  type: 'AUCTION_CLOSED' as const,
  payload
});

export const auctionBidCancelled = (payload: BidEvent) => ({
  type: 'AUCTION_BID_CANCELLED' as const,
  payload
});

export const auctionBidPlaced = (payload: BidEvent) => ({
  type: 'AUCTION_BID_PLACED' as const,
  payload
});

export const auctionReleasingCostUpdated = (
  payload: ReleasingCostUpdatedEvent
) => ({
  type: 'AUCTION_RELEASING_COST_UPDATED' as const,
  payload
});

export const auctionCountdownStarted = (
  payload: AuctionCountdownStartedEvent
) => ({
  type: 'AUCTION_COUNTDOWN_STARTED' as const,
  payload
});

export const auctionCloseTimeUpdated = (
  payload: AuctionCloseTimeUpdatedEvent
) => ({
  type: 'AUCTION_CLOSE_TIME_UPDATED' as const,
  payload
});

export const timeSyncResponse = (payload: {
  clientTimeStart: number;
  serverTime: number;
}) => ({
  type: 'TIME_SYNC_RESPONSE' as const,
  payload
});

export const websocketDisconnected = () => ({
  type: 'WEBSOCKET_DISCONNECTED' as const
});

export const websocketConnected = () => ({
  type: 'WEBSOCKET_CONNECTED' as const
});

export const websocketReconnected = () => ({
  type: 'WEBSOCKET_RECONNECTED' as const
});

export const WEBSOCKET_SEND_MESSAGE = 'WEBSOCKET_SEND_MESSAGE';
export const websocketSendMessage = (
  routingKey: string,
  payload: Record<string, unknown>
) => ({
  type: 'WEBSOCKET_SEND_MESSAGE' as const,
  routingKey: routingKey,
  payload
});

export const websocketSubscribeToChannel = (
  channelIdentifier: number | string
) => {
  return {
    type: 'WEBSOCKET_SEND_MESSAGE' as const,
    routingKey: 'subscribe_to_channel',
    payload: {
      channelIdentifier
    }
  };
};

export const failedToFetch = () => ({
  type: 'FETCH_FAILED' as const
});

export const registerLastFetch = () => ({
  type: 'REGISTER_LAST_FETCH' as const
});

export const fetchSuccess = () => ({
  type: 'FETCH_SUCCESS' as const
});

export type ConnectionStateFetchAction =
  | ReturnType<typeof failedToFetch>
  | ReturnType<typeof fetchSuccess>
  | ReturnType<typeof registerLastFetch>;

export type WebsocketAction =
  | ReturnType<typeof auctionClosed>
  | ReturnType<typeof timeSyncResponse>
  | ReturnType<typeof auctionBidCancelled>
  | ReturnType<typeof auctionBidPlaced>
  | ReturnType<typeof auctionReleasingCostUpdated>
  | ReturnType<typeof auctionCountdownStarted>
  | ReturnType<typeof auctionCloseTimeUpdated>;

export type WebsocketEventAction =
  | ReturnType<typeof websocketConnected>
  | ReturnType<typeof websocketReconnected>
  | ReturnType<typeof websocketDisconnected>;
