import { isServer } from '~/config/public/environment';
import { CookiebotConsent } from '~/globalTypes';

export const getCookieConsent = () =>
  isServer ? null : window?.Cookiebot?.consent ?? null;

export const isCookieCategoryConsent = (
  category: keyof Pick<
    CookiebotConsent,
    'marketing' | 'statistics' | 'preferences'
  >
) => getCookieConsent()?.[category] ?? false;
