import { CarguideDocument, CarguideQuery } from '~/config/generated/graphql';
import { useCmsData } from '~/App/shared/hooks/useCmsData';
import { CarguideData } from '~/App/shared/interfaces/store/CmsData';
import { useEffect, useMemo, useState } from 'react';
import { localForageGetItem } from '~/helpers/client/localForage';
import { CarGuideAnswers } from './types';

export const useCarguideCmsData = () => {
  const cmsData = useCmsData<CarguideQuery, CarguideData>({
    storeKey: 'carguide',
    query: CarguideDocument
  });

  return cmsData;
};

export const useIsCarguideFinished = () => {
  const { data: cmsData, fetching } = useCarguideCmsData();
  const questionsLength = cmsData?.questions?.length || 0;

  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    if (!fetching && questionsLength) {
      void localForageGetItem<CarGuideAnswers>('carguide').then(answers => {
        if (!answers) {
          setIsFinished(false);
        } else {
          const answersLength = Object.keys(answers).length;
          const hasAnsweredALl = answersLength === questionsLength;

          setIsFinished(hasAnsweredALl);
        }
      });
    }
  }, [questionsLength, fetching]);

  const state = useMemo(
    () => ({
      isCarguideFinished: isFinished
    }),
    [isFinished]
  );

  return state;
};
