export const unionBy = <T = Record<string, unknown>>(
  array: T[],
  key: keyof T
) =>
  array.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        i => i?.[key] === value?.[key] && i?.[key] === value?.[key]
      )
  );
