import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: ${({ theme }) => theme.colors.error.main};
  color: ${({ theme }) => theme.colors.common.white};
  border-radius: 0.25rem;
  padding: 0.75rem;
`;

interface Props {
  className?: string;
  errorMessage: string;
}

const ErrorMessage = ({ errorMessage, className }: Props) => (
  <Container className={className}>{errorMessage}</Container>
);

export default ErrorMessage;
