import { url } from '~/config/public/orchestration';
import { Notifications } from '~/App/shared/interfaces/store/Notifications';
import axios from './axiosWithAuthHandling';

export const fetchNotifications = async (idToken: string) => {
  return axios
    .get<{ notifications: Notifications }>(`${url}/common/notifications`, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    })
    .then(res => {
      return res.data.notifications;
    });
};

interface ViewAuction {
  idToken: string;
  auctionId: string;
}
export const sendViewAuction = async ({ idToken, auctionId }: ViewAuction) => {
  return axios.delete(`${url}/common/notifications/auction/${auctionId}`, {
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  });
};

interface ViewNotification {
  idToken: string;
  savedSearchId: string;
}
export const sendViewSavedSearch = async ({
  idToken,
  savedSearchId
}: ViewNotification) => {
  return axios.put<{ savedSearchId: string }>(
    `${url}/common/notifications/saved-search/${savedSearchId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }
  );
};
