import { DomainLocale, Locale } from '~/App/shared/localization/types';
import { environment } from '~/config/public/environment';
import { BASE_DEV_URLS, BASE_URLS } from '~/config/constants';
import { Environment } from '~/config/types';

export const trimLocalePathSegment = (path: string, locale: Locale) => {
  const splitPath = path.split('/');
  if (splitPath?.[1] !== locale) {
    return path;
  }
  return splitPath.filter(elem => elem !== locale).join('/');
};

export const getSvOrEnLocale = (locale: Locale | null) => {
  return locale === Locale.EN ? Locale.EN : Locale.SV;
};

export const getRedirectBaseUrl = ({
  requestedlocale,
  domainLocale,
  originUrl
}: {
  requestedlocale: Locale;
  domainLocale: DomainLocale | null;
  originUrl: string;
}) => {
  if (!domainLocale) {
    return originUrl;
  }

  const redirectLocale: DomainLocale =
    requestedlocale === Locale.SV ? Locale.SV : Locale.EN;

  if (environment === Environment.Production) {
    return BASE_URLS[redirectLocale];
  }
  if (environment === Environment.Local) {
    return BASE_DEV_URLS[redirectLocale];
  }
  if (redirectLocale === domainLocale) {
    return originUrl;
  }

  const pattern = domainLocale !== Locale.SV ? 'kvdcars-com' : 'kvd-se';
  const replacePhrase = domainLocale === Locale.SV ? 'kvdcars-com' : 'kvd-se';
  return originUrl.replace(pattern, replacePhrase);
};

export const trimTrailingSlash = (path: string) => path.replace(/\/$/, '');

export const trimLeadingSlash = (path: string) => path.replace(/^\/+/, '');

export const addLeadingSlash = (path: string) => `/${trimLeadingSlash(path)}`;
