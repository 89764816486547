import { pathnameStartsWithRoute } from './router';
import { TranslateFunction } from '~/Locale';

const blackListedPaths = ['ROUTES.CHECKOUT', 'ROUTES.PAYMENT'];

export const showFooter = (t: TranslateFunction, pathname: string) =>
  !blackListedPaths.some(route => pathnameStartsWithRoute(t, pathname, route));

const pathnamesWithoutFooterButtons = [
  '/handelsbanken',
  '/nordea',
  '/wasakredit',
  '/danskefinans',
  '/seb',
  '/sixtleasing',
  '/skanska'
];

export const hideFooterButtons = (t: TranslateFunction, pathname: string) =>
  pathnameStartsWithRoute(t, pathname, 'ROUTES.SELL') ||
  pathnamesWithoutFooterButtons.includes(pathname);
