import axios from '~/helpers/orchestration/axiosWithAuthHandling';
import { targetedAuctionsFeatureEnabled } from '~/config/public/environment';
import { set as setCookie, formatCookieString } from '~/helpers/client/cookie';

import {
  getUsersTargetedAuctionGroupIds,
  pollTargetedAuctions,
  TARGETED_AUCTIONS_POLLING_INTERVALL_MILLISECONDS
} from '~/helpers/targetedAuctions';
import {
  clearPollingTargetedAuctions,
  setPollingTargetedAuctions,
  hydrateTargetedAuctions,
  clearHasSeenLatestAuctions
} from '~/App/shared/actions/targetedAuctionsActions';
import { hydrateWonAuctions } from '~/App/shared/actions/wonAuctions';
import { localForageSetItem } from '~/helpers/client/localForage';
import { requestErrorHandler } from '~/helpers/notifyError';
import { log } from '~/helpers/bugsnagHelper';
import { sellflowSetDefaultContactDetails } from './sellflowActions';
import { initialDefaultContactDetails } from '../reducers/sellFlow1Reducer';
import { Credentials, login, logout } from '~/helpers/orchestration/auth';
import { fetchSavedSearches } from './saved-searches';
import { hydrateFavouriteAuctions } from './favouriteAuctions';
import { fetchMessages } from './messages';
import { isObjectViewDrawerOpen } from '../selectors/storeObject';
import { idToken as idTokenSelector } from '../selectors/sessionSelector';
import { isAxiosError } from '~/helpers/axiosUtils';
import { Member } from '../interfaces/Member';
import { ReduxStore } from '../interfaces/store';
import { Auth, Session, SessionErrors } from '../interfaces/store/Session';
import { ReduxDispatch } from '../interfaces/Redux';
import { TranslateFunction } from '~/Locale';
import { openMenuDrawer } from './drawerMenu';

type SessionStartSuccessArgs = {
  auth: Auth;
  member: Member;
  socialIdpSession?: boolean;
};

export const sessionStartRequest = () => ({
  type: 'SESSION_START_REQUEST' as const
});

export const sessionStartSuccess = ({
  auth,
  member
}: SessionStartSuccessArgs) => ({
  type: 'SESSION_START_SUCCESS' as const,
  auth: auth,
  member: member
});

export const sessionStartFailure = (errors: SessionErrors) => ({
  type: 'SESSION_START_FAILURE' as const,
  errors: errors
});

export const sessionUpdate = ({
  auth,
  member,
  t
}: {
  auth: Auth;
  member: Member;
  t?: TranslateFunction;
}) => ({
  type: 'SESSION_UPDATE' as const,
  auth: auth,
  member: member,
  // TODO: Probably refactor this (snackbar, message) to use meta instead - So it can be executed from action and not reducer.
  payload: {
    key: Date.now(),
    message: t ? t('Updated member settings') : 'Updated member settings'
  }
});

export const sessionHydrate = ({ session }: { session: Session }) => ({
  type: 'SESSION_HYDRATE' as const,
  session: session
});

export const verifyMemberEmail = () => ({
  type: 'VERIFY_MEMBER_EMAIL' as const
});

export const confirmUserSettings = () => ({
  type: 'CONFIRM_MEMBER_SETTINGS' as const
});

export const sessionDestroyRequest = () => ({
  type: 'SESSION_DESTROY_REQUEST' as const
});

export const sessionDestroySuccess = () => ({
  type: 'SESSION_DESTROY_SUCCESS' as const
});

export const sessionDestroyFailure = () => ({
  type: 'SESSION_DESTROY_FAILURE' as const
});

export const sessionClientRequest = () => ({
  type: 'SESSION_CLIENT_REQUEST' as const
});

export const setClientVersion = (clientVersion: string) => ({
  type: 'SET_CLIENT_VERSION' as const,
  payload: clientVersion
});

export const sessionStartSuccessIDP =
  ({ auth, member, socialIdpSession }: SessionStartSuccessArgs) =>
  async (dispatch: ReduxDispatch) => {
    await localForageSetItem('session', {
      member,
      auth,
      isAuthenticated: true
    });

    dispatch({
      type: 'SESSION_START_SUCCESS',
      auth: auth,
      member: member,
      socialIdpSession: socialIdpSession
    });
  };

type SessionStartArgs = {
  auth?: Credentials;
  onLogin?(): void;
  session?: Session;
};

export const sessionStart =
  ({ auth, onLogin, session }: SessionStartArgs) =>
  async (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    dispatch({ type: 'SESSION_START_REQUEST' });

    try {
      let data: Session;

      if (session) {
        data = session;
      } else if (auth) {
        data = (await login(auth)).data;
      } else {
        throw Error('No session or auth provided');
      }

      /* TODO: Middleware this too */
      if (targetedAuctionsFeatureEnabled) {
        const groups = getUsersTargetedAuctionGroupIds(data.auth.idToken);
        const threadArray: ReturnType<typeof setInterval>[] = [];

        groups.forEach(group => {
          pollTargetedAuctions(group, data);
          const thread = setInterval(
            () => pollTargetedAuctions(group, data),
            TARGETED_AUCTIONS_POLLING_INTERVALL_MILLISECONDS
          );

          threadArray.push(thread);

          dispatch(
            hydrateTargetedAuctions({
              idToken: data.auth.idToken,
              targetedAuctionId: group
            })
          )
            .then(() => dispatch(setPollingTargetedAuctions(threadArray)))
            .catch(requestErrorHandler);
        });
      }

      onLogin?.();

      /* TODO: Move this into some middleware to be ran when we start the session !! Not here. This is error prone and awful. Bad job old me */
      const hasShowedBefore = localStorage.getItem('hasShowedVerification');
      const hasPhoneNumber = Boolean(data.member?.person?.phoneNumber);
      const hasVerifiedPhoneNumber = Boolean(
        data.member?.person?.phoneNumberVerified
      );
      const hasVerifiedEmail = Boolean(data.member?.person?.emailVerified);

      const state = getState();
      const _isBiddingViewOpen = isObjectViewDrawerOpen(state);

      const openVerification =
        hasPhoneNumber &&
        hasVerifiedEmail &&
        !hasShowedBefore &&
        !hasVerifiedPhoneNumber &&
        !_isBiddingViewOpen;

      if (openVerification) {
        localStorage.setItem('hasShowedVerification', 'true');
        dispatch(openMenuDrawer('VERIFY_PHONE_NUMBER'));
      }

      // TODO: All of these should be moved into some middleware as well.
      void dispatch(
        fetchSavedSearches({
          idToken: data.auth.idToken
        })
      );

      void dispatch(hydrateFavouriteAuctions(data.auth.idToken));

      void dispatch(
        hydrateWonAuctions({
          idToken: data.auth.idToken
        })
      );

      dispatch(
        sessionStartSuccess({
          auth: { ...data.auth },
          member: {
            ...data.member,
            targetedAuctionGroups: getUsersTargetedAuctionGroupIds(
              data.auth.idToken
            )
          }
        })
      );

      return getState().session;
    } catch (error: unknown) {
      if (isAxiosError<{ message: string }>(error)) {
        const errors = error.response
          ? [
              {
                status: error.response.status,
                detail: error.response.data.message
              }
            ]
          : [{ status: 500 }];
        dispatch(sessionStartFailure(errors));
      }

      if (error instanceof Error) {
        log(error);
      }

      return getState().session;
    }
  };

export const sessionDestroy =
  () => async (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();
    const idToken = idTokenSelector(state);

    dispatch(clearPollingTargetedAuctions());
    dispatch(clearHasSeenLatestAuctions());
    dispatch(sessionDestroyRequest());
    dispatch(sellflowSetDefaultContactDetails(initialDefaultContactDetails));

    try {
      await logout(idToken);
      dispatch(sessionDestroySuccess());
      dispatch(clearPollingTargetedAuctions());
      dispatch(clearHasSeenLatestAuctions());
    } catch (err: unknown) {
      dispatch(sessionDestroyFailure());
    }
  };

export const fetchSessionClientVersion =
  () => (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    dispatch(sessionClientRequest());

    return axios
      .get<{ clientVersion: string }>('/api/client-version', {
        responseType: 'json'
      })
      .then(({ data }) => {
        const { clientVersion } = data;
        const session = getState().session;
        const { clientVersion: currentVersion } = session;

        if ((currentVersion ?? '') !== (clientVersion ?? '')) {
          setCookie('showVersionNotice', 'true', 1);

          const messageFetcher = fetchMessages(
            formatCookieString(document.cookie)
          );

          void dispatch(messageFetcher);
        } else {
          setCookie('showVersionNotice', 'false', 1);
        }
      })
      .catch((error: unknown) => {
        requestErrorHandler(error);
      });
  };

export type SessionActions =
  | ReturnType<typeof sessionHydrate>
  | ReturnType<typeof sessionUpdate>
  | ReturnType<typeof sessionStartRequest>
  | ReturnType<typeof sessionStartSuccess>
  | ReturnType<typeof sessionStartFailure>
  | ReturnType<typeof verifyMemberEmail>
  | ReturnType<typeof sessionDestroyRequest>
  | ReturnType<typeof sessionDestroySuccess>
  | ReturnType<typeof sessionDestroyFailure>
  | ReturnType<typeof setClientVersion>
  | ReturnType<typeof confirmUserSettings>;
