import { createSelector } from 'reselect';
import { ReduxStore } from '../interfaces/store';
import {
  CmsData,
  CmsDataStoreKey
} from '~/App/shared/interfaces/store/CmsData';

const cmsDataRoot = (store: ReduxStore) => store.cmsData;

export const cmsDataByStoreKeySelector = <Data>(
  key: CmsDataStoreKey,
  nestedKey?: string
) =>
  createSelector<ReduxStore, CmsData, Data>(cmsDataRoot, cmsData => {
    if (nestedKey) {
      return cmsData?.[key]?.[
        nestedKey as keyof CmsData[keyof CmsData]
      ] as unknown as Data;
    }
    return cmsData?.[key] as unknown as Data;
  });
