import { useMemo } from 'react';
import { useSelector, useStore } from 'react-redux';
import {
  GA4UserProps,
  getGAUserFromState
} from '~/helpers/client/ga4TrackEvent';
import { isHydratedSelector } from '../selectors/sessionSelector';
import { ReduxStore } from '~/App/shared/interfaces/store';
import { get as getCookie } from '~/helpers/client/cookie';

export const useGA4User = (): GA4UserProps => {
  const store = useStore<ReduxStore>();
  const ga4User = getGAUserFromState(store.getState());

  return useMemo(
    () => ({
      user_id: ga4User.user_id,
      user_type: ga4User.user_type,
      is_car_dealer: ga4User.is_car_dealer
    }),
    [ga4User.user_id, ga4User.user_type, ga4User.is_car_dealer]
  );
};

export const useGA4UserIsHydrated = () => {
  const ga4User = useGA4User();

  // session must be hydrated to use GA4 User
  const isHydrated = useSelector(isHydratedSelector);

  return { ga4User, isHydrated };
};

export const getGAId = () => {
  if (typeof window === 'undefined') return null;

  return getCookie('_gid') ?? null;
};
