import React, { useState, useRef, ChangeEvent, ClipboardEvent } from 'react';
import styled from 'styled-components';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Spinner from './Spinner';
import throttle from 'lodash.throttle';
import { useTranslation } from '~/Locale';
import {
  BodyText,
  CheckCircleIcon,
  Message,
  Stack,
  Title
} from '@kvdbil/components';
import { verifyPhoneNumber } from '~/App/shared/actions/verifyPhoneNumber';
import { useDispatch, useSelector } from 'react-redux';
import {
  phoneVerificationError,
  phoneVerificationIsMaxRetriesExceeded,
  phoneVerificationIsVerified,
  phoneVerificationIsVerifying
} from '~/App/shared/selectors/verifyPhoneNumberSelector';
import { phoneNumber as phoneNumberSelector } from '~/App/shared/selectors/sessionSelector';
import VerifyFooter from './VerifyFooter';

const Container = styled(Stack)`
  width: 100%;
  text-align: center;
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  gap: 1rem;
  justify-content: center;
`;

const InputBox = styled.input`
  outline: none;
  width: 3.5rem;
  height: 3.5rem;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.text.dark};
  border: 2px solid ${({ theme }) => theme.colors.gray.dark3};

  ${({ disabled, theme }) =>
    disabled &&
    `
      border: 2px solid ${theme.colors.gray.light3};
      background-color: ${theme.colors.background.gray};
    `}
`;

const AnimationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 10rem;

  .verifyInput-enter {
    max-width: 3.5rem;
    opacity: 0;
  }

  .verifyInput-enter.verifyInput-enter-active {
    max-width: 18rem;
    opacity: 1;
    transition: max-width 0.3s ease-in-out 0.2s, opacity 0.2s ease-in-out;
  }

  .verifyInput-exit {
    opacity: 1;
    width: 100%;
    max-width: 18rem;
  }

  .verifyInput-exit.verifyInput-exit-active {
    opacity: 0;
    max-width: 3.5rem;
    overflow: hidden;
    transition: max-width 0.3s ease-in-out, opacity 0.2s ease-in-out 0.3s;
  }
`;

const VerifiedStatus = styled.div`
  color: ${({ theme }) => theme.colors.secondary.main};

  svg {
    font-size: 6rem;
  }
`;

const transitionProps = {
  classNames: 'verifyInput',
  timeout: {
    enter: 500,
    exit: 500
  }
};

export type CodeVerification = { code: string; delay: number };

interface Props {
  handleClose?: () => void;
}

const EnterCode = ({ handleClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isVerifying = useSelector(phoneVerificationIsVerifying);
  const isMaxRetriesExceeded = useSelector(
    phoneVerificationIsMaxRetriesExceeded
  );
  const error = useSelector(phoneVerificationError);
  const phoneNumber = useSelector(phoneNumberSelector);
  const isVerified = useSelector(phoneVerificationIsVerified);
  const [inputBoxOne, setInputBoxOne] = useState('');
  const [inputBoxTwo, setInputBoxTwo] = useState('');
  const [inputBoxThree, setInputBoxThree] = useState('');
  const [inputBoxFour, setInputBoxFour] = useState('');

  const clearInputs = () => {
    setInputBoxOne('');
    setInputBoxTwo('');
    setInputBoxThree('');
    setInputBoxFour('');
  };

  const inputBoxOneRef = useRef<HTMLInputElement | null>(null);
  const inputBoxTwoRef = useRef<HTMLInputElement | null>(null);
  const inputBoxThreeRef = useRef<HTMLInputElement | null>(null);
  const inputBoxFourRef = useRef<HTMLInputElement | null>(null);

  const isInputDisabled = isVerifying || isMaxRetriesExceeded;

  const throttledVerifyCode = throttle((code: string) => {
    /* Delay until actual request is sent. So we don't ruin animation :) */
    dispatch(verifyPhoneNumber({ code, delay: 750 }));
    clearInputs();
  }, 250);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { dataset, value } = event.target;
    const codeWithoutFour = `${inputBoxOne}${inputBoxTwo}${inputBoxThree}`;

    const isValid = value.length < 2;
    const isCodeValid = codeWithoutFour.length == 3;
    const shouldFocus = value.length === 1;

    if (!isValid) {
      return;
    }

    switch (dataset.val) {
      case '1':
        setInputBoxOne(value);
        shouldFocus && inputBoxTwoRef.current?.focus();
        return;
      case '2':
        setInputBoxTwo(value);
        shouldFocus && inputBoxThreeRef.current?.focus();
        return;
      case '3':
        setInputBoxThree(value);
        shouldFocus && inputBoxFourRef.current?.focus();
        return;
      case '4':
        setInputBoxFour(value);

        if (!isCodeValid) {
          return;
        }

        throttledVerifyCode(`${codeWithoutFour}${value}`);
        return;
      default:
        return;
    }
  };

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    const pastedText = event.clipboardData.getData('text').trim();

    if (pastedText.length === 4) {
      inputBoxOneRef.current?.blur();
      throttledVerifyCode(pastedText);
    }
  };

  const hasError = Boolean(error && !isVerifying);

  return (
    <Container rowGap={2}>
      <Stack>
        <Title as="h3">{t('Verify phone number')}</Title>
        <BodyText>{t('We need to confirm that your number ...')}</BodyText>
      </Stack>
      <BodyText>
        {`${t('Type the four character long code we have sent ...')} `}
        <strong>{phoneNumber}</strong>
      </BodyText>

      {hasError && (
        <Message type="error" fullWidth>
          {error || t('Something went wrong, please try again later.')}
        </Message>
      )}

      <AnimationContainer>
        <SwitchTransition>
          <CSSTransition
            {...transitionProps}
            key={!isVerifying && !isVerified ? 'showInput' : 'showSpinner'}
          >
            <>
              {!isVerifying && !isVerified && (
                <InputContainer key={1}>
                  <InputBox
                    autoFocus
                    key="1"
                    type="text"
                    pattern="\d*"
                    onPaste={handlePaste}
                    disabled={isInputDisabled}
                    ref={inputBoxOneRef}
                    value={inputBoxOne}
                    onChange={handleChange}
                    data-val="1"
                  />
                  <InputBox
                    key="2"
                    type="text"
                    pattern="\d*"
                    disabled={isInputDisabled}
                    ref={inputBoxTwoRef}
                    value={inputBoxTwo}
                    onChange={handleChange}
                    data-val="2"
                  />
                  <InputBox
                    key="3"
                    type="text"
                    pattern="\d*"
                    disabled={isInputDisabled}
                    ref={inputBoxThreeRef}
                    value={inputBoxThree}
                    onChange={handleChange}
                    data-val="3"
                  />
                  <InputBox
                    key="4"
                    type="text"
                    pattern="\d*"
                    disabled={isInputDisabled}
                    ref={inputBoxFourRef}
                    value={inputBoxFour}
                    onChange={handleChange}
                    data-val="4"
                  />
                </InputContainer>
              )}
              {isVerifying && !isVerified && (
                <Spinner isLoading={isVerifying} />
              )}
              {isVerified && (
                <VerifiedStatus>
                  <CheckCircleIcon />
                </VerifiedStatus>
              )}
            </>
          </CSSTransition>
        </SwitchTransition>
      </AnimationContainer>

      <VerifyFooter handleClose={handleClose} />
    </Container>
  );
};

export default EnterCode;
