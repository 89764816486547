import { combineReducers } from 'redux';
import { UpdateAliasAction } from '../actions/objectViewDrawerActions';
import { WebsocketAction } from '../actions/connection-events';
import Auction from '../interfaces/Auction';
import { ReduxStore } from '../interfaces/store';

const LogoutAction = {
  type: 'SESSION_DESTROY_SUCCESS' as const
};

export type ByIdInitialState = Record<string, Auction>;

export const byId = (
  state: ByIdInitialState = {},
  action: WebsocketAction | UpdateAliasAction | typeof LogoutAction
): ReduxStore['websocketAuctions']['byId'] => {
  switch (action.type) {
    case 'AUCTION_CLOSED': {
      const auction = state[action.payload.auctionId] || {
        id: action.payload.auctionId
      };
      const activeAuction = auction.activeAuction;

      if (!activeAuction) {
        return state;
      }

      return {
        ...state,
        [action.payload.auctionId]: {
          ...auction,
          activeAuction: {
            ...activeAuction,
            closed: true,
            countdownInProgress: false,
            highestBid: action.payload.highestBid
          }
        }
      };
    }

    case 'AUCTION_BID_PLACED': {
      const auction = state[action.payload.auctionId] || {
        id: action.payload.auctionId
      };

      const activeAuction = auction.activeAuction;

      if (!activeAuction) {
        return state;
      }

      return {
        ...state,
        [action.payload.auctionId]: {
          ...auction,
          activeAuction: {
            ...activeAuction,
            reservationPriceReached: action.payload.reservationPriceReached
          }
        }
      };
    }

    case 'AUCTION_BID_CANCELLED': {
      const auction = state[action.payload.auctionId] || {
        id: action.payload.auctionId
      };
      const activeAuction = auction.activeAuction;
      const cancelledBids = auction.cancelledBids ?? [];

      if (!activeAuction) {
        return state;
      }

      return {
        ...state,
        [action.payload.auctionId]: {
          ...auction,
          activeAuction: {
            ...activeAuction,
            reservationPriceReached: action.payload.reservationPriceReached
          },
          cancelledBids: [...cancelledBids, action.payload.id]
        }
      };
    }

    case 'UPDATE_MY_BIDDER_ALIAS': {
      const auction = state[action.payload.auctionId] || {
        id: action.payload.auctionId
      };

      if (!auction.activeAuction) {
        return state;
      }

      return {
        ...state,
        [action.payload.auctionId]: {
          ...auction,
          id: action.payload.auctionId,
          activeAuction: {
            ...auction.activeAuction,
            myBidderAlias: action.payload.myBidderAlias
          }
        }
      };
    }

    case 'UPDATE_MY_BIDDER_ALIASES':
      return {
        ...state,
        ...action.auctions
          .filter(auction => Boolean(auction?.activeAuction?.myBidderAlias))
          .reduce(
            (prev, current) => ({
              ...prev,
              [current.id]: {
                ...current
              }
            }),
            {}
          )
      };

    case 'SESSION_DESTROY_SUCCESS':
      return {};

    case 'AUCTION_COUNTDOWN_STARTED': {
      const auction = state[action.payload.auctionId] || {
        id: action.payload.auctionId
      };

      if (!auction.activeAuction) {
        return state;
      }

      return {
        ...state,
        [action.payload.auctionId]: {
          ...auction,
          id: action.payload.auctionId,
          countdownDuration: action.payload.countdownDuration,
          activeAuction: {
            ...auction.activeAuction,
            countdownInProgress: true
          }
        }
      };
    }

    case 'AUCTION_CLOSE_TIME_UPDATED': {
      const auction = state[action.payload.auctionId] || {
        id: action.payload.auctionId
      };

      if (!auction.activeAuction) {
        return state;
      }

      return {
        ...state,
        [action.payload.auctionId]: {
          ...auction,
          id: action.payload.auctionId,
          activeAuction: {
            ...auction.activeAuction,
            preliminaryCloseAt: action.payload.preliminaryCloseAt
          }
        }
      };
    }

    default:
      return state;
  }
};

export const allIds = (
  state: string[] = [],
  action: WebsocketAction | UpdateAliasAction | typeof LogoutAction
): ReduxStore['websocketAuctions']['allIds'] => {
  switch (action.type) {
    case 'UPDATE_MY_BIDDER_ALIASES':
      return [
        ...action.auctions
          .filter(auction => Boolean(auction?.activeAuction?.myBidderAlias))
          .map(auction => auction.id),
        ...state
      ];

    case 'AUCTION_CLOSED':
    case 'AUCTION_BID_PLACED':
    case 'AUCTION_COUNTDOWN_STARTED':
    case 'AUCTION_CLOSE_TIME_UPDATED':
      if (!state.includes(action.payload.auctionId)) {
        return [action.payload.auctionId, ...state];
      }
      return state;

    case 'SESSION_DESTROY_SUCCESS':
      return [];

    default:
      return state;
  }
};

const websocketAuctions = combineReducers<ReduxStore['websocketAuctions']>({
  byId: byId,
  allIds: allIds
});

export default websocketAuctions;
