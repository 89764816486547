import { createSelector } from 'reselect';
import { Finance } from '../interfaces/store/Finance';
import { ReduxStore } from '../interfaces/store';
import { CmsData } from '../interfaces/store/CmsData';

const financeRoot = createSelector<ReduxStore, Finance, Finance>(
  root => root.finance,
  financeRoot => financeRoot
);

export const cmsDataRoot = createSelector<ReduxStore, CmsData, CmsData>(
  root => root.cmsData,
  cmsData => cmsData
);

export const globalInterestRate = createSelector(
  cmsDataRoot,
  cmsData => cmsData?.globalConfiguration?.financingInterest ?? 0
);

export const loanProcessingFee = createSelector(
  financeRoot,
  financeRoot => financeRoot?.processingFee
);

export const loanMonthlyInvoiceFee = createSelector(
  financeRoot,
  financeRoot => financeRoot?.invoiceFee
);
