import { useCallback, useState } from 'react';
import { requestErrorHandler } from '~/helpers/notifyError';
import { getFacilities as getFacilitiesEndpoint } from '~/helpers/orchestration/facilities';
import { Facility, FacilityFilters } from '../../interfaces/Facility';
import { useCmsData } from '../../hooks/useCmsData';
import {
  ObjectServicesDocument,
  ObjectServicesQuery
} from '~/config/generated/graphql';
import { ObjectServicesData } from '../../interfaces/store/CmsData';

const defaultIncludeObjectTypes: FacilityFilters[] = [
  'CAR',
  'LIGHT_TRANSPORT',
  'RECREATIONAL_VEHICLE'
];

export const useTransportCalculator = () => {
  const { data: cmsData } = useCmsData<ObjectServicesQuery, ObjectServicesData>(
    {
      storeKey: 'objectServices',
      query: ObjectServicesDocument
    }
  );
  const [facilities, setFacilities] = useState<Facility[]>([]);

  const getFacilities = useCallback(
    (includeObjectTypes: FacilityFilters[] = defaultIncludeObjectTypes) => {
      const facilityFilters = includeObjectTypes?.length
        ? {
            params: {
              objectType: includeObjectTypes
            }
          }
        : undefined;

      getFacilitiesEndpoint(facilityFilters)
        .then(facilities => {
          setFacilities(facilities);
        })
        .catch(requestErrorHandler);
    },
    []
  );

  return {
    cmsData,
    facilities,
    getFacilities
  };
};
