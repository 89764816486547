import styled from 'styled-components';
import { mq } from '@kvdbil/components';
import React from 'react';
import { FinancingProps } from '~/App/shared/components/ProductCard/types';
import FinancingRowItem from '~/App/shared/components/ProductCard/components/FinancingRowItem';

const Container = styled.div`
  margin-top: 1rem;
  border-top: 2px solid ${({ theme }) => theme.colors.gray.light6};

  ${mq('tablet')} {
    margin-top: 0.5rem;
  }
`;

const Financing = ({ rows }: { rows: FinancingProps['rows'] }) => {
  return (
    <Container>
      {rows.map((row, index) => (
        <FinancingRowItem
          key={index}
          label={row.label}
          value={row.value}
          isPrice={row.isPrice}
          initialPrice={row.initialPrice}
        />
      ))}
    </Container>
  );
};

export default Financing;
