import { ContactButtonActions } from '../actions/contactButton';
import { ReduxStore } from '../interfaces/store';

export const contactButtonReducer = (
  state: ReduxStore['contactButton'] = {
    isOpen: false,
    isHidden: false,
    position: {},
    kundo: { isOpen: false },
    isDefaultTriggerEnabled: true
  },
  action: ContactButtonActions
) => {
  switch (action.type) {
    case 'SET_CONTACT_BUBBLE':
      return { ...state, isOpen: action.payload };

    case 'SET_CONTACT_BUBBLE_IS_HIDDEN':
      return { ...state, isHidden: action.payload };

    case 'SET_CONTACT_BUTTON_POSITION':
      return { ...state, position: action.payload };

    case 'SET_KUNDO_OPEN':
      return { ...state, kundo: { isOpen: action.payload } };

    case 'SET_CONTACT_BUTTON_DEFAULT_TRIGGER_ENABLED':
      return { ...state, isDefaultTriggerEnabled: action.payload };

    default:
      return state;
  }
};

export default contactButtonReducer;
