import React from 'react';
import { Redirect, useLocation } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { AuctionType } from '~/App/shared/interfaces/Auction';
import { useTranslation } from '~/Locale';

export interface RedirectToBuyCarProps extends RouteComponentProps {
  auctionType?: AuctionType;
}

const RedirectToBuyCar: React.FC<RedirectToBuyCarProps> = ({
  staticContext,
  auctionType
}) => {
  const { t } = useTranslation();

  const { search } = useLocation();

  const newSearch = new URLSearchParams(search);

  if (auctionType) {
    newSearch.set('auctionType', auctionType);
  }

  staticContext && (staticContext.statusCode = 301);

  return (
    <Redirect
      to={{
        pathname: t('ROUTES.FILTER_CAR'),
        search: newSearch.toString()
      }}
    />
  );
};

export const RedirectFromAuctions: React.FC<RouteComponentProps> = props => (
  <RedirectToBuyCar auctionType={AuctionType.BIDDING} {...props} />
);

export const RedirectFromFixedPrice: React.FC<RouteComponentProps> = props => (
  <RedirectToBuyCar auctionType={AuctionType.BUY_NOW} {...props} />
);

export default RedirectToBuyCar;
