import { createSelector } from 'reselect';
import { countSavedSearchNotifications } from '~/helpers/notifications';

// Interfaces
import { ReduxStore } from '../interfaces/store';
import { Notifications } from '../interfaces/store/Notifications';

export const notificationsRoot = (state: ReduxStore) => state.notifications;

export const auctionNotifications = createSelector(
  notificationsRoot,
  notifications => notifications?.auctions || []
);

export const favouriteNotifications = createSelector(
  notificationsRoot,
  notifications => notifications?.favourites || []
);

export const savedSearchesNotifications = createSelector(
  notificationsRoot,
  notifications => notifications?.savedSearches || []
);

export const salesAgreementsNotifications = createSelector(
  notificationsRoot,
  (notifications: Notifications) => notifications?.salesAgreements || []
);

export const totalNotificationCount = createSelector(
  auctionNotifications,
  favouriteNotifications,
  savedSearchesNotifications,
  salesAgreementsNotifications,
  countSavedSearchNotifications,
  (auction, favourite, search, salesAgreement, savedSearches) =>
    auction.length + favourite.length + savedSearches + salesAgreement.length
);
