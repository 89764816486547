import { SessionActions } from './../actions/session';
import {
  F,
  T,
  prop,
  evolve,
  always,
  sortBy,
  uniqBy,
  prepend,
  compose,
  reverse,
  reject,
  propEq
} from 'ramda';

import {
  EDIT_SAVED_SEARCH_FAILURE,
  EDIT_SAVED_SEARCH_REQUEST,
  EDIT_SAVED_SEARCH_SUCCESS,
  FETCH_SAVED_SEARCHES_FAILURE,
  FETCH_SAVED_SEARCHES_REQUEST,
  FETCH_SAVED_SEARCHES_SUCCESS,
  REMOVE_SAVED_SEARCH_FAILURE,
  REMOVE_SAVED_SEARCH_REQUEST,
  REMOVE_SAVED_SEARCH_SUCCESS,
  SAVE_SEARCH_FAILURE,
  SAVE_SEARCH_REQUEST,
  SAVE_SEARCH_SUCCESS
} from '../actions/saved-searches';
import { SavedSearch } from '../interfaces/SavedSearch';

const initialState = {
  list: [],
  loadingId: '',
  isLoading: false
};

type SaveSearchRequestAction = {
  type: typeof SAVE_SEARCH_REQUEST;
  payload: {
    id: string;
  };
};

type SaveSearchSuccessAction = {
  type: typeof SAVE_SEARCH_SUCCESS;
  payload: SavedSearch;
};

type SaveSearchFailureAction = {
  type: typeof SAVE_SEARCH_FAILURE;
};

type SaveSearchAction =
  | SaveSearchRequestAction
  | SaveSearchSuccessAction
  | SaveSearchFailureAction;

type RemoveSavedSearchRequestAction = {
  type: typeof REMOVE_SAVED_SEARCH_REQUEST;
  payload?: SavedSearch;
};
type RemoveSavedSearchSuccessAction = {
  type: typeof REMOVE_SAVED_SEARCH_SUCCESS;
  payload?: SavedSearch;
};
type RemoveSavedSearchFailureAction = {
  type: typeof REMOVE_SAVED_SEARCH_FAILURE;
};

type RemoveSavedSearchAction =
  | RemoveSavedSearchRequestAction
  | RemoveSavedSearchSuccessAction
  | RemoveSavedSearchFailureAction;

type EditSavedSearchRequestAction = {
  type: typeof EDIT_SAVED_SEARCH_REQUEST;
  payload?: SavedSearch;
};
type EditSavedSearchSuccessAction = {
  type: typeof EDIT_SAVED_SEARCH_SUCCESS;
  payload: SavedSearch;
};
type EditSavedSearchFailureAction = {
  type: typeof EDIT_SAVED_SEARCH_FAILURE;
};

type EditSavedSearchAction =
  | EditSavedSearchRequestAction
  | EditSavedSearchSuccessAction
  | EditSavedSearchFailureAction;

type FetchSavedSearchRequestAction = {
  type: typeof FETCH_SAVED_SEARCHES_REQUEST;
  payload?: SavedSearch;
};
type FetchSavedSearchSuccessAction = {
  type: typeof FETCH_SAVED_SEARCHES_SUCCESS;
  payload: SavedSearch[];
};
type FetchSavedSearchFailureAction = {
  type: typeof FETCH_SAVED_SEARCHES_FAILURE;
};

type FetchSavedSearchAction =
  | FetchSavedSearchRequestAction
  | FetchSavedSearchSuccessAction
  | FetchSavedSearchFailureAction;

const savedSearches = (
  state = initialState,
  action:
    | SaveSearchAction
    | RemoveSavedSearchAction
    | EditSavedSearchAction
    | FetchSavedSearchAction
    | SessionActions
) => {
  switch (action.type) {
    case 'SAVE_SEARCH_REQUEST':
      return evolve(
        {
          isLoading: T,
          loadingId: always(action.payload.id)
        },
        state
      );
    case 'SAVE_SEARCH_SUCCESS':
      return evolve(
        {
          isLoading: F,
          loadingId: always(''),
          list: prepend(action.payload)
        },
        state
      );
    case 'SAVE_SEARCH_FAILURE':
      return evolve(
        {
          isLoading: F,
          loadingId: always('')
        },
        state
      );

    case 'REMOVE_SAVED_SEARCH_REQUEST':
      return evolve(
        {
          isLoading: T
        },
        state
      );

    case 'REMOVE_SAVED_SEARCH_SUCCESS':
      return evolve(
        {
          isLoading: F,
          list: reject(propEq('id', action.payload))
        },
        state
      );

    case 'REMOVE_SAVED_SEARCH_FAILURE':
      return evolve(
        {
          isLoading: F
        },
        state
      );

    case 'EDIT_SAVED_SEARCH_REQUEST':
      return evolve(
        {
          isLoading: T,
          loadingId: always(action.payload?.id)
        },
        state
      );
    case 'EDIT_SAVED_SEARCH_FAILURE':
      return evolve(
        {
          isLoading: F
        },
        state
      );
    case 'EDIT_SAVED_SEARCH_SUCCESS':
      return evolve(
        {
          isLoading: F,
          list: compose(uniqBy(prop('id')), prepend(action.payload))
        },
        state
      );

    case 'FETCH_SAVED_SEARCHES_REQUEST':
      return evolve(
        {
          isLoading: T
        },
        state
      );

    case 'FETCH_SAVED_SEARCHES_FAILURE':
      return evolve(
        {
          isLoading: F,
          list: always([])
        },
        state
      );

    case 'FETCH_SAVED_SEARCHES_SUCCESS':
      return evolve(
        {
          list: compose<SavedSearch[], SavedSearch[], SavedSearch[]>(
            reverse,
            sortBy(prop('updatedAt')),
            always(action.payload)
          ),
          isLoading: F
        },
        state
      );
    case 'SESSION_DESTROY_SUCCESS':
      return initialState;
    default:
      return state;
  }
};

export default savedSearches;

export const getAllSavedSearches = (state = {}) => state;
