/**
 * @deprecated TODO: [KVDBIL-1634] refactor...
 */

import { Facility } from '~/App/shared/interfaces/Facility';
import {
  normalizeSearchParams,
  createSearchQuery
} from '~/helpers/auctionSearch';
import {
  SearchObjectsAction,
  SearchAuctionObjectsSuccessAction
} from '../actions/auctions';
import { GetFacilitiesAction } from '../actions/facilities';
import { ReduxStore } from '../interfaces/store';
import { getStateKeysFromBooleans } from '~/helpers/buyCar';

const chunkSize = 20;

const renameFacility = (facility: Facility) => ({
  value: facility.id,
  label: facility.name
});

/**
 * @deprecated TODO: [KVDBIL-1634] remove this
 */
export const initialState: ReduxStore['auctionsList'] = {
  bidding: {
    ids: [],
    hits: 0,
    total: 0,
    lastSearchQuery: {},
    doneLoadingObjectsList: false,
    isFetching: false
  },
  fixedPrice: {
    ids: [],
    hits: 0,
    total: 0,
    lastSearchQuery: {},
    doneLoadingObjectsList: false,
    isFetching: false
  },
  buyCar: {
    ids: [],
    hits: 0,
    total: 0,
    lastSearchQuery: {},
    doneLoadingObjectsList: false,
    isFetching: false
  },
  facilities: [],
  limit: chunkSize,
  stepSize: chunkSize,
  query: {},
  carGuideAuctions: {},
  showAuctionFilters: false,
  showAdvancedAuctionFilters: false,
  doneLoadingAuctionsList: false,
  fetching: false,
  fetchingMore: false,
  closeCollapse: false,
  brandsAndModels: {}
};

/**
 * @deprecated TODO: [KVDBIL-1634] remove this
 */
export const auctionsList = (
  state = initialState,
  action:
    | GetFacilitiesAction
    | SearchObjectsAction
    | SearchAuctionObjectsSuccessAction
): ReduxStore['auctionsList'] => {
  switch (action.type) {
    case 'GET_FACILITIES_SUCCESS': {
      return {
        ...state,
        facilities: action.payload.map(renameFacility)
      };
    }

    case 'SEARCH_OBJECTS_REQUEST':
      // TODO: [1MP] this should be removed when we only have buyCar! :D
      const keyState = getStateKeysFromBooleans(action.payload);

      return {
        ...state,
        fetching: true,
        [keyState]: {
          ...state[keyState],
          isFetching: true,
          doneLoadingObjectsList: false
        }
      };

    case 'SEARCH_OBJECTS_FAILURE':
      return {
        ...state,
        fetching: false,
        bidding: { ...state.bidding, isFetching: false },
        fixedPrice: { ...state.fixedPrice, isFetching: false },
        buyCar: { ...state.buyCar, isFetching: false }
      };

    case 'SEARCH_OBJECTS_SUCCESS':
      return {
        ...state,
        fetching: false,
        doneLoadingAuctionsList: true,
        carGuideAuctions: action.payload.carGuideAuctions ?? {}
      };

    case 'SEARCH_BUY_CAR_OBJECTS_SUCCESS': {
      const ids = action.payload.auctions.map(auction => auction.id);
      const query =
        (action.payload.params &&
          createSearchQuery(normalizeSearchParams(action.payload.params))) ??
        {};

      return {
        ...state,
        buyCar: {
          ...state.buyCar,
          ids: ids,
          hits: action.payload.hits,
          total: action.payload.total,
          doneLoadingObjectsList: true,
          lastSearchQuery: query,
          isFetching: false
        }
      };
    }

    default:
      return state;
  }
};

/**
 * @deprecated TODO: [KVDBIL-1634] remove this
 */
export default auctionsList;
