import { theme } from '@kvdbil/components';
import { loadableReady } from '@loadable/component';
import 'core-js';
import 'intersection-observer';
import throttle from 'lodash.throttle';
import React from 'react';
import { hydrate } from 'react-dom';
import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import 'url-search-params-polyfill';
import AsyncRouter from '~/App/components/AsyncRouter';
import App from '~/App/index';
import { rehydrateLocale } from '~/App/shared/localization';
import {
  isDebug,
  launchDarklyClientID,
  targetedAuctionsFeatureEnabled
} from '~/config/public/environment';
import store from '~/config/store';
import { isLoggedIn } from '~/helpers/member';
import {
  getUsersTargetedAuctionGroupIds,
  pollTargetedAuctions,
  TARGETED_AUCTIONS_POLLING_INTERVALL_MILLISECONDS
} from '~/helpers/targetedAuctions';
import ChunkLoadErrorBoundary from './App/components/ChunkLoadErrorBoundary';
import { addAbTest } from './App/shared/actions/abtest';
import { hydrateFavouriteAuctions } from './App/shared/actions/favouriteAuctions';
import { loginFlowOffCanvas } from './App/shared/actions/loginFlowActions';
import { recentlyViewedAuctionsHydrate } from './App/shared/actions/recently-viewed-auctions';
import { recentlyViewedSearchesHydrate } from './App/shared/actions/recently-viewed-searches';
import { fetchSavedSearches } from './App/shared/actions/saved-searches';
import {
  fetchSessionClientVersion,
  sessionHydrate
} from './App/shared/actions/session';
import {
  clearPollingTargetedAuctions,
  hydrateTargetedAuctions,
  setPollingTargetedAuctions
} from './App/shared/actions/targetedAuctionsActions';
import { hydrateWonAuctions } from './App/shared/actions/wonAuctions';
import DebugBar from './App/shared/components/DebugBar/DebugBar';
import { loadDayJSLocale } from './App/shared/date/date';
import { Session } from './App/shared/interfaces/store/Session';
import { BugsnagErrorBoundary } from './config/bugsnag';
import { AB_TESTS } from './config/constants';
import { requestErrorHandler } from '~/helpers/notifyError';
import { log } from '~/helpers/bugsnagHelper';
import { LocalizationProvider } from './Locale';
import { Provider as GraphqlProvider } from 'urql';
// @ts-expect-error missing types
import Worker, { WorkerMessage } from './localForage.worker';
import { createGraphqlClient } from './config/graphqlClient';
import { FeatureFlagProvider } from './App/components/FeatureFlags';
import { initialize as initializeLDClient } from 'launchdarkly-js-client-sdk';
import logger from './helpers/logger';

require('~/../assets/styles.css');

const importFolder = (r: ReturnType<typeof require.context>) => {
  r.keys().forEach(r);
};

importFolder(
  require.context('../assets/images/', true, /\.(jpg|jpeg|gif|png|svg)$/)
);

importFolder(
  require.context('../assets/fonts/', true, /\.(woff(2)?|ttf|eot|svg)?$/)
);

const {
  localization: { domainLocale }
} = store.getState().app;

const currentLocale = window.CLIENT_LOCALE;
loadDayJSLocale(currentLocale);

// For easier debugging of redux state
window.reduxStore = store;

const localForageWorker = new Worker();

const hydrateSession = (session: Session) => {
  store.dispatch(clearPollingTargetedAuctions());
  store.dispatch(sessionHydrate({ session: session }));
  if (isLoggedIn(session)) {
    store
      .dispatch(hydrateFavouriteAuctions(session.auth.idToken))
      .catch(requestErrorHandler);
    store
      .dispatch(
        fetchSavedSearches({
          idToken: session.auth.idToken
        })
      )
      .catch(requestErrorHandler);
    store
      .dispatch(hydrateWonAuctions({ idToken: session.auth.idToken }))
      .catch(requestErrorHandler);

    if (targetedAuctionsFeatureEnabled) {
      const groups = getUsersTargetedAuctionGroupIds(session.auth.idToken);
      const threadArray: ReturnType<typeof setInterval>[] = [];

      groups.forEach(group => {
        pollTargetedAuctions(group, session);
        const thread = setInterval(
          () => pollTargetedAuctions(group, session),
          TARGETED_AUCTIONS_POLLING_INTERVALL_MILLISECONDS
        );

        threadArray.push(thread);
        store
          .dispatch(
            hydrateTargetedAuctions({
              idToken: session.auth.idToken,
              targetedAuctionId: group
            })
          )
          .then(() => {
            store.dispatch(setPollingTargetedAuctions(threadArray));
          })
          .catch((error: unknown) => {
            log(new Error('Failed to hydrate targeted auctios'), {
              error: error,
              severity: 'warning'
            });
          });
      });
    }

    store
      .dispatch(fetchSavedSearches({ idToken: session.auth.idToken }))
      .catch(requestErrorHandler);
    store
      .dispatch(hydrateFavouriteAuctions(session.auth.idToken))
      .catch(requestErrorHandler);
  }
};

store.dispatch(
  loginFlowOffCanvas(typeof window !== 'undefined' && window.innerWidth > 768)
);

const CLIENT_VERSION_CHECK_INTERVAL = 1000 * 60 * 15; // ms * sec * min = 15 minutes

const fetchClientVersion = () => {
  void store.dispatch(fetchSessionClientVersion());
};

fetchClientVersion();
setInterval(fetchClientVersion, CLIENT_VERSION_CHECK_INTERVAL);

const GET_SESSION = 'GET_SESSION';
const GET_RECENTLY_VIEWED_AUCTIONS = 'GET_RECENTLY_VIEWED_AUCTIONS';
const GET_RECENTLY_VIEWED_SEARCHES = 'GET_RECENTLY_VIEWED_SEARCHES';

localForageWorker.onmessage = ({ data: message }: { data: WorkerMessage }) => {
  switch (message.type) {
    case GET_RECENTLY_VIEWED_AUCTIONS:
      store.dispatch(recentlyViewedAuctionsHydrate(message.payload));
      break;
    case GET_RECENTLY_VIEWED_SEARCHES:
      store.dispatch(recentlyViewedSearchesHydrate(message.payload));
      break;
    case GET_SESSION:
      hydrateSession(message.payload);
      break;
    default:
      break;
  }
};

localForageWorker.onerror = (error: object) => {
  logger.error({ ...error });
};

/* Hydrate localForage data */
localForageWorker.postMessage({ type: GET_RECENTLY_VIEWED_AUCTIONS });
localForageWorker.postMessage({ type: GET_RECENTLY_VIEWED_SEARCHES });
localForageWorker.postMessage({ type: GET_SESSION });

// Only update localForage from store every {throttleTimeout} milliseconds
const throttleTimeout = 1000;

const SET_SESSION = 'SET_SESSION';
const SET_SELL_FLOW = 'SET_SELL_FLOW';
const SET_RECENTLY_VIEWED_AUCTIONS = 'SET_RECENTLY_VIEWED_AUCTIONS';
const SET_RECENTLY_VIEWED_SEARCHES = 'SET_RECENTLY_VIEWED_SEARCHES';
store.subscribe(
  throttle(() => {
    const storeState = store.getState();
    const { isHydrated } = storeState.session;

    const isRecentlyAuctionsHydrated =
      storeState.recentlyViewedAuctions.isHydrated;
    const isRecentlySearchesHydrated =
      storeState.recentlyViewedSearches.isHydrated;

    if (isHydrated) {
      localForageWorker.postMessage({
        type: SET_SESSION,
        payload: storeState.session
      });
    }

    if (isRecentlyAuctionsHydrated) {
      try {
        localForageWorker.postMessage({
          type: SET_RECENTLY_VIEWED_AUCTIONS,
          payload: storeState.recentlyViewedAuctions
        });
      } catch (e: unknown) {
        // I don't think this will actually happen, as the data in the store
        // _should_ not be large enough to cause an out of memory error after
        // the changes to the reducer. Log just in case
        log(new Error('Failed saving recently viewed searches in browser'), {
          severity: 'warning',
          error: e,
          payload: storeState.recentlyViewedAuctions
        });
      }
    }

    if (isRecentlySearchesHydrated) {
      localForageWorker.postMessage({
        type: SET_RECENTLY_VIEWED_SEARCHES,
        payload: storeState.recentlyViewedSearches
      });
    }

    localForageWorker.postMessage({
      type: SET_SELL_FLOW,
      payload: storeState.sellFlow
    });
  }, throttleTimeout)
);

const handleNavigationBlock = (message: string, callback: CallableFunction) =>
  callback(message !== 'block');

/* We keep this function here. If we run this AFTER we will overrite the current window.startABTest, which we want to */
window.startABTest = abTest => {
  const definedABTests = Object.keys(AB_TESTS);
  if (definedABTests.includes(abTest)) {
    store.dispatch(addAbTest(abTest));
  } else {
    logger.error(`The A/B test ${abTest} does not exist.`);
  }
};

const flags = window.FEATURE_FLAGS.flags;
const user = window.FEATURE_FLAGS.user;
const bootstrap = window.FEATURE_FLAGS.bootstrap;

const client = initializeLDClient(launchDarklyClientID, user, {
  sendEvents: true, // False will send no events, A/B test events included
  evaluationReasons: false, // Will request evaluation reasons from LD, no need since we got them from backend
  fetchGoals: false, // One less request, but will only allow custom metrics being counted
  sendEventsOnlyForVariation: true, // Will send less events which will save us money
  diagnosticOptOut: true, // Will not send any diagnostic data to LD
  bootstrap // Set flags from server side, skips initial get flags request on init
});

const routerBasename =
  domainLocale !== currentLocale ? `/${currentLocale}` : undefined;

const { value: cmsProxyCaching } = flags?.['cms-proxy-caching'] ?? {};

const createDoc = () => {
  return (
    <BugsnagErrorBoundary>
      <GraphqlProvider
        value={createGraphqlClient({
          useExternalCaching: Boolean(cmsProxyCaching)
        })}
      >
        <LocalizationProvider
          locale={rehydrateLocale()}
          options={{ showKeys: false }}
        >
          <ThemeProvider theme={theme}>
            <Provider store={store}>
              <FeatureFlagProvider flags={flags} user={user} client={client}>
                <ChunkLoadErrorBoundary>
                  <Router
                    basename={routerBasename}
                    getUserConfirmation={handleNavigationBlock}
                  >
                    <AsyncRouter store={store}>
                      <App />
                    </AsyncRouter>
                  </Router>
                </ChunkLoadErrorBoundary>
              </FeatureFlagProvider>
            </Provider>
          </ThemeProvider>
          {isDebug && <DebugBar />}
        </LocalizationProvider>
      </GraphqlProvider>
    </BugsnagErrorBoundary>
  );
};

void loadableReady(() => {
  hot(true);
  hydrate(createDoc(), document.getElementById('root'));
});
