import { resetButtonStyle } from '@kvdbil/components';
import React, { MouseEvent, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from '~/Locale';
import TextFieldWithControl, { TextFieldProps } from './TextFieldWithControl';

const Button = styled.button`
  ${resetButtonStyle}
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.gray.light1};
`;

const PasswordFieldWithControl = <FormState,>(
  props: TextFieldProps<FormState>
) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const toggleVisibility = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setShowPassword(prev => !prev);
  };

  const inputType = showPassword ? 'text' : 'password';

  return (
    <TextFieldWithControl
      {...props}
      type={inputType}
      suffix={
        <Button onClick={toggleVisibility} tabIndex={0} type="button">
          {showPassword ? t('Hide') : t('Show')}
        </Button>
      }
    />
  );
};

export default PasswordFieldWithControl;
