import React from 'react';
import styled from 'styled-components';
import Media from '~/App/shared/components/Media';
import { useTranslation } from '~/Locale';
import FatalGraphic from '../graphics/FatalGraphic';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: ${({ theme }) => theme.colors.background.gray};
  padding: 2rem;
  text-align: center;

  &:before {
    content: ' ';
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    width: 0;
  }
`;

const Header = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  top: 0;
  left: 0;
  padding-top: 2rem;
`;

const Logo = styled.div`
  width: 140px;
  margin: 0 auto;
`;

const Content = styled.div`
  max-width: 320px;
  width: 95%;
  display: inline-block;
  vertical-align: middle;
`;

const Image = styled.div`
  font-size: 10rem;
  margin: 0 auto;
`;

const Error = styled.div`
  white-space: pre;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1em;
  text-align: left;
  font-size: 0.75rem;
  font-family: 'courier new';
  margin: 1rem;
  transform: translate(0%, -50%);
`;

const Text = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.gray.dark4};
`;

export interface FatalProps {
  errorOutput?: string;
}

export const Fatal = ({ errorOutput = '' }: FatalProps) => {
  const { t } = useTranslation();

  return (
    <Container data-testid="fatal">
      <Header>
        <Logo data-testid="fatal-logo">
          <Media
            alwaysLoad
            ratio={273 / 72}
            src="/images/kvdbil-logo.png"
            alt="kvd.se"
          />
        </Logo>
      </Header>
      <Content>
        <Image>
          <FatalGraphic data-testid="fatal-icon" />
        </Image>
        <Text>
          {t(
            'Oops, it seems to be a problem with the server. We are looking in to it as soon as we can'
          )}
        </Text>
      </Content>
      {errorOutput ? <Error>{errorOutput}</Error> : null}
    </Container>
  );
};

export default Fatal;
