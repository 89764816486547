import { HeaderActions } from '../actions/headerActions';

const headerReducer = (
  state = {
    headerHeight: null,
    isHeaderHidden: false,
    isHeaderSlideOpen: true,
    isHeaderSlideEnabled: true
  },
  action: HeaderActions
) => {
  switch (action.type) {
    case 'SET_HEADER_HEIGHT':
      return { ...state, headerHeight: action.payload };

    case 'SET_HEADER_HIDDEN':
      return { ...state, isHeaderHidden: action.payload };

    case 'SET_HEADER_SLIDE_OPEN':
      return { ...state, isHeaderSlideOpen: action.payload };

    case 'SET_HEADER_SLIDE_ENABLED':
      return { ...state, isHeaderSlideEnabled: action.payload };

    default:
      return state;
  }
};

export default headerReducer;
