import { SessionActions } from './../actions/session';

import { fetchNotifications } from '~/helpers/orchestration/notifications';

import { Notifications } from '../interfaces/store/Notifications';
import { ReduxDispatch, ReduxMiddlewareAPI } from '../interfaces/Redux';

export interface NotificationAPI extends ReduxMiddlewareAPI {
  fetcher(idToken: string): Promise<Notifications>;
}

export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';
export const fetchNotificationsMiddleware =
  ({ dispatch, fetcher = fetchNotifications }: NotificationAPI) =>
  (next: ReduxDispatch) =>
  async (action: SessionActions) => {
    if (!action) {
      next(action);
    }

    if (
      action.type === 'SESSION_HYDRATE' ||
      action.type === 'SESSION_START_SUCCESS'
    ) {
      const idToken =
        action.type === 'SESSION_START_SUCCESS'
          ? action.auth?.idToken
          : action.session?.auth?.idToken;

      next(action);

      if (!idToken) {
        return;
      }

      dispatch({ type: GET_NOTIFICATIONS_REQUEST });

      try {
        const res = await fetcher(idToken);
        dispatch({ type: GET_NOTIFICATIONS_SUCCESS, payload: res });
      } catch (err) {
        dispatch({ type: GET_NOTIFICATIONS_FAILURE, payload: err });
      }

      return;
    }

    return next(action);
  };
