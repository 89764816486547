export const setHeaderHeightAction = (payload: number | null) => ({
  type: 'SET_HEADER_HEIGHT' as const,
  payload
});

export const setHeaderHiddenAction = (payload: boolean) => ({
  type: 'SET_HEADER_HIDDEN' as const,
  payload
});

export const setHeaderSlideAction = (payload: boolean) => ({
  type: 'SET_HEADER_SLIDE_OPEN' as const,
  payload
});

export const setHeaderSlideEnabledAction = (payload: boolean) => ({
  type: 'SET_HEADER_SLIDE_ENABLED' as const,
  payload
});

export type HeaderActions =
  | ReturnType<typeof setHeaderHeightAction>
  | ReturnType<typeof setHeaderHiddenAction>
  | ReturnType<typeof setHeaderSlideAction>
  | ReturnType<typeof setHeaderSlideEnabledAction>;
