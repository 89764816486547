import { useCallback, useEffect } from 'react';
import { SCARAB_STORAGE_KEYS } from '~/config/constants';
import { emarsysTrackingId } from '~/config/public/environment';

const ConsentBasedScripts = () => {
  const emarsysScarabScriptId = 'scarab-js-api';

  const addEmarsysScarabScript = useCallback(() => {
    window.ScarabQueue = window.ScarabQueue || [];
    window.EMARSYS_GA_DISABLED = true;

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = emarsysScarabScriptId;
    script.src = `//cdn.scarabresearch.com/js/${emarsysTrackingId}/scarab-v2.js`;
    script.async = true;
    document.head.appendChild(script);
  }, []);

  const removeEmarsysScarabScript = useCallback(() => {
    SCARAB_STORAGE_KEYS.forEach(key => {
      sessionStorage?.removeItem(key);
      localStorage?.removeItem(key);
    });

    if (window.ScarabQueue) {
      window.ScarabQueue = []; // Reset the queue to prevent further processing
    }
    if (typeof window.EMARSYS_GA_DISABLED !== 'undefined') {
      window.EMARSYS_GA_DISABLED = undefined;
    }

    const scarabScript = document.getElementById(emarsysScarabScriptId);
    if (scarabScript) {
      scarabScript.remove();
    }
  }, []);

  useEffect(() => {
    const handleMarketingConsent = () => {
      const currentMarketingConsent = window?.Cookiebot?.consent?.marketing;

      if (typeof currentMarketingConsent !== 'boolean') {
        return;
      }

      const isScarabScriptAlreadyAdded = document.getElementById(
        emarsysScarabScriptId
      );

      if (currentMarketingConsent) {
        !isScarabScriptAlreadyAdded && addEmarsysScarabScript();
      } else {
        isScarabScriptAlreadyAdded && removeEmarsysScarabScript();
      }
    };

    window.addEventListener('CookiebotOnConsentReady', handleMarketingConsent);

    return () => {
      window.removeEventListener('CookiebotOnConsentReady', () => null);
    };
  }, [addEmarsysScarabScript, removeEmarsysScarabScript]);

  return null;
};

export default ConsentBasedScripts;
