import { Spinner } from '@kvdbil/components';
import React from 'react';
import StatusPageContainer from '~/App/shared/components/StatusPageContainer';

interface Props {
  minHeight?: number;
  unit?: 'rem' | 'vh';
}

const StatusPageLoading = ({ minHeight = 50, unit }: Props) => {
  return (
    <StatusPageContainer $minHeight={minHeight} $unit={unit}>
      <Spinner size="large" speed="fast" color="neutral" />
    </StatusPageContainer>
  );
};

export default StatusPageLoading;
