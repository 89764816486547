import React, { ReactNode } from 'react';
import styled from 'styled-components';
import {
  theme,
  Status as StyledStatus,
  LeaderIcon,
  ErrorFilledIcon
} from '@kvdbil/components';
import { ProcessedAuctionStates } from '~/helpers/auction';
import { TranslateFunction } from '~/Locale';

export interface TextItem {
  label: string;
  icon: ReactNode;
  color: string;
}
export const getAuctionStatusTexts = (
  processAuctionStates: ProcessedAuctionStates,
  reservationPriceReached: boolean,
  t: TranslateFunction
): TextItem[] => {
  const { isClosed, isWinner, isLeading, isOverbidded, isParticipating } =
    processAuctionStates;

  const texts: TextItem[] = [];

  /* Are we not participating in the auction, display nothing. */
  if (!isParticipating) {
    return [];
  }

  if (isClosed && isWinner) {
    if (reservationPriceReached) {
      texts.push({
        label: t('You won'),
        icon: <LeaderIcon />,
        color: theme.colors.primary.main
      });
    } else {
      texts.push({
        label: t('Highest bid'),
        icon: <LeaderIcon />,
        color: theme.colors.text.dark
      });
    }
  }

  if (isClosed && !isWinner) {
    texts.push({
      label: t('You lost'),
      icon: null,
      color: theme.colors.text.dark
    });
  }

  if (!isClosed && isLeading) {
    texts.push({
      label: t(`You're leading`),
      icon: <LeaderIcon />,
      color: theme.colors.primary.main
    });
  }

  if (!isClosed && isOverbidded) {
    texts.push({
      label: t(`Overbid`),
      icon: <ErrorFilledIcon />,
      color: theme.colors.text.dark
    });
  }

  return texts;
};

const TextStatus = styled(StyledStatus)<{ color: string }>`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  border-radius: 50rem;
  color: ${props => props.color};

  svg {
    margin-right: 0.25rem;
  }
`;

const Status = ({ label, icon, color }: TextItem) => {
  return (
    <TextStatus as="div" color={color} key={label}>
      {icon}
      {label}
    </TextStatus>
  );
};

export default Status;
