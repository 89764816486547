import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { sessionDestroy } from '~/App/shared/actions/session';
import { isHydratedSelector } from '~/App/shared/selectors/sessionSelector';

export const Logout = () => {
  const dispatch = useDispatch();
  const isHydrated = useSelector(isHydratedSelector);

  useEffect(() => {
    if (isHydrated) {
      dispatch(sessionDestroy());
    }
  }, [dispatch, isHydrated]);

  if (!isHydrated) {
    return null;
  }

  return <Redirect to="/" />;
};

export default Logout;
