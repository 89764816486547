import { ButtonRegularTextStyle } from '@kvdbil/components';
import React, { useCallback } from 'react';
import Select, {
  components,
  GroupBase,
  OptionProps,
  SingleValueProps,
  PlaceholderProps
} from 'react-select';
import { ALL_LOCALE_CODES } from '~/config/constants';
import styled from 'styled-components';
import {
  countriesWithoutSweden,
  Country
} from '~/App/shared/localization/components/constants';
import { languageSelectModalStyles } from '~/App/shared/localization/components/selectStyles';
import { appStartLoading } from '~/App/shared/actions/app';
import { fetchLocalizedRoute } from '~/helpers/orchestration/app';
import { trimTrailingSlash } from '~/App/shared/localization/helpers';
import { generatePath } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { localizationSelector } from '~/App/shared/selectors/localization';
import { useLocation } from 'react-router-dom';
import { useLanguageSelection } from '~/App/shared/localization/hooks/useLanguageSelection';

const IconWrapper = styled.span`
  display: flex;
  font-size: 1.5rem;
`;

const LabelWrapper = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  ${ButtonRegularTextStyle}
`;

const Option = (props: OptionProps<Country, false, GroupBase<Country>>) => {
  return (
    <components.Option {...props}>
      <IconWrapper>{props.data.icon}</IconWrapper>
      <LabelWrapper>{props.data.label}</LabelWrapper>
    </components.Option>
  );
};

const Placeholder = ({
  children,
  ...props
}: PlaceholderProps<Country, false, GroupBase<Country>>) => (
  <components.Placeholder {...props}>
    <LabelWrapper>{children}</LabelWrapper>
  </components.Placeholder>
);

const SingleValue = (
  props: SingleValueProps<Country, false, GroupBase<Country>>
) => (
  <components.SingleValue {...props}>
    <IconWrapper>{props?.data?.icon}</IconWrapper>
    <LabelWrapper>{props?.data?.label}</LabelWrapper>
  </components.SingleValue>
);

const LanguageSelectModal = () => {
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const {
    currentLocale,
    currentRoute: { originalPathname, params }
  } = useSelector(localizationSelector);

  const { getRedirectUrl } = useLanguageSelection();

  const filteredCountries = countriesWithoutSweden.filter(country =>
    ALL_LOCALE_CODES.includes(country.value)
  );

  const handleLanguageSelection = useCallback(
    async (selectedCountry: Country) => {
      dispatch(appStartLoading());

      if (!originalPathname || !currentLocale || originalPathname === '/') {
        window.location.href = getRedirectUrl(selectedCountry.value, search);
        return;
      }

      let redirectUrl = '';
      try {
        const translatedPath = await fetchLocalizedRoute({
          currentLocale,
          requestedLocale: selectedCountry.value,
          originalRoute: originalPathname,
          currentPathname: pathname,
          params
        });

        const generatedPath = trimTrailingSlash(
          generatePath(translatedPath, params)
        );
        redirectUrl = getRedirectUrl(
          selectedCountry.value,
          `${generatedPath}${search}`
        );
      } catch (e) {
        redirectUrl = getRedirectUrl(selectedCountry.value, search);
      }

      window.location.href = redirectUrl;
    },
    [
      currentLocale,
      dispatch,
      getRedirectUrl,
      originalPathname,
      params,
      pathname,
      search
    ]
  );

  return (
    <Select<Country, false, GroupBase<Country>>
      options={filteredCountries}
      onChange={handleLanguageSelection}
      components={{
        IndicatorSeparator: () => null,
        Option,
        SingleValue,
        Placeholder
      }}
      styles={{ ...languageSelectModalStyles }}
      isSearchable={false}
      placeholder="Switch to..."
      menuPortalTarget={document.body}
      menuPosition="fixed"
      menuPlacement="bottom"
    />
  );
};

export default LanguageSelectModal;
