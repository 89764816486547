import { pathnameStartsWithRoute } from './router';
import { TranslateFunction } from '~/Locale';

const blackListedPaths = ['ROUTES.PAYMENT'];

export const showHeader = (t: TranslateFunction, pathname: string) => {
  return !blackListedPaths.some(route =>
    pathnameStartsWithRoute(t, pathname, route)
  );
};
