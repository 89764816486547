import React from 'react';
import SlideShow from '~/App/shared/components/SlideShow';
import Auction from '~/App/shared/interfaces/Auction';
import { ProductCardVariant } from '~/App/shared/components/ProductCard/types';
import ProductCard from '~/App/shared/components/ProductCard/ProductCard';

interface Props {
  auctions: Auction[];
  isCrossSaleSlider?: boolean;
  productCardVariant?: ProductCardVariant;
}

const AuctionsSlideShow = ({
  auctions = [],
  isCrossSaleSlider = false,
  productCardVariant = ProductCardVariant.Regular
}: Props) => {
  if (auctions.length === 0) {
    return null;
  }

  return (
    <SlideShow isCrossSaleSlider={isCrossSaleSlider}>
      {auctions.map((auction: Auction) => (
        <ProductCard
          key={auction.id}
          auction={auction}
          variant={productCardVariant}
        />
      ))}
    </SlideShow>
  );
};

export default AuctionsSlideShow;
