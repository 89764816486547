import dayjs from 'dayjs';
import { AuctionRequestsType } from '../actions/auction';
import { BiddingViewAction } from '../actions/objectViewDrawerActions';
import Auction, { AuctionNotFound } from '../interfaces/Auction';
import { ReduxStore } from '../interfaces/store';
import { ObjectView } from '../interfaces/store/ObjectView';

function isAuctionNotFound(
  auction: Auction | AuctionNotFound
): auction is AuctionNotFound {
  return !(auction as Auction)?.id;
}

const initialState: ObjectView = {
  storeObjects: {},
  isFetching: false,
  auctionId: undefined,
  isOpen: false,
  buyMethod: null,
  bidAmount: null,
  isLoading: false,
  allBids: [],
  flow: 'default',
  supportSchedule: {
    schedule: [],
    lastUpdate: null
  }
};

export const objectView = (
  state: ObjectView = initialState,
  action: AuctionRequestsType | BiddingViewAction
): ReduxStore['objectView'] => {
  switch (action.type) {
    case 'GET_AUCTION_REQUEST':
      return {
        ...state,
        isFetching: true
      };

    case 'GET_AUCTION_SUCCESS': {
      // if auction does not exist, the backend will response with AuctionNotFound schema
      if (isAuctionNotFound(action?.payload)) {
        return { ...state, isFetching: false };
      }

      return {
        ...state,
        storeObjects: {
          ...state.storeObjects,
          [action.payload.id]: action.payload
        },
        isFetching: false
      };
    }

    case 'GET_AUCTION_FAILURE': {
      return {
        ...state,
        isFetching: false,
        storeObjects: {
          ...state.storeObjects,
          [action.payload]: undefined
        }
      };
    }

    case 'OPEN_BIDDING_VIEW':
      return Object.assign({}, state, {
        isOpen: true,
        auctionId: action.payload,
        flow: action.flow,
        buyMethod: action.buyMethod
      });
    case 'CLOSE_BIDDING_VIEW':
      return Object.assign({}, state, {
        isOpen: false,
        bidAmount: null,
        buyMethod: null
      });

    case 'BIDDING_VIEW_SET_SUPPORT_SCHEDULE':
      return {
        ...state,
        supportSchedule: {
          schedule: action.payload ?? [],
          lastUpdate: action.payload ? dayjs() : null
        }
      };

    case 'BIDDING_VIEW_SET_ALL_BIDS': {
      if (!action.payload) {
        return state;
      }

      const bids = action.payload.map(bid => ({
        ...bid,
        auctionId: String(bid.auctionId)
      }));

      const auctionId = bids[0]?.auctionId;

      if (auctionId && state.auctionId === auctionId) {
        return { ...state, allBids: bids };
      }

      return state;
    }

    default:
      return { ...state };
  }
};
