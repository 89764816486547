/**
 * @deprecated TODO: [KVDBIL-1634] refactor...
 */

import {
  pair,
  lt,
  length,
  reject,
  converge,
  propOr,
  T,
  equals,
  compose,
  ifElse,
  is,
  always,
  when,
  split,
  toLower,
  dissoc,
  anyPass,
  map,
  isNil,
  assoc,
  evolve,
  pickBy,
  KeyValuePair,
  fromPairs,
  omit,
  zip,
  unless,
  or,
  join
} from 'ramda';
import { AuctionSearchQuery } from '~/App/shared/interfaces/AuctionSearchQuery';
import {
  AuctionSearchParams,
  EvolvedSearchParams
} from '~/App/shared/interfaces/AuctionSearchParams';
import { AuctionSearchKey } from '~/App/shared/selectors/auctionSearchSelector';
import { MinMaxIntervalKey, minMaxIntervalValues } from '~/config/constants';
import Auction from '~/App/shared/interfaces/Auction';
import { isEmpty } from '~/App/shared/utils';

const isOne = equals('1');
const isTrue = equals(true);
const isTrueString = equals('true');
const isTruthy = compose(
  anyPass<string | boolean>([isOne, isTrue, isTrueString]),
  when(is(String), toLower)
);

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export const normalizeString = compose(
  ifElse(
    is(String),
    compose(split(','), decodeURIComponent, toLower),
    map(when(is(String), toLower))
  ),
  when(isNil, always([]))
);

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export const normalizeBool = ifElse(isTruthy, T, always(null));

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export const assocPair =
  (type: string) => (params: Partial<AuctionSearchQuery>) => {
    const fromType = `${type}From` as keyof AuctionSearchQuery;
    const fromValue = propOr(null, fromType, params);

    const toType = `${type}To` as keyof AuctionSearchQuery;
    const toValue = propOr(null, toType, params);

    const values = pair(fromValue, toValue);

    return compose(assoc(type, values))(params);
  };

type MergeFromToCompose = (
  x: Partial<AuctionSearchQuery>
) => Partial<AuctionSearchQuery>;

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export const mergeFromTo =
  (type: string) => (params: Partial<AuctionSearchQuery>) => {
    const fromType = `${type}From`;
    const toType = `${type}To`;

    const hasProp = compose(
      lt(0),
      length,
      reject(isNil),
      converge(pair, [propOr(null, fromType), propOr(null, toType)])
    );

    const mergeCompose: MergeFromToCompose = compose(
      dissoc(toType),
      dissoc(fromType),
      when(hasProp, assocPair(type))
    );

    return mergeCompose(params);
  };

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export const normalizeSearchParams = (
  params: Partial<AuctionSearchQuery>
): AuctionSearchParams => {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  return (compose as any)(
    mergeFromTo('bid'),
    mergeFromTo('hp'),
    mergeFromTo('year'),
    mergeFromTo('grade'),
    mergeFromTo('cardealerPrice'),
    mergeFromTo('buyNowAmount'),
    mergeFromTo('odometerReading'),
    mergeFromTo('loanCost'),
    mergeFromTo('releasingCost'),
    mergeFromTo('co2'),
    mergeFromTo('vehicleType'),
    evolve({
      fuel: normalizeString,
      bid: normalizeString,
      year: normalizeString,
      brand: normalizeString,
      color: normalizeString,
      numSeats: normalizeString,
      facilities: normalizeString,
      familyName: normalizeString,
      vehicleBody: normalizeString,
      gearbox: normalizeString,
      equipment: normalizeString,
      cardealerPrice: normalizeString,
      buyNoWAmount: normalizeString,
      odometerReading: normalizeString,
      releasingCost: normalizeString,
      loanCost: normalizeString,
      releasable: normalizeBool,
      exportable: normalizeBool,
      deductibleVat: normalizeBool,
      reducedPrice: normalizeBool,
      metallic: normalizeBool,
      ecoFriendly: normalizeBool,
      certified: normalizeBool,
      buyNow: normalizeBool,
      co2: normalizeString
    })
  )(params);
};

type OptionalNum = number | null;
type Range = [OptionalNum, OptionalNum];

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export const isNotNil = (n: unknown) => !isNil(n) && !isEmpty(n);

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export const isNotFalsy = (n: unknown) => Boolean(n);

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export const createInterval =
  (type: string) =>
  (
    searchParams: EvolvedSearchParams | Partial<AuctionSearchQuery>
  ): Partial<AuctionSearchQuery> => {
    const [min, max] = minMaxIntervalValues[type as MinMaxIntervalKey];

    const vals: Range = propOr([null, null], type, searchParams);
    const nonStandardValue = (val: number, key: string) => {
      if (key === `${type}From`) {
        return val !== min;
      }
      return val !== max;
    };
    const newKeys = compose<
      Range,
      KeyValuePair<string, OptionalNum>[],
      Record<string, OptionalNum>,
      Record<string, OptionalNum>,
      Record<string, OptionalNum>
    >(
      reject(isNil),
      pickBy<Record<string, OptionalNum>>(nonStandardValue),
      fromPairs,
      zip([`${type}From`, `${type}To`])
    )(vals);

    return {
      ...omit([type], searchParams),
      ...newKeys
    };
  };

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export const createIntervals =
  (params: AuctionSearchKey[]) =>
  (
    searchParams: EvolvedSearchParams | Partial<AuctionSearchQuery>
  ): Partial<AuctionSearchQuery> =>
    params.reduce((acc, param) => {
      return createInterval(param)(acc);
    }, searchParams as Partial<AuctionSearchQuery>);

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export const SEARCH_QUERY_INTERVAL_KEYS = [
  'bid',
  'hp',
  'year',
  'grade',
  'odometerReading',
  'cardealerPrice',
  'buyNowAmount',
  'co2',
  'loanCost',
  'releasingCost'
];

export const createIntervalKeys =
  SEARCH_QUERY_INTERVAL_KEYS as AuctionSearchKey[];

const isNotArray = (v: unknown) => !Array.isArray(v);
const joinExceptNull = unless(or(equals(null), isNotArray), join(','));

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export const evolveSearchParams = (
  obj: AuctionSearchParams
): EvolvedSearchParams =>
  evolve({
    fuel: joinExceptNull,
    brand: joinExceptNull,
    numSeats: joinExceptNull,
    color: joinExceptNull,
    gearbox: joinExceptNull,
    equipment: joinExceptNull,
    vehicleBody: joinExceptNull,
    familyName: joinExceptNull,
    facilities: joinExceptNull
  })(obj);

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export const createSearchQuery = (
  params: Partial<AuctionSearchParams>
): Partial<AuctionSearchQuery> => {
  return compose(
    createIntervals(createIntervalKeys),
    evolveSearchParams,
    pickBy(isNotFalsy),
    pickBy(isNotNil)
  )(params);
};

/**
 * @deprecated TODO: [KVDBIL-1634] this should be moved or removed
 */
export const getLocalFilters = (auction: Auction) => {
  const specificationOrder = ['brand', 'familyName', 'gearbox', 'objectType'];
  let objects = {};
  specificationOrder.forEach(key => {
    let value = '';
    if (key === 'objectType') {
      key = 'vehicleType';
      value = auction?.processObject?.objectType;
    } else {
      value = String(
        auction?.processObject?.properties?.[
          key as keyof typeof auction.processObject.properties
        ]
      );
    }

    if (!value) {
      return null;
    }
    objects = { ...objects, [key]: value.toLowerCase() };
  });
  return objects as AuctionSearchQuery;
};
