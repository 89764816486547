import styled from 'styled-components';
import React from 'react';
import dayjs from 'dayjs';
import AuctionCardTimer from '~/App/shared/components/ProductCard/components/ContainerWithImage/components/AuctionCardTimer';
import AuctionCardRemainingTime from '~/App/shared/components/ProductCard/components/ContainerWithImage/components/AuctionCardRemainingTime';
import { ProcessTimeStatuses } from '~/helpers/auction';

const OverlayButtons = styled.div`
  display: flex;
  align-items: center;
`;

export interface Props {
  isCounting: boolean;
  isComingSoon: boolean;
  preliminaryCloseAt?: string;
  processTimeStatuses: ProcessTimeStatuses;
}

const AuctionImageHeader = ({
  isCounting,
  preliminaryCloseAt,
  processTimeStatuses,
  isComingSoon
}: Props) => {
  return (
    <OverlayButtons>
      {isCounting && preliminaryCloseAt ? (
        <AuctionCardTimer closeAt={dayjs(preliminaryCloseAt).toDate()} />
      ) : (
        <AuctionCardRemainingTime
          processTimeStatuses={processTimeStatuses}
          isComingSoon={isComingSoon}
          preliminaryCloseAt={preliminaryCloseAt}
        />
      )}
    </OverlayButtons>
  );
};

export default AuctionImageHeader;
