import React from 'react';
import { Path } from 'react-hook-form';
import TextFieldWithControl from '~/App/shared/components/Fields/TextFieldWithControl';
import { TextFieldProps } from '../types';

const PhoneNumberField = <FormState,>({
  control,
  t,
  name,
  rules,
  label,
  ...props
}: TextFieldProps<FormState>) => {
  const validationRules = {
    required: {
      value: true,
      message: t(`Phone number can't be blank`)
    },
    ...rules
  };

  const fieldName = name ?? ('phoneNumber' as Path<FormState>);

  return (
    <TextFieldWithControl
      {...props}
      control={control}
      name={fieldName}
      label={label ?? t('Phone number')}
      autoComplete="tel"
      rules={validationRules}
      type="tel"
    />
  );
};

export default PhoneNumberField;
