import React from 'react';
import { CloseIcon, mq } from '@kvdbil/components';
import { propOr } from 'ramda';
import styled, { DefaultTheme, StyledComponent } from 'styled-components';
import { SiteMessageType } from '../interfaces/Message';

const Wrapper = styled.div`
  padding: 0.5em 0;
  color: ${({ theme }) => theme.colors.text.light};
  margin-top: -1px;
  border-width: 1px 0px;

  ${mq('tablet')} {
    padding: 1em 0;
  }
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1024px;
  margin: 0 auto;
  width: calc(100% - (0.75rem * 2));

  ${mq('tablet')} {
    width: calc(100% - (1rem * 2));
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  width: 2.5rem;
  height: auto;
  color: currentColor;
  outline: none;

  ${mq('tablet')} {
    order: 3;
  }
  ${mq(null, 'tablet')} {
    order: 1;
  }

  span {
    display: block;
  }

  font-size: 1.5rem;
`;

const Content = styled.div`
  order: 2;
  flex: 1;
  color: currentColor;
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 2.5rem);

  a {
    text-decoration: underline;
    color: currentColor;
  }
`;

const InformationWrapper = styled(Wrapper)`
  background-color: ${({ theme }) => theme.colors.info.main};
  border: solid 1px ${({ theme }) => theme.colors.info.dark};
`;

const USPWrapper = styled(Wrapper)`
  background-color: ${({ theme }) => theme.colors.background.gray};
  color: ${({ theme }) => theme.colors.primary.main};
  text-align: center;
  padding: 0.125rem 0;

  ${mq('tablet')} {
    padding: 0.125rem 0;
  }

  ${CloseButton} {
    display: none;
  }
`;

const ErrorWrapper = styled(Wrapper)`
  background-color: ${({ theme }) => theme.colors.error.main};
  border: 1px solid ${({ theme }) => theme.colors.error.dark};
`;

const SuccessWrapper = styled(Wrapper)`
  background-color: ${({ theme }) => theme.colors.success.main};
  border: 1px solid ${({ theme }) => theme.colors.success.dark};
`;

type StyledDiv = StyledComponent<
  'div',
  DefaultTheme,
  Record<string, unknown>,
  never
>;

type ComponentMapping = { [key in SiteMessageType]: StyledDiv };

const containers: Partial<ComponentMapping> = {
  USP: USPWrapper,
  Error: ErrorWrapper,
  Success: SuccessWrapper
};

export interface SiteMessageProps {
  children: string;
  type?: string;
  onDismiss(): void;
}

export const SiteMessage = ({
  children,
  type = 'Information',
  onDismiss
}: SiteMessageProps) => {
  const Container: StyledDiv = propOr(InformationWrapper, type, containers);

  return (
    <Container>
      <CenterContainer>
        <Content dangerouslySetInnerHTML={{ __html: children }} />
        <CloseButton
          aria-label="close-message"
          data-testid="site-message-close"
          onClick={onDismiss}
        >
          <CloseIcon />
        </CloseButton>
      </CenterContainer>
    </Container>
  );
};

export default SiteMessage;
