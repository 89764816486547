import {
  PickupProxyPerson,
  PickupProxyTransportCompany
} from '../../interfaces/store/Checkout';

export interface SetPickupPersonCarDealerRequestAction {
  type: 'SET_PICKUP_PERSON_CAR_DEALER_REQUEST';
  payload: {
    pickupProxyPerson: PickupProxyPerson;
    pickupProxyTransportCompany: PickupProxyTransportCompany;
  };
}

export interface PickupProxySuccessAction {
  type: 'GET_ORDER_SUCCESS';
  payload: {
    pickupProxyPerson: PickupProxyPerson;
    pickupProxyTransportCompany: PickupProxyTransportCompany;
  };
}

export const pickupProxyPersonReducer = (
  state: PickupProxyPerson | null = null,
  action: SetPickupPersonCarDealerRequestAction | PickupProxySuccessAction
) => {
  switch (action.type) {
    case 'GET_ORDER_SUCCESS':
    case 'SET_PICKUP_PERSON_CAR_DEALER_REQUEST':
      return action?.payload?.pickupProxyPerson ?? null;
    default:
      return state;
  }
};

export const pickupProxyTransportCompanyReducer = (
  state: PickupProxyTransportCompany | null = null,
  action: SetPickupPersonCarDealerRequestAction | PickupProxySuccessAction
) => {
  switch (action.type) {
    case 'GET_ORDER_SUCCESS':
    case 'SET_PICKUP_PERSON_CAR_DEALER_REQUEST':
      return action?.payload?.pickupProxyTransportCompany ?? null;
    default:
      return state;
  }
};
