import {
  Button,
  Caption,
  Message,
  Spacer,
  Title,
  Flex
} from '@kvdbil/components';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from '~/Locale';
import { useSelector } from 'react-redux';
import { email as emailSelector } from '~/App/shared/selectors/sessionSelector';
import { runWithRecaptcha } from '~/helpers/recaptcha';
import { useDynamicScript } from '~/App/shared/hooks/useDynamicScript';
import { googleRecaptchaSource } from '~/config/dynamicScriptsTags';
import { createBusinessSubscriber } from '~/helpers/orchestration/subscribe';
import { EmailField } from '~/App/shared/components/Fields/shared';
import CheckBoxWithControl from '~/App/shared/components/Fields/CheckBoxWithControl';
import { useForm } from 'react-hook-form';

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

const FormWrapper = styled.div`
  padding-top: 1rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

interface FormValues {
  email: string;
  corporateSubscription: boolean;
}

interface Props {
  title?: string;
  description?: string;
  sendButton?: string;
}

const CorporateNewsletterForm = ({ title, description, sendButton }: Props) => {
  const { t } = useTranslation();
  const memberEmail = useSelector(emailSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid }
  } = useForm<FormValues>({
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      email: memberEmail || '',
      corporateSubscription: false
    }
  });

  useDynamicScript(googleRecaptchaSource);

  const isCorporateSubscriptionChecked = watch('corporateSubscription');

  const onSubmit = (values: FormValues) => {
    setError('');
    setIsLoading(true);

    const { email, corporateSubscription } = values;
    runWithRecaptcha(() => {
      createBusinessSubscriber({ email, confirmation: corporateSubscription })
        .then(() => {
          setIsSuccess(true);
        })
        .catch(() => {
          setError(
            t("We can't add you to the business newsletter. Please try again.")
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  return (
    <Container data-testid="corporate-newsletter">
      <Title as="h3">{title || t('Subscribe to our newsletter')}</Title>
      <Spacer height={0.5} />
      <Caption>
        {description || t('You can end your subscription at any time.')}
      </Caption>

      <FormWrapper>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <EmailField control={control} t={t} />

          {isSuccess ? (
            <Message fullWidth withIcon type="success">
              {t('Thanks! You are now subscribed to our newsletter')}
            </Message>
          ) : (
            <Flex rowGap="0.5rem" direction="column">
              <CheckBoxWithControl
                control={control}
                name="corporateSubscription"
                label={t('Yes please, I want newsletters!')}
              />
            </Flex>
          )}

          <Button
            isLoading={isLoading}
            fullWidth
            type="submit"
            color="secondary"
            size="regular"
            isDisabled={!isValid || !isCorporateSubscriptionChecked}
          >
            {sendButton || t('Subscribe')}
          </Button>
        </Form>
      </FormWrapper>

      {error && (
        <>
          <Spacer />
          <Message withIcon type="error">
            {error}
          </Message>
        </>
      )}
    </Container>
  );
};

export default CorporateNewsletterForm;
