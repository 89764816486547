import { combineReducers } from 'redux';
import app from './app';
import time from './time';
import session from './sessionReducer';
import messages from './messagesReducer';
import auctions from './auctions';
import sellFlowReducer from './sellflowReducer';
import sellFlow1Reducer from './sellFlow1Reducer';
import transferReducer from './transferReducer';
import myAuctions from './myAuctionsReducer';
import snackbarReducer from './snackbarReducer';
import valuationReducer from './valuationReducer';
import loginFlow from './loginFlowReducer';
import verifyPhoneNumber from './verifyPhoneNumberReducer';
import header from './header';
import websocketBids from './websocketBidsReducer';
import connectionReducer from './connectionEvents';
import websocketAuctions from './websocketAuctions';
import favouriteAuctions from './favouriteAuctions';
import wonAuctions from './wonAuctions';
import savedSearches from './savedSearches';
import targetedAuctions from './targetedAuctionsReducer';
import checkoutReducer from './checkoutReducer/checkoutReducer';
import recentlyViewedAuctions from './recentlyViewedAuctionsReducer';
import recentlyViewedSearches from './recently-viewed-searches';
import auctionsListReducer from './auctionsList';
import finance from './finance';
import contactButton from './contactButton';
import abTestReducer from './abtest';
import notificationsReducer from './notificationsReducer';
import mySavedAuctions from './my-saved-auctions';
import { ReduxStore } from '../interfaces/store';
import featureFlagReducer from './featureFlags';
import cmsData from './cmsData';
import drawerMenu from './drawerMenuReducer';
import storeObjects from './storeObjectsReducer';
import { objectView } from './objectView';
import kvdHelper from './kvdHelperReducer';

export const rootReducer = combineReducers<ReduxStore>({
  app: app,
  abTests: abTestReducer,
  checkout: checkoutReducer,
  time: time,
  session: session,
  messages: messages,
  sellFlow: sellFlowReducer,
  sellFlow1: sellFlow1Reducer,
  loginFlow: loginFlow,
  header: header,
  websocketBids: websocketBids,
  connectionState: connectionReducer,
  websocketAuctions: websocketAuctions,
  savedSearches: savedSearches,
  myAuctions: myAuctions,
  notifications: notificationsReducer,
  transfer: transferReducer,
  targetedAuctions: targetedAuctions,
  favouriteAuctions: favouriteAuctions,
  mySavedAuctions: mySavedAuctions,
  recentlyViewedAuctions: recentlyViewedAuctions,
  recentlyViewedSearches: recentlyViewedSearches,
  auctionsList: auctionsListReducer,
  finance: finance,
  auctions: auctions,
  wonAuctions: wonAuctions,
  valuation: valuationReducer,
  snackbar: snackbarReducer,
  verifyPhoneNumber: verifyPhoneNumber,
  contactButton,
  featureFlags: featureFlagReducer,
  cmsData,
  drawerMenu,
  storeObjects,
  objectView,
  kvdHelper
});
