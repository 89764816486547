import styled from 'styled-components';
import { mq } from '@kvdbil/components';
import React from 'react';
import { formatMoney } from '~/helpers/currency';
import { useTranslation } from '~/Locale';
import { FinancingItemProps } from '~/App/shared/components/ProductCard/types';

const FinanceRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FinanceRowTitle = styled.span`
  font-family: ${({ theme }) => theme.typography.fontAlternativeFamily};
  font-weight: ${({ theme }) => theme.typography.fontAlternativeRegularWeight};
  font-size: 18px;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.text.dark};
  margin-top: 1rem;

  ${mq('tablet')} {
    font-family: ${({ theme }) => theme.typography.fontBaseFamily};
    font-weight: ${({ theme }) => theme.typography.fontBaseRegularWeight};
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 0.5rem;
  }
`;

const PriceValue = styled.span<{ $isPriceReduced: boolean }>`
  color: ${({ theme, $isPriceReduced }) =>
    $isPriceReduced ? theme.colors.tertiary.main : theme.colors.text.dark};
  font-family: ${({ theme }) => theme.typography.fontAlternativeFamily};
  font-weight: ${({ theme }) => theme.typography.fontAlternativeRegularWeight};
  font-size: 1.5rem;
  line-height: 2rem;
  margin-top: 1rem;

  ${mq('tablet')} {
    font-family: ${({ theme }) => theme.typography.fontBaseFamily};
    font-weight: ${({ theme }) => theme.typography.fontBaseBoldWeight};
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 0.5rem;
  }
`;

const SecondRowItem = styled.span`
  color: ${({ theme }) => theme.colors.text.dark};
  font-family: ${({ theme }) => theme.typography.fontBaseFamily};
  font-weight: ${({ theme }) => theme.typography.fontBaseRegularWeight};
  font-size: 1rem;
  line-height: 1.5rem;

  ${mq('tablet')} {
    font-size: 0.75rem;
  }
`;

const InitialPriceValue = styled(SecondRowItem)`
  text-decoration: line-through;
  text-align: right;
  color: ${({ theme }) => theme.colors.gray.dark4};
`;

const FinancingRowItem = ({
  label,
  value,
  isPrice,
  initialPrice
}: FinancingItemProps) => {
  const { t } = useTranslation();
  const isPriceReduced = Boolean(initialPrice);

  if (isPrice) {
    return (
      <>
        <FinanceRow>
          <FinanceRowTitle>{label}</FinanceRowTitle>
          <PriceValue $isPriceReduced={isPriceReduced}>{value}</PriceValue>
        </FinanceRow>
        {isPriceReduced && (
          <InitialPriceValue as="div">
            {formatMoney({
              value: initialPrice ?? 0,
              currency: t('SEK')
            })}
          </InitialPriceValue>
        )}
      </>
    );
  }

  return (
    <FinanceRow>
      <SecondRowItem>{label}</SecondRowItem>
      <SecondRowItem>{value}</SecondRowItem>
    </FinanceRow>
  );
};

export default FinancingRowItem;
